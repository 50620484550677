import React, { useState, useContext, useEffect } from 'react';
import {
  setVideoCallStatus,
  setStatus,
  callNothing,
  getVideoCall,
  callEndVideoCall,
  setVideoCall,
  saveNoteTime,
  saveNote,
  saveInLiveSessionData,
  getInliveSessionData,
  getVideoCallRelatedToPatient,
} from 'store/modules/video_call/actions';
import { useSelector, useDispatch } from 'react-redux';
import useDebounce from 'hooks/debounce/useDebounce';

import app from 'services/fire';
import { Input, Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import ImgCamera from 'assets/camera.svg';

import VideoCallContext from '../../Context';
import AgoraVideoCall from '../../../../../AgoraVideoCall';
import Timer from '../Subscreens/components/timer';

import * as S from './styles';

let unsubscribe = null;
let intervalToWaitPatientResponse = null;
const { TextArea } = Input;
let controllerStatus = '';
const arrayOfStatus = [];

export function Video({ selectedOption }) {
  const dispatch = useDispatch();
  const { patientDetails, professionalDetails } = useContext(VideoCallContext);
  const [videoCallVRStatus, setVideoCallVRStatus] = useState(
    'Carregando informações ....',
  );
  const [isPatientNotResponding, setIsPatientNotResponding] = useState(false);
  const [isToShowPatientName, setIstoShowPatientName] = useState(false);
  const [editedPatientName, setEditedPatientName] = useState('');
  const [videoCallTimer, setVideoCallTimer] = useState(
    <Timer endVideoCall={endVideoCall} action="nothing" />,
  );

  const videoCallStatus = useSelector(
    state => state.video_call.video_call_status,
  );
  const in_live_session_data = useSelector(
    state => state.video_call.in_live_session_data,
  );
  const videoCallConfig = useSelector(
    state => state.video_call.video_call_config,
  );
  const token = useSelector(state => state.auth.token);
  const user = useSelector(state => state.auth.user);
  const lastSavedTime = useSelector(
    state => state.video_call.last_time_note_saved,
  );
  const day_event_related_info = useSelector(
    state => state.video_call.day_event_related_info,
  );

  const currentProtocol = useSelector(state => state.video_call.current_evals);
  const note = useSelector(state => state.video_call.last_note_saved);

  const debounceChange = useDebounce(saveNotes, 9000);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  function updateTimer(status, id) {
    setVideoCallTimer(
      <Timer endVideoCall={endVideoCall} action={status} videoCallId={id} />,
    );
  }

  function callToPatient() {
    dispatch(setVideoCallStatus(patientDetails.id, 'CALLING'));

    unsubscribe = app
      .firestore()
      .collection('professionals')
      .doc(professionalDetails.id)
      .onSnapshot(doc => {
        dispatch(setStatus(doc.data().currentVideoCall.statusOnVideoCall));
      });

    setVideoCallVRStatus('Chamando paciente, aguarde ... ');
  }

  function saveNotes() {
    const _id = patientDetails.id;
    const sess_id = in_live_session_data.session.sessionId;
    const { eventId } = day_event_related_info;
    let evals = null;
    if (currentProtocol?.evaluations !== undefined) {
      evals = currentProtocol.evaluations;
    } else {
      evals = [];
    }
    dispatch(saveInLiveSessionData(_id, sess_id, evals, note, eventId));
  }

  function endVideoCall(channel, reason) {
    dispatch(callEndVideoCall(channel, reason));
  }

  function showMessageWaitingPatient(type) {
    setIsPatientNotResponding(type);
  }

  useEffect(() => {
    if (selectedOption === 'Prática do Dia') {
      if (day_event_related_info.eventId !== null) {
        dispatch(
          getInliveSessionData(patientDetails.id, day_event_related_info),
        );
      }
    }
  }, [selectedOption]);

  useEffect(() => {
    if (videoCallConfig !== null) {
      updateTimer('play', videoCallConfig?.videoCallId);
    }
  }, [videoCallConfig]);

  useEffect(() => {
    if (currentProtocol !== null && in_live_session_data !== null) {
      debounceChange();
    }
  }, [in_live_session_data]);

  useEffect(() => {
    if (patientDetails !== null) {
      dispatch(getVideoCallRelatedToPatient(patientDetails.id));
    }
  }, [patientDetails]);

  useEffect(() => {
    if (day_event_related_info?.eventId !== undefined) {
      callToPatient();
      /* if (day_event_related_info.eventId !== null) {
        dispatch(
          getInliveSessionData(patientDetails.id, day_event_related_info),
        );
      } */
    }
  }, [day_event_related_info]);

  useEffect(() => {
    if (unsubscribe !== null) {
      unsubscribe();
    }
  }, []);

  useEffect(() => {
    arrayOfStatus.push(videoCallStatus);
    switch (videoCallStatus) {
      case 'BUSY': {
        if (arrayOfStatus.at(-2) === '') {
          dispatch(callNothing(patientDetails.id));
        }
        controllerStatus = 'BUSY';
        setVideoCallVRStatus('Entrou na chamada! ');
        setIstoShowPatientName(true);

        const tempName = patientDetails.name.split(' ');
        if (tempName.length > 1) {
          setEditedPatientName(`${tempName[0]} ${tempName[1]}`);
        } else {
          setEditedPatientName(tempName[0]);
        }
        break;
      }
      case 'REFUSED':
        setVideoCallVRStatus('O paciente recusou a chamada! ');
        dispatch(callNothing(patientDetails.id));
        controllerStatus = 'REFUSED';
        break;
      case 'ACCEPTED':
        controllerStatus = 'ACCEPTED';
        setVideoCallVRStatus('O paciente aceitou! ');
        dispatch(getVideoCall());

        clearInterval(intervalToWaitPatientResponse);
        break;
      case 'CALLING': {
        // Garantir que só chame CALLING 1x
        if (controllerStatus !== videoCallStatus) {
          controllerStatus = 'CALLING';

          setVideoCallVRStatus('Ligando para o paciente, aguarde ... ');

          let contWaitPatient = 0;
          clearInterval(intervalToWaitPatientResponse);
          intervalToWaitPatientResponse = setInterval(() => {
            if (contWaitPatient >= 59) {
              clearInterval(intervalToWaitPatientResponse);
              dispatch(callNothing(patientDetails.id));
            } else {
              contWaitPatient += 1;
            }
          }, 1000);
        }

        break;
      }
      case 'NOTHING':
        controllerStatus = 'NOTHING';
        setVideoCallVRStatus('Ligue para o paciente! ');
        setIsPatientNotResponding(false);
        setIstoShowPatientName(false);
        updateTimer('stop', videoCallConfig?.videoCallId);

        if (videoCallConfig !== null) {
          dispatch(setVideoCall(null));
          dispatch(saveNoteTime(''));
          dispatch(saveNote(''));
          unsubscribe();
        }
        break;
      default:
        break;
    }
  }, [videoCallStatus]);

  return (
    <S.Container>
      {(videoCallStatus === 'NOTHING' || videoCallStatus === 'CALLING') && (
        <S.ContainerVideoStatus>
          <label>{videoCallVRStatus}</label>

          {videoCallStatus === 'NOTHING' && (
            <Button
              type="primary"
              htmlType="submit"
              className="btn-start-practice"
              icon={<S.ImageCamera src={ImgCamera} />}
              onClick={() => callToPatient()}
              style={S.AntdStyles.ButtonCallPatient}
            >
              Chamar Paciente
            </Button>
          )}
        </S.ContainerVideoStatus>
      )}

      {videoCallConfig !== null && (
        <>
          {videoCallTimer}
          <S.ContainerVideoCallScreen>
            <AgoraVideoCall
              videoProfile="480p_4"
              channel={videoCallConfig.videoCallId}
              transcode="interop"
              attendeeMode="video"
              baseMode="avc"
              appId={videoCallConfig.AGORA_APP_ID}
              uid={undefined}
              token={token}
              user={user}
              getVideoCallStatus={videoCallStatus}
              endVideoCall={endVideoCall}
              showMessageWaitingPatient={showMessageWaitingPatient}
            />
            {isPatientNotResponding && (
              <S.WaitForPatientScreen>
                <Spin indicator={antIcon} />
                <strong>Esperando paciente ...</strong>
              </S.WaitForPatientScreen>
            )}
            {isToShowPatientName && patientDetails?.name !== undefined && (
              <label className="patient-name">{editedPatientName}</label>
            )}
          </S.ContainerVideoCallScreen>

          {selectedOption === 'Prática do Dia' &&
            in_live_session_data !== null && (
              <>
                <S.ContainerTextInfo>
                  <span className="title-annotations">Anotações da Sessão</span>
                  {lastSavedTime !== '' && (
                    <span className="saved-at">
                      Anotações salvas em {lastSavedTime}
                    </span>
                  )}
                </S.ContainerTextInfo>
                <TextArea
                  rows={4}
                  value={note}
                  onChange={e => dispatch(saveNote(e.target.value))}
                />
              </>
            )}
        </>
      )}
    </S.Container>
  );
}
