import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Button } from 'antd';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateEvaluation } from 'store/modules/evaluations/actions';
import TextAndRadioGroup from '../../components/TextAndRadioGroup';
import * as S from './styles';

function DASH({ title, current_eval }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const qts = current_eval?.content?.questions;

  const Questions = [
    '1. Abrir um vidro novo ou com a tampa muito apertada',
    '2. Fazer tarefas domésticas pesadas (por exemplo: lavar paredes, lavar o chão)',
    '3. Carregar uma sacola ou uma maleta',
    '4. Lavar suas costas',
    '5. Usar uma faca para cortar alimentos',
    '6. Atividades recreativas que exigem alguma força ou impacto nos braços, ombros ou mãos (por exemplo: jogar vôlei, martelar).',
    '7. Durante a semana passada, em que ponto o seu problema com braço, ombro ou mão afetaram suas atividades normais com a família, amigos, vizinhos ou colegas?',
    '8. Durante a semana passada o seu trabalho ou outras atividades diárias regulares foram limitadas devido ao seu problema com braço, ombro ou mão',
    '9. Dor no braço, ombro ou mão',
    '10. Desconforto na pele (alfinetadas) no braço, ombro ou mão',
    '11. Durante a semana passada, quanto de dificuldade você teve para dormir por causa da dor no seu braço, ombro ou mão?',
  ];

  const [formValues, setFormValues] = useState(
    current_eval?.content?.questions.length > 0
      ? Questions.map((item, index) => ({
          index: index + 1,
          text: item,
          value: current_eval.content.questions[index].value,
        }))
      : Questions.map((item, index) => ({
          index: index + 1,
          text: item,
          value: '',
        })),
  );

  const forms = [
    { label: 'Não houve dificuldade (1 ponto)', value: '1' },
    { label: 'Houve pouca dificuldade (2 pontos)', value: '2' },
    { label: 'Houve dificuldade moderada (3 pontos)', value: '3' },
    { label: 'Dificuldade severa (4 pontos)', value: '4' },
    { label: 'Não conseguiu fazer (5 pontos)', value: '5' },
  ];

  const forms2 = [
    { label: 'Não afetou (1 ponto)', value: '1' },
    { label: 'Afetou pouco (2 pontos)', value: '2' },
    { label: 'Afetou moderadamente (3 pontos)', value: '3' },
    { label: 'Afetou muito (4 pontos)', value: '4' },
    { label: 'Afetou extremamente (5 pontos)', value: '5' },
  ];

  const forms3 = [
    { label: 'Não limitou (1 ponto)', value: '1' },
    { label: 'Limitou pouco (2 pontos)', value: '2' },
    { label: 'Limitou moderadamente (3 pontos)', value: '3' },
    { label: 'Limitou muito (4 pontos)', value: '4' },
    { label: 'Não conseguiu fazer (5 pontos)', value: '5' },
  ];

  const forms4 = [
    { label: 'Nenhuma (1 ponto)', value: '1' },
    { label: 'Pouca (2 pontos)', value: '2' },
    { label: 'Moderada (3 pontos)', value: '3' },
    { label: 'Severa (4 pontos)', value: '4' },
    { label: 'Extrema (5 pontos)', value: '5' },
  ];

  function updateValues(isFinal) {
    const tempFormValue = {
      id: current_eval?.id,
      isFinished: isFinal,
      name: title,
      professionalId: current_eval?.professionalId,
      result: null,
      content: {
        questions: formValues,
      },
    };
    dispatch(updateEvaluation(tempFormValue, id));

    if (isFinal) {
      setFormValues(
        Questions.map((item, index) => ({
          index: index + 1,
          text: item,
          value: '',
        })),
      );
    }
  }

  function returnForms(indexQuestion) {
    if (indexQuestion === 7) return forms2;
    if (indexQuestion === 8) return forms3;
    if (indexQuestion === 9 || indexQuestion === 10) return forms4;
    return forms;
  }

  useEffect(() => {
    const valuesFormValues = Object.values(formValues);
    if (valuesFormValues.find(item => item.value !== '')) {
      updateValues(false);
    }
  }, [formValues]);

  return (
    <Formik
      initialValues={{
        Answer1: qts[0] !== undefined ? qts[0].value : '',
        Answer2: qts[1] !== undefined ? qts[1].value : '',
        Answer3: qts[2] !== undefined ? qts[2].value : '',
        Answer4: qts[3] !== undefined ? qts[3].value : '',
        Answer5: qts[4] !== undefined ? qts[4].value : '',
        Answer6: qts[5] !== undefined ? qts[5].value : '',
        Answer7: qts[6] !== undefined ? qts[6].value : '',
        Answer8: qts[7] !== undefined ? qts[7].value : '',
        Answer9: qts[8] !== undefined ? qts[8].value : '',
        Answer10: qts[9] !== undefined ? qts[9].value : '',
        Answer11: qts[10] !== undefined ? qts[10].value : '',
      }}
      onSubmit={(values, actions) => {
        // eslint-disable-next-line prefer-const
        let valuesSubmit = {
          name: title,
          content: {
            questions: [],
          },
        };

        Questions.forEach((item, index) => {
          valuesSubmit.content.questions.push({
            index: index + 1,
            text: item,
            value: values[`Answer${index + 1}`],
          });
        });

        actions.resetForm({
          values: {
            Answer1: '',
            Answer2: '',
            Answer3: '',
            Answer4: '',
            Answer5: '',
            Answer6: '',
            Answer7: '',
            Answer8: '',
            Answer9: '',
            Answer10: '',
            Answer11: '',
          },
        });

        updateValues(true);
      }}
    >
      {props => (
        <>
          {Questions.map(
            (item, index) =>
              item && (
                <TextAndRadioGroup
                  text={item}
                  ArrayValue={returnForms(index + 1)}
                  setAnswer={props.setFieldValue}
                  keyAnswer={`Answer${index + 1}`}
                  direction="vertical"
                  setFormValues={setFormValues}
                  formValues={formValues}
                  index={index}
                  currentValue={props.values[`Answer${index + 1}`]}
                  propsFormik={props}
                />
              ),
          )}

          <Button
            type="primary"
            htmlType="submit"
            onClick={props.handleSubmit}
            style={S.AntdStyles.ButtonSave}
          >
            Salvar
          </Button>
        </>
      )}
    </Formik>
  );
}

export default DASH;
