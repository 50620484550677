/* eslint-disable no-lonely-if */
/* eslint-disable no-empty */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import { useDispatch } from 'react-redux';
import {
  Modal,
  Row,
  Col,
  Input,
  Dropdown,
  Menu,
  Button,
  InputNumber,
  notification,
  Checkbox,
  Radio,
  AutoComplete,
} from 'antd';

import { Formik } from 'formik';
import * as Yup from 'yup';

import moment from 'moment';
import 'moment/locale/pt-br';
import localePTDate from 'antd/lib/locale/pt_BR';

import InputMask from 'react-input-mask';
import { StatesOfBrazil } from 'utils/States';

import {
  PropsSpecialityArray,
  PropsSpecialityEnum,
  PropsSpecialityTranslated,
} from 'utils/speciality';
import {
  ProfessionTranslated,
  PropsProfessionArray,
  PropsProfessionArrayWithoutOther,
  PropsProfessionEnum,
  PropsProfessionTranslated,
} from 'utils/profession';
import { useCallback, useState } from 'react';
import { maskBuilderCPFCNPJ } from 'utils/maskValidator';
import { createProfessional } from 'store/modules/professional/actions';
import closeIcon from 'assets/close.svg';

import * as S from './styles';
import './styles.css';

export function AddProfessionalModal({ isModalVisible, handleOk }) {
  const firstChange = useMediaQuery('(min-width: 770px)');
  const secondChange = useMediaQuery('(min-width: 830px)');
  const dispatch = useDispatch();
  const [choosePatient, setChoosePatient] = useState('professional');

  const formikGeneralDataProfessional = {
    initialValues: {
      name: '',
      email: '',
      cellphone: '',
      speciality: [],
      otherSpeciality: '',
      profession: '',
      otherProfession: '',
      street: '',
      houseNumber: '',
      complement: '',
      neighborhood: '',
      city: '',
      state: 'Selecione',
      postalCode: '',
      document: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('É obrigatório'),
      email: Yup.string().email('E-mail inválido').required('É obrigatório'),
    }),
  };
  const formikGeneralDataReceptionist = {
    initialValues: {
      name: '',
      email: '',
      document: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('É obrigatório'),
      email: Yup.string().email('E-mail inválido').required('É obrigatório'),
    }),
  };

  const professionTranslated = useCallback(profession => {
    const result = PropsProfessionTranslated.find(p => p.type === profession)
      .pt;
    return result;
  }, []);

  const specialityTranslated = useCallback(speciality => {
    const result = PropsSpecialityTranslated.find(p => p.type === speciality)
      .pt;
    return result;
  }, []);

  return (
    <Formik
      initialValues={
        choosePatient === 'professional'
          ? formikGeneralDataProfessional.initialValues
          : formikGeneralDataReceptionist.initialValues
      }
      validationSchema={
        choosePatient === 'professional'
          ? formikGeneralDataProfessional.validationSchema
          : formikGeneralDataReceptionist.validationSchema
      }
      onSubmit={values => {
        if (
          (values.speciality.length === 0 &&
            values.otherSpeciality === '' &&
            choosePatient === 'professional') ||
          (values.profession === '' &&
            values.otherProfession === '' &&
            choosePatient === 'professional')
        ) {
          notification.error({
            message:
              'Por favor preencha profissão e especialidade e tente adicionar novamente!',
          });
        } else {
          dispatch(createProfessional(values, choosePatient));
          handleOk();
        }
      }}
    >
      {props => (
        <Modal
          title="Adicionar novo profissional"
          visible={isModalVisible}
          onOk={() => handleOk()}
          onCancel={() => handleOk()}
          width={850}
          closeIcon={
            <img src={closeIcon} alt="close" style={S.AntdStyles.iconClose} />
          }
          footer={[
            <Button
              type="primary"
              onClick={() => props.resetForm()}
              style={{
                width: '180px',
                borderRadius: '5px',
                backgroundColor: 'transparent',
                borderColor: '#118E88',
                color: '#118E88',
              }}
            >
              Limpar Formulário
            </Button>,
            <Button
              type="primary"
              onClick={() => props.handleSubmit()}
              style={{
                width: '180px',
                borderRadius: '5px',
                backgroundColor: '#118E88',
                borderColor: '#118E88',
              }}
            >
              Adicionar
            </Button>,
          ]}
        >
          <>
            <div
              style={{
                marginRight: firstChange ? '1rem' : '0.5rem',
                display: secondChange ? 'block' : 'flex',
                flexDirection: secondChange ? 'row' : 'column',
                marginBottom: '1rem',
              }}
            >
              <Radio.Group
                value={choosePatient}
                onChange={e => setChoosePatient(e.target.value)}
              >
                <Radio.Button
                  value="professional"
                  style={
                    choosePatient === 'professional'
                      ? S.AntdStyles.RadioButtonActiveAble
                      : S.AntdStyles.RadioButtonActiveDisable
                  }
                >
                  Profissional de Saúde
                </Radio.Button>
                <Radio.Button
                  value="receptionist"
                  style={
                    choosePatient === 'receptionist'
                      ? S.AntdStyles.RadioButtonArchivedAble
                      : S.AntdStyles.RadioButtonArchivedDisable
                  }
                >
                  Recepcionista
                </Radio.Button>
              </Radio.Group>
            </div>
            <S.TitlePage>Informações Gerais</S.TitlePage>
            {choosePatient === 'professional' ? (
              <>
                <Row gutter={[32, 16]}>
                  <Col className="gutter-row" span={firstChange ? 8 : 24}>
                    <S.LabelText className="input-label">
                      Nome
                      <Input
                        style={S.AntdStyles.marginTop}
                        id="name"
                        name="name"
                        type="text"
                        onChange={props.handleChange}
                        value={props.values.name}
                        onBlur={props.handleBlur}
                      />
                    </S.LabelText>
                    {props.touched.name && props.errors.name ? (
                      <S.ErrorMessage>{props.errors.name}</S.ErrorMessage>
                    ) : null}
                  </Col>

                  <Col className="gutter-row" span={firstChange ? 8 : 24}>
                    <S.LabelText className="input-label">
                      E-mail
                      <Input
                        style={S.AntdStyles.marginTop}
                        id="email"
                        name="email"
                        type="text"
                        onChange={props.handleChange}
                        value={props.values.email}
                        onBlur={props.handleBlur}
                      />
                      {props.touched.email && props.errors.email ? (
                        <S.ErrorMessage>{props.errors.email}</S.ErrorMessage>
                      ) : null}
                    </S.LabelText>
                  </Col>
                  <Col className="gutter-row" span={firstChange ? 8 : 24}>
                    <S.LabelText className="input-label">
                      CPF ou CNPJ
                      <InputMask
                        mask={maskBuilderCPFCNPJ(props.values.document)}
                        style={S.AntdStyles.inputMask}
                        value={props.values.document}
                        onChange={e =>
                          props.setFieldValue(
                            'document',
                            e.target.value.replace(/\D/g, ''),
                          )
                        }
                      />
                    </S.LabelText>
                  </Col>

                  <Col className="gutter-row" span={firstChange ? 8 : 24}>
                    <S.LabelText className="input-label">
                      Telefone
                      <InputMask
                        mask="(99)99999-9999"
                        defaultValue={props.values.cellphone}
                        style={S.AntdStyles.inputMask}
                        onChange={e =>
                          props.setFieldValue('cellphone', e.target.value)
                        }
                      />
                    </S.LabelText>
                  </Col>
                  <Col className="gutter-row" span={firstChange ? 8 : 24}>
                    <S.LabelText className="input-label">
                      Profissão
                      <AutoComplete
                        style={S.AntdStyles.AutoComplete}
                        defaultValue={props.values.profession}
                        onChange={text =>
                          props.setFieldValue('profession', text)
                        }
                        options={PropsProfessionArrayWithoutOther.map(
                          item =>
                            item && {
                              value: professionTranslated(
                                PropsProfessionEnum[item],
                              ),
                            },
                        )}
                      />
                      {props.touched.profession && props.errors.profession ? (
                        <S.ErrorMessage>
                          {props.errors.profession}
                        </S.ErrorMessage>
                      ) : null}
                      {props.touched.profession && props.errors.profession ? (
                        <S.ErrorMessage>
                          {props.errors.profession}
                        </S.ErrorMessage>
                      ) : null}
                    </S.LabelText>
                  </Col>
                  {props.values.profession === 'OTHERPROFESSION' ? (
                    <Col className="gutter-row" span={firstChange ? 8 : 24}>
                      <S.LabelText className="input-label">
                        Outra Área
                        <Input
                          placeholder="Qual?"
                          onChange={event =>
                            props.setFieldValue(
                              'otherProfession',
                              event.target.value,
                            )
                          }
                          value={props.values.otherProfession}
                          style={{ marginTop: '4px' }}
                        />
                      </S.LabelText>
                    </Col>
                  ) : null}

                  <Col className="gutter-row" span={firstChange ? 24 : 24}>
                    <S.LabelText className="input-label">
                      Especialidade
                      <Checkbox.Group
                        style={{ width: '100%' }}
                        onChange={checkedValues =>
                          props.setFieldValue('speciality', checkedValues)
                        }
                      >
                        <S.DivMarginTop>
                          <Row>
                            {PropsSpecialityArray.map(
                              item =>
                                item && (
                                  <Col span={12}>
                                    <Checkbox
                                      value={`${specialityTranslated(
                                        PropsSpecialityEnum[item],
                                      )}`}
                                    >
                                      {specialityTranslated(
                                        PropsSpecialityEnum[item],
                                      )}
                                    </Checkbox>
                                  </Col>
                                ),
                            )}
                          </Row>
                        </S.DivMarginTop>
                      </Checkbox.Group>
                      {props.touched.speciality && props.errors.speciality ? (
                        <S.ErrorMessage>
                          {props.errors.speciality}
                        </S.ErrorMessage>
                      ) : null}
                      {props.values.speciality.find(
                        item =>
                          item ===
                          specialityTranslated(PropsSpecialityEnum.OTHER),
                      ) ? (
                        <Input
                          placeholder="Qual?"
                          onChange={event =>
                            props.setFieldValue(
                              'otherSpeciality',
                              event.target.value,
                            )
                          }
                          value={props.values.otherSpeciality}
                          style={{
                            marginTop: '10px',
                            width: '33%',
                            alignSelf: 'flex-end',
                            marginRight: '17%',
                          }}
                        />
                      ) : null}
                    </S.LabelText>
                  </Col>
                </Row>
                <br />
                <S.TitlePage>Endereço</S.TitlePage>

                <Row gutter={[32, 16]}>
                  <Col className="gutter-row" span={firstChange ? 16 : 24}>
                    <S.LabelText className="input-label">
                      Rua
                      <Input
                        style={S.AntdStyles.marginTop}
                        id="street"
                        name="street"
                        type="text"
                        onChange={props.handleChange}
                        value={props.values.street}
                        onBlur={props.handleBlur}
                      />
                    </S.LabelText>
                  </Col>

                  <Col className="gutter-row" span={firstChange ? 8 : 24}>
                    <S.LabelText className="input-label">
                      Número
                      <InputNumber
                        min={0}
                        style={S.AntdStyles.inputNumber}
                        defaultValue={props.values.houseNumber}
                        onChange={value =>
                          props.setFieldValue('houseNumber', value)
                        }
                      />
                    </S.LabelText>
                  </Col>

                  <Col className="gutter-row" span={firstChange ? 16 : 24}>
                    <S.LabelText className="input-label">
                      Complemento
                      <Input
                        style={S.AntdStyles.marginTop}
                        id="complement"
                        name="complement"
                        type="text"
                        onChange={props.handleChange}
                        value={props.values.complement}
                        onBlur={props.handleBlur}
                      />
                    </S.LabelText>
                  </Col>

                  <Col className="gutter-row" span={firstChange ? 8 : 24}>
                    <S.LabelText className="input-label">
                      Bairro
                      <Input
                        style={S.AntdStyles.marginTop}
                        id="neighborhood"
                        name="neighborhood"
                        type="text"
                        onChange={props.handleChange}
                        value={props.values.neighborhood}
                        onBlur={props.handleBlur}
                      />
                    </S.LabelText>
                  </Col>

                  <Col className="gutter-row" span={firstChange ? 8 : 24}>
                    <S.LabelText className="input-label">
                      Cidade
                      <Input
                        style={S.AntdStyles.marginTop}
                        id="city"
                        name="city"
                        type="text"
                        onChange={props.handleChange}
                        value={props.values.city}
                        onBlur={props.handleBlur}
                      />
                    </S.LabelText>
                  </Col>

                  <Col className="gutter-row" span={firstChange ? 8 : 24}>
                    <S.LabelText className="input-label">
                      Estado
                      <Dropdown
                        overlay={
                          <Menu style={S.AntdStyles.menuStates}>
                            {StatesOfBrazil.map(item => (
                              <Menu.Item
                                onClick={() =>
                                  props.setFieldValue('state', item)
                                }
                              >
                                {item}
                              </Menu.Item>
                            ))}
                          </Menu>
                        }
                      >
                        <Button style={S.AntdStyles.ButtonTypePayment}>
                          {props.values.state}
                        </Button>
                      </Dropdown>
                    </S.LabelText>
                  </Col>

                  <Col className="gutter-row" span={firstChange ? 8 : 24}>
                    <S.LabelText className="input-label">
                      CEP
                      <InputMask
                        mask="99999-999"
                        defaultValue={props.values.postalCode}
                        style={S.AntdStyles.inputMask}
                        onChange={e =>
                          props.setFieldValue('postalCode', e.target.value)
                        }
                      />
                    </S.LabelText>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row gutter={[32, 16]}>
                  <Col className="gutter-row" span={firstChange ? 8 : 24}>
                    <S.LabelText className="input-label">
                      Nome
                      <Input
                        style={S.AntdStyles.marginTop}
                        id="name"
                        name="name"
                        type="text"
                        onChange={props.handleChange}
                        value={props.values.name}
                        onBlur={props.handleBlur}
                      />
                    </S.LabelText>
                    {props.touched.name && props.errors.name ? (
                      <S.ErrorMessage>{props.errors.name}</S.ErrorMessage>
                    ) : null}
                  </Col>

                  <Col className="gutter-row" span={firstChange ? 8 : 24}>
                    <S.LabelText className="input-label">
                      E-mail
                      <Input
                        style={S.AntdStyles.marginTop}
                        id="email"
                        name="email"
                        type="text"
                        onChange={props.handleChange}
                        value={props.values.email}
                        onBlur={props.handleBlur}
                      />
                      {props.touched.email && props.errors.email ? (
                        <S.ErrorMessage>{props.errors.email}</S.ErrorMessage>
                      ) : null}
                    </S.LabelText>
                  </Col>
                  <Col className="gutter-row" span={firstChange ? 8 : 24}>
                    <S.LabelText className="input-label">
                      CPF ou CNPJ
                      <InputMask
                        mask={maskBuilderCPFCNPJ(props.values.document)}
                        style={S.AntdStyles.inputMask}
                        value={props.values.document}
                        onChange={e =>
                          props.setFieldValue(
                            'document',
                            e.target.value.replace(/\D/g, ''),
                          )
                        }
                      />
                    </S.LabelText>
                  </Col>
                </Row>
              </>
            )}
          </>
        </Modal>
      )}
    </Formik>
  );
}
