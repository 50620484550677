export function setVideoCallStatus(id, type) {
  return {
    type: '@video_call/SET_VIDEO_CALL_STATUS',
    payload: { id, type },
  };
}

export function setStatus(type) {
  return {
    type: '@video_call/SET_STATUS',
    payload: { type },
  };
}

export function getVideoCallRelatedToPatient(patientId) {
  return {
    type: '@video_call/GET_VIDEO_CALL_RELATED',
    payload: { patientId },
  };
}

export function getVideoCall() {
  return {
    type: '@video_call/GET_VIDEO_CALL',
  };
}

export function setVideoCall(videoCall) {
  return {
    type: '@video_call/SET_VIDEO_CALL',
    payload: { videoCall },
  };
}

export function setInfoDayEvent(dayEventInfo) {
  return {
    type: '@video_call/SET_INFO_DAY_EVENT',
    payload: { dayEventInfo },
  };
}

export function callNothing(id) {
  return {
    type: '@video_call/CALL_NOTHING',
    payload: { id },
  };
}

export function sendCurrentVideoToPatient(patientId, sessionId, currentVideo) {
  return {
    type: '@video_call/SEND_VIDEO_TO_PATIENT',
    payload: { patientId, sessionId, currentVideo },
  };
}

export function setCurrentVideo(currentVideo) {
  return {
    type: '@video_call/SET_CURRENT_VIDEO',
    payload: { currentVideo },
  };
}

export function getInliveSessionData(patientId, event_info) {
  return {
    type: '@video_call/GET_IN_LIVE_SESSION_DATA',
    payload: { patientId, event_info },
  };
}

export function setInliveSessionData(in_live_session) {
  return {
    type: '@video_call/SET_IN_LIVE_SESSION_DATA',
    payload: { in_live_session },
  };
}

export function callEndVideoCall(channel, reason) {
  return {
    type: '@video_call/CALL_END_VIDEO_CALL',
    payload: { channel, reason },
  };
}

export function selectedScreen(screen) {
  return {
    type: '@video_call/SET_SCREEN_SELECTED',
    payload: { screen },
  };
}

export function saveNote(note) {
  return {
    type: '@video_call/SAVE_NOTE',
    payload: { note },
  };
}

export function saveNoteTime(last_time_saved_note) {
  return {
    type: '@video_call/SAVE_TIME_NOTE',
    payload: { last_time_saved_note },
  };
}

export function saveInLiveSessionData(pat_id, sess_id, evals, note, eventId) {
  return {
    type: '@video_call/SAVE_SESSION_DATA',
    payload: { pat_id, sess_id, evals, note, eventId },
  };
}

export function updateInLiveSessionData(
  pat_id,
  sess_id,
  evals,
  note,
  eventId,
  isFinished,
) {
  return {
    type: '@video_call/UPDATE_SESSION_DATA',
    payload: { pat_id, sess_id, evals, note, eventId, isFinished },
  };
}

export function setCurrentEvals(evals) {
  return {
    type: '@video_call/SET_CURRENT_EVALS',
    payload: { evals },
  };
}

export function stopShowVideo(patientId) {
  return {
    type: '@video_call/STOP_SHOW_VIDEO',
    payload: { patientId },
  };
}

export function getVideocallsUsed() {
  return {
    type: '@video_call/GET_VIDEOCALLS_USED',
  };
}

export function setVideocallsUsed(videoCallsUsed) {
  return {
    type: '@video_call/SET_VIDEOCALLS_USED',
    payload: { videoCallsUsed },
  };
}
