import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 225px;
  height: 160px;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
`;

export const TouchScreen = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 5;
  cursor: pointer;
  background: #000000;
  opacity: 0.5;
`;

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 350px;
`;

export const ContainerEditedDescription = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 44px;
  z-index: 6;
  background: white;
  padding-left: 10px;

  label {
    font: normal normal normal 14px/16px Nunito Sans;
  }
`;

export const AntdStyles = {
  iconClose: { marginRight: 8, width: 15, marginBottom: 4 },
};
