import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Button, InputNumber, Col } from 'antd';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateEvaluation } from 'store/modules/evaluations/actions';
import SavedAnswerComponent from 'components/SavedAnswer';

import * as S from './styles';

function SixMinWalk({ title, current_eval }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const qts = current_eval?.content?.questions;

  const Question = 'Distância percorrida em metros:';

  const [formValues, setFormValues] = useState(
    current_eval?.content?.questions.length > 0
      ? [
          {
            index: 1,
            text: Question,
            value: parseInt(current_eval.content.questions[0].value, 10),
          },
        ]
      : [
          {
            index: 1,
            text: Question,
            value: 0,
          },
        ],
  );

  function updateValues(isFinal) {
    const tempFormValue = {
      id: current_eval?.id,
      isFinished: isFinal,
      name: title,
      professionalId: current_eval?.professionalId,
      result: null,
      content: {
        questions: formValues,
      },
    };
    dispatch(updateEvaluation(tempFormValue, id));
    if (isFinal) {
      setFormValues([
        {
          index: 1,
          text: Question,
          value: 0,
        },
      ]);
    }
  }

  useEffect(() => {
    const valuesFormValues = Object.values(formValues);
    if (valuesFormValues.find(item => item.value !== 0)) {
      updateValues(false);
    }
  }, [formValues]);

  return (
    <Formik
      initialValues={{
        Answer1: qts[0] !== undefined ? qts[0].value : '',
      }}
      onSubmit={(values, actions) => {
        // eslint-disable-next-line prefer-const
        updateValues(true);

        let valuesSubmit = {
          name: title,
          content: {
            questions: values,
          },
        };
        valuesSubmit = null;

        actions.resetForm({
          values: {
            Answer1: '',
          },
        });

        if (valuesSubmit !== null);
        // values[`Answer${index + 1}`]
      }}
    >
      {props => (
        <>
          <S.ContainerQuestionSixMinWalk>
            <Col style={{ marginBottom: '1.5rem' }}>
              <S.LabelText>{Question}</S.LabelText>
              <InputNumber
                min={0}
                style={S.AntdStyles.inputNumber}
                defaultValue={formValues[0].value}
                onChange={e => {
                  props.setFieldValue('Answer1', e);
                  setFormValues([
                    {
                      index: 0,
                      text: Question,
                      value: e,
                    },
                  ]);
                }}
              />
              <SavedAnswerComponent
                style={{ marginLeft: '4px' }}
                resource="hasSpecializedEvaluation"
                propsFormik={props}
                fieldName="Answer1"
              />
            </Col>
          </S.ContainerQuestionSixMinWalk>
          <Button
            type="primary"
            htmlType="submit"
            onClick={props.handleSubmit}
            style={S.AntdStyles.ButtonSave}
          >
            Salvar
          </Button>
        </>
      )}
    </Formik>
  );
}

export default SixMinWalk;
