import { useState } from 'react';
import { Typography, Avatar } from 'antd';
import 'antd/dist/antd.less';
import './PatientController.css';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import {
  sendCodeToPatient,
  archivePatient,
} from 'store/modules/patient/actions';

import { nameHandler } from 'utils/NameHandler';
import { ModalSendCode } from './components/ModalSendCode';

import * as S from './style';

const { Text } = Typography;

function PatientController() {
  const infoDetails = useSelector(state => state.clinic.infoDetails);
  const profile_active = useSelector(
    state => state.auth?.user?.active_profile_name,
  );
  const [modalSendCode, setModalSendCode] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  function handleCancel() {
    setModalSendCode(false);
  }

  function handleOk() {
    setModalSendCode(false);
    dispatch(sendCodeToPatient(id));
  }

  function handleName(name) {
    if (name.length > 37) {
      return `${name.substring(0, 38)}...`;
    }
    return name;
  }

  return (
    <div className="container-patient-controller">
      <div className="container-white-background">
        <div className="container-patient-info">
          {infoDetails !== null && infoDetails.photo !== null && (
            <Avatar src={infoDetails?.photo} style={S.AntdStyles.AvatarUser} />
          )}
          {infoDetails !== null && infoDetails.photo === null && (
            <Avatar style={S.AntdStyles.AvatarUser} size={50}>
              {infoDetails?.photo === null && nameHandler(infoDetails.name)}
            </Avatar>
          )}
          <Text style={{ width: 155 }}>
            {infoDetails !== null
              ? handleName(infoDetails.name)
              : 'Carregando...'}
          </Text>
        </div>
        <div className="container-patient-controller-btns">
          {profile_active === 'RECEPTIONIST' && (
            <Text
              style={S.AntdStyles.linkTexts}
              onClick={() => history.push('/chat')}
            >
              Enviar Mensagem
            </Text>
          )}
          <Text
            style={S.AntdStyles.linkTexts}
            onClick={() => setModalSendCode(true)}
          >
            Enviar código de acesso
          </Text>
          <Text
            style={S.AntdStyles.linkTexts}
            onClick={() => {
              const patientsID = [id];
              dispatch(archivePatient(patientsID));
            }}
          >
            Arquivar
          </Text>
        </div>
        <ModalSendCode
          handleOk={handleOk}
          handleCancel={handleCancel}
          isModalVisible={modalSendCode}
        />
      </div>
    </div>
  );
}

export default PatientController;
