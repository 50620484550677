import { Layout, Divider } from 'antd';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';

import AppHeader from '../../../components/Header';
import PhotoAndClinic from './components/PhotoAndClinic';
import DataGeneral from './components/DataGeneral';
import DataPassword from './components/DataPassword';

import 'antd/dist/antd.less';
import './SiderMenu.css';

function MyAccount() {
  const isBiggerThan = useMediaQuery('(min-width: 990px)');
  const firstChange = useMediaQuery('(min-width: 900px)');
  const secondChange = useMediaQuery('(min-width: 750px)');
  const { Content, Sider } = Layout;

  return (
    <Layout>
      <AppHeader />
      <Content
        style={{
          display: 'flex',
          height: '100%',
          width: '100%',
          paddingTop: isBiggerThan ? '7%' : '10%',
          flexDirection: 'row',
          justifyContent: 'center',
          padding: secondChange ? '7rem 7rem 0 7rem' : '1rem 1rem',
        }}
      >
        <div
          className="container-app-content"
          style={{ flexDirection: firstChange ? 'row' : 'column' }}
        >
          <Sider
            style={{
              flexDirection: 'column',
              backgroundColor: 'var(--guyabano)',
              height: '80%',
              display: firstChange ? 'flex' : 'none',
            }}
          >
            <PhotoAndClinic />
          </Sider>
          {firstChange === false && (
            <PhotoAndClinic secondChange={secondChange} />
          )}
          <div className="container-patient-content">
            <div>
              <DataGeneral secondChange={secondChange} />
            </div>

            <Divider />

            <DataPassword secondChange={secondChange} />
          </div>
        </div>
      </Content>
    </Layout>
  );
}

export default MyAccount;
