export function getContentVideos() {
  return {
    type: '@professional_content/GET_CONTENT_VIDEOS',
  };
}

export function setContentVideos(videos) {
  return {
    type: '@professional_content/SET_CONTENT_VIDEOS',
    payload: { videos },
  };
}
