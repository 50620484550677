import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
  min-height: 200px;
  background: #ffff;
  align-items: center;
  position: relative;

  @media (min-width: 770px) {
    margin-bottom: 0;
  }
  @media (max-width: 770px) {
    margin-bottom: 50px;
  }
`;

export const ContainerDivider = styled.div`
  width: 90%;
`;

export const ContainerPlanTitle = styled.div`
  display: flex;
  width: 95%;
  padding: 15px 10px 0px 10px;

  strong {
    text-align: left;
    font: normal normal bold 16px/19px Nunito Sans;

    &.plan-name {
      color: var(--darkCyan);
    }

    &.plan-price {
      color: var(--davysGrey);
      margin-left: auto;
    }

    &.big-plan-price {
      color: var(--davysGrey);
      margin-left: 4%;
    }
  }

  label {
    text-align: left;
    font: normal normal normal 16px/19px Nunito Sans;
  }

  img {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }
`;

export const ContainerPlanOferts = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-bottom: 10px;

  label {
    text-align: left;
    font: normal normal normal 14px/16px Nunito Sans;
  }

  strong {
    color: #fff;

    &.this-is-best-plan {
      margin-top: 10px;
      font: normal normal bold 14px/16px Nunito Sans;
    }
  }
`;

export const ContainerPlanSubTitles = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;

  strong {
    text-align: left;
    font: normal normal bold 16px/19px Nunito Sans;

    color: var(--davysGrey);
  }

  img {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  width: 100%;
  position: absolute;
  bottom: 10px;
  margin-top: auto;
`;

export const AntdStyles = {
  ButtonChangePlan: {
    background: 'var(--darkCyan) 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    border: 'none',
    opacity: 1,
    width: '10rem',
  },
  Button2: {
    background: 'var(--darkCyan) 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    border: 'none',
    opacity: 1,
    width: '9rem',
    marginTop: '3rem',
    marginBottom: '1rem',
  },
};
