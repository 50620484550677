/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect } from 'react';
import {
  Radio,
  Button,
  Row,
  Col,
  DatePicker,
  InputNumber,
  TimePicker,
  Checkbox,
  Input,
  AutoComplete,
} from 'antd';
import { addEventAgenda } from 'store/modules/agenda/actions';

import * as Yup from 'yup';
import { Formik } from 'formik';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import moment from 'moment';
import 'moment/locale/pt-br';
import localePTDate from 'antd/lib/locale/pt_BR';
import './styles.css';

import { getProtocolsCreated } from 'store/modules/protocol/actions';
import { getTime, format } from 'date-fns';
import * as S from './styles';
import ProtocolList from './components/ProtocolList';

export function AddEventAgenda() {
  const { id } = useParams();

  const { TextArea } = Input;
  const history = useHistory();

  const dispatch = useDispatch();

  const firstChange = useMediaQuery('(min-width: 900px)');
  const secondChange = useMediaQuery('(min-width: 770px)');
  const practiceId = useSelector(state => state.agenda.practiceId);
  const addressUser = useSelector(state => state.auth.user.address);
  const addressPatient = useSelector(
    state => state.patient.patientDetails.address,
  );
  const patient = useSelector(state => state.patient.patientDetails.id);
  const optionsWeek = [
    { label: 'Segundas', value: 1 },
    { label: 'Terças', value: 2 },
    { label: 'Quartas', value: 3 },
    { label: 'Quintas', value: 4 },
    { label: 'Sextas', value: 5 },
    { label: 'Sábados', value: 6 },
    { label: 'Domingos', value: 7 },
  ];

  useEffect(() => {
    dispatch(getProtocolsCreated(id));
  }, []);

  const options = [
    {
      value: `Na clínica: ${addressUser.city}, ${addressUser.street}, ${addressUser.complement}`,
    },
    {
      value: `Na residência do paciente: ${addressPatient.city}, ${addressPatient.street} ,${addressPatient.complement}`,
    },
  ];

  const placeholderAddress = `Na clínica: ${addressUser.city}, ${addressUser.street}, ${addressUser.complement}`;

  return (
    <>
      <div style={{ marginLeft: firstChange ? '2rem' : '4rem' }}>
        <S.TitlePage>Adicionar Evento</S.TitlePage>
        <Formik
          initialValues={{
            title: 'Presencial',
            start: new Date(),
            numberOfWeeks: 1,
            hour: '',
            daysOfWeek: [],
            manyTimes: 'Uma vez',
            details: '',
            duration: 0,
            protocolId: '',
            local: '',
          }}
          validationSchema={Yup.object({
            title: Yup.string().required('É obrigatório'),
            start: Yup.string().required('É obrigatório'),
            daysOfWeek: Yup.array().required('É obrigatório'),
            manyTimes: Yup.string().required('É obrigatório'),
            hour: Yup.string(),
          })}
          onSubmit={(values, { setErrors }) => {
            if (values.start instanceof Object) {
              values.start = format(values.start, 'yyyy-MM-dd');
            }

            if (values.title === 'Domiciliar') {
              values.hour = moment('12:00', 'HH:mm').format('HH:mm');
            }

            if (values.start === 'Data inválida') {
              setErrors({ start: 'Data inválida' });
            }
            if (
              (values.hour === 'Data inválida' || values.hour === '') &&
              values.title !== 'Domiciliar'
            ) {
              setErrors({ hour: 'Horário inválido' });
              return;
            }
            if (practiceId === '' && values.title === 'Domiciliar') {
              setErrors({
                protocolId: 'Selecione uma prática',
              });
              return;
            }

            if (values.local === '' && values.title === 'Presencial') {
              setErrors({
                local: 'Endereço inválido',
              });
              return;
            }
            if (values.manyTimes === 'Semanal' && values.numberOfWeeks === 0) {
              setErrors({
                numberOfWeeks: 'Insira quantidade de semanas',
              });
              return;
            }
            if (values.duration === 0 && values.title !== 'Domiciliar') {
              setErrors({
                duration: 'Insira a duração do evento',
              });
              return;
            }
            if (
              values.manyTimes === 'Semanal' &&
              values.daysOfWeek.length === 0
            ) {
              setErrors({
                daysOfWeek:
                  'Selecione o(s) dia(s) da semana da sua recorrência',
              });
              return;
            }

            let timestamp = `${values.start}T${values.hour}:00`;
            timestamp = getTime(new Date(timestamp));
            values.timestamp = timestamp.toString();
            values.protocolId = practiceId;
            values.patientId = patient;

            dispatch(addEventAgenda(values, history));
          }}
        >
          {props => (
            <>
              <Row gutter={[32, 16]}>
                <Col span={24}>
                  <S.DivCol>
                    <Radio.Group
                      value={props.values.title}
                      onChange={e =>
                        props.setFieldValue('title', e.target.value)
                      }
                    >
                      <Radio value="Domiciliar">Prática Domiciliar</Radio>
                      <Radio value="Telechamada">Sessão via Telechamada</Radio>
                      <Radio value="Presencial">Sessão Presencial</Radio>
                    </Radio.Group>
                    {props.touched.title && props.errors.title ? (
                      <S.ErrorMessage>{props.errors.title}</S.ErrorMessage>
                    ) : null}
                  </S.DivCol>
                </Col>
                <Col span={24}>
                  <S.DivCol>
                    <S.LabelText className="input-label">
                      Recorrência
                    </S.LabelText>
                    <Radio.Group
                      value={props.values.manyTimes}
                      onChange={e =>
                        props.setFieldValue('manyTimes', e.target.value)
                      }
                    >
                      <Radio value="Uma vez">Uma vez</Radio>
                      <Radio value="Semanal">Semanal</Radio>
                    </Radio.Group>
                    {props.touched.manyTimes && props.errors.manyTimes ? (
                      <S.ErrorMessage>{props.errors.manyTimes}</S.ErrorMessage>
                    ) : null}
                  </S.DivCol>
                </Col>
                <Col span={secondChange ? 8 : 24}>
                  <S.LabelText className="input-label">
                    Data de Início
                    <DatePicker
                      style={S.AntdStyles.marginTop}
                      locale={localePTDate}
                      format="DD-MM-YYYY"
                      value={moment(props.values.start)}
                      allowClear={false}
                      onChange={(_, dateString) => {
                        props.setFieldValue(
                          'start',
                          moment(dateString, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                        );
                      }}
                    />
                  </S.LabelText>
                  {props.touched.start && props.errors.start ? (
                    <S.ErrorMessage>{props.errors.start}</S.ErrorMessage>
                  ) : null}
                </Col>
                {props.values.manyTimes === 'Uma vez' ? (
                  <></>
                ) : (
                  <Col span={secondChange ? 8 : 24}>
                    <S.LabelText className="input-label">
                      Duração em Semanas
                      <InputNumber
                        min={0}
                        style={S.AntdStyles.inputNumber}
                        defaultValue={0}
                        onChange={value =>
                          props.setFieldValue('numberOfWeeks', value)
                        }
                      />
                    </S.LabelText>
                    {props.touched.numberOfWeeks &&
                    props.errors.numberOfWeeks ? (
                      <S.ErrorMessage>
                        {props.errors.numberOfWeeks}
                      </S.ErrorMessage>
                    ) : null}
                  </Col>
                )}

                {props.values.title === 'Domiciliar' ? (
                  <></>
                ) : (
                  <Col span={secondChange ? 8 : 24}>
                    <S.LabelText className="input-label">
                      Duração do evento em Minutos
                      <InputNumber
                        min={0}
                        style={S.AntdStyles.inputNumber}
                        defaultValue={0}
                        onChange={value =>
                          props.setFieldValue('duration', value)
                        }
                      />
                    </S.LabelText>
                    {props.touched.duration && props.errors.duration ? (
                      <S.ErrorMessage>{props.errors.duration}</S.ErrorMessage>
                    ) : null}
                  </Col>
                )}
                {props.values.title === 'Domiciliar' ? (
                  <></>
                ) : (
                  <Col span={secondChange ? 8 : 24}>
                    <S.LabelText className="input-label">
                      Horário
                      <TimePicker
                        style={S.AntdStyles.marginTop}
                        locale={localePTDate}
                        showTime={{ format: 'HH:mm' }}
                        format="HH:mm"
                        onChange={(_, hourString) => {
                          props.setFieldValue(
                            'hour',
                            moment(hourString, 'HH:mm').format('HH:mm'),
                          );
                        }}
                      />
                    </S.LabelText>
                    {props.touched.hour && props.errors.hour ? (
                      <S.ErrorMessage>{props.errors.hour}</S.ErrorMessage>
                    ) : null}
                  </Col>
                )}
                {props.values.manyTimes === 'Uma vez' ? (
                  <></>
                ) : (
                  <Col span={24}>
                    <S.LabelText className="input-label">
                      Dias da Semana
                      <Checkbox.Group
                        style={S.AntdStyles.marginTop}
                        options={optionsWeek}
                        onChange={checkedValues =>
                          props.setFieldValue('daysOfWeek', checkedValues)
                        }
                      />
                    </S.LabelText>
                    {props.touched.daysOfWeek && props.errors.daysOfWeek ? (
                      <S.ErrorMessage>{props.errors.daysOfWeek}</S.ErrorMessage>
                    ) : null}
                  </Col>
                )}

                <Col span={24} style={{ marginBottom: '0.4rem' }}>
                  <S.LabelText className="input-label">
                    Detalhes
                    <TextArea
                      rows={3}
                      style={{ marginTop: '4px' }}
                      onChange={event =>
                        props.setFieldValue('details', event.target.value)
                      }
                    />
                  </S.LabelText>
                </Col>
              </Row>
              <Col span={24}>
                <S.LabelText className="input-label">
                  Inserir Prática
                </S.LabelText>
                <ProtocolList
                  visOpt={
                    props.values.title === 'Telechamada'
                      ? 'INLIVE'
                      : props.values.title === 'Domiciliar'
                      ? 'DOMESTIC'
                      : 'ALL'
                  }
                />

                {props.touched.protocolId && props.errors.protocolId ? (
                  <S.ErrorMessage>{props.errors.protocolId}</S.ErrorMessage>
                ) : null}
              </Col>
              {props.values.title === 'Presencial' ? (
                <>
                  <Col span={24} style={{ marginBottom: '1rem' }}>
                    <S.LabelText className="input-label">Local</S.LabelText>
                    <AutoComplete
                      style={{
                        width: '100%',
                        height: 30,
                      }}
                      options={options}
                      placeholder={placeholderAddress}
                      filterOption={(inputValue, option) =>
                        option.value
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      }
                      onChange={e => {
                        props.setFieldValue('local', e);
                      }}
                    />
                  </Col>
                  <Col>
                    {props.touched.local && props.errors.local ? (
                      <S.ErrorMessage>{props.errors.local}</S.ErrorMessage>
                    ) : null}
                  </Col>
                </>
              ) : (
                <></>
              )}

              <Button
                type="primary"
                htmlType="submit"
                onClick={() => history.push(`/${id}/agenda`)}
                style={{ ...S.AntdStyles.ButtonSave, marginRight: '2rem' }}
              >
                Voltar
              </Button>

              <Button
                type="primary"
                htmlType="submit"
                onClick={props.handleSubmit}
                style={S.AntdStyles.ButtonSave}
              >
                Criar Evento
              </Button>
            </>
          )}
        </Formik>
      </div>
    </>
  );
}
