/* eslint-disable no-unused-vars */
import { Switch, BrowserRouter, Redirect, Route } from 'react-router-dom';
import { useHistory } from 'react-router';

import { useSelector } from 'react-redux';

import InfoEdit from 'pages/Admin/InfoEdit';
import { PropsProfileEnum } from 'utils/profile';
import Login from '../pages/Authentication';

import PatientList from '../pages/Professional/PatientList';
import PatientEdit from '../pages/Professional/PatientEdit';
import Patients from '../pages/Professional/Patients';
import MyAccountProfessional from '../pages/Professional/MyAccount';
import Anamnesis from '../pages/Professional/Anamnesis';
import Goals from '../pages/Professional/Goals';
import Agenda from '../pages/Professional/Agenda';
import ProfessionalAgenda from '../pages/Professional/ProfessionalAgenda';
import AddEventAgenda from '../pages/Professional/AddEventAgenda';
import Evaluations from '../pages/Professional/Evaluations';
import ComputerVision from '../pages/Professional/ComputerVision';
import Protocol from '../pages/Professional/PatientProtocol';
import Chat from '../pages/shared/Chat';
import Monitoring from '../pages/Professional/PatientMonitoring';
import VideoCall from '../pages/Professional/VideoCall';
import ProfessionalContent from '../pages/Professional/ProfessionalContent';
import Subscription from '../pages/shared/Subscription';
import Notifications from '../pages/Professional/Notifications';
import { TelemonitoringRoute } from './TelemonitoringRoute';
import { Maintenance } from '../pages/Maintenance';

import PatientListAdmin from '../pages/Admin/PatientListAdmin';
import NotVinculatedToClinic from '../pages/Admin/NotVinculatedToClinic';
import MyAccountClinic from '../pages/Admin/MyAccount';
import ProfessionalList from '../pages/Admin/ProfessionalList';
import Consults from '../pages/Admin/Schedule';
import DataConsults from '../pages/Admin/DataConsults';

// const isMaintenance = false;

/*
function MaintenanceRoutes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route to="*" exact component={Maintenance} />
      </Switch>
    </BrowserRouter>
  );
} */

function AuthRoutes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="*" exact component={Login} />
      </Switch>
    </BrowserRouter>
  );
}

function AdminRoutes() {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <Redirect to="/patient_list_clinic" />
          </Route>
          <Route path="/patient_list_clinic" component={PatientListAdmin} />
          <Route path="/professional_list" component={ProfessionalList} />
          <Route path="/my_account_clinic" component={MyAccountClinic} />
          <Route path="/:id/edit_info" component={InfoEdit} />
          <Route path="/consults" component={Consults} />
          <Route path="/subscription" component={Subscription} />
          <Route path="/chat" component={Chat} />
          <Route path="/data_consults" component={DataConsults} />
          <Route path="/notifications" component={Notifications} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

function NotVinculated() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Redirect to="/not_vinculated" />
        </Route>
        <Route path="/not_vinculated" component={NotVinculatedToClinic} />
      </Switch>
    </BrowserRouter>
  );
}

function ProfessionalRoutes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Redirect to="/content" />
        </Route>
        <Route exact path="/patient_list" component={PatientList} />
        <Route path="/:id/protocol" component={Protocol} />
        <Route path="/:id/edit_info" component={PatientEdit} />
        <Route path="/:id/anamnesis" component={Anamnesis} />
        <Route path="/:id/goals" component={Goals} />
        <Route path="/:id/agenda/add_event" component={AddEventAgenda} />
        <Route path="/:id/agenda" component={Agenda} />
        <Route path="/:id/evaluations" component={Evaluations} />
        <Route path="/:id/monitoring" component={Monitoring} />
        <Route path="/:id/computer_vision" component={ComputerVision} />
        <Route path="/professional_agenda" component={ProfessionalAgenda} />
        <Route path="/content" component={ProfessionalContent} />
        <TelemonitoringRoute path="/:id/video_call" component={VideoCall} />
        <Route path="/subscription" component={Subscription} />
        <Route path="/my_account" component={MyAccountProfessional} />
        <Route path="/patient" component={Patients} />
        <Route path="/chat" component={Chat} />
        <Route path="/notifications" component={Notifications} />
      </Switch>
    </BrowserRouter>
  );
}

export default function Routes() {
  const signedIn = useSelector(state => state.auth.signed);
  const user = useSelector(state => state.auth.user);

  /*
  if (isMaintenance) {
    return MaintenanceRoutes();
  } */

  if (user === null) {
    return AuthRoutes();
  }

  if (user.profiles !== undefined) {
    const profileActive = user?.profiles?.find(
      item => item.id === user.active_profile_id,
    );

    if (signedIn && profileActive?.profile === PropsProfileEnum.THERAPIST) {
      return ProfessionalRoutes();
    }

    if (signedIn && profileActive?.profile !== PropsProfileEnum.THERAPIST) {
      if (profileActive?.clinicId !== null) {
        return AdminRoutes();
      }

      return NotVinculated();
    }
  }

  // deixa na tela de login pois pode ser um cadastro com o gmail
  // ou pode ta esperando o usuário ser logado
  return AuthRoutes();
}
