/* eslint-disable no-bitwise */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { Layout, Input, AutoComplete, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import { ExamsEnum, ExamsArray } from 'utils/exams';
import { useParams } from 'react-router-dom';

import { addNewEvaluation } from 'store/modules/evaluations/actions';

import AppHeader from 'components/Header';
import SiderMenu from 'components/Siders/Professional/Sider';
import ImgFilterActivities from 'assets/setting-protocol.svg';
import { getPatientDetails } from 'store/modules/patient/actions';
import LEFS from './componentsForms/LEFS';
import DASH from './componentsForms/DASH';
import EscalaFuncionalEscleroseLateral from './componentsForms/EscalaFuncionalEscleroseLateral';
import McGill from './componentsForms/McGill';
import ABC from './componentsForms/ABC';
import Barthel from './componentsForms/Barthel';
import HoehnEYahr from './componentsForms/HoehnEYahr';
import Berg from './componentsForms/Berg';
import FESI from './componentsForms/FES-I';
import MedidaIndependenciaFuncional from './componentsForms/MedidaIndependenciaFuncional';
import MiniExameEstadoMental from './componentsForms/MiniExameEstadoMental';
import SixMinWalk from './componentsForms/SixMinWalk';
import SitAndStand from './componentsForms/SitAndStand';
import TenMetersWalk from './componentsForms/TenMetersWalk';
import FuglMeyer from './componentsForms/FuglMeyer';
import MAL from './componentsForms/MAL';
import TFMJT from './componentsForms/TFMJT';
import TCB from './componentsForms/TCB';
import ModalFilter from './components/ModalFilter';
import * as S from './styles';

function Exams({ calledByVideoCall = false }) {
  const { Search } = Input;
  const { id } = useParams();
  const current_eval = useSelector(state => state.evaluations.curr_eval);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPatientDetails(id));
  }, []);

  const firstChange = useMediaQuery('(min-width: 900px)');
  const respAutoComp = useMediaQuery('(min-width: 630px)');

  const [exam, setExam] = useState(null);
  const [showExams, setShowExams] = useState(ExamsArray);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [optionsFilterApply, setOptionsFilterApply] = useState([]);

  function handleFindExam(text) {
    const foundExam = ExamsArray.find(item => item === text);

    if (foundExam) {
      dispatch(addNewEvaluation(foundExam, id));
      setExam(ExamsEnum[text]);
    }
  }

  useEffect(() => {
    // eslint-disable-next-line prefer-const
    let newExamFilter = [];

    ExamsArray.forEach(examItem => {
      const arrayMaster = ExamsEnum[examItem].categories;
      const arrayContains = optionsFilterApply;

      const isContains = arrayContains.every(
        element => arrayMaster.indexOf(element) > -1,
      );

      if (isContains) {
        newExamFilter.push(examItem);
      }
    });

    setShowExams(newExamFilter);
  }, [optionsFilterApply]);

  return (
    <Layout>
      {!calledByVideoCall && <AppHeader />}
      <SiderMenu calledByVideoCall={calledByVideoCall}>
        {showModalFilter && (
          <ModalFilter
            showModal={showModalFilter}
            setShowModal={setShowModalFilter}
            optionsFilterApply={optionsFilterApply}
            setOptionsFilterApply={setOptionsFilterApply}
          />
        )}

        <S.DivContainer firstChange={firstChange}>
          <S.TitlePage>Avaliações</S.TitlePage>
          {/* <Radio.Group
            value={choiceTypeExam}
            onChange={e => setChoiceTypeExam(e.target.value)}
            style={{ marginTop: '0.5rem', marginBottom: '2rem' }}
          >
            <Radio.Button
              value="Estruturas e Funções do corpo"
              style={
                choiceTypeExam === 'Estruturas e Funções do corpo'
                  ? S.AntdStyles.RadioButtonActiveAble
                  : S.AntdStyles.RadioButtonActiveDisable
              }
            >
              Estruturas e Funções do corpo
            </Radio.Button>
            <Radio.Button
              value="Atividade"
              style={
                choiceTypeExam === 'Atividade'
                  ? S.AntdStyles.RadioButtonMiddleArchivedAble
                  : S.AntdStyles.RadioButtonMiddleArchivedDisable
              }
            >
              Atividade
            </Radio.Button>
            <Radio.Button
              value="Participação"
              style={
                choiceTypeExam === 'Participação'
                  ? S.AntdStyles.RadioButtonArchivedAble
                  : S.AntdStyles.RadioButtonArchivedDisable
              }
            >
              Participação
            </Radio.Button>
          </Radio.Group> */}

          <S.DivCol>
            <Row style={{ alignItems: 'flex-end' }}>
              <S.LabelText className="input-label">
                Instrumento de Avaliação
                <AutoComplete
                  dropdownMatchSelectWidth={252}
                  allowClear={false}
                  style={{
                    marginTop: '4px',
                    width: respAutoComp ? '100%' : '70%',
                  }}
                  options={showExams.map(
                    item =>
                      item && {
                        value: item,
                      },
                  )}
                  filterOption={(inputValue, option) =>
                    option.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                  onSelect={value => handleFindExam(value)}
                  onChange={value => handleFindExam(value)}
                >
                  <Search
                    placeholder="Buscar"
                    onSearch={text => handleFindExam(text)}
                  />
                </AutoComplete>
              </S.LabelText>

              <S.DivFilter
                onClick={() => setShowModalFilter(true)}
                aria-hidden="true"
              >
                <S.ImageFilterActivities src={ImgFilterActivities} />
                <strong>Filtrar Avaliações</strong>
              </S.DivFilter>
            </Row>

            {exam !== null && exam.purpose !== null && (
              <>
                <div style={{ marginTop: '2rem' }}>
                  <S.TitleSession>Propósito</S.TitleSession>
                </div>
                {exam.purpose.split('\n').map(str => (
                  <S.PDescript>{str}</S.PDescript>
                ))}
              </>
            )}
            {exam !== null && exam.description !== null && (
              <>
                <div style={{ marginTop: '2rem' }}>
                  <S.TitleSession>Descrição</S.TitleSession>
                </div>
                {exam.description.split('\n').map(str => (
                  <S.PDescript>{str}</S.PDescript>
                ))}
              </>
            )}
            {exam !== null && (
              <div style={{ marginTop: '2rem' }}>
                <S.TitleSession>Formulário</S.TitleSession>
              </div>
            )}
            {exam !== null &&
              current_eval !== null &&
              current_eval?.name === 'LEFS - Funcional de Membros Inferiores' &&
              exam.title ===
                ExamsEnum['LEFS - Funcional de Membros Inferiores'].title && (
                <LEFS
                  title="LEFS - Funcional de Membros Inferiores"
                  current_eval={current_eval}
                />
              )}
            {exam !== null &&
              current_eval !== null &&
              current_eval?.name === 'DASH - Disfunção do braço, ombro e mão' &&
              exam.title ===
                ExamsEnum['DASH - Disfunção do braço, ombro e mão'].title && (
                <DASH
                  title="DASH - Disfunção do braço, ombro e mão"
                  current_eval={current_eval}
                />
              )}
            {exam !== null &&
              current_eval !== null &&
              current_eval?.name ===
                'Escala Funcional para Esclerose Lateral Amiotrófica Revisada' &&
              exam.title ===
                ExamsEnum[
                  'Escala Funcional para Esclerose Lateral Amiotrófica Revisada'
                ].title && (
                <EscalaFuncionalEscleroseLateral
                  title="Escala Funcional para Esclerose Lateral Amiotrófica Revisada"
                  current_eval={current_eval}
                />
              )}
            {exam !== null &&
              current_eval !== null &&
              current_eval?.name === 'Escala McGill para Detecção de Dor' &&
              exam.title ===
                ExamsEnum['Escala McGill para Detecção de Dor'].title && (
                <McGill
                  title="Escala McGill para Detecção de Dor"
                  current_eval={current_eval}
                />
              )}
            {exam !== null &&
              current_eval !== null &&
              current_eval?.name ===
                'ABC - Escala de Confiança de Equilíbrio' &&
              exam.title ===
                ExamsEnum['ABC - Escala de Confiança de Equilíbrio'].title && (
                <ABC
                  title="ABC - Escala de Confiança de Equilíbrio"
                  current_eval={current_eval}
                />
              )}
            {exam !== null &&
              current_eval !== null &&
              current_eval?.name === 'Índice de Barthel' &&
              exam.title === ExamsEnum['Índice de Barthel'].title && (
                <Barthel
                  title="Índice de Barthel"
                  current_eval={current_eval}
                />
              )}
            {exam !== null &&
              current_eval !== null &&
              current_eval?.name === 'Escala de equilíbrio Berg' &&
              exam.title === ExamsEnum['Escala de equilíbrio Berg'].title && (
                <Berg
                  title="Escala de equilíbrio Berg"
                  current_eval={current_eval}
                />
              )}
            {exam !== null &&
              current_eval !== null &&
              current_eval?.name === 'Estadiamento de Hoehn e Yahr' &&
              exam.title ===
                ExamsEnum['Estadiamento de Hoehn e Yahr'].title && (
                <HoehnEYahr
                  title="Estadiamento de Hoehn e Yahr"
                  current_eval={current_eval}
                />
              )}
            {exam !== null &&
              current_eval !== null &&
              current_eval?.name === 'FES-i - Escala de eficácia de quedas' &&
              exam.title ===
                ExamsEnum['FES-i - Escala de eficácia de quedas'].title && (
                <FESI
                  title="FES-i - Escala de eficácia de quedas"
                  current_eval={current_eval}
                />
              )}
            {exam !== null &&
              current_eval !== null &&
              current_eval?.name === 'Medida de Independência Funcional' &&
              exam.title ===
                ExamsEnum['Medida de Independência Funcional'].title && (
                <MedidaIndependenciaFuncional
                  title="Medida de Independência Funcional"
                  current_eval={current_eval}
                />
              )}
            {exam !== null &&
              current_eval !== null &&
              current_eval?.name === 'Mini Exame de Estado Mental' &&
              exam.title === ExamsEnum['Mini Exame de Estado Mental'].title && (
                <MiniExameEstadoMental
                  title="Mini Exame de Estado Mental"
                  current_eval={current_eval}
                />
              )}
            {exam !== null &&
              current_eval !== null &&
              current_eval?.name === 'Teste caminhada de 6 minutos' &&
              exam.title ===
                ExamsEnum['Teste caminhada de 6 minutos'].title && (
                <SixMinWalk
                  title="Teste caminhada de 6 minutos"
                  current_eval={current_eval}
                />
              )}
            {exam !== null &&
              current_eval !== null &&
              current_eval?.name === 'Teste caminhada de 10 metros' &&
              exam.title ===
                ExamsEnum['Teste caminhada de 10 metros'].title && (
                <TenMetersWalk
                  title="Teste caminhada de 10 metros"
                  current_eval={current_eval}
                />
              )}
            {exam !== null &&
              current_eval !== null &&
              current_eval?.name === 'Teste do sentar e levantar 5x' &&
              exam.title ===
                ExamsEnum['Teste do sentar e levantar 5x'].title && (
                <SitAndStand
                  title="Teste do sentar e levantar 5x"
                  current_eval={current_eval}
                />
              )}
            {exam !== null &&
              current_eval !== null &&
              current_eval?.name === 'MAL - Registro de Atividade Motora' &&
              exam.title ===
                ExamsEnum['MAL - Registro de Atividade Motora'].title && (
                <MAL
                  title="MAL - Registro de Atividade Motora"
                  current_eval={current_eval}
                />
              )}
            {exam !== null &&
              current_eval !== null &&
              current_eval?.name === 'Escala de Avaliação de Fugl-Meyer' &&
              exam.title ===
                ExamsEnum['Escala de Avaliação de Fugl-Meyer'].title && (
                <FuglMeyer
                  title="Escala de Avaliação de Fugl-Meyer"
                  current_eval={current_eval}
                />
              )}
            {exam !== null &&
              current_eval !== null &&
              current_eval?.name === 'TCB - Teste de Caixa e de Blocos' &&
              exam.title ===
                ExamsEnum['TCB - Teste de Caixa e de Blocos'].title && (
                <TCB
                  title="TCB - Teste de Caixa e de Blocos"
                  current_eval={current_eval}
                />
              )}
            {exam !== null &&
              current_eval !== null &&
              current_eval?.name ===
                'TFMJT - Teste de Função Manual de Jebsen-Taylor' &&
              exam.title ===
                ExamsEnum['TFMJT - Teste de Função Manual de Jebsen-Taylor']
                  .title && (
                <TFMJT
                  title="TFMJT - Teste de Função Manual de Jebsen-Taylor"
                  current_eval={current_eval}
                />
              )}
          </S.DivCol>
        </S.DivContainer>
        <br />
        <br />
        <br />
        <br />
      </SiderMenu>
    </Layout>
  );
}

export default Exams;
