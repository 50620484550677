export function getSelf() {
  return {
    type: '@clinic/GET_SELF',
  };
}

export function getSilentSelf() {
  return {
    type: '@clinic/GET_SILENT_SELF',
  };
}

export function setSelf(prof_self) {
  return {
    type: '@clinic/SET_SELF',
    payload: { prof_self },
  };
}

export function uploadAvatarClinic(file, isShowMessage = true) {
  return {
    type: '@clinic/UPLOAD_AVATAR_CLINIC',
    payload: { file, isShowMessage },
  };
}

export function removeAvatar() {
  return {
    type: '@clinic/REMOVE_AVATAR',
  };
}

export function removeAvatarClinic() {
  return {
    type: '@clinic/REMOVE_AVATAR_CLINIC',
  };
}

export function updateClinic(clinic) {
  return {
    type: '@clinic/UPDATE_CLINIC',
    payload: { clinic },
  };
}

export function getProfessionalsWithInvites() {
  return {
    type: '@clinic/GET_PROFESSIONAL_WITH_INVITES',
  };
}

export function setProfessionalsWithInvites(professionals) {
  return {
    type: '@clinic/SET_PROFESSIONAL_WITH_INVITES',
    payload: { professionals },
  };
}

export function getTherapistsWithInvites() {
  return {
    type: '@clinic/GET_THERAPISTS_WITH_INVITES',
  };
}

export function setTherapistsWithInvites(therapists) {
  return {
    type: '@clinic/SET_THERAPISTS_WITH_INVITES',
    payload: { therapists },
  };
}

export function getTherapists() {
  return {
    type: '@clinic/GET_THERAPISTS',
  };
}

export function setTherapists(therapists) {
  return {
    type: '@clinic/SET_THERAPISTS',
    payload: { therapists },
  };
}

export function getProfessionalsAdmins() {
  return {
    type: '@clinic/GET_PROFESSIONAL_ADMINS',
  };
}

export function setProfessionalsAdmins(administrators) {
  return {
    type: '@clinic/SET_PROFESSIONAL_ADMINS',
    payload: { administrators },
  };
}

export function getPossibleAdmins() {
  return {
    type: '@clinic/GET_POSSIBLE_ADMINS',
  };
}

export function setPossibleAdmins(possibleAdmins) {
  return {
    type: '@clinic/SET_POSSIBLE_ADMINS',
    payload: { possibleAdmins },
  };
}

export function addProfessionalAdmin(professional) {
  return {
    type: '@clinic/ADD_PROFESSIONAL_ADMIN',
    payload: { professional },
  };
}

export function removeAdmin(professional) {
  return {
    type: '@clinic/REMOVE_ADMIN',
    payload: { professional },
  };
}

export function answerInviteClinic(
  answer,
  clinicId,
  inviteId,
  notificationId,
  isNewProfile,
) {
  return {
    type: '@clinic/ANSWER_INVITE_CLINIC',
    payload: { answer, clinicId, inviteId, notificationId, isNewProfile },
  };
}

export function changeActiveProfile(profileId, item, history) {
  return {
    type: '@clinic/CHANGE_ACTIVE_PROFILE',
    payload: { profileId, item, history },
  };
}

export function getInfoDetails(typeProfile, patientId) {
  return {
    type: '@clinic/GET_INFO_DETAILS',
    payload: { typeProfile, patientId },
  };
}

export function getInfoDetailsOnlyId(id) {
  return {
    type: '@clinic/GET_INFO_DETAILS_ONLY_ID',
    payload: { id },
  };
}

export function setInfoDetails(profile, typeProfile) {
  return {
    type: '@clinic/SET_INFO_DETAILS',
    payload: { profile, typeProfile },
  };
}

export function saveProfessionalInfo(profile, typeProfile) {
  return {
    type: '@clinic/SAVE_PROFESSIONAL_INFO',
    payload: { profile, typeProfile },
  };
}
