import styled from 'styled-components';

export const TitleMyAccount = styled.h3`
  font: normal normal bold 20px/27px Nunito Sans;
  letter-spacing: 0px;
  color: var(--davysGrey);
  margin-bottom: 1rem;
`;

export const InfoAccount = styled.p`
  margin-top: 1rem;
  color: var(--battleshipGrey);
  text-align: left;
  font: normal normal normal 12px/18px Nunito Sans;
  letter-spacing: 0px;
`;

export const MyPlan = styled.a`
  color: var(--darkCyan);
  text-align: left;
  font: normal normal bold 12px/18px Nunito Sans;
  letter-spacing: 0px;
  text-decoration: none;
`;

export const AntdStyles = {
  PhotoClinic: {
    position: 'absolute',
    bottom: 0,
    left: 80,
    color: 'var(--darkBlueGray)',
    backgroundColor: 'var(--maximumBluePurple)',
  },

  removePhoto: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'white',
    cursor: 'pointer',
  },

  AvatarUser: {
    color: 'var(--darkCyan)',
    backgroundColor: '#C9E2E1',
    fontSize: 52,
    cursor: 'pointer',
  },

  editImg: {
    position: 'absolute',
    top: 0,
    left: 90,
    cursor: 'pointer',
  },

  titleText: {
    font: 'normal normal normal 18px/22px Nunito Sans',
    color: '#575757',
    marginBottom: '2rem',
  },

  RadioButtonGroup: {
    marginBottom: '3rem',
  },

  RadioButtonArchivedAble: {
    width: '7rem',
    textAlign: 'center',
    borderColor: '#6864A4',
    borderWidth: '1px',
    backgroundColor: '#f7f7f7',
    fontWeight: 'bold',
    color: '#6864A4',
    borderRadius: '0px 4px 4px 0px',
  },

  RadioButtonArchivedDisable: {
    width: '7rem',
    textAlign: 'center',
    borderWidth: '1px',
    backgroundColor: '#f7f7f7',
    fontWeight: 'bold',
    color: '#868686',
    borderRadius: '0px 4px 4px 0px',
  },
};

export const DivSider = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DivPhotos = styled.div`
  position: relative;
  top: 0px;
  left: 0px;
`;
