import styled from 'styled-components';
import ImgBackgroundLogin from 'assets/background-login.svg';

export const Background = styled.div`
  background-color: var(--darkCyan);
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-image: url(${ImgBackgroundLogin});
  background-size: cover;
`;

export const Container = styled.div`
  display: flex;
  background-color: var(--white);
  padding: 2.5rem 4rem 1.5rem 4rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 50%;
  height: 50%;
`;
