/* eslint-disable no-nested-ternary */
import { useEffect, useCallback } from 'react';
import { Menu, Typography, Button, Avatar } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { getContacts, getNotifications } from 'store/modules/messages/actions';

import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { signOut } from 'store/modules/auth/actions';
import { changeActiveProfile } from 'store/modules/clinic/actions';
import { nameHandler } from 'utils/NameHandler';
import { PropsProfileEnum, PropsProfileTranslated } from 'utils/profile';
import { showHeaderVinculated } from 'utils/showHeaderVinculated';
import * as S from './style';

const { Text } = Typography;

const { SubMenu } = Menu;

export function ShortMenu() {
  const history = useHistory();
  const dispatch = useDispatch();
  const patientDetails = useSelector(state => state.patient.patientDetails);
  const isClinic = useSelector(
    state =>
      state.auth?.user?.active_profile_name !== PropsProfileEnum.THERAPIST,
  );
  const user = useSelector(state => state.auth.user);

  useEffect(() => {
    if (showHeaderVinculated(user)) {
      dispatch(getContacts());
    }
    dispatch(getNotifications(1, []));
  }, []);

  const handleClick = e => {
    if (isClinic) {
      switch (e.key) {
        case 'setting:1':
          history.push(`/patient_list_clinic`);
          break;
        case 'setting:2':
          history.push(`/professional_list`);
          break;
        case 'setting:3':
          history.push(`/consults`);
          break;
        case 'setting:4':
          history.push(`/my_account_clinic`);
          break;
        case 'setting:5':
          history.push(`/notifications`);
          break;
        case 'setting:6':
          history.push('/chat');
          break;
        case 'setting:7':
          history.push('/subscription');
          break;
        case 'setting:8':
          dispatch(signOut());
          break;
        default:
          break;
      }
    } else {
      switch (e.key) {
        case 'setting:1':
          history.push(`/patient_list`);
          break;
        case 'setting:2':
          if (patientDetails !== null) {
            history.push(`/${patientDetails.id}/agenda`);
          } else {
            history.push(`/professional_agenda`);
          }
          break;
        case 'setting:3':
          history.push(`/my_account`);
          break;
        case 'setting:4':
          history.push(`/notifications`);
          break;
        case 'setting:5':
          history.push('/chat');
          break;
        case 'setting:6':
          history.push('/subscription');
          break;
        case 'setting:7':
          history.push('/content');
          break;
        case 'setting:8':
          dispatch(signOut());
          break;
        default:
          break;
      }
    }
  };

  const profileTranslated = useCallback(profile => {
    const result = PropsProfileTranslated.find(p => p.type === profile).pt;
    return result;
  }, []);

  return (
    <Menu
      mode="horizontal"
      onClick={handleClick}
      triggerSubMenuAction="click"
      style={{
        display: 'flex',
        background: 'none',
        color: 'white',
        width: '100%',
        height: '100%',
        border: 'none',
        justifyContent: 'center',
      }}
      onOpenChange={event => {
        if (event.length !== 1) {
          const index = 1;
          dispatch(getNotifications(index, []));
        }
      }}
    >
      {isClinic ? (
        <SubMenu
          key="SubMenu"
          title="Menu"
          icon={<DownOutlined />}
          style={{ marginTop: '5px' }}
          triggerSubMenuAction="click"
        >
          <Menu.Item key="setting:1">Pacientes</Menu.Item>
          <Menu.Item key="setting:2">Profissionais</Menu.Item>
          <Menu.Item key="setting:3">Consultas</Menu.Item>
          <Menu.Item key="setting:4">Minha Conta</Menu.Item>
          <Menu.Item key="setting:5">Notificações</Menu.Item>
          <Menu.Item key="setting:6">Mensagens</Menu.Item>
          <Menu.Item key="setting:7">Minha Assinatura</Menu.Item>
          <Menu.Item key="setting:8">Sair</Menu.Item>
          <div
            style={{
              width: '186px',
              height: 1,
              marginLeft: 12,
              marginTop: 10,
              marginBottom: 20,
              backgroundColor: '#D5D5D5',
            }}
          />
          <div
            style={{
              marginLeft: 15,
            }}
          >
            <Text style={{ color: '#B1B1B1' }}>Trocar Conta</Text>
          </div>
          {user?.profiles.map(item =>
            item.id !== user.active_profile_id ? (
              <Menu.Item
                style={{ height: '3.2rem' }}
                onClick={() => {
                  dispatch(changeActiveProfile(item.id));
                  if (item.profile === PropsProfileEnum.THERAPIST) {
                    history.push('/patient_list');
                  } else {
                    history.push('/patient_list_clinic');
                  }
                  window.location.reload();
                }}
              >
                <Button
                  style={{
                    height: '100%',
                    width: '100%',
                    flexDirection: 'column',
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                  }}
                >
                  {item?.photo ? (
                    <Avatar
                      src={user?.photo}
                      style={S.AntdStyles.AvatarUserItem}
                    />
                  ) : (
                    <Avatar size={36} style={S.AntdStyles.AvatarUserItem}>
                      {item?.photo === null && nameHandler(item.name)}
                    </Avatar>
                  )}
                  {item?.clinicPhoto !== null ? (
                    <Avatar
                      src={item?.clinicPhoto}
                      size={24}
                      style={S.AntdStyles.AvatarClinicItem}
                    />
                  ) : item?.clinicName !== null ? (
                    <Avatar size={24} style={S.AntdStyles.AvatarClinicItem}>
                      {item?.clinicPhoto === null &&
                        nameHandler(item?.clinicName)}
                    </Avatar>
                  ) : null}
                  <Text
                    style={{
                      display: 'flex',
                      marginLeft: 30,
                      marginTop: 2,
                      font: 'normal normal bold 14px/18px Nunito Sans',
                    }}
                  >
                    {item.name.length > 24
                      ? `${item.name.substring(0, 24)}...`
                      : item.name}
                  </Text>
                  <Text
                    style={{
                      display: 'flex',
                      marginLeft: 30,
                      color: '#868686',
                      font: 'normal normal normal 14px/18px Nunito Sans',
                    }}
                  >
                    {profileTranslated(PropsProfileEnum[item.profile])}
                  </Text>
                </Button>
              </Menu.Item>
            ) : null,
          )}
        </SubMenu>
      ) : (
        <SubMenu
          key="SubMenu"
          title="Menu"
          icon={<DownOutlined />}
          style={{ marginTop: '5px' }}
          triggerSubMenuAction="click"
          onClick={() => null}
        >
          <Menu.Item key="setting:1">Pacientes</Menu.Item>
          <Menu.Item key="setting:2">Agenda</Menu.Item>
          <Menu.Item key="setting:3">Minha Conta</Menu.Item>
          <Menu.Item key="setting:4">Notificações</Menu.Item>
          <Menu.Item key="setting:5">Mensagens</Menu.Item>
          <Menu.Item key="setting:6">Minha Assinatura</Menu.Item>
          <Menu.Item key="setting:7">Conteúdos</Menu.Item>
          <Menu.Item key="setting:8">Sair</Menu.Item>
          <div
            style={{
              width: '186px',
              height: 1,
              marginLeft: 12,
              marginTop: 10,
              marginBottom: 20,
              backgroundColor: '#D5D5D5',
            }}
          />
          <div
            style={{
              marginLeft: 15,
              marginBottom: 15,
            }}
          >
            <Text style={{ color: '#B1B1B1' }}>Trocar Conta</Text>
          </div>
          {user?.profiles.map(item =>
            item.id !== user.active_profile_id ? (
              <Menu.Item
                style={{ height: '3.2rem' }}
                onClick={() => {
                  dispatch(changeActiveProfile(item.id));
                  if (item.profile === PropsProfileEnum.THERAPIST) {
                    history.push('/patient_list');
                  } else {
                    history.push('/patient_list_clinic');
                  }
                  window.location.reload();
                }}
              >
                <Button
                  style={{
                    height: '100%',
                    width: '100%',
                    flexDirection: 'column',
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                  }}
                >
                  {item?.photo ? (
                    <Avatar
                      src={user?.photo}
                      style={S.AntdStyles.AvatarUserItem}
                    />
                  ) : (
                    <Avatar size={36} style={S.AntdStyles.AvatarUserItem}>
                      {item?.photo === null && nameHandler(item?.name)}
                    </Avatar>
                  )}
                  {item?.clinicPhoto !== null ? (
                    <Avatar
                      src={item?.clinicPhoto}
                      size={24}
                      style={S.AntdStyles.AvatarClinicItem}
                    />
                  ) : item?.clinicName !== null ? (
                    <Avatar size={24} style={S.AntdStyles.AvatarClinicItem}>
                      {item?.clinicPhoto === null &&
                        nameHandler(item?.clinicName)}
                    </Avatar>
                  ) : null}
                  <Text
                    style={{
                      display: 'flex',
                      marginLeft: 30,
                      marginTop: 2,
                      font: 'normal normal bold 14px/18px Nunito Sans',
                    }}
                  >
                    {item.name.length > 24
                      ? `${item.name.substring(0, 24)}...`
                      : item.name}
                  </Text>
                  <Text
                    style={{
                      display: 'flex',
                      marginLeft: 30,
                      color: '#868686',
                      font: 'normal normal normal 14px/18px Nunito Sans',
                    }}
                  >
                    {profileTranslated(PropsProfileEnum[item.profile])}
                  </Text>
                </Button>
              </Menu.Item>
            ) : null,
          )}
        </SubMenu>
      )}
    </Menu>
  );
}
// <Menu.Item key="setting:5">Adicionar Conta</Menu.Item>
