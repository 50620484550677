import { takeLatest, call, all, put } from 'redux-saga/effects';
import { notification } from 'antd';

import api from '../../../services/api';

import { setContentVideos } from './actions';

export function* getContentVideos() {
  try {
    const response = yield call(api.get, `/educational_content`);
    yield put(setContentVideos(response.data.educational_content));
  } catch (err) {
    notification.error({
      message:
        'Houve um erro ao buscar os videos de Conteúdos, tente novamente!',
    });
  }
}

export default all([
  takeLatest('@professional_content/GET_CONTENT_VIDEOS', getContentVideos),
]);
