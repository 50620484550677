/* eslint-disable no-unused-vars */
import produce from 'immer';

const INITIAL_STATE = {
  monitoringData: null,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@monitor/SET_MONITORING_DATA': {
        draft.monitoringData = action.payload.monitoringData;
        break;
      }
      default:
    }
  });
}
