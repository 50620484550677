import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  height: 100%;
  padding-top: 7%;
  width: 100%;
`;

export const DivSession = styled.div`
  background: var(--lavender) 0% 0% no-repeat padding-box;
  border-width: 15px;
  width: 140px;
  border-left: solid var(--deepKoamaru) 2px;
  display: flex;
  height: 25px;
  align-items: center;
  justify-content: flex-start;
  border-radius: 0 1px 1px 0;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;

export const TitleSession = styled.label`
  opacity: 1;
  color: var(--deepKoamaru);
  text-align: start;
  font: normal normal bold 14px/19px Nunito Sans;
  letter-spacing: 0px;
  margin-left: 6px;
`;

export const ContainerNoPlan = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 350px;
  min-height: 320px;

  strong {
    color: var(--davysGrey);
    text-align: left;
    font: normal normal normal 16px/19px Nunito Sans;
    letter-spacing: 0px;
    opacity: 0.8;
  }
`;

export const ContainerCurrentPlanSection = styled.div`
  display: flex;
  width: 100%;
  height: 320px;

  @media (min-width: 770px) {
    flex-direction: row;
  }

  @media (max-width: 770px) {
    flex-direction: column;
  }
`;

export const CurrentPlan = styled.div`
  display: flex;
  flex-direction: column;
  width: 320px;
  height: 310px;

  label {
    opacity: 0.6;
  }
`;

export const CurrentPlanInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    color: var(--davysGrey);
    text-align: left;

    &.subtitle {
      opacity: 0.6;
    }

    &.regular-text {
      opacity: 1;
      font: normal normal normal 14px/16px Nunito Sans;
    }

    & + label {
      margin-top: 5px;
    }
  }
  @media (min-width: 770px) {
    margin-left: 15%;
  }

  @media (max-width: 770px) {
    margin-left: 5%;
    margin-top: 15%;
  }
`;

export const PaymentOptions = styled.div`
  display: flex;
  width: 100%;

  strong {
    margin-top: 8px;
    font: normal normal bold 14px/16px Nunito Sans;
  }

  label {
    text-align: left;
    font: normal normal normal 14px/16px Nunito Sans;

    &.click-to-edit {
      opacity: 0.6;
      color: var(--darkCyan);
      cursor: pointer;
      align-items: center;
      margin-top: 4px;
    }

    &.regular-text-credcard {
      display: flex;
      align-items: center;
      opacity: 1;
      color: var(--davysGrey);
      margin-left: 4px;
    }

    &.regular-text-credcard2 {
      display: flex;
      align-items: center;
      opacity: 1;
      color: var(--davysGrey);
      margin-top: 2px;
    }
  }
`;

export const PlanConfigurations = styled.div`
  display: flex;
  width: 100%;
`;

export const PlanConfigurationSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RegularAlertMessage = styled.div`
  display: flex;
  width: 100%;
  margin-top: 1rem;

  img {
    width: 16px;
    height: 16px;
  }

  label {
    margin-left: 4px;
    &.alert-message {
      font: normal normal normal 14px/16px Nunito Sans;
    }
  }
`;

export const PlanConfigurationSubSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 20px;

  label {
    font: normal normal normal 14px/16px Nunito Sans;
  }
`;

export const TitlePage = styled.h5`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal normal 20px/27px Nunito Sans;
  letter-spacing: 0px;
  opacity: 1;
  margin-bottom: 1rem;
`;

export const AntdStyles = {
  ButtonGetMore: {
    background: 'transparent',
    borderRadius: '4px',
    border: '1px solid var(--darkCyan)',
    color: 'var(--darkCyan)',
    opacity: 1,
    width: '9rem',
    marginTop: '0.5rem',
    marginBottom: '2rem',
  },
  ButtonSeeData: {
    background: 'transparent',
    borderRadius: '4px',
    border: 'none',
    color: 'var(--darkCyan)',
    opacity: 1,
    width: '9rem',
    marginBottom: '1rem',
  },
  ButtonEdit: {
    background: 'transparent',
    borderRadius: '4px',
    border: 'none',
    color: 'var(--darkCyan)',
    opacity: 1,
    width: '5rem',
  },
  ButtonCancelPlan: {
    background: 'var(--darkCyan) 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    border: 'none',
    opacity: 1,
    color: 'white',
    width: '22rem',
  },
};
