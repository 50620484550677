import styled from 'styled-components';

export const TitlePage = styled.h5`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal normal 20px/27px Nunito Sans;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

export const EditSpeciality = styled.a`
  color: var(--darkCyan);
  text-align: left;
  font: normal normal normal 14px/19px Nunito Sans;
  letter-spacing: 0px;
  text-decoration: none;
`;

export const TitleSession = styled.label`
  opacity: 1;
  color: var(--deepKoamaru);
  text-align: start;
  font: normal normal bold 14px/19px Nunito Sans;
  letter-spacing: 0px;
  margin-left: 6px;
`;

export const DivSession = styled.div`
  background: var(--lavender) 0% 0% no-repeat padding-box;
  border-width: 15px;
  width: 140px;
  border-left: solid var(--deepKoamaru) 2px;
  display: flex;
  height: 25px;
  align-items: center;
  justify-content: flex-start;
  border-radius: 0 1px 1px 0;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;

export const AntdStyles = {
  marginTop: {
    marginTop: '4px',
  },

  inputMask: {
    textAlign: 'left',
    font: 'normal normal normal 14px/19px Nunito Sans',
    letterSpacing: '0px',
    color: 'var(--jet)',
    display: 'flex',
    height: '32px',
    width: '100%',
    padding: '4px 11px',
    marginTop: '0.25rem',
    borderWidth: 1,
    border: '1px solid #d9d9d9',
  },

  inputMaskDisabled: {
    textAlign: 'left',
    font: 'normal normal normal 14px/19px Nunito Sans',
    letterSpacing: '0px',
    color: 'var(--jet)',
    display: 'flex',
    height: '32px',
    width: '100%',
    padding: '4px 11px',
    marginTop: '0.25rem',
    borderWidth: 1,
    border: '1px solid #d9d9d9',
    cursor: 'not-allowed',
  },

  ButtonSave: {
    background: 'var(--darkCyan) 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    opacity: 1,
    width: '12rem',
    marginTop: '2rem',
    marginBottom: '2rem',
    fontWeight: 'bold',
  },

  AutoComplete: {
    marginTop: '4px',
    fontFamily: 'Nunito Sans',
    fontSize: '14px',
  },

  ButtonTypePayment: {
    marginTop: '4px',
    textAlign: 'left',
    fontFamily: 'Nunito Sans',
  },

  DivSessionAddress: {
    marginTop: '3rem',
    width: '76px',
  },

  marginTop2rem: {
    marginTop: '2rem',
  },

  inputNumber: { width: '100%', marginTop: '4px' },
  menuStates: { height: 200, overflowY: 'scroll' },
};

export const LabelText = styled.div`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal bold 14px/19px Nunito Sans;
  letter-spacing: 0px;
  display: flex;
  flex-direction: column;
`;

export const ErrorMessage = styled.label`
  color: #c12;
  font-size: 12px;
  margin-top: 2px;
  font-weight: 100;
`;

export const DeleteText = styled.h5`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal normal 14px/21px Nunito Sans;
  letter-spacing: 0px;
  margin-top: 1rem;
`;

export const SpecialityText = styled.h5`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal normal 14px/16px Nunito Sans;
  letter-spacing: 0px;
  margin-top: 0.3rem;
`;

export const EditText = styled.h5`
  color: var(--darkCyan);
  text-align: left;
  font: normal normal normal 14px/19px Nunito Sans;
  letter-spacing: 0px;
  position: absolute;
  right: 3rem;
  cursor: pointer;
`;
