/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
// eslint-disable-next-line no-unused-vars
import React, { useContext, useEffect, useState } from 'react';
import { Divider, Button } from 'antd';
import CrownImg from 'assets/crown.svg';
import StarImg from 'assets/star.svg';
import AlertImg from 'assets/gray-alert.svg';
import { useSelector, useDispatch } from 'react-redux';

import { cancelPlan } from 'store/modules/payment/actions';
import { isConsole } from 'react-device-detect';
import PlanContext from '../../Context/PlanContext';

import * as S from './styles';
import { CancelPlanModal } from './components/CancelPlanModal';

export function Plan({
  planData = [],
  isBestPlan = false,
  isCurrentPlan = false,
  isTestPeriod = false,
  isShowButtonContract = true,
  planFake = false,
  showModalCreateClinic = null,
}) {
  const { changeScreen } = useContext(PlanContext);
  const profile = useSelector(state =>
    state.auth.user.profiles.find(
      item => item.id === state.auth.user.active_profile_id,
    ),
  );

  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();
  const statusCurrentPlan = useSelector(
    state => state.payment.subsc_status.subscriptionStatus.statusCurrentPlan,
  );

  function handleOk() {
    dispatch(cancelPlan());
    setIsModalVisible(false);
  }

  function handleCancel() {
    setIsModalVisible(false);
  }

  const [quantProfessionals, setQuantProfessional] = useState(
    '0 Profissionais',
  );

  useEffect(() => {
    const resources = planData.content_include?.split('//');
    const professionals = resources?.find(item =>
      item.includes('Profissionais'),
    );
    setQuantProfessional(professionals?.slice(1, -1));
  }, [planData]);

  return (
    <S.Container>
      <CancelPlanModal
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <S.ContainerPlanTitle>
        {isBestPlan && <img src={CrownImg} alt="coroa" />}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <strong className="plan-name">{planData.planName}</strong>
          {planData.planName.includes('Clínica') && (
            <strong className="plan-name">{`(${quantProfessionals})`}</strong>
          )}
        </div>
        <strong
          className={
            planData.planName !== 'Plano Profissional Plus'
              ? 'plan-price'
              : 'big-plan-price'
          }
        >
          {planData.price}
        </strong>
        {!planFake && <label>/{planData.frequency}</label>}
      </S.ContainerPlanTitle>
      <S.ContainerDivider>
        <Divider />
      </S.ContainerDivider>
      {!planFake && (
        <S.ContainerPlanOferts>
          <S.ContainerPlanSubTitles>
            <img src={StarImg} alt="estrela" />
            <strong>Este plano inclui:</strong>
          </S.ContainerPlanSubTitles>
          <label>{planData.content_include}</label>
        </S.ContainerPlanOferts>
      )}
      <S.ContainerPlanOferts>
        {!planFake && planData.content_not_included !== '' && (
          <>
            <S.ContainerPlanSubTitles>
              <img src={AlertImg} alt="alerta" />
              <strong>Este plano não inclui:</strong>
            </S.ContainerPlanSubTitles>
            <label>{planData.content_not_include}</label>
          </>
        )}
        {!planFake && isBestPlan && (
          <S.ContainerPlanSubTitles>
            <strong className="this-is-best-plan">
              Este é o melhor plano disponível!
            </strong>
          </S.ContainerPlanSubTitles>
        )}
        {planFake && (
          <>
            <S.ContainerPlanSubTitles>
              <img src={StarImg} alt="estrela" />
              <strong>{planData.planDescTitle}</strong>
            </S.ContainerPlanSubTitles>
            <label>{planData.planDescription}</label>
          </>
        )}
        {isShowButtonContract ? (
          isCurrentPlan !== true ? (
            <S.ContainerButton>
              <Button
                key="primary"
                type="default"
                style={S.AntdStyles.ButtonChangePlan}
                onClick={() =>
                  planData.planId.includes('CLINIC') &&
                  profile.clinicId === null
                    ? showModalCreateClinic()
                    : changeScreen('payment', planData.planName)
                }
              >
                {isTestPeriod === true ? (
                  <strong>Contratar Plano</strong>
                ) : (
                  <strong>Trocar Plano</strong>
                )}
              </Button>
            </S.ContainerButton>
          ) : statusCurrentPlan === 'canceled' ? (
            <label>Plano cancelado</label>
          ) : (
            <Button
              key="primary"
              type="default"
              style={S.AntdStyles.ButtonChangePlan}
              onClick={() => setIsModalVisible(true)}
              disabled={statusCurrentPlan === 'canceled'}
            >
              <strong>Cancelar Plano</strong>
            </Button>
          )
        ) : (
          <></>
        )}
        {planFake && (
          <Button
            key="primary"
            type="default"
            style={{
              ...S.AntdStyles.ButtonChangePlan,
              marginTop: '38%',
            }}
            onClick={planData.buttonFunc}
          >
            <strong>{planData.buttonName}</strong>
          </Button>
        )}
      </S.ContainerPlanOferts>
    </S.Container>
  );
}
