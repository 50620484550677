import styled from 'styled-components';

export const NotificationDiv = styled.div`
  height: 50;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 2rem;
`;

export const NotificationText = styled.h1`
  color: #575757;
  margin-left: 5;
  font: normal normal normal 20px/21px Nunito Sans;
`;

export const AntdStyles = {
  notifContainer: {
    marginLeft: 45,
    marginTop: -30,
    width: 40,
    alignSelf: 'center',
    justifyContent: 'center',
    backgroundColor: '#118E88',
    borderRadius: 5,
  },
  notifText: {
    color: '#fff',
    font: 'normal normal normal 12px/21px Nunito Sans',
    textAlign: 'center',
  },

  AvatarUser: {
    color: 'var(--darkCyan)',
    backgroundColor: '#C9E2E1',
    fontSize: 52,
    cursor: 'pointer',
  },
};
