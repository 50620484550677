/* eslint-disable prefer-const */
/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useEffect, useRef } from 'react';
import { Row, Col, Input, Dropdown, Menu, Button, Checkbox } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import './styles.css';
import { Formik } from 'formik';
import {
  savePatientGoals,
  savePatientGoalsFinal,
} from 'store/modules/goals/actions';

import {
  PropsStructureAndBodyFunctionsArrayTranslated,
  PropsStructureAndBodyFunctionsInputArray,
  PropsActivityArrayTranslated,
  PropsActivityInputArray,
  PropsImproveGaitArrayTranslated,
  PropsImprovePosturalExchangeArrayTranslated,
} from 'utils/goals';
import useDebounce from 'hooks/debounce/useDebounce';
import SavedAnswer from 'components/SavedAnswer';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';

import * as S from './styles';

function FormGoals() {
  const goalsDetails = useSelector(state => state.goals.goals);
  const dispatch = useDispatch();
  const firstChange = useMediaQuery('(min-width: 770px)');
  const { id } = useParams();

  const formikGoals = {
    initialValues: goalsDetails,
    onSubmit: values => {
      dispatch(savePatientGoalsFinal(values, id));
    },
  };

  const { TextArea } = Input;

  const getValueObjectNested = useCallback((objectFormik, path) => {
    const pathObjectSplitted = path.split('.');
    let value = objectFormik;
    for (let i = 0; i < pathObjectSplitted.length; i += 1) {
      value = value[pathObjectSplitted[i]];
    }
    return value;
  }, []);

  const formikRef = useRef();

  function saveForm() {
    if (formikRef?.current?.values !== undefined) {
      dispatch(savePatientGoals(formikRef?.current?.values, id));
    }
  }

  const debounceChange = useDebounce(saveForm, 2000);

  return (
    <Formik
      initialValues={formikGoals.initialValues}
      onSubmit={values => formikGoals.onSubmit(values)}
      innerRef={formikRef}
    >
      {props => (
        <>
          {useEffect(() => {
            debounceChange();
          }, [props.values])}

          <S.TitlePage>Objetivos</S.TitlePage>

          <S.DivSession>
            <S.TitleSession>
              Estruturas e Funções Corporais (CIF)
            </S.TitleSession>
          </S.DivSession>

          <Row
            gutter={[32, 16]}
            style={{ marginTop: '2rem', marginBottom: '2rem' }}
          >
            <Col span={firstChange ? 20 : 24}>
              {PropsStructureAndBodyFunctionsArrayTranslated.map(
                (item, index) =>
                  item && (
                    <div style={{ marginBottom: '8px' }}>
                      {index + 1} -{' '}
                      <Checkbox
                        defaultChecked={getValueObjectNested(
                          props.values,
                          item.type,
                        )}
                        name={`${item.type}`}
                        onChange={e =>
                          props.setFieldValue(`${item.type}`, e.target.checked)
                        }
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                          }}
                        >
                          <S.LabelText
                            className="input-label"
                            style={{ marginTop: '0.5rem' }}
                          >
                            {item.pt}
                          </S.LabelText>
                          <SavedAnswer
                            resource="hasGoals"
                            propsFormik={props}
                            fieldName={`${item.type}`}
                            style={{ marginLeft: '4px' }}
                          />
                        </div>
                      </Checkbox>
                      {PropsStructureAndBodyFunctionsInputArray.map(
                        input =>
                          input.from === item.type && (
                            <>
                              <S.LabelText
                                className="input-label"
                                style={{ marginTop: '0.5rem' }}
                              >
                                <Input
                                  style={S.AntdStyles.marginTop}
                                  disabled={
                                    !getValueObjectNested(
                                      props.values,
                                      input.from,
                                    )
                                  }
                                  placeholder={input.label}
                                  id={`${input.to}`}
                                  name={`${input.to}`}
                                  type="text"
                                  onChange={props.handleChange}
                                  value={getValueObjectNested(
                                    props.values,
                                    input.to,
                                  )}
                                  onBlur={props.handleBlur}
                                />
                              </S.LabelText>
                              <SavedAnswer
                                resource="hasGoals"
                                propsFormik={props}
                                fieldName={`${input.to}`}
                              />
                            </>
                          ),
                      )}
                      {item.pt === 'Melhorar a Destreza Manual' && (
                        <>
                          <S.LabelText
                            className="input-label"
                            style={{ marginTop: '0.5rem' }}
                          >
                            <Dropdown
                              overlay={
                                <Menu>
                                  {['Lado direito', 'Lado esquerdo'].map(
                                    side => (
                                      <Menu.Item>
                                        <element
                                          onClick={() =>
                                            props.setFieldValue(
                                              'estruturas_e_funcoes_corporais_cif.melhorar_a_destreza_manual.lado',
                                              side,
                                            )
                                          }
                                        >
                                          {side}
                                        </element>
                                      </Menu.Item>
                                    ),
                                  )}
                                </Menu>
                              }
                              placement="bottomCenter"
                            >
                              <Button style={S.AntdStyles.ButtonTypePayment}>
                                {
                                  props.values
                                    .estruturas_e_funcoes_corporais_cif
                                    .melhorar_a_destreza_manual.lado
                                }
                              </Button>
                            </Dropdown>
                          </S.LabelText>
                          <SavedAnswer
                            propsFormik={props}
                            fieldName="estruturas_e_funcoes_corporais_cif.melhorar_a_destreza_manual.lado"
                          />
                        </>
                      )}
                    </div>
                  ),
              )}
            </Col>
          </Row>

          <S.DivSession style={{ width: '115px' }}>
            <S.TitleSession>Atividade (CIF)</S.TitleSession>
          </S.DivSession>

          <Row
            gutter={[32, 16]}
            style={{ marginTop: '2rem', marginBottom: '2rem' }}
          >
            <Col span={firstChange ? 16 : 24}>
              {PropsActivityArrayTranslated.map(
                (item, index) =>
                  item && (
                    <div style={{ marginBottom: '8px' }}>
                      {index + 1} -{' '}
                      <Checkbox
                        defaultChecked={getValueObjectNested(
                          props.values,
                          item.type,
                        )}
                        name={`${item.type}`}
                        onChange={e =>
                          props.setFieldValue(`${item.type}`, e.target.checked)
                        }
                      >
                        <div
                          style={{ display: 'flex', alignItems: 'flex-end' }}
                        >
                          <S.LabelText
                            className="input-label"
                            style={{ marginTop: '0.5rem' }}
                          >
                            {item.pt}
                          </S.LabelText>
                          <SavedAnswer
                            resource="hasGoals"
                            propsFormik={props}
                            fieldName={`${item.type}`}
                            style={{ marginLeft: '4px' }}
                          />
                        </div>
                      </Checkbox>
                      {PropsActivityInputArray.map(
                        input =>
                          input.from === item.type && (
                            <>
                              <S.LabelText
                                className="input-label"
                                style={{ marginTop: '0.5rem' }}
                              >
                                <Input
                                  style={S.AntdStyles.marginTop}
                                  placeholder={input.label}
                                  type="text"
                                  disabled={
                                    !getValueObjectNested(
                                      props.values,
                                      input.from,
                                    )
                                  }
                                  id={`${input.to}`}
                                  name={`${input.to}`}
                                  onChange={props.handleChange}
                                  value={getValueObjectNested(
                                    props.values,
                                    input.to,
                                  )}
                                  onBlur={props.handleBlur}
                                />
                              </S.LabelText>
                              <SavedAnswer
                                resource="hasGoals"
                                propsFormik={props}
                                fieldName={`${input.to}`}
                              />
                            </>
                          ),
                      )}
                      {item.pt === 'Melhorar a Marcha' &&
                        PropsImproveGaitArrayTranslated.map(
                          ImproveGait =>
                            ImproveGait && (
                              <div
                                style={{
                                  marginBottom: '8px',
                                  marginTop: '8px',
                                  marginLeft: '36px',
                                }}
                              >
                                <Checkbox
                                  defaultChecked={getValueObjectNested(
                                    props.values,
                                    ImproveGait.type,
                                  )}
                                  disabled={
                                    !getValueObjectNested(
                                      props.values,
                                      item.type,
                                    )
                                  }
                                  name={`${ImproveGait.type}`}
                                  onChange={e =>
                                    props.setFieldValue(
                                      `${ImproveGait.type}`,
                                      e.target.checked,
                                    )
                                  }
                                >
                                  {ImproveGait.pt}
                                </Checkbox>
                                <SavedAnswer
                                  propsFormik={props}
                                  fieldName={`${ImproveGait.type}`}
                                />
                              </div>
                            ),
                        )}
                      {item.pt === 'Melhorar Troca Postural' &&
                        PropsImprovePosturalExchangeArrayTranslated.map(
                          ImprovePosturalExchange =>
                            ImprovePosturalExchange && (
                              <div
                                style={{
                                  marginBottom: '8px',
                                  marginTop: '8px',
                                  marginLeft: '36px',
                                }}
                              >
                                <Checkbox
                                  defaultChecked={getValueObjectNested(
                                    props.values,
                                    ImprovePosturalExchange.type,
                                  )}
                                  disabled={
                                    !getValueObjectNested(
                                      props.values,
                                      item.type,
                                    )
                                  }
                                  name={`${ImprovePosturalExchange.type}`}
                                  onChange={e =>
                                    props.setFieldValue(
                                      `${ImprovePosturalExchange.type}`,
                                      e.target.checked,
                                    )
                                  }
                                >
                                  {ImprovePosturalExchange.pt}
                                </Checkbox>
                                <SavedAnswer
                                  resource="hasGoals"
                                  propsFormik={props}
                                  fieldName={`${ImprovePosturalExchange.type}`}
                                />
                              </div>
                            ),
                        )}
                    </div>
                  ),
              )}
            </Col>
          </Row>

          <S.DivSession style={{ width: '130px' }}>
            <S.TitleSession>Participação (CIF)</S.TitleSession>
          </S.DivSession>

          <S.LabelText className="input-label" style={{ marginTop: '0.5rem' }}>
            Defina aqui qual participação você deseja alcançar com seu paciente
            em curto, médio e longo prazo.
          </S.LabelText>

          <Col className="gutter-row" span={24} style={{ marginTop: '1rem' }}>
            <S.LabelText className="input-label">
              Curto
              <TextArea
                defaultValue={props.values.participacao_cif.curto}
                rows={3}
                onChange={event =>
                  props.setFieldValue(
                    'participacao_cif.curto',
                    event.target.value,
                  )
                }
                style={{ marginTop: '4px' }}
              />
            </S.LabelText>
            <SavedAnswer
              resource="hasGoals"
              propsFormik={props}
              fieldName="participacao_cif.curto"
            />
          </Col>

          <Col className="gutter-row" span={24} style={{ marginTop: '1rem' }}>
            <S.LabelText className="input-label">
              Médio
              <TextArea
                defaultValue={props.values.participacao_cif.medio}
                rows={3}
                onChange={event =>
                  props.setFieldValue(
                    'participacao_cif.medio',
                    event.target.value,
                  )
                }
                style={{ marginTop: '4px' }}
              />
            </S.LabelText>
            <SavedAnswer
              resource="hasGoals"
              propsFormik={props}
              fieldName="participacao_cif.medio"
            />
          </Col>

          <Col className="gutter-row" span={24} style={{ marginTop: '1rem' }}>
            <S.LabelText className="input-label">
              Longo
              <TextArea
                defaultValue={props.values.participacao_cif.longo}
                rows={3}
                onChange={event =>
                  props.setFieldValue(
                    'participacao_cif.longo',
                    event.target.value,
                  )
                }
                style={{ marginTop: '4px' }}
              />
            </S.LabelText>
            <SavedAnswer
              resource="hasGoals"
              propsFormik={props}
              fieldName="participacao_cif.longo"
            />
          </Col>

          <S.DivSession style={{ width: '160px', marginTop: '2rem' }}>
            <S.TitleSession>Descrição do Objetivo</S.TitleSession>
          </S.DivSession>

          <Col className="gutter-row" span={24} style={{ marginTop: '1rem' }}>
            <S.LabelText className="input-label">
              Descreva para seu paciente qual é o objetivo. Esta informação
              ficará visível para ele.
              <TextArea
                defaultValue={props.values.goal}
                rows={3}
                onChange={event =>
                  props.setFieldValue('goal', event.target.value)
                }
                style={{ marginTop: '4px' }}
              />
            </S.LabelText>
            <SavedAnswer
              resource="hasGoals"
              propsFormik={props}
              fieldName="goal"
            />
          </Col>

          <Button
            type="primary"
            htmlType="submit"
            onClick={props.handleSubmit}
            style={S.AntdStyles.ButtonSave}
          >
            Salvar Alterações
          </Button>
          <br />
          <br />
          <br />
          <br />
        </>
      )}
    </Formik>
  );
}

export default FormGoals;
