/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  notification,
  Input,
  Checkbox,
  Button,
  Row,
  Col,
  Menu,
  TimePicker,
  InputNumber,
  Dropdown,
} from 'antd';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { getAddressByCep } from 'store/modules/CEP/actions';
import moment from 'moment';
import { StatesOfBrazil } from 'utils/States';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import { updateClinic } from 'store/modules/clinic/actions';
import { maskBuilderCPFCNPJ } from 'utils/maskValidator';
import { PropsProfileEnum } from 'utils/profile';
import * as S from './styles';

function DataClinic({ secondChange }) {
  const dispatch = useDispatch();
  const clinic = useSelector(state => state.clinic.self);
  const isClinic = useSelector(
    state =>
      state.auth?.user?.active_profile_name !== PropsProfileEnum.THERAPIST,
  );
  const isAdmin = useSelector(state => state.auth.isAdmin);
  const isMyAccountView = useSelector(state => state.auth.isMyAccountView);
  const firstChange = useMediaQuery('(min-width: 770px)');

  const optionsWeek = [
    { label: 'Domingos', value: 0 },
    { label: 'Segundas', value: 1 },
    { label: 'Terças', value: 2 },
    { label: 'Quartas', value: 3 },
    { label: 'Quintas', value: 4 },
    { label: 'Sextas', value: 5 },
    { label: 'Sábados', value: 6 },
  ];

  Yup.addMethod(Yup.string, 'defaultType', function (errorMessage) {
    return this.test(`test-default-type`, errorMessage, function (value) {
      const { path, createError } = this;

      return (
        value !== 'Selecione' || createError({ path, message: errorMessage })
      );
    });
  });

  const formikGeneralData = {
    initialValues: {
      nameClinic: clinic?.name,
      emailClinic: clinic?.email,
      cellphoneClinic1: clinic?.phone_number1,
      cellphoneClinic2: clinic?.phone_number2,
      hourClinic1: clinic?.hourInit,
      hourClinic2: clinic?.hourEnd,
      daysOfWeek: clinic?.dayInWeek,
      street: clinic?.address?.street,
      state: clinic?.address?.state,
      number: clinic?.address?.number,
      complement: clinic?.address?.complement,
      neighborhood: clinic?.address?.neighborhood,
      city: clinic?.address?.city,
      postal_code: clinic?.address?.postal_code,
      document: clinic?.document,
    },
    validationSchema: Yup.object({
      street: Yup.string().required('É obrigatório'),
      nameClinic: Yup.string().required('É obrigatório'),
      emailClinic: Yup.string().required('É obrigatório'),
      hourClinic1: Yup.string().required('É obrigatório'),
      cellphoneClinic1: Yup.string().required('É obrigatório'),
      cellphoneClinic2: Yup.string().required('É obrigatório'),
      daysOfWeek: Yup.array()
        .min(1, 'Selecione no mínimo um dia')
        .required('É obrigatório'),
      state: Yup.string().defaultType('É obrigatório'),
      number: Yup.string().required('É obrigatório'),
      complement: Yup.string().required('É obrigatório'),
      neighborhood: Yup.string().required('É obrigatório'),
      city: Yup.string().required('É obrigatório'),
      postal_code: Yup.string().required('É obrigatório'),
      document: Yup.string().required('É obrigatório'),
    }),
    onSubmit: values => {
      if (values.daysOfWeek.length === 0 && isAdmin) {
        notification.warning({
          message: 'Selecione os dias de funcionamento',
        });
        return;
      }

      const clinicSubmit = values;
      dispatch(updateClinic(clinicSubmit));
    },
  };

  const cep = useSelector(state => state.CEP);
  const formikRef = useRef(null);

  useEffect(() => {
    if (!isMyAccountView && cep.address !== null) {
      formikRef.current.setFieldValue('street', cep.address.logradouro);
      formikRef.current.setFieldValue('neighborhood', cep.address.bairro);
      formikRef.current.setFieldValue('city', cep.address.cidade);
      formikRef.current.setFieldValue('state', cep.address.estado_info.nome);
    }
  }, [cep]);

  return !isMyAccountView && clinic !== null ? (
    <Formik
      initialValues={formikGeneralData.initialValues}
      validationSchema={formikGeneralData.validationSchema}
      onSubmit={values => formikGeneralData.onSubmit(values)}
      innerRef={formikRef}
    >
      {props => (
        <div>
          <S.TitleSession>Dados Gerais</S.TitleSession>

          <Row gutter={[32, 16]} style={S.AntdStyles.marginTop2rem}>
            <Col span={secondChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Nome
                <Input
                  style={S.AntdStyles.marginTop}
                  id="nameClinic"
                  name="nameClinic"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.nameClinic}
                  onBlur={props.handleBlur}
                />
                {props.touched.nameClinic && props.errors.nameClinic ? (
                  <S.ErrorMessage>{props.errors.nameClinic}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>
            <Col span={secondChange ? 8 : 24}>
              <S.LabelText>E-mail</S.LabelText>
              <Input
                placeholder="exemplo@exemplo.com.br"
                style={S.AntdStyles.marginTop}
                id="emailClinic"
                name="emailClinic"
                type="email"
                onChange={props.handleChange}
                value={props.values.emailClinic}
                onBlur={props.handleBlur}
                disabled
              />
              {props.touched.emailClinic && props.errors.emailClinic ? (
                <S.ErrorMessage>{props.errors.emailClinic}</S.ErrorMessage>
              ) : null}
            </Col>
            <Col span={secondChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Telefone #1
                <InputMask
                  mask="(99)99999-9999"
                  style={S.AntdStyles.inputMask}
                  value={props.values.cellphoneClinic1}
                  onChange={e =>
                    props.setFieldValue('cellphoneClinic1', e.target.value)
                  }
                />
              </S.LabelText>
              {props.touched.cellphoneClinic1 &&
              props.errors.cellphoneClinic1 ? (
                <S.ErrorMessage>{props.errors.cellphoneClinic1}</S.ErrorMessage>
              ) : null}
            </Col>
            <Col span={secondChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Telefone #2
                <InputMask
                  mask="(99)99999-9999"
                  style={S.AntdStyles.inputMask}
                  value={props.values.cellphoneClinic2}
                  onChange={e =>
                    props.setFieldValue('cellphoneClinic2', e.target.value)
                  }
                />
              </S.LabelText>
              {props.touched.cellphoneClinic2 &&
              props.errors.cellphoneClinic2 ? (
                <S.ErrorMessage>{props.errors.cellphoneClinic2}</S.ErrorMessage>
              ) : null}
            </Col>
            <Col className="gutter-row" span={secondChange ? 8 : 24}>
              <S.LabelText className="input-label">
                CPF ou CNPJ
                <InputMask
                  mask={maskBuilderCPFCNPJ(props.values.document)}
                  style={S.AntdStyles.inputMask}
                  value={props.values.document}
                  onChange={e =>
                    props.setFieldValue(
                      'document',
                      e.target.value.replace(/\D/g, ''),
                    )
                  }
                />
                {props.touched.document && props.errors.document ? (
                  <S.ErrorMessage>{props.errors.document}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col span={secondChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Horário de funcionamento
                <Row style={{ marginTop: '0.27rem' }}>
                  <TimePicker
                    style={{ width: '40%' }}
                    onChange={(_, hourString1) => {
                      props.setFieldValue(
                        'hourClinic1',
                        moment(hourString1, 'HH:mm').format('HH:mm'),
                      );
                    }}
                    placeholder={clinic?.hourInit}
                    format="HH:mm"
                    defaultOpenValue={moment(clinic?.hourInit, 'HH:mm')}
                  />

                  <S.HoursSession>-</S.HoursSession>
                  <TimePicker
                    style={{ width: '40%' }}
                    onChange={(_, hourString2) => {
                      props.setFieldValue(
                        'hourClinic2',
                        moment(hourString2, 'HH:mm').format('HH:mm'),
                      );
                    }}
                    format="HH:mm"
                    placeholder={clinic?.hourEnd}
                    defaultOpenValue={moment(clinic?.hourEnd, 'HH:mm')}
                  />
                </Row>
                {props.touched.hourClinic1 && props.errors.hourClinic1 ? (
                  <S.ErrorMessage>{props.errors.hourClinic1}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>
            <Col span={secondChange ? 24 : 24} style={{ marginBottom: '1rem' }}>
              <S.LabelText className="input-label">
                Dias da Semana (Funcionamento)
                <Checkbox.Group
                  style={S.AntdStyles.marginTop}
                  options={optionsWeek}
                  onChange={checkedValues =>
                    props.setFieldValue('daysOfWeek', checkedValues)
                  }
                  defaultValue={clinic?.dayInWeek}
                />
              </S.LabelText>
              {props.touched.daysOfWeek && props.errors.daysOfWeek ? (
                <S.ErrorMessage>{props.errors.daysOfWeek}</S.ErrorMessage>
              ) : null}
            </Col>
          </Row>
          <S.TitleSession marginT="1rem">Endereço</S.TitleSession>

          <Row gutter={[32, 16]} style={S.AntdStyles.marginTop2rem}>
            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                CEP
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <InputMask
                    mask="99999-999"
                    defaultValue={props.values.postal_code}
                    style={S.AntdStyles.inputMask}
                    onChange={e =>
                      props.setFieldValue('postal_code', e.target.value)
                    }
                  />
                  <Button
                    type="primary"
                    style={S.AntdStyles.buttonSearchCEP}
                    onClick={() => {
                      dispatch(getAddressByCep(props.values.postal_code));
                    }}
                  >
                    Buscar
                  </Button>
                </div>
                {props.touched.postal_code && props.errors.postal_code ? (
                  <S.ErrorMessage>{props.errors.postal_code}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col className="gutter-row" span={firstChange ? 16 : 24}>
              <S.LabelText className="input-label">
                Rua
                <Input
                  style={S.AntdStyles.marginTop}
                  id="street"
                  name="street"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.street}
                  onBlur={props.handleBlur}
                />
                {props.touched.street && props.errors.street ? (
                  <S.ErrorMessage>{props.errors.street}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Número
                <InputNumber
                  min={0}
                  style={S.AntdStyles.inputNumber}
                  value={props.values.number}
                  onChange={value => props.setFieldValue('number', value)}
                />
                {props.touched.number && props.errors.number ? (
                  <S.ErrorMessage>{props.errors.number}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col className="gutter-row" span={firstChange ? 16 : 24}>
              <S.LabelText className="input-label">
                Complemento
                <Input
                  style={S.AntdStyles.marginTop}
                  id="complement"
                  name="complement"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.complement}
                  onBlur={props.handleBlur}
                />
                {props.touched.complement && props.errors.complement ? (
                  <S.ErrorMessage>{props.errors.complement}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Bairro
                <Input
                  style={S.AntdStyles.marginTop}
                  id="neighborhood"
                  name="neighborhood"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.neighborhood}
                  onBlur={props.handleBlur}
                />
                {props.touched.neighborhood && props.errors.neighborhood ? (
                  <S.ErrorMessage>{props.errors.neighborhood}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Cidade
                <Input
                  style={S.AntdStyles.marginTop}
                  id="city"
                  name="city"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.city}
                  onBlur={props.handleBlur}
                />
                {props.touched.city && props.errors.city ? (
                  <S.ErrorMessage>{props.errors.city}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Estado
                <Dropdown
                  overlay={
                    <Menu>
                      {StatesOfBrazil.map(item => (
                        <Menu.Item>
                          <element
                            onClick={() => props.setFieldValue('state', item)}
                          >
                            {item}
                          </element>
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                  placement="bottomCenter"
                >
                  <Button style={S.AntdStyles.ButtonTypePayment}>
                    {props.values.state}
                  </Button>
                </Dropdown>
                {props.touched.state && props.errors.state ? (
                  <S.ErrorMessage>{props.errors.state}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>
          </Row>

          <Button
            type="primary"
            htmlType="submit"
            style={S.AntdStyles.ButtonSave}
            onClick={props.handleSubmit}
          >
            Salvar Alterações
          </Button>
        </div>
      )}
    </Formik>
  ) : null;
  // <div>Carregando...</div>
}

export default DataClinic;
