/* eslint-disable no-unused-vars */
import produce from 'immer';

const INITIAL_STATE = {
  self: null,
  schedule: [],
  consultsData: null,
  comparativeMonth: null,
  loading: false,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@clinicagenda/SET_SELF': {
        draft.self = action.payload.prof_self;
        break;
      }
      case '@clinicagenda/SET_SCHEDULE_CLINIC': {
        draft.schedule = action.payload.schedule;
        draft.loading = false;
        break;
      }
      case '@clinicagenda/ERASE_SCHEDULE_CLINIC': {
        draft.schedule = [];
        break;
      }
      case '@clinicagenda/GET_SCHEDULE_CLINIC': {
        draft.loading = true;
        break;
      }
      case '@clinicagenda/SET_CONSULTS_DATA': {
        draft.consultsData = action.payload.consultsData;
        break;
      }
      case '@clinicagenda/SET_MONTH_COMPARATIVE': {
        draft.comparativeMonth = action.payload.comparativeMonth;
        break;
      }

      default:
    }
  });
}
