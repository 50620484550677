/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-empty */
/* eslint-disable require-yield */
/* eslint-disable no-unused-vars */

import { takeLatest, takeEvery, all, call, put } from 'redux-saga/effects';
import { notification } from 'antd';
import { ComputerVisionTranslated } from 'utils/computerVision';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import { setComputerVisionEvaluation, setNewEvaluations } from './actions';
import api from '../../../services/api';

export function* addNewEvaluation({ payload }) {
  try {
    const { evalName, patientId } = payload;
    const response = yield call(
      api.post,
      `/patient/${patientId}/add_new_evaluation`,
      {
        name: evalName,
      },
    );
    yield put(setNewEvaluations(response.data));
  } catch (err) {}
}

export function* addComputerVisionEvaluation({ payload }) {
  try {
    const {
      activityId,
      leg_length,
      left_video,
      right_video,
      patient,
    } = payload.evaluation;
    const data = new FormData();
    data.append('activity', activityId);
    data.append('leg_length', leg_length);
    data.append('left_side_video', left_video);
    data.append('right_side_video', right_video);
    data.append('patient', patient);

    const response = yield call(api.post, `/pe/send_data_vision`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log('response', response);
    notification.success({
      message:
        'Envio de vídeos para análise realizado com sucesso! Acompanhe o resultado na tela de acompanhar na aba de visão computacional.',
    });
  } catch (err) {
    if (err === 'Network Error') {
      notification.error({
        message: 'O vídeo enviado ultrapassa o limite máximo de tempo.',
      });
    }
  }
}

export function* updateEvaluation({ payload }) {
  try {
    const { evaluation, patientId } = payload;
    const response = yield call(
      api.put,
      `/patient/${patientId}/update_evaluation`,
      {
        ...evaluation,
      },
    );
    if (response.data.SpecializedEvaluation.isFinished === true) {
      notification.success({
        message: 'Avaliação salva com sucesso!',
      });
      yield put(
        setNewEvaluations({
          content: { questions: [] },
          id: null,
          isFinished: false,
          name: evaluation.name,
          professionalId: evaluation.professionalId,
          result: null,
          computerVision: [],
        }),
      );
    }
  } catch (err) {}
}

export function* getComputerVisionEvaluation({ payload }) {
  try {
    const { activity, patient } = payload;

    const response = yield call(
      api.get,
      `/pe/get_info_vision/patient/${patient}/activity/${activity}`,
    );
    response.data.evaluations.forEach((item, index) => {
      item.name = `${ComputerVisionTranslated[item.activity]} #${
        response.data.evaluations.length - index
      } ${format(new Date(item.createdAt), 'P', {
        locale: pt,
      })} ${
        item.status === 'ANALYSING'
          ? '- em análise'
          : item.status === 'ERROR'
          ? '- erro'
          : ''
      }`;
      if (item.data !== null) {
        Object.keys(item.data).forEach(key => {
          if (item.data[key] === null) {
            item.data[key] = '-';
          }
        });
      }
    });

    yield put(setComputerVisionEvaluation(response.data.evaluations));
  } catch (err) {}
}

export default all([
  takeLatest('@evaluations/ADD_NEW_EVALUATIONS', addNewEvaluation),
  takeLatest('@evaluations/ADD_COMPUTER_VISION', addComputerVisionEvaluation),
  takeLatest('@evaluations/GET_COMPUTER_VISION', getComputerVisionEvaluation),
  takeEvery('@evaluations/UPDATE_EVALUATION', updateEvaluation),
]);
