/* eslint-disable no-useless-escape */
export default {
  minLength(value, length) {
    return value.length < length ? 1 : 0;
  },
  maxLength(value, length) {
    return value.length > length ? 1 : 0;
  },
  validChar(value) {
    return !/^[0-9a-zA-Z\_]+$/.test(value) ? 1 : 0;
  },
  isNonEmpty(value) {
    return value === '' ? 1 : 0;
  },
};

export function replaceSpecialChars(str) {
  str = str.replace(/[ÀÁÂÃÄÅ]/, 'A');
  str = str.replace(/[àáâãäå]/, 'a');
  str = str.replace(/[ÈÉÊË]/, 'E');
  str = str.replace(/[éèëê]/, 'e');
  str = str.replace(/[ÍÌÏÎ]/, 'I');
  str = str.replace(/[íìïî]/, 'i');
  str = str.replace(/[ÓÒÔÖÔ]/, 'e');
  str = str.replace(/[óòõöô]/, 'o');
  str = str.replace(/[óòõöô]/, 'o');
  str = str.replace(/[ÚÙÜ]/, 'u');
  str = str.replace(/[úùüû]/, 'u');
  str = str.replace(/[Ç]/, 'C');
  str = str.replace(/[ç]/, 'c');
  return str;
}
