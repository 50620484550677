export const PropsComplementaryExamsArrayTranslated = [
  {
    type: 'exames_complementares.ressonancia_magnetica.checked',
    pt: 'Ressonância Magnética',
  },
  {
    type: 'exames_complementares.tomografia_computadorizada.checked',
    pt: 'Tomografia Computadorizada',
  },
  {
    type: 'exames_complementares.ultrassom.checked',
    pt: 'Ultrassom',
  },
  {
    type: 'exames_complementares.raio_x.checked',
    pt: 'Raio-X',
  },
  {
    type: 'exames_complementares.outro.checked',
    pt: 'Outro',
  },
];

export const PropsComplementaryExamsInputArray = [
  {
    from: 'exames_complementares.ressonancia_magnetica.checked',
    to: 'exames_complementares.ressonancia_magnetica.local',
    label: 'Local',
  },
  {
    from: 'exames_complementares.tomografia_computadorizada.checked',
    to: 'exames_complementares.tomografia_computadorizada.local',
    label: 'Local',
  },
  {
    from: 'exames_complementares.outro.checked',
    to: 'exames_complementares.outro.qual',
    label: 'Qual',
  },
];
