import React, { memo } from 'react';
import { TimePicker, Space, Row, Col } from 'antd';
import moment from 'moment';
import 'moment/locale/pt-br';
import localePTDate from 'antd/lib/locale/pt_BR';
import SavedAnswerComponent from 'components/SavedAnswer';

import * as S from './styles';
import './styles.css';

function TextAndHourInput({
  text,
  answerKey,
  answerFunc,
  setFormValues,
  formValues,
  index,
  type,
  currentValue,
  propsFormik,
}) {
  const validationMinutes = Array.from({ length: 57 }, (_, i) => i + 3);

  function updateFormValues(value, currentIndex) {
    const tempForm = [];
    for (let i = 0; i < formValues.length; i += 1) {
      if (i !== index) {
        tempForm.push(formValues[i]);
      } else {
        const v = formValues[i].value;
        v[currentIndex] = value;
        tempForm.push({
          index: i + 1,
          text: formValues[i].text,
          value: [...v],
        });
      }
    }
    setFormValues(tempForm);
  }

  return (
    <div style={{ marginTop: '0.5rem', marginLeft: '1rem' }}>
      <ul
        style={{
          marginLeft: '1rem',
        }}
      >
        <li>
          <S.LabelText className="input-label">
            {text}
            <Row>
              <Space>
                <Col>
                  <S.LabelText
                    className="input-label"
                    style={{ marginTop: '1rem' }}
                  >
                    Medida 1
                    <TimePicker
                      style={S.AntdStyles.marginTop}
                      locale={localePTDate}
                      showTime={{ format: 'mm:ss' }}
                      format="mm:ss"
                      disabledMinutes={() => validationMinutes}
                      showNow={false}
                      defaultValue={moment(
                        currentValue[0] === '' ? '00:00' : currentValue[0],
                        'mm:ss',
                      )}
                      placeholder="Minutos"
                      onChange={(_, hourString) => {
                        answerFunc(
                          `${answerKey}1`,
                          moment(hourString, 'mm:ss').format('mm:ss'),
                        );
                        updateFormValues(
                          moment(hourString, 'mm:ss').format('mm:ss'),
                          type === 'Healthy' ? 0 : 3,
                        );
                      }}
                    />
                  </S.LabelText>
                </Col>
                <Col>
                  <S.LabelText
                    className="input-label"
                    style={{ marginTop: '1rem' }}
                  >
                    Medida 2
                    <TimePicker
                      style={S.AntdStyles.marginTop}
                      locale={localePTDate}
                      showTime={{ format: 'mm:ss' }}
                      format="mm:ss"
                      defaultValue={moment(currentValue[1], 'mm:ss')}
                      disabledMinutes={() => validationMinutes}
                      showNow={false}
                      placeholder="Minutos"
                      onChange={(_, hourString) => {
                        answerFunc(
                          `${answerKey}2`,
                          moment(hourString, 'mm:ss').format('mm:ss'),
                        );
                        updateFormValues(
                          moment(hourString, 'mm:ss').format('mm:ss'),
                          type === 'Healthy' ? 1 : 4,
                        );
                      }}
                    />
                  </S.LabelText>
                </Col>
                <Col>
                  <S.LabelText
                    className="input-label"
                    style={{ marginTop: '1rem' }}
                  >
                    Medida 3
                    <TimePicker
                      style={S.AntdStyles.marginTop}
                      locale={localePTDate}
                      showTime={{ format: 'mm:ss' }}
                      format="mm:ss"
                      defaultValue={moment(currentValue[2], 'mm:ss')}
                      disabledMinutes={() => validationMinutes}
                      showNow={false}
                      placeholder="Minutos"
                      onChange={(_, hourString) => {
                        answerFunc(
                          `${answerKey}3`,
                          moment(hourString, 'mm:ss').format('mm:ss'),
                        );
                        updateFormValues(
                          moment(hourString, 'mm:ss').format('mm:ss'),
                          type === 'Healthy' ? 2 : 5,
                        );
                      }}
                    />
                  </S.LabelText>
                </Col>
                <SavedAnswerComponent
                  resource="hasSpecializedEvaluation"
                  propsFormik={propsFormik}
                  fieldName={`${answerKey}1`}
                />
              </Space>
            </Row>
          </S.LabelText>
        </li>
      </ul>
    </div>
  );
}

export default memo(
  TextAndHourInput,
  (prev, next) => prev.propsFormik.values === next.propsFormik.values,
);
