export function getPatientAnamnesis(patientID) {
  return {
    type: '@anamnesis/GET_PATIENT_ANAMNESIS',
    payload: { patientID },
  };
}

export function setPatientAnamnesis(anamnesis) {
  return {
    type: '@anamnesis/SET_PATIENT_ANAMNESIS',
    payload: { anamnesis },
  };
}

export function savePatientAnamnesis(anamnesis, patientID) {
  return {
    type: '@anamnesis/SAVE_PATIENT_ANAMNESIS',
    payload: { anamnesis, patientID },
  };
}

export function savePatientAnamnesisFinal(anamnesis, patientID) {
  return {
    type: '@anamnesis/FINAL_SAVE_PATIENT_ANAMNESIS',
    payload: { anamnesis, patientID },
  };
}
