/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect } from 'react';
import { Layout, Menu, Dropdown } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import {
  differenceInDays,
  format,
  getMinutes,
  toDate,
  getHours,
} from 'date-fns';
import { DownOutlined } from '@ant-design/icons';
import { deleteMessage } from 'store/modules/messages/actions';
import pt from 'date-fns/esm/locale/pt';
import * as S from './styles';

function MessageItem({ data, index, messages }) {
  const firstChange = useMediaQuery('(min-width: 900px)');
  const user = useSelector(state => state.auth.user.uid);
  const chatId = useSelector(
    state => state.messages.selectedChat.selectedChatId,
  );
  const dispatch = useDispatch();

  const menu = (
    <Menu>
      <Menu.Item onClick={() => dispatch(deleteMessage(data.id, chatId))}>
        <a>Apagar mensagem</a>
      </Menu.Item>
    </Menu>
  );

  const day = useCallback(() => {
    if (
      format(data.createdAt.seconds * 1000 - 1000, 'PPPP', {
        locale: pt,
      }) !==
      format(messages[index - 1].createdAt.seconds * 1000, 'PPPP', {
        locale: pt,
      })
    ) {
      if (
        differenceInDays(
          new Date(),
          new Date(data.createdAt.seconds * 1000 - 1000),
        ) === 0
      ) {
        return 'Hoje';
      } else {
        const text = format(data.createdAt.seconds * 1000, 'PPPP', {
          locale: pt,
        });
        return text[0].toUpperCase() + text.substring(1);
      }
    }
  }, []);

  const firstDay = useCallback(
    () =>
      format(data.createdAt.seconds * 1000, 'PPPP', {
        locale: pt,
      })[0].toUpperCase() +
      format(data.createdAt.seconds * 1000, 'PPPP', {
        locale: pt,
      }).substring(1),
  );

  return (
    <>
      <S.DayContainer>
        <S.DayText>{index === 0 ? firstDay() : day(messages, index)}</S.DayText>
      </S.DayContainer>
      <S.MessageLine
        style={{
          justifyContent: user !== data.userId ? 'flex-start' : 'flex-end',
          alignSelf: user !== data.userId ? 'flex-start' : 'flex-end',
          marginRight: user !== data.userId ? '4rem' : 0,
          marginLeft: user !== data.userId ? 0 : '4rem',
        }}
      >
        <S.MessageItem
          style={{
            backgroundColor:
              user !== data.userId ? 'var(--white)' : 'var(--lavender)',
          }}
        >
          {user === data.userId ? (
            <Dropdown overlay={menu} trigger={['click']}>
              <a>
                <DownOutlined
                  style={{
                    alignSelf: user !== data.userId ? 'end' : 'end',
                  }}
                />
              </a>
            </Dropdown>
          ) : (
            <></>
          )}
          {data.messageType === 'TEXT' ? (
            <S.MessageText
              style={{
                textAlign: user !== data.userId ? 'left' : 'right',
                color:
                  user !== data.userId
                    ? 'var(--davysGrey'
                    : 'var(--deepKoamaru)',
              }}
            >
              {data.message}
            </S.MessageText>
          ) : (
            <S.MessageImage src={data.file} />
          )}
        </S.MessageItem>
        <S.DateText
          style={{
            textAlign: user !== data.userId ? 'left' : 'right',
          }}
        >
          {getHours(toDate(data.createdAt.seconds * 1000)) > 9
            ? getHours(toDate(data.createdAt.seconds * 1000))
            : `0${getHours(toDate(data.createdAt.seconds * 1000))}`}
          :
          {getMinutes(toDate(data.createdAt.seconds * 1000)) > 9
            ? getMinutes(toDate(data.createdAt.seconds * 1000))
            : `0${getMinutes(toDate(data.createdAt.seconds * 1000))}`}
        </S.DateText>
      </S.MessageLine>
    </>
  );
}

export default MessageItem;
