/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-vars */
import { takeLatest, all, call, put } from 'redux-saga/effects';
import { notification } from 'antd';

import { format, getMonth, getYear } from 'date-fns';
import pt from 'date-fns/locale/pt';

import api from 'services/api';

import { setPatientAgenda, getPatientAgenda } from './actions';

export function* getPatAgenda({ payload }) {
  try {
    const { patientID, month, year } = payload;

    const timezone = new Date().getTimezoneOffset();

    const response = yield call(
      api.get,
      `/get_schedule_to_professional/patient/${patientID}/month/${month}/year/${year}/timezone/${timezone}`,
    );

    yield put(setPatientAgenda(response.data.events));
  } catch (err) {
    notification.error({
      message: 'Houve um erro ao buscar dados da agenda, tente novamente!',
    });
  }
}

// eslint-disable-next-line require-yield
export function* addEventAgenda({ payload }) {
  try {
    const {
      title,
      timestamp,
      daysOfWeek,
      numberOfWeeks,
      details,
      local,
      protocolId,
      patientId,
    } = payload.event;
    const history = payload.history;
    let { duration } = payload.event;
    if (title === 'DOMESTIC') {
      duration = 0;
    }

    let finalTitle = 'DOMESTIC';

    if (title === 'Domiciliar') {
      finalTitle = 'DOMESTIC';
    } else if (title === 'Telechamada') {
      finalTitle = 'INLIVE';
    } else {
      finalTitle = 'PRESENTIAL';
    }

    let protocolIdValidation = null;
    if (protocolId !== undefined && protocolId !== '') {
      protocolIdValidation = protocolId;
    }

    if (Array.isArray(protocolIdValidation)) {
      if (protocolIdValidation.length > 0) {
        protocolIdValidation = protocolIdValidation[0];
      } else {
        protocolIdValidation = null;
      }
    }

    if (timestamp === 'NaN') {
      notification.error({
        message: 'Necessário seleção de data e hora para salvar o evento!',
      });
      return;
    }
    const timezone = new Date().getTimezoneOffset();

    const responseAddition = yield call(api.post, `/patient/add_new_event`, {
      title: finalTitle,
      timestamp,
      daysOfWeek,
      numberOfWeeks,
      details,
      local,
      protocolId: protocolIdValidation,
      patientId,
      duration,
      timezone,
    });

    if (responseAddition.data.results !== undefined) {
      if (responseAddition.data.results === 'Novos eventos adicionados') {
        notification.success({
          message: 'Evento criado com sucesso.',
        });

        history.push(`/${patientId}/agenda`);
      }
    }
  } catch (err) {
    notification.error({
      message:
        'Houve um erro criar um novo evento na agenda, cheque se todos os campos foram preenchidos e tente novamente!',
    });
  }
}

export function* deleteEvent({ payload }) {
  try {
    const { patientId, eventId, batch, date } = payload;

    const monthCurrent = format(date, 'MM', { locale: pt });
    const yearCurrent = format(date, 'yyyy');

    const response = yield call(
      api.delete,
      `/patient/${patientId}/event/${eventId}/batch/${batch}`,
    );

    if (response.data.results === 'Evento(s) removido') {
      notification.success({
        message: 'Evento removido com sucesso!',
      });
    }

    yield put(getPatientAgenda(patientId, monthCurrent, yearCurrent));
  } catch (err) {
    notification.error({
      message:
        'Houve um erro ao tentar deletar este evento da agenda, tente novamente. Se o erro persistir contate o suporte!',
    });
  }
}

export default all([
  takeLatest('@agenda/GET_PATIENT_AGENDA', getPatAgenda),
  takeLatest('@agenda/ADD_EVENT_AGENDA', addEventAgenda),
  takeLatest('@agenda/DELETE_EVENT', deleteEvent),
]);
