export const ProfessionalListEnum = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
};

export const TypeAccountTranslated = {
  RECEPTIONIST: 'Recepcionista',
  THERAPIST: 'Profissional de Saúde',
};

export const StatusTranslated = {
  PENDING: 'Pendente',
  ACCEPTED: 'Aceito',
  DENIED: 'Rejeitado',
};
