/* eslint-disable no-alert */
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ImgMonitoreExercises from '../../assets/exercises-monitore.svg';
import ImgBackgroundLogin from '../../assets/background-login.svg';
import ImgLogoMonitore from '../../assets/logo-monitore.svg';
import * as S from './styles';

import Login from './components/Login';
import Register from './components/Register';
import RecoverPassword from './components/RecoverPassword';
import EmailValidation from './components/EmailValidation';

function Authentication() {
  const [page, setPage] = useState('login');
  const isEmailVerified = useSelector(state => state.auth.isEmailVerified);

  useEffect(() => {
    if (!isEmailVerified) {
      setPage('emailValidation');
    }
  }, [isEmailVerified]);

  return (
    <S.Background src={ImgBackgroundLogin}>
      <S.Container>
        <S.LoginLeft>
          <S.Title>somos o monitore</S.Title>
          <S.Description>
            O sistema de acompanhamento <br /> remoto para pacientes de <br />{' '}
            fisioterapia da Neurobots.{' '}
          </S.Description>
          <S.ImageMonitoreExercises src={ImgMonitoreExercises} />
        </S.LoginLeft>

        {page === 'login' && <Login setPage={setPage} />}
        {page === 'register' && <Register setPage={setPage} />}
        {page === 'recoverPassword' && <RecoverPassword setPage={setPage} />}
        {page === 'emailValidation' && <EmailValidation setPage={setPage} />}
      </S.Container>
      <S.LogoImg src={ImgLogoMonitore} />
    </S.Background>
  );
}

export default Authentication;
