/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

export const Container = styled.div`
  padding: ${props =>
    props.mediaFirst
      ? `6rem 7rem`
      : props.mediaThird
      ? `6rem 3rem`
      : `6rem 0.5rem`};
`;

export const TitlePage = styled.h2`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal normal 20px/27px Nunito Sans;
  letter-spacing: 0px;
  opacity: 1;
`;

export const DivInterationPatient = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: ${props => (props.media ? 'row' : 'column')};
  justify-content: space-between;
  align-items: ${props => (props.media ? 'center' : 'flex-start')};
`;

export const AntdStyles = {
  RadioButtonActiveAble: {
    width: '7rem',
    textAlign: 'center',
    borderColor: '#3A3979',
    borderWidth: '1px',
    backgroundColor: '#F3F2FF',
    fontWeight: 'bold',
    color: '#6864A4',
    borderRadius: '4px 0px 0px 4px',
  },

  RadioButtonActiveDisable: {
    width: '7rem',
    textAlign: 'center',
    borderWidth: '1px',
    backgroundColor: '#f7f7f7',
    fontWeight: 'bold',
    color: '#868686',
    borderRadius: '4px 0px 0px 4px',
  },

  RadioButtonArchivedAble: {
    width: '7rem',
    textAlign: 'center',
    borderColor: '#3A3979',
    borderWidth: '1px',
    backgroundColor: '#F3F2FF',
    fontWeight: 'bold',
    color: '#3A3979',
    borderRadius: '0px 4px 4px 0px',
  },

  RadioButtonArchivedDisable: {
    width: '7rem',
    textAlign: 'center',
    borderWidth: '1px',
    backgroundColor: '#f7f7f7',
    fontWeight: 'bold',
    color: '#868686',
    borderRadius: '0px 4px 4px 0px',
  },

  ButtonAddPatient: {
    background: 'var(--darkCyan) 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    font: 'normal normal bold 14px/19px Nunito Sans',
    borderWidth: 0,
    width: '11rem',
    justifyContent: 'center',
  },

  InputSearchPatient: {
    width: '20rem',
    marginRight: '3rem',
  },

  ImgArchived: { marginRight: 8, width: 15, marginBottom: 4 },
  ImgDeleted: { marginRight: 8, width: 15, marginBottom: 4 },

  ImgActived: {
    marginRight: 8,
    marginBottom: -3,
    width: 18,
    height: 18,
  },

  ButtonArchiveOrActive: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  AvatarUser: {
    color: 'var(--darkCyan)',
    backgroundColor: '#C9E2E1',
    fontSize: 20,
  },
};

export const DivPatientList = styled.div`
  margin-top: 1rem;
`;
export const DivButtons = styled.div`
  margin-top: 1rem;
  flex-direction: row;
`;

export const LabelPatientList = styled.label`
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: bold;
  color: var(--davysGrey);
`;

export const LabelArchived = styled.label`
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: bold;
  color: var(--davysGrey);
  cursor: pointer;
`;

export const LabelTitleTable = styled.label`
  color: var(--unnamed-color-868686);
  text-align: left;
  font: normal normal normal 14px/19px Nunito Sans;
  letter-spacing: 0px;
  color: #868686;
  opacity: 1;
`;

export const DivCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
