import { Typography, Avatar } from 'antd';
import 'antd/dist/antd.less';
import './ReceptionistController.css';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { archiveProfessional } from 'store/modules/professional/actions';
import { nameHandler } from 'utils/NameHandler';
import * as S from './style';

const { Text } = Typography;

export default function ReceptionistController() {
  const infoDetails = useSelector(state => state.clinic.infoDetails);
  const history = useHistory();

  const { id } = useParams();
  const dispatch = useDispatch();

  function handleName(name) {
    if (name.length > 37) {
      return `${name.substring(0, 38)}...`;
    }
    return name;
  }

  return (
    <div className="container-patient-controller">
      <div className="container-white-background">
        <div className="container-patient-info">
          {infoDetails !== null && infoDetails.photo !== null && (
            <Avatar src={infoDetails?.photo} style={S.AntdStyles.AvatarUser} />
          )}
          {infoDetails !== null && infoDetails.photo === null && (
            <Avatar style={S.AntdStyles.AvatarUser} size={50}>
              {infoDetails?.photo === null && nameHandler(infoDetails.name)}
            </Avatar>
          )}
          <Text style={{ width: 155 }}>
            {infoDetails !== null
              ? handleName(infoDetails.name)
              : 'Carregando...'}
          </Text>
        </div>
        <div className="container-patient-controller-btns">
          <Text
            style={S.AntdStyles.linkTexts}
            onClick={() => history.push('/chat')}
          >
            Enviar Mensagem
          </Text>
          <Text
            style={S.AntdStyles.linkTexts}
            onClick={() => {
              const professional = [id];
              dispatch(archiveProfessional(professional));
            }}
          >
            Arquivar
          </Text>
        </div>
      </div>
    </div>
  );
}
