import { Modal, Button } from 'antd';

import 'moment/locale/pt-br';
import closeIcon from 'assets/close.svg';

import * as S from './styles';
import './styles.css';

export function CancelPlanModal({ isModalVisible, handleCancel, handleOk }) {
  return (
    <Modal
      visible={isModalVisible}
      onOk={() => handleOk()}
      onCancel={() => handleCancel()}
      style={{ top: '30%' }}
      width={350}
      closeIcon={
        <img src={closeIcon} alt="close" style={S.AntdStyles.iconClose} />
      }
      footer={[
        <Button
          type="primary"
          onClick={() => handleCancel()}
          style={{
            width: '130px',
            borderRadius: '5px',
            backgroundColor: 'transparent',
            borderColor: '#118E88',
            color: '#118E88',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          Voltar
        </Button>,
        <Button
          type="primary"
          onClick={() => handleOk()}
          style={{
            width: '130px',
            borderRadios: '5px',
            backgroundColor: '#118E88',
            borderColor: '#118E88',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          Cancelar Plano
        </Button>,
      ]}
    >
      <S.ContainerModal>
        <S.TitlePage>
          Você tem certeza de que deseja cancelar o plano?
        </S.TitlePage>
      </S.ContainerModal>
    </Modal>
  );
}
