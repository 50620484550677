import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Button, Popover } from 'antd';
import { updatePassword } from 'store/modules/auth/actions';
import { QuestionCircleOutlined } from '@ant-design/icons';
import * as S from './styles';

function DataPassword({ secondChange }) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);

  return (
    <Formik
      initialValues={{ email: user.email }}
      validationSchema={Yup.object({
        email: Yup.string().email().required('É obrigatório'),
      })}
      onSubmit={values => dispatch(updatePassword(values.email))}
    >
      {props => (
        <div style={{ paddingBottom: 100, width: '80vw' }}>
          <S.TitlePassword>Senha</S.TitlePassword>

          <S.DivBlockTextAndInput secondChange={secondChange}>
            <S.LabelText className="input-label">
              E-mail
              <S.Center>
                <Input
                  id="email"
                  name="email"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.email}
                  onBlur={props.handleBlur}
                  style={S.AntdStyles.marginTop}
                />

                <Popover
                  content={
                    <div>
                      <p>
                        A alteração da senha é feita através do seu e-mail,{' '}
                        <br /> insira-o ao lado e confira sua caixa de entrada.
                      </p>
                    </div>
                  }
                  placement={secondChange ? 'top' : 'topRight'}
                  style={S.AntdStyles.Final}
                >
                  <QuestionCircleOutlined style={S.AntdStyles.iconInfo} />
                </Popover>
              </S.Center>
              {props.touched.email && props.errors.email ? (
                <S.ErrorMessage>{props.errors.email}</S.ErrorMessage>
              ) : null}
            </S.LabelText>
          </S.DivBlockTextAndInput>

          <Button
            type="primary"
            htmlType="submit"
            onClick={props.handleSubmit}
            style={S.AntdStyles.ButtonRecoverPassword}
          >
            Me envie um e-mail
          </Button>
        </div>
      )}
    </Formik>
  );
}

export default DataPassword;
