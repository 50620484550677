export function getProfessionalAgenda(month, year) {
  return {
    type: '@prof_agenda/GET_PROFESSIONAL_AGENDA',
    payload: { month, year },
  };
}

export function setProfessionalAgenda(agenda) {
  return {
    type: '@prof_agenda/SET_PROFESSIONAL_AGENDA',
    payload: { agenda },
  };
}

export function deleteEventProfessionalAgenda(patientId, eventId, batch, date) {
  return {
    type: '@prof_agenda/DELETE_EVENT_PROFESSIONAL_AGENDA',
    payload: { patientId, eventId, batch, date },
  };
}
