import React, { useEffect, useRef } from 'react';
import {
  Row,
  Col,
  Input,
  DatePicker,
  Dropdown,
  Menu,
  Button,
  InputNumber,
  AutoComplete,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import InputMask from 'react-input-mask';
import './styles.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import 'moment/locale/pt-br';
import localePTDate from 'antd/lib/locale/pt_BR';
import { StatesOfBrazil } from 'utils/States';
import { typePaymentArray } from 'utils/typePayment';
import { healthPlanArray } from 'utils/healthPlan';
import { savePatientDetails } from 'store/modules/patient/actions';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import { getAddressByCep } from 'store/modules/CEP/actions';
import * as S from './styles';

function FormPatientEdit() {
  const infoDetails = useSelector(state => state.clinic.infoDetails);
  const dispatch = useDispatch();
  const firstChange = useMediaQuery('(min-width: 770px)');

  const formikGeneralData = {
    initialValues: {
      name: infoDetails?.name,
      email: infoDetails?.email,
      born: infoDetails?.born,
      cpf: infoDetails?.CPF,
      cellphone: infoDetails?.phoneNumber,
      typePayment: infoDetails?.typePayment,
      healthPlan: infoDetails?.healthPlan,
      street: infoDetails?.address?.street,
      houseNumber: infoDetails?.address?.number,
      complement: infoDetails?.address?.complement,
      neighborhood: infoDetails?.address?.neighborhood,
      city: infoDetails?.address?.city,
      state: infoDetails?.address?.state,
      postalCode: infoDetails?.address?.postal_code,
      idade: infoDetails?.age,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('É obrigatório'),
      email: Yup.string().email('E-mail inválido'),
      born: Yup.string().required('É obrigatório'),
      cellphone: Yup.string().required('É obrigatório'),
      street: Yup.string().required('É obrigatório'),
      houseNumber: Yup.number().required('É obrigatório'),
      complement: Yup.string().required('É obrigatório'),
      neighborhood: Yup.string().required('É obrigatório'),
      city: Yup.string().required('É obrigatório'),
      state: Yup.string().required('É obrigatório'),
      postalCode: Yup.string().required('É obrigatório'),
    }),
    onSubmit: values => {
      values.cpf = values.cpf.replaceAll('.', '');
      values.cpf = values.cpf.replaceAll('-', '');
      values.cellphone = values.cellphone.replaceAll('-', '');
      values.cellphone = values.cellphone.replaceAll('(', '');
      values.cellphone = values.cellphone.replaceAll(')', '');
      dispatch(savePatientDetails({ ...values, id: infoDetails.id }));
    },
  };

  const cep = useSelector(state => state.CEP);
  const formikRef = useRef(null);

  useEffect(() => {
    if (cep.address !== null) {
      formikRef.current.setFieldValue('street', cep.address.logradouro);
      formikRef.current.setFieldValue('neighborhood', cep.address.bairro);
      formikRef.current.setFieldValue('city', cep.address.cidade);
      formikRef.current.setFieldValue('state', cep.address.estado_info.nome);
    }
  }, [cep]);

  return (
    <Formik
      initialValues={formikGeneralData.initialValues}
      validationSchema={formikGeneralData.validationSchema}
      onSubmit={values => formikGeneralData.onSubmit(values)}
      innerRef={formikRef}
    >
      {props => (
        <div style={{ paddingBottom: '3rem' }}>
          <S.TitlePage>Dados Cadastrais</S.TitlePage>

          <S.DivSession>
            <S.TitleSession>Informações Gerais</S.TitleSession>
          </S.DivSession>

          <Row gutter={[32, 16]}>
            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Nome
                <Input
                  style={S.AntdStyles.marginTop}
                  id="name"
                  name="name"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.name}
                  onBlur={props.handleBlur}
                />
              </S.LabelText>
              {props.touched.name && props.errors.name ? (
                <S.ErrorMessage>{props.errors.name}</S.ErrorMessage>
              ) : null}
            </Col>

            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                CPF
                <InputMask
                  mask="999.999.999-99"
                  defaultValue={
                    props.values.cpf !== undefined ? props.values.cpf : ''
                  }
                  style={{
                    ...S.AntdStyles.inputMaskDisabled,
                    cursor:
                      props.initialValues.cpf !== ''
                        ? 'not-allowed'
                        : 'default',
                  }}
                  disabled={props.initialValues.cpf !== ''}
                  onChange={e => props.setFieldValue('cpf', e.target.value)}
                />
                {props.touched.cpf && props.errors.cpf ? (
                  <S.ErrorMessage>{props.errors.cpf}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Telefone Celular
                <InputMask
                  mask="(99)99999-9999"
                  defaultValue={props.values.cellphone}
                  style={{
                    ...S.AntdStyles.inputMaskDisabled,
                    cursor: 'default',
                  }}
                  onChange={e =>
                    props.setFieldValue('cellphone', e.target.value)
                  }
                />
                {props.touched.cellphone && props.errors.cellphone ? (
                  <S.ErrorMessage>{props.errors.cellphone}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Data de Nascimento
                <DatePicker
                  style={S.AntdStyles.marginTop}
                  onChange={(_, dateString) => {
                    props.setFieldValue(
                      'born',
                      moment(dateString, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                    );
                  }}
                  defaultValue={moment(props.values.born, 'YYYY-MM-DD')}
                  locale={localePTDate}
                  format="DD-MM-YYYY"
                  allowClear={false}
                />
                {props.touched.born && props.errors.born ? (
                  <S.ErrorMessage>{props.errors.born}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>
            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Idade
                <Input
                  style={S.AntdStyles.marginTop}
                  id="idade"
                  name="idade"
                  type="text"
                  value={props.values.idade}
                  onBlur={props.handleBlur}
                  disabled
                />
                {props.touched.idade && props.errors.idade ? (
                  <S.ErrorMessage>{props.errors.idade}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                E-mail
                <Input
                  style={S.AntdStyles.marginTop}
                  id="email"
                  name="email"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.email}
                  onBlur={props.handleBlur}
                />
                {props.touched.email && props.errors.email ? (
                  <S.ErrorMessage>{props.errors.email}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Tipos de Pagamentos
                <Dropdown
                  overlay={
                    <Menu>
                      {typePaymentArray.map(item => (
                        <Menu.Item>
                          <element
                            onClick={() =>
                              props.setFieldValue('typePayment', item)
                            }
                          >
                            {item}
                          </element>
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                  placement="bottomCenter"
                >
                  <Button style={S.AntdStyles.ButtonTypePayment}>
                    {props.values.typePayment === null ||
                    props.values.typePayment === ''
                      ? 'Não Informado'
                      : props.values.typePayment}
                  </Button>
                </Dropdown>
                {props.touched.typePayment && props.errors.typePayment ? (
                  <S.ErrorMessage>{props.errors.typePayment}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>
            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Planos de Saúde
                <AutoComplete
                  style={S.AntdStyles.AutoComplete}
                  defaultValue={
                    props.values.healthPlan === null
                      ? 'Não Informado'
                      : props.values.healthPlan
                  }
                  onChange={text => props.setFieldValue('healthPlan', text)}
                  options={healthPlanArray.map(
                    item =>
                      item && {
                        value: item,
                      },
                  )}
                />
                {props.touched.healthPlan && props.errors.healthPlan ? (
                  <S.ErrorMessage>{props.errors.healthPlan}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>
          </Row>

          <S.DivSession style={S.AntdStyles.DivSessionAddress}>
            <S.TitleSession>Endereço</S.TitleSession>
          </S.DivSession>

          <Row gutter={[32, 16]} style={S.AntdStyles.marginTop2rem}>
            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                CEP
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <InputMask
                    mask="99999-999"
                    defaultValue={props.values.postalCode}
                    style={S.AntdStyles.inputMask}
                    onChange={e =>
                      props.setFieldValue('postalCode', e.target.value)
                    }
                  />
                  <Button
                    type="primary"
                    style={S.AntdStyles.buttonSearchCEP}
                    onClick={() => {
                      dispatch(getAddressByCep(props.values.postalCode));
                    }}
                  >
                    Buscar
                  </Button>
                </div>
                {props.touched.postalCode && props.errors.postalCode ? (
                  <S.ErrorMessage>{props.errors.postalCode}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col className="gutter-row" span={firstChange ? 16 : 24}>
              <S.LabelText className="input-label">
                Rua
                <Input
                  style={S.AntdStyles.marginTop}
                  id="street"
                  name="street"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.street}
                  onBlur={props.handleBlur}
                />
                {props.touched.street && props.errors.street ? (
                  <S.ErrorMessage>{props.errors.street}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Número
                <InputNumber
                  min={0}
                  style={S.AntdStyles.inputNumber}
                  defaultValue={props.values.houseNumber}
                  onChange={value => props.setFieldValue('houseNumber', value)}
                />
                {props.touched.houseNumber && props.errors.houseNumber ? (
                  <S.ErrorMessage>{props.errors.houseNumber}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col className="gutter-row" span={firstChange ? 16 : 24}>
              <S.LabelText className="input-label">
                Complemento
                <Input
                  style={S.AntdStyles.marginTop}
                  id="complement"
                  name="complement"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.complement}
                  onBlur={props.handleBlur}
                />
                {props.touched.complement && props.errors.complement ? (
                  <S.ErrorMessage>{props.errors.complement}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Bairro
                <Input
                  style={S.AntdStyles.marginTop}
                  id="neighborhood"
                  name="neighborhood"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.neighborhood}
                  onBlur={props.handleBlur}
                />
                {props.touched.neighborhood && props.errors.neighborhood ? (
                  <S.ErrorMessage>{props.errors.neighborhood}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Cidade
                <Input
                  style={S.AntdStyles.marginTop}
                  id="city"
                  name="city"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.city}
                  onBlur={props.handleBlur}
                />
                {props.touched.city && props.errors.city ? (
                  <S.ErrorMessage>{props.errors.city}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Estado
                <Dropdown
                  overlay={
                    <Menu style={S.AntdStyles.menuStates}>
                      {StatesOfBrazil.map(state => (
                        <Menu.Item>
                          <element
                            onClick={() => props.setFieldValue('state', state)}
                          >
                            {state}
                          </element>
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                  placement="bottomCenter"
                >
                  <Button style={S.AntdStyles.ButtonTypePayment}>
                    {props.values.state}
                  </Button>
                </Dropdown>
                {props.touched.state && props.errors.state ? (
                  <S.ErrorMessage>{props.errors.state}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>
          </Row>

          <Button
            type="primary"
            htmlType="submit"
            onClick={props.handleSubmit}
            style={S.AntdStyles.ButtonSave}
          >
            Salvar Alterações
          </Button>
          {/* <Divider />
          <S.DeleteText>
            Você pode deletar a conta deste usuário por meio do botão abaixo. No
            entanto, esta opção exclui também os dados e registros relacionados
            a este perfil. Para apenas desativá-lo temporariamente mantendo as
            suas informações, clique em “Arquivar” na lista de pacientes.
          </S.DeleteText>
          <Button
            type="secondary"
            htmlType="submit"
            onClick={props.handleSubmit}
            style={{
              marginTop: 20,
              marginBottom: 50,
              backgroundColor: 'transparent',
              borderColor: '#118E88',
              borderRadius: 5,
              color: '#118E88',
            }}
          >
            Apagar Conta
          </Button> */}
        </div>
      )}
    </Formik>
  );
}

export default FormPatientEdit;
