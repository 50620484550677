import styled from 'styled-components';

export const ContainerQuestionSixMinWalk = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  align-items: center;
`;

export const LabelText = styled.div`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal bold 14px/19px Nunito Sans;
  letter-spacing: 0px;
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

export const SavedAnswerLabel = styled.label`
  text-align: left;
  font: normal normal normal 12px/18px Nunito Sans;
  letter-spacing: 0px;
  color: #b1b1b1;
  opacity: 1;
`;

export const AntdStyles = {
  inputNumber: { width: '20%', marginTop: '4px' },

  ButtonSave: {
    background: 'var(--darkCyan) 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    opacity: 1,
    width: '10rem',
    margin: '1rem 0',
    fontWeight: 'bold',
  },
};
