import styled from 'styled-components';

export const AntdStyles = {
  btnCallPatient: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    borderRadius: '5px',
    font: 'normal normal bold 14px/19px Nunito Sans',
    background: '#6963a9',
    borderColor: '#6963a9',
    paddingLeft: '.1%',
    paddingRight: '.1%',
    width: '80%',
  },

  linkTexts: {
    color: '#6963a9',
    fontWeight: 'bold',
    font: 'normal normal bold 14px/19px Nunito Sans',
    cursor: 'pointer',
  },

  AvatarUser: {
    color: 'var(--darkCyan)',
    backgroundColor: '#C9E2E1',
    fontSize: 20,
  },
};

export const ImageCamera = styled.img`
  display: flex;
  align-items: center;
  width: 16px;
  height: 9px;
`;
