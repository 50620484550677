/* Layouts */

export const home_layout = {
  font: {
    family: 'Helvetica',
  },
  title: {
    font: {
      size: 12,
    },
  },
  hovermode: 'closest',
  showlegend: false,
  width: 550,
  height: 150,
  margin: { l: 5, t: 0, r: 10, b: 25 },
  xaxis: {
    showticklabels: true,
    tickangle: 0,
    showline: false,
    ticks: '',
    range: [0, 200],
  },
  yaxis: {
    showticklabels: false,
    showline: false,
    ticks: '',
    zeroline: false,
    range: [0, 10],
  },
  paper_bgcolor: 'transparent',
  plot_bgcolor: 'transparent',
};

export const layoutHorizontalBarChart = {
  barmode: 'stack',
  showlegend: false,
  width: 350,
  height: 50,
  margin: { l: 10, t: 0, r: 10, b: 20 },
  xaxis: {
    showticklabels: true,
    showline: false,
    zeroline: false,
    ticks: 'outside',
    dtick: 1,
    range: [0, 10],
  },
  font: {
    family: 'Nunito Sans',
  },
  yaxis: {
    showticklabels: false,
    showline: false,
    ticks: '',
    zeroline: false,
    range: [0, 2],
  },
  paper_bgcolor: 'transparent',
  plot_bgcolor: 'transparent',
};

export const verticalBarLayout = {
  font: {
    family: 'Nunito Sans',
  },
  title: {
    font: {
      size: 10,
      family: 'Nunito Sans',
    },
  },
  hovermode: 'closest',
  showlegend: false,
  width: 550,
  height: 150,
  margin: { l: 5, t: 0, r: 10, b: 25 },
  xaxis: {
    showticklabels: true,
    tickangle: 0,
    showline: false,
    ticks: '',
    range: [0, 200],
  },
  yaxis: {
    showticklabels: false,
    showline: false,
    ticks: '',
    zeroline: false,
    range: [0, 10],
  },
  marker: {
    color: 'rgb(158,202,225)',
  },
  bargap: 1,
  paper_bgcolor: 'transparent',
  plot_bgcolor: 'transparent',
};

export const defaultLayout = {
  font: {
    family: 'Helvetica',
  },
  title: {
    font: {
      size: 12,
    },
  },
  hovermode: 'closest',
  showlegend: false,
  width: 550,
  height: 150,
  margin: { l: 5, t: 0, r: 10, b: 25 },
  xaxis: {
    showticklabels: true,
    tickangle: 45,
    showline: false,
    ticks: '',
    range: [0, 200],
  },
  yaxis: {
    showticklabels: false,
    showline: false,
    ticks: '',
    zeroline: false,
    range: [0, 10],
  },
  paper_bgcolor: 'transparent',
  plot_bgcolor: 'transparent',
};

/* Data */

export const firstDash = {
  x: [0, 200],
  y: [10, 10],
  type: 'scatter',
  mode: 'lines',
  line: {
    color: '#D5D5D5',
    width: 2,
    dash: 'dash',
  },
  name: '',
};

/* Functions */

export function newDash(y) {
  const dashLine = {
    x: [0, 200],
    y: [y, y],
    type: 'scatter',
    mode: 'lines',
    opacity: 0.5,
    line: {
      color: '#B1B1B1',
      width: 2,
      dash: 'dash',
    },
    name: '',
  };
  return dashLine;
}

export function newBarData(x, y) {
  const bar = {
    type: 'bar',
    x: [x],
    y: [y],
    width: 2.5,
    marker: {
      color: '#118E88',
    },
    name: '',
  };

  return bar;
}

export function newComparisonFirstBarHorizontal(x, text) {
  const trace = {
    x: [x],
    y: [text],
    name: '',
    orientation: 'h',
    marker: {
      color: '#9A91DB',
      width: 1,
    },
    type: 'bar',
  };

  return trace;
}

export function newComparisonSecondBarHorizontal(x, text) {
  const trace = {
    x: [x],
    y: [text],
    name: '',
    orientation: 'h',
    marker: {
      color: '#D5D5D5',
      width: 1,
    },
    type: 'bar',
  };

  return trace;
}

export function responsiveLayout(x, texts) {
  const layout = {
    font: {
      family: 'Helvetica',
    },
    title: {
      font: {
        size: 12,
      },
    },

    hovermode: 'closest',
    showlegend: false,
    width: 550,
    height: 150,
    margin: { l: 5, t: 0, r: 10, b: 31 },
    xaxis: {
      showticklabels: true,
      tickangle: 45,
      showline: false,
      ticks: '',
      range: [1, 200],
      tickvals: x, // [10, 30],
      ticktext: texts, // ['28/03', '31/04'],
      showgrid: false,
    },
    yaxis: {
      showticklabels: false,
      showline: false,
      ticks: '',
      zeroline: false,
      range: [0, 11],
      showgrid: false,
    },
    paper_bgcolor: 'transparent',
    plot_bgcolor: 'transparent',
  };

  return layout;
}

export function responsiveLayoutTicks(x, texts) {
  const layout = {
    font: {
      family: 'Helvetica',
    },
    title: {
      font: {
        size: 12,
      },
    },
    hovermode: false,
    showlegend: false,
    width: 550,
    height: 200,
    margin: { l: 15, t: 0, r: 10, b: 45 },
    xaxis: {
      showticklabels: true,
      tickangle: 45,
      showline: false,
      ticks: '',
      range: [1, 200],
      tickvals: x, // [10, 30],
      ticktext: texts, // ['28/03', '31/04'],
      showgrid: false,
    },
    yaxis: {
      showticklabels: true,
      showline: false,
      dtick: 2,
      ticks: '',
      zeroline: false,
      range: [0, 11],
      showgrid: false,
    },
    paper_bgcolor: 'transparent',
    plot_bgcolor: 'transparent',
  };

  return layout;
}

export function responsiveLayoutFall(x, texts) {
  const layout = {
    font: {
      family: 'Helvetica',
    },
    title: {
      font: {
        size: 12,
      },
    },
    hovermode: false,
    showlegend: false,
    width: 550,
    height: 150,
    margin: { l: 25, t: 5, r: 10, b: 25 },
    xaxis: {
      showticklabels: true,
      tickangle: 0,
      showline: false,
      ticks: '',
      range: [1, 200],
      tickvals: x, // [10, 30],
      ticktext: texts, // ['28/03', '31/04'],
      showgrid: false,
    },
    yaxis: {
      showticklabels: true,
      showline: false,
      dtick: 2,
      ticks: '',
      tickvals: [0, 1], // [10, 30],
      ticktext: ['Não', 'Sim'], // ['28/03', '31/04'],
      zeroline: false,
      range: [0, 1],
      showgrid: false,
    },
    paper_bgcolor: 'transparent',
    plot_bgcolor: 'transparent',
  };

  return layout;
}
