/* eslint-disable no-nested-ternary */
import React, { useContext, useState } from 'react';
import { Divider, Button } from 'antd';
import StarImg from 'assets/star.svg';
import { useDispatch } from 'react-redux';

import { cancelPlan } from 'store/modules/payment/actions';
import PlanContext from '../../Context/PlanContext';

import * as S from './styles';
import { CancelPlanModal } from './components/CancelPlanModal';

export function Package({ packageData = [], isShowButtonContract = false }) {
  const { changeScreen } = useContext(PlanContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();

  function handleOk() {
    dispatch(cancelPlan());
    setIsModalVisible(false);
  }

  function handleCancel() {
    setIsModalVisible(false);
  }

  return (
    <S.Container>
      <CancelPlanModal
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <S.ContainerPlanTitle>
        <strong className="plan-name">{packageData.packageName}</strong>
        <strong className="plan-price">{packageData.price}</strong>
      </S.ContainerPlanTitle>
      <S.ContainerDivider>
        <Divider />
      </S.ContainerDivider>
      <S.ContainerPlanOferts>
        <S.ContainerPlanSubTitles>
          <img src={StarImg} alt="estrela" />
          <strong>Este pacote inclui:</strong>
        </S.ContainerPlanSubTitles>
        <label>{packageData.content_include}</label>
      </S.ContainerPlanOferts>
      <S.ContainerPlanOferts>
        {isShowButtonContract === true ? (
          <S.ContainerButton>
            <Button
              key="primary"
              type="default"
              style={S.AntdStyles.ButtonChangePlan}
              onClick={() => changeScreen('payment', packageData.packageName)}
            >
              <strong>Contratar Pacote</strong>
            </Button>
          </S.ContainerButton>
        ) : (
          <></>
        )}
      </S.ContainerPlanOferts>
    </S.Container>
  );
}
