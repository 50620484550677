export function getMonitoringData(patientID) {
  return {
    type: '@monitor/GET_MONITORING_DATA',
    payload: { patientID },
  };
}

export function setMonitoringData(monitoringData) {
  return {
    type: '@monitor/SET_MONITORING_DATA',
    payload: { monitoringData },
  };
}
