export const AntdStyles = {
  RadioButtonActiveAbleLeft: {
    width: '7rem',
    textAlign: 'center',
    borderColor: '#3A3979',
    borderWidth: '1px',
    backgroundColor: '#F3F2FF',
    fontWeight: 'bold',
    color: '#6864A4',
    borderRadius: '4px 0px 0px 4px',
  },

  RadioButtonActiveDisableLeft: {
    width: '7rem',
    textAlign: 'center',
    borderWidth: '1px',
    backgroundColor: '#f7f7f7',
    fontWeight: 'bold',
    color: '#868686',
    borderRadius: '4px 0px 0px 4px',
  },

  RadioButtonActiveAbleRight: {
    width: '7rem',
    textAlign: 'center',
    borderColor: '#3A3979',
    borderWidth: '1px',
    backgroundColor: '#F3F2FF',
    fontWeight: 'bold',
    color: '#6864A4',
    borderRadius: '0px 4px 4px 0px',
  },

  RadioButtonActiveDisableRight: {
    width: '7rem',
    textAlign: 'center',
    borderWidth: '1px',
    backgroundColor: '#f7f7f7',
    fontWeight: 'bold',
    color: '#868686',
    borderRadius: '0px 4px 4px 0px',
  },
};
