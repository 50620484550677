import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 160px;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
  background: #fff;
  overflow: hidden;

  text-align: left;
  letter-spacing: 0px;
  opacity: 1;

  span {
    margin: 0px 0px 10px 10px;
    font: normal normal normal 14px/16px Nunito Sans;

    &.practice {
      color: var(--battleshipGrey);
    }
  }

  strong {
    margin: 10px 0px 10px 10px;
    font: normal normal bold 14px/16px Nunito Sans;
    color: var(--davysGrey);
  }
`;

export const ContainerVideos = styled.div`
  display: flex;
  width: 120%;
  padding-top: 10px;
  position: relative;
`;

export const Video = styled.div`
  width: 100%;
  height: 100%;
  width: 100px;
  height: 60px;
  margin-left: 10px;
  position: relative;
`;

export const ContainerSubInfo = styled.div`
  display: flex;
  width: 95%;
  justify-content: space-between;
`;

export const UntouchableScreen = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
`;

export const AntdStyles = {
  DropDownButton: {
    background: 'transparent',
    color: 'var(--davysGrey)',
    border: 'none',
    cursor: 'pointer',
    zIndex: 10,
  },
};
