import { takeLatest, call, all, put } from 'redux-saga/effects';
import { notification } from 'antd';

import api from '../../../services/api';
// import app from '../../../services/fire';

import {
  setLoading,
  setSubscriptionStatus,
  getSubscriptionStatus,
} from './actions';

import { getUser } from '../auth/actions';

export function* getSubscription() {
  try {
    const response = yield call(api.get, `/payment/subscriptionStatus`);
    yield put(setSubscriptionStatus(response.data));
  } catch (err) {
    notification.error({
      message:
        'Não foi possível receber os dados do pagamento, reinicie a página e tente novamente.',
    });
  }
}

export function* updateCredCard({ payload }) {
  const { card } = payload;
  try {
    const response = yield call(api.put, `/payment/updateCard`, {
      payment: {
        paymentMean: 'CARD',
        paymentInfos: {
          cardNumber: card.cardNumber,
          cardName: card.cardName,
          cardFlag: card.cardFlag,
          cardUsageTimeMonth: card.cardUsageTime.split('/')[0],
          cardUsageTimeYear: `20${card.cardUsageTime.split('/')[1]}`,
          cardSecuryCode: card.cardSecuryCode,
        },
      },
    });
    if (response.title !== undefined) {
      notification.error({
        message: response.toPrint,
      });
    }
    if (response.results !== undefined) {
      notification.error({
        message: response.toPrint,
      });
    }
    yield put(getSubscriptionStatus());
    yield put(setLoading(false));

    notification.success({
      message: 'Cartão de crédito atualizado com sucesso!',
    });
  } catch (err) {
    notification.error({
      message: 'Não foi possível atualizar os dados do cartão.',
    });
    yield put(setLoading(false));
  }
}

export function* purchase({ payload }) {
  const { info } = payload;

  yield put(setLoading(true));

  try {
    const response = yield call(api.post, `/payment/purchase`, {
      planId: info.contractId,
      name: info.name,
      document: info.cpf,
      phoneNumber: info.cellPhone,
      payment: {
        paymentMean: 'CARD',
        existingCard: info.existingCard,
        paymentInfos: {
          cardNumber: info.cardNumber,
          cardName: info.cardName,
          cardFlag: info.cardFlag,
          cardUsageTimeMonth: info.cardUsageTime.split('/')[0],
          cardUsageTimeYear: `20${info.cardUsageTime.split('/')[1]}`,
          cardSecuryCode: info.cardSecuryCode,
        },
      },
    });

    yield put(setLoading(false));

    if (response.title !== undefined) {
      notification.error({
        message: response.toPrint,
      });
    }

    if (response.results !== undefined) {
      notification.error({
        message: response.toPrint,
      });
    }

    notification.success({
      message: 'Plano adiquirido',
    });

    yield put(getSubscriptionStatus());
    yield put(getUser());
  } catch (err) {
    yield put(setLoading(false));

    notification.error({
      message:
        'Não foi possível atualizar os dados do cartão. Cheque todos os campos e tente novamente!',
    });
  }
}

export function* purchasePackage({ payload }) {
  const { info } = payload;

  yield put(setLoading(true));

  try {
    const response = yield call(api.post, `/payment/purchase_package`, {
      packageId: info.contractId,
      name: info.name,
      document: info.cpf,
      phoneNumber: info.cellPhone,
      payment: {
        paymentMean: 'CARD',
        existingCard: info.existingCard,
        paymentInfos: {
          cardNumber: info.cardNumber,
          cardName: info.cardName,
          cardFlag: info.cardFlag,
          cardUsageTimeMonth: info.cardUsageTime.split('/')[0],
          cardUsageTimeYear: `20${info.cardUsageTime.split('/')[1]}`,
          cardSecuryCode: info.cardSecuryCode,
        },
      },
    });

    yield put(setLoading(false));

    if (response.title !== undefined) {
      notification.error({
        message: response.toPrint,
      });
    }

    if (response.results !== undefined) {
      notification.error({
        message: response.toPrint,
      });
    }

    notification.success({
      message: 'Pacote adiquirido',
    });

    yield put(getSubscriptionStatus());
    yield put(getUser());
  } catch (err) {
    yield put(setLoading(false));

    notification.error({
      message:
        'Não foi possível atualizar os dados do cartão. Cheque todos os campos e tente novamente!',
    });
  }
}

export function* cancel() {
  try {
    yield call(api.delete, `/payment/cancelMyPlan`);
    notification.success({
      message:
        'Seu plano foi cancelado com sucesso. Você terá acesso aos benefícios do plano até a data de término.',
    });
    yield put(getSubscriptionStatus());
    yield put(getUser());
  } catch (err) {
    notification.error({
      message:
        'Não foi possível cancelar seu plano. Tente novamente mais tarde!',
    });
  }
}

export function* contactUsPayment({ payload }) {
  try {
    const { quantProfessionals, observations } = payload;

    yield call(api.post, `/payment/contactUs`, {
      quantProfessionals,
      observations,
    });

    notification.success({
      message:
        'Mensagem enviada com sucesso. Em breve entraremos em contato com você.',
    });
  } catch (err) {
    notification.error({
      message:
        'Não foi possível enviar a mensagem. Tente novamente mais tarde!',
    });
  }
}

export default all([
  takeLatest('@payment/GET_SUBSCRIPTION_STATUS', getSubscription),
  takeLatest('@payment/UPDATE_CARD', updateCredCard),
  takeLatest('@payment/PURCHASE_PLAN', purchase),
  takeLatest('@payment/PURCHASE_PACKAGE', purchasePackage),
  takeLatest('@payment/CANCEL_PLAN', cancel),
  takeLatest('@payment/CONTACT_US_PAYMENT', contactUsPayment),
]);
