import styled from 'styled-components';

export const AntdStyles = {
  header: {
    position: 'fixed',
    zIndex: 5,
    width: '100%',
    background: '#118E88',
    borderBottom: '4px solid',
    borderColor: '#55bfb8',
    height: '60px',
  },

  menu: {
    display: 'flex',
    background: 'none',
    color: 'white',
    width: '100%',
    height: '100%',
    border: 'none',
  },

  hederTitle: {
    color: 'white',
  },

  buttonLogin: {
    background: '#fff',
    borderRadius: '4px',
    width: '5rem',
    color: '#118E88',
    marginRight: '1rem',
    borderWidth: '1px solid #118E88',
  },
  buttonRegister: {
    backgroundColor: '#118E88',
    borderColor: '#118E88',
    borderRadius: '4px',
    width: '8rem',
    color: '#fff',
    border: '1px solid var(--darkBlueGray)',
  },
  // buttonRegister: {
  //   background: 'var(--white) 0% 0% no-repeat padding-box',
  //   borderRadius: '4px',
  //   marginTop: '1.5rem',
  //   width: '7rem',
  //   color: 'var(--darkBlueGray)',
  //   border: '1px solid var(--darkBlueGray)',
  // },

  dropDownProfessional: {
    display: 'flex',
    color: 'white',
    width: '50%',
    height: '100%',
    paddingLeft: '20px',
  },

  menuDropDownProfessional: {
    marginTop: '14px',
    height: 'auto',
    padding: '10px',
    border: 'none',
  },

  menuNotification: {
    marginTop: '24px',
    width: '380px',
    height: '380px',
    overflow: 'auto',
  },

  btnAddAccount: {
    width: '80%',
    borderColor: '#6963a9',
    color: '#6963a9',
  },

  menuitem1js: {
    marginLeft: '10%',
  },

  userContainerItem: {
    width: '100%',
    height: '100%',
    display: 'flex',
  },

  AvatarUser: {
    color: '#118E88',
    backgroundColor: '#C9E2E1',
    fontSize: 18,
  },

  AvatarUserItem: {
    color: '#118E88',
    backgroundColor: '#C9E2E1',
    fontSize: 18,
    marginRight: 10,
    position: 'absolute',
    top: 6,
    left: 0,
    fontWeight: 'normal',
  },

  AvatarClinicItem: {
    color: '#118E88',
    backgroundColor: '#C9E2E1',
    position: 'absolute',
    top: 18,
    left: 20,
    fontWeight: 'normal',
    border: '2px solid #fff',
  },

  AvatarClinicHeader: {
    color: '#118E88',
    backgroundColor: '#C9E2E1',
    borderRadius: '12px',
    position: 'absolute',
    top: 14,
    left: 22,
    border: '2px solid #118E88',
  },

  subMenuInfoProfessional: {
    color: 'white',
  },
  notifContainer: {
    marginLeft: 45,
    marginTop: -30,
    width: 40,
    alignSelf: 'center',
    justifyContent: 'center',
    backgroundColor: '#118E88',
    borderRadius: 5,
  },
  notifText: {
    color: '#fff',
    font: 'normal normal normal 12px/21px Nunito Sans',
    textAlign: 'center',
  },
  paragraphText: {
    color: '#575757',
    font: 'normal normal normal 14px/22px Nunito Sans',
  },
  insideParagraphText: {
    color: '#868686',
    font: 'normal normal normal 14px/22px Nunito Sans',
  },
};

export const NotificationDiv = styled.div`
  height: 50;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const NotificationText = styled.h1`
  color: #575757;
  margin-left: 5;
  font: normal normal normal 20px/21px Nunito Sans;
`;

export const Background = styled.div`
  background-color: var(--darkCyan);
  flex: 1;
  align-items: center;
  justify-content: center;
  background-size: cover;
`;

export const BackgroundAvatar = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  height: 100%;
  cursor: pointer;
`;

export const ContainerInfoProfessional = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 100%;
  cursor: pointer;
`;

export const ImageMonitoreLogoHeader = styled.img`
  width: 25%;
  min-width: 300px;
  @media (min-width: 990px) {
    display: flex;
  }

  @media (max-width: 990px) {
    display: none;
    padding-left: 1rem;
  }
`;

export const ImageNotification = styled.img`
  display: flex;
  align-items: center;
  width: 18px;
  height: 100%;
  cursor: pointer;
`;

export const ImageMessage = styled.img`
  display: flex;
  align-items: center;
  width: 20px;
  height: 100%;
  cursor: pointer;
`;

export const BackgroundInfoProfessional = styled.img`
  display: flex;
  width: 50%;
  height: 100%;
  cursor: pointer;
`;

export const NotificationBadge = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 50px;
  background-color: #74f2de;
  position: absolute;
  top: 40px;
`;

export const TextsDiv = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: '#000';
`;

export const Header = styled.div`
  background: none;
  color: white;
  width: 100%;
  height: 100%;
  border: none;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  margin: 0;
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const DivLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DivNavText = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
`;

export const ItemHeader = styled.div`
  border-bottom: none;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  margin-right: 12px;
`;

export const ItemTextHeader = styled.span`
  font-size: 14px;
`;

export const ItemHeaderIcons = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ItemIcon = styled.a`
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`;

export const ItemHeaderProfile = styled.div`
  color: white;
  border-bottom: none;
  width: 230px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  cursor: pointer;
`;

export const ItemHeaderNavProfile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 15px;
  width: 100%;
`;
