/* eslint-disable react/no-deprecated */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; //

import * as S from './Timer';

let nbTimer = '';
let countTimer = 0;
let intervalToUpdateTimer = null;
let auxProps = null;
let startControl = true;

export default class Timer extends Component {
  constructor(props) {
    super(props);
    auxProps = props;
    this.state = {
      timer: <label className="gray-text-color">{nbTimer}</label>,
    };
  }

  componentDidMount() {
    if (this.props.action === 'play') {
      if (startControl) {
        // Evitar que reinicie cronômetro em pauses...
        startControl = false;
        nbTimer = '00:00:00';
        countTimer = 0;
      }
      this.startChronometer(this.props);
    }

    if (this.props.action === 'stop') {
      startControl = true;
      this.stopChronometer();
    }
  }

  componentWillUnmount() {
    startControl = true;
    this.stopChronometer();
  }

  componentWillReceiveProps = props => {
    const { newProps } = auxProps;
    const self = this;
    if (props !== newProps) {
      if (props.action === 'play') {
        if (startControl) {
          // Evitar que reinicie cronômetro em pauses...
          startControl = false;
          nbTimer = '00:00:00';
          countTimer = 0;
          self.startChronometer(props);
        }
      }
      if (props.action === 'stop') {
        startControl = true;
        self.stopChronometer();
      }
      if (props.action === 'pause') {
        self.stopChronometer();
      }
    }
  };

  startChronometer = props => {
    const self = this;
    let mTime = 0;
    function startTimerCount() {
      const date = new Date(0);
      date.setSeconds(countTimer); // specify value for SECONDS here
      nbTimer = date.toISOString().substr(11, 8);
      countTimer += 1;
      mTime += 1;

      self.setState({
        timer: (
          // eslint-disable-next-line prettier/prettier
          <label
            className={mTime < 5100 ? 'gray-text-color' : 'red-text-color'}
          >
            {nbTimer}
          </label>
        ),
      });

      if (mTime === 5100) {
        confirmAlert({
          title: 'Atenção! ',
          message: 'Faltam 10 minutos para o fim desta chamada.',
          buttons: [
            {
              label: 'Ok',
            },
          ],
        });
      }
      if (mTime === 5400) {
        props.endVideoCall(props.videoCallId, 'video-call-time-expired');
      }
    }
    intervalToUpdateTimer = setInterval(() => {
      startTimerCount();
    }, 1000);
  };

  stopChronometer = () => {
    if (intervalToUpdateTimer != null) {
      clearInterval(intervalToUpdateTimer);
      this.setState({
        timer: '',
      });
    }
  };

  render() {
    return <S.ContainerTimer>{this.state.timer}</S.ContainerTimer>;
  }
}
