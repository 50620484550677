import styled from 'styled-components';

export const LabelText = styled.div`
  text-align: left;
  letter-spacing: 0px;
  display: flex;
  flex-direction: column;
  width: auto;
  font: normal normal normal 14px/19px Nunito Sans;
  color: #575757;
`;

export const SavedAnswerLabel = styled.label`
  text-align: left;
  font: normal normal normal 12px/18px Nunito Sans;
  letter-spacing: 0px;
  color: #b1b1b1;
  opacity: 1;
`;
