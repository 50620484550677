/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

export const Container = styled.div`
  padding: ${props =>
    props.mediaFirst
      ? `10rem 7rem`
      : props.mediaThird
      ? `6rem 3rem`
      : `6rem 0.5rem`};
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AlertText = styled.h3`
  color: var(--davysGrey);
  text-align: center;
  font: normal normal bold 26px/43px Nunito Sans;
  letter-spacing: 0px;
  opacity: 1;
`;

export const TitlePage = styled.h2`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal normal 26px/33px Nunito Sans;
  letter-spacing: 0px;
  margin-left: 0.1rem;
  opacity: 1;
`;

export const TitleSiders = styled.h3`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal bold 14px/18px Nunito Sans;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 1rem;
`;

export const TextFilters = styled.h3`
  color: var(--davysGrey);
  font: normal normal normal 14px/18px Nunito Sans;
  letter-spacing: 0px;
  opacity: 1;
`;

export const DivInterationPatient = styled.div`
  margin-top: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: ${props => (props.media ? 'center' : 'flex-start')};
`;

export const AntdStyles = {
  ButtonAddPatient: {
    background: 'var(--darkCyan) 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    borderWidth: 0,
    width: '10rem',
    marginTop: '1rem',
    marginBottom: '2rem',
  },

  InputSearchPatient: {
    width: '20rem',
    marginRight: '3rem',
  },

  ImgArchived: { marginRight: 8, width: 15, marginBottom: 4 },
  ImgDeleted: { marginRight: 8, width: 16, marginBottom: 4 },

  ImgActived: {
    marginRight: 8,
    marginBottom: -3.5,
    width: 20,
    height: 20,
  },

  ButtonArchiveOrActive: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  AvatarUser: {
    color: 'var(--darkCyan)',
    backgroundColor: '#C9E2E1',
    fontSize: 20,
  },
};

export const LabelText = styled.div`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal bold 14px/19px Nunito Sans;
  letter-spacing: 0px;
  display: flex;
  flex-direction: column;
`;

export const DivPatientList = styled.div`
  margin-top: 1rem;
  flex: 1;
  width: 100%;
`;

export const LabelPatientList = styled.label`
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: bold;
  color: var(--davysGrey);
`;

export const LabelArchived = styled.label`
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: bold;
  color: var(--davysGrey);
  cursor: pointer;
`;

export const LabelTitleTable = styled.label`
  color: var(--unnamed-color-868686);
  text-align: left;
  font: normal normal normal 14px/19px Nunito Sans;
  letter-spacing: 0px;
  color: #868686;
  opacity: 1;
`;

export const DivCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
