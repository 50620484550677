/* eslint-disable no-unused-vars */
import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  agenda: null,
  practice: [],
  practiceId: '',
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@agenda/GET_PATIENT_AGENDA': {
        draft.loading = true;
        break;
      }

      case '@agenda/SET_PATIENT_AGENDA': {
        draft.loading = false;
        draft.agenda = action.payload.agenda;
        break;
      }

      case '@agenda/ADD_PRACTICE_TO_EVENT': {
        draft.practice = action.payload.practice;
        draft.practiceId = action.payload.id;
        break;
      }

      default:
    }
  });
}
