import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import patient from './patient/sagas';
import anamnesis from './anamnesis/sagas';
import goals from './goals/sagas';
import agenda from './agenda/sagas';
import professional_agenda from './professional_agenda/sagas';
import evaluations from './evaluations/sagas';
import protocol from './protocol/sagas';
import messages from './messages/sagas';
import monitor from './monitor/sagas';
import professional from './professional/sagas';
import clinic from './clinic/sagas';
import clinicagenda from './clinicagenda/sagas';
import video_call from './video_call/sagas';
import professional_content from './professional_content/sagas';
import payment from './payment/sagas';
import CEP from './CEP/sagas';

export default function* rootSaga() {
  return yield all([
    auth,
    patient,
    goals,
    anamnesis,
    agenda,
    professional_agenda,
    evaluations,
    protocol,
    messages,
    monitor,
    professional,
    video_call,
    professional_content,
    payment,
    clinic,
    clinicagenda,
    CEP,
  ]);
}
