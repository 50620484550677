/* eslint-disable no-unused-vars */
import { ConsoleSqlOutlined } from '@ant-design/icons';
import produce from 'immer';

const INITIAL_STATE = {
  patientList: null,
  patientDetails: null,
  loading: false,
  patientBeingRaised: {
    name: '',
    email: '',
    born: new Date(),
    cellphone: '',
    typePayment: null,
    healthPlan: null,
    street: '',
    houseNumber: '',
    complement: '',
    neighborhood: '',
    city: '',
    state: 'Selecione',
    postalCode: '',
    idade: '',
  },
  disableCellphone: false,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@patient/GET_PATIENTS_LIST': {
        draft.loading = true;
        break;
      }

      case '@patient/SET_PATIENTS_LOADING': {
        draft.loading = action.payload.status;
        break;
      }

      case '@patient/SET_PATIENTS_LIST': {
        draft.patientList = action.payload.patientList;
        draft.loading = false;
        break;
      }

      case '@patient/GET_PATIENT_DETAILS': {
        draft.loading = true;
        break;
      }

      case '@patient/SET_PATIENT_DETAILS': {
        draft.patientDetails = action.payload.patientDetails;
        draft.loading = false;
        break;
      }

      case '@patient/PATIENT_BEING_RAISED': {
        draft.patientBeingRaised = action.payload.patient;
        break;
      }
      case '@patient/DISABLE_CPF_PATIENT': {
        draft.disableCellphone = action.payload.isDisabled;
        break;
      }

      default:
    }
  });
}
