export function getExerciseCatalog() {
  return {
    type: '@protocol/GET_EXERCISE_CATALOG',
  };
}

export function setExerciseCatalog(exerciseCatalog) {
  return {
    type: '@protocol/SET_EXERCISE_CATALOG',
    payload: { exerciseCatalog },
  };
}

export function setActivitySelected(activitySelected) {
  return {
    type: '@protocol/SET_ACTIVITY_SELECTED',
    payload: { activitySelected },
  };
}

export function setAddedActivitiesToProtocol(addedActToProtocol) {
  return {
    type: '@protocol/SET_ADDED_ACTIVITIES_TO_PROTOCOL',
    payload: { addedActToProtocol },
  };
}

export function saveProtocol(
  patientId,
  name,
  type,
  protId,
  addedActToProtocol,
) {
  return {
    type: '@protocol/SAVE_PROTOCOL',
    payload: { patientId, name, type, protId, addedActToProtocol },
  };
}

export function getProtocolsCreated(patientId) {
  return {
    type: '@protocol/GET_PROTOCOLS_CREATED',
    payload: { patientId },
  };
}

export function setProtocolsCreated(protocols) {
  return {
    type: '@protocol/SET_PROTOCOLS_CREATED',
    payload: { protocols },
  };
}

export function unlinkProtocol(patientId, protocolId) {
  return {
    type: '@protocol/UNLINK_PROTOCOL',
    payload: { patientId, protocolId },
  };
}
