/* eslint-disable no-alert */
/* eslint-disable prefer-template */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Layout,
  Menu,
  Avatar,
  Dropdown,
  Typography,
  Button,
  List,
  notification,
  Divider,
} from 'antd';
import { showHeaderVinculated } from 'utils/showHeaderVinculated';
import { isMobile } from 'react-device-detect';

import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import ImageLogoHeader from 'assets/logo-header.svg';
import ImageNotifications from 'assets/notification.svg';
import ImageMessageEnv from 'assets/message-envelop.svg';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import { signOut } from 'store/modules/auth/actions';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useState, useCallback } from 'react';
import {
  getContacts,
  getNotifications,
  readAllNotifications,
  sendTokenPN,
} from 'store/modules/messages/actions';
import InfiniteScroll from 'react-infinite-scroller';
import IconClose from 'assets/close.svg';
import envelopeGreen from 'assets/envelope_green.svg';
import {
  differenceInDays,
  getDate,
  getHours,
  getMinutes,
  getMonth,
} from 'date-fns';
import { PropsProfileEnum, PropsProfileTranslated } from 'utils/profile';
import { onMessageListener, getToken, permission } from 'services/fire';
import {
  answerInviteClinic,
  changeActiveProfile,
} from 'store/modules/clinic/actions';
import { nameHandler } from 'utils/NameHandler';

import { ShortMenu } from './ShortMenu';

import 'antd/dist/antd.less';
import './antd-elements.css';

import * as S from './style';

import { RegisterModal } from './components/RegisterModal';
import JoinClinicModal from './components/JoinClinicModal';

const { Paragraph, Text } = Typography;
const { Header } = Layout;

export default function AppHeader() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const newMessages = useSelector(state => state.messages.newMessages);
  const newNotifications = useSelector(
    state => state.messages.newNotifications,
  );
  const sixthChange = useMediaQuery('(min-width: 1400px)');
  const fifthChange = useMediaQuery('(min-width: 1230px)');
  const fourthChange = useMediaQuery('(min-width: 1120px)');
  const minorChange = useMediaQuery('(min-width: 1060px)');
  const thirdChange = useMediaQuery('(min-width: 1100px)');
  const isBiggerThan = useMediaQuery('(min-width: 990px)');
  const isShowMenu = useMediaQuery('(min-width: 770px)');
  const user = useSelector(state => state.auth.user);
  const [activeProfile, setActiveProfile] = useState(null);
  const [isVisibleJoinModal, setIsvisibleJoinModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  useEffect(() => {
    if (user) {
      const profile = user.profiles.find(
        item => item.id === user.active_profile_id,
      );
      setActiveProfile(profile);
    }
  }, [user]);

  const isClinic = useSelector(
    state =>
      state.auth?.user?.active_profile_name !== PropsProfileEnum.THERAPIST,
  );
  const notifications = useSelector(state => state.messages.notifications);
  const [rerender, setRerender] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [vinculated, setVinculated] = useState(false);

  const profileTranslated = useCallback(profile => {
    const result = PropsProfileTranslated.find(p => p.type === profile).pt;
    return result;
  }, []);

  useEffect(() => {
    // console.tron.log('NOTIFICATIONS: ', notifications);
  }, [notifications]);

  const handleClickMain = e => {
    switch (e) {
      case '1':
        if (isClinic) {
          history.push('/patient_list_clinic');
        } else {
          history.push('/patient_list');
        }
        break;
      case '2':
        if (isClinic) {
          history.push('/professional_list');
        } else {
          history.push(`/professional_agenda`);
        }
        break;
      case '3':
        history.push(`/consults`);
        break;
      case '4':
        history.push(`/content`);
        break;
      default:
        break;
    }
  };

  const handleData = data => {
    if (
      getMinutes(new Date()) - getMinutes(new Date(data)) < 5 &&
      getHours(new Date()) === getHours(new Date(data)) &&
      getDate(new Date()) === getDate(new Date(data)) &&
      getMonth(new Date()) === getMonth(new Date(data))
    ) {
      return 'Agora';
    }
    if (
      getHours(new Date()) === getHours(new Date(data)) &&
      getDate(new Date()) === getDate(new Date(data)) &&
      getMonth(new Date()) === getMonth(new Date(data))
    ) {
      return `Há ${
        getMinutes(new Date()) - getMinutes(new Date(data))
      } minutos`;
    }
    if (
      getHours(new Date()) !== getHours(new Date(data)) &&
      getDate(new Date()) === getDate(new Date(data)) &&
      getMonth(new Date()) === getMonth(new Date(data))
    ) {
      return `Há ${getHours(new Date()) - getHours(new Date(data))} horas`;
    }
    return `Há ${differenceInDays(new Date(), new Date(data))} dias`;
  };

  const handleToken = async () => {
    if (permission) {
      const token = await getToken();
      dispatch(sendTokenPN(token));
    }
  };

  useEffect(() => {
    handleToken();
  }, []);

  const openNotification = message => {
    notification.open({
      message: message.title,
      description: message.body,
      duration: 3,
    });
  };

  function handleCancel() {
    setIsModalVisible(false);
  }

  useEffect(() => {
    if (showHeaderVinculated(user)) {
      dispatch(getContacts());
    }
    dispatch(getNotifications(1, []));
  }, []);

  // removendo conteúdo do cache pra resolver o problema da tela em branco
  const deleteCache = () => {
    if ('caches' in window) {
      caches.keys().then(names => {
        // Delete all the cache files
        names.forEach(name => {
          caches.delete(name).then(res => {});
        });
      });
    }
  };

  useEffect(() => {
    deleteCache();
  }, []);

  useEffect(() => {
    if (permission) {
      onMessageListener()
        .then(message => {
          if (message.notification?.title !== undefined) {
            setRerender(rerender + 1);
            openNotification(message.notification);
          }
          if (message.data?.type === 'NOTIFICATION') {
            dispatch(getNotifications(1, []));
          }
          if (message.data?.type === 'NEW_MESSAGE') {
            setRerender(rerender + 1);
            if (showHeaderVinculated(user)) {
              dispatch(getContacts());
            }
          }
          // para recuperar as notificações novamente e pegar atualizaçào
        })
        // eslint-disable-next-line no-console
        .catch(err => {});
    }
  }, [rerender]);

  const [isVisibleNotification, setIsVisibleNotification] = useState(false);

  const notificationmenu = (
    <Menu style={S.AntdStyles.menuNotification}>
      <InfiniteScroll
        useWindow={false}
        initialLoad={false}
        style={{ padding: 10 }}
        loadMore={() => null}
      >
        <S.NotificationDiv>
          <S.NotificationText>Notificações</S.NotificationText>
          <img
            alt="pesquisa"
            src={IconClose}
            style={{ height: 14, cursor: 'pointer' }}
            onClick={() => setIsVisibleNotification(false)}
          />
        </S.NotificationDiv>
        <List
          itemLayout="vertical"
          size="large"
          dataSource={notifications}
          renderItem={item => (
            <List.Item
              key={item.content}
              style={{
                backgroundColor: item.markAsRead === true ? '#FFF' : '#F7F7F7',
                height: 'auto',
                cursor: 'pointer',
                borderRadius: 2,
                marginBottom: 10,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <List.Item.Meta
                avatar={
                  item.imageUrl === null ? (
                    <Avatar
                      size={40}
                      style={{
                        ...S.AntdStyles.AvatarUser,
                      }}
                    >
                      {nameHandler(item.involvedName)}
                    </Avatar>
                  ) : (
                    <Avatar
                      src={item.imageUrl}
                      style={{
                        height: 40,
                        width: 40,
                        marginLeft: -10,
                        alignSelf: 'center',
                        marginTop: item.markAsRead === false ? 0 : -10,
                      }}
                    />
                  )
                }
                description={
                  <div>
                    <Typography>
                      <Paragraph style={S.AntdStyles.paragraphText}>
                        {item.content}{' '}
                        <Text strong style={S.AntdStyles.insideParagraphText}>
                          {handleData(item.createdAt)}
                        </Text>
                      </Paragraph>
                    </Typography>
                    {item.type === 'INVITE_PENDING' ? (
                      <div style={{ flexDirection: 'row' }}>
                        <Button
                          type="primary"
                          style={S.AntdStyles.buttonLogin}
                          onClick={() =>
                            dispatch(
                              answerInviteClinic(
                                false,
                                item.involvedId,
                                item.extraData.inviteId,
                                item.id,
                                false,
                              ),
                            )
                          }
                          htmlType="submit"
                        >
                          Recusar
                        </Button>
                        <Button
                          type="primary"
                          style={S.AntdStyles.buttonRegister}
                          onClick={() => {
                            setCurrentItem(item);
                            setIsvisibleJoinModal(true);
                            setIsVisibleNotification(false);
                          }}
                          htmlType="submit"
                        >
                          Aceitar
                        </Button>
                      </div>
                    ) : null}
                  </div>
                }
              />
              {item.markAsRead === false ? (
                <div
                  style={{
                    ...S.AntdStyles.notifContainer,
                    marginLeft: item.type === 'INVITE_PENDING' ? 10 : 45,
                  }}
                >
                  <h1 style={S.AntdStyles.notifText}>Nova</h1>
                </div>
              ) : (
                <></>
              )}
            </List.Item>
          )}
        />
      </InfiniteScroll>
    </Menu>
  );

  const menu = (
    <Menu style={S.AntdStyles.menuDropDownProfessional}>
      <Menu.Item
        onClick={() =>
          isClinic
            ? history.push('/my_account_clinic')
            : history.push('/my_account')
        }
      >
        <Text>Minha Conta</Text>
      </Menu.Item>
      {showHeaderVinculated(user) && (
        <Menu.Item onClick={() => history.push('/subscription')}>
          <Text>Minha Assinatura</Text>
        </Menu.Item>
      )}
      <Menu.Item
        onClick={() => {
          history.push('/');
          dispatch(signOut());
        }}
      >
        <Text>Sair</Text>
      </Menu.Item>
      <div
        style={{
          width: '200px',
          height: 1,
          marginLeft: 12,
          marginTop: 10,
          marginBottom: 20,
          backgroundColor: '#D5D5D5',
        }}
      />
      <div
        style={{
          marginLeft: 15,
        }}
      >
        <Text style={{ color: '#B1B1B1' }}>Trocar Conta</Text>
      </div>
      {user?.profiles?.map(item =>
        item.id !== user.active_profile_id ? (
          <Menu.Item
            onClick={() => {
              dispatch(changeActiveProfile(item.id, item, history));
            }}
          >
            <Button
              style={{
                height: '100%',
                width: '100%',
                flexDirection: 'column',
                borderColor: 'transparent',
                backgroundColor: 'transparent',
              }}
            >
              {item?.photo ? (
                <Avatar src={item?.photo} style={S.AntdStyles.AvatarUserItem} />
              ) : (
                <Avatar size={36} style={S.AntdStyles.AvatarUserItem}>
                  {item?.photo === null && nameHandler(item.name)}
                </Avatar>
              )}
              {item?.clinicPhoto !== null ? (
                <Avatar
                  src={item?.clinicPhoto}
                  size={24}
                  style={S.AntdStyles.AvatarClinicItem}
                />
              ) : item?.clinicName !== null ? (
                <Avatar size={24} style={S.AntdStyles.AvatarClinicItem}>
                  {item?.clinicPhoto === null && nameHandler(item?.clinicName)}
                </Avatar>
              ) : null}
              <Text
                style={{
                  display: 'flex',
                  marginLeft: 30,
                  marginTop: 2,
                  font: 'normal normal bold 14px/18px Nunito Sans',
                }}
              >
                {item.name.length > 24
                  ? `${item.name.substring(0, 24)}...`
                  : item.name}
              </Text>
              <Text
                style={{
                  display: 'flex',
                  marginLeft: 30,
                  color: '#868686',
                  font: 'normal normal normal 14px/18px Nunito Sans',
                }}
              >
                {profileTranslated(PropsProfileEnum[item.profile])}
              </Text>
              {profileTranslated(PropsProfileEnum[item.profile]) ===
                'Profissional de saúde' && (
                <Text
                  style={{
                    display: 'flex',
                    marginLeft: 30,
                    color: '#868686',
                    font: 'normal normal normal 14px/18px Nunito Sans',
                  }}
                >
                  {item?.clinicName !== null ? 'da clínica' : 'independente'}
                </Text>
              )}
            </Button>
          </Menu.Item>
        ) : null,
      )}
      <Menu.Item>
        <Button
          type="primary"
          icon={<PlusOutlined style={{ opacity: 0.5 }} />}
          onClick={() => setIsModalVisible(true)}
          style={{
            width: '100%',
            borderRadius: '5px',
            backgroundColor: 'transparent',
            borderColor: '#6963A9',
            color: '#6963A9',
            font: 'normal normal bold 14px/19px Nunito Sans',
          }}
        >
          Adicionar Conta
        </Button>
      </Menu.Item>
    </Menu>
  );

  const handleNameSize = name => {
    if (!fifthChange && name.length > 14) {
      return `${name.substring(0, 14)}...`;
    }
    if (name.length > 19) {
      return `${name.substring(0, 18)}...`;
    }
    return name;
  };

  function handleCancelJoinModal() {
    setIsvisibleJoinModal(false);
  }

  return (
    <>
      <div
        style={{
          ...S.AntdStyles.header,
          padding: isBiggerThan ? '0 7rem' : '0 1rem',
        }}
      >
        <RegisterModal
          isModalVisible={isModalVisible}
          handleCancel={handleCancel}
        />
        <JoinClinicModal
          isVisible={isVisibleJoinModal}
          handleCancel={handleCancelJoinModal}
          item={currentItem}
        />

        <S.Header
          style={{
            display: isShowMenu ? 'flex' : 'none',
          }}
        >
          <S.HeaderContent>
            <S.DivLogo>
              {thirdChange && (
                <S.ImageMonitoreLogoHeader
                  src={ImageLogoHeader}
                  style={{ cursor: 'pointer' }}
                  onClick={() => history.push('/')}
                />
              )}
            </S.DivLogo>
            {showHeaderVinculated(user) && (
              <S.DivNavText>
                <S.ItemHeader
                  style={{
                    backgroundColor:
                      location?.pathname === '/patient_list' ||
                      location?.pathname === '/patient_list_clinic'
                        ? '#275E5B'
                        : '#118E88',
                    borderRadius:
                      location?.pathname === '/patient_list' ||
                      location?.pathname === '/patient_list_clinic'
                        ? '8px'
                        : '0',
                    width: '100px',
                  }}
                  onClick={() => handleClickMain('1')}
                >
                  <S.ItemTextHeader>Pacientes</S.ItemTextHeader>
                </S.ItemHeader>
                <S.ItemHeader
                  style={{
                    backgroundColor:
                      location?.pathname === `/professional_agenda` ||
                      location?.pathname === '/professional_list_admin' ||
                      location?.pathname === '/professional_list'
                        ? '#275E5B'
                        : '#118E88',
                    borderRadius:
                      location?.pathname === `/professional_agenda` ||
                      location?.pathname === '/professional_list_admin' ||
                      location?.pathname === '/professional_list'
                        ? '8px'
                        : '0',

                    width: isClinic ? '120px' : '100px',
                  }}
                  onClick={() => handleClickMain('2')}
                >
                  <S.ItemTextHeader>
                    {isClinic ? 'Profissionais' : 'Agenda'}
                  </S.ItemTextHeader>
                </S.ItemHeader>
                {isClinic ? (
                  <S.ItemHeader
                    style={{
                      backgroundColor:
                        location?.pathname === `/consults`
                          ? '#275E5B'
                          : '#118E88',
                      borderRadius:
                        location?.pathname === `/consults` ? '8px' : '0',
                      width: '100px',
                    }}
                    onClick={() => handleClickMain('3')}
                  >
                    <S.ItemTextHeader>Consultas</S.ItemTextHeader>
                  </S.ItemHeader>
                ) : (
                  <S.ItemHeader
                    style={{
                      backgroundColor:
                        location?.pathname === '/content'
                          ? '#275E5B'
                          : '#118E88',
                      borderRadius:
                        location?.pathname === '/content' ? '8px' : '0',
                      width: '100px',
                    }}
                    onClick={() => handleClickMain('4')}
                  >
                    <S.ItemTextHeader>Conteúdos</S.ItemTextHeader>
                  </S.ItemHeader>
                )}
              </S.DivNavText>
            )}
          </S.HeaderContent>

          <S.ItemHeaderIcons>
            <Dropdown
              overlay={notificationmenu}
              trigger={['click']}
              visible={isVisibleNotification}
              onVisibleChange={isVisible => {
                setIsVisibleNotification(isVisible);
                if (!isVisible) {
                  const index = 1;
                  dispatch(getNotifications(index, []));
                } else {
                  dispatch(readAllNotifications());
                }
              }}
            >
              <S.ItemIcon>
                <S.ImageNotification src={ImageNotifications} />
              </S.ItemIcon>
            </Dropdown>
            {newNotifications > 0 ? (
              <S.NotificationBadge style={{ marginLeft: 20 }} />
            ) : (
              <></>
            )}
            {showHeaderVinculated(user) && (
              <>
                <S.ItemIcon>
                  <S.ImageMessage
                    src={ImageMessageEnv}
                    onClick={() => history.push('/chat')}
                  />
                </S.ItemIcon>
                {newMessages > 0 ? (
                  <S.NotificationBadge style={{ marginLeft: 62 }} />
                ) : (
                  <></>
                )}
              </>
            )}
          </S.ItemHeaderIcons>

          <S.ItemHeaderProfile>
            <Dropdown
              overlay={menu}
              trigger="click"
              style={{
                width: '100%',
                border: 'none',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <S.ItemHeaderNavProfile>
                <S.BackgroundAvatar
                  style={{
                    marginLeft: '2%',
                    position: 'relative',
                    marginRight: '15px',
                  }}
                >
                  {user?.photo ? (
                    <Avatar src={user?.photo} style={S.AntdStyles.AvatarUser} />
                  ) : (
                    <Avatar size={40} style={S.AntdStyles.AvatarUser}>
                      {user?.photo === null && nameHandler(user.name)}
                    </Avatar>
                  )}

                  {activeProfile?.clinicPhoto !== null ? (
                    <Avatar
                      src={activeProfile?.clinicPhoto}
                      size={24}
                      style={S.AntdStyles.AvatarClinicHeader}
                    />
                  ) : activeProfile?.clinicName !== null ? (
                    <Avatar size={24} style={S.AntdStyles.AvatarClinicHeader}>
                      {activeProfile?.clinicPhoto === null &&
                        nameHandler(activeProfile?.clinicName)}
                    </Avatar>
                  ) : null}
                </S.BackgroundAvatar>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '130px',
                  }}
                >
                  <span
                    style={{
                      color: 'white',
                      height: '20px',
                      fontSize: '14px',
                    }}
                  >
                    {handleNameSize(user?.name)}
                  </span>
                  {activeProfile?.clinicName !== null &&
                    activeProfile?.clinicName !== undefined && (
                      <span
                        style={{
                          color: 'white',
                          fontSize: '12px',
                        }}
                      >
                        {handleNameSize(activeProfile?.clinicName)}
                      </span>
                    )}
                </div>
                <DownOutlined
                  style={{
                    fontSize: 16,
                    alignSelf: 'center',
                    marginLeft: '5px',
                  }}
                />
              </S.ItemHeaderNavProfile>
            </Dropdown>
          </S.ItemHeaderProfile>
        </S.Header>
        {!isShowMenu && <ShortMenu />}
      </div>

      {!isMobile && (
        <Button
          type="primary"
          style={{
            position: 'absolute',
            bottom: 20,
            right: 70,
            width: 'auto',
            height: '40px',
            padding: '10px',
            background: '#118E88 0% 0% no-repeat padding-box',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            zIndex: 5,
          }}
          onClick={e => {
            window.location = 'mailto:suporte@neurobots.com.br';
            e.preventDefault();
          }}
        >
          <img
            src={envelopeGreen}
            alt="fale conosco"
            style={{
              width: '20px',
              height: '20px',
            }}
          />
          <Divider
            type="vertical"
            style={{
              backgroundColor: '#71BDB7',
              height: '220%',
              marginTop: '2px',
            }}
          />
          <span>Fale conosco</span>
        </Button>
      )}
    </>
  );
}
