/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Row, Button, Table, Space, Avatar } from 'antd';

import 'moment/locale/pt-br';

import { useState, useEffect } from 'react';
import { attributePatientToProfessional } from 'store/modules/patient/actions';
import { getTherapistsWithInvites } from 'store/modules/clinic/actions';
import {
  PropsSpecialityEnumUpper,
  SpecialityTranslatedFromArray,
} from 'utils/speciality';
import { nameHandler } from 'utils/NameHandler';
import closeIcon from 'assets/close.svg';

import * as S from './styles';
import './styles.css';

export function AttributeProfessionalModal({
  isModalVisible,
  handleAttributeModalFalse,
  patientsList,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTherapistsWithInvites());
  }, []);

  const [professionalID, setProfessionalID] = useState('');
  const therapistsAndInvites = useSelector(
    state => state.clinic.therapistsAndInvites,
  );
  const [data, setData] = useState([]);
  useEffect(() => {
    if (therapistsAndInvites !== null) {
      setData(
        therapistsAndInvites.map((therapist, index) => ({
          ...therapist,
          key: index,
        })),
      );
    }
  }, [therapistsAndInvites]);

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'professionalName',
      render: (text, record) => (
        <Space size="middle">
          <Avatar size={20} src={record.photo} style={S.AntdStyles.AvatarUser}>
            {nameHandler(text)}
          </Avatar>
          <label>{text}</label>
        </Space>
      ),
    },
    {
      title: 'Especialidades',
      dataIndex: 'specialities',
      render: text => (
        <Space
          style={{
            alignSelf: 'center',
            marginLeft: text.length === 0 ? '2.5rem' : '0rem',
          }}
        >
          {text.length === 0
            ? '-'
            : text.map(
                (item, index) =>
                  `${
                    item.speciality !== PropsSpecialityEnumUpper.OTHER
                      ? SpecialityTranslatedFromArray(item.speciality)
                      : item.otherSpecialityName
                  } ${index + 1 === text.length ? '' : '/'}`,
              )}
        </Space>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'professionalEmail',
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setProfessionalID(selectedRows[0].id);
    },
  };

  return (
    <Modal
      title="Atribuir a um profissional"
      visible={isModalVisible}
      onOk={() => handleAttributeModalFalse()}
      onCancel={() => handleAttributeModalFalse()}
      style={{ top: 20 }}
      width={850}
      closeIcon={
        <img src={closeIcon} alt="close" style={S.AntdStyles.iconClose} />
      }
      footer={[
        <Button
          type="primary"
          onClick={() => handleAttributeModalFalse()}
          style={{
            width: '180px',
            borderRadius: '5px',
            backgroundColor: 'transparent',
            borderColor: '#118E88',
            color: '#118E88',
          }}
        >
          Cancelar
        </Button>,
        <Button
          type="primary"
          onClick={() => {
            const patientsID = [];
            const personalInfoID = [];
            patientsList.forEach(item => {
              patientsID.push(item.id);
              personalInfoID.push(item.personalInfoId);
            });
            let submitList = [];
            submitList = patientsID.map((_, index) => ({
              personalInfoId: personalInfoID[index],
              patientId: patientsID[index],
              professionalId: professionalID,
            }));
            dispatch(attributePatientToProfessional(submitList));
            handleAttributeModalFalse();
          }}
          style={{
            width: '180px',
            borderRadius: '5px',
            backgroundColor: '#118E88',
            borderColor: '#118E88',
          }}
        >
          Adicionar
        </Button>,
      ]}
    >
      <S.ContainerModal>
        <Row>
          <S.TitleSession>Pacientes a serem atribuidos:</S.TitleSession>
          {patientsList.map(item => (
            <S.SubtitleSession>{item.name};</S.SubtitleSession>
          ))}
        </Row>
        <S.TitlePage>Lista de Profissionais</S.TitlePage>

        <Table
          rowSelection={{
            type: 'radio',
            ...rowSelection,
          }}
          columns={columns}
          dataSource={data}
          pagination={{
            defaultPageSize: 500,
          }}
        />
      </S.ContainerModal>
    </Modal>
  );
}
