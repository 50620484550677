import React from 'react';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';
import { addDays, format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import closeIcon from 'assets/close.svg';

import * as S from './styles';
import './styles.css';

export function ModalVideoCallsPerformedInfo({
  isModalVisible = false,
  handleOk,
  handleCancel,
}) {
  const videocallsUsed = useSelector(state => state.video_call.video_call_used);

  return (
    <Modal
      title="Videochamadas Realizadas"
      visible={isModalVisible}
      onOk={() => handleOk()}
      onCancel={() => handleCancel()}
      width={450}
      closeIcon={
        <img src={closeIcon} alt="close" style={S.AntdStyles.iconClose} />
      }
      footer={null}
    >
      {videocallsUsed === null ? (
        <div>Carregando...</div>
      ) : (
        <>
          <S.Container>
            <span>{`Ciclo: ${format(
              addDays(new Date(videocallsUsed.cycle.start), 1),
              'dd/MM/yyyy',
              {
                locale: pt,
              },
            )} até ${format(
              addDays(new Date(videocallsUsed.cycle.end), 1),
              'dd/MM/yyyy',
              {
                locale: pt,
              },
            )}`}</span>
            <S.ContainerPatientPlusVideoCalls>
              {videocallsUsed.professionals.map(item => (
                <S.ContainerPatient>
                  <strong>{item.professionalName}</strong>
                  <label>{item.quantVideocall}</label>
                </S.ContainerPatient>
              ))}
            </S.ContainerPatientPlusVideoCalls>
          </S.Container>
          <S.ContainerTotal>
            <strong>Total</strong>
            <strong>{`${videocallsUsed.teleconsultation.consumed}/${videocallsUsed.teleconsultation.total}`}</strong>
          </S.ContainerTotal>
        </>
      )}
    </Modal>
  );
}
