/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Button } from 'antd';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateEvaluation } from 'store/modules/evaluations/actions';
import DoubleTextAndRadioGroup from '../../components/DoubleTextAndRadioGroup';
import TextAndTextArea from '../../components/TextAndTextArea';
import * as S from './styles';

function MAL({ title, current_eval }) {
  const dispatch = useDispatch();
  const { id } = useParams();

  const qts = current_eval?.content?.questions;

  const forms = [
    { label: '0', value: '0' },
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
  ];

  const Questions = ['Escala Quantitativa', 'Escala Qualitativa'];

  const sessions = [
    '1. Acender a luz pelo interruptor',
    '2. Abrir uma gaveta',
    '3. Tirar uma peça de roupa da gaveta',
    '4. Tirar o telefone do gancho',
    '5. Passar um pano (limpar) na bancada da cozinha ou outra superfície',
    '6. Sair do carro (inclui apenas o movimento necessário para levantar do banco e ficar em pé fora do carro, depois que a porta estiver aberta)',
    '7. Abrir a geladeira',
    '8. Abrir uma porta girando a maçaneta',
    '9. Utilizar o controle remoto da TV',
    '10. Lavar as mãos (inclui ensaboar e enxaguar as mãos; não inclui abrir/fechar uma torneira manual)',
    '11. Abrir e fechar uma torneira de rosca ou alavanca',
    '12. Secar as mãos',
    '13. Colocar as meias',
    '14. Tirar as meias',
    '15. Calçar os sapatos (inclui amarrar os cadarços e ajustar os velcros ou as tiras)',
    '16. Tirar os sapatos (inclui desamarrar cadarços e soltar os velcros os as tiras)',
    '17. Levantar-se de uma cadeira com apoio de braço',
    '18. Afastar a cadeira da mesa antes de se assentar',
    '19. Puxar a cadeira em direção à mesa após estar assentado',
    '20. Levantar um copo, garrafa (de vidro ou plástico) ou lata (não precisa incluir beber)',
    '21. Escovar os dentes (não inclui a preparação da escova de dente ou escovar a dentadura, a menos que esta seja escovada dentro da boca)',
    '22. Colocar base de maquiagem, loção ou creme de barbear no rosto',
    '23. Usar uma chave para destrancar uma porta',
    '24. Escrever no papel (se a mão utilizada para escrever antes do derrame é a mais afetada, pontue o item; se a mão que não escrevia antes do derrame é a mais afetada, pule o item e assinale N/A)',
    '25. Carregar um objeto na mão (dependurar um item sobre braço não é aceitável)',
    '26. Usar um garfo ou uma colher para se alimentar (se refere à ação de levar a comida até a boca com o garfo ou colher)',
    '27. Pentear o cabelo',
    '28. Levantar uma xícara pela alça',
    '29. Abotoar uma camisa',
    '30. Comer metade de um sanduíche, tira-gosto ou petiscos (qualquer alimento que se come com a mão)',
  ];

  const [formValues, setFormValues] = useState(
    current_eval?.content?.questions.length > 0
      ? sessions.map((item, index) => ({
          index: index + 1,
          text: item,
          value: [...current_eval.content.questions[index].value],
        }))
      : sessions.map((item, index) => ({
          index: index + 1,
          text: item,
          value: ['', '', ''],
        })),
  );

  function updateValues(isFinal) {
    const tempFormValue = {
      id: current_eval?.id,
      isFinished: isFinal,
      name: title,
      professionalId: current_eval?.professionalId,
      result: null,
      content: {
        questions: formValues,
      },
    };
    dispatch(updateEvaluation(tempFormValue, id));
    if (isFinal) {
      setFormValues(
        sessions.map((item, index) => ({
          index: index + 1,
          text: item,
          value: ['', '', ''],
        })),
      );
    }
  }

  useEffect(() => {
    const valuesFormValues = Object.values(formValues);
    if (
      valuesFormValues.find(
        item => JSON.stringify(item.value) !== JSON.stringify(['', '', '']),
      )
    ) {
      updateValues(false);
    }
  }, [formValues]);

  return (
    <Formik
      initialValues={{
        AnswerQuant1: qts[0] !== undefined ? qts[0].value[0] : '',
        AnswerQuant2: qts[0] !== undefined ? qts[1].value[0] : '',
        AnswerQuant3: qts[0] !== undefined ? qts[2].value[0] : '',
        AnswerQuant4: qts[0] !== undefined ? qts[3].value[0] : '',
        AnswerQuant5: qts[0] !== undefined ? qts[4].value[0] : '',
        AnswerQuant6: qts[0] !== undefined ? qts[5].value[0] : '',
        AnswerQuant7: qts[0] !== undefined ? qts[6].value[0] : '',
        AnswerQuant8: qts[0] !== undefined ? qts[7].value[0] : '',
        AnswerQuant9: qts[0] !== undefined ? qts[8].value[0] : '',
        AnswerQuant10: qts[0] !== undefined ? qts[9].value[0] : '',
        AnswerQuant11: qts[0] !== undefined ? qts[10].value[0] : '',
        AnswerQuant12: qts[0] !== undefined ? qts[11].value[0] : '',
        AnswerQuant13: qts[0] !== undefined ? qts[12].value[0] : '',
        AnswerQuant14: qts[0] !== undefined ? qts[13].value[0] : '',
        AnswerQuant15: qts[0] !== undefined ? qts[14].value[0] : '',
        AnswerQuant16: qts[0] !== undefined ? qts[15].value[0] : '',
        AnswerQuant17: qts[0] !== undefined ? qts[16].value[0] : '',
        AnswerQuant18: qts[0] !== undefined ? qts[17].value[0] : '',
        AnswerQuant19: qts[0] !== undefined ? qts[18].value[0] : '',
        AnswerQuant20: qts[0] !== undefined ? qts[19].value[0] : '',
        AnswerQuant21: qts[0] !== undefined ? qts[20].value[0] : '',
        AnswerQuant22: qts[0] !== undefined ? qts[21].value[0] : '',
        AnswerQuant23: qts[0] !== undefined ? qts[22].value[0] : '',
        AnswerQuant24: qts[0] !== undefined ? qts[23].value[0] : '',
        AnswerQuant25: qts[0] !== undefined ? qts[24].value[0] : '',
        AnswerQuant26: qts[0] !== undefined ? qts[25].value[0] : '',
        AnswerQuant27: qts[0] !== undefined ? qts[26].value[0] : '',
        AnswerQuant28: qts[0] !== undefined ? qts[27].value[0] : '',
        AnswerQuant29: qts[0] !== undefined ? qts[28].value[0] : '',
        AnswerQuant30: qts[0] !== undefined ? qts[29].value[0] : '',
        AnswerQual1: qts[0] !== undefined ? qts[0].value[1] : '',
        AnswerQual2: qts[0] !== undefined ? qts[1].value[1] : '',
        AnswerQual3: qts[0] !== undefined ? qts[2].value[1] : '',
        AnswerQual4: qts[0] !== undefined ? qts[3].value[1] : '',
        AnswerQual5: qts[0] !== undefined ? qts[4].value[1] : '',
        AnswerQual6: qts[0] !== undefined ? qts[5].value[1] : '',
        AnswerQual7: qts[0] !== undefined ? qts[6].value[1] : '',
        AnswerQual8: qts[0] !== undefined ? qts[7].value[1] : '',
        AnswerQual9: qts[0] !== undefined ? qts[8].value[1] : '',
        AnswerQual10: qts[0] !== undefined ? qts[9].value[1] : '',
        AnswerQual11: qts[0] !== undefined ? qts[10].value[1] : '',
        AnswerQual12: qts[0] !== undefined ? qts[11].value[1] : '',
        AnswerQual13: qts[0] !== undefined ? qts[12].value[1] : '',
        AnswerQual14: qts[0] !== undefined ? qts[13].value[1] : '',
        AnswerQual15: qts[0] !== undefined ? qts[14].value[1] : '',
        AnswerQual16: qts[0] !== undefined ? qts[15].value[1] : '',
        AnswerQual17: qts[0] !== undefined ? qts[16].value[1] : '',
        AnswerQual18: qts[0] !== undefined ? qts[17].value[1] : '',
        AnswerQual19: qts[0] !== undefined ? qts[18].value[1] : '',
        AnswerQual20: qts[0] !== undefined ? qts[19].value[1] : '',
        AnswerQual21: qts[0] !== undefined ? qts[20].value[1] : '',
        AnswerQual22: qts[0] !== undefined ? qts[21].value[1] : '',
        AnswerQual23: qts[0] !== undefined ? qts[22].value[1] : '',
        AnswerQual24: qts[0] !== undefined ? qts[23].value[1] : '',
        AnswerQual25: qts[0] !== undefined ? qts[24].value[1] : '',
        AnswerQual26: qts[0] !== undefined ? qts[25].value[1] : '',
        AnswerQual27: qts[0] !== undefined ? qts[26].value[1] : '',
        AnswerQual28: qts[0] !== undefined ? qts[27].value[1] : '',
        AnswerQual29: qts[0] !== undefined ? qts[28].value[1] : '',
        AnswerQual30: qts[0] !== undefined ? qts[29].value[1] : '',
        AnswerWhy1: qts[0] !== undefined ? qts[0].value[2] : '',
        AnswerWhy2: qts[0] !== undefined ? qts[1].value[2] : '',
        AnswerWhy3: qts[0] !== undefined ? qts[2].value[2] : '',
        AnswerWhy4: qts[0] !== undefined ? qts[3].value[2] : '',
        AnswerWhy5: qts[0] !== undefined ? qts[4].value[2] : '',
        AnswerWhy6: qts[0] !== undefined ? qts[5].value[2] : '',
        AnswerWhy7: qts[0] !== undefined ? qts[6].value[2] : '',
        AnswerWhy8: qts[0] !== undefined ? qts[7].value[2] : '',
        AnswerWhy9: qts[0] !== undefined ? qts[8].value[2] : '',
        AnswerWhy10: qts[0] !== undefined ? qts[9].value[2] : '',
        AnswerWhy11: qts[0] !== undefined ? qts[10].value[2] : '',
        AnswerWhy12: qts[0] !== undefined ? qts[11].value[2] : '',
        AnswerWhy13: qts[0] !== undefined ? qts[12].value[2] : '',
        AnswerWhy14: qts[0] !== undefined ? qts[13].value[2] : '',
        AnswerWhy15: qts[0] !== undefined ? qts[14].value[2] : '',
        AnswerWhy16: qts[0] !== undefined ? qts[15].value[2] : '',
        AnswerWhy17: qts[0] !== undefined ? qts[16].value[2] : '',
        AnswerWhy18: qts[0] !== undefined ? qts[17].value[2] : '',
        AnswerWhy19: qts[0] !== undefined ? qts[18].value[2] : '',
        AnswerWhy20: qts[0] !== undefined ? qts[19].value[2] : '',
        AnswerWhy21: qts[0] !== undefined ? qts[20].value[2] : '',
        AnswerWhy22: qts[0] !== undefined ? qts[21].value[2] : '',
        AnswerWhy23: qts[0] !== undefined ? qts[22].value[2] : '',
        AnswerWhy24: qts[0] !== undefined ? qts[23].value[2] : '',
        AnswerWhy25: qts[0] !== undefined ? qts[24].value[2] : '',
        AnswerWhy26: qts[0] !== undefined ? qts[25].value[2] : '',
        AnswerWhy27: qts[0] !== undefined ? qts[26].value[2] : '',
        AnswerWhy28: qts[0] !== undefined ? qts[27].value[2] : '',
        AnswerWhy29: qts[0] !== undefined ? qts[28].value[2] : '',
        AnswerWhy30: qts[0] !== undefined ? qts[29].value[2] : '',
      }}
      onSubmit={(values, actions) => {
        // eslint-disable-next-line prefer-const
        let valuesSubmit = {
          name: title,
          content: {
            questions: [],
          },
        };

        sessions.forEach((item, index) => {
          valuesSubmit.content.questions.push({
            index: index + 1,
            text: item,
            value: [
              values[`AnswerQuant${index + 1}`],
              values[`AnswerQual${index + 1}`],
              values[`AnswerWhy${index + 1}`],
            ],
          });
        });

        actions.resetForm({
          values: {
            AnswerQuant1: '',
            AnswerQuant2: '',
            AnswerQuant3: '',
            AnswerQuant4: '',
            AnswerQuant5: '',
            AnswerQuant6: '',
            AnswerQuant7: '',
            AnswerQuant8: '',
            AnswerQuant9: '',
            AnswerQuant10: '',
            AnswerQuant11: '',
            AnswerQuant12: '',
            AnswerQuant13: '',
            AnswerQuant14: '',
            AnswerQuant15: '',
            AnswerQuant16: '',
            AnswerQuant17: '',
            AnswerQuant18: '',
            AnswerQuant19: '',
            AnswerQuant20: '',
            AnswerQuant21: '',
            AnswerQuant22: '',
            AnswerQuant23: '',
            AnswerQuant24: '',
            AnswerQuant25: '',
            AnswerQuant26: '',
            AnswerQuant27: '',
            AnswerQuant28: '',
            AnswerQuant29: '',
            AnswerQuant30: '',
            AnswerQual1: '',
            AnswerQual2: '',
            AnswerQual3: '',
            AnswerQual4: '',
            AnswerQual5: '',
            AnswerQual6: '',
            AnswerQual7: '',
            AnswerQual8: '',
            AnswerQual9: '',
            AnswerQual10: '',
            AnswerQual11: '',
            AnswerQual12: '',
            AnswerQual13: '',
            AnswerQual14: '',
            AnswerQual15: '',
            AnswerQual16: '',
            AnswerQual17: '',
            AnswerQual18: '',
            AnswerQual19: '',
            AnswerQual20: '',
            AnswerQual21: '',
            AnswerQual22: '',
            AnswerQual23: '',
            AnswerQual24: '',
            AnswerQual25: '',
            AnswerQual26: '',
            AnswerQual27: '',
            AnswerQual28: '',
            AnswerQual29: '',
            AnswerQual30: '',
            AnswerWhy1: '',
            AnswerWhy2: '',
            AnswerWhy3: '',
            AnswerWhy4: '',
            AnswerWhy5: '',
            AnswerWhy6: '',
            AnswerWhy7: '',
            AnswerWhy8: '',
            AnswerWhy9: '',
            AnswerWhy10: '',
            AnswerWhy11: '',
            AnswerWhy12: '',
            AnswerWhy13: '',
            AnswerWhy14: '',
            AnswerWhy15: '',
            AnswerWhy16: '',
            AnswerWhy17: '',
            AnswerWhy18: '',
            AnswerWhy19: '',
            AnswerWhy20: '',
            AnswerWhy21: '',
            AnswerWhy22: '',
            AnswerWhy23: '',
            AnswerWhy24: '',
            AnswerWhy25: '',
            AnswerWhy26: '',
            AnswerWhy27: '',
            AnswerWhy28: '',
            AnswerWhy29: '',
            AnswerWhy30: '',
          },
        });

        updateValues(true);
      }}
    >
      {props => (
        <>
          {/* <AutoSizer>
            {({ height, width }) => (
              <List
                height={height}
                rowCount={sessions.length}
                rowHeight={350}
                overscanRowCount={5}
                rowRenderer={({ index, key, style }) => (
                  <div key={key} style={style}>
                    <>
                      <S.LabelText>{sessions[index]}</S.LabelText>
                      {Questions.map((item, indexQuestion) => (
                        <DoubleTextAndRadioGroup
                          key={
                            indexQuestion === 0
                              ? `AnswerQuant${index + 1}`
                              : `AnswerQual${index + 1}`
                          }
                          text={item}
                          ArrayValue={forms} // Aqui
                          setAnswer={props.setFieldValue}
                          keyAnswer={
                            indexQuestion === 0
                              ? `AnswerQuant${index + 1}`
                              : `AnswerQual${index + 1}`
                          }
                          direction="horizontal"
                          setFormValues={setFormValues}
                          formValues={formValues} // Aqui
                          index={indexQuestion}
                          indexSession={index}
                          currentValue={
                            indexQuestion === 0
                              ? props.values[`AnswerQuant${index + 1}`]
                              : props.values[`AnswerQual${index + 1}`]
                          }
                          propsFormik={props} // Aqui
                        />
                      ))}
                      <TextAndTextArea
                        key={`AnswerWhy${index + 1}`}
                        text="Se não, porquê? (use código)"
                        answerKey={`AnswerWhy${index + 1}`}
                        answerFunc={props.setFieldValue}
                        setFormValues={setFormValues}
                        formValues={formValues}
                        indexSession={index}
                        currentValue={props.values[`AnswerWhy${index + 1}`]}
                        propsFormik={props}
                      />
                    </>
                  </div>
                )}
                width={width}
              />
            )}
                        </AutoSizer> */}

          {sessions.map((titleSession, indexSession) => (
            <>
              <S.LabelText>{titleSession}</S.LabelText>
              {Questions.map((item, index) => (
                <DoubleTextAndRadioGroup
                  key={
                    index === 0
                      ? `AnswerQuant${indexSession + 1}`
                      : `AnswerQual${indexSession + 1}`
                  }
                  text={item}
                  ArrayValue={forms} // Aqui
                  setAnswer={props.setFieldValue}
                  keyAnswer={
                    index === 0
                      ? `AnswerQuant${indexSession + 1}`
                      : `AnswerQual${indexSession + 1}`
                  }
                  direction="horizontal"
                  setFormValues={setFormValues}
                  formValues={formValues} // Aqui
                  index={indexSession}
                  indexSession={indexSession}
                  currentValue={
                    index === 0
                      ? props.values[`AnswerQuant${indexSession + 1}`]
                      : props.values[`AnswerQual${indexSession + 1}`]
                  }
                  propsFormik={props} // Aqui
                />
              ))}
              <TextAndTextArea
                key={`AnswerWhy${indexSession + 1}`}
                text="Se não, porquê? (use código)"
                answerKey={`AnswerWhy${indexSession + 1}`}
                answerFunc={props.setFieldValue}
                setFormValues={setFormValues}
                formValues={formValues}
                indexSession={indexSession}
                currentValue={props.values[`AnswerWhy${indexSession + 1}`]}
                propsFormik={props}
              />
            </>
          ))}
          {
            <Button
              type="primary"
              htmlType="submit"
              onClick={props.handleSubmit}
              style={S.AntdStyles.ButtonSave}
            >
              Salvar
            </Button>
          }
        </>
      )}
    </Formik>
  );
}

export default MAL;
