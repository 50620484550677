/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import { useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Input, Button, AutoComplete } from 'antd';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import { Protocols } from './components/Protocols';
import { ProtocolItem } from './components/ProtocolItem';

import FormContext from '../Context/FormContext';

import * as S from './styles';
import './styles.css';

function ProtocolList() {
  // const patientDetails = useSelector(state => state.patient.patientDetails);
  const protocols = useSelector(state => state.protocol.protocolsCreated);
  // const dispatch = useDispatch();

  const { Search } = Input;
  const firstChange = useMediaQuery('(min-width: 611px)');

  const { changeComponent } = useContext(FormContext);

  const [visOpt, setVisualizationOption] = useState('ALL');
  const [searchOptions, setSearchOptions] = useState([]);

  const [allProtocols, setAllProtocols] = useState([]);

  const [domesticProtocols, setDomesticProtocols] = useState([]);

  const [inliveProtocols, setInliveProtocols] = useState([]);

  function updateInfo(prot) {
    const tempProt = [];
    const tempAll = [];
    const tempDom = [];
    const tempInlive = [];
    for (let x = 0; x < prot.length; x += 1) {
      if (prot[x].type === 'DOMESTIC') {
        tempDom.push(
          <ProtocolItem
            id={prot[x].id}
            name={prot[x].name}
            type={prot[x].type}
            exercises={prot[x].exercises}
            active={prot[x].active}
          />,
        );
      }
      if (prot[x].type === 'INLIVE') {
        tempInlive.push(
          <ProtocolItem
            id={prot[x].id}
            name={prot[x].name}
            type={prot[x].type}
            exercises={prot[x].exercises}
            active={prot[x].active}
          />,
        );
      }
      tempAll.push(
        <ProtocolItem
          id={prot[x].id}
          name={prot[x].name}
          type={prot[x].type}
          exercises={prot[x].exercises}
          active={prot[x].active}
        />,
      );
      tempProt.push({
        value: prot[x].name,
      });
    }
    setAllProtocols(tempAll);
    setDomesticProtocols(tempDom);
    setInliveProtocols(tempInlive);
    setSearchOptions(tempProt);
  }

  /*
    BUSCA DE ACORDO COM O TIPO SELECIONADO: ALL, DOMESTIC, IN_LIVE_SESSION

  */
  const onSearch = (value, prot) => {
    const tempProt = [];

    if (value !== '') {
      if (visOpt === 'ALL') {
        for (let x = 0; x < prot.length; x += 1) {
          if (value === prot[x].name) {
            tempProt.push(
              <ProtocolItem
                id={prot[x].id}
                name={prot[x].name}
                type={prot[x].type}
                exercises={prot[x].exercises}
              />,
            );
          }
        }
        setAllProtocols(tempProt);
      }
      if (visOpt === 'DOMESTIC') {
        for (let x = 0; x < prot.length; x += 1) {
          if (prot[x].name === value && prot[x].type === 'DOMESTIC') {
            tempProt.push(
              <ProtocolItem
                id={prot[x].id}
                name={prot[x].name}
                type={prot[x].type}
                exercises={prot[x].exercises}
              />,
            );
          }
        }
        setDomesticProtocols(tempProt);
      }
      if (visOpt === 'INLIVE') {
        for (let x = 0; x < prot.length; x += 1) {
          if (prot[x].name === value && prot[x].type === 'INLIVE') {
            tempProt.push(
              <ProtocolItem
                id={prot[x].id}
                name={prot[x].name}
                type={prot[x].type}
                exercises={prot[x].exercises}
              />,
            );
          }
        }
        setInliveProtocols(tempProt);
      }
    } else {
      updateInfo(prot);
    }
  };

  useEffect(() => {
    if (protocols !== null) {
      updateInfo(protocols);
    }
  }, [protocols]);

  const secondChange = useMediaQuery('(min-width: 771px)');

  return (
    <div style={{ width: 'calc(100% - 2rem)', height: '100%' }}>
      <S.TitlePage>Protocolos</S.TitlePage>
      <div
        className="container-click-elements-protocol"
        style={{
          justifyContent: !secondChange && 'flex-start',
          alignItems: secondChange ? 'center' : 'flex-start',
        }}
      >
        <AutoComplete
          dropdownMatchSelectWidth={252}
          style={{
            width: 252,
            marginBottom: firstChange ? 0 : 10,
          }}
          allowClear={false}
          options={searchOptions}
          filterOption={(inputValue, option) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
        >
          <Search
            placeholder="Buscar"
            onSearch={value => onSearch(value, protocols)}
            style={{ width: 252 }}
          />
        </AutoComplete>
        <Button
          type="primary"
          htmlType="submit"
          style={{
            ...S.AntdStyles.ButtonCreateNewActivity,
            marginLeft: !secondChange ? (firstChange ? '10px' : '0') : '0',
          }}
          onClick={() => changeComponent('create')}
        >
          Criar Novo Protocolo
        </Button>
      </div>
      <div
        className="container-btns-controller-content-visualization-protocol"
        style={{ alignItems: secondChange ? 'center' : 'flex-start' }}
      >
        <Button
          type="primary"
          htmlType="submit"
          style={visOpt === 'ALL' ? S.AntdStyles.Btn2 : S.AntdStyles.Btn1}
          onClick={() => {
            setVisualizationOption('ALL');
          }}
        >
          Exibir Todas
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          style={visOpt === 'DOMESTIC' ? S.AntdStyles.Btn2 : S.AntdStyles.Btn1}
          onClick={() => setVisualizationOption('DOMESTIC')}
        >
          Práticas Domiciliáres
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          style={visOpt === 'INLIVE' ? S.AntdStyles.Btn2 : S.AntdStyles.Btn1}
          onClick={() => setVisualizationOption('INLIVE')}
        >
          Telechamada
        </Button>
      </div>

      {visOpt === 'ALL' && (
        <S.ContainerProtocolsList>
          <Protocols protocols={allProtocols} />
        </S.ContainerProtocolsList>
      )}

      {visOpt === 'DOMESTIC' && (
        <S.ContainerProtocolsList>
          <Protocols protocols={domesticProtocols} />
        </S.ContainerProtocolsList>
      )}

      {visOpt === 'INLIVE' && (
        <S.ContainerProtocolsList>
          <Protocols protocols={inliveProtocols} />
        </S.ContainerProtocolsList>
      )}
    </div>
  );
}

export default ProtocolList;
