import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 45vw;
  height: 100vh;
  padding-top: 6%;
`;

export const ContainerVideoStatus = styled.div`
  display: flex;
  flex-direction: column;
  width: 45vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

export const ContainerVideoCallScreen = styled.div`
  display: flex;
  justify-content: center;
  width: 45vw;
  height: 50vh;
  position: relative;

  label {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
    font: normal normal bold 16px/19px Nunito Sans;
    color: white;
    margin-right: 10px;
  }
`;

export const WaitForPatientScreen = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: white;
  opacity: 0.6;
  position: absolute;
  z-index: 3;

  strong {
    font: normal normal normal 19px/22px Nunito Sans;
    margin-left: 5px;
    color: var(--davysGrey);
  }
`;

export const ContainerTextInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  span {
    text-align: left;
    letter-spacing: 0px;
    opacity: 1;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;

    &.title-annotations {
      color: var(--davysGrey);
      font: normal normal bold 16px/19px Nunito Sans;
    }

    &.saved-at {
      color: var(--philippineSilver);
      font: normal normal normal 16px/19px Nunito Sans;
    }
  }
`;

export const ImageCamera = styled.img`
  display: flex;
  align-items: center;
  width: 16px;
  height: 9px;
`;

export const AntdStyles = {
  ButtonCallPatient: {
    display: 'flex',
    alignItems: 'center',
    background: 'var(--darkCyan) 0% 0% no-repeat padding-box',
    justifyContent: 'space-around',
    borderRadius: '4px',
    opacity: 1,
    width: '11rem',
    fontWeight: 'bold',
    fontColor: 'white',
    alignSelf: 'center',
  },
};
