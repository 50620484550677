import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from 'antd';
import { getSubscriptionStatus } from 'store/modules/payment/actions';
import { getSelf } from 'store/modules/professional/actions';

import AppHeader from 'components/Header';

import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import { PlanManagement } from './components/PlanManagement';
import { MakePayment } from './components/MakePayment';

import PlanContext from './Context/PlanContext';

export default function Subscription() {
  const dispatch = useDispatch();
  const subscript_status = useSelector(state => state.payment.subsc_status);
  const self = useSelector(state => state.professional.self);
  const [screen, setScreen] = useState('loading');
  const [reason, setReason] = useState(null);
  const scrollRef = useRef(null);
  const firstChange = useMediaQuery('(min-width: 900px)');
  const onlyComputers = useMediaQuery('(min-width: 1180px)');

  function changeScreen(subScreen, reasonReceived) {
    setScreen(subScreen);
    setReason(reasonReceived);
  }

  useEffect(() => {
    dispatch(getSubscriptionStatus());
    dispatch(getSelf());
  }, []);

  useEffect(() => {
    if (subscript_status !== null) {
      setScreen('management');
    }
  }, [subscript_status]);

  return (
    <Layout style={{ marginBottom: 60 }}>
      <AppHeader />
      {onlyComputers ? (
        <div
          ref={scrollRef}
          style={{
            scrollBehavior: 'smooth',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            padding: '0 7rem',
            marginTop: firstChange ? 0 : 40,
            minWidth: '100%',
          }}
        >
          <PlanContext.Provider
            value={{ changeScreen, subscript_status, self }}
          >
            {screen === 'management' &&
              subscript_status !== null &&
              self !== null && <PlanManagement scrollRef={scrollRef} />}
            {screen === 'payment' &&
              subscript_status !== null &&
              self !== null && <MakePayment reason={reason} />}
            {screen === 'loading' && (
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <span>Carregando ... </span>
              </div>
            )}
          </PlanContext.Provider>
        </div>
      ) : (
        <div
          ref={scrollRef}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            padding: '0 7rem',
          }}
        >
          <span>Função disponível apenas em computadores.</span>
        </div>
      )}
    </Layout>
  );
}
