/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Row, Avatar, Modal, Space, Table, notification } from 'antd';
import {
  addProfessionalAdmin,
  getPossibleAdmins,
  getProfessionalsAdmins,
  removeAdmin,
} from 'store/modules/clinic/actions';
import { TypeAccountTranslated } from 'pages/Admin/ProfessionalList/utils/professionalList';
import { nameHandler } from 'utils/NameHandler';
import closeIcon from 'assets/close.svg';
import * as S from './styles';
import TrashIcon from '../../../../../assets/trash.svg';
import { DeleteAdminModal } from './components/DeleteAdminModal';

function DataAdmins({ secondChange }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();
  const admins = useSelector(state => state.clinic.administrators);
  const possibleAdmins = useSelector(state => state.clinic.possibleAdmins);
  const [professionalID, setProfessionalID] = useState('');
  const user = useSelector(state => state.auth.user);
  const [recordId, setRecordId] = useState('');
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function handleDeleteOk() {
    if (user?.active_profile_name !== 'RECEPTIONIST') {
      dispatch(removeAdmin(recordId));
    } else {
      notification.error({
        message:
          'Não é possível excluir um administrador sendo um administrador-recepcionista',
      });
    }
    setIsModalDeleteVisible(false);
  }

  function handleDeleteCancel() {
    setIsModalDeleteVisible(false);
  }

  useEffect(() => {
    dispatch(getProfessionalsAdmins());
    dispatch(getPossibleAdmins());
  }, []);

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'professionalName',
      render: (text, record) => (
        <Space size="middle">
          <Avatar
            size={20}
            src={record.administratorPhoto}
            style={S.AntdStyles.AvatarUser}
          >
            {nameHandler(text)}
          </Avatar>
          <label>{text}</label>
        </Space>
      ),
    },
    {
      title: 'Profissão',
      dataIndex: 'professionProfile',
      render: text => (
        <Space size="middle">
          <label>{TypeAccountTranslated[text]}</label>
        </Space>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'professionalEmail',
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setProfessionalID(selectedRows[0].id);
    },
  };

  return (
    <Formik
      initialValues={{ professionalIds: [] }}
      validationSchema={Yup.object({
        professionalIds: Yup.array().required('É obrigatório'),
      })}
      onSubmit={({ setErrors }) => {
        if (professionalID === '') {
          setErrors({
            professionalIds: 'Selecione um profissional para Adicionar',
          });
        } else {
          dispatch(addProfessionalAdmin(professionalID));
        }
      }}
    >
      {props => (
        <S.DivBlockTextAndInput>
          <DeleteAdminModal
            isModalVisible={isModalDeleteVisible}
            handleOk={handleDeleteOk}
            handleCancel={handleDeleteCancel}
          />
          <Modal
            title="Profissionais da Clínica"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            closeIcon={
              <img src={closeIcon} alt="close" style={S.AntdStyles.iconClose} />
            }
            footer={[
              <Button
                onClick={() => {
                  props.handleSubmit();
                  handleOk();
                }}
                key="submit"
                type="primary"
                style={{
                  width: '200px',
                  alignSelf: 'center',
                  borderRadius: '5px',
                  backgroundColor: '#118E88',
                  borderColor: '#118E88',
                  color: '#fff',
                }}
              >
                Adicionar Administrador
              </Button>,
            ]}
            width="40rem"
          >
            <S.Container secondChange={secondChange}>
              <Table
                rowSelection={{
                  type: 'radio',
                  ...rowSelection,
                }}
                columns={columns}
                dataSource={possibleAdmins}
                pagination={{
                  defaultPageSize: 500,
                }}
              />
            </S.Container>
            {props.touched.professionalIds && props.errors.professionalIds ? (
              <S.ErrorMessage>{props.errors.professionalIds}</S.ErrorMessage>
            ) : null}
          </Modal>
          <S.TitleAdmin>Administradores Gerais</S.TitleAdmin>
          <S.SubtitleAdmin>
            Os administradores gerais podem acessar e configurar o máximo de
            informações possível no sistema.
          </S.SubtitleAdmin>

          <S.Container secondChange={secondChange}>
            {admins.map(item => (
              <Row
                style={{ padding: '0.5rem', justifyContent: 'space-between' }}
              >
                <Row>
                  <Avatar
                    size={24}
                    src={item.administratorPhoto}
                    style={S.AntdStyles.AvatarUser}
                  >
                    {item.administratorPhoto === null
                      ? nameHandler(item.administratorName)
                      : null}
                  </Avatar>
                  <S.LabelText className="input-label">
                    {item.administratorName.length > (secondChange ? 49 : 17)
                      ? `${item.administratorName.substring(
                          0,
                          secondChange ? 49 : 17,
                        )}...`
                      : item.administratorName}
                  </S.LabelText>
                </Row>
                <S.IconButton
                  onClick={() => {
                    setRecordId(item.id);

                    setIsModalDeleteVisible(true);
                  }}
                >
                  <img
                    alt="trash"
                    src={TrashIcon}
                    style={S.AntdStyles.iconInfo}
                  />
                </S.IconButton>
              </Row>
            ))}
          </S.Container>

          <Button
            type="primary"
            htmlType="submit"
            onClick={() => showModal()}
            style={S.AntdStyles.ButtonAdmin}
          >
            Adicionar Administrador Geral
          </Button>
        </S.DivBlockTextAndInput>
      )}
    </Formik>
  );
}

export default DataAdmins;
