import styled from 'styled-components';

export const Subtitles = styled.label`
  text-align: left;
  font: normal normal bold 14px/19px Nunito Sans;
  letter-spacing: 0px;
  color: var(--davysGrey);
  opacity: 1;
  margin-top: 1rem;
`;

export const TitleSession = styled.label`
  opacity: 1;
  color: var(--deepKoamaru);
  text-align: start;
  font: normal normal bold 14px/19px Nunito Sans;
  letter-spacing: 0px;
  background: var(--lavender) 0% 0% no-repeat padding-box;
  border-left: solid var(--deepKoamaru) 2px;
  border-radius: 0 1px 1px 0;
  padding: 3px 8px 3px 5px;
  height: 25px;
  width: fit-content;
`;

export const ContainerShowActivityVideos = styled.div`
  display: flex;
  width: 95%;
  max-width: 780px;
  min-width: 650px;
  min-height: 280px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const ContainerActivityInfo = styled.div`
  display: flex;
  width: 95%;
  max-width: 780px;
  min-width: 650px;
  min-height: 80px;

  @media (min-width: 770px) {
    flex-direction: row;
    align-items: center;
  }

  @media (max-width: 770px) {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 5%;
  }
`;

export const ElementActivityInfo = styled.div`
  display: flex;
  width: 33%;
  align-items: center;

  img {
    width: 27px;
    height: 27px;
    padding: 4px;
    background: var(--wishfulBlue);
  }

  label {
    text-align: start;
    font: normal normal normal 14px/19px Nunito Sans;
    letter-spacing: 0px;
    margin-left: 4px;
  }

  @media (min-width: 770px) {
    margin-top: 0;
  }

  @media (max-width: 770px) {
    margin-top: 8px;
  }
`;

export const ElementVideoActVideos = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 250px;
  min-width: 220px;
  font-size: 14px;
  min-height: 257px;
  color: grey;
  margin-right: 10%;
  position: relative;
  background-color: var(--white);
  margin-top: 2%;
  justify-content: center;
  padding: 3%;
  margin: 10px;
  box-shadow: 4px 4px #f5f5f5;
  border-radius: 4px;

  a {
    font-size: 14px;
    color: black;
  }

  @media (min-width: 770px) {
    margin-left: 0;
  }

  @media (max-width: 770px) {
    flex-direction: column;
  }

  img {
    width: 20px;
    height: 20px;
  }
`;

export const ContainerStatusActivity = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 4px;
  margin-top: 2px;

  label {
    margin-left: 4px;
  }
`;

export const NormalLabel = styled.label`
  text-align: start;
  font: normal normal normal 14px/19px Nunito Sans;
  letter-spacing: 0px;
  width: 90%;

  @media (min-width: 770px) {
    width: 90%;
  }

  @media (max-width: 770px) {
    width: 60%;
  }
`;
