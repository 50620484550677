/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-lonely-if */
/* eslint-disable no-undef */
import React, { useEffect, useState, useContext } from 'react';
import Stepper from 'react-stepper-horizontal';
import { useSelector, useDispatch } from 'react-redux';
import useDebounce from 'hooks/debounce/useDebounce';
import ReactPlayer from 'react-player';
import {
  updateInLiveSessionData,
  setCurrentEvals,
  stopShowVideo,
} from 'store/modules/video_call/actions';
import { Button, Popconfirm } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { SelectionItem } from './SelectionItem';
import VideoCallContext from '../../../../Context';

import * as S from './styles';

export function LiveSession(props) {
  const dispatch = useDispatch();
  const { handleSessionStart } = props;
  const in_live_session_data = useSelector(
    state => state.video_call.in_live_session_data,
  );
  const day_event_related_info = useSelector(
    state => state.video_call.day_event_related_info,
  );
  const notes = useSelector(state => state.video_call.last_note_saved);
  const [activityVideos, setActivityVideos] = useState([]);
  const [sessionTitle, setSessionTitle] = useState('');
  const [currentVideo, setCurrentVideo] = useState('');
  const [activityConfig, setActivityConfig] = useState({
    series: '',
    repetitions: '',
  });
  const [currentEvalSelected, setCurrentEvalSelected] = useState(-1);
  const [currentProtocol, setCurrentProtocol] = useState(null);
  const { patientDetails, initLiveSession } = useContext(VideoCallContext);
  const [popBackScreen, setPopBackScreen] = useState(false);
  // const [contShowPop, setContShowPop] = useState(1);

  const debounceUpdate = useDebounce(updateNotes, 9000);

  function updateNotes() {
    if (currentProtocol !== null) {
      const _id = patientDetails.id;
      const sess_id = in_live_session_data?.session.sessionId;
      const evals = currentProtocol.evaluations;
      const { eventId } = day_event_related_info;
      const { flag } = currentProtocol;
      dispatch(
        updateInLiveSessionData(_id, sess_id, evals, notes, eventId, flag),
      );
    }
  }

  useEffect(() => {
    if (currentProtocol !== null && in_live_session_data !== null) {
      debounceUpdate();
    }
  }, [notes]);

  useEffect(() => {
    const protocol = day_event_related_info?.protocol?.exercises;
    const tempArray = [];
    const tempArrayOfStatus = [];
    for (let x = 0; x < protocol.length; x += 1) {
      tempArray.push({
        title: ' ',
      });
      tempArrayOfStatus.push(false);
    }
    // Receber evaluations tratando os casos de null nas qualities
    const evals = in_live_session_data?.session.evaluations;
    const tmpArrayEvals = [];
    for (let x = 0; x < evals.length; x += 1) {
      if (evals[x].quality === null) {
        tmpArrayEvals.push({
          exerciseId: evals[x].exerciseId,
          quality: -1,
          videoUrl: evals[x].videoUrl,
        });
      } else {
        tmpArrayEvals.push({
          exerciseId: evals[x].exerciseId,
          quality: evals[x].quality,
          videoUrl: evals[x].videoUrl,
        });
      }
    }

    setActivityVideos(tempArray);
    setSessionTitle(day_event_related_info?.protocol.name);
    setActivityConfig({
      series: day_event_related_info?.protocol?.exercises[0]?.series,
      repetitions: day_event_related_info?.protocol?.exercises[0]?.repetitions,
    });

    setCurrentVideo(day_event_related_info?.protocol?.exercises[0]?.videoUrl);

    setCurrentProtocol({
      index: 0,
      evaluations: tmpArrayEvals,
      status: tempArrayOfStatus,
      flag: false,
    });
  }, []);

  useEffect(() => {
    if (currentProtocol !== null) {
      const _id = patientDetails.id;
      const sess_id = in_live_session_data?.session.sessionId;
      const evals = currentProtocol.evaluations;
      const { eventId } = day_event_related_info;
      const { flag } = currentProtocol;
      dispatch(
        updateInLiveSessionData(_id, sess_id, evals, notes, eventId, flag),
      );
      dispatch(setCurrentEvals(currentProtocol));

      /* let hasAnyEval = false;
      for (let x = 0; x < currentProtocol.evaluations.length; x += 1) {
        if (currentProtocol.evaluations[x].quality === null) {
          hasAnyEval = true;
        }
      }
      if (!hasAnyEval) {

      } */
    }
  }, [currentProtocol]);

  function handleChangeScreen() {
    handleSessionStart('preview');
  }

  // handler do clique na avaliação/quality
  function handleEvalSelected(quality, prot) {
    setCurrentEvalSelected(quality);
    const tempProt = [];
    const newStatus = [];
    const protocol = in_live_session_data.session;
    // Apenas para atualizar corretamente o valor no indice
    if (prot === null) {
      const tempIndex = currentEvalSelected === -1 ? 0 : currentEvalSelected;
      for (let x = 0; x < protocol.evaluations.length; x += 1) {
        if (x === tempIndex) {
          tempProt.push({
            exerciseId: protocol.evaluations[x].exerciseId,
            quality,
            videoUrl: protocol.evaluations[x].videoUrl,
          });
          newStatus.push(true);
        } else {
          tempProt.push({
            exerciseId: protocol.evaluations[x].exerciseId,
            quality:
              protocol.evaluations[x].quality !== null
                ? protocol.evaluations[x].quality
                : -1,
            videoUrl: protocol.evaluations[x].videoUrl,
          });
          newStatus.push(false);
        }
      }
      setCurrentProtocol({
        index: prot !== null ? prot.index : 0,
        evaluations: tempProt,
        status: newStatus,
        flag: prot !== null ? prot.flag : false,
      });
    } else {
      for (let x = 0; x < prot.evaluations.length; x += 1) {
        if (x === prot.index) {
          tempProt.push({
            exerciseId: prot?.evaluations[x].exerciseId,
            quality,
            videoUrl: protocol.evaluations[x].videoUrl,
          });
          newStatus.push(true);
        } else {
          tempProt.push({
            exerciseId: protocol.evaluations[x].exerciseId,
            quality:
              prot.evaluations[x].quality !== null
                ? prot.evaluations[x].quality
                : -1,
            videoUrl: protocol.evaluations[x].videoUrl,
          });
          newStatus.push(prot?.status[x]);
        }
      }
      setCurrentProtocol({
        index: prot.index,
        evaluations: tempProt,
        status: newStatus,
        flag: prot !== null ? prot.flag : false,
      });
    }
  }

  // handler da mudança para proxima avaliação
  function handleProtocolNavigation(isFoward, flag) {
    const data = in_live_session_data.session;
    if (isFoward) {
      // setContShowPop(0);
      // Atualza valor no index, muda o index +1 se não for maior do que o maior
      if (currentProtocol.index <= currentProtocol.evaluations.length - 1) {
        const tmpIndex =
          currentProtocol.index === currentProtocol.evaluations.length - 1
            ? currentProtocol.index
            : currentProtocol.index + 1;
        setCurrentEvalSelected(currentProtocol.evaluations[tmpIndex].quality);
        setCurrentVideo(
          in_live_session_data?.session.evaluations[tmpIndex].videoUrl,
        );
        setCurrentProtocol({
          index: tmpIndex,
          evaluations: currentProtocol.evaluations,
          status: currentProtocol.status,
          flag,
        });
        initLiveSession(
          patientDetails.id,
          data.sessionId,
          data.evaluations[tmpIndex].videoUrl,
        );
      }
    } else {
      // Só atualiza o valor do index pra -1 se não for menor do que 0
      /* if (currentProtocol.index >= 0) {
        const tmpIndex =
          currentProtocol.index === 0
            ? currentProtocol.index
            : currentProtocol.index - 1;
        setCurrentEvalSelected(currentProtocol.evaluations[tmpIndex].quality);
        setCurrentVideo(
          in_live_session_data?.session.evaluations[tmpIndex].videoUrl,
        );
        setCurrentProtocol({
          index: tmpIndex,
          evaluations: currentProtocol.evaluations,
          status: currentProtocol.status,
          flag,
        });
        initLiveSession(
          patientDetails.id,
          data.sessionId,
          data.evaluations[currentProtocol.index].videoUrl,
        );
      } */
      // if (contShowPop === 1) {
      setPopBackScreen(true);
      // }

      /* if (currentProtocol.index === 0 || currentProtocol.index - 1 === 0) {
        setContShowPop(1);
      } */
    }
  }

  function handleOk() {
    handleChangeScreen();
    dispatch(stopShowVideo(patientDetails.id));
  }

  function handleCancel() {
    setPopBackScreen(false);
  }

  return (
    <S.Container>
      <Popconfirm
        title="Deseja voltar para tela de visualização das atividades?"
        visible={popBackScreen}
        onConfirm={handleOk}
        onCancel={handleCancel}
      >
        <strong
          className="btn-back-to-preview"
          onClick={() => handleProtocolNavigation(false)}
          tabIndex="0"
        >
          Voltar
        </strong>
      </Popconfirm>
      <S.ContainerSteps>
        <Stepper
          steps={activityVideos}
          activeStep={currentProtocol !== null ? currentProtocol?.index : 0}
          defaultBorderColor="#575757"
          defaultColor="#D5D5D5"
          activeColor="#B1B1B1"
          completeColor="#B1B1B1"
          activeTitleColor="#B1B1B1"
          completeTitleColor="#B1B1B1"
          defaultTitleColor="#B1B1B1"
          size={16}
          defaultBorderWidth={7}
        />
      </S.ContainerSteps>
      <br />
      <strong className="regular-strong">{sessionTitle}</strong>
      <span className="regular-span">
        {activityConfig.series} série, {activityConfig.repetitions} repetições
      </span>
      <S.ContainerPlayer>
        <ReactPlayer
          url={currentVideo}
          width="390px"
          height="200px"
          playing
          loop
        />
      </S.ContainerPlayer>
      <strong className="regular-strong">
        Qualidade de execução do exercício{' '}
      </strong>

      {currentProtocol !== null && (
        <SelectionItem
          indexSelection={currentEvalSelected}
          handleEvalSelected={handleEvalSelected}
          currentProtocol={currentProtocol}
        />
      )}

      {currentProtocol?.status[currentProtocol.index] !== undefined &&
        currentProtocol?.status[currentProtocol.index] === true && (
          <S.ContainerFinished>
            <CheckOutlined />
            <span className="answer-saved">Resposta Salva</span>
          </S.ContainerFinished>
        )}
      <S.ContainerButtons>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-eval-exercise"
          onClick={() => handleProtocolNavigation(true, false)}
          style={S.AntdStyles.ButtonEvaluateExercise}
        >
          <span className="finish-exercise">Salvar e Avançar</span>
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-skip-exercise"
          onClick={() => handleProtocolNavigation(true, false)}
          style={S.AntdStyles.ButtonSkipExercise}
        >
          <span className="skip-exercise">Pular</span>
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          className="btn-eval-exercise"
          title="Ao clicar no botão a prática do dia será finalizada. É importante terminar de avaliar o paciente primeiro antes de clicar neste botão."
          onClick={() => handleProtocolNavigation(true, true)}
          style={S.AntdStyles.ButtonFinishPractice}
        >
          <span className="finish-exercise">Finalizar Prática do Dia</span>
        </Button>
      </S.ContainerButtons>
    </S.Container>
  );
}
