import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Button } from 'antd';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateEvaluation } from 'store/modules/evaluations/actions';
import TextAndRadioGroupWithDescription from '../../components/TextAndRadioWithDescription';
import * as S from './styles';

function FuglMeyer({ title, current_eval }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const qts = current_eval?.content?.questions;

  const forms1 = [
    { label: '0', value: '0' },
    { label: '2', value: '2' },
  ];

  const forms2 = [
    { label: '0', value: '0' },
    { label: '1', value: '1' },
    { label: '2', value: '2' },
  ];

  function returnForms(indexQuestion) {
    if (indexQuestion === 1) return forms1;
    if (indexQuestion === 2) return forms2;
    return forms1;
  }

  const Questions1 = ['Bíceps/Tríceps'];
  const DescriptionQ1 = [
    '',
    'Pontuação: (0) Sem atividade reflexa (2) Atividade reflexa pode ser avaliada',
  ];

  const Questions2 = [
    'a) Elevação',
    'b) Retração de ombro',
    'c) Abdução > 90º',
    'd) Rotação Externa',
    'e) Flexão do cotovelo',
    'f) Supinação',
  ];
  const DescriptionQ2 = [
    'Pontuação: (0) Tarefa não pode ser realizada completamente (1) Tarefa pode ser realizada parcialmente (2) Tarefa é realizada perfeitamente',
    '',
    '',
    '',
    '',
    '',
    '',
  ];

  const Questions3 = [
    'a) Adução do ombro',
    'b) Rotação interna',
    'c) Extensão do cotovelo',
    'd) Pronação',
  ];
  const DescriptionQ3 = [
    'Pontuação: (0) Tarefa não pode ser realizada completamente (1) Tarefa pode ser realizada parcialmente (2) Tarefa é realizada perfeitamente',
    '',
    '',
    '',
    '',
  ];

  const Questions4 = [
    'a) Mão a coluna lombar',
    'b) Flexão de ombro até 90º',
    'c) Prono-supinação (cotovelo a 90º e ombro a 0º)',
    'd) Abdução de ombro a 90º com o cotovelo estendido e pronado',
    'e) Flexão de ombro de 90º a 180º',
    'f) Prono-supinação (cotovelo extendido e ombro fletido de 30º a 90º)',
  ];
  const DescriptionQ4 = [
    '',
    'Pontuação: (0) Tarefa não pode ser realizada completamente (1) Tarefa pode ser realizada parcialmente (2) Tarefa é realizada perfeitamente',
    'Pontuação: (0) se o início do movimento o braço é abduzido ou o cotovelo é fletido (1) se na fase final do movimento o ombro abduz e /ou ocorre flexão de cotovelo (2) a tarefa é realizada perfeitamente.',
    'Pontuação: (0) não ocorre posicionamento correto do cotovelo e ombro e/ou pronação e supinação não pode ser realizada completamente (1) prono-supino pode ser realizada com ADM limitada ao mesmo tempo em que ombro e cotovelo estão corretamente posicionados (2) a tarefa é realizada perfeitamente.',
    'Pontuação: (0) não é tolerada nenhuma flexão de ombro ou desvio da pronação do antebraço (1) realiza parcialmente ou ocorre flexão do cotovelo e o antebraço não se mantêm pronado na fase TARDIA do movimento (2) a tarefa pode ser realizada sem desvio',
    'Pontuação: (0) o braço é abduzido e o cotovelo fletido no início do movimento (1) o ombro abduz e/ou ocorre flexão de cotovelo na fase final do movimento (2) a tarefa é realizada perfeitamente',
    'Pontuação: (0) posição não pode ser obtida pelo paciente e /ou prono-supinação não pode ser realizada perfeitamente (1) atividade de prono-supinação pode ser realizada mesmo com ADM limitada e ao mesmo tempo o ombro e o cotovelo estão corretamente posicionados (2) a tarefa é realizada perfeitamente.',
  ];

  const Questions5 = ['a) Bíceps/tríceps/flexor dos dedos'];
  const DescriptionQ5 = [
    '',
    'Pontuação: (0) 2 ou 3 reflexos estão hiperativos (1) 1 reflexo está hiperativo ou dois estão vivos (2) Não mais que um reflexo está vivo e nenhum está hiperativo.',
  ];

  const Questions6 = [
    'a) Cotovelo a 90º, ombro a 0º e pronação com resistência (assistência se necessário)',
    'b) Máxima flexo-extensão de punho, cotovelos a 90º, ombro 0º, dedos fletidos e pronação (assistência se necessário)',
    'c) Dorsiflexão com cotovelo a 0º, ombro a 30º e pronação com resistência (auxílio)',
    'd) Máxima flexo-extensão, com cotovelo a 0º, ombro a 30º e pronação (auxílio)',
    'e) Circundação',
  ];
  const DescriptionQ6 = [
    '',
    'Pontuação: (0) paciente não pode dorsifletir o punho na posição requerida (1) a dorsiflexão pode ser realizada, mas sem resistência alguma (2) a posição pode ser mantida contra alguma resistência',
    'Pontuação: (0) não ocorre movimento voluntário (1) o paciente não move ativamente o punho em todo grau de movimento (2) a tarefa pode ser realizada.',
    'Pontuação: (0) paciente não pode dorsifletir o punho na posição requerida (1) a dorsiflexão pode ser realizada, mas sem resistência alguma (2) a posição pode ser mantida contra alguma resistência.',
    'Pontuação: (0) não ocorre movimento voluntário (1) o paciente não move ativamente o punho em todo grau de movimento (2) a tarefa pode ser realizada.',
    'Pontuação: (0) não ocorre movimento voluntário (1) o paciente não move ativamente o punho em todo grau de movimento (2) a tarefa pode ser realizada.',
  ];

  const Questions7 = [
    'a) Flexão em massa dos dedos',
    'b) Extensão em massa dos dedos',
    'c) Preensão 1: Articulação metacarpofalangeana (II a V) estendidas e interfalangeana distal e proximal fletidas. Preensão contra resistência (segurar um livro)',
    'd) Preensão 2: aduzir o polegar e segurar um papel interposto entre o polegar e o indicador',
    'e) Preensão 3: oposição entre polegar e indicador com lápis interposto',
    'f) Preensão 4: Segurar com firmeza um objeto cilíndrico, com a superfície volar do 1º e 2º dedos contra os demais',
    'g) Preensão 5: O paciente segura firmemente uma bola de tênis',
  ];
  const DescriptionQ7 = [
    '',
    'Pontuação: (0) Tarefa não pode ser realizada completamente (1) Tarefa pode ser realizada parcialmente (2) Tarefa é realizada perfeitamente.',
    'Pontuação: (0) nenhuma atividade ocorre (1) ocorre relaxamento (liberação) da flexão em massa (2) extensão completa (comparando com mão não afetada).',
    'Pontuação: (0) posição requerida não pode ser realizada (1) a preensão é fraca (2) a preensão pode ser mantida contra considerável resistência.',
    'Pontuação: (0) A função não pode ser realizada (1) o papel pode ser mantido no lugar, mas não contra um leve puxão (2) o papel é segurado firmemente contra um puxão.',
    'Pontuação: (0) A função não pode ser realizada (1) o lápis pode ser mantido no lugar, mas não contra um leve puxão (2) o lápis é segurado firmemente contra um puxão.',
    'Pontuação: (0) A função não pode ser realizada (1) o objeto pode ser mantido no lugar, mas não contra um leve puxão (2) o objeto é segurado firmemente contra um puxão.',
    'Pontuação: (0) A função não pode ser realizada (1) o objeto pode ser mantido no lugar, mas não contra um leve puxão (2) o objeto é segurado firmemente contra um puxão',
  ];

  const Questions8 = [
    'a) Tremor',
    'b) Dismetria',
    'c) Velocidade: Index-nariz 5 vezes, e o mais rápido que conseguir',
  ];
  const DescriptionQ8 = [
    '',
    'Pontuação: (0) tremor marcante, (1).tremor leve, (2) sem tremor.',
    'Pontuação: (0) dismetria marcante, (1) dismetria leve,(2) sem dismetria.',
    'Pontuação: (0) 6 seg mais lento que o lado não afetado, (1) de 2 a 5 seg mais lento que o lado não afetado, (2) menos de 2 seg de diferença entre os lados.',
  ];

  const [formValues, setFormValues] = useState(
    current_eval?.content?.questions.length > 0
      ? [
          ...Questions1,
          ...Questions2,
          ...Questions3,
          ...Questions4,
          ...Questions5,
          ...Questions6,
          ...Questions7,
          ...Questions8,
        ].map((item, index) => ({
          index: index + 1,
          text: item,
          value: current_eval.content.questions[index].value,
        }))
      : [
          ...Questions1,
          ...Questions2,
          ...Questions3,
          ...Questions4,
          ...Questions5,
          ...Questions6,
          ...Questions7,
          ...Questions8,
        ].map((item, index) => ({
          index: index + 1,
          text: item,
          value: '',
        })),
  );

  function updateValues(isFinal) {
    const tempFormValue = {
      id: current_eval?.id,
      isFinished: isFinal,
      name: title,
      professionalId: current_eval?.professionalId,
      result: null,
      content: {
        questions: formValues,
      },
    };
    dispatch(updateEvaluation(tempFormValue, id));
    if (isFinal) {
      setFormValues(
        [
          ...Questions1,
          ...Questions2,
          ...Questions3,
          ...Questions4,
          ...Questions5,
          ...Questions6,
          ...Questions7,
          ...Questions8,
        ].map((item, index) => ({
          index: index + 1,
          text: item,
          value: '',
        })),
      );
    }
  }

  useEffect(() => {
    const valuesFormValues = Object.values(formValues);
    if (valuesFormValues.find(item => item.value !== '')) {
      updateValues(false);
    }
  }, [formValues]);

  return (
    <Formik
      initialValues={{
        Answer1: qts[0] !== undefined ? qts[0].value : '',
        Answer2: qts[1] !== undefined ? qts[1].value : '',
        Answer3: qts[2] !== undefined ? qts[2].value : '',
        Answer4: qts[3] !== undefined ? qts[3].value : '',
        Answer5: qts[4] !== undefined ? qts[4].value : '',
        Answer6: qts[5] !== undefined ? qts[5].value : '',
        Answer7: qts[6] !== undefined ? qts[6].value : '',
        Answer8: qts[7] !== undefined ? qts[7].value : '',
        Answer9: qts[8] !== undefined ? qts[8].value : '',
        Answer10: qts[9] !== undefined ? qts[9].value : '',
        Answer11: qts[10] !== undefined ? qts[10].value : '',
        Answer12: qts[11] !== undefined ? qts[11].value : '',
        Answer13: qts[12] !== undefined ? qts[12].value : '',
        Answer14: qts[13] !== undefined ? qts[13].value : '',
        Answer15: qts[14] !== undefined ? qts[14].value : '',
        Answer16: qts[15] !== undefined ? qts[15].value : '',
        Answer17: qts[16] !== undefined ? qts[16].value : '',
        Answer18: qts[17] !== undefined ? qts[17].value : '',
        Answer19: qts[18] !== undefined ? qts[18].value : '',
        Answer20: qts[19] !== undefined ? qts[19].value : '',
        Answer21: qts[20] !== undefined ? qts[20].value : '',
        Answer22: qts[21] !== undefined ? qts[21].value : '',
        Answer23: qts[22] !== undefined ? qts[22].value : '',
        Answer24: qts[23] !== undefined ? qts[23].value : '',
        Answer25: qts[24] !== undefined ? qts[24].value : '',
        Answer26: qts[25] !== undefined ? qts[25].value : '',
        Answer27: qts[26] !== undefined ? qts[26].value : '',
        Answer28: qts[27] !== undefined ? qts[27].value : '',
        Answer29: qts[28] !== undefined ? qts[28].value : '',
        Answer30: qts[29] !== undefined ? qts[29].value : '',
        Answer31: qts[30] !== undefined ? qts[30].value : '',
        Answer32: qts[31] !== undefined ? qts[31].value : '',
        Answer33: qts[32] !== undefined ? qts[32].value : '',
      }}
      onSubmit={(values, actions) => {
        // eslint-disable-next-line prefer-const
        let valuesSubmit = {
          name: title,
          content: {
            questions: [],
          },
        };

        [
          ...Questions1,
          ...Questions2,
          ...Questions3,
          ...Questions4,
          ...Questions5,
          ...Questions6,
          ...Questions7,
          ...Questions8,
        ].forEach((item, index) => {
          valuesSubmit.content.questions.push({
            index: index + 1,
            text: item,
            value: values[`Answer${index + 1}`],
          });
        });

        actions.resetForm({
          values: {
            Answer1: '',
            Answer2: '',
            Answer3: '',
            Answer4: '',
            Answer5: '',
            Answer6: '',
            Answer7: '',
            Answer8: '',
            Answer9: '',
            Answer10: '',
            Answer11: '',
            Answer12: '',
            Answer13: '',
            Answer14: '',
            Answer15: '',
            Answer16: '',
            Answer17: '',
            Answer18: '',
            Answer19: '',
            Answer20: '',
            Answer21: '',
            Answer22: '',
            Answer23: '',
            Answer24: '',
            Answer25: '',
            Answer26: '',
            Answer27: '',
            Answer28: '',
            Answer29: '',
            Answer30: '',
            Answer31: '',
            Answer32: '',
            Answer33: '',
          },
        });

        updateValues(true);
      }}
    >
      {props => (
        <>
          <S.LabelText>1. Motricidade Reflexa:</S.LabelText>
          <TextAndRadioGroupWithDescription
            text={Questions1}
            ArrayValue={returnForms(1)}
            setAnswer={props.setFieldValue}
            keyAnswer={`Answer${1}`}
            direction="horizontal"
            description={DescriptionQ1}
            index={1}
            setFormValues={setFormValues}
            formValues={formValues}
            currentValue={props.values[`Answer${1}`]}
            indexSession={0}
            propsFormik={props}
          />

          <S.LabelText>
            2. Sinergia Flexora:
            {DescriptionQ2[0] !== '' ? <span>{DescriptionQ2[0]}</span> : null}
          </S.LabelText>
          {Questions2.map(
            (item, index) =>
              item && (
                <TextAndRadioGroupWithDescription
                  text={item}
                  ArrayValue={returnForms(2)}
                  setAnswer={props.setFieldValue}
                  keyAnswer={`Answer${index + 1 + 1}`}
                  direction="horizontal"
                  description={DescriptionQ2}
                  index={index}
                  indexSession={index + 1}
                  setFormValues={setFormValues}
                  formValues={formValues}
                  currentValue={props.values[`Answer${index + 1 + 1}`]}
                  propsFormik={props}
                />
              ),
          )}

          <S.LabelText>
            3. Sinergia extensora:
            {DescriptionQ3[0] !== '' ? <span>{DescriptionQ3[0]}</span> : null}
          </S.LabelText>
          {Questions3.map(
            (item, index) =>
              item && (
                <TextAndRadioGroupWithDescription
                  text={item}
                  ArrayValue={returnForms(2)}
                  setAnswer={props.setFieldValue}
                  keyAnswer={`Answer${index + 8}`}
                  direction="horizontal"
                  description={DescriptionQ3}
                  index={index + 1}
                  indexSession={index + 7}
                  setFormValues={setFormValues}
                  formValues={formValues}
                  currentValue={props.values[`Answer${index + 8}`]}
                  propsFormik={props}
                />
              ),
          )}

          <S.LabelText>
            4. Sinergia extensora
            {DescriptionQ4[0] !== '' ? <span>{DescriptionQ4[0]}</span> : null}
          </S.LabelText>
          {Questions4.map(
            (item, index) =>
              item && (
                <TextAndRadioGroupWithDescription
                  text={item}
                  ArrayValue={returnForms(2)}
                  setAnswer={props.setFieldValue}
                  keyAnswer={`Answer${index + 11 + 1}`}
                  direction="horizontal"
                  description={DescriptionQ4}
                  index={index + 1}
                  indexSession={index + 11}
                  setFormValues={setFormValues}
                  formValues={formValues}
                  currentValue={props.values[`Answer${index + 11 + 1}`]}
                  propsFormik={props}
                />
              ),
          )}

          <S.LabelText>
            5. Motricidade Reflexa:
            {DescriptionQ5[0] !== '' ? <span>{DescriptionQ5[0]}</span> : null}
          </S.LabelText>
          <TextAndRadioGroupWithDescription
            text={Questions5}
            ArrayValue={returnForms(2)}
            setAnswer={props.setFieldValue}
            keyAnswer={`Answer${18}`}
            direction="horizontal"
            description={DescriptionQ5}
            index={1}
            indexSession={17}
            setFormValues={setFormValues}
            formValues={formValues}
            currentValue={props.values[`Answer${18}`]}
            propsFormik={props}
          />

          <S.LabelText>
            6. Sinergia extensora:
            {DescriptionQ6[0] !== '' ? <span>{DescriptionQ6[0]}</span> : null}
          </S.LabelText>
          {Questions6.map(
            (item, index) =>
              item && (
                <TextAndRadioGroupWithDescription
                  text={item}
                  ArrayValue={returnForms(2)}
                  setAnswer={props.setFieldValue}
                  keyAnswer={`Answer${index + 18 + 1}`}
                  direction="horizontal"
                  description={DescriptionQ6}
                  index={index + 1}
                  indexSession={index + 18}
                  setFormValues={setFormValues}
                  formValues={formValues}
                  currentValue={props.values[`Answer${index + 18 + 1}`]}
                  propsFormik={props}
                />
              ),
          )}

          <S.LabelText>
            7. Sinergia extensora:
            {DescriptionQ7[0] !== '' ? <span>{DescriptionQ7[0]}</span> : null}
          </S.LabelText>
          {Questions7.map(
            (item, index) =>
              item && (
                <TextAndRadioGroupWithDescription
                  text={item}
                  ArrayValue={returnForms(2)}
                  setAnswer={props.setFieldValue}
                  keyAnswer={`Answer${index + 23 + 1}`}
                  direction="horizontal"
                  description={DescriptionQ7}
                  index={index + 1}
                  indexSession={index + 23}
                  setFormValues={setFormValues}
                  formValues={formValues}
                  currentValue={props.values[`Answer${index + 23 + 1}`]}
                  propsFormik={props}
                />
              ),
          )}

          <S.LabelText>
            8. Sinergia extensora
            {DescriptionQ8[0] !== '' ? <span>{DescriptionQ8[0]}</span> : null}
          </S.LabelText>
          {Questions8.map(
            (item, index) =>
              item && (
                <TextAndRadioGroupWithDescription
                  text={item}
                  ArrayValue={returnForms(2)}
                  setAnswer={props.setFieldValue}
                  keyAnswer={`Answer${index + 30 + 1}`}
                  direction="horizontal"
                  description={DescriptionQ8}
                  index={index + 1}
                  indexSession={index + 30}
                  setFormValues={setFormValues}
                  formValues={formValues}
                  currentValue={props.values[`Answer${index + 30 + 1}`]}
                  propsFormik={props}
                />
              ),
          )}

          <Button
            type="primary"
            htmlType="submit"
            onClick={props.handleSubmit}
            style={S.AntdStyles.ButtonSave}
          >
            Salvar
          </Button>
        </>
      )}
    </Formik>
  );
}

export default FuglMeyer;
