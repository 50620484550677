/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: var(--guyabano);
  align-items: center;
  justify-content: center;

  @media (max-width: 950px) {
    flex-direction: column;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 84vw;
  height: 90vh;
  background-color: var(--guyabano);
  align-items: center;
  justify-content: center;
  @media (max-width: 950px) {
    flex-direction: column;
  }
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 90vh;
  padding-top: 20px;
  background-color: var(--guyabano);
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 85vh;
  background-color: var(--ceilingBrightWhite);
  margin-top: 3rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const ChatHeader = styled.div`
  align-items: center;
  vertical-align: center;
  flex-direction: row;
  display: flex;
  padding-left: 30px;
  width: 100%;
  height: 60px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: var(--white);
`;

export const UserNameChat = styled.h5`
  color: var(--davysGrey);
  text-align: left;
  font: bold normal normal 18px Nunito Sans;
  letter-spacing: 0px;
  margin-left: 1rem;
  opacity: 1;
`;

export const Search = styled.div`
  width: 100%;
  height: 8%;
  margin-top: 15px;
`;

export const LastCardTalk = styled.div`
  flex-direction: row;
  display: flex;
  width: 90%;
  height: 110px;
  margin-top: 10px;
  padding: 0.7rem;
  border-radius: 5px;
  background-color: var(--white);
`;

export const Divider = styled.div`
  width: 90%;
  height: 1px;
  margin-top: 15px;
  margin-bottom: 5px;
  background-color: var(--reflection);
`;

export const CardTalk = styled.div`
  width: 90%;
  height: 75px;
  margin-top: 10px;
  flex-direction: row;
  display: flex;
  padding: 0.7rem;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  cursor: pointer;
`;

export const UserImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50px;
`;

export const ColumnTexts = styled.div`
  flex: 1;
  flex-direction: column;
  vertical-align: center;
  margin-left: 0.5rem;
`;

export const RowTexts = styled.div`
  flex: 1;
  height: 0.5rem;
  flex-direction: row;
  display: flex;
`;

export const NewMessageNotif = styled.div`
  height: 24px;
  justify-content: center;
  align-items: center;
  /* width: 55%; */
  background-color: var(--darkCyan);
  border-radius: 5px;
`;

export const ScrollerContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: var(--guyabano);
  overflow-y: scroll;
`;

export const TitlePage = styled.h5`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal normal 20px/27px Nunito Sans;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

export const Topic = styled.h5`
  color: var(--davysGrey);
  text-align: left;
  font: bold normal normal 14px/20px Nunito Sans;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 0.1rem;
`;

export const Hour = styled.h5`
  color: var(--philippineSilver);
  text-align: left;
  font: normal normal normal 14px/20px Nunito Sans;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 0.1rem;
  margin-left: 0.2rem;
`;

export const Subtext = styled.h5`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal normal 13px/20px Nunito Sans;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 1.3rem;
  margin-bottom: 0.4rem;
`;

export const NotifText = styled.h5`
  color: var(--white);
  text-align: center;
  font: normal normal normal 13px/24px Nunito Sans;
  opacity: 1;
`;

export const ButtonText = styled.h5`
  color: var(--darkBlueGray);
  text-align: center;
  font: bold normal normal 13px/24px Nunito Sans;
`;

export const ButtonPacient = styled.div`
  min-width: 180px;
  max-width: 240px;
  flex: 1;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  border-color: purple;
  border-radius: 5px;
  border: 1px solid purple;
  margin-right: 1rem;
  cursor: pointer;
`;

export const TextsContainer = styled.div`
  height: 30px;
  flex: 1;
  display: flex;
  align-items: center;
  vertical-align: center;
  flex-direction: row;
`;

export const ChatInputToolbar = styled.div`
  height: 62px;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const InputArea = styled.div`
  display: flex;
  height: 40px;
  margin-left: 1rem;
  width: 85%;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  border: 1px solid var(--philippineSilver);
`;

export const Input = styled.input`
  border: 0px solid var(--davysGrey);
  border-radius: 5px;
  width: 90%;
  padding-inline: 1rem;
`;

export const VerticalDivider = styled.div`
  height: 90%;
  background-color: var(--ceilingBrightWhite);
  width: 1px;
`;

export const SendView = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  justify-content: center;
  cursor: pointer;
`;

export const BackView = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  margin-right: 20px;
  justify-content: center;
  cursor: pointer;
`;

export const MessagesList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  flex: 1;
  overflow-y: scroll;
`;

export const AssetsView = styled.div`
  display: flex;
  flex: 1;
  background-color: #ffffff00;
  border-width: 0px;
  height: 100%;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const AntdStyles = {
  AvatarUser: {
    color: 'var(--darkCyan)',
    backgroundColor: '#C9E2E1',
    fontSize: 20,
  },
  DropDownButton: {
    background: 'transparent',
    color: 'var(--davysGrey)',
    border: 'none',
    cursor: 'pointer',
    zIndex: 10,
  },
};
