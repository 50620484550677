export function getPatientAgenda(patientID, month, year) {
  return {
    type: '@agenda/GET_PATIENT_AGENDA',
    payload: { patientID, month, year },
  };
}

export function setPatientAgenda(agenda) {
  return {
    type: '@agenda/SET_PATIENT_AGENDA',
    payload: { agenda },
  };
}

export function addEventAgenda(event, history) {
  return {
    type: '@agenda/ADD_EVENT_AGENDA',
    payload: { event, history },
  };
}

export function addPracticeToEvent(practice, id) {
  return {
    type: '@agenda/ADD_PRACTICE_TO_EVENT',
    payload: { practice, id },
  };
}

export function deleteEvent(patientId, eventId, batch, date) {
  return {
    type: '@agenda/DELETE_EVENT',
    payload: { patientId, eventId, batch, date },
  };
}
