/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useCallback, useState, useEffect } from 'react';
import { Input, Button, Modal, Checkbox, Row, Col } from 'antd';
import {
  PropsSpecialityEnum,
  PropsSpecialityTranslated,
  PropsSpecialityArray,
} from 'utils/speciality';
import closeIcon from 'assets/close.svg';
import * as S from './styles';
import './styles.css';

function ModalSpeciality({
  visible,
  setVisible,
  propsFormik,
  specialitiesUser,
  handleOk,
}) {
  const specialityTranslated = useCallback(speciality => {
    const result = PropsSpecialityTranslated.find(p => p.type === speciality)
      .pt;
    return result;
  }, []);
  const [values, setValues] = useState([]);

  return (
    <Modal
      title="Editar especialidade do profissional"
      visible={visible}
      onCancel={() => setVisible(false)}
      closeIcon={
        <img src={closeIcon} alt="close" style={S.AntdStyles.iconClose} />
      }
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            setVisible(false);
            if (values.length > 0) {
              handleOk(values);
            }
          }}
          style={{
            backgroundColor: '#118E88',
            borderRadius: '5px',
            opacity: 1,
          }}
        >
          Ok
        </Button>,
      ]}
    >
      <S.DescInput>Especialidade</S.DescInput>
      <Checkbox.Group
        style={{ width: '100%' }}
        defaultValue={propsFormik.values.specialities.map(s => s.speciality)}
        onChange={checkedValues => {
          setValues(checkedValues);
        }}
      >
        <S.DivMarginTop>
          <Row>
            {PropsSpecialityArray.map(
              item =>
                item && (
                  <Col span={12}>
                    <Checkbox value={item}>
                      {specialityTranslated(PropsSpecialityEnum[item])}
                    </Checkbox>
                  </Col>
                ),
            )}
          </Row>
        </S.DivMarginTop>
      </Checkbox.Group>
      {propsFormik.touched.specialities && propsFormik.errors.specialities ? (
        <S.ErrorMessage>{propsFormik.errors.specialities}</S.ErrorMessage>
      ) : null}

      {propsFormik.values.specialities.findIndex(
        item => item === specialityTranslated(PropsSpecialityEnum.OTHER),
      ) >= 0 ? (
        <Input
          placeholder="Qual?"
          defaultValue={propsFormik.values.otherSpeciality}
          onChange={event =>
            propsFormik.setFieldValue('otherSpeciality', event.target.value)
          }
          style={{ marginTop: '10px' }}
        />
      ) : null}
      {propsFormik.values.specialities.findIndex(
        item => item === specialityTranslated(PropsSpecialityEnum.OTHER),
      ) >= 0 && propsFormik.values.otherSpeciality === '' ? (
        <S.ErrorMessage>É obrigatório</S.ErrorMessage>
      ) : null}
    </Modal>
  );
}

export default ModalSpeciality;
