import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import ReactPlayer from 'react-player';

import VideoCallContext from '../../../../Context';

import * as S from './styles';

export function Preview(props) {
  const { handleSessionStart } = props;
  const [protocolTitle, setProtocolTitle] = useState('');
  const [protocolVideos, setProtocolVideos] = useState([]);
  const [objectsNeeded, setObjectsNeeded] = useState('');
  const [sessionDetails, setSessionDetails] = useState('');
  const { patientDetails, initLiveSession } = useContext(VideoCallContext);

  const day_event_related_info = useSelector(
    state => state.video_call.day_event_related_info,
  );

  const in_live_session_data = useSelector(
    state => state.video_call.in_live_session_data,
  );

  useEffect(() => {
    setProtocolTitle(
      'Exercício para fortalecer equilíbrio e criar resistência',
    );
    setObjectsNeeded('Uma cadeira, um tapete ou edredom e uma vassoura');
    setSessionDetails('Avaliação Semanal...');
  }, []);

  useEffect(() => {
    if (day_event_related_info.protocol !== null) {
      setProtocolVideos([...day_event_related_info.protocol.exercises]);
    }
  }, [day_event_related_info]);

  function handleSubmit() {
    const data = day_event_related_info.protocol.exercises;
    initLiveSession(
      patientDetails.id,
      in_live_session_data.session.sessionId,
      data[0].videoUrl,
    );
    handleSessionStart('start');
  }

  return day_event_related_info.eventDetails !== null ? (
    <S.Container>
      <strong>{protocolTitle}</strong>
      <ul>
        {protocolVideos.map(video => (
          <li>
            <ReactPlayer
              url={video.videoUrl}
              width="150px"
              height="120px"
              title={video.name}
              light
            />
          </li>
        ))}
      </ul>
      <strong>O Paciente vai precisar de </strong>
      <span className="regular-text">{objectsNeeded}</span>
      <strong>Detalhes</strong>
      <span className="regular-text">{sessionDetails}</span>
      <Button
        type="primary"
        htmlType="submit"
        className="btn-start-practice"
        onClick={handleSubmit}
        style={S.AntdStyles.ButtonStart}
      >
        Iniciar Prática
      </Button>
    </S.Container>
  ) : (
    <S.ContainerInfoDontHaveEvents>
      <strong>Não existe evento de telechamada criado para este dia!</strong>
      <br />
      <label>
        Para criar um evento é preciso primeiro criar um protocolo no menu
        Protocolos. Em seguida basta ir no menu Agenda e criar um evento de
        telechamada em Adicionar Evento.
      </label>
      <br />
      <label>
        Caso tenha criado o evento com esta página aberta, basta atualizá-la e
        você receberá as novas informações.
      </label>
    </S.ContainerInfoDontHaveEvents>
  );
}
