/* eslint-disable no-unused-vars */
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  Row,
  Col,
  Input,
  DatePicker,
  Dropdown,
  Menu,
  Button,
  InputNumber,
  AutoComplete,
} from 'antd';

import { addPatientToClinic, validateCPF } from 'store/modules/patient/actions';
import useDebounce from 'hooks/debounce/useDebounce';

import { Formik } from 'formik';
import * as Yup from 'yup';

import moment from 'moment';
import 'moment/locale/pt-br';
import localePTDate from 'antd/lib/locale/pt_BR';

import InputMask from 'react-input-mask';
import { typePaymentArray } from 'utils/typePayment';
import { healthPlanArray } from 'utils/healthPlan';
import { StatesOfBrazil } from 'utils/States';

import { useRef } from 'react';
import closeIcon from 'assets/close.svg';

import { format, addHours } from 'date-fns';
import * as S from './styles';
import './styles.css';

export function AddPatientModal({ isModalVisible, handleModalFalse }) {
  const firstChange = useMediaQuery('(min-width: 770px)');
  const dispatch = useDispatch();
  const formikRef = useRef();
  // const disableCellphone = useSelector(state => state.patient.disableCellphone);
  const disableCellphone = null;

  function valCellphone() {
    dispatch(validateCPF(formikRef?.current?.values?.cpf));
  }
  const debounceChange = useDebounce(valCellphone, 1500);

  const formikGeneralData = {
    initialValues: {
      name: '',
      email: '',
      born: new Date(),
      cpf: '',
      cellphone: '',
      typePayment: null,
      healthPlan: null,
      street: '',
      houseNumber: '',
      complement: '',
      neighborhood: '',
      city: '',
      state: 'Selecione',
      postalCode: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('É obrigatório'),
      email: Yup.string().email('E-mail inválido'),
      born: Yup.string().required('É obrigatório'),
      cpf: Yup.string().required('É obrigatório'),
    }),
  };

  return (
    <Formik
      initialValues={formikGeneralData.initialValues}
      validationSchema={formikGeneralData.validationSchema}
      innerRef={formikRef}
      onSubmit={values => {
        values.born = format(addHours(new Date(values.born), 12), 'yyyy-MM-dd');
        values.cellphone = disableCellphone ? '' : values.cellphone;
        dispatch(addPatientToClinic(values));
        handleModalFalse();
      }}
    >
      {props => (
        <Modal
          title="Adicionar novo paciente"
          visible={isModalVisible}
          onOk={() => handleModalFalse()}
          onCancel={() => handleModalFalse()}
          style={{ top: 20 }}
          width={850}
          closeIcon={
            <img src={closeIcon} alt="close" style={S.AntdStyles.iconClose} />
          }
          footer={[
            <Button
              type="primary"
              onClick={() => props.resetForm()}
              style={{
                width: '180px',
                borderRadius: '5px',
                backgroundColor: 'transparent',
                borderColor: '#118E88',
                color: '#118E88',
              }}
            >
              Limpar Formulário
            </Button>,
            <Button
              type="primary"
              onClick={() => props.handleSubmit()}
              style={{
                width: '180px',
                borderRadius: '5px',
                backgroundColor: '#118E88',
                borderColor: '#118E88',
              }}
            >
              Salvar
            </Button>,
          ]}
        >
          <S.ContainerModal>
            <S.TitlePage>Informações Gerais</S.TitlePage>
            <Row gutter={[32, 16]}>
              <Col className="gutter-row" span={firstChange ? 8 : 24}>
                <S.LabelText className="input-label">
                  Nome
                  <Input
                    style={S.AntdStyles.marginTop}
                    id="name"
                    name="name"
                    type="text"
                    onChange={props.handleChange}
                    value={props.values.name}
                    onBlur={props.handleBlur}
                  />
                </S.LabelText>
                {props.touched.name && props.errors.name ? (
                  <S.ErrorMessage>{props.errors.name}</S.ErrorMessage>
                ) : null}
              </Col>

              <Col className="gutter-row" span={firstChange ? 8 : 24}>
                <S.LabelText className="input-label">
                  CPF
                  <InputMask
                    mask="999.999.999-99"
                    value={props.values.cpf}
                    style={S.AntdStyles.inputMask}
                    onChange={e => {
                      props.setFieldValue('cpf', e.target.value);
                      debounceChange();
                    }}
                  />
                  <S.ErrorMessage>
                    Digite corretamente o CPF. Não será possível alterar depois.
                  </S.ErrorMessage>
                </S.LabelText>
              </Col>

              <Col className="gutter-row" span={firstChange ? 8 : 24}>
                <S.LabelText className="input-label">
                  Telefone Celular
                  <InputMask
                    mask="(99)99999-9999"
                    value={props.values.cellphone}
                    style={{
                      ...S.AntdStyles.inputMask,
                      cursor: disableCellphone ? 'not-allowed' : 'default',
                    }}
                    onChange={e => {
                      props.setFieldValue('cellphone', e.target.value);
                    }}
                  />
                </S.LabelText>
                {disableCellphone && (
                  <S.ErrorMessage>
                    Campo desabilitado, pois o já existe telefone cadastrado
                    nesse CPF.
                  </S.ErrorMessage>
                )}
              </Col>

              <Col className="gutter-row" span={firstChange ? 8 : 24}>
                <S.LabelText className="input-label">
                  Data de Nascimento
                  <DatePicker
                    style={S.AntdStyles.marginTop}
                    onChange={(_, dateString) => {
                      props.setFieldValue(
                        'born',
                        moment(dateString, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                      );
                    }}
                    allowClear={false}
                    value={moment(props.values.born, 'YYYY-MM-DD')}
                    locale={localePTDate}
                    format="DD-MM-YYYY"
                  />
                  {props.touched.born && props.errors.born ? (
                    <S.ErrorMessage>{props.errors.born}</S.ErrorMessage>
                  ) : null}
                </S.LabelText>
              </Col>

              <Col className="gutter-row" span={firstChange ? 8 : 24}>
                <S.LabelText className="input-label">
                  E-mail
                  <Input
                    style={S.AntdStyles.marginTop}
                    id="email"
                    name="email"
                    type="text"
                    onChange={props.handleChange}
                    value={props.values.email}
                    onBlur={props.handleBlur}
                  />
                  {props.touched.email && props.errors.email ? (
                    <S.ErrorMessage>{props.errors.email}</S.ErrorMessage>
                  ) : null}
                </S.LabelText>
              </Col>

              <Col className="gutter-row" span={firstChange ? 8 : 24}>
                <S.LabelText className="input-label">
                  Tipo de Pagamentos
                  <Dropdown
                    overlay={
                      <Menu>
                        {typePaymentArray.map(item => (
                          <Menu.Item>
                            <element
                              onClick={() =>
                                props.setFieldValue('typePayment', item)
                              }
                            >
                              {item}
                            </element>
                          </Menu.Item>
                        ))}
                      </Menu>
                    }
                    placement="bottomCenter"
                  >
                    <Button style={S.AntdStyles.ButtonTypePayment}>
                      {props.values.typePayment === null
                        ? 'Não Informado'
                        : props.values.typePayment}
                    </Button>
                  </Dropdown>
                  {props.touched.typePayment && props.errors.typePayment ? (
                    <S.ErrorMessage>{props.errors.typePayment}</S.ErrorMessage>
                  ) : null}
                </S.LabelText>
              </Col>

              <Col className="gutter-row" span={firstChange ? 8 : 24}>
                <S.LabelText className="input-label">
                  Plano de Saúde
                  <AutoComplete
                    style={S.AntdStyles.AutoComplete}
                    value={
                      props.values.healthPlan === null
                        ? 'Não Informado'
                        : props.values.healthPlan
                    }
                    onChange={text => props.setFieldValue('healthPlan', text)}
                    options={healthPlanArray.map(
                      item =>
                        item && {
                          value: item,
                        },
                    )}
                  />
                  {props.touched.healthPlan && props.errors.healthPlan ? (
                    <S.ErrorMessage>{props.errors.healthPlan}</S.ErrorMessage>
                  ) : null}
                </S.LabelText>
              </Col>
            </Row>
            <br />
            <S.TitlePage>Endereço</S.TitlePage>

            <Row gutter={[32, 16]}>
              <Col className="gutter-row" span={firstChange ? 16 : 24}>
                <S.LabelText className="input-label">
                  Rua
                  <Input
                    style={S.AntdStyles.marginTop}
                    id="street"
                    name="street"
                    type="text"
                    onChange={props.handleChange}
                    value={props.values.street}
                    onBlur={props.handleBlur}
                  />
                </S.LabelText>
              </Col>

              <Col className="gutter-row" span={firstChange ? 8 : 24}>
                <S.LabelText className="input-label">
                  Número
                  <InputNumber
                    min={0}
                    style={S.AntdStyles.inputNumber}
                    value={props.values.houseNumber}
                    onChange={value =>
                      props.setFieldValue('houseNumber', value)
                    }
                  />
                </S.LabelText>
              </Col>

              <Col className="gutter-row" span={firstChange ? 16 : 24}>
                <S.LabelText className="input-label">
                  Complemento
                  <Input
                    style={S.AntdStyles.marginTop}
                    id="complement"
                    name="complement"
                    type="text"
                    onChange={props.handleChange}
                    value={props.values.complement}
                    onBlur={props.handleBlur}
                  />
                </S.LabelText>
              </Col>

              <Col className="gutter-row" span={firstChange ? 8 : 24}>
                <S.LabelText className="input-label">
                  Bairro
                  <Input
                    style={S.AntdStyles.marginTop}
                    id="neighborhood"
                    name="neighborhood"
                    type="text"
                    onChange={props.handleChange}
                    value={props.values.neighborhood}
                    onBlur={props.handleBlur}
                  />
                </S.LabelText>
              </Col>

              <Col className="gutter-row" span={firstChange ? 8 : 24}>
                <S.LabelText className="input-label">
                  Cidade
                  <Input
                    style={S.AntdStyles.marginTop}
                    id="city"
                    name="city"
                    type="text"
                    onChange={props.handleChange}
                    value={props.values.city}
                    onBlur={props.handleBlur}
                  />
                </S.LabelText>
              </Col>

              <Col className="gutter-row" span={firstChange ? 8 : 24}>
                <S.LabelText className="input-label">
                  Estado
                  <Dropdown
                    overlay={
                      <Menu style={S.AntdStyles.menuStates}>
                        {StatesOfBrazil.map(item => (
                          <Menu.Item
                            onClick={() => props.setFieldValue('state', item)}
                          >
                            {item}
                          </Menu.Item>
                        ))}
                      </Menu>
                    }
                  >
                    <Button style={S.AntdStyles.ButtonTypePayment}>
                      {props.values.state}
                    </Button>
                  </Dropdown>
                </S.LabelText>
              </Col>

              <Col className="gutter-row" span={firstChange ? 8 : 24}>
                <S.LabelText className="input-label">
                  CEP
                  <InputMask
                    mask="99999-999"
                    value={props.values.postalCode}
                    style={S.AntdStyles.inputMask}
                    onChange={e =>
                      props.setFieldValue('postalCode', e.target.value)
                    }
                  />
                </S.LabelText>
              </Col>
            </Row>
          </S.ContainerModal>
        </Modal>
      )}
    </Formik>
  );
}
