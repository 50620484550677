/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useCallback, useEffect } from 'react';
import {
  Row,
  Col,
  Input,
  DatePicker,
  Dropdown,
  Menu,
  Button,
  InputNumber,
  Checkbox,
  Radio,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import InputMask from 'react-input-mask';
import './styles.css';
import { Formik } from 'formik';
import moment from 'moment';
import 'moment/locale/pt-br';
import localePTDate from 'antd/lib/locale/pt_BR';
import {
  PropsComplementaryExamsArrayTranslated,
  PropsComplementaryExamsInputArray,
} from 'utils/complementaryExams';
import {
  PropsTimesAWeekTranslated,
  TimesAWeekTranslated,
} from 'utils/timesAWeek';
import {
  PropsDominancyHandedTranslated,
  DominancyHandedTranslated,
} from 'utils/dominancyEnum';
import {
  PropsComorbiditiesLeftArrayTranslated,
  PropsComorbiditiesRightArrayTranslated,
  PropsComorbiditiesRightInputArray,
  PropsMoreComorbiditiesArrayTranslated,
  PropsMoreComorbiditiesInputArray,
} from 'utils/comorbidities';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import ImgHumanBody from 'assets/corpo-humano.png';
import {
  savePatientAnamnesis,
  savePatientAnamnesisFinal,
} from 'store/modules/anamnesis/actions';
import useDebounce from 'hooks/debounce/useDebounce';
import SavedAnswer from 'components/SavedAnswer';
import { useParams } from 'react-router-dom';

import * as S from './styles';

function FormAnamnesis() {
  const anamnesisDetails = useSelector(state => state.anamnesis.anamnesis);
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();
  const firstChange = useMediaQuery('(min-width: 770px)');
  const { id } = useParams();

  const formikAnamnesis = {
    initialValues: anamnesisDetails,
    onSubmit: values => {
      /* Validações para quando quiser validar subrespostas

      if (values.dados_da_consulta.data_da_consulta === 'Data inválida') {
        values.dados_da_consulta.data_da_consulta = null;
      }

      if (values.dados_pessoais.pratica_exercicios_fisicos === 'no') {
        values.dados_pessoais.por_quanto_tempo = '';
        values.dados_pessoais.quantas_vezes_na_semana = '';
      }

      if (
        values.exames_complementares.ressonancia_magnetica.checked === false
      ) {
        values.exames_complementares.ressonancia_magnetica.local = '';
        values.exames_complementares.ressonancia_magnetica.url_imagem = '';
      }

      if (
        values.exames_complementares.tomografia_computadorizada.checked ===
        false
      ) {
        values.exames_complementares.tomografia_computadorizada.local = '';
        values.exames_complementares.tomografia_computadorizada.url_imagem = '';
      }

      if (values.exames_complementares.outro.checked === false) {
        values.exames_complementares.outro.qual = '';
      }

      if (values.comorbidades.dores.outro_local.checked === false) {
        values.comorbidades.dores.outro_local.texto = '';
      }

      if (values.comorbidades.diabetes.checked === false) {
        values.comorbidades.diabetes.texto = '';
      }

      if (values.comorbidades.tabagismo.checked === false) {
        values.comorbidades.tabagismo.tempo = '';
      }

      if (values.comorbidades.doenca_cardiaca.checked === false) {
        values.comorbidades.doenca_cardiaca.qual = '';
      }

      if (values.comorbidades.doenca_renal.checked === true) {
        values.comorbidades.doenca_renal.qual = '';
      }

      if (values.comorbidades.outra.checked === false) {
        values.comorbidades.outra.qual = '';
      } */

      dispatch(savePatientAnamnesisFinal(values, id));
    },
  };

  const { TextArea } = Input;

  const getValueObjectNested = useCallback((objectFormik, path) => {
    const pathObjectSplitted = path.split('.');
    let value = objectFormik;
    for (let i = 0; i < pathObjectSplitted.length; i += 1) {
      value = value[pathObjectSplitted[i]];
    }
    return value;
  }, []);

  const formikRef = useRef();

  function saveForm() {
    if (formikRef?.current?.values !== undefined) {
      dispatch(savePatientAnamnesis(formikRef?.current?.values, id));
    }
  }

  const debounceChange = useDebounce(saveForm, 2000);

  return (
    <Formik
      initialValues={formikAnamnesis.initialValues}
      onSubmit={values => formikAnamnesis.onSubmit(values)}
      innerRef={formikRef}
    >
      {props => (
        <div style={{ paddingBottom: '3rem' }}>
          {useEffect(() => {
            debounceChange();
          }, [props.values])}

          <S.TitlePage>Anamnese</S.TitlePage>

          <S.DivSession>
            <S.TitleSession>Sobre a consulta</S.TitleSession>
          </S.DivSession>

          <Row gutter={[32, 16]}>
            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Data da consulta
                <DatePicker
                  style={S.AntdStyles.marginTop}
                  locale={localePTDate}
                  allowClear={false}
                  format="DD-MM-YYYY"
                  onChange={(_, dateString) => {
                    props.setFieldValue(
                      'dados_da_consulta.data_da_consulta',
                      moment(dateString, 'DD/MM/YYYY').format('DD/MM/YYYY'),
                    );
                  }}
                  defaultValue={
                    getValueObjectNested(
                      props.values,
                      'dados_da_consulta.data_da_consulta',
                    ) !== ''
                      ? moment(
                          getValueObjectNested(
                            props.values,
                            'dados_da_consulta.data_da_consulta',
                          ),
                          'DD/MM/YYYY',
                        )
                      : null
                  }
                />
              </S.LabelText>
              <SavedAnswer
                resource="hasAnamnesis"
                propsFormik={props}
                fieldName="dados_da_consulta.data_da_consulta"
              />
            </Col>
            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Nome do Médico
                <Input
                  style={S.AntdStyles.marginTop}
                  id="dados_da_consulta.nome_do_medico"
                  name="dados_da_consulta.nome_do_medico"
                  type="text"
                  onChange={props.handleChange}
                  value={getValueObjectNested(
                    props.values,
                    'dados_da_consulta.nome_do_medico',
                  )}
                  onBlur={props.handleBlur}
                />
              </S.LabelText>
              <SavedAnswer
                resource="hasAnamnesis"
                propsFormik={props}
                fieldName="dados_da_consulta.nome_do_medico"
              />
            </Col>
            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Especialidade
                <Input
                  style={S.AntdStyles.marginTop}
                  id="dados_da_consulta.especialidade_do_medico"
                  name="dados_da_consulta.especialidade_do_medico"
                  type="text"
                  onChange={props.handleChange}
                  value={getValueObjectNested(
                    props.values,
                    'dados_da_consulta.especialidade_do_medico',
                  )}
                  onBlur={props.handleBlur}
                />
              </S.LabelText>
              <SavedAnswer
                resource="hasAnamnesis"
                propsFormik={props}
                fieldName="dados_da_consulta.especialidade_do_medico"
              />
            </Col>
            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Telefone do Médico
                <InputMask
                  mask="(99)99999-9999"
                  defaultValue={getValueObjectNested(
                    props.values,
                    'dados_da_consulta.telefone_do_medico',
                  )}
                  style={S.AntdStyles.inputMask}
                  onChange={e =>
                    props.setFieldValue(
                      'dados_da_consulta.telefone_do_medico',
                      e.target.value,
                    )
                  }
                />
              </S.LabelText>
              <SavedAnswer
                resource="hasAnamnesis"
                propsFormik={props}
                fieldName="dados_da_consulta.telefone_do_medico"
              />
            </Col>
            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Email do Médico
                <Input
                  style={S.AntdStyles.marginTop}
                  id="dados_da_consulta.email_do_medico"
                  name="dados_da_consulta.email_do_medico"
                  type="text"
                  onChange={props.handleChange}
                  value={getValueObjectNested(
                    props.values,
                    'dados_da_consulta.email_do_medico',
                  )}
                  onBlur={props.handleBlur}
                />
              </S.LabelText>
              <SavedAnswer
                resource="hasAnamnesis"
                propsFormik={props}
                fieldName="dados_da_consulta.email_do_medico"
              />
            </Col>
            <Col className="gutter-row" span={24}>
              <S.LabelText className="input-label">
                Histórico do Paciente
                <TextArea
                  defaultValue={getValueObjectNested(
                    props.values,
                    'dados_da_consulta.historico_do_paciente',
                  )}
                  rows={3}
                  onChange={event =>
                    props.setFieldValue(
                      'dados_da_consulta.historico_do_paciente',
                      event.target.value,
                    )
                  }
                  style={{ marginTop: '4px' }}
                  placeholder="Escreva aqui caso o paciente já houver sido diagnosticado previamente."
                />
              </S.LabelText>
              <SavedAnswer
                resource="hasAnamnesis"
                propsFormik={props}
                fieldName="dados_da_consulta.historico_do_paciente"
              />
            </Col>
            <Col className="gutter-row" span={24}>
              <S.LabelText className="input-label">
                Diagnóstico Médico
                <TextArea
                  defaultValue={getValueObjectNested(
                    props.values,
                    'dados_da_consulta.diagnostico_medico',
                  )}
                  rows={3}
                  onChange={event =>
                    props.setFieldValue(
                      'dados_da_consulta.diagnostico_medico',
                      event.target.value,
                    )
                  }
                  style={{ marginTop: '4px' }}
                  placeholder="Escreva aqui caso o paciente já houver sido diagnosticado previamente."
                />
              </S.LabelText>
              <SavedAnswer
                resource="hasAnamnesis"
                propsFormik={props}
                fieldName="dados_da_consulta.diagnostico_medico"
              />
            </Col>
            <Col className="gutter-row" span={24}>
              <S.LabelText className="input-label">
                Medicações
                <TextArea
                  rows={3}
                  style={{ marginTop: '4px' }}
                  placeholder="Descreva aqui nome, dosagem e frequência das medicações consumidas pelo paciente."
                  defaultValue={getValueObjectNested(
                    props.values,
                    'medicacoesString',
                  )}
                  onChange={event =>
                    props.setFieldValue('medicacoesString', event.target.value)
                  }
                />
              </S.LabelText>
              <SavedAnswer
                resource="hasAnamnesis"
                propsFormik={props}
                fieldName="medicacoesString"
              />
            </Col>
          </Row>

          <S.DivSession style={S.AntdStyles.DivSessionAddress}>
            <S.TitleSession>Sobre o paciente</S.TitleSession>
          </S.DivSession>

          <Row gutter={[32, 16]} style={S.AntdStyles.marginTop2rem}>
            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Profissão
                <Input
                  style={S.AntdStyles.marginTop}
                  id="dados_pessoais.profissao"
                  name="dados_pessoais.profissao"
                  type="text"
                  onChange={props.handleChange}
                  value={getValueObjectNested(
                    props.values,
                    'dados_pessoais.profissao',
                  )}
                  onBlur={props.handleBlur}
                />
              </S.LabelText>
              <SavedAnswer
                resource="hasAnamnesis"
                propsFormik={props}
                fieldName="dados_pessoais.profissao"
              />
            </Col>

            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                O paciente é
                <Dropdown
                  overlay={
                    <Menu>
                      {PropsDominancyHandedTranslated.map(dominancy => (
                        <Menu.Item>
                          <element
                            onClick={() =>
                              props.setFieldValue(
                                'dados_pessoais.dominancia',
                                dominancy.type,
                              )
                            }
                          >
                            {dominancy.pt}
                          </element>
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                  placement="bottomCenter"
                >
                  <Button style={S.AntdStyles.ButtonTypePayment}>
                    {DominancyHandedTranslated(
                      getValueObjectNested(
                        props.values,
                        'dados_pessoais.dominancia',
                      ),
                    )}
                  </Button>
                </Dropdown>
              </S.LabelText>
              <SavedAnswer
                resource="hasAnamnesis"
                propsFormik={props}
                fieldName="dados_pessoais.dominancia"
              />
            </Col>

            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Pratica exercícios físicos?
                <Radio.Group
                  onChange={e =>
                    props.setFieldValue(
                      'dados_pessoais.pratica_exercicios_fisicos',
                      e.target.value,
                    )
                  }
                  value={getValueObjectNested(
                    props.values,
                    'dados_pessoais.pratica_exercicios_fisicos',
                  )}
                  style={{ marginTop: '4px' }}
                >
                  <Radio value="no">Não</Radio>
                  <Radio value="yes">Sim</Radio>
                </Radio.Group>
              </S.LabelText>
              <SavedAnswer
                resource="hasAnamnesis"
                propsFormik={props}
                fieldName="dados_pessoais.pratica_exercicios_fisicos"
              />
            </Col>

            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Há quanto tempo?
                <Input
                  style={S.AntdStyles.marginTop}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  disabled={
                    getValueObjectNested(
                      props.values,
                      'dados_pessoais.pratica_exercicios_fisicos',
                    ) === 'no'
                  }
                  id="dados_pessoais.por_quanto_tempo"
                  name="dados_pessoais.por_quanto_tempo"
                  type="text"
                  value={getValueObjectNested(
                    props.values,
                    'dados_pessoais.por_quanto_tempo',
                  )}
                />
              </S.LabelText>
              <SavedAnswer
                resource="hasAnamnesis"
                propsFormik={props}
                fieldName="dados_pessoais.por_quanto_tempo"
              />
            </Col>

            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Quantas vezes na semana?
                <Dropdown
                  disabled={
                    getValueObjectNested(
                      props.values,
                      'dados_pessoais.pratica_exercicios_fisicos',
                    ) === 'no'
                  }
                  overlay={
                    <Menu>
                      {PropsTimesAWeekTranslated.map(times => (
                        <Menu.Item>
                          <element
                            onClick={() =>
                              props.setFieldValue(
                                'dados_pessoais.quantas_vezes_na_semana',
                                times.type,
                              )
                            }
                          >
                            {times.pt}
                          </element>
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                  placement="bottomCenter"
                >
                  <Button style={S.AntdStyles.ButtonTypePayment}>
                    {TimesAWeekTranslated(
                      getValueObjectNested(
                        props.values,
                        'dados_pessoais.quantas_vezes_na_semana',
                      ),
                    )}
                  </Button>
                </Dropdown>
              </S.LabelText>
              <SavedAnswer
                resource="hasAnamnesis"
                propsFormik={props}
                fieldName="dados_pessoais.quantas_vezes_na_semana"
              />
            </Col>

            <Col className="gutter-row" span={24}>
              <S.LabelText className="input-label">
                Hobbies
                <TextArea
                  defaultValue={getValueObjectNested(
                    props.values,
                    'dados_pessoais.hobbies',
                  )}
                  rows={3}
                  onChange={event =>
                    props.setFieldValue(
                      'dados_pessoais.hobbies',
                      event.target.value,
                    )
                  }
                  style={{ marginTop: '4px' }}
                  placeholder="Ler, fazer yoga, cozinhar, desenhar, jogar video game."
                />
              </S.LabelText>
              <SavedAnswer
                resource="hasAnamnesis"
                propsFormik={props}
                fieldName="dados_pessoais.hobbies"
              />
            </Col>
          </Row>

          <S.DivSession style={{ marginTop: '3rem', width: '180px' }}>
            <S.TitleSession>Exames Complementares</S.TitleSession>
          </S.DivSession>

          <Row gutter={[32, 16]} style={S.AntdStyles.marginTop2rem}>
            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              {PropsComplementaryExamsArrayTranslated.map(
                item =>
                  item && (
                    <div style={{ marginBottom: '8px' }}>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Checkbox
                          defaultChecked={getValueObjectNested(
                            props.values,
                            item.type,
                          )}
                          onChange={e =>
                            props.setFieldValue(
                              `${item.type}`,
                              e.target.checked,
                            )
                          }
                        >
                          {item.pt}
                        </Checkbox>
                        <SavedAnswer
                          resource="hasAnamnesis"
                          propsFormik={props}
                          fieldName={`${item.type}`}
                        />
                      </div>
                      {PropsComplementaryExamsInputArray.map(
                        input =>
                          input.from === item.type && (
                            <>
                              <S.LabelText
                                className="input-label"
                                style={{ marginTop: '0.5rem' }}
                              >
                                <Input
                                  style={S.AntdStyles.marginTop}
                                  disabled={
                                    !getValueObjectNested(
                                      props.values,
                                      input.from,
                                    )
                                  }
                                  placeholder={input.label}
                                  id={`${input.to}`}
                                  name={`${input.to}`}
                                  type="text"
                                  onChange={props.handleChange}
                                  value={getValueObjectNested(
                                    props.values,
                                    input.to,
                                  )}
                                  onBlur={props.handleBlur}
                                />
                              </S.LabelText>
                              <SavedAnswer
                                resource="hasAnamnesis"
                                propsFormik={props}
                                fieldName={`${input.to}`}
                              />
                            </>
                          ),
                      )}
                    </div>
                  ),
              )}
            </Col>
          </Row>

          <S.DivSession style={{ marginTop: '3rem', width: '55px' }}>
            <S.TitleSession>Laudo</S.TitleSession>
          </S.DivSession>

          <Row gutter={[32, 16]} style={S.AntdStyles.marginTop2rem}>
            <Col className="gutter-row" span={24}>
              <TextArea
                defaultValue={getValueObjectNested(props.values, 'laudo')}
                rows={3}
                onChange={event =>
                  props.setFieldValue('laudo', event.target.value)
                }
                style={{ marginTop: '4px' }}
              />
              <SavedAnswer
                resource="hasAnamnesis"
                propsFormik={props}
                fieldName="laudo"
              />
            </Col>
          </Row>

          <S.DivSession style={{ marginTop: '3rem', width: '110px' }}>
            <S.TitleSession>Comorbidades</S.TitleSession>
          </S.DivSession>

          <Row gutter={[32, 16]} style={S.AntdStyles.marginTop2rem}>
            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              {PropsComorbiditiesLeftArrayTranslated.map(
                comorbiditie =>
                  comorbiditie && (
                    <div style={{ marginBottom: '8px' }}>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Checkbox
                          defaultChecked={getValueObjectNested(
                            props.values,
                            comorbiditie.type,
                          )}
                          onChange={e =>
                            props.setFieldValue(
                              `${comorbiditie.type}`,
                              e.target.checked,
                            )
                          }
                        >
                          {comorbiditie.pt}
                        </Checkbox>
                        <SavedAnswer
                          resource="hasAnamnesis"
                          propsFormik={props}
                          fieldName={`${comorbiditie.type}`}
                        />
                      </div>
                    </div>
                  ),
              )}
            </Col>
            <Col
              className="gutter-row"
              span={firstChange ? 8 : 24}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
              }}
            >
              <img
                src={ImgHumanBody}
                style={{
                  height: 180,
                  width: 70,
                  position: 'absolute',
                  top: 30,
                  left: 50,
                }}
              />
              {getValueObjectNested(
                props.values,
                'comorbidades.dores.cabeca',
              ) && (
                <div
                  style={{
                    ...S.AntdStyles.comorbidityIndicator,
                    top: 30,
                    left: 77,
                  }}
                />
              )}

              {getValueObjectNested(
                props.values,
                'comorbidades.dores.costas',
              ) && (
                <div
                  style={{
                    ...S.AntdStyles.comorbidityIndicator,
                    top: 65,
                    left: 77,
                  }}
                />
              )}

              {getValueObjectNested(
                props.values,
                'comorbidades.dores.braco_direito',
              ) && (
                <div
                  style={{
                    ...S.AntdStyles.comorbidityIndicator,
                    top: 85,
                    left: 58,
                  }}
                />
              )}

              {getValueObjectNested(
                props.values,
                'comorbidades.dores.mao_direita',
              ) && (
                <div
                  style={{
                    ...S.AntdStyles.comorbidityIndicator,
                    top: 120,
                    left: 52,
                  }}
                />
              )}

              {getValueObjectNested(
                props.values,
                'comorbidades.dores.perna_direita',
              ) && (
                <div
                  style={{
                    ...S.AntdStyles.comorbidityIndicator,
                    top: 140,
                    left: 69,
                  }}
                />
              )}

              {getValueObjectNested(
                props.values,
                'comorbidades.dores.pe_direito',
              ) && (
                <div
                  style={{
                    ...S.AntdStyles.comorbidityIndicator,
                    top: 195,
                    left: 70,
                  }}
                />
              )}

              {getValueObjectNested(
                props.values,
                'comorbidades.dores.tronco',
              ) && (
                <div
                  style={{
                    ...S.AntdStyles.comorbidityIndicator,
                    top: 85,
                    left: 80,
                  }}
                />
              )}

              {getValueObjectNested(
                props.values,
                'comorbidades.dores.braco_esquerdo',
              ) && (
                <div
                  style={{
                    ...S.AntdStyles.comorbidityIndicator,
                    top: 85,
                    left: 98,
                  }}
                />
              )}

              {getValueObjectNested(
                props.values,
                'comorbidades.dores.mao_esquerda',
              ) && (
                <div
                  style={{
                    ...S.AntdStyles.comorbidityIndicator,
                    top: 120,
                    left: 103,
                  }}
                />
              )}

              {getValueObjectNested(
                props.values,
                'comorbidades.dores.perna_esquerda',
              ) && (
                <div
                  style={{
                    ...S.AntdStyles.comorbidityIndicator,
                    top: 140,
                    left: 86,
                  }}
                />
              )}

              {getValueObjectNested(
                props.values,
                'comorbidades.dores.pe_esquerdo',
              ) && (
                <div
                  style={{
                    ...S.AntdStyles.comorbidityIndicator,
                    top: 195,
                    left: 86,
                  }}
                />
              )}
            </Col>
            <Col
              className="gutter-row"
              span={firstChange ? 8 : 24}
              style={{ marginTop: firstChange ? '0rem' : '15rem' }}
            >
              {PropsComorbiditiesRightArrayTranslated.map(
                comorbiditie =>
                  comorbiditie && (
                    <div style={{ marginBottom: '8px' }}>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Checkbox
                          defaultChecked={getValueObjectNested(
                            props.values,
                            comorbiditie.type,
                          )}
                          onChange={e =>
                            props.setFieldValue(
                              `${comorbiditie.type}`,
                              e.target.checked,
                            )
                          }
                        >
                          {comorbiditie.pt}
                        </Checkbox>
                        <SavedAnswer
                          resource="hasAnamnesis"
                          propsFormik={props}
                          fieldName={`${comorbiditie.type}`}
                        />
                      </div>
                      {PropsComorbiditiesRightInputArray.map(
                        input =>
                          input.from === comorbiditie.type && (
                            <>
                              <S.LabelText
                                className="input-label"
                                style={{ marginTop: '0.5rem' }}
                              >
                                <Input
                                  style={S.AntdStyles.marginTop}
                                  disabled={
                                    !getValueObjectNested(
                                      props.values,
                                      input.from,
                                    )
                                  }
                                  placeholder={input.label}
                                  id={`${input.to}`}
                                  name={`${input.to}`}
                                  type="text"
                                  onChange={props.handleChange}
                                  value={getValueObjectNested(
                                    props.values,
                                    input.to,
                                  )}
                                  onBlur={props.handleBlur}
                                />
                              </S.LabelText>
                              <SavedAnswer
                                resource="hasAnamnesis"
                                propsFormik={props}
                                fieldName={`${input.to}`}
                              />
                            </>
                          ),
                      )}
                    </div>
                  ),
              )}
            </Col>

            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              {PropsMoreComorbiditiesArrayTranslated.map(
                item =>
                  item && (
                    <div style={{ marginBottom: '8px' }}>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Checkbox
                          defaultChecked={getValueObjectNested(
                            props.values,
                            item.type,
                          )}
                          onChange={e =>
                            props.setFieldValue(
                              `${item.type}`,
                              e.target.checked,
                            )
                          }
                        >
                          {item.pt}
                        </Checkbox>
                        <SavedAnswer
                          resource="hasAnamnesis"
                          propsFormik={props}
                          fieldName={`${item.type}`}
                        />
                      </div>
                      {PropsMoreComorbiditiesInputArray.map(
                        input =>
                          input.from === item.type && (
                            <>
                              <S.LabelText
                                className="input-label"
                                style={{ marginTop: '0.5rem' }}
                              >
                                <Input
                                  style={S.AntdStyles.marginTop}
                                  disabled={
                                    !getValueObjectNested(
                                      props.values,
                                      input.from,
                                    )
                                  }
                                  placeholder={input.label}
                                  id={`${input.to}`}
                                  name={`${input.to}`}
                                  type="text"
                                  onChange={props.handleChange}
                                  value={getValueObjectNested(
                                    props.values,
                                    input.to,
                                  )}
                                  onBlur={props.handleBlur}
                                />
                              </S.LabelText>
                              <SavedAnswer
                                resource="hasAnamnesis"
                                propsFormik={props}
                                fieldName={`${input.to}`}
                              />
                            </>
                          ),
                      )}
                    </div>
                  ),
              )}
            </Col>

            <Col className="gutter-row" span={24}>
              <S.LabelText className="input-label">
                Queixa Principal
                <TextArea
                  defaultValue={getValueObjectNested(
                    props.values,
                    'queixa_principal',
                  )}
                  rows={3}
                  onChange={event =>
                    props.setFieldValue('queixa_principal', event.target.value)
                  }
                  style={{ marginTop: '4px' }}
                />
              </S.LabelText>
              <SavedAnswer
                resource="hasAnamnesis"
                propsFormik={props}
                fieldName="queixa_principal"
              />
            </Col>

            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Já ocorreu queda?
                <Radio.Group
                  onChange={e =>
                    props.setFieldValue(
                      'ja_ocorreu_alguma_queda',
                      e.target.value,
                    )
                  }
                  defaultValue={getValueObjectNested(
                    props.values,
                    'ja_ocorreu_alguma_queda',
                  )}
                  style={{ marginTop: '4px' }}
                >
                  <Radio value="não">Não</Radio>
                  <Radio value="sim">Sim</Radio>
                </Radio.Group>
              </S.LabelText>
              <SavedAnswer
                resource="hasAnamnesis"
                propsFormik={props}
                fieldName="ja_ocorreu_alguma_queda"
              />
            </Col>

            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Quantas no último mês?
                <InputNumber
                  disabled={
                    getValueObjectNested(
                      props.values,
                      'ja_ocorreu_alguma_queda',
                    ) === 'não'
                  }
                  min={0}
                  style={S.AntdStyles.inputNumber}
                  defaultValue={getValueObjectNested(
                    props.values,
                    'quantas_no_ultimo_mes',
                  )}
                  onChange={value =>
                    props.setFieldValue('quantas_no_ultimo_mes', value)
                  }
                />
              </S.LabelText>
              <SavedAnswer
                resource="hasAnamnesis"
                propsFormik={props}
                fieldName="quantas_no_ultimo_mes"
              />
            </Col>

            <Col className="gutter-row" span={24}>
              <S.LabelText className="input-label">
                Diagnóstico Cinesiológico-Funcional
                <TextArea
                  defaultValue={getValueObjectNested(
                    props.values,
                    'diagnostico_cinesiologico_funcional',
                  )}
                  rows={3}
                  onChange={event =>
                    props.setFieldValue(
                      'diagnostico_cinesiologico_funcional',
                      event.target.value,
                    )
                  }
                  style={{ marginTop: '4px' }}
                />
              </S.LabelText>
              <SavedAnswer
                resource="hasAnamnesis"
                propsFormik={props}
                fieldName="diagnostico_cinesiologico_funcional"
              />
            </Col>
          </Row>

          <Button
            type="primary"
            htmlType="submit"
            onClick={props.handleSubmit}
            style={S.AntdStyles.ButtonSave}
          >
            Salvar Alterações
          </Button>
        </div>
      )}
    </Formik>
  );
}

export default FormAnamnesis;
