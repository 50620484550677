import React from 'react';
import { Layout } from 'antd';

import ImageLogoHeader from 'assets/logo-header.svg';
import { ImageMonitoreLogoHeader } from './styles';

import * as S from './styles';

export function HeaderTelemonitoring() {
  const { Header } = Layout;

  return (
    <Header style={S.AntdStyles.header}>
      <ImageMonitoreLogoHeader src={ImageLogoHeader} />
    </Header>
  );
}
