import produce from 'immer';

const INITIAL_STATE = {
  token: null,
  signed: false,
  loading: false,
  user: null,
  isEmailVerified: true,
  isMyAccountView: true,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@auth/SIGN_IN_REQUEST': {
        draft.loading = true;
        break;
      }

      case '@auth/SIGN_IN_SUCCESS': {
        draft.token = action.payload.token;
        draft.signed = true;
        draft.loading = false;
        draft.user = action.payload.user;
        break;
      }

      case '@auth/SIGN_IN_SUCCESS_GOOGLE': {
        draft.token = action.payload.token;
        draft.signed = true;
        draft.loading = false;
        draft.user = action.payload.user;
        break;
      }

      case '@auth/SIGN_IN_SUCCESS_WITHOUT_VERIFICATION': {
        draft.token = action.payload.token;
        draft.loading = false;
        draft.user = action.payload.user;
        break;
      }

      case '@auth/SIGN_FAILURE': {
        draft.loading = false;
        break;
      }

      case '@auth/SIGN_OUT': {
        draft.token = null;
        draft.signed = false;
        draft.loading = false;
        draft.isAdmin = false;
        draft.user = null;
        draft.isEmailVerified = true;
        draft.isMyAccountView = true;
        break;
      }

      case '@auth/REFRESH_PAGE_START': {
        draft.signed = false;
        break;
      }

      case '@auth/REFRESH_PAGE_END': {
        draft.signed = true;
        break;
      }

      case '@auth/SET_USER': {
        if (draft.user === null) {
          draft.user = { ...action.payload.user };
          break;
        }
        draft.user = { ...draft.user, ...action.payload.user };
        break;
      }

      case '@auth/UPDATE_USER_STATE': {
        draft.user = action.payload.user;
        break;
      }

      case '@auth/SIGNED': {
        draft.signed = !draft.signed;
        break;
      }

      case '@auth/SET_IS_EMAIL_VERIFIED': {
        draft.isEmailVerified = action.payload.isVerified;
        break;
      }

      case '@auth/SET_MY_ACCOUNT_VIEW': {
        draft.isMyAccountView = action.payload.isView;
        break;
      }

      case '@auth/CANCEL_LOADING': {
        draft.loading = action.payload.status;
        break;
      }

      default:
    }
  });
}
