import styled from 'styled-components';

export const AntdStyles = {
  marginTop: {
    marginTop: '4px',
    width: '90%',
    alignSelf: 'center',
  },
  iconClose: { marginRight: 8, width: 15, marginBottom: 4 },

  ButtonAdmin: {
    background: 'var(--darkCyan) 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    opacity: 1,
    width: '14rem',
    marginTop: '1rem',
    marginBottom: '5rem',
  },

  Final: {
    display: 'flex',
    alignSelf: 'flex-end',
  },

  iconInfo: {
    color: 'var(--unnamed-color-b1b1b1) 0% 0% no-repeat padding-box;',
    marginRight: '5rem',
    height: 16,
    width: 16,
  },

  AvatarUser: {
    color: 'var(--darkCyan)',
    backgroundColor: '#C9E2E1',
    marginRight: '0.5rem',
    alignSelf: 'center',
  },
};

export const TitleAdmin = styled.h5`
  color: var(--battleshipGrey);
  text-align: left;
  font: normal normal normal 16px/22px Nunito Sans;
  letter-spacing: 0px;
  margin-top: 1rem;
`;

export const SubtitleAdmin = styled.h6`
  color: var(--battleshipGrey);
  text-align: left;
  font: normal normal normal 12px/16px Nunito Sans;
  letter-spacing: 0px;
  margin-top: 1rem;
  width: 40%;
`;

export const DivBlockTextAndInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: ${props => (props.secondChange ? '100%' : '100%')};
`;

export const LabelText = styled.div`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal bold 14px/24px Nunito Sans;
  letter-spacing: 0px;
  display: flex;
  flex-direction: column;
`;

export const Center = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
`;

export const ErrorMessage = styled.label`
  color: #c12;
  font-size: 12px;
  margin-top: 2px;
  font-weight: 100;
`;

export const Container = styled.div`
  display: flex;
  max-height: 15rem;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  width: ${props => (props.secondChange ? '100%' : '100%')};
`;

export const IconButton = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  cursor: pointer;
`;
