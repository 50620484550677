/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import 'antd/dist/antd.less';
import { useHistory } from 'react-router-dom';
import arrow from 'assets/arrow-left-grey.svg';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';

function ReceptionistPageHeader() {
  const history = useHistory();
  const isBiggerThan = useMediaQuery('(min-width: 990px)');

  return (
    <div
      onClick={() => history.push('/professional_list')}
      style={{
        display: 'flex',
        backgroundColor: '#f7f7f7',
        alignItems: 'center',
        border: '1px solid #868686',
        borderRadius: '4px',
        opacity: 1,
        font: 'normal normal bold',
        letterSpacing: '0px',
        color: '#868686',
        height: '18px',
        width: '100%',
        cursor: 'pointer',
        padding: '16px 0 16px 14px',
      }}
    >
      <img src={arrow} alt="back" style={{ width: 6, marginRight: 14 }} />
      <div style={{ width: 1, height: 34, backgroundColor: '#D5D5D5' }} />
      <div
        style={{
          fontSize: isBiggerThan ? 13.5 : 12.5,
          color: '#868686',
          fontWeight: 'bold',
          marginLeft: 12,
          fontFamily: 'Nunito Sans',
        }}
      >
        Selecionar outro profissional
      </div>
    </div>
  );
}

export default ReceptionistPageHeader;
