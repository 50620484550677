/* eslint-disable no-unused-vars */
import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  anamnesis: null,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@anamnesis/GET_PATIENT_ANAMNESIS': {
        draft.loading = true;
        break;
      }

      case '@anamnesis/SET_PATIENT_ANAMNESIS': {
        draft.loading = false;
        draft.anamnesis = action.payload.anamnesis;
        break;
      }

      default:
    }
  });
}
