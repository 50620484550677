/* eslint-disable no-unused-vars */
import produce from 'immer';

const INITIAL_STATE = {
  video_call_status: '',
  video_call_config: null,
  day_event_related_info: null,
  current_video: null,
  in_live_session_data: null,
  selected_screen: '',
  last_note_saved: '',
  last_time_note_saved: '',
  current_evals: [],
  video_call_used: null,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@video_call/SET_STATUS': {
        draft.video_call_status = action.payload.type;
        break;
      }

      case '@video_call/SET_VIDEO_CALL': {
        draft.video_call_config = action.payload.videoCall;
        break;
      }

      case '@video_call/SET_CURRENT_VIDEO': {
        draft.current_video = action.payload.currentVideo;
        break;
      }

      case '@video_call/SET_IN_LIVE_SESSION_DATA': {
        draft.in_live_session_data = action.payload.in_live_session;
        break;
      }

      case '@video_call/SET_SCREEN_SELECTED': {
        draft.selected_screen = action.payload.screen;
        break;
      }

      case '@video_call/SAVE_NOTE': {
        draft.last_note_saved = action.payload.note;
        break;
      }

      case '@video_call/SAVE_TIME_NOTE': {
        draft.last_time_note_saved = action.payload.last_time_saved_note;
        break;
      }

      case '@video_call/SET_CURRENT_EVALS': {
        draft.current_evals = action.payload.evals;
        break;
      }

      case '@video_call/SET_INFO_DAY_EVENT': {
        draft.day_event_related_info = action.payload.dayEventInfo;
        break;
      }

      case '@video_call/SET_VIDEOCALLS_USED': {
        draft.video_call_used = action.payload.videoCallsUsed;
        break;
      }

      default:
    }
  });
}
