import { takeLatest, all, call, put } from 'redux-saga/effects';
import { notification } from 'antd';

import api from '../../../services/api';

import { setPatientAnamnesis } from './actions';

export function* getPatientAnamnesis({ payload }) {
  try {
    const { patientID } = payload;

    const response = yield call(api.get, `/patients/${patientID}/anamnesis`);

    yield put(setPatientAnamnesis(response.data.anamnesis));
  } catch (err) {
    notification.error({
      message: 'Houve um erro ao buscar dados do anamnese, tente novamente!',
    });
  }
}

// eslint-disable-next-line require-yield
export function* savePatientAnamnesis({ payload }) {
  try {
    const { anamnesis, patientID } = payload;

    yield call(api.put, `/patients/${patientID}/anamnesis`, anamnesis);
  } catch (err) {
    if (err.title !== 'IncompatiblePlanException') {
      notification.error({
        message:
          'Houve um erro ao salvar os dados do anamnese, tente novamente!',
      });
    }
  }
}

// eslint-disable-next-line require-yield
export function* finalSavePatientAnamnesis({ payload }) {
  try {
    const { anamnesis, patientID } = payload;

    yield call(api.put, `/patients/${patientID}/anamnesis`, anamnesis);
    notification.success({
      message: 'Anamnese salva com sucesso!',
    });
  } catch (err) {
    notification.error({
      message: 'Houve um erro ao salvar os dados do anamnese, tente novamente!',
    });
  }
}

export default all([
  takeLatest('@anamnesis/GET_PATIENT_ANAMNESIS', getPatientAnamnesis),
  takeLatest('@anamnesis/SAVE_PATIENT_ANAMNESIS', savePatientAnamnesis),
  takeLatest(
    '@anamnesis/FINAL_SAVE_PATIENT_ANAMNESIS',
    finalSavePatientAnamnesis,
  ),
]);
