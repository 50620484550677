export function getAddressByCep(CEP) {
  return {
    type: '@CEP/GET_ADDRESS_BY_CEP',
    payload: { CEP },
  };
}

export function setAddressByCep(address) {
  return {
    type: '@CEP/SET_ADDRESS_BY_CEP',
    payload: { address },
  };
}
