export const PropsStructureAndBodyFunctionsArrayTranslated = [
  {
    type:
      'estruturas_e_funcoes_corporais_cif.melhorar_amplitude_de_movimento.checked',
    pt: 'Melhorar amplitude de movimento',
  },
  {
    type:
      'estruturas_e_funcoes_corporais_cif.melhorar_coordenacao_motora.checked',
    pt: 'Melhorar Coordenação Motora',
  },
  {
    type: 'estruturas_e_funcoes_corporais_cif.melhorar_flexibilidade.checked',
    pt: 'Melhorar Flexibilidade',
  },
  {
    type:
      'estruturas_e_funcoes_corporais_cif.melhorar_a_forca_muscular.checked',
    pt: 'Melhorar a Força Muscular',
  },
  {
    type: 'estruturas_e_funcoes_corporais_cif.melhorar_a_capacidade_aerobica',
    pt: 'Melhorar a Capacidade Aeróbica',
  },

  {
    type:
      'estruturas_e_funcoes_corporais_cif.melhorar_a_destreza_manual.checked',
    pt: 'Melhorar a Destreza Manual',
  },
  {
    type: 'estruturas_e_funcoes_corporais_cif.melhorar_a_capacidade_vital',
    pt: 'Melhorar a Capacidade Vital',
  },
  {
    type:
      'estruturas_e_funcoes_corporais_cif.melhorar_o_equilibrio_e_o_controle_postural',
    pt: 'Melhorar o Equilíbrio e o Controle Postural',
  },
  {
    type: 'estruturas_e_funcoes_corporais_cif.adequear_tonus_muscular.checked',
    pt: 'Adequar Tônus Muscular',
  },
  {
    type: 'estruturas_e_funcoes_corporais_cif.reduzir_a_fadiga_muscular',
    pt: 'Reduzir a Fadiga Muscular',
  },
  {
    type: 'estruturas_e_funcoes_corporais_cif.reduzir_a_dor.checked',
    pt: 'Reduzir a dor',
  },
  {
    type:
      'estruturas_e_funcoes_corporais_cif.melhorar_a_estabilidade_articular.checked',
    pt: 'Melhorar a Estabilidade Articular',
  },
  {
    type: 'estruturas_e_funcoes_corporais_cif.melhorar_o_esquema_corporal',
    pt: 'Melhorar o Esquema Corporal',
  },
  {
    type: 'estruturas_e_funcoes_corporais_cif.melhorar_a_percepção_corporal',
    pt: 'Estimular a Percepção Corporal',
  },
  {
    type:
      'estruturas_e_funcoes_corporais_cif.estimular_o_controle_muscular.checked',
    pt: 'Estimular o Controle Muscular',
  },
  {
    type:
      'estruturas_e_funcoes_corporais_cif.estimular_simetria_orientacao_postural.checked',
    pt: 'Estimular Simetria / Orientação Postural',
  },
  {
    type: 'estruturas_e_funcoes_corporais_cif.estimular_cognicao',
    pt: 'Estimular Cognição',
  },
  {
    type: 'estruturas_e_funcoes_corporais_cif.outros.checked',
    pt: 'Outros',
  },
];

export const PropsStructureAndBodyFunctionsInputArray = [
  {
    from:
      'estruturas_e_funcoes_corporais_cif.melhorar_amplitude_de_movimento.checked',
    to:
      'estruturas_e_funcoes_corporais_cif.melhorar_amplitude_de_movimento.qual_amplitude',
    label: 'Qual amplitude ?',
  },
  {
    from:
      'estruturas_e_funcoes_corporais_cif.melhorar_amplitude_de_movimento.checked',
    to:
      'estruturas_e_funcoes_corporais_cif.melhorar_amplitude_de_movimento.qual_lado',
    label: 'Qual lado ?',
  },
  {
    from:
      'estruturas_e_funcoes_corporais_cif.melhorar_coordenacao_motora.checked',
    to: 'estruturas_e_funcoes_corporais_cif.melhorar_coordenacao_motora.onde',
    label: 'Onde ?',
  },
  {
    from: 'estruturas_e_funcoes_corporais_cif.melhorar_flexibilidade.checked',
    to:
      'estruturas_e_funcoes_corporais_cif.melhorar_flexibilidade.quais_musculos',
    label: 'Quais músculos?',
  },
  {
    from: 'estruturas_e_funcoes_corporais_cif.melhorar_flexibilidade.checked',
    to: 'estruturas_e_funcoes_corporais_cif.melhorar_flexibilidade.qual_lado',
    label: 'Qual lado?',
  },
  {
    from:
      'estruturas_e_funcoes_corporais_cif.melhorar_a_forca_muscular.checked',
    to:
      'estruturas_e_funcoes_corporais_cif.melhorar_a_forca_muscular.quais_musculos',
    label: 'Quais músculos?',
  },
  {
    from:
      'estruturas_e_funcoes_corporais_cif.melhorar_a_forca_muscular.checked',
    to:
      'estruturas_e_funcoes_corporais_cif.melhorar_a_forca_muscular.qual_lado',
    label: 'Qual lado?',
  },
  {
    from: 'estruturas_e_funcoes_corporais_cif.adequear_tonus_muscular.checked',
    to:
      'estruturas_e_funcoes_corporais_cif.adequear_tonus_muscular.quais_musculos',
    label: 'Quais músculos?',
  },
  {
    from: 'estruturas_e_funcoes_corporais_cif.reduzir_a_dor.checked',
    to: 'estruturas_e_funcoes_corporais_cif.reduzir_a_dor.local',
    label: 'Local?',
  },
  {
    from:
      'estruturas_e_funcoes_corporais_cif.melhorar_a_estabilidade_articular.checked',
    to:
      'estruturas_e_funcoes_corporais_cif.melhorar_a_estabilidade_articular.texto',
    label: '',
  },
  {
    from:
      'estruturas_e_funcoes_corporais_cif.estimular_o_controle_muscular.checked',
    to:
      'estruturas_e_funcoes_corporais_cif.estimular_o_controle_muscular.quais_musculos',
    label: 'Quais músculos?',
  },
  {
    from:
      'estruturas_e_funcoes_corporais_cif.estimular_o_controle_muscular.checked',
    to:
      'estruturas_e_funcoes_corporais_cif.estimular_o_controle_muscular.qual_lado',
    label: 'Qual lado?',
  },
  {
    from:
      'estruturas_e_funcoes_corporais_cif.estimular_simetria_orientacao_postural.checked',
    to:
      'estruturas_e_funcoes_corporais_cif.estimular_simetria_orientacao_postural.segmentos_corporais',
    label: 'Segmentos Corporais',
  },
  {
    from: 'estruturas_e_funcoes_corporais_cif.outros.checked',
    to: 'estruturas_e_funcoes_corporais_cif.outros.qual',
    label: '',
  },
];

export const PropsActivityArrayTranslated = [
  {
    type: 'atividade_cif.melhorar_a_marcha.checked',
    pt: 'Melhorar a Marcha',
  },
  {
    type: 'atividade_cif.melhorar_troca_postural.checked',
    pt: 'Melhorar Troca Postural',
  },
  {
    type: 'atividade_cif.melhorar_alcance.checked',
    pt: 'Melhorar Alcance',
  },
  {
    type: 'atividade_cif.melhorar_preensao.checked',
    pt: 'Melhorar a Preensão',
  },
  {
    type: 'atividade_cif.aumentar_o_uso_do_membro_superior.checked',
    pt: 'Aumentar o Uso do Membro Superior',
  },
  {
    type: 'atividade_cif.estimular_o_ortostatismo.checked',
    pt: 'Estimular o Ortostatismo',
  },
  {
    type:
      'atividade_cif.estimular_a_independencia_para_atividades_diarias.checked',
    pt: 'Estimular a Independência Para Atividades Diárias',
  },
  {
    type: 'atividade_cif.estimular_a_sedestacao_sem_apoio',
    pt: 'Estimular a Sedestação Sem Apoio',
  },
  {
    type: 'atividade_cif.outros.checked',
    pt: 'Outros',
  },
];

export const PropsActivityInputArray = [
  {
    from: 'atividade_cif.melhorar_a_marcha.checked',
    to: 'atividade_cif.melhorar_a_marcha.texto',
    label: '',
  },
  {
    from: 'atividade_cif.melhorar_troca_postural.checked',
    to: 'atividade_cif.melhorar_troca_postural.texto',
    label: '',
  },
  {
    from: 'atividade_cif.melhorar_alcance.checked',
    to: 'atividade_cif.melhorar_alcance.qual_lado',
    label: 'Qual lado?',
  },
  {
    from: 'atividade_cif.melhorar_preensao.checked',
    to: 'atividade_cif.melhorar_preensao.qual_lado',
    label: 'Qual lado?',
  },
  {
    from: 'atividade_cif.aumentar_o_uso_do_membro_superior.checked',
    to: 'atividade_cif.aumentar_o_uso_do_membro_superior.qual_lado',
    label: 'Qual lado?',
  },
  {
    from: 'atividade_cif.estimular_o_ortostatismo.checked',
    to: 'atividade_cif.estimular_o_ortostatismo.quais',
    label: 'Quais?',
  },
  {
    from:
      'atividade_cif.estimular_a_independencia_para_atividades_diarias.checked',
    to: 'atividade_cif.estimular_a_independencia_para_atividades_diarias.qual',
    label: 'Quais?',
  },
  {
    from: 'atividade_cif.outros.checked',
    to: 'atividade_cif.outros.qual',
    label: '',
  },
];

export const PropsImproveGaitArrayTranslated = [
  {
    type: 'atividade_cif.melhorar_a_marcha.velocidade',
    pt: 'Velocidade',
  },
  {
    type: 'atividade_cif.melhorar_a_marcha.cadencia',
    pt: 'Cadência',
  },
  {
    type: 'atividade_cif.melhorar_a_marcha.tamanho_do_passo',
    pt: 'Tamanho do Passo',
  },
  {
    type: 'atividade_cif.melhorar_a_marcha.resistencia_para_marcha',
    pt: 'Resistência Para Marcha',
  },
];

export const PropsImprovePosturalExchangeArrayTranslated = [
  {
    type: 'atividade_cif.melhorar_troca_postural.dd_dl',
    pt: 'DD-DL',
  },
  {
    type: 'atividade_cif.melhorar_troca_postural.dv_dd',
    pt: 'DV-DD',
  },
  {
    type: 'atividade_cif.melhorar_troca_postural.dd_dv',
    pt: 'DD-DV',
  },
  {
    type: 'atividade_cif.melhorar_troca_postural.sentar_levantar',
    pt: 'Sentar-Levantar',
  },
];
