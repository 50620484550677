/* eslint-disable no-console */
/* eslint-disable dot-notation */
import Axios from 'axios';
import { notification } from 'antd';
import fire from './fire';

const auth = Axios.create({
  baseURL: 'https://v2-dot-nbweb-prod.rj.r.appspot.com/',
});

// https://nbweb-dev.rj.r.appspot.com/
// baseURL: 'https://clinic-dot-nbweb-dev.rj.r.appspot.com/',
// baseURL: 'http://192.168.0.100:8080',

let isRefreshing = false;
let failedRequestQueue = [];

auth.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) {
      isRefreshing = true;
      const originalConfig = error.config;

      if (isRefreshing === true) {
        fire.auth().onAuthStateChanged(user => {
          user.getIdToken().then(token => {
            if (token) {
              auth.defaults.headers.Authorization = token;
              failedRequestQueue.forEach(request => request.onSuccess(token));
              failedRequestQueue = [];
              isRefreshing = false;
            }
          });
        });
      }

      return new Promise((resolve, rejects) => {
        failedRequestQueue.push({
          onSuccess: token => {
            originalConfig.headers['Authorization'] = `${token}`;

            resolve(auth(originalConfig));
          },
          onFailure: err => {
            rejects(err);
          },
        });
      });
    }

    if (error.response && error.response.data) {
      if (!isRefreshing) {
        switch (error.response.data.title) {
          /* case 'TestPeriodExpiratedException':
          case 'IncompatiblePlanException':
          case 'ExpiredSubscriptionException':
          case 'UnavailableTeleconsultationException':
            notification.error({
              message: error.response.data.toPrint,
            });
            return Promise.reject(error.response.data);
          case 'CreateCustomerException':
            notification.error({
              message: error.response.data.toPrint,
            });
            return Promise.reject(error.response.data);
          case 'NonexistentPatientException':
            notification.error({
              message: error.response.data.toPrint,
            });
            return Promise.reject(error.response.data);
          case 'NonexistentProtocolException':
            notification.error({
              message: error.response.data.toPrint,
            });
            return Promise.reject(error.response.data); */
          case 'UnavailableTeleconsultationException':
            notification.error({
              message: error.response.data.toPrint,
            });
            return Promise.reject(error.response.data);
          case 'ExpiredSubscriptionException':
            notification.error({
              message: error.response.data.toPrint,
            });
            return Promise.reject(error.response.data);
          case 'TestPeriodExpiratedException':
            notification.error({
              message: error.response.data.toPrint,
            });
            return Promise.reject(error.response.data);
          case 'AlreadyExistsPhoneNumberException':
            notification.error({
              message: error.response.data.message,
            });
            return Promise.reject(error.response.data);

          case 'IncompatiblePlanException':
            notification.error({
              message: error.response.data.toPrint,
            });
            return Promise.reject(error.response.data);

          case 'InvalidActionException':
            if (error.response.data.message !== 'chatId não criado.') {
              notification.error({
                message: `${error.response.data.toPrint}`,
              });
            }
            return Promise.reject(error.response.data);

          case 'UnavailableProfessionalException':
            notification.error({
              message: `${error.response.data.toPrint}`,
            });
            return Promise.reject(error.response.data);

          case 'UserUnauthenticatedException':
            return new Promise(resolve => {
              fire.auth().onAuthStateChanged(user => {
                user.getIdToken().then(token => {
                  if (token) {
                    auth.defaults.headers.Authorization = token;
                  }
                  resolve();
                });
              });
            });

          case 'AlreadyExistsClinicException':
            return notification.error({
              message: 'Já existe uma clínica com este documento.',
            });
          case 'NonlinkedUsersException':
            return notification.error({
              message: `${error.response.data.message}`,
            });

          default:
            return Promise.reject(error.response.data);
        }
      }
    }
    if (error.message === 'Network Error') {
      notification.error({
        message: 'Algo deu errado, tente novamente ou contate o suporte!',
      });
    }
    return Promise.reject(error.message);
  },
);

export default auth;
