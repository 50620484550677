/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-empty */
import { takeLatest, call, all, put, select } from 'redux-saga/effects';
import { notification } from 'antd';
import { getToken, permission } from 'services/fire';
import { PropsProfileEnum } from 'utils/profile';
import { PropsSpecialityEnumTranslated } from 'utils/speciality';
import { getInfoDetailsOnlyId as getInfoDetailsOnlyIdAction } from 'store/modules/clinic/actions';
import api from '../../../services/api';

import {
  setSelf,
  setProfessionalsWithInvites,
  setProfessionalsAdmins,
  setTherapistsWithInvites,
  setPossibleAdmins,
  setInfoDetails,
  setTherapists,
  getProfessionalsAdmins,
  getSelf,
} from './actions';

import { getUser } from '../auth/actions';
import { sendTokenPN } from '../messages/actions';
import { getPatientsList } from '../patient/actions';

export function* getSelfSaga() {
  try {
    const response = yield call(api.get, `/clinic`);
    yield put(setSelf(response.data.clinic));
  } catch (err) {
    notification.error({
      message: 'Houve um erro ao buscar seus dados, tente novamente!',
    });
  }
}

export function* getSilentSelf() {
  try {
    const response = yield call(api.get, `/self`);
    yield put(setSelf(response.data.self));
  } catch (err) {}
}

export function* uploadAvatarClinic({ payload }) {
  try {
    const { file, isShowMessage } = payload;
    const data = new FormData();
    data.append('file', file);
    data.append('action', 'UPLOAD');

    yield call(api.post, `/upload_profile_image_clinic`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    yield put(getUser());

    if (isShowMessage) {
      notification.success({
        message: 'Atualize a página!',
        description:
          'A imagem da clínica foi atualizada e aparecerá no próximo recarregamento de página.',
      });
    }
  } catch (err) {
    notification.error({
      message: 'Houve um erro ao fazer upload da imagem, tente novamente!',
    });
  }
}

export function* removeAvatarClinic() {
  try {
    const data = new FormData();
    data.append('action', 'REMOVE');

    yield call(api.post, `/upload_profile_image_clinic`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    notification.success({
      message: 'Atualize a página!',
      description: 'A imagem de perfil da clínica foi removida.',
    });
    yield put(getUser());
  } catch (err) {
    notification.error({
      message: 'Houve um erro ao fazer upload da imagem, tente novamente!',
    });
  }
}

export function* updateClinic({ payload }) {
  const { clinic } = payload;
  const clinicRedux = state => state.clinic.self;
  const clinicStore = yield select(clinicRedux);
  try {
    yield call(api.put, `/clinic`, {
      clinic: {
        address: {
          street: clinic.street,
          number: clinic.number,
          complement: clinic.complement,
          neighborhood: clinic.neighborhood,
          city: clinic.city,
          state: clinic.state,
          country: 'Brasil',
          postal_code: clinic.postal_code,
        },
        dayInWeek: clinic.daysOfWeek,
        hourEnd: clinic.hourClinic2,
        hourInit: clinic.hourClinic1,
        id: clinicStore.id,
        name: clinic.nameClinic,
        phone_number1: clinic.cellphoneClinic1,
        phone_number2: clinic.cellphoneClinic2,
      },
    });
    notification.success({
      message: 'Atualização de perfil da clínica com sucesso!',
    });
    yield put(getSelf());
  } catch (err) {
    notification.error({
      message:
        'Houve um erro ao fazer atualizar o perfil da clínica, tente novamente!',
    });
  }
}

export function* getProfessionalWithInvitesSagas() {
  try {
    const response = yield call(api.get, `/list_professionals_with_invites`);
    response.data.professionals.map(item => {
      item.key = item.id;
    });
    const { professionals } = response.data;
    yield put(setProfessionalsWithInvites(professionals));
  } catch (err) {
    notification.error({
      message: 'Houve um erro ao buscar seus profissionais, tente novamente!',
    });
  }
}

export function* getTherapistsWithInvitesSagas() {
  try {
    const response = yield call(api.get, `/list_therapist_with_invites`);
    yield put(setTherapistsWithInvites(response.data.professionals));
  } catch (err) {
    notification.error({
      message: 'Houve um erro ao buscar os profissionais, tente novamente!',
    });
  }
}

export function* getTherapistsSagas() {
  try {
    const response = yield call(api.get, `/list_therapist`);
    yield put(setTherapists(response.data.professionals));
  } catch (err) {
    notification.error({
      message: 'Houve um erro ao buscar os profissionais, tente novamente!',
    });
  }
}

export function* getProfessionalAdminsSagas() {
  try {
    const response = yield call(api.get, `/list_professionals_administrator`);
    yield put(setProfessionalsAdmins(response.data.administrators));
  } catch (err) {
    notification.error({
      message: 'Houve um erro ao buscar seus dados, tente novamente!',
    });
  }
}

export function* getPossibleAdmins() {
  try {
    const response = yield call(api.get, `/list_professionals`);
    response.data.professionals.map(item => {
      item.key = item.id;
    });
    const { professionals } = response.data;
    yield put(setPossibleAdmins(professionals));
  } catch (err) {
    notification.error({
      message: 'Houve um erro ao buscar seus dados, tente novamente!',
    });
  }
}

export function* addProfessionalAdminSagas({ payload }) {
  try {
    const { professional } = payload;

    yield call(api.post, `/add_administrator`, {
      administratorId: professional,
    });
    notification.success({
      message: 'Administrador adicionado com sucesso!',
    });
    yield put(getProfessionalsAdmins());
  } catch (err) {
    notification.error({
      message:
        'Houve um erro ao tentar adicionar um administrador, tente novamente!',
    });
  }
}

export function* removeAdminSagas({ payload }) {
  try {
    const { professional } = payload;

    yield call(
      api.delete,
      `/remove_administrator/administrator/${professional}`,
    );
    notification.success({
      message: 'Administrador removido com sucesso!',
    });
    yield put(getProfessionalsAdmins());
  } catch (err) {
    notification.error({
      message:
        'Houve um erro ao tentar remover um administrador, tente novamente!',
    });
  }
}

export function* answerInviteClinic({ payload }) {
  try {
    const {
      answer,
      notificationId,
      inviteId,
      clinicId,
      isNewProfile,
    } = payload;

    yield call(api.put, `/invite_clinic`, {
      clinicId,
      inviteId,
      accepted: answer, // aqui aceita ou recusa o convite
      notificationId,
      isNewProfile,
    });
    yield put(getUser());
    notification.success({
      message: 'Resposta enviada com sucesso!',
    });
  } catch (err) {
    notification.error({
      message:
        'Houve um erro ao tentar adicionar um administrador, tente novamente!',
    });
  }
}

export function* changeActiveProfile({ payload }) {
  try {
    const { profileId, item, history } = payload;

    yield call(api.put, `/activate_profile`, {
      profileId,
    });

    notification.success({
      message: 'Troca de perfil ativo com sucesso!',
    });

    yield put(getUser());

    if (item.profile === PropsProfileEnum.THERAPIST) {
      history.push('/content');
    } else if (item.clinicId === null) {
      history.push('/not_vinculated');
    } else {
      history.push('/patient_list_clinic');
    }
    history.go(0);

    yield put(getPatientsList());

    let token = null;
    if (permission) {
      token = yield getToken();
    }
    yield put(sendTokenPN(token));
  } catch (err) {
    notification.error({
      message: 'Houve um erro ao tentar alternar de conta, tente novamente!',
    });
  }
}

export function* saveProfessionalInfo({ payload }) {
  const professionalRedux = state => state.clinic.infoDetails;
  const professionalStore = yield select(professionalRedux);

  try {
    const { profile, typeProfile } = payload;

    if (typeProfile === PropsProfileEnum.THERAPIST) {
      if (
        profile.specialitiesTranslated.find(
          item => item === PropsSpecialityEnumTranslated.OTHER,
        )
      ) {
        profile.specialitiesTranslated = profile.specialitiesTranslated.filter(
          item => item !== PropsSpecialityEnumTranslated.OTHER,
        );
        profile.specialitiesTranslated.push(profile.otherSpeciality);
      }

      const bodyRequest = {
        professionalInfo: {
          address: {
            city: profile.city,
            complement: profile.complement,
            country: 'Brasil',
            neighborhood: profile.neighborhood,
            number: profile.houseNumber,
            postal_code: profile.postalCode,
            state: profile.state,
            street: profile.street,
          },
          specialities: profile.specialitiesTranslated,
          profession:
            profile?.profession === 'OTHERPROFESSION'
              ? {
                  otherProfessionName:
                    profile?.otherProfession !== ''
                      ? profile?.otherProfession
                      : '',
                  profession: 'OTHERPROFESSION',
                }
              : profile?.profession,

          id: professionalStore.id,
          document: profile.document,
          name: profile.name,
          phone_number: profile.cellphone,
        },
      };
      yield call(api.post, `/update_professional`, bodyRequest);
      yield put(getInfoDetailsOnlyIdAction(professionalStore.id));
    }

    if (typeProfile === PropsProfileEnum.RECEPTIONIST) {
      yield call(api.post, `/update_professional`, {
        professionalInfo: {
          id: professionalStore.id,
          document: profile.document,
          name: profile.name,
        },
      });

      yield put(getInfoDetailsOnlyIdAction(professionalStore.id));
    }

    notification.success({
      message: 'Dados salvos com sucesso!',
    });
  } catch (err) {
    notification.error({
      message:
        'Houve um erro ao tentar salvar dados do paciente, tente novamente!',
    });
  }
}

export function* getInfoDetails({ payload }) {
  try {
    const { typeProfile, patientId } = payload;

    if (typeProfile === 'PATIENT') {
      const response = yield call(api.get, `/patients/${patientId}`);
      yield put(setInfoDetails(response.data.patient, 'PATIENT'));
    }
  } catch (err) {
    notification.error({
      message: 'Houve um erro ao buscar os profissionais, tente novamente!',
    });
  }
}

export function* getInfoDetailsOnlyId({ payload }) {
  try {
    const { id } = payload;

    const response = yield call(api.get, `/get_info/${id}`);
    yield put(
      setInfoDetails(response.data.profile, response.data.profile.type),
    );
  } catch (err) {
    notification.error({
      message: 'Houve um erro ao buscar o usuário, tente novamente!',
    });
  }
}

export default all([
  takeLatest('@clinic/GET_SELF', getSelfSaga),
  takeLatest('@clinic/GET_SILENT_SELF', getSilentSelf),
  takeLatest('@clinic/UPLOAD_AVATAR_CLINIC', uploadAvatarClinic),
  takeLatest('@clinic/REMOVE_AVATAR_CLINIC', removeAvatarClinic),
  takeLatest('@clinic/UPDATE_CLINIC', updateClinic),
  takeLatest(
    '@clinic/GET_PROFESSIONAL_WITH_INVITES',
    getProfessionalWithInvitesSagas,
  ),
  takeLatest(
    '@clinic/GET_THERAPISTS_WITH_INVITES',
    getTherapistsWithInvitesSagas,
  ),
  takeLatest('@clinic/GET_THERAPISTS', getTherapistsSagas),
  takeLatest('@clinic/GET_PROFESSIONAL_ADMINS', getProfessionalAdminsSagas),
  takeLatest('@clinic/GET_POSSIBLE_ADMINS', getPossibleAdmins),
  takeLatest('@clinic/ADD_PROFESSIONAL_ADMIN', addProfessionalAdminSagas),
  takeLatest('@clinic/REMOVE_ADMIN', removeAdminSagas),
  takeLatest('@clinic/CHANGE_ACTIVE_PROFILE', changeActiveProfile),
  takeLatest('@clinic/SAVE_PROFESSIONAL_INFO', saveProfessionalInfo),
  takeLatest('@clinic/ANSWER_INVITE_CLINIC', answerInviteClinic),
  takeLatest('@clinic/GET_INFO_DETAILS', getInfoDetails),
  takeLatest('@clinic/GET_INFO_DETAILS_ONLY_ID', getInfoDetailsOnlyId),
]);
