/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import { Modal } from 'antd';

import * as S from './styles';

export function Post({ title, description, link }) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  function handleCancel() {
    setIsModalVisible(!isModalVisible);
  }

  return (
    <S.Container onClick={() => setIsModalVisible(!isModalVisible)}>
      <h2>{title}</h2>
      <span>{description}</span>
      <Modal
        visible={isModalVisible}
        onCancel={() => handleCancel()}
        style={{ top: 20 }}
        width={1000}
        footer={null}
      >
        <br />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            position: 'relative',
          }}
        >
          <S.BlanckSpace />
          <iframe title={title} src={link} width="100%" height="500px" />
        </div>
      </Modal>
    </S.Container>
  );
}
