/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import { useParams } from 'react-router-dom';

import { getPatientDetails } from 'store/modules/patient/actions';
import {
  getExerciseCatalog,
  getProtocolsCreated,
} from 'store/modules/protocol/actions';

import AppHeader from 'components/Header';
import SiderMenu from 'components/Siders/Professional/Sider';
import { useSelector, useDispatch } from 'react-redux';

import { AddEventAgenda } from './AddEventAgenda';

import 'moment/locale/pt-br';
import './styles.css';
import ProtocolSetup from '../PatientProtocol/components/ProtocolSetup';
import FormContext from './components/ProtocolList/components/Context/FormContext';

function EventAgenda() {
  const { id } = useParams();
  const [screen, setScreen] = useState(<AddEventAgenda />);

  const patientDetails = useSelector(state => state.patient.patientDetails);
  const loadingPatient = useSelector(state => state.patient.loading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPatientDetails(id));
    dispatch(getExerciseCatalog());
    dispatch(getProtocolsCreated(id));
  }, []);

  function setScreenInProtocolList() {
    setScreen(<AddEventAgenda />);
  }

  function changeComponent(input, protocolId) {
    switch (input) {
      case 'default':
        setScreen(<AddEventAgenda />);
        break;
      case 'add_event':
        setScreen(<AddEventAgenda />);
        break;
      case 'edit':
        setScreen(
          <ProtocolSetup
            protocolId={protocolId}
            setScreenInProtocolList={setScreenInProtocolList}
            edit
          />,
        );
        break;
      default:
        break;
    }
  }

  return (
    <Layout>
      <AppHeader />
      <SiderMenu>
        {patientDetails !== null && (
          <FormContext.Provider value={{ changeComponent, patientDetails }}>
            {screen}
          </FormContext.Provider>
        )}
        {loadingPatient === true && patientDetails === null && (
          <div>Carregando ... </div>
        )}
      </SiderMenu>
    </Layout>
  );
}

export default EventAgenda;
