import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import format from 'date-fns/format';
import ptBR from 'date-fns/locale/pt-BR';
import { Divider, Row, Col } from 'antd';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import { stringFormater } from '../../../../../../../../../utils/StringFormater';
import FinishedCheck from '../../../../../../../../../assets/finished-check.svg';
import NotFinishedCheck from '../../../../../../../../../assets/not-finished-check.svg';
import * as S from './styles';

export function ActivityDay({ date, activities, considerations }) {
  const [fActivity, setFActivity] = useState([]);
  const [sActivity, setSActivity] = useState([]);
  const [tActivity, setTActivity] = useState([]);
  const [sessionDate, setSessionDate] = useState('');
  const isBiggerThan = useMediaQuery('(min-width: 770px)');

  useEffect(() => {
    let contArray1 = 2;
    let contArray2 = 1;
    let contArray3 = 0;
    const tempArray1 = [];
    const tempArray2 = [];
    const tempArray3 = []; // MMMM

    const tempDate = format(new Date(date), 'EEEEEEE d MMMM', {
      locale: ptBR,
    }).split(' ');
    const secondPartDate = tempDate[2].charAt(0).toUpperCase();

    setSessionDate(
      `${tempDate[1]} de ${secondPartDate}${tempDate[2].slice(1)}`,
    );

    for (let x = 0; x < activities.length; x += 1) {
      if (contArray1 === 2) {
        tempArray1.push(activities[x]);
        contArray1 = 0;
      } else {
        contArray1 += 1;
      }
      if (contArray2 === 2) {
        tempArray2.push(activities[x]);
        contArray2 = 0;
      } else {
        contArray2 += 1;
      }
      if (contArray3 === 2) {
        tempArray3.push(activities[x]);
        contArray3 = 0;
      } else {
        contArray3 += 1;
      }
    }
    setFActivity([...tempArray1]);
    setSActivity([...tempArray2]);
    setTActivity([...tempArray3]);
  }, [activities]);

  return (
    <>
      <br />
      <S.TitleSession>{sessionDate}</S.TitleSession>
      <br />
      <S.ContainerShowActivityVideos>
        <Row
          gutter={[32, 16]}
          style={{
            display: 'flex',
            width: '100%',
          }}
        >
          {fActivity.map(exercises => (
            <Col className="gutter-row" span={isBiggerThan ? 8 : 24}>
              <S.ElementVideoActVideos
                key={exercises.cid}
                title={exercises.name}
              >
                <ReactPlayer
                  url={exercises.video_url}
                  width="180px"
                  height="120px"
                  light
                  title={exercises.name}
                />
                <S.Subtitles title={exercises.name}>
                  {stringFormater(exercises.name)}
                </S.Subtitles>
                <>
                  {exercises.quality !== null && exercises.quality !== -1 ? (
                    <>
                      <S.ContainerStatusActivity>
                        <img src={FinishedCheck} alt="Finalizada" />
                        <label>Atividade concluída</label>
                      </S.ContainerStatusActivity>
                      <S.ContainerStatusActivity>
                        <label>{`Qualidade do movimento: ${exercises.quality}`}</label>
                      </S.ContainerStatusActivity>
                    </>
                  ) : (
                    <S.ContainerStatusActivity>
                      <img src={NotFinishedCheck} alt="Não finalizada" />
                      <label>Atividade não concluída</label>
                    </S.ContainerStatusActivity>
                  )}
                </>
              </S.ElementVideoActVideos>
            </Col>
          ))}

          {sActivity.map(exercises => (
            <Col className="gutter-row" span={isBiggerThan ? 8 : 24}>
              <S.ElementVideoActVideos
                key={exercises.cid}
                title={exercises.name}
              >
                <ReactPlayer
                  url={exercises.video_url}
                  width="180px"
                  height="120px"
                  light
                  title={exercises.name}
                />
                <S.Subtitles title={exercises.name}>
                  {stringFormater(exercises.name)}
                </S.Subtitles>
                <>
                  {exercises.quality !== null && exercises.quality !== -1 ? (
                    <>
                      <S.ContainerStatusActivity>
                        <img src={FinishedCheck} alt="Finalizada" />
                        <label>Atividade concluída</label>
                      </S.ContainerStatusActivity>
                      <S.ContainerStatusActivity>
                        <label>{`Qualidade do movimento: ${exercises.quality}`}</label>
                      </S.ContainerStatusActivity>
                    </>
                  ) : (
                    <S.ContainerStatusActivity>
                      <img src={NotFinishedCheck} alt="Não finalizada" />
                      <label>Atividade não concluída</label>
                    </S.ContainerStatusActivity>
                  )}
                </>
              </S.ElementVideoActVideos>
            </Col>
          ))}

          {tActivity.map(exercises => (
            <Col className="gutter-row" span={isBiggerThan ? 8 : 24}>
              <S.ElementVideoActVideos
                key={exercises.cid}
                title={exercises.name}
              >
                <ReactPlayer
                  url={exercises.video_url}
                  width="180px"
                  height="120px"
                  light
                  title={exercises.name}
                />
                <S.Subtitles title={exercises.name}>
                  {stringFormater(exercises.name)}
                </S.Subtitles>
                <>
                  {exercises.quality !== null && exercises.quality !== -1 ? (
                    <>
                      <S.ContainerStatusActivity>
                        <img src={FinishedCheck} alt="Finalizada" />
                        <label>Atividade concluída</label>
                      </S.ContainerStatusActivity>
                      <S.ContainerStatusActivity>
                        <label>{`Qualidade do movimento: ${exercises.quality}`}</label>
                      </S.ContainerStatusActivity>
                    </>
                  ) : (
                    <S.ContainerStatusActivity>
                      <img src={NotFinishedCheck} alt="Não finalizada" />
                      <label>Atividade não concluída</label>
                    </S.ContainerStatusActivity>
                  )}
                </>
              </S.ElementVideoActVideos>
            </Col>
          ))}
        </Row>
      </S.ContainerShowActivityVideos>

      {!isBiggerThan && (
        <>
          <br />
          <br />
        </>
      )}
      <S.Subtitles>Anotações: </S.Subtitles>
      <S.NormalLabel>{considerations}</S.NormalLabel>
      <Divider />
    </>
  );
}
