/* eslint-disable consistent-return */
import { PropsProfileEnum } from 'utils/profile';

export const showHeaderVinculated = user => {
  const profileActive = user?.profiles?.find(
    item => item.id === user.active_profile_id,
  );

  if (profileActive?.profile === PropsProfileEnum.THERAPIST) {
    return true;
  }

  if (profileActive?.profile !== PropsProfileEnum.THERAPIST) {
    if (profileActive?.clinicId !== null) {
      return true;
    }
    return false;
  }

  return true;
};
