/* eslint-disable no-unused-vars */
import produce from 'immer';

const INITIAL_STATE = {
  self: null,
  professionalsAndInvites: null,
  therapistsAndInvites: null,
  loadingProfessionals: false,
  administrators: [],
  therapists: null,
  possibleAdmins: [],
  infoDetails: null,
  typeInfoDetails: null,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@clinic/SET_SELF': {
        draft.self = action.payload.prof_self;
        break;
      }

      case '@clinic/GET_PROFESSIONAL_WITH_INVITES': {
        draft.loadingProfessionals = true;
        break;
      }

      case '@clinic/SET_PROFESSIONAL_WITH_INVITES': {
        draft.professionalsAndInvites = action.payload.professionals;
        draft.loadingProfessionals = false;
        break;
      }

      case '@clinic/SET_THERAPISTS_WITH_INVITES': {
        draft.therapistsAndInvites = action.payload.therapists;
        break;
      }

      case '@clinic/SET_PROFESSIONAL_ADMINS': {
        draft.administrators = action.payload.administrators;
        break;
      }

      case '@clinic/SET_POSSIBLE_ADMINS': {
        draft.possibleAdmins = action.payload.possibleAdmins;
        break;
      }

      case '@clinic/SET_INFO_DETAILS': {
        draft.infoDetails = action.payload.profile;
        draft.typeInfoDetails = action.payload.typeProfile;
        break;
      }

      case '@clinic/SET_THERAPISTS': {
        draft.therapists = action.payload.therapists;
        break;
      }

      default:
    }
  });
}
