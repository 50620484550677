/* eslint-disable no-unused-vars */
import { Radio } from 'antd';

import styles from './styles.module.css';

function CheckBoxInput({ info, setInfo, keyName, change }) {
  return (
    <div className={styles.questionFormAddProtocol}>
      <Radio.Group
        onChange={e => {
          if (e.target.value === keyName[0]) {
            setInfo({
              domPract: { enable: true, value: 'home-practice' },
              telePract: { enable: false, value: 'live-sessions' },
            });
          } else {
            setInfo({
              domPract: { enable: false, value: 'home-practice' },
              telePract: { enable: true, value: 'live-sessions' },
            });
          }
        }}
        value={info}
        style={{
          marginTop: '4px',
          display: !change && 'flex',
          flexDirection: !change && 'column',
        }}
      >
        <Radio value={keyName[0]}>Práticas Domicilares</Radio>
        <Radio value={keyName[1]}>Sessões via Telechamada</Radio>
      </Radio.Group>
    </div>
  );
}

export default CheckBoxInput;
