import styled from 'styled-components';

export const AntdStyles = {
  marginTop: {
    marginTop: '4px',
  },

  ButtonRecoverPassword: {
    background: 'var(--darkCyan) 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    opacity: 1,
    width: '12rem',
    marginTop: '1rem',
  },

  Final: {
    display: 'flex',
    alignSelf: 'flex-end',
  },

  iconInfo: {
    color: 'var(--darkCyan)',
    marginLeft: 10,
  },
};

export const TitlePassword = styled.h5`
  color: var(--battleshipGrey);
  text-align: left;
  font: normal normal normal 16px/22px Nunito Sans;
  letter-spacing: 0px;
  margin-top: 1rem;
`;

export const DivBlockTextAndInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: ${props => (props.secondChange ? '30%' : '100%')};
`;

export const LabelText = styled.div`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal bold 14px/19px Nunito Sans;
  letter-spacing: 0px;
  display: flex;
  flex-direction: column;
`;

export const Center = styled.div`
  display: flex;
  align-items: center;
`;

export const ErrorMessage = styled.label`
  color: #c12;
  font-size: 12px;
  margin-top: 2px;
  font-weight: 100;
`;
