import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 150px;
  width: 300px;
`;

export const AntdStyles = {
  iconClose: { marginRight: 8, width: 15, marginBottom: 4 },
};
