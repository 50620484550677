/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
import React, { useCallback, useState, useEffect } from 'react';
import { Input, Button, Modal, Checkbox, Row, Col } from 'antd';
import {
  PropsSpecialityEnum,
  PropsSpecialityTranslated,
  PropsSpecialityArray,
  SpecialityTranslated,
} from 'utils/speciality';
import closeIcon from 'assets/close.svg';

import * as S from './styles';
import './styles.css';

function ModalSpeciality({
  visible,
  setVisible,
  propsFormik,
  specialitiesUser,
}) {
  return (
    <Modal
      title="Editar sua especialidade"
      visible={visible}
      onCancel={() => setVisible(false)}
      closeIcon={
        <img src={closeIcon} alt="close" style={S.AntdStyles.iconClose} />
      }
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={() => setVisible(false)}
          style={{
            backgroundColor: '#118E88',
            borderRadius: '5px',
            opacity: 1,
          }}
        >
          Ok
        </Button>,
      ]}
    >
      <S.DescInput>Especialidade</S.DescInput>
      <Checkbox.Group
        style={{ width: '100%' }}
        defaultValue={propsFormik.values.speciality.map(s => s.speciality)}
        onChange={checkedValues => {
          const newSpecs = checkedValues.map(
            spec => (spec = { speciality: spec }),
          );
          propsFormik.setFieldValue('speciality', newSpecs);
        }}
      >
        <S.DivMarginTop>
          <Row>
            {PropsSpecialityArray.map(
              item =>
                item && (
                  <Col span={12}>
                    <Checkbox value={`${item}`}>
                      {SpecialityTranslated(PropsSpecialityEnum[item])}
                    </Checkbox>
                  </Col>
                ),
            )}
          </Row>
        </S.DivMarginTop>
      </Checkbox.Group>
      {propsFormik.touched.speciality && propsFormik.errors.speciality ? (
        <S.ErrorMessage>{propsFormik.errors.speciality}</S.ErrorMessage>
      ) : null}

      {propsFormik.values.speciality.findIndex(
        item => item.speciality === 'OTHER',
      ) >= 0 ? (
        <Input
          placeholder="Qual?"
          defaultValue={propsFormik.values.otherSpeciality}
          onChange={event =>
            propsFormik.setFieldValue('otherSpeciality', event.target.value)
          }
          style={{ marginTop: '10px' }}
        />
      ) : null}
      {propsFormik.values.speciality.findIndex(
        item => item === SpecialityTranslated(PropsSpecialityEnum.OTHER),
      ) >= 0 && propsFormik.values.otherSpeciality === '' ? (
        <S.ErrorMessage>É obrigatório</S.ErrorMessage>
      ) : null}
    </Modal>
  );
}

export default ModalSpeciality;
