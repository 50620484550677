/* eslint-disable no-unused-vars */
import { Layout, Col, Radio, Row, Select, DatePicker, Divider } from 'antd';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import api from 'services/api';
import AppHeader from 'components/Header';
import localePTDate from 'antd/lib/locale/pt_BR';
import moment from 'moment';
import 'moment/locale/pt-br';
import { refreshToken } from 'store/modules/auth/actions';
import {
  getConsultsData,
  getMonthComparative,
} from 'store/modules/clinicagenda/actions';
import { getTherapists } from 'store/modules/clinic/actions';
import { addYears, subMonths } from 'date-fns';
import { PropsSpecialityTranslatedArray } from 'utils/speciality';
import Plot from 'react-plotly.js';
import * as S from './styles';
import './styles.css';
import { ComparativeMensalArrayExibition, ConsultsArray } from './utils';
import { CircleGraph } from './components/circleGraphs';

export default function DataConsults() {
  const dispatch = useDispatch();
  const secondChange = useMediaQuery('(min-width: 860px)');
  const thirdChange = useMediaQuery('(min-width: 640px)');
  const [withProfessional, setWithProfessional] = useState('Por Profissional');
  const [date1, setDate1] = useState(
    moment(subMonths(new Date(), 1), 'YYYY/MM/DD'),
  );
  const [date2, setDate2] = useState(moment(new Date(), 'YYYY/MM/DD'));
  const [year, setYear] = useState(moment(new Date(), 'YYYY'));

  useEffect(() => {
    dispatch(getTherapists());
  }, [dispatch]);

  const location = useLocation();
  const search = location?.state?.search;

  // Quando o token expira
  api.interceptors.response.use(
    response => response,
    error => {
      if (error.message === 'Request failed with status code 401') {
        dispatch(refreshToken());
      }
      Promise.reject(error);
    },
  );

  const consultationData = useSelector(
    state => state.clinicagenda.consultsData,
  );

  const professionalList = useSelector(state => state.clinic.therapists);
  const [professional, setProfessional] = useState({
    professionalName: 'Exibir Todos',
    id: 'ALL',
  });
  const [newProfessionalList, setNewProfessionalList] = useState([
    { professionalName: 'Exibir Todos', id: 'ALL' },
  ]);
  const [speciality, setSpeciality] = useState({
    type: PropsSpecialityTranslatedArray[0].type,
    pt: PropsSpecialityTranslatedArray[0].pt,
  });
  const [comparativeMensalExibit, setComparativeMensalExibit] = useState({
    type: ComparativeMensalArrayExibition[0].type,
    pt: ComparativeMensalArrayExibition[0].pt,
  });

  const comparativeMensal = useSelector(
    state => state.clinicagenda?.comparativeMonth,
  );

  const [comparativeMensalArray, setComparativeMensalArray] = useState([
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ]);

  useEffect(() => {
    if (search) {
      setProfessional(search);
    }
  }, [comparativeMensal]);

  useEffect(() => {
    let isByProfissional = false;

    if (withProfessional === 'Por Profissional') {
      isByProfissional = true;
    } else {
      isByProfissional = false;
    }

    if (isByProfissional) {
      dispatch(
        getConsultsData(
          typeof professional === 'object' ? professional.id : professional,
          isByProfissional,
          date1.format('YY/MM/DD'),
          date2.format('YY/MM/DD'),
        ),
      );
    } else {
      dispatch(
        getConsultsData(
          typeof speciality === 'object' ? speciality.type : speciality,
          isByProfissional,
          date1.format('YY/MM/DD'),
          date2.format('YY/MM/DD'),
        ),
      );
    }
  }, [date1, date2, withProfessional, professional, speciality]);

  useEffect(() => {
    dispatch(getMonthComparative(year.format('YYYY')));
  }, [year]);

  useEffect(() => {
    if (comparativeMensal !== null) {
      setComparativeMensalArray(
        comparativeMensal[comparativeMensalExibit.type],
      );
    }
  }, [comparativeMensal, comparativeMensalExibit]);

  useEffect(() => {
    if (professionalList !== null && professionalList.length !== 0) {
      let newList = [];
      newList = [{ professionalName: 'Exibir Todos', id: 'ALL' }].concat(
        professionalList,
      );
      setNewProfessionalList(newList);
    }
  }, [professionalList]);

  return (
    <Layout>
      <AppHeader />
      <S.Container mediaFirst={secondChange} mediaThird={thirdChange}>
        <Col style={{ width: '100%' }}>
          <S.TitlePage>Dados das Consultas</S.TitlePage>
          <Row>
            <S.TitleSiders style={{ width: '100%' }}>Filtrar</S.TitleSiders>
            <Radio.Group
              value={withProfessional}
              onChange={e => {
                setWithProfessional(e.target.value);
                // changeTextFilter(
                //   e.target.value === 'Por Profissional',
                //   professional.id,
                // );
              }}
              style={{ marginTop: '0.3rem' }}
            >
              <Row style={{ justifyContent: 'center' }}>
                <Row
                  style={{
                    marginTop: '0.5rem',
                    alignItems: 'start',
                    justifyContent: 'start',
                  }}
                >
                  <Radio
                    value="Por Profissional"
                    style={
                      withProfessional === 'Por Profissional'
                        ? S.AntdStyles.RadioButtonActiveAble
                        : S.AntdStyles.RadioButtonActiveDisable
                    }
                  >
                    Por Profissional
                  </Radio>
                </Row>
                <Row
                  style={{
                    marginTop: '0.5rem',
                    alignItems: 'start',
                    justifyContent: 'start',
                  }}
                >
                  <Radio
                    value="Por Área"
                    style={
                      withProfessional === 'Por Área'
                        ? S.AntdStyles.RadioButtonArchivedAble
                        : S.AntdStyles.RadioButtonArchivedDisable
                    }
                  >
                    Por Área
                  </Radio>
                </Row>
              </Row>
            </Radio.Group>
          </Row>
          <Row>
            <Col style={S.AntdStyles.marginTop}>
              {withProfessional === 'Por Profissional' ? (
                <>
                  <S.TitleSiders>Profissional</S.TitleSiders>
                  <Select
                    showSearch
                    style={{
                      width: '16rem',
                      maxHeight: 50,
                      marginTop: '0.5rem',
                      marginLeft: '3px',
                    }}
                    options={newProfessionalList.map(
                      item =>
                        item && {
                          label: item.professionalName,
                          value: item.id,
                        },
                    )}
                    placeholder=""
                    filterOption={(inputValue, option) =>
                      option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                    value={professional.professionalName}
                    onChange={prof => {
                      setProfessional(prof);
                    }}
                  />
                </>
              ) : (
                <>
                  <S.TitleSiders>Área</S.TitleSiders>
                  <Select
                    showSearch
                    style={{
                      width: '16rem',
                      maxHeight: 50,
                      marginTop: '0.5rem',
                      marginLeft: '3px',
                    }}
                    options={PropsSpecialityTranslatedArray.map(
                      item =>
                        item && {
                          value: item.type,
                          label: item.pt,
                        },
                    )}
                    placeholder=""
                    filterOption={(inputValue, option) =>
                      option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                    value={speciality.pt}
                    onChange={spec => {
                      setSpeciality(spec);
                    }}
                  />
                </>
              )}
            </Col>
            <Col
              style={{
                ...S.AntdStyles.marginTop,
                marginTop: '0.5rem',
                marginLeft: secondChange ? '1rem' : 0,
                width: secondChange ? '' : '100%',
                marginBottom: '1.5rem',
              }}
            >
              <S.TitleSiders>Período</S.TitleSiders>
              <Row style={{ alignItems: 'center', flex: 1 }}>
                <DatePicker
                  style={{
                    ...S.AntdStyles.marginTop,
                    marginLeft: '3px',
                  }}
                  onChange={(_, dateString) => {
                    setDate1(moment(dateString, 'DD-MM-YYYY'));
                  }}
                  allowClear={false}
                  value={date1}
                  locale={localePTDate}
                  format="DD/MM/YYYY"
                />
                <S.HifenText>-</S.HifenText>
                <DatePicker
                  style={S.AntdStyles.marginTop}
                  onChange={(_, dateString) => {
                    setDate2(moment(dateString, 'DD-MM-YYYY'));
                  }}
                  allowClear={false}
                  value={date2}
                  locale={localePTDate}
                  format="DD/MM/YYYY"
                />
              </Row>
            </Col>
          </Row>
          <Row style={{ justifyContent: 'space-between' }}>
            {consultationData !== null &&
              ConsultsArray.map((item, index) => (
                <CircleGraph
                  percentValue={consultationData[item.type]?.percentage}
                  circleValue={consultationData[item.type]?.value}
                  title={ConsultsArray[index].title}
                  subtitleInit={ConsultsArray[index].subtitleInit}
                  subtitleEnd={ConsultsArray[index].subtitleEnd}
                  color={ConsultsArray[index].color}
                />
              ))}
          </Row>
          <Divider />
          <S.TitlePage>Comparativo Mensal</S.TitlePage>
          <Col>
            <Row>
              <Col
                style={{
                  ...S.AntdStyles.marginTop,
                  width: secondChange ? '' : '100%',
                  zIndex: 1,
                }}
              >
                <S.TitleSiders>Ano</S.TitleSiders>
                <DatePicker
                  style={{
                    ...S.AntdStyles.marginTop,
                    marginLeft: secondChange ? '3px' : 0,
                    width: '16rem',
                  }}
                  onChange={(_, dateString) => {
                    setYear(moment(addYears(new Date(dateString), 1), 'YYYY'));
                  }}
                  allowClear={false}
                  value={year}
                  locale={localePTDate}
                  format="YYYY"
                  picker="year"
                />
              </Col>
              <Col
                style={{
                  ...S.AntdStyles.marginTop,
                  marginTop: '0.5rem',
                  marginLeft: secondChange ? '1rem' : 0,
                  marginBottom: '1.5rem',
                  zIndex: 1,
                }}
              >
                <S.TitleSiders>Exibindo</S.TitleSiders>
                <Select
                  style={{
                    maxHeight: 50,
                    marginTop: '0.5rem',
                    marginLeft: secondChange ? '3px' : 0,
                    width: '16rem',
                  }}
                  options={ComparativeMensalArrayExibition.map(
                    item =>
                      item && {
                        value: item.type,
                        label: item.pt,
                      },
                  )}
                  placeholder=""
                  filterOption={(inputValue, option) =>
                    option.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                  value={comparativeMensalExibit.pt}
                  onChange={comparative => {
                    setComparativeMensalExibit(comparative);
                  }}
                />
              </Col>
            </Row>
            <Plot
              style={{
                marginTop: -60,
                marginLeft: '-2.5rem',
                zIndex: -1,
              }}
              data={[
                {
                  x: [
                    'JAN',
                    'FEV',
                    'MAR',
                    'ABR',
                    'MAI',
                    'JUN',
                    'JUL',
                    'AGO',
                    'SET',
                    'OUT',
                    'NOV',
                    'DEZ',
                  ],
                  y: comparativeMensalArray,
                  type: 'bar',
                  mode: 'lines',
                  marker: {
                    color: '#55BFB8',
                  },
                  width: [
                    0.15,
                    0.15,
                    0.15,
                    0.15,
                    0.15,
                    0.15,
                    0.15,
                    0.15,
                    0.15,
                    0.15,
                    0.15,
                    0.15,
                  ],
                },
              ]}
              layout={{
                height: 400,
                paper_bgcolor: '#00000000',
                plot_bgcolor: '#00000000',
                yaxis: {
                  zeroline: false,
                },
              }}
              config={{ responsive: true, displayModeBar: false }}
            />
          </Col>
        </Col>
      </S.Container>
    </Layout>
  );
}
