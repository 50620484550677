/* eslint-disable consistent-return */
import { Modal, Radio } from 'antd';
import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  PropsProfileTranslated,
  PropsProfileEnum,
  PropsProfileArray,
} from '../../../../utils/profile';
import { RegisterClinicModal } from '../Registers/ClinicRegister';
import { RegisterProfessionalModal } from '../Registers/ProfessionalRegister';
import * as S from './styles';

export function RegisterModal({ isModalVisible, handleCancel }) {
  const [checkProfile, setCheckProfile] = useState('');

  const profileTranslated = useCallback(profile => {
    const result = PropsProfileTranslated.find(p => p.type === profile).pt;
    return result;
  }, []);
  const user = useSelector(state => state.auth.user);

  const handleCheckedProfile = () => {
    if (checkProfile === '') {
      return 'Selecione um tipo de conta para adicionar';
    }
    if (checkProfile === 'RECEPTIONIST') {
      return 'Adicionar uma conta de Recepcionista';
    }
    if (checkProfile === 'THERAPIST') {
      return 'Adicionar uma conta de Profissional de Saúde';
    }
    if (checkProfile === 'CLINIC') {
      return 'Adicionar uma conta de Clinica';
    }
  };

  return (
    <Modal
      title={handleCheckedProfile()}
      visible={isModalVisible}
      onCancel={() => handleCancel()}
      style={{ top: 20 }}
      width={850}
      footer={null}
    >
      {user !== null && checkProfile === '' ? (
        <Radio.Group
          onChange={e => setCheckProfile(e.target.value)}
          value={checkProfile}
          style={{ marginTop: '1rem' }}
        >
          {PropsProfileArray.map(
            itemProfile =>
              itemProfile !== 'PATIENT' &&
              itemProfile !== 'RECEPTIONIST' &&
              (user.profile === 'CLINIC' || user.profile === 'RECEPTIONIST'
                ? itemProfile !== user.profile
                : itemProfile) && (
                <Radio
                  style={S.AntdStyles.radioStyle}
                  value={PropsProfileEnum[itemProfile]}
                >
                  {profileTranslated(PropsProfileEnum[itemProfile])}
                </Radio>
              ),
          )}
        </Radio.Group>
      ) : null}
      {checkProfile === 'THERAPIST' ? (
        <RegisterProfessionalModal handleBack={() => setCheckProfile('')} />
      ) : null}
      {checkProfile === 'CLINIC' ? (
        <RegisterClinicModal handleBack={() => setCheckProfile('')} />
      ) : null}
    </Modal>
  );
}
