import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Button } from 'antd';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateEvaluation } from 'store/modules/evaluations/actions';
import TextAndRadioGroup from '../../components/TextAndRadioGroup';

import * as S from './styles';

function EscalaFuncionalEscleroseLateral({ title, current_eval }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const qts = current_eval?.content?.questions;

  const Questions = [
    '1. Fala',
    '2. Salivação',
    '3. Deglutição',
    '4. Escrita',
    '5a. Manipulação de alimentos e utensílios (indivíduos sem Gastrostomia)',
    '5b. Indivíduos com gastrostomia',
    '6. Vestuário e Higiene',
    '7. Virar na cama e ajustar a roupa de cama',
    '8. Andar',
    '9. Subir escadas',
    '10. Dispnéia',
    '11. Ortopnéia',
    '12. Insuficiência Respiratória',
  ];

  const [formValues, setFormValues] = useState(
    current_eval?.content?.questions.length > 0
      ? Questions.map((item, index) => ({
          index: index + 1,
          text: item,
          value: current_eval.content.questions[index].value,
        }))
      : Questions.map((item, index) => ({
          index: index + 1,
          text: item,
          value: '',
        })),
  );

  function updateValues(isFinal) {
    const tempFormValue = {
      id: current_eval?.id,
      isFinished: isFinal,
      name: title,
      professionalId: current_eval?.professionalId,
      result: null,
      content: {
        questions: formValues,
      },
    };
    dispatch(updateEvaluation(tempFormValue, id));
    if (isFinal) {
      setFormValues(
        Questions.map((item, index) => ({
          index: index + 1,
          text: item,
          value: '',
        })),
      );
    }
  }

  useEffect(() => {
    const valuesFormValues = Object.values(formValues);
    if (valuesFormValues.find(item => item.value !== '')) {
      updateValues(false);
    }
  }, [formValues]);

  const forms = [
    { label: 'Processo de fala normal (4 pontos)', value: 4 },
    { label: 'Distúrbio da fala detectável (3 pontos)', value: 3 },
    { label: 'Compreensível com repetição (2 pontos)', value: 2 },
    { label: 'Fala combinada com comunicação não vocal (1 ponto)', value: 1 },
    { label: 'Perda de utilidade da fala (0 pontos)', value: 0 },
  ];

  const forms2 = [
    { label: 'Normal (4 pontos)', value: 4 },
    {
      label:
        'Insignificante, mas notável o excesso de saliva na boca podendo ter babas noturnas (3 pontos)',
      value: 3,
    },
    {
      label: 'Excesso de saliva moderada, podendo ter mínimas babas (2 pontos)',
      value: 2,
    },
    {
      label: 'Excesso acentuado de saliva com alguma baba (1 ponto)',
      value: 1,
    },
    {
      label:
        'Baba acentuada exigindo constante uso de babador ou lenço para boca (0 pontos)',
      value: 0,
    },
  ];

  const forms3 = [
    { label: 'Normal (4 pontos)', value: 4 },
    {
      label: 'Problemas precoces para comer, engasgos ocasionais (3 pontos)',
      value: 3,
    },
    {
      label: 'Alterações na consistência da dieta (2 pontos)',
      value: 2,
    },
    {
      label: 'Necessidade de suplemento alimentar pastoso (1 ponto)',
      value: 1,
    },
    {
      label: 'Nada pela boca, exclusivamente parenteral ou enteral (0 pontos)',
      value: 0,
    },
  ];

  const forms4 = [
    { label: 'Normal (4 pontos)', value: 4 },
    {
      label:
        'Lentificada e descuidada, todas as palavras são legíveis (3 pontos)',
      value: 3,
    },
    {
      label: 'Nem todas as palavras são legíveis (2 pontos)',
      value: 2,
    },
    {
      label: 'Capaz de segurar a caneta, mas incapaz de escrever (1 ponto)',
      value: 1,
    },
    {
      label: 'Não é capaz de segurar a caneta (0 pontos)',
      value: 0,
    },
  ];

  const forms5 = [
    { label: 'Normal (4 pontos)', value: 4 },
    {
      label:
        'Um pouco lento e desajeitado, mas não necessita de ajuda (3 pontos)',
      value: 3,
    },
    {
      label:
        'Pode cortar o alimento embora lento e desajeitado; necessita de alguma ajuda (2 pontos)',
      value: 2,
    },
    {
      label:
        'Alimentos cortados por outra pessoa, mas alimenta-se sozinho lentamente (1 ponto)',
      value: 1,
    },
    {
      label: 'Precisa ser alimentado (0 pontos)',
      value: 0,
    },
  ];

  const forms6 = [
    { label: 'Normal (4 pontos)', value: 4 },
    {
      label:
        'Independente de auto-cuidado com diminuição do rendimento do esforço (3 pontos)',
      value: 3,
    },
    {
      label: 'Assistência intermitente ou substituição dos métodos (2 pontos)',
      value: 2,
    },
    {
      label: 'Necessita do cuidadoso para auto-cuidado (1 ponto)',
      value: 1,
    },
    {
      label: 'Dependência total (0 pontos)',
      value: 0,
    },
  ];

  const forms7 = [
    { label: 'Normal (4 pontos)', value: 4 },
    {
      label: 'Um pouco lento ou desajeitado, não necessita de ajuda (3 pontos)',
      value: 3,
    },
    {
      label:
        'Pode virar sozinho ou ajustar o lençol com grande dificuldade (2 pontos)',
      value: 2,
    },
    {
      label:
        'Tem iniciativa, mas não consegue virar ou ajustar o lençol (1 ponto)',
      value: 1,
    },
    {
      label: 'Incapaz (0 pontos)',
      value: 0,
    },
  ];

  const forms8 = [
    { label: 'Normal (4 pontos)', value: 4 },
    {
      label: 'Deambulação precoce dificultada (3 pontos)',
      value: 3,
    },
    {
      label: 'Passeios com assistência (2 pontos)',
      value: 2,
    },
    {
      label: 'Movimento funcional não deambulatório somente (1 ponto)',
      value: 1,
    },
    {
      label: 'Não apresenta movimentação voluntária das pernas (0 pontos)',
      value: 0,
    },
  ];

  const forms9 = [
    { label: 'Normal (4 pontos)', value: 4 },
    {
      label: 'Lentidão (3 pontos)',
      value: 3,
    },
    {
      label: 'Ligeiro desequilíbrio ou fadiga (2 pontos)',
      value: 2,
    },
    {
      label: 'Necessita de assistência (1 ponto)',
      value: 1,
    },
    {
      label: 'Não realiza (0 pontos)',
      value: 0,
    },
  ];

  const forms10 = [
    { label: 'Nenhuma (4 pontos)', value: 4 },
    {
      label: 'Ocorre quando caminha (3 pontos)',
      value: 3,
    },
    {
      label: 'Ocorre quando come, toma banho e se veste (2 pontos)',
      value: 2,
    },
    {
      label: 'Ocorre no repouso, ou sentado ou deitado (1 ponto)',
      value: 1,
    },
    {
      label:
        'Dificuldade significante, considerando suporte mecânico (0 pontos)',
      value: 0,
    },
  ];

  const forms11 = [
    { label: 'Nenhuma (4 pontos)', value: 4 },
    {
      label:
        'Alguma dificuldade de dormir, falta de ar, não se utiliza rotineiramente mais que 2 travesseiros (3 pontos)',
      value: 3,
    },
    {
      label:
        'Necessidade de travesseiros extras para dormir (mais que 2) (2 pontos)',
      value: 2,
    },
    {
      label: 'Pode dormir somente sentado (1 ponto)',
      value: 1,
    },
    {
      label: 'Não consegue dormir (0 pontos)',
      value: 0,
    },
  ];

  const forms12 = [
    { label: 'Nenhuma (4 pontos)', value: 4 },
    {
      label: 'Uso intermitente de BIPAP (3 pontos)',
      value: 3,
    },
    {
      label: 'Uso contínuo do BIPAP à noite (2 pontos)',
      value: 2,
    },
    {
      label: 'Uso contínuo do BIPAP durante o dia e a noite (1 ponto)',
      value: 1,
    },
    {
      label: 'Ventilação Mecânica Invasiva por intubação (0 pontos)',
      value: 0,
    },
  ];

  function returnForms(indexQuestion) {
    if (indexQuestion === 2) return forms2;
    if (indexQuestion === 3) return forms3;
    if (indexQuestion === 4) return forms4;
    if (indexQuestion === 5) return forms5;
    if (indexQuestion === 7) return forms6;
    if (indexQuestion === 8) return forms7;
    if (indexQuestion === 9) return forms8;
    if (indexQuestion === 10) return forms9;
    if (indexQuestion === 11) return forms10;
    if (indexQuestion === 12) return forms11;
    if (indexQuestion === 13) return forms12;
    return forms;
  }

  return (
    <Formik
      initialValues={{
        Answer1: qts[0] !== undefined ? qts[0].value : '',
        Answer2: qts[1] !== undefined ? qts[1].value : '',
        Answer3: qts[2] !== undefined ? qts[2].value : '',
        Answer4: qts[3] !== undefined ? qts[3].value : '',
        Answer5: qts[4] !== undefined ? qts[4].value : '',
        Answer6: qts[5] !== undefined ? qts[5].value : '',
        Answer7: qts[6] !== undefined ? qts[6].value : '',
        Answer8: qts[7] !== undefined ? qts[7].value : '',
        Answer9: qts[8] !== undefined ? qts[8].value : '',
        Answer10: qts[9] !== undefined ? qts[9].value : '',
        Answer11: qts[10] !== undefined ? qts[10].value : '',
        Answer12: qts[11] !== undefined ? qts[11].value : '',
        Answer13: qts[12] !== undefined ? qts[12].value : '',
      }}
      onSubmit={(values, actions) => {
        // eslint-disable-next-line prefer-const
        let valuesSubmit = {
          name: title,
          content: {
            questions: [],
          },
        };

        Questions.forEach((item, index) => {
          valuesSubmit.content.questions.push({
            index: index + 1,
            text: item,
            value: values[`Answer${index + 1}`],
          });
        });

        actions.resetForm({
          values: {
            Answer1: '',
            Answer2: '',
            Answer3: '',
            Answer4: '',
            Answer5: '',
            Answer6: '',
            Answer7: '',
            Answer8: '',
            Answer9: '',
            Answer10: '',
            Answer11: '',
            Answer12: '',
            Answer13: '',
          },
        });

        updateValues(true);
      }}
    >
      {props => (
        <>
          {Questions.map(
            (item, index) =>
              item && (
                <TextAndRadioGroup
                  text={item}
                  ArrayValue={returnForms(index + 1)}
                  setAnswer={props.setFieldValue}
                  keyAnswer={`Answer${index + 1}`}
                  direction="vertical"
                  setFormValues={setFormValues}
                  formValues={formValues}
                  index={index}
                  currentValue={props.values[`Answer${index + 1}`]}
                  propsFormik={props}
                />
              ),
          )}

          <Button
            type="primary"
            htmlType="submit"
            onClick={props.handleSubmit}
            style={S.AntdStyles.ButtonSave}
          >
            Salvar
          </Button>
        </>
      )}
    </Formik>
  );
}

export default EscalaFuncionalEscleroseLateral;
