/* eslint-disable no-unused-vars */
import produce from 'immer';

const INITIAL_STATE = {
  self: null,
  sendMsgModal: false,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@professional/SET_SELF': {
        draft.self = action.payload.prof_self;
        break;
      }
      case '@professional/SEND_MSG_MODAL': {
        draft.sendMsgModal = action.payload.type;
        break;
      }
      default:
    }
  });
}
