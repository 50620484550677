import { Layout, Typography, Avatar, List } from 'antd';

import AppHeader from 'components/Header';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import {
  differenceInDays,
  getDate,
  getHours,
  getMinutes,
  getMonth,
} from 'date-fns';
import { useEffect } from 'react';
import { readAllNotifications } from 'store/modules/messages/actions';
import { nameHandler } from 'utils/NameHandler';
import * as S from './styles';

function Notifications() {
  const notifications = useSelector(state => state.messages.notifications);
  const { Paragraph, Text } = Typography;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(readAllNotifications());
  }, []);

  const handleData = data => {
    if (
      getMinutes(new Date()) - getMinutes(new Date(data)) < 5 &&
      getHours(new Date()) === getHours(new Date(data)) &&
      getDate(new Date()) === getDate(new Date(data)) &&
      getMonth(new Date()) === getMonth(new Date(data))
    ) {
      return 'Agora';
    }
    if (
      getHours(new Date()) === getHours(new Date(data)) &&
      getDate(new Date()) === getDate(new Date(data)) &&
      getMonth(new Date()) === getMonth(new Date(data))
    ) {
      return `Há ${
        getMinutes(new Date()) - getMinutes(new Date(data))
      } minutos`;
    }
    if (
      getHours(new Date()) !== getHours(new Date(data)) &&
      getDate(new Date()) === getDate(new Date(data)) &&
      getMonth(new Date()) === getMonth(new Date(data))
    ) {
      return `Há ${getHours(new Date()) - getHours(new Date(data))} horas`;
    }
    return `Há ${differenceInDays(new Date(), new Date(data))} dias`;
  };

  return (
    <Layout>
      <AppHeader />
      <InfiniteScroll
        useWindow={false}
        initialLoad={false}
        style={{ padding: '80px 5px' }}
        loadMore={() => null}
      >
        <S.NotificationDiv>
          <S.NotificationText>Notificações</S.NotificationText>
        </S.NotificationDiv>
        <List
          itemLayout="vertical"
          size="large"
          dataSource={notifications}
          renderItem={item => (
            <List.Item
              key={item.content}
              style={{
                backgroundColor: '#FFF',
                height: item.markAsRead === true ? 85 : 100,
                cursor: 'pointer',
                borderRadius: 2,
                marginBottom: 2,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <List.Item.Meta
                avatar={
                  item.imageUrl === null ? (
                    <Avatar
                      size={40}
                      style={{
                        ...S.AntdStyles.AvatarUser,
                      }}
                    >
                      {nameHandler(item.involvedName)}
                    </Avatar>
                  ) : (
                    <Avatar
                      src={item.imageUrl}
                      style={{
                        height: 40,
                        width: 40,
                        marginLeft: -10,
                        alignSelf: 'center',
                        marginTop: item.markAsRead === false ? 0 : -10,
                      }}
                    />
                  )
                }
                description={
                  <div>
                    <Typography>
                      <Paragraph style={S.AntdStyles.paragraphText}>
                        {item.content}{' '}
                        <Text strong style={S.AntdStyles.insideParagraphText}>
                          {handleData(item.createdAt)}
                        </Text>
                      </Paragraph>
                    </Typography>
                  </div>
                }
              />
              {item.markAsRead === false ? (
                <div style={S.AntdStyles.notifContainer}>
                  <h1 style={S.AntdStyles.notifText}>Nova</h1>
                </div>
              ) : (
                <></>
              )}
            </List.Item>
          )}
        />
      </InfiniteScroll>
    </Layout>
  );
}

export default Notifications;
