import styled from 'styled-components';

export const AntdStyles = {
  ButtonAddToProtocol: {
    background: 'var(--darkCyan) 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    border: 'none',
    opacity: 1,
    width: '12rem',
    marginTop: '1rem',
  },
};

export const ContainerShowActivityVideos = styled.div`
  display: flex;
  width: 95%;
  max-width: 780px;
  min-width: 650px;
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
  justify-content: space-around;

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
    border: none;
  }

  ::-webkit-scrollbar {
    width: 4.5px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    height: 120px;
    outline: 1.3px solid var(--battleshipGrey);
    border-radius: 10px;
    background-color: var(--battleshipGrey);
  }
`;

export const ListExerciseActVideos = styled.ul`
  display: flex;
  flex-direction: column;
  width: 30%;
  padding: 0px;
`;

export const ElementVideoActVideos = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 250px;
  min-width: 200px;
  font-size: 14px;
  min-height: 257px;
  color: grey;
  margin-right: 10%;
  position: relative;
  background-color: var(--white);
  margin-top: 2%;
  justify-content: center;
  padding: 3%;
  margin: 10px;
  box-shadow: 4px 4px #f5f5f5;
  border-radius: 4px;

  a {
    font-size: 14px;
    color: black;
  }

  @media (min-width: 770px) {
    margin-left: 0;
  }

  @media (max-width: 770px) {
    flex-direction: column;
  }
`;

export const ElementInfoActVideos = styled.span`
  display: flex;
  width: 80%;
  margin-top: 4px;
  font-weight: bold;
  min-height: 50px;
  max-height: 50px;
`;
