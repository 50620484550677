import React, { useContext, useEffect, useState } from 'react';
import { Empty } from 'antd';
import Plot from 'react-plotly.js';
import {
  newBarData,
  newDash,
  newComparisonFirstBarHorizontal,
  newComparisonSecondBarHorizontal,
  layoutHorizontalBarChart,
  responsiveLayout,
  responsiveLayoutTicks,
  responsiveLayoutFall,
  verticalBarLayout,
} from '../../../../../../../utils/graphsCfg';

import FormContext from '../../../Context/FormContext';
import * as S from './styles';

export function ByPeriod() {
  const { monitoringData } = useContext(FormContext);

  const [barLevalPainBegin, setBarLevelPainBegin] = useState([]);
  const [barLevalPainNow, setBarLevelPainNow] = useState([]);
  const [barDataLPGraph, setBarDataLPGraph] = useState([]);
  const [layoutLevelPain, setLayouLevelPain] = useState(verticalBarLayout);

  useEffect(() => {
    const monit = monitoringData.inliveSessionStatistics;

    const secondCompGrid = [];
    secondCompGrid.push(newDash(0.1));
    secondCompGrid.push(newDash(2));
    secondCompGrid.push(newDash(4));
    secondCompGrid.push(newDash(6));
    secondCompGrid.push(newDash(8));
    secondCompGrid.push(newDash(10));

    // PAIN LEVEL

    const pngInfo = returnAllInfo(monit, 'qualityHistory');
    setBarLevelPainBegin([...pngInfo.beginBar]);
    setBarLevelPainNow([...pngInfo.todayBar]);
    setBarDataLPGraph([...secondCompGrid, ...pngInfo.dataBar]);
    setLayouLevelPain(pngInfo.layout);
  }, [monitoringData]);

  function returnAllInfo(monit, obj) {
    const lvlPain = monit[obj];
    const lpData = [];
    const tempLPDatas = [];
    const tempLPPositions = [];
    let bBar = 0;
    let lBar = 0;

    let cntLP = 0;
    const tmpArLP = [];
    for (let x = lvlPain.length - 1; x >= 0; x -= 1) {
      tmpArLP.push(lvlPain[x].AverageQuality);
      // vai receber várias vezes mas vai ser sempre o mesmo valor
      bBar = monit.firstQuality.AverageQuality;
      lBar = monit.lastQuality.AverageQuality;
      const tmpDt = lvlPain[x].date.split('-');

      tempLPDatas.push(`${tmpDt[2]}/${tmpDt[1]}`); // /${tmpDt[0][2]}${tmpDt[0][3]}
      tempLPPositions.push((cntLP + 1) * 16);
      if (cntLP === 12) break;
      cntLP += 1;
    }
    for (let x = 0; x < tmpArLP.length; x += 1) {
      lpData.push(newBarData((x + 1) * 16, tmpArLP[x]));
    }

    let layout = null;

    if (obj === 'fallingHistory') {
      layout = responsiveLayoutFall(tempLPPositions, tempLPDatas);
    } else if (obj === 'frequencyHistory') {
      layout = responsiveLayout(tempLPPositions, tempLPDatas);
    } else {
      layout = responsiveLayoutTicks(tempLPPositions, tempLPDatas);
    }

    const firstBarLP = newComparisonFirstBarHorizontal(bBar, 'Início');
    const secondBarLP = newComparisonSecondBarHorizontal(10 - bBar, 'Início');
    const firstBarLPNow = newComparisonFirstBarHorizontal(lBar, 'Atualmente');
    const secondBarLPNow = newComparisonSecondBarHorizontal(
      10 - lBar,
      'Atualmente',
    );

    const finalDataLP = [firstBarLP, secondBarLP];

    const finalDataLPNow = [firstBarLPNow, secondBarLPNow];

    const finalObject = {
      beginBar: [...finalDataLP],
      todayBar: [...finalDataLPNow],
      dataBar: [...lpData],
      layout,
    };

    return finalObject;
  }

  return (
    <>
      <br />
      <S.TitleSession>Qualidade de Execução dos Movimentos</S.TitleSession>
      <br />

      {monitoringData.inliveSessionStatistics?.frequencyHistory?.length > 0 ? (
        <>
          <S.ContainerRotatedGraphic>
            <label>Início</label>
            <Plot
              data={barLevalPainBegin}
              layout={layoutHorizontalBarChart}
              config={{ displayModeBar: false }}
            />
          </S.ContainerRotatedGraphic>

          <S.ContainerRotatedGraphic>
            <label>Atualmente</label>
            <Plot
              data={barLevalPainNow}
              layout={layoutHorizontalBarChart}
              config={{ displayModeBar: false }}
            />
          </S.ContainerRotatedGraphic>
          <br />

          <Plot
            data={barDataLPGraph}
            layout={layoutLevelPain}
            config={{ displayModeBar: false }}
          />
          <br />
        </>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: 200,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <label>Não houveram sessões de telechamadas finalizadas.</label>
            <Empty />
          </div>
        </>
      )}
    </>
  );
}
