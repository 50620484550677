/* eslint-disable no-unused-vars */
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from 'antd';
import 'moment/locale/pt-br';

import { useRef } from 'react';
import closeIcon from 'assets/close.svg';

import * as S from './styles';
import './styles.css';

export function DeleteAdminModal({ isModalVisible, handleCancel, handleOk }) {
  const firstChange = useMediaQuery('(min-width: 770px)');
  const dispatch = useDispatch();
  const formikRef = useRef();

  return (
    <Modal
      visible={isModalVisible}
      onOk={() => handleOk()}
      onCancel={() => handleCancel()}
      style={{ top: '30%' }}
      width={350}
      closeIcon={
        <img src={closeIcon} alt="close" style={S.AntdStyles.iconClose} />
      }
      footer={[
        <Button
          type="primary"
          onClick={() => handleCancel()}
          style={{
            width: '116px',
            borderRadius: '5px',
            backgroundColor: 'transparent',
            borderColor: '#118E88',
            color: '#118E88',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          Cancelar
        </Button>,
        <Button
          type="primary"
          onClick={() => handleOk()}
          style={{
            width: '174px',
            borderRadius: '5px',
            backgroundColor: '#118E88',
            borderColor: '#118E88',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          Excluir Administrador
        </Button>,
      ]}
    >
      <S.ContainerModal>
        <S.TitlePage>
          Você tem certeza de que deseja excluir o administrador?
        </S.TitlePage>
      </S.ContainerModal>
    </Modal>
  );
}
