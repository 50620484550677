/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { useParams } from 'react-router-dom';
import { getPatientAnamnesis } from 'store/modules/anamnesis/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import AppHeader from 'components/Header';
import SiderMenu from 'components/Siders/Professional/Sider';
import { getPatientDetails } from 'store/modules/patient/actions';
import FormAnamnesis from './components/FormAnamnesis';

function Anamnesis({ calledByVideoCall = false }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const patientAnamnesis = useSelector(state => state.anamnesis.anamnesis);
  const loadingAnamnesis = useSelector(state => state.anamnesis.loading);
  const firstChange = useMediaQuery('(min-width: 900px)');

  useEffect(() => {
    dispatch(getPatientDetails(id));
  }, []);

  useEffect(() => {
    dispatch(getPatientAnamnesis(id));
  }, []);

  return (
    <Layout>
      {!calledByVideoCall && <AppHeader />}
      <SiderMenu calledByVideoCall={calledByVideoCall}>
        <div style={{ marginLeft: firstChange ? '2rem' : '4rem' }}>
          {loadingAnamnesis === false && patientAnamnesis !== null && (
            <FormAnamnesis />
          )}
          {(loadingAnamnesis === true || patientAnamnesis === null) && (
            <div>Carregando ... </div>
          )}
        </div>
        <br />
        <br />
        <br />
        <br />
      </SiderMenu>
    </Layout>
  );
}

export default Anamnesis;
