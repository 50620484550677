/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

export const Container = styled.div`
  padding: ${props =>
    props.mediaFirst
      ? `6rem 7rem`
      : props.mediaThird
      ? `6rem 3rem`
      : `6rem 0.5rem`};
  flex-direction: ${props => (props.mediaFirst ? 'row' : 'column')};
  display: flex;
`;
