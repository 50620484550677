export const eventStatusList = [
  {
    label: 'PENDING',
    value: 'Sessão pendente',
  },
  {
    label: 'EVENT_REALIZED',
    value: 'Sessão realizada',
  },
  {
    label: 'CANCELED_BY_PROFESSIONAL',
    value: 'Cancelada pelo profissional',
  },
  {
    label: 'CANCELED_BY_PATIENT',
    value: 'Cancelada pelo paciente',
  },
  {
    label: 'PATIENT_ABSENCE',
    value: 'Ausência do paciente',
  },
];
