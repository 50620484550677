import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

export const ContainerPatientPlusVideoCalls = styled.div`
  display: flex;
  width: 100%;
  height: 300px;
  overflow-y: scroll;
  flex-direction: column;
  margin-top: 10px;
  align-items: flex-end;
`;

export const ContainerPatient = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-top: 1rem;
  margin-right: 1rem;

  label {
    opacity: 0.6;
  }
`;

export const ContainerTotal = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  height: 2rem;
  width: 85%;
  justify-content: space-between;
`;

export const AntdStyles = {
  ButtonClose: {
    width: '6rem',
    borderRadius: '5px',
    backgroundColor: 'transparent',
    borderColor: '#118E88',
    color: '#118E88',
  },
  iconClose: { marginRight: 8, width: 15, marginBottom: 4 },
};
