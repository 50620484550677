/* eslint-disable no-unused-vars */
import produce from 'immer';

const INITIAL_STATE = {
  patientExerciseCatalog: null,
  addedActivitiesToProtocol: [],
  actvtSelected: null,
  protocolsCreated: [],
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@protocol/SET_ADDED_ACTIVITIES_TO_PROTOCOL': {
        draft.addedActivitiesToProtocol = action.payload.addedActToProtocol;
        break;
      }

      case '@protocol/SET_ACTIVITY_SELECTED': {
        draft.actvtSelected = action.payload.activitySelected;
        break;
      }

      case '@protocol/SET_EXERCISE_CATALOG': {
        draft.patientExerciseCatalog = action.payload.exerciseCatalog;
        break;
      }

      case '@protocol/SET_PROTOCOLS_CREATED': {
        draft.protocolsCreated = action.payload.protocols;
        break;
      }

      default:
    }
  });
}
