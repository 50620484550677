/* eslint-disable no-unused-vars */
import React, { useEffect, useState, memo } from 'react';
import { Button, Modal, Checkbox, Col, Row } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import * as S from './styles';
import './styles.css';

function ModalFilter({
  showModal,
  setShowModal,
  optionsFilterApply,
  setOptionsFilterApply,
}) {
  const optionsCIF = [
    'Estruturas e funções do corpo',
    'Atividade',
    'Participação',
  ];

  const optionsBodyPart = ['Membro superior', 'Membro inferior', 'Coluna'];

  const optionsSystem = ['Motor', 'Sensorial', 'Cognitivo'];

  const optionsUpshot = [
    'Dor',
    'Equilíbrio',
    'Atividades de vida diária',
    'Força muscular',
    'Tônus muscular',
    'Marcha',
    'Cognição',
    'Condicionamento muscular',
    'Condicionamento cardiorrespiratório',
  ];

  const optionsPopulation = [
    'Esclerose Lateral amiotrófica',
    'Parkinson',
    'AVC',
    'Neuropatias periféricas',
    'Idoso',
  ];

  const [optionsFilterChosen, setOptionsFilterChosen] = useState(
    optionsFilterApply,
  );

  function onChange(event) {
    if (event.target.checked) {
      setOptionsFilterChosen(prevState => [...prevState, event.target.value]);
    } else {
      setOptionsFilterChosen(prevState =>
        prevState.filter(item => item !== event.target.value),
      );
    }
  }

  return (
    <Modal
      title="Filtrar Avaliações"
      visible={showModal}
      onOk={() => setShowModal(false)}
      onCancel={() => setShowModal(false)}
      width={430}
      footer={[
        <Button
          type="primary"
          style={S.AntdStyles.buttonApply}
          onClick={() => {
            setOptionsFilterApply(optionsFilterChosen);
            setShowModal(false);
          }}
        >
          Aplicar
        </Button>,
        <Button
          key="primary"
          type="default"
          style={S.AntdStyles.buttonClear}
          icon={<DeleteOutlined style={{ marginRight: '4px' }} />}
          onClick={() => {
            setOptionsFilterChosen([]);
            setOptionsFilterApply([]);
            setShowModal(false);
          }}
        >
          <strong>Limpar Filtros</strong>
        </Button>,
      ]}
    >
      <S.ContainerFilters>
        <Row gutter={[32, 16]}>
          <Col span={12}>
            <span>- CIF</span>

            <S.DivColCheckbox>
              {optionsCIF.map(
                item =>
                  item && (
                    <Checkbox
                      style={S.AntdStyles.checkbox}
                      value={item}
                      onChange={onChange}
                      defaultChecked={optionsFilterApply.find(
                        filterOption => filterOption === item,
                      )}
                    >
                      {item}
                    </Checkbox>
                  ),
              )}
            </S.DivColCheckbox>
          </Col>
          <Col span={12}>
            <span>- Partes do corpo</span>
            <S.DivColCheckbox>
              {optionsBodyPart.map(
                item =>
                  item && (
                    <Checkbox
                      style={S.AntdStyles.checkbox}
                      value={item}
                      onChange={onChange}
                      defaultChecked={optionsFilterApply.find(
                        filterOption => filterOption === item,
                      )}
                    >
                      {item}
                    </Checkbox>
                  ),
              )}
            </S.DivColCheckbox>
          </Col>

          <Col span={12}>
            <span>- Desfechos</span>
            <S.DivColCheckbox>
              {optionsUpshot.map(
                item =>
                  item && (
                    <Checkbox
                      style={S.AntdStyles.checkbox}
                      value={item}
                      onChange={onChange}
                      defaultChecked={optionsFilterApply.find(
                        filterOption => filterOption === item,
                      )}
                    >
                      {item}
                    </Checkbox>
                  ),
              )}
            </S.DivColCheckbox>
          </Col>
          <Col span={12}>
            <span>- População</span>
            <S.DivColCheckbox>
              {optionsPopulation.map(
                item =>
                  item && (
                    <Checkbox
                      style={S.AntdStyles.checkbox}
                      value={item}
                      onChange={onChange}
                      defaultChecked={optionsFilterApply.find(
                        filterOption => filterOption === item,
                      )}
                    >
                      {item}
                    </Checkbox>
                  ),
              )}
            </S.DivColCheckbox>
          </Col>
          <Col span={24}>
            <span>- Sistemas</span>
            <S.DivColCheckbox>
              {optionsSystem.map(
                item =>
                  item && (
                    <Checkbox
                      style={S.AntdStyles.checkbox}
                      value={item}
                      onChange={onChange}
                      defaultChecked={optionsFilterApply.find(
                        filterOption => filterOption === item,
                      )}
                    >
                      {item}
                    </Checkbox>
                  ),
              )}
            </S.DivColCheckbox>
          </Col>
        </Row>
      </S.ContainerFilters>
    </Modal>
  );
}

export default memo(ModalFilter);
