export const blogContent = [
  {
    id: 0,
    title: 'Como adicionar pacientes?',
    description:
      'Neste tutorial iremos aprender como cadastrar pacientes no Monitore.',
    link:
      'https://tutorialmonitore.wordpress.com/2022/05/04/como-adicionar-pacientes/',
  },
  {
    id: 1,
    title: 'Como adicionar perfil de Clínica? (Opcional)',
    description:
      'Neste tutorial iremos aprender como adicionar um novo perfil do tipo Clínica a sua conta.',
    link:
      'https://tutorialmonitore.wordpress.com/2022/05/04/como-adicionar-perfil-de-clinica/',
  },
  {
    id: 2,
    title: 'Como adicionar Profissional a Clínica? (Opcional)',
    description:
      'Tutorial destinado a explicar como é feita a adição de Profissional por usuários do tipo Clínica e Recepcionista.',
    link:
      'https://tutorialmonitore.wordpress.com/2022/05/10/como-adicionar-profissional-a-clinica/',
  },
  {
    id: 3,
    title: 'Como assinar um plano no Monitore?',
    description:
      'Este tutorial explica o passo-a-passo para assinatura de um plano no Monitore.',
    link:
      'https://tutorialmonitore.wordpress.com/2022/05/10/como-assinar-um-plano-no-monitore/',
  },
  {
    id: 4,
    title: 'Como acessar e utilizar o aplicativo do Monitore',
    description:
      'Neste tutorial é explicado como o paciente consegue acesso ao aplicativo do Monitore e apresenta as principais funcionalidades do aplicativo.',
    link:
      'https://tutorialmonitore.wordpress.com/2022/05/11/como-acessar-e-utilizar-o-aplicativo-do-monitore/',
  },
];
