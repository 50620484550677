import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  & + div {
    margin-top: 30px;
  }

  h2,
  span {
    cursor: pointer;
  }
`;

export const BlanckSpace = styled.div`
  width: 100%;
  height: 100px;
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  background-color: white;
  margin: 2px 2px 15px 2px;
`;
