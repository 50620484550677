/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { TitleAgendaEnum } from 'utils/titleAgendaConsults';

export const ContainerTitleCard = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const TitlePage = styled.h5`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal normal 20px/27px Nunito Sans;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

export const TitleSession = styled.label`
  opacity: 1;
  color: var(--deepKoamaru);
  text-align: start;
  font: normal normal bold 14px/19px Nunito Sans;
  letter-spacing: 0px;
  background: var(--lavender) 0% 0% no-repeat padding-box;
  border-left: solid var(--deepKoamaru) 2px;
  border-radius: 0 1px 1px 0;
  padding: 3px 8px 3px 5px;
  height: 25px;
`;

export const EventAgenda = styled.label`
  color: var(--davysGrey);
  text-align: start;
  font: normal normal normal 15px/19px Nunito Sans;
  padding-left: 4px;
  text-transform: capitalize;
  cursor: pointer;
  font-size: ${props => (props.descCalendarChange ? '15px' : '12px')};
`;

export const AntdStyles = {
  marginTop: {
    marginTop: '4px',
  },
  iconClose: { marginRight: 8, width: 15, marginBottom: 4 },

  ButtonAddEvent: {
    background: 'var(--darkCyan) 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    opacity: 1,
    width: '12rem',
    font: ' normal normal bold 14px/19px Nunito Sans',
  },

  RadioGroup: { marginLeft: '2rem', marginTop: '0.7rem', zIndex: 0 },

  ButtonLeft: {
    textAlign: 'center',
    borderColor: 'var(--battleshipGrey)',
    borderWidth: '1px',
    backgroundColor: '#f7f7f7',
    fontWeight: 'bold',
    color: 'var(--battleshipGrey)',
    borderRadius: '4px 0px 0px 4px',
  },

  ButtonRight: {
    textAlign: 'center',
    borderColor: 'var(--battleshipGrey)',
    borderWidth: '1px',
    backgroundColor: '#f7f7f7',
    fontWeight: 'bold',
    color: 'var(--battleshipGrey)',
    borderRadius: '0px 4px 4px 0px',
  },
};

export const LabelDate = styled.label`
  text-align: left;
  font: normal normal bold 14px/19px Nunito Sans;
  letter-spacing: 0px;
  opacity: 1;
  color: var(--davysGrey);
`;

export const CardActivityAgenda = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 0px 15px #60607710;
  border-left: ${props =>
    props.titleCard === TitleAgendaEnum.DOMICILIAR
      ? 'solid #79E5FF 7px'
      : props.titleCard === TitleAgendaEnum.PRESENCIAL
      ? 'solid #FFC465 7px'
      : props.titleCard === TitleAgendaEnum.TELECHAMADA
      ? 'solid #FFA8E4 7px'
      : 'solid #FFC465 7px'};
  border-radius: 4px;
  opacity: 1;
  height: 120px;
  width: 100%;
  padding: 10px;
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow: auto;

  &:last-child {
    margin-bottom: 3rem;
  }
`;

export const NoActivityAgenda = styled.div`
  background-color: transparent;
  border-radius: 4px;
  opacity: 1;
  height: 120px;
  width: 100%;
  margin: 1rem 0;
`;

export const NoActivityText = styled.label`
  color: var(--unnamed-color-575757);
  text-align: left;
  font: normal normal normal 14px/21px Nunito Sans;
  letter-spacing: 0px;
  color: #575757;
  opacity: 1;
`;

export const TitleCard = styled.label`
  color: var(--unnamed-color-575757);
  text-align: left;
  font: normal normal bold 16px/21px Nunito Sans;
  letter-spacing: 0px;
  color: #575757;
  opacity: 1;
  text-transform: capitalize;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const DescCard = styled.label`
  font: normal normal normal 14px/19px Nunito Sans;
  color: #575757;
  margin-left: 4px;
`;

export const DivRowCard = styled.div`
  display: flex;
`;

export const MinImage = styled.div`
  min-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImgCard = styled.img`
  padding-bottom: 3px;
`;

export const DivContainerEvent = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 4px;
  cursor: pointer;
  align-items: center;
`;

export const DivColorEvent = styled.div`
  width: 5px;
  height: 25px;
  border-radius: 10px;
  cursor: pointer;
`;

export const DivContainer = styled.div`
  margin-left: ${props => (props.firstChange ? '2rem' : '4rem')};
`;

export const DivHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;
`;

export const ImgRadioButton = styled.img`
  width: 8px;
  height: 16px;
`;

export const DivSubTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DivCalendar = styled.div`
  margin-top: 1rem;
  margin-bottom: 3rem;
`;
