import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  height: 100vh;
  background: transparent;

  text-align: left;
  letter-spacing: 0px;
  opacity: 1;

  strong {
    font: normal normal bold 14px/16px Nunito Sans;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding-left: 1.8rem;

    &.btn-back-to-preview {
      color: var(--darkCyan);
      cursor: pointer;
    }

    &.regular-strong {
      color: var(--davysGrey);
    }
  }

  a {
    color: var(--philippineSilver);
    visibility: hidden;
  }

  span {
    padding-left: 1.8rem;
    font: normal normal normal 14px/16px Nunito Sans;

    &.regular-span {
      visibility: visible;
    }

    &.skip-exercise {
      visibility: visible;
      color: var(--darkCyan);
      font-weight: bold;
      padding: 0;
    }

    &.finish-exercise {
      visibility: visible;
      font-weight: bold;
      color: white;
      padding: 0;
    }

    &.answer-saved {
      visibility: visible;
      padding-left: 3px;
    }
  }

  button {
    width: 60px;
    margin-left: 1.8rem;
  }
`;

export const ContainerSteps = styled.div`
  width: 300px;
  max-height: 30px;
`;

export const ContainerPlayer = styled.div`
  width: 390px;
  height: 200px;
  margin-left: 1.8rem;
`;

export const Title = styled.div`
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  color: var(--davysGrey);
  font: normal normal bold 16px/19px Nunito Sans;
`;

export const ContainerSelectionItem = styled.ul`
  display: flex;
  width: 90%;
  height: 30px;
  list-style-type: none;
  margin-top: 0.5rem;
  align-items: center;
  margin-left: 1.8rem;

  li {
    display: flex;
    flex-direction: column;
    min-width: 30px;
    min-height: 30px;
    align-items: center;
    font: normal normal normal 14px/16px Nunito Sans;

    & + li {
      margin-left: 1rem;
    }
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  width: 350px;
`;

export const ContainerFinished = styled.div`
  display: flex;
  width: 200px;
  align-items: center;
  justify-content: flex-start;
  opacity: 0.5;
  font: normal normal normal 14px/16px Nunito Sans;
`;

export const EmptyItem = styled.div`
  background: var(--reflection);
  width: 20px;
  height: 20px;
  border: 1px solid var(--battleshipGrey);
  cursor: pointer;
  border-radius: 50%;
  margin-bottom: 4px;
`;

export const FilledItem = styled.div`
  background: var(--darkCyan);
  width: 20px;
  height: 20px;
  border: 1px solid var(--battleshipGrey);
  cursor: pointer;
  border-radius: 50%;
  margin-bottom: 4px;
`;

export const AntdStyles = {
  Step: {
    margin: '0',
    width: '40%',
  },
  ButtonEvaluateExercise: {
    background: 'var(--darkCyan) 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    opacity: 1,
    width: '10rem',
    marginTop: '1rem',
    marginBottom: '2rem',
    fontWeight: 'bold',
    fontColor: 'white',
  },
  ButtonFinishPractice: {
    background: 'var(--darkCyan) 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    opacity: 1,
    width: '12rem',
    marginTop: '1rem',
    marginBottom: '2rem',
    fontWeight: 'bold',
    fontColor: 'white',
  },
  ButtonSkipExercise: {
    border: '1px solid var(--darkCyan)',
    background: 'transparent',
    borderRadius: '4px',
    opacity: 1,
    width: '11rem',
    marginTop: '1rem',
    marginBottom: '2rem',
    fontWeight: 'bold',
    fontColor: 'var(--darkCyan)',
  },
};
