/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Avatar, Upload, notification, Popconfirm } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { EditOutlined, CloseCircleOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { uploadAvatar, removeAvatar } from 'store/modules/professional/actions';
import { nameHandler } from 'utils/NameHandler';
import { useHistory } from 'react-router-dom';
import * as S from './styles';

function PhotoAndClinic({ secondChange = true }) {
  const user = useSelector(state => state.auth.user);
  const history = useHistory();

  const [hoverPhoto, setHoverPhoto] = useState(false);
  const dispatch = useDispatch();

  const onPreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const onUpload = async file => {
    dispatch(uploadAvatar(file.file));
  };

  // eslint-disable-next-line consistent-return
  function beforeUpload(file) {
    const isTypeImg =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/jpg';
    if (!isTypeImg) {
      notification.error({
        message: 'Você só pode fazer upload de foto JPG/PNJ/JPEG',
      });
    }
    const isLt1M = file.size / 1024 / 1024 < 0.5;
    if (!isLt1M) {
      notification.error({
        message: 'Sua foto não pode ser maior que 500 KB.',
      });
    }
    return isTypeImg && isLt1M;
  }

  function confirmRemoveAvatar() {
    dispatch(removeAvatar());
  }

  return (
    <S.DivSider
      style={{ width: '83.5%', marginTop: secondChange ? '0rem' : '6rem' }}
    >
      <S.TitleMyAccount>Minha Conta</S.TitleMyAccount>
      <S.DivPhotos>
        <ImgCrop rotate>
          <Upload
            showUploadList={false}
            onPreview={onPreview}
            maxCount={1}
            customRequest={e => {
              onUpload(e);
            }}
            beforeUpload={beforeUpload}
          >
            {hoverPhoto === false ? (
              user.photo === null ? (
                <Avatar
                  size={120}
                  style={S.AntdStyles.AvatarUser}
                  onMouseEnter={() => setHoverPhoto(true)}
                >
                  {user.photo === null && nameHandler(user.name)}
                </Avatar>
              ) : (
                <Avatar
                  src={user.photo}
                  size={120}
                  style={S.AntdStyles.AvatarUser}
                  onMouseEnter={() => setHoverPhoto(true)}
                />
              )
            ) : (
              <Avatar
                size={120}
                style={{ ...S.AntdStyles.AvatarUser, fontSize: 30 }}
                onMouseLeave={() => setHoverPhoto(false)}
                icon={<EditOutlined />}
              />
            )}
          </Upload>
        </ImgCrop>

        {user.photo !== null && (
          <Popconfirm
            title="Você tem certeza que deseja remover sua foto?"
            onConfirm={() => confirmRemoveAvatar()}
            onCancel={() => {}}
            okText="Sim"
            cancelText="Não"
          >
            <Avatar
              size={25}
              style={S.AntdStyles.removePhoto}
              icon={
                <CloseCircleOutlined
                  style={{ color: '#ff6666', fontSize: 23 }}
                />
              }
            />
          </Popconfirm>
        )}

        {/* <Avatar size={38} style={S.AntdStyles.PhotoClinic}>
          Clinica
            </Avatar> */}
      </S.DivPhotos>

      <S.InfoAccount>
        Para alterar informações sobre o seu plano como formas de pagamento e
        quantidade de videochamadas, acesse a página{' '}
        <S.MyPlan onClick={() => history.push('/subscription')}>
          Meu Plano
        </S.MyPlan>
        .
      </S.InfoAccount>
    </S.DivSider>
  );
}

export default PhotoAndClinic;
