import styled from 'styled-components';

export const ContainerGraphs = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 500px;
`;

export const TitleSession = styled.label`
  opacity: 1;
  color: var(--deepKoamaru);
  text-align: start;
  font: normal normal bold 14px/19px Nunito Sans;
  letter-spacing: 0px;
  background: var(--lavender) 0% 0% no-repeat padding-box;
  border-left: solid var(--deepKoamaru) 2px;
  border-radius: 0 1px 1px 0;
  padding: 3px 8px 3px 5px;
  height: 25px;
  width: fit-content;
`;

export const SubContainerLegends = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
`;

export const LegendSquare = styled.div`
  width: 12px;
  height: 12px;
  background: ${props => props.lcolor};
`;

export const AntdStyles = {
  RadioButtonSelectVisualization: {
    width: 'auto',
    textAlign: 'center',
    borderColor: '#6864A4',
    borderWidth: '1px',
    backgroundColor: '#f7f7f7',
    fontWeight: 'bold',
    color: '#6864A4',
    borderRadius: '0px 0px 0px 0px',
  },
  RadioButtonLeftAble: {
    width: '7rem',
    textAlign: 'center',
    borderColor: '#3A3979',
    borderWidth: '1px',
    backgroundColor: '#F3F2FF',
    fontWeight: 'bold',
    color: '#6864A4',
    borderRadius: '4px 0px 0px 4px',
  },

  RadioButtonLeftDisable: {
    width: '7rem',
    textAlign: 'center',
    borderWidth: '1px',
    backgroundColor: '#f7f7f7',
    fontWeight: 'bold',
    color: '#868686',
    borderRadius: '4px 0px 0px 4px',
  },

  RadioButtonRigthAble: {
    width: '7rem',
    textAlign: 'center',
    borderColor: '#3A3979',
    borderWidth: '1px',
    backgroundColor: '#F3F2FF',
    fontWeight: 'bold',
    color: '#3A3979',
    borderRadius: '0px 4px 4px 0px',
  },

  RadioButtonRigthDisable: {
    width: '7rem',
    textAlign: 'center',
    borderWidth: '1px',
    backgroundColor: '#f7f7f7',
    fontWeight: 'bold',
    color: '#868686',
    borderRadius: '0px 4px 4px 0px',
  },

  RadioButtonMiddleAble: {
    width: '7rem',
    textAlign: 'center',
    borderColor: '#3A3979',
    borderWidth: '1px',
    backgroundColor: '#F3F2FF',
    fontWeight: 'bold',
    color: '#3A3979',
    borderRadius: '0px 0px 0px 0px',
  },

  RadioButtonMiddleDisable: {
    width: '7rem',
    textAlign: 'center',
    borderWidth: '1px',
    backgroundColor: '#f7f7f7',
    fontWeight: 'bold',
    color: '#868686',
    borderRadius: '0px 0px 0px 0px',
  },

  ButtonToolTip: {
    border: 'none',
    background: 'transparent',
    cursor: 'default',
  },
};
