import styled from 'styled-components';

export const DivContainer = styled.div`
  margin-left: ${props => (props.firstChange ? '2rem' : '4rem')};
  height: 100%;
  width: 'calc(100% - 4rem)';
  display: flex;
  flex-direction: column;
`;

export const TitlePage = styled.h5`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal normal 20px/27px Nunito Sans;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

export const TitleSession = styled.label`
  opacity: 1;
  color: var(--deepKoamaru);
  text-align: start;
  font: normal normal bold 14px/19px Nunito Sans;
  letter-spacing: 0px;
  background: var(--lavender) 0% 0% no-repeat padding-box;
  border-left: solid var(--deepKoamaru) 2px;
  border-radius: 0 1px 1px 0;
  padding: 3px 8px 3px 5px;
  height: 25px;
`;

export const PDescript = styled.p`
  text-align: left;
  font: normal normal normal 12px/18px Nunito Sans;
  letter-spacing: 0px;
  color: #575757;
  opacity: 1;
  width: 70%;
  margin: 0.75rem 0 0 0.75rem;
`;

export const ContainerVideos = styled.div`
  display: ${props => (props.firstChange ? 'flex' : 'block')};
  width: 100%;
`;

export const VideoElement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const AntdStyles = {
  ButtonAddPatient: {
    backgroundColor: '#118E88',
    borderColor: '#118E88',
    // background: '#EAEAEA',
    borderRadius: '4px',
    // borderWidth: 0,
    // borderColor: '#EAEAEA',
    width: '13rem',
    marginTop: '1rem',
    height: '1.8rem',
    marginBottom: '2rem',
    justifyContent: 'center',
    // color: '#f4f4fa',
    borderWidth: '1px',
    fontWeight: 'bold',
    // color: '#868686',
    color: '#fff',
  },
};
