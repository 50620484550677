export const PropsDominancyHandedTranslated = [
  {
    type: 'LEFTHANDED',
    pt: 'Canhoto',
  },
  {
    type: 'RIGHTHANDED',
    pt: 'Destro',
  },
  {
    type: 'TWOHANDED',
    pt: 'Ambidestro',
  },
  {
    type: 'UNDEFINED',
    pt: 'Não Definido',
  },
];

export const PropsDominancyHandedEnum = {
  LEFTHANDED: 'LEFTHANDED',
  RIGHTHANDED: 'RIGHTHANDED',
  TWOHANDED: 'TWOHANDED',
};

export const DominancyHandedTranslated = dominancy => {
  const result = PropsDominancyHandedTranslated.find(p => p.type === dominancy)
    .pt;
  return result;
};
