/* eslint-disable no-unused-vars */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  Button,
  Row,
  Col,
  Input,
  AutoComplete /*  Radio,  */,
} from 'antd';

import InputMask from 'react-input-mask';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';

import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

import { setLoading, updateCard } from 'store/modules/payment/actions';
import closeIcon from 'assets/close.svg';

import * as S from './styles';
import './styles.css';

export function ModalChangeCredCard({
  isModalVisible = false,
  handleOk,
  handleCancel,
}) {
  const loadings = useSelector(state => state.payment.loading);
  const dispatch = useDispatch();
  const firstChange = useMediaQuery('(min-width: 770px)');

  const filterAutoComplete = [
    {
      value: 'Visa',
    },
    {
      value: 'Mastercard',
    },
    {
      value: 'Elo',
    },
    {
      value: 'Amex',
    },
    {
      value: 'Hipercard',
    },
  ];

  const formikGeneralData = {
    initialValues: {
      cardNumber: '',
      cardName: '',
      cardFlag: '',
      cardUsageTime: '',
      cardSecuryCode: '',
    },
    validationSchema: Yup.object({
      cardNumber: Yup.string().required('É obrigatório'),
      cardName: Yup.string().required('É obrigatório'),
      cardFlag: Yup.string().required('É obrigatório'),
      cardUsageTime: Yup.string().required('É obrigatório'),
      cardSecuryCode: Yup.string().required('É obrigatório'),
    }),
  };

  function enterLoading() {
    dispatch(setLoading(true));
  }

  return (
    <Modal
      title="Editar configurações de cartão de crédito"
      visible={isModalVisible}
      onOk={() => handleOk()}
      onCancel={() => handleCancel()}
      width={750}
      closeIcon={
        <img src={closeIcon} alt="close" style={S.AntdStyles.iconClose} />
      }
      footer={[
        <Button
          type="primary"
          onClick={() => handleCancel()}
          style={S.AntdStyles.ButtonClose}
        >
          Fechar
        </Button>,
      ]}
    >
      <Formik
        initialValues={formikGeneralData.initialValues}
        validationSchema={formikGeneralData.validationSchema}
        onSubmit={(values, { setFieldValue, resetForm }) => {
          enterLoading();
          dispatch(updateCard(values));
          setFieldValue('cardFlag', '');
          setFieldValue('cardNumber', '');
          resetForm();
        }}
      >
        {props => (
          <S.Container>
            <S.ContainerLeftSectionForm>
              <Row gutter={[16, 16]}>
                <Col className="gutter-row" span={20}>
                  <S.LabelText>
                    Número do cartão
                    <InputMask
                      mask="9999 9999 9999 9999"
                      value={props.values.cardNumber}
                      style={S.AntdStyles.inputMask}
                      onChange={e =>
                        props.setFieldValue('cardNumber', e.target.value)
                      }
                    />
                    {props.touched.cardNumber && props.errors.cardNumber ? (
                      <S.ErrorMessage>{props.errors.cardNumber}</S.ErrorMessage>
                    ) : null}
                  </S.LabelText>
                </Col>

                <Col className="gutter-row" span={20}>
                  <S.LabelText>
                    Nome escrito no cartão
                    <Input
                      id="name"
                      name="name"
                      type="text"
                      onChange={e =>
                        props.setFieldValue('cardName', e.target.value)
                      }
                      value={props.values.cardName}
                      onBlur={props.handleBlur}
                    />
                    {props.touched.cardName && props.errors.cardName ? (
                      <S.ErrorMessage>{props.errors.cardName}</S.ErrorMessage>
                    ) : null}
                  </S.LabelText>
                </Col>

                <Col className="gutter-row" span={16}>
                  <S.LabelText>
                    Bandeira
                    <AutoComplete
                      dropdownMatchSelectWidth={252}
                      style={{
                        width: 252,
                      }}
                      allowClear
                      options={filterAutoComplete}
                      filterOption={(inputValue, option) =>
                        option.value
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      }
                      value={props.values.cardFlag}
                      onChange={e => {
                        props.setFieldValue('cardFlag', e);
                      }}
                    >
                      <Input
                        id="cardFlag"
                        name="cardFlag"
                        type="text"
                        onChange={props.handleChange}
                        value={props.values.cardFlag}
                        onBlur={props.handleBlur}
                      />
                    </AutoComplete>
                  </S.LabelText>
                </Col>

                <Col className="gutter-row" span={12}>
                  <S.LabelText>
                    Validade
                    <InputMask
                      mask="99/99"
                      value={props.values.cardUsageTime}
                      style={S.AntdStyles.shortInputMask}
                      onChange={e =>
                        props.setFieldValue('cardUsageTime', e.target.value)
                      }
                    />
                    {props.touched.cardUsageTime &&
                    props.errors.cardUsageTime ? (
                      <S.ErrorMessage>
                        {props.errors.cardUsageTime}
                      </S.ErrorMessage>
                    ) : null}
                  </S.LabelText>
                </Col>

                <Col className="gutter-row" span={firstChange ? 12 : 18}>
                  <S.LabelText>
                    Código de segurança
                    <Input
                      id="cardSecuryCode"
                      name="cardSecuryCode"
                      type="text"
                      style={{ width: '40%' }}
                      onChange={props.handleChange}
                      value={props.values.cardSecuryCode}
                      onBlur={props.handleBlur}
                    />
                    {props.touched.cardSecuryCode &&
                    props.errors.cardSecuryCode ? (
                      <S.ErrorMessage>
                        {props.errors.cardSecuryCode}
                      </S.ErrorMessage>
                    ) : null}
                  </S.LabelText>
                </Col>

                <Col className="gutter-row" span={12}>
                  <Button
                    type="primary"
                    onClick={() => {
                      props.handleSubmit();
                    }}
                    loading={loadings}
                    style={S.AntdStyles.ButtonUpdate}
                  >
                    Atualizar
                  </Button>
                </Col>
              </Row>
            </S.ContainerLeftSectionForm>
            <S.ContainerRightSectionCard>
              <Cards
                cvc={props.values.cardSecuryCode}
                expiry={props.values.cardUsageTime}
                name={props.values.cardName}
                number={props.values.cardNumber}
              />
            </S.ContainerRightSectionCard>
          </S.Container>
        )}
      </Formik>
    </Modal>
  );
}
