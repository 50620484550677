/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
import { BrowserRouter, Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConfigProvider, notification } from 'antd';
import ptBR from 'antd/lib/locale/pt_BR';
import { useEffect } from 'react';
import GlobalStyles from './styles/global';

import Routes from './routes';
import history from './services/history';
import AppProvider from './hooks/index';

import './config/ReactotronConfig';
import 'antd/dist/antd.less';
import './styles/styles.css';

import { store, persistor } from './store';

function App() {
  notification.config({
    duration: 5.5,
  });

  // removendo conteúdo do cache pra resolver o problema da tela em branco
  const deleteCache = () => {
    if ('caches' in window) {
      caches.keys().then(names => {
        // Delete all the cache files
        names.forEach(name => {
          caches.delete(name).then(res => {});
        });
      });
    }
  };

  useEffect(() => {
    deleteCache();
  }, []);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <AppProvider>
            <ConfigProvider locale={ptBR} direction="ltr">
              <GlobalStyles />
              <BrowserRouter>
                <Routes />
              </BrowserRouter>
            </ConfigProvider>
          </AppProvider>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
