import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import { useParams } from 'react-router-dom';

import AppHeader from 'components/Header';
import SiderMenuPatient from 'components/Siders/Clinic/Siders/SiderPatient';
import SiderMenuProfessional from 'components/Siders/Clinic/Siders/SiderProfessional';
import SiderMenuReceptionist from 'components/Siders/Clinic/Siders/SiderReceptionist';
import { getInfoDetailsOnlyId } from 'store/modules/clinic/actions';

import FormPatientEdit from './components/FormPatientEditClinic';
import FormProfessionalEdit from './components/FormProfessionalEdit';
import FormReceptionistEdit from './components/FormReceptionistEdit';

export default function InfoEdit() {
  const infoDetails = useSelector(state => state.clinic.infoDetails);
  const typeInfoDetails = useSelector(state => state.clinic.typeInfoDetails);
  const firstChange = useMediaQuery('(min-width: 900px)');
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getInfoDetailsOnlyId(id));
  }, []);

  return (
    <Layout>
      <AppHeader />
      {typeInfoDetails === 'PATIENT' && (
        <SiderMenuPatient>
          <div style={{ marginLeft: firstChange ? '2rem' : '4rem' }}>
            {infoDetails !== null && <FormPatientEdit />}
            {infoDetails === null && <div>Carregando ... </div>}
          </div>
        </SiderMenuPatient>
      )}
      {typeInfoDetails === 'THERAPIST' && (
        <SiderMenuProfessional>
          <div style={{ marginLeft: firstChange ? '2rem' : '4rem' }}>
            {infoDetails !== null && <FormProfessionalEdit />}
            {infoDetails === null && <div>Carregando ... </div>}
          </div>
        </SiderMenuProfessional>
      )}
      {typeInfoDetails === 'RECEPTIONIST' && (
        <SiderMenuReceptionist>
          <div style={{ marginLeft: firstChange ? '2rem' : '4rem' }}>
            {infoDetails !== null && <FormReceptionistEdit />}
            {infoDetails === null && <div>Carregando ... </div>}
          </div>
        </SiderMenuReceptionist>
      )}
    </Layout>
  );
}
