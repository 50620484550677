import { useState, useEffect } from 'react';
import { Button, Typography, Avatar, Tooltip } from 'antd';
import 'antd/dist/antd.less';
import './PatientController.css';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { sendCodeToPatient } from 'store/modules/patient/actions';

import { nameHandler } from 'utils/NameHandler';
import { ModalSendCode } from './components/ModalSendCode';

import * as S from './style';
import { ImageCamera } from './style';

import ImgCamera from '../../../../../assets/camera.svg';

const { Text } = Typography;

function PatientController() {
  const patientDetails = useSelector(state => state.patient.patientDetails);
  const [patientID, setPatientID] = useState(null);
  const [modalSendCode, setModalSendCode] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  function handleCancel() {
    setModalSendCode(false);
  }

  function handleOk() {
    setModalSendCode(false);
    dispatch(sendCodeToPatient(id));
  }

  function handleName(name) {
    if (name.length > 37) {
      return `${name.substring(0, 38)}...`;
    }
    return name;
  }

  useEffect(() => {
    setPatientID(patientDetails?.id);
  }, [patientDetails]);

  return (
    <div className="container-white-background">
      <div className="container-patient-info">
        {patientDetails !== null && patientDetails.photo !== null && (
          <Avatar src={patientDetails?.photo} style={S.AntdStyles.AvatarUser} />
        )}
        {patientDetails !== null && patientDetails.photo === null && (
          <Avatar style={S.AntdStyles.AvatarUser} size={50}>
            {patientDetails?.photo === null && nameHandler(patientDetails.name)}
          </Avatar>
        )}
        <Text style={{ width: 155 }}>
          {patientDetails !== null
            ? handleName(patientDetails.name)
            : 'Carregando...'}
        </Text>
      </div>
      <div className="container-patient-controller-btns">
        <Text
          style={S.AntdStyles.linkTexts}
          onClick={() => history.push('/chat')}
        >
          Enviar Mensagem
        </Text>
        <Text
          style={S.AntdStyles.linkTexts}
          onClick={() => setModalSendCode(true)}
        >
          Enviar código de acesso
        </Text>
        {isMobile ? (
          <Tooltip title="Função disponível apenas em computadores.">
            <Button
              type="primary"
              style={S.AntdStyles.btnCallPatient}
              icon={<ImageCamera src={ImgCamera} />}
              onClick={() => {}}
            >
              Chamar Paciente
            </Button>
          </Tooltip>
        ) : (
          <Button
            type="primary"
            style={S.AntdStyles.btnCallPatient}
            icon={<ImageCamera src={ImgCamera} />}
            onClick={() => {
              const win = window.open(`/${patientID}/video_call`, '_blank');
              win.focus();
            }}
          >
            Chamar Paciente
          </Button>
        )}
      </div>
      <ModalSendCode
        handleOk={handleOk}
        handleCancel={handleCancel}
        isModalVisible={modalSendCode}
      />
    </div>
  );
}

export default PatientController;
