export const ConsultsArray = [
  {
    type: 'events_realized',
    title: 'Consultas Realizadas',
    subtitleInit: '',
    subtitleEnd: 'das consultas agendadas foram realizadas normalmente.',
    color: '#55BFB8',
  },
  {
    type: 'canceled_by_patients',
    title: 'Cancelamento de Pacientes',
    subtitleInit: 'Pacientes cancelaram',
    subtitleEnd: 'das consultas.',
    color: '#87889B',
  },
  {
    type: 'canceled_by_professionals',
    title: 'Cancelamento do(a) Profissional',
    subtitleInit: 'O profissional cancelou',
    subtitleEnd: 'das consultas.',
    color: '#87889B',
  },
  {
    type: 'patient_absence',
    title: 'Falta de Pacientes',
    subtitleInit: 'Pacientes não compareceram a',
    subtitleEnd: 'das consultas.',
    color: '#87889B',
  },
];

export const ComparativeMensalArrayExibition = [
  {
    type: 'canceled_by_patients',
    pt: 'Cancel. de Pacientes',
  },
  {
    type: 'canceled_by_professionals',
    pt: 'Cancel. do(a) Profissional',
  },
  {
    type: 'patient_absence',
    pt: 'Falta de Pacientes',
  },
  {
    type: 'events_realized',
    pt: 'Consultas Realizadas',
  },
];
