/* eslint-disable no-unused-vars */
import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  curr_eval: null,
  computerVision: [],
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@evaluations/SET_NEW_EVALUATION': {
        draft.curr_eval = action.payload.evaluation;
        break;
      }
      case '@evaluations/SET_COMPUTER_VISION': {
        draft.computerVision = action.payload.evaluations;
        break;
      }
      default:
        break;
    }
  });
}
