/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import {
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Dropdown,
  Menu,
  InputNumber,
  notification,
  Upload,
  Radio,
} from 'antd';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { StatesOfBrazil } from 'utils/States';

import InputMask from 'react-input-mask';
import { maskBuilderCPFCNPJ } from 'utils/maskValidator';

import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import { useState, useCallback } from 'react';
import {
  PropsSpecialityArray,
  PropsSpecialityEnum,
  PropsSpecialityTranslated,
} from 'utils/speciality';
import {
  PropsProfessionArray,
  PropsProfessionEnum,
  PropsProfessionTranslated,
} from 'utils/profession';
import { createNewProfileProfessional } from 'store/modules/auth/actions';
import ImgCrop from 'antd-img-crop';
import { useHistory } from 'react-router-dom';
import * as S from './styles';

export function RegisterProfessionalModal({ handleBack }) {
  // validação da especialidade
  const firstChange = useMediaQuery('(min-width: 770px)');
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector(state => state.auth.user);
  // const loading = useSelector(state => state.auth.loading);
  const [fileList, setFileList] = useState([]);
  const professionTranslated = useCallback(profession => {
    const result = PropsProfessionTranslated.find(p => p.type === profession)
      .pt;
    return result;
  }, []);

  const specialityTranslated = useCallback(speciality => {
    const result = PropsSpecialityTranslated.find(p => p.type === speciality)
      .pt;
    return result;
  }, []);

  // validação da especialidade
  const [specialityValidation, setspecialityValidation] = useState(false);

  Yup.addMethod(Yup.string, 'defaultType', function (errorMessage) {
    return this.test(`test-default-type`, errorMessage, function (value) {
      const { path, createError } = this;

      return (
        value !== 'Selecione' || createError({ path, message: errorMessage })
      );
    });
  });

  const formik = {
    initialValues: {
      name: user !== null && user.name !== null ? user.name : '',
      document: '',
      stateUF: 'Selecione',
      email: user !== null && user.email !== null ? user.email : '',
      address: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: '',
      postal_code: '',
      cellphone: '',
      profession: '',
      speciality: [],
      otherProfession: '',
      otherSpeciality: '',
      terms: false,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('É obrigatório'),
      document: Yup.string().required('É obrigatório'),
      email: Yup.string().email('E-mail inválido').required('É obrigatório'),
      address: Yup.string().required('É obrigatório'),
      number: Yup.string().required('É obrigatório'),
      complement: Yup.string().required('É obrigatório'),
      neighborhood: Yup.string().required('É obrigatório'),
      city: Yup.string().required('É obrigatório'),
      stateUF: Yup.string().defaultType('É obrigatório'),
      postal_code: Yup.string().required('É obrigatório'),
      cellphone: Yup.string().required('É obrigatório'),
      profession: Yup.string().required('É obrigatório'),
      speciality: Yup.array()
        .min(1, 'Selecione no mínimo uma especialidade')
        .required('É obrigatório'),
      otherProfession: Yup.string().when('profession', {
        is: PropsProfessionEnum.OTHERPROFESSION,
        then: Yup.string().required('É obrigatório'),
      }),
      terms: Yup.boolean().equals([true], 'Você precisa aceitar os termos'),
    }),
    onSubmit: values => {
      setspecialityValidation(false);

      if (
        values.speciality.find(item => item === PropsSpecialityEnum.OTHER) &&
        values.otherSpeciality === ''
      ) {
        setspecialityValidation(true);
        return;
      }

      // eslint-disable-next-line prefer-const
      let userSubmit = values;
      // inserindo outra profissão ou outra especialidade na mesma variável
      if (
        userSubmit.profession ===
        professionTranslated(PropsProfessionEnum.OTHERPROFESSION)
      ) {
        userSubmit.profession = userSubmit.otherProfession;
      }

      if (
        userSubmit.speciality.find(
          item => item === specialityTranslated(PropsSpecialityEnum.OTHER),
        )
      ) {
        userSubmit.speciality = userSubmit.speciality.filter(
          item => item !== specialityTranslated(PropsSpecialityEnum.OTHER),
        );
        userSubmit.speciality.push(userSubmit.otherSpeciality);
      }

      const photo = fileList.length > 0 ? fileList[0].originFileObj : null;

      if (userSubmit.stateUF === 'Selecione') {
        userSubmit.stateUF = null;
      }

      dispatch(createNewProfileProfessional(userSubmit, photo, history));
    },
  };

  const onChange = ({ fileList: newFileList }) => {
    // eslint-disable-next-line prefer-const
    let file = newFileList;
    file[0].status = 'done';
    setFileList(file);
  };

  const onPreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };
  return (
    <Formik
      initialValues={formik.initialValues}
      validationSchema={formik.validationSchema}
      onSubmit={values => {
        formik.onSubmit(values);
      }}
    >
      {props => (
        <S.ContainerModal>
          <S.TitlePage>Informações Geraiss</S.TitlePage>
          <Row gutter={[32, 16]}>
            <Col className="gutter-row" span={firstChange ? 24 : 24}>
              <S.LabelText className="input-label">
                Foto
                <ImgCrop rotate>
                  <Upload
                    listType="picture-card"
                    fileList={fileList}
                    onChange={onChange}
                    onPreview={onPreview}
                  >
                    {fileList.length < 1 && '+ Upload'}
                  </Upload>
                </ImgCrop>
              </S.LabelText>
              {props.touched.name && props.errors.name ? (
                <S.ErrorMessage>{props.errors.name}</S.ErrorMessage>
              ) : null}
            </Col>
            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Nome
                <Input
                  style={S.AntdStyles.marginTop}
                  id="name"
                  name="name"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.name}
                  onBlur={props.handleBlur}
                />
              </S.LabelText>
              {props.touched.name && props.errors.name ? (
                <S.ErrorMessage>{props.errors.name}</S.ErrorMessage>
              ) : null}
            </Col>
            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText>CPF ou CNPJ</S.LabelText>
              <InputMask
                mask={maskBuilderCPFCNPJ(props.values.document)}
                style={S.AntdStyles.inputMask}
                value={props.values.document}
                onChange={e =>
                  props.setFieldValue(
                    'document',
                    e.target.value.replace(/\D/g, ''),
                  )
                }
              />
              {props.touched.document && props.errors.document ? (
                <S.ErrorMessage>{props.errors.document}</S.ErrorMessage>
              ) : null}
            </Col>
            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Telefone
                <InputMask
                  mask="(99)99999-9999"
                  value={props.values.cellphone}
                  style={S.AntdStyles.inputMask}
                  onChange={e => {
                    props.setFieldValue('cellphone', e.target.value);
                  }}
                />
              </S.LabelText>
            </Col>

            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText>Profissão</S.LabelText>

              <Radio.Group
                onChange={event =>
                  props.setFieldValue('profession', event.target.value)
                }
                value={props.values.profession}
                style={{ marginTop: '8px' }}
              >
                {PropsProfessionArray.map(
                  item =>
                    item && (
                      <Radio
                        value={professionTranslated(PropsProfessionEnum[item])}
                      >
                        {professionTranslated(PropsProfessionEnum[item])}
                      </Radio>
                    ),
                )}
              </Radio.Group>
              {props.touched.profession && props.errors.profession ? (
                <S.ErrorMessage>{props.errors.profession}</S.ErrorMessage>
              ) : null}
              {props.values.profession ===
              professionTranslated(PropsProfessionEnum.OTHERPROFESSION) ? (
                <Input
                  placeholder="Qual?"
                  onChange={event =>
                    props.setFieldValue('otherProfession', event.target.value)
                  }
                  value={props.values.otherProfession}
                  style={{ marginTop: '8px' }}
                />
              ) : null}
              {props.touched.otherProfession && props.errors.otherProfession ? (
                <S.ErrorMessage>{props.errors.otherProfession}</S.ErrorMessage>
              ) : null}
            </Col>
            <Col className="gutter-row" span={firstChange ? 16 : 24}>
              <S.LabelText>Especialidade</S.LabelText>
              <Checkbox.Group
                style={{ width: '100%' }}
                onChange={checkedValues =>
                  props.setFieldValue('speciality', checkedValues)
                }
              >
                <S.DivMarginTop>
                  <Row>
                    {PropsSpecialityArray.map(
                      item =>
                        item && (
                          <Col span={12}>
                            <Checkbox
                              value={`${specialityTranslated(
                                PropsSpecialityEnum[item],
                              )}`}
                            >
                              {specialityTranslated(PropsSpecialityEnum[item])}
                            </Checkbox>
                          </Col>
                        ),
                    )}
                  </Row>
                </S.DivMarginTop>
              </Checkbox.Group>
              {props.touched.speciality && props.errors.speciality ? (
                <S.ErrorMessage>{props.errors.speciality}</S.ErrorMessage>
              ) : null}
              {props.values.speciality.find(
                item =>
                  item === specialityTranslated(PropsSpecialityEnum.OTHER),
              ) ? (
                <Input
                  placeholder="Qual?"
                  onChange={event =>
                    props.setFieldValue('otherSpeciality', event.target.value)
                  }
                  value={props.values.otherSpeciality}
                  style={{ marginTop: '10px' }}
                />
              ) : null}
              {specialityValidation ? (
                <S.ErrorMessage>É obrigatório</S.ErrorMessage>
              ) : null}
            </Col>
          </Row>

          <S.TitlePage style={{ marginTop: 30 }}>Endereço</S.TitlePage>
          <Row gutter={[32, 16]}>
            <Col className="gutter-row" span={firstChange ? 16 : 24}>
              <S.LabelText className="input-label">
                Rua
                <Input
                  style={S.AntdStyles.marginTop}
                  id="address"
                  name="address"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.address}
                  onBlur={props.handleBlur}
                />
                {props.touched.address && props.errors.address ? (
                  <S.ErrorMessage>{props.errors.address}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Número
                <InputNumber
                  min={0}
                  style={S.AntdStyles.inputNumber}
                  value={props.values.number}
                  onChange={value => props.setFieldValue('number', value)}
                />
                {props.touched.number && props.errors.number ? (
                  <S.ErrorMessage>{props.errors.number}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col className="gutter-row" span={firstChange ? 16 : 24}>
              <S.LabelText className="input-label">
                Complemento
                <Input
                  style={S.AntdStyles.marginTop}
                  id="complement"
                  name="complement"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.complement}
                  onBlur={props.handleBlur}
                />
                {props.touched.complement && props.errors.complement ? (
                  <S.ErrorMessage>{props.errors.complement}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Bairro
                <Input
                  style={S.AntdStyles.marginTop}
                  id="neighborhood"
                  name="neighborhood"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.neighborhood}
                  onBlur={props.handleBlur}
                />
                {props.touched.neighborhood && props.errors.neighborhood ? (
                  <S.ErrorMessage>{props.errors.neighborhood}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Cidade
                <Input
                  style={S.AntdStyles.marginTop}
                  id="city"
                  name="city"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.city}
                  onBlur={props.handleBlur}
                />
                {props.touched.city && props.errors.city ? (
                  <S.ErrorMessage>{props.errors.city}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Estado
                <Dropdown
                  overlay={
                    <Menu>
                      {StatesOfBrazil.map(item => (
                        <Menu.Item>
                          <element
                            onClick={() => props.setFieldValue('stateUF', item)}
                          >
                            {item}
                          </element>
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                  placement="bottomCenter"
                >
                  <Button style={S.AntdStyles.ButtonTypePayment}>
                    {props.values.stateUF}
                  </Button>
                </Dropdown>
                {props.touched.stateUF && props.errors.stateUF ? (
                  <S.ErrorMessage>{props.errors.stateUF}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                CEP
                <InputMask
                  mask="99999-999"
                  value={props.values.postal_code}
                  style={S.AntdStyles.inputMask}
                  onChange={e =>
                    props.setFieldValue('postal_code', e.target.value)
                  }
                />
                {props.touched.postal_code && props.errors.postal_code ? (
                  <S.ErrorMessage>{props.errors.postal_code}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <div
              style={{
                right: 20,
                bottom: 20,
                marginTop: 20,
                position: 'absolute',
              }}
            >
              <Button
                type="primary"
                style={S.AntdStyles.buttonLogin}
                onClick={() => handleBack()}
                htmlType="submit"
              >
                Voltar
              </Button>
              <Button
                type="primary"
                style={S.AntdStyles.buttonRegister}
                onClick={props.handleSubmit}
                htmlType="submit"
              >
                Adicionar
              </Button>
            </div>
          </Row>

          <S.TitlePage style={{ marginTop: 30 }}> Termos de uso</S.TitlePage>

          <Row gutter={[32, 16]}>
            <Col className="gutter-row" span={firstChange ? 16 : 24}>
              <S.LabelText className="input-label">
                <Checkbox
                  onChange={e => props.setFieldValue('terms', e.target.checked)}
                  value={props.values.terms}
                >
                  {' '}
                  Li e aceito os{' '}
                  <a
                    href="https://neurobots.com.br/monitore/termos"
                    target="_blank"
                    rel="noreferrer"
                  >
                    termos de uso{' '}
                  </a>
                  e a{' '}
                  <a
                    href="https://neurobots.com.br/monitore/privacidade"
                    target="_blank"
                    rel="noreferrer"
                  >
                    política de privacidade.
                  </a>
                </Checkbox>
                {props.touched.terms && props.errors.terms ? (
                  <S.ErrorMessage>{props.errors.terms}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>
          </Row>
        </S.ContainerModal>
      )}
    </Formik>
  );
}
