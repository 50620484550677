/* eslint-disable no-unreachable */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import { takeLatest, put, all, call, select, delay } from 'redux-saga/effects';
import { notification } from 'antd';
import firebase from 'firebase/app';
import { PropsProfileEnum } from 'utils/profile';
import {
  signFailure,
  signInSuccess,
  signInSuccessGoogle,
  setUser,
  refreshPageStart,
  refreshPageEnd,
  getUser,
  signInSuccessWithoutVerification,
  setIsEmailVerified,
  changeLoadingStatus,
  updateUserState,
} from './actions';
import { uploadAvatar as uploadAvatarProfessional } from '../professional/actions';
import { uploadAvatarClinic, changeActiveProfile } from '../clinic/actions';

import api from '../../../services/api';
import postmon from '../../../services/postmon';
import fire from '../../../services/fire';

export function* signIn({ payload }) {
  try {
    const { email, password } = payload;
    const infoFirebase = yield fire
      .auth()
      .signInWithEmailAndPassword(email, password);

    const token = yield fire.auth().currentUser.getIdToken();

    api.defaults.headers.Authorization = token;

    const responseFirstAccess = yield call(api.get, '/check_first_access');

    if (responseFirstAccess.data.first === true) {
      const user = {
        name: infoFirebase.user.displayName,
        email: infoFirebase.user.email,
        photo: null,
        checkFirstAccess: responseFirstAccess.data.first,
        profile: null,
        loggedWithGoogle: false,
      };

      yield put(setUser(user));
      yield put(changeLoadingStatus(false));
      return;
    }

    const responseSelf = yield call(api.get, '/self');

    const user = {
      checkFirstAccess: responseFirstAccess.data.first,
      loggedWithGoogle: false,
      ...responseSelf.data.self,
    };

    // se for acesso com google já email verificado
    // senão, é enviado um email de verificação
    if (fire.auth().currentUser.emailVerified) {
      yield put(signInSuccess(token, user));
    } else {
      fire.auth().currentUser.sendEmailVerification();

      yield put(signInSuccessWithoutVerification(token, user));
      yield put(setIsEmailVerified(false));
    }
  } catch (err) {
    switch (err.code) {
      case 'auth/invalid-email':
        notification.error({
          message: 'Email Inválido',
        });
        break;

      case 'auth/user-disabled':
        notification.error({
          message: 'Email desabilitado',
        });
        break;

      case 'auth/user-not-found':
        notification.error({
          message: 'Usuário não encontrado',
        });
        break;

      case 'auth/wrong-password':
        notification.error({
          message: 'Usuário e/ou senha não correspondente',
        });
        break;

      default:
        notification.error({
          message: 'Algo deu errado durante a conexão, tente novamente.',
        });
        break;
    }
    yield put(signFailure());
  }
}

export function* signInGoogle() {
  try {
    const provider = new firebase.auth.GoogleAuthProvider();

    const infoEmailGoogle = yield fire.auth().signInWithPopup(provider);

    const token = yield fire.auth().currentUser.getIdToken();

    api.defaults.headers.Authorization = token;

    const responseFirstAccess = yield call(api.get, '/check_first_access');

    if (responseFirstAccess.data.first === true) {
      const user = {
        name: infoEmailGoogle.user.displayName,
        email: infoEmailGoogle.user.email,
        photo: infoEmailGoogle.user.photoURL,
        checkFirstAccess: responseFirstAccess.data.first,
        profile: null,
        loggedWithGoogle: true,
      };

      // fire.auth().signOut();
      yield put(setUser(user));
      return;
    }

    const responseSelf = yield call(api.get, '/self');

    const user = {
      checkFirstAccess: responseFirstAccess.data.first,
      loggedWithGoogle: true,
      ...responseSelf.data.self,
    };

    // se for acesso com google já email verificado
    // senão, é enviado um email de verificação
    if (fire.auth().currentUser.emailVerified) {
      yield put(signInSuccessGoogle(token, user));
    } else {
      fire.auth().currentUser.sendEmailVerification();
      yield put(signInSuccessWithoutVerification(token, user));
      yield put(setIsEmailVerified(false));
    }
  } catch (err) {
    switch (err.code) {
      case 'auth/invalid-email':
        notification.error({
          message: 'Email Inválido',
        });
        break;

      case 'auth/user-disabled':
        notification.error({
          message: 'Email desabilitado',
        });
        break;

      case 'auth/user-not-found':
        notification.error({
          message: 'Usuário não encontrado',
        });
        break;

      case 'auth/wrong-password':
        notification.error({
          message: 'Usuário e/ou senha não correspondente',
        });
        break;

      default:
        notification.error({
          message: 'Algo deu errado durante a conexão, tente novamente.',
        });
        break;
    }
    yield put(signFailure());
  }
}

export function* setToken() {
  yield put(refreshPageStart());
  let isExistsToken = false;
  yield call(
    () =>
      new Promise((resolve, reject) => {
        fire.auth().onAuthStateChanged(user => {
          if (fire.auth()?.currentUser?.emailVerified) {
            user.getIdToken().then(token => {
              if (token) {
                api.defaults.headers.Authorization = token;
                isExistsToken = true;
              }
              resolve();
            });
          } else {
            console.log('fire.auth()?.currentUser: ', fire.auth()?.currentUser);
          }
        });
      }),
  );
  if (isExistsToken) {
    yield put(getUser());
    yield put(refreshPageEnd());
  }
}

export function* refreshToken() {
  yield call(
    () =>
      new Promise((resolve, reject) => {
        fire.auth().onAuthStateChanged(user => {
          user.getIdToken().then(token => {
            if (token) {
              api.defaults.headers.Authorization = token;
            }
            resolve();
          });
        });
      }),
  );
}

export function* registerProfessional({ payload }) {
  try {
    const { user, photo, page } = payload;

    const userRedux = state => state.auth.user;
    const userStore = yield select(userRedux);

    let token = null;

    // checagem se o login já foi feito com o firebase
    if (userStore !== null) {
      token = yield fire.auth().currentUser.getIdToken();

      api.defaults.headers.Authorization = token;
    } else {
      const responseAuth = yield fire
        .auth()
        .createUserWithEmailAndPassword(user.email, user.password);

      token = yield responseAuth.user.getIdToken();

      api.defaults.headers.Authorization = token;
    }

    const finalDocument = user.document
      .replaceAll('.', '')
      .replace('/', '')
      .replace('-', '');
    const responseFirstAccess = yield call(api.get, '/check_first_access');

    if (responseFirstAccess.data.first) {
      let body = {
        type: 'THERAPIST',
        nome: user.name,
        document: finalDocument,
        endereco: {
          street: user.address,
          number: user.number,
          complement: user.complement,
          neighborhood: user.neighborhood,
          city: user.city,
          state: user.stateUF,
          country: 'Brasil',
          postal_code: user.postal_code,
        },
        fone: user.cellphone,
        profissao: user.profession,
        especialidade: user.speciality,
      };

      // Organizar create_profile
      const responseProfile = yield call(api.post, '/create_profile', body);
      if (responseProfile === undefined) return;

      if (photo !== null) {
        yield put(uploadAvatarProfessional(photo, false));
      }
    }

    // se for acesso com google já email verificado
    // senão, é enviado um email de verificação
    if (fire.auth().currentUser.emailVerified) {
      yield put(signInSuccess(token, user));
      yield put(updateUserState(null));
      page('login');
      notification.success({
        message: 'Cadastro finalizado com sucesso! Faça login.',
      });
    } else {
      fire.auth().currentUser.sendEmailVerification();

      yield put(signInSuccessWithoutVerification(token, user));
      yield put(setIsEmailVerified(false));
    }
  } catch (err) {
    switch (err.code) {
      case 'auth/email-already-in-use':
        notification.error({
          message: 'O email inserido já se encontra em uso. Faça login.',
        });
        break;

      case 'auth/invalid-email':
        notification.error({
          message:
            'O email inserido é inválido. Cheque se foi digitado corretamente e tente novamente.',
        });
        break;

      case 'auth/operation-not-allowed':
        notification.error({
          message: 'Email e senha estão desabilitados.',
        });
        break;

      case 'auth/weak-password':
        notification.error({
          message: 'Senha fraca, insira uma senha com mais caracteres.',
        });
        break;

      default:
        notification.error({
          message: 'Algo deu errado, tente novamente',
        });
        break;
    }
  }
}

export function* createNewProfileProfessional({ payload }) {
  try {
    const { user, photo, history } = payload;
    const userRedux = state => state.auth.user;
    const userStore = yield select(userRedux);

    const finalDocument = user.document
      .replaceAll('.', '')
      .replace('/', '')
      .replace('-', '');

    // Organizar create_profile
    yield call(api.post, '/create_profile', {
      type: 'THERAPIST',
      nome: user.name,
      document: finalDocument,
      endereco: {
        street: user.address,
        number: user.number,
        complement: user.complement,
        neighborhood: user.neighborhood,
        city: user.city,
        state: user.stateUF,
        country: 'Brasil',
        postal_code: user.postal_code,
      },
      fone: user.cellphone,
      profissao: user.profession,
      especialidade: user.speciality,
    });

    const responseSelf = yield call(api.get, '/self');
    const { self } = responseSelf.data;
    let newProfile = null;

    self.profiles.forEach(itemX => {
      const findIndexProfile = userStore.profiles.findIndex(
        itemY => itemY.id === itemX.id,
      );

      if (findIndexProfile === -1) {
        newProfile = itemX;
      }
    });

    if (newProfile !== null) {
      yield put(changeActiveProfile(newProfile.id));
      history.push('/patient_list');
      history.go(0);
    }

    if (photo !== null) {
      yield put(uploadAvatarProfessional(photo, false));
    }
  } catch (err) {
    switch (err.code) {
      default:
        notification.error({
          message: 'Algo deu errado, tente novamente',
        });
        break;
    }
  }
}

export function* createNewProfileClinic({ payload }) {
  try {
    const { user, photo, history } = payload;
    const userRedux = state => state.auth.user;
    const userStore = yield select(userRedux);

    const finalDocument = user.document
      .replaceAll('.', '')
      .replaceAll('-', '')
      .replace('/', '')
      .replace('-', '');

    // Organizar create_profile
    yield call(api.post, '/create_profile', {
      type: 'CLINIC',
      name: user.name,
      document: finalDocument,
      address: {
        street: user.address,
        number: user.number,
        complement: user.complement,
        neighborhood: user.neighborhood,
        city: user.city,
        state: user.stateUF,
        country: 'Brasil',
        postal_code: user.postal_code,
      },
      phone_number1: user.cellphone1,
      phone_number2: user.cellphone2,
      hourInit: user.hourInit,
      hourEnd: user.hourEnd,
      dayInWeek: user.dayInWeek,
    });

    const responseSelf = yield call(api.get, '/self');
    const { self } = responseSelf.data;
    let newProfile = null;

    self.profiles.forEach(itemX => {
      const findIndexProfile = userStore.profiles.findIndex(
        itemY => itemY.id === itemX.id,
      );

      if (findIndexProfile === -1) {
        newProfile = itemX;
      }
    });

    if (newProfile !== null) {
      yield put(changeActiveProfile(newProfile.id));
      history.push('/patient_list_clinic');
      history.go(0);
    }

    if (photo !== null) {
      yield put(uploadAvatarProfessional(photo, false));
    }
  } catch (err) {
    switch (err.code) {
      default:
        notification.error({
          message: 'Algo deu errado, tente novamente',
        });
        break;
    }
  }
}

export function* registerClinic({ payload }) {
  try {
    const { user, photo, page } = payload;

    const userRedux = state => state.auth.user;
    const userStore = yield select(userRedux);

    let token = null;
    // checagem se o login já foi feito com o firebase
    if (userStore !== null) {
      token = yield fire.auth().currentUser.getIdToken();

      api.defaults.headers.Authorization = token;
    } else {
      const responseAuth = yield fire
        .auth()
        .createUserWithEmailAndPassword(user.email, user.password);

      token = yield responseAuth.user.getIdToken();

      api.defaults.headers.Authorization = token;
    }
    const finalDocument = user.document
      .replaceAll('.', '')
      .replace('/', '')
      .replace('-', '');
    const responseFirstAccess = yield call(api.get, '/check_first_access');

    if (responseFirstAccess.data.first) {
      const body = {
        type: 'CLINIC',
        name: user.name,
        document: finalDocument,
        address: {
          street: user.address,
          number: user.number,
          complement: user.complement,
          neighborhood: user.neighborhood,
          city: user.city,
          state: user.stateUF,
          country: 'Brasil',
          postal_code: user.postal_code,
        },
        phone_number1: user.cellphone1,
        phone_number2: user.cellphone2,
        hourInit: user.hourInit,
        hourEnd: user.hourEnd,
        dayInWeek: user.dayInWeek,
      };

      // if (userStore !== null && userStore?.photo !== null) {
      //   body.photo = userStore.photo;
      // }

      const responseProfile = yield call(api.post, '/create_profile', body);
      if (responseProfile === undefined) return;

      if (photo !== null) {
        yield put(uploadAvatarProfessional(photo, false));
      }
    }
    // se for acesso com google já email verificado
    // senão, é enviado um email de verificação
    if (fire.auth().currentUser.emailVerified) {
      yield put(signInSuccess(token, user));
      yield put(updateUserState(null));
      page('login');
      notification.success({
        message: 'Cadastro finalizado com sucesso! Faça login.',
      });
    } else {
      fire.auth().currentUser.sendEmailVerification();

      yield put(signInSuccessWithoutVerification(token, user));
      yield put(setIsEmailVerified(false));
    }
  } catch (err) {
    switch (err.code) {
      case 'auth/email-already-in-use':
        notification.error({
          message: 'Email já em uso, faça login.',
        });
        break;

      case 'auth/invalid-email':
        notification.error({
          message: 'Email inválido',
        });
        break;

      case 'auth/operation-not-allowed':
        notification.error({
          message: 'Email e senha estão desabilitados',
        });
        break;

      case 'auth/weak-password':
        notification.error({
          message: 'Senha fraca',
        });
        break;

      default:
        // notification.error({
        //   message: 'Algo deu errado, tente novamente',
        // });
        break;
    }
  }
}

export function* registerReceptionist({ payload }) {
  try {
    const { user, photo, page } = payload;

    const userRedux = state => state.auth.user;
    const userStore = yield select(userRedux);

    let token = null;
    const finalDocument = user.document
      .replaceAll('.', '')
      .replace('/', '')
      .replace('-', '');

    // checagem se o login já foi feito com o firebase
    if (userStore !== null) {
      token = yield fire.auth().currentUser.getIdToken();

      api.defaults.headers.Authorization = token;
    } else {
      const responseAuth = yield fire
        .auth()
        .createUserWithEmailAndPassword(user.email, user.password);

      token = yield responseAuth.user.getIdToken();

      api.defaults.headers.Authorization = token;
    }

    const responseFirstAccess = yield call(api.get, '/check_first_access');

    if (responseFirstAccess.data.first) {
      const body = {
        type: 'RECEPTIONIST',
        name: user.name,
        document: finalDocument,
      };

      if (userStore !== null && userStore.photo !== null) {
        body.photo = userStore.photo;
      }

      const responseProfile = yield call(api.post, '/create_profile', body);
      if (responseProfile === undefined) return;

      if (photo !== null) {
        yield put(uploadAvatarProfessional(photo, false));
      }
    }

    // se for acesso com google já email verificado
    // senão, é enviado um email de verificação
    if (fire.auth().currentUser.emailVerified) {
      yield put(signInSuccess(token, user));
      yield put(updateUserState(null));
      page('login');
      notification.success({
        message: 'Cadastro finalizado com sucesso! Faça login.',
      });
    } else {
      fire.auth().currentUser.sendEmailVerification();
      yield put(signInSuccessWithoutVerification(token, user));
      yield put(setIsEmailVerified(false));
    }
  } catch (err) {
    switch (err.code) {
      case 'auth/email-already-in-use':
        notification.error({
          message: 'Email já em uso. Faça login.',
        });
        break;

      case 'auth/invalid-email':
        notification.error({
          message: 'Email inválido',
        });
        break;

      case 'auth/operation-not-allowed':
        notification.error({
          message: 'Email e senha estão desabilitados',
        });
        break;

      case 'auth/weak-password':
        notification.error({
          message: 'Senha fraca',
        });
        break;

      default:
        notification.error({
          message: 'Algo deu errado, tente novamente',
        });
        break;
    }
  }
}

export function* signOut() {
  try {
    yield fire.auth().signOut();
  } catch (err) {
    console.log(err);
  }
}

export function* updateUser({ payload }) {
  try {
    const { user } = payload;

    const userRedux = state => state.auth.user;
    const userStore = yield select(userRedux);

    if (userStore.profile === 'THERAPIST') {
      yield call(api.put, `/update_self`, {
        profile: {
          id: userStore.id,
          name: user.name,
          document: user.document,
          phone_number: user.cellphone,
          profession: user?.profession,
          specialities: user.specialities,
          address: {
            street: user.street,
            number: user.houseNumber,
            complement: user.complement,
            neighborhood: user.neighborhood,
            city: user.city,
            state: user.state,
            country: 'Brasil',
            postal_code: user.postalCode,
          },
        },
      });
    }

    if (userStore.profile === 'CLINIC') {
      yield call(api.put, `/update_self`, {
        profile: {
          address: {
            street: user.address,
            number: user.number,
            complement: user.complement,
            neighborhood: user.neighborhood,
            city: user.city,
            state: user.stateUF,
            country: 'Brasil',
            postal_code: user.postal_code,
          },
          document: user.document,
          dayInWeek: user.daysOfWeek,
          hourEnd: user.hourClinic2,
          hourInit: user.hourClinic1,
          id: userStore.id,
          name: user.nameClinic,
          phone_number1: user.cellphoneClinic1,
          phone_number2: user.cellphoneClinic2,
        },
      });
    }
    if (userStore.profile === 'RECEPTIONIST') {
      const finalCPF = user.document
        .replace('.', '')
        .replace('.', '')
        .replace('-', '');
      yield call(api.put, `/update_self`, {
        profile: {
          name: user.name,
          document: finalCPF,
        },
      });
    }
    yield put(getUser());

    notification.success({
      message: 'Conta atualizada com sucesso!',
    });
  } catch (err) {
    notification.error({
      message: 'Houve um erro em atualizar sua conta, tente novamente!',
    });
  }
}

export function* updatePassword({ payload }) {
  try {
    const { email } = payload;

    yield fire.auth().sendPasswordResetEmail(email);

    notification.success({
      message: 'E-mail enviado!',
    });
  } catch (err) {
    switch (err.code) {
      case 'auth/invalid-email':
        notification.error({
          message: 'Email inválido',
        });
        break;

      case 'auth/user-not-found':
        notification.error({
          message: 'Usuário não encontrado',
        });
        break;

      default:
        notification.error({
          message: 'Houve um erro em atualizar sua senha, tente novamente!',
        });
        break;
    }
  }
}

export function* getUserSaga() {
  try {
    const responseSelf = yield call(api.get, '/self');
    yield put(setUser(responseSelf.data.self));
  } catch {
    notification.error({
      message: 'Houve um erro em buscar suas informações, tente novamente!',
    });
  }
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/REFRESH_TOKEN', refreshToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_IN_REQUEST_GOOGLE', signInGoogle),
  takeLatest('@auth/REGISTER_PROFESSIONAL', registerProfessional),
  takeLatest('@auth/REGISTER_CLINIC', registerClinic),
  takeLatest('@auth/REGISTER_RECEPTIONIST', registerReceptionist),
  takeLatest('@auth/SIGN_OUT', signOut),
  takeLatest('@auth/UPDATE_USER', updateUser),
  takeLatest('@auth/UPDATE_PASSWORD', updatePassword),
  takeLatest('@auth/GET_USER', getUserSaga),
  takeLatest(
    '@auth/CREATE_NEW_PROFILE_PROFESSIONAL',
    createNewProfileProfessional,
  ),
  takeLatest('@auth/CREATE_NEW_PROFILE_CLINIC', createNewProfileClinic),
]);
