/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { Layout, Menu, Drawer } from 'antd';

import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { divider } from './Divider';
import PatientController from '../Controller';
import PatientPageHeader from '../pageHeader';
import '../SiderMenu.css';
import * as S from './styles';

import ImgRegisterData from '../../../../../../assets/register-data.svg';
import ImgAnamnesis from '../../../../../../assets/anamnesis.svg';
import ImgAgenda from '../../../../../../assets/agenda.svg';
import ImgFollow from '../../../../../../assets/follow.svg';

import {
  ImageRegisterData,
  ImageAnamnesis,
  ImageAgenda,
  ImageFollow,
} from './styles';

const { Sider } = Layout;

function SiderMenu() {
  const { id } = useParams();
  const infoDetailsName = useSelector(state => state.clinic.infoDetails.name);
  const history = useHistory();
  const location = useLocation();

  const [navColor1, setNavColor1] = useState('#118E88');
  const [navColor2, setNavColor2] = useState('#118E88');
  const [navColor3, setNavColor3] = useState('#118E88');
  const [navColor4, setNavColor4] = useState('#118E88');

  const setBackGroundColor = key => {
    switch (key) {
      case '1':
        setNavColor1('#00605B');
        setNavColor2('#118E88');
        setNavColor3('#118E88');
        setNavColor4('#118E88');
        break;
      case '2':
        setNavColor1('#118E88');
        setNavColor2('#00605B');
        setNavColor3('#118E88');
        setNavColor4('#118E88');
        break;
      case '3':
        setNavColor1('#118E88');
        setNavColor2('#118E88');
        setNavColor3('#00605B');
        setNavColor4('#118E88');
        break;
      case '4':
        setNavColor1('#118E88');
        setNavColor2('#118E88');
        setNavColor3('#118E88');
        setNavColor4('#00605B');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (location.pathname.includes('edit_info')) {
      setBackGroundColor('1');
    }
  }, []);

  const NavBar = () => (
    <>
      <PatientPageHeader />
      <div className="space-btw" />
      <PatientController />
      <Menu mode="inline" defaultSelectedKeys={['1']} style={S.AntdStyles.menu}>
        <Menu.Item
          key="1"
          icon={<ImageRegisterData src={ImgRegisterData} />}
          style={{
            display: 'flex',
            alignItems: 'center',
            background: navColor1,
            color: 'white',
            width: '90%',
            borderRadius: '3px',
            marginBottom: '0px',
            marginTop: '4%',
          }}
          onClick={() => {
            setBackGroundColor('1');
            history.push(`/${id}/edit_info`);
          }}
        >
          Dados Cadastrais
        </Menu.Item>
        {divider}
        <Menu.Item
          key="2"
          icon={<ImageAnamnesis src={ImgAnamnesis} />}
          style={{
            display: 'flex',
            alignItems: 'center',
            background: navColor2,
            color: 'white',
            width: '90%',
            borderRadius: '3px',
            marginBottom: '0px',
            marginTop: '0px',
          }}
          onClick={() => {
            setBackGroundColor('2');
            history.push({
              pathname: `/patient_list_clinic`,
              state: {
                search: infoDetailsName,
              },
            });
          }}
        >
          Pacientes
        </Menu.Item>
        {divider}
        <Menu.Item
          key="3"
          icon={<ImageAgenda src={ImgAgenda} />}
          style={{
            display: 'flex',
            alignItems: 'center',
            background: navColor3,
            color: 'white',
            width: '90%',
            borderRadius: '3px',
            marginBottom: '0px',
            marginTop: '0px',
          }}
          onClick={() => {
            setBackGroundColor('6');
            history.push({
              pathname: `/consults`,
              state: {
                search: infoDetailsName,
              },
            });
          }}
        >
          Consultas
        </Menu.Item>
        {divider}
        <Menu.Item
          key="4"
          icon={<ImageFollow src={ImgFollow} />}
          style={{
            display: 'flex',
            alignItems: 'center',
            background: navColor4,
            color: 'white',
            width: '90%',
            borderRadius: '3px',
            marginBottom: '5%',
            marginTop: '0px',
          }}
          onClick={() => {
            setBackGroundColor('4');
            history.push({
              pathname: `/data_consults`,
              state: {
                search: { id, professionalName: infoDetailsName },
              },
            });
          }}
        >
          Dados das Consultas
        </Menu.Item>
      </Menu>
    </>
  );

  const [toggled, setToggled] = useState(false);
  // showCollapsed tem q ficar false > 990px e true < 990px, 990px é quando o sider colapsa
  const [showCollapsed, setShowCollapsed] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 990;

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResizeWindow);
    return () => {
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, []);

  useEffect(() => {
    if (width > breakpoint) {
      setShowCollapsed(false);
    } else {
      setShowCollapsed(true);
    }
  }, [width]);

  return (
    <>
      <Drawer
        placement="left"
        onClose={() => setToggled(false)}
        closable={false}
        visible={toggled}
        bodyStyle={{ backgroundColor: '#F7F7F7', padding: '15px' }}
        key="left"
        width={260}
      >
        <NavBar />
      </Drawer>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        collapsed={showCollapsed}
        style={S.AntdStyles.sider}
        zeroWidthTriggerStyle={{ backgroundColor: '#118E88' }}
        onCollapse={(collapsed, type) => {
          if (type === 'clickTrigger' && collapsed) {
            setToggled(false);
          } else if (type === 'clickTrigger' && !collapsed) {
            setToggled(true);
          }
        }}
      >
        <NavBar />
      </Sider>
    </>
  );
}

export default SiderMenu;
