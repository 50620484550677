/* eslint-disable react/no-array-index-key */
/* eslint-disable no-lonely-if */
import React, { useContext, useEffect, useState } from 'react';
import { Menu, Dropdown, Button, Radio } from 'antd';
import ArrowDownGrey from 'assets/arrow-down-grey.svg';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import FormContext from '../Context/FormContext';
import { ExamsEnum } from '../../../../../utils/exams';
// import { tfmjt } from '../../../../utils/mocks';

import * as S from './styles';

export function PerformedEvaluations() {
  const { monitoringData } = useContext(FormContext);
  const [filterSearchOptions, setFilterSearchOptions] = useState([]);
  const [radioOptions, setRadioOptions] = useState([]);
  const [evalSelected, setEvalSelected] = useState('Selecione a Avaliação');
  const [currentEvalTable, setCurrentEvalTable] = useState([]);
  const [currentEvalResults, setCurrentEvalResults] = useState([]);
  const [currentResult, setCurrentResult] = useState(null);
  const [tableTitles, setTableTitles] = useState([]);
  const [tableContent, setTableContent] = useState(null);
  const [tableContentFull, setTableContentFull] = useState(null);
  const [reverseCurrentTable, setReverseCurrentTable] = useState(null);
  const [selectedRadioValue, setSelectedRadioValue] = useState('Comparativo');
  const [currentDescription, setCurrentDescription] = useState(null);
  const [currentEvalDates, setCurrentEvalDates] = useState([]);
  const isBiggerThan = useMediaQuery('(min-width: 770px)');
  // const [currentDates, setCurrentDates] = useState([]);

  // Carregar informações
  useEffect(() => {
    const tempArray = [];
    for (let x = 0; x < monitoringData.evaluations.length; x += 1) {
      if (!tempArray.includes(monitoringData.evaluations[x].name, 0)) {
        tempArray.push(monitoringData.evaluations[x].name);
      }
    }
    setFilterSearchOptions(tempArray.length > 0 ? [...tempArray] : []);
  }, [monitoringData]);

  // Seleção do dropdown
  function handleDropdownSelection(item) {
    setEvalSelected(item);

    let contEqual = 0;
    const tempArray = [];
    const tempArrayOfArraySelection = [];
    const tempArrayOfTitles = [];
    const tempArrayOfResults = [];
    const tempArrayOfDates = [];
    // Selecionar opcoes do dropdown baseados nas avaliacoes realizadas
    for (let x = 0; x < monitoringData.evaluations.length; x += 1) {
      if (item === monitoringData.evaluations[x].name) {
        tempArray.push(monitoringData.evaluations[x].content.questions);
        if (tempArrayOfArraySelection.length === 0) {
          tempArrayOfArraySelection.push('Comparativo');
          tempArrayOfArraySelection.push(`Avaliação #${contEqual + 1}`);
          contEqual += 1;
        } else {
          tempArrayOfArraySelection.push(`Avaliação #${contEqual + 1}`);
          contEqual += 1;
        }
        tempArrayOfTitles.push(`Avaliação #${contEqual + 1}`);
        const finalValueForResult =
          typeof monitoringData.evaluations[x].result === 'string'
            ? [monitoringData.evaluations[x].result]
            : monitoringData.evaluations[x].result;
        tempArrayOfResults.push(finalValueForResult);
        tempArrayOfDates.push(monitoringData.evaluations[x].date);
      }
    }
    setCurrentDescription(ExamsEnum[item].description);
    setRadioOptions([...tempArrayOfArraySelection]);
    // Atualizar variavel que recebe valores da tabela baseado também nas avaliacoes
    setCurrentEvalTable([...tempArray]);
    setCurrentEvalResults([...tempArrayOfResults.reverse()]);
    setReverseCurrentTable([...tempArray.reverse()]);
    setCurrentEvalDates([...tempArrayOfDates.reverse()]);
    if (isBiggerThan) {
      handleRadioButtonSelection(
        'Comparativo',
        tempArray,
        tempArrayOfArraySelection,
        tempArray.reverse(),
        tempArrayOfResults,
        tempArrayOfDates,
        item,
      );
    } else {
      handleRadioButtonSelection(
        'Avaliação #1',
        tempArray,
        tempArrayOfArraySelection,
        tempArray.reverse(),
        tempArrayOfResults,
        tempArrayOfDates,
        item,
      );
    }
  }

  function handleRadioButtonSelection(
    item,
    currentTable,
    currentRadios,
    reverseTable,
    results,
    dates,
    currentEval,
  ) {
    let tempIndex = 0;

    // const tempLength = currentTable.length;
    setSelectedRadioValue(item);
    if (item === 'Comparativo') {
      const tempArrayTable = [];
      // renderizar toda a tabela com os valores de currentEvalTable
      for (let x = 0; x < currentTable[0].length; x += 1) {
        const tempArrayLine = [];
        // pegar a linha
        for (let y = currentTable.length - 1; y >= 0; y -= 1) {
          if (y === currentTable.length - 1) {
            if (currentEval !== 'MAL - Registro de Atividade Motora') {
              tempArrayLine.push(currentTable[y][x].text);
              tempArrayLine.push(currentTable[y][x].value);
            } else {
              const tmp = currentTable[y][x].value;
              tempArrayLine.push(currentTable[y][x].text);
              tempArrayLine.push(
                `Quant: ${tmp[0]}+Quali: ${tmp[1]}+Coment: ${tmp[2]}`,
              );
            }
          } else {
            if (currentEval !== 'MAL - Registro de Atividade Motora') {
              tempArrayLine.push(currentTable[y][x].value);
            } else {
              const tmp2 = currentTable[y][x].value;
              tempArrayLine.push(
                `Quant: ${tmp2[0]}+Quali: ${tmp2[1]}+Coment: ${tmp2[2]}`,
              );
            }
          }
        }
        tempArrayTable.push([...tempArrayLine]);
      }
      setTableContentFull([...tempArrayTable]);
      setTableContent(null);
      setTableTitles([
        ...currentRadios.filter(option => option !== 'Comparativo'),
      ]);
      setCurrentResult([...results]);
      // setCurrentDates([...dates]);
    } else {
      // renderizar tabela apenas com valor inserido
      const tempValues = item.split('#');
      tempIndex = parseInt(tempValues[1], 10);

      setTableContent([...reverseTable[tempIndex - 1]]);
      setTableContentFull(null);
      setTableTitles([currentRadios[tempIndex]]);
      setCurrentResult([results[tempIndex - 1]]);
      // setCurrentDates([dates[tempIndex - 1]]);
    }
  }

  return (
    <>
      <S.Subtitles>Instrumento de Avaliação</S.Subtitles>
      <Dropdown
        overlay={
          <Menu style={S.AntdStyles.menuExams}>
            {filterSearchOptions.map((item, index) => (
              <Menu.Item>
                <element
                  key={index}
                  onClick={() => handleDropdownSelection(item)}
                >
                  {item}
                </element>
              </Menu.Item>
            ))}
          </Menu>
        }
        placement="bottomCenter"
      >
        <Button style={S.AntdStyles.ButtonExams}>
          {evalSelected}{' '}
          <S.ImgDropdown src={ArrowDownGrey} alt="mostrar exames" />
        </Button>
      </Dropdown>
      {currentDescription !== null && currentDescription !== undefined && (
        <S.Subtitles>Exibir</S.Subtitles>
      )}

      <Radio.Group
        value={selectedRadioValue}
        onChange={e =>
          handleRadioButtonSelection(
            e.target.value,
            currentEvalTable,
            radioOptions,
            reverseCurrentTable,
            currentEvalResults,
            currentEvalDates,
            evalSelected,
          )
        }
        style={{ marginTop: '0.5rem', marginBottom: '2rem', width: '70%' }}
      >
        {radioOptions.map(option => {
          if (isBiggerThan) {
            return (
              <Radio key={option} value={option}>
                {option}
              </Radio>
            );
            // eslint-disable-next-line no-else-return
          } else {
            if (option !== 'Comparativo') {
              return (
                <Radio key={option} value={option}>
                  {option}
                </Radio>
              );
            }
          }
          return null;
        })}
      </Radio.Group>

      {currentDescription !== null && currentDescription !== undefined && (
        <>
          <div>
            <S.TitleSession>Descrição</S.TitleSession>
          </div>
          {currentDescription.split('\n').map(str => (
            <S.PDescript>{str}</S.PDescript>
          ))}
        </>
      )}

      <br />

      {evalSelected !== 'Selecione a Avaliação' && (
        <S.TableOfEvaluations id="tab1">
          <thead>
            <tr>
              {tableTitles.length !== 0 && <th> </th>}
              {tableTitles.map((titles, index) => (
                <th key={index}>{titles}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {tableContent !== null &&
              evalSelected !== 'MAL - Registro de Atividade Motora' && (
                <>
                  {tableContent.map((value, index) => (
                    <tr key={index}>
                      <td title={value.text}>{value.text}</td>
                      <td>{value.value}</td>
                    </tr>
                  ))}
                </>
              )}

            {tableContent !== null &&
              evalSelected === 'MAL - Registro de Atividade Motora' && (
                <>
                  {tableContent.map((value, index) => (
                    <tr key={index}>
                      <td title={value.text}>{value.text}</td>
                      <td>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <span>{`Quantitativo: ${value.value[0]}`}</span>
                          <span>{`Qualitativo: ${value.value[1]}`}</span>
                          <span
                            title={value.value[2]}
                          >{`Comentário: ${value.value[2]}`}</span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              )}

            {tableContentFull !== null &&
              evalSelected !== 'MAL - Registro de Atividade Motora' && (
                <>
                  {tableContentFull.map((line, index) => (
                    <tr key={index}>
                      {line.map(value => (
                        <>
                          <td title={value}>{value}</td>
                        </>
                      ))}
                    </tr>
                  ))}
                </>
              )}

            {tableContentFull !== null &&
              evalSelected === 'MAL - Registro de Atividade Motora' && (
                <>
                  {tableContentFull.map((line, index) => (
                    <tr key={index}>
                      {line.map((value, _index) => (
                        <>
                          <td key={_index}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              {value.split('+').map((element, __index) => (
                                <span key={__index} title={element}>
                                  {element.length > 20 &&
                                  element.includes('Coment')
                                    ? `${element.substr(0, 20)}...`
                                    : element}
                                </span>
                              ))}
                            </div>
                          </td>
                        </>
                      ))}
                    </tr>
                  ))}
                </>
              )}

            {currentResult !== null &&
              evalSelected !== 'MAL - Registro de Atividade Motora' &&
              evalSelected !==
                'TFMJT - Teste de Função Manual de Jebsen-Taylor' &&
              evalSelected !== 'Escala McGill para Detecção de Dor' &&
              evalSelected !== 'Escala de Avaliação de Fugl-Meyer' && (
                <>
                  <tr>
                    <td>
                      <strong>Total</strong>
                    </td>
                    {currentResult.map((result, index) => (
                      <td key={index}>{result}</td>
                    ))}
                  </tr>
                </>
              )}

            {currentResult !== null &&
              evalSelected === 'MAL - Registro de Atividade Motora' && (
                <>
                  <tr>
                    <td>
                      <strong>Total</strong>
                    </td>
                    {currentResult.map((result, index) => (
                      <td key={index}>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <span>{`Quanti.: ${result[2]}`}</span>
                          <span>{`Quali.: ${result[1]}`}</span>
                          <span>{`Total: ${result[0]}`}</span>
                        </div>
                      </td>
                    ))}
                  </tr>
                </>
              )}

            {currentResult !== null &&
              evalSelected ===
                'TFMJT - Teste de Função Manual de Jebsen-Taylor' && (
                <>
                  <tr>
                    <td>
                      <strong>Total</strong>
                    </td>
                    {currentResult.map((result, index) => (
                      <td key={index}>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <span title="Virar cartões">{`Virar cartões: ${result[6]}`}</span>
                          <span title="Pegar objetos">{`Pegar objetos: ${result[5]}`}</span>
                          <span title="Pegar feijões">{`Pegar feijões: ${result[4]}`}</span>
                          <span title="Empilhar fichas">{`Empilhar fichas: ${result[4]}`}</span>
                          <span title="Mover latas leves">{`Latas leves: ${result[2]}`}</span>
                          <span title="Mover latas pesadas">{`Latas pesadas: ${result[1]}`}</span>
                          <span>{`Total: ${result[0]}`}</span>
                        </div>
                      </td>
                    ))}
                  </tr>
                </>
              )}

            {currentResult !== null &&
              evalSelected === 'Escala de Avaliação de Fugl-Meyer' && (
                <>
                  <tr>
                    <td>
                      <strong>Total</strong>
                    </td>
                    {currentResult.map((result, index) => (
                      <td key={index}>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <span title="Função Motora MMSS(posição sentada)">{`Func. Motora: ${result[2]}`}</span>
                          <span title="Coordenação/Velocidade MMSS (posição sentada)">{`Coord./Veloc.: ${result[1]}`}</span>
                          <span>{`Total: ${result[0]}`}</span>
                        </div>
                      </td>
                    ))}
                  </tr>
                </>
              )}

            {currentResult !== null &&
              evalSelected === 'Escala McGill para Detecção de Dor' && (
                <>
                  <tr>
                    <td>
                      <strong>Total</strong>
                    </td>
                    {currentResult.map((result, index) => (
                      <td key={index}>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <span>{`Sensorial: ${result[4]}`}</span>
                          <span>{`Afetivo: ${result[3]}`}</span>
                          <span>{`Avaliativo: ${result[2]}`}</span>
                          <span>{`Miscelânea: ${result[1]}`}</span>
                          <span>{`Total: ${result[0]}`}</span>
                        </div>
                      </td>
                    ))}
                  </tr>
                </>
              )}
          </tbody>
        </S.TableOfEvaluations>
      )}
      <br />
    </>
  );
}

/* useEffect(() => {
 const tempArray = [];
  tempArray.push([...tfmjt]);
  tempArray.push([...tfmjt]);
  tempArray.push([...tfmjt]);
  handleRadioButtonSelection(
    'Comparativo',
    tempArray,
    ['Comparativo', 'Avaliação #1', 'Avaliação 2#', 'Avaliação 3#'],
    [tfmjt.reverse()],
  );
}, []); */
