import { takeLatest, call, all, put } from 'redux-saga/effects';
import { notification } from 'antd';

import api from '../../../services/api';

import { setMonitoringData } from './actions';

export function* getMonitoringData({ payload }) {
  try {
    const { patientID } = payload;
    const timezone = new Date().getTimezoneOffset();

    const response = yield call(
      api.get,
      `/patients/${patientID}/monitor/timezone/${timezone}`,
    );
    yield put(setMonitoringData(response.data.monitor));
  } catch (err) {
    notification.error({
      message:
        'Houve um erro ao buscar o catalogo de exercícios, tente novamente!',
    });
  }
}

export default all([
  takeLatest('@monitor/GET_MONITORING_DATA', getMonitoringData),
]);
