/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable no-lonely-if */
import React, { useEffect, useState } from 'react';
import { Menu, Dropdown, Button, Row, Col, Table, Space } from 'antd';

import ArrowDownGrey from 'assets/arrow-down-grey.svg';

import { useSelector } from 'react-redux';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';

import 'moment/locale/pt-br';
import ReactPlayer from 'react-player/lazy';
import Plot from 'react-plotly.js';

import {
  comparativeLayout,
  graph,
  graphWIndex,
  labelAMonitoring,
  labelBMonitoring,
} from 'utils/graphsMonitoringEvals';
import { columns, columnPOne, columnPTwo, columnPThree } from './Columns';
import * as S from './styles';

export function Comparative() {
  const firstChange = useMediaQuery('(min-width: 770px)');

  const computerVision = useSelector(
    state => state.evaluations?.computerVision,
  );
  const [firstEvaluation, setFirstEvaluation] = useState(undefined);
  const [secondEvaluation, setSecondEvaluation] = useState(undefined);
  const [dataSource, setDataSource] = useState([]);
  const [dataSourcePOne, setDataSourcePOne] = useState([]);
  const [dataSourcePTwo, setDataSourcePTwo] = useState([]);
  const [dataSourcePThree, setDataSourcePThree] = useState([]);
  const [hipAngleLeftFirst, setHipAngleLeftFirst] = useState([]);
  const [hipAngleRightFirst, setHipAngleRightFirst] = useState([]);
  const [kneeAngleLeftFirst, setKneeAngleLeftFirst] = useState([]);
  const [kneeAngleRightFirst, setKneeAngleRightFirst] = useState([]);
  const [hipAngleLeftSecond, setHipAngleLeftSecond] = useState([]);
  const [hipAngleRightSecond, setHipAngleRightSecond] = useState([]);
  const [kneeAngleLeftSecond, setKneeAngleLeftSecond] = useState([]);
  const [kneeAngleRightSecond, setKneeAngleRightSecond] = useState([]);

  useEffect(() => {
    if (computerVision.length !== 0 && computerVision.length === 1) {
      setFirstEvaluation(
        computerVision
          .slice(0)
          .reverse()
          .find(item => item.status === 'DONE'),
      );
    }
    if (computerVision.length !== 0 && computerVision.length >= 2) {
      setFirstEvaluation(
        computerVision
          .slice(0)
          .reverse()
          .find(item => item.status === 'DONE'),
      );
      setSecondEvaluation(computerVision.find(item => item.status === 'DONE'));
    }
    if (computerVision.length === 0) {
      setFirstEvaluation({ name: 'Sem Avaliações' });
      setSecondEvaluation({ name: 'Sem Avaliações' });
    }
  }, [computerVision]);

  useEffect(() => {
    // sempre que alguma avaliação selecionada alterar, o valor da tabela será resetado valor será inicialmente resetado.
    setDataSource([]);
    setDataSourcePOne([]);
    setDataSourcePTwo([]);
    const newData = [];
    const newDataPOne = [];
    const newDataPTwo = [];
    const newDataPThree = [];

    const tempFirstObject = {};
    const tmpFObjectPOne = {};
    const tmpFObjectPTwo = {};
    const tmpFObjectPThree = {};

    // FIRST EVALUATION
    if (firstEvaluation?.status === 'DONE') {
      // One
      tempFirstObject.evaluation = firstEvaluation?.name;
      tmpFObjectPOne.evaluation = tempFirstObject.evaluation;

      tempFirstObject.velocity =
        firstEvaluation?.data?.gait_velocity !== '-'
          ? firstEvaluation?.data?.gait_velocity?.toFixed(4)
          : '-';
      tmpFObjectPOne.velocity = tempFirstObject.velocity;

      tempFirstObject.cadency =
        firstEvaluation?.data?.gait_cadence !== '-'
          ? firstEvaluation?.data?.gait_cadence?.toFixed(4)
          : '-';
      tmpFObjectPOne.cadency = tempFirstObject.cadency;

      tempFirstObject.step_length_right =
        firstEvaluation?.data?.step_length_right !== '-'
          ? firstEvaluation?.data?.step_length_right?.toFixed(4)
          : '-';
      tmpFObjectPOne.step_length_right = tempFirstObject.step_length_right;

      // Two
      tempFirstObject.step_length_left =
        firstEvaluation?.data?.step_length_left !== '-'
          ? firstEvaluation?.data?.step_length_left?.toFixed(4)
          : '-';
      tmpFObjectPTwo.step_length_left = tempFirstObject.step_length_left;

      tempFirstObject.stance_right =
        firstEvaluation?.data?.stance_per_right !== '-'
          ? `${(firstEvaluation?.data?.stance_per_right * 100).toFixed(2)}%`
          : '-';
      tmpFObjectPTwo.stance_right = tempFirstObject.stance_right;

      tempFirstObject.stance_left =
        firstEvaluation?.data?.stance_per_left !== '-'
          ? `${(firstEvaluation?.data?.stance_per_left * 100).toFixed(2)}%`
          : '-';
      tmpFObjectPTwo.stance_left = tempFirstObject.stance_left;

      // Three
      tempFirstObject.swing_right =
        firstEvaluation?.data?.swing_per_right !== '-'
          ? `${(firstEvaluation?.data?.swing_per_right * 100).toFixed(2)}%`
          : '-';
      tmpFObjectPTwo.swing_right = tempFirstObject.swing_right;

      tempFirstObject.swing_left =
        firstEvaluation?.data?.swing_per_left !== '-'
          ? `${(firstEvaluation?.data?.swing_per_left * 100).toFixed(2)}%`
          : '-';
      tmpFObjectPTwo.swing_left = tempFirstObject.swing_left;

      newData.push(tempFirstObject);
      newDataPOne.push(tmpFObjectPOne);
      newDataPTwo.push(tmpFObjectPTwo);
      newDataPThree.push(tmpFObjectPThree);

      const xPlotsFirst = [];
      const newXIndexes = [];
      const newXIndexesR = [];

      const fDHipLeft = [];
      const fData = [];
      const fDKneeLeft = [];
      const fDKneeRight = [];

      const tmpIndexes = [];
      const tmpIndexesR = [];

      let cont = 0;
      let contR = 0;
      let lastIndex = 0;
      let lastIndexR = 0;

      const phase_transition_left =
        firstEvaluation?.data?.phase_transition_left;
      const sizeArrLeft = firstEvaluation.data.hip_angle_left.length;

      for (let i = 0; i < sizeArrLeft; i += 1) {
        xPlotsFirst.push(i);
        tmpIndexes.push(i);
        if (phase_transition_left[cont] === i || i === sizeArrLeft - 1) {
          const tmpLocalData = firstEvaluation?.data?.hip_angle_left.slice(
            lastIndex,
            i - 1,
          );
          newXIndexes.push(tmpIndexes.slice(lastIndex, i - 1));

          fDKneeLeft.push(
            firstEvaluation?.data?.knee_angle_left.slice(lastIndex, i - 1),
          );

          fDHipLeft.push(tmpLocalData);

          if (i !== 0) {
            lastIndex = i - 2;
          } else {
            lastIndex = i;
          }
          cont += 1;
        }
      }

      const phase_transition_right =
        firstEvaluation?.data?.phase_transition_right;
      const sizeArrRight = firstEvaluation.data.hip_angle_right.length;

      for (let i = 0; i < sizeArrRight; i += 1) {
        xPlotsFirst.push(i);
        tmpIndexesR.push(i);
        if (phase_transition_right[contR] === i || i === sizeArrRight - 1) {
          const tmpLocalData = firstEvaluation?.data?.hip_angle_right.slice(
            lastIndexR,
            i - 1,
          );
          newXIndexesR.push(tmpIndexesR.slice(lastIndexR, i - 1));

          fDKneeRight.push(
            firstEvaluation?.data?.knee_angle_right.slice(lastIndexR, i - 1),
          );
          fData.push(tmpLocalData);

          if (i !== 0) {
            lastIndexR = i - 2;
          } else {
            lastIndexR = i;
          }
          contR += 1;
        }
      }

      if (firstEvaluation?.data?.hip_angle_left !== '') {
        const updtVal = fDHipLeft.map((dt, index) =>
          graphWIndex(newXIndexes, dt, index),
        );
        setHipAngleLeftFirst(updtVal);
      }
      if (firstEvaluation?.data?.hip_angle_right !== '') {
        const updtVal = fData.map((dt, index) =>
          graphWIndex(newXIndexesR, dt, index),
        );
        setHipAngleRightFirst(updtVal);
      }
      if (firstEvaluation?.data?.knee_angle_left !== '') {
        const updtVal = fDKneeLeft.map((dt, index) =>
          graphWIndex(newXIndexes, dt, index),
        );
        setKneeAngleLeftFirst(updtVal);
      }
      if (firstEvaluation?.data?.knee_angle_right !== '') {
        const updtVal = fDKneeRight.map((dt, index) =>
          graphWIndex(newXIndexesR, dt, index),
        );
        setKneeAngleRightFirst(updtVal);
      }
    }

    // SECOND EVALUATION
    if (secondEvaluation?.status === 'DONE') {
      const tempSecondObject = {};
      const tmpSObjectPOne = {};
      const tmpSObjectPTwo = {};
      const tmpSObjectPThree = {};

      tempSecondObject.evaluation = secondEvaluation?.name;
      tmpSObjectPOne.evaluation = tempSecondObject.evaluation;

      tempSecondObject.velocity =
        secondEvaluation?.data?.gait_velocity !== '-'
          ? secondEvaluation?.data?.gait_velocity?.toFixed(4)
          : '-';
      tmpSObjectPOne.velocity = tempSecondObject.velocity;

      tempSecondObject.cadency =
        secondEvaluation?.data?.gait_cadence !== '-'
          ? secondEvaluation?.data?.gait_cadence?.toFixed(4)
          : '-';
      tmpSObjectPOne.cadency = tempSecondObject.cadency;

      tempSecondObject.step_length_right =
        secondEvaluation?.data?.step_length_right !== '-'
          ? secondEvaluation?.data?.step_length_right?.toFixed(4)
          : '-';
      tmpSObjectPOne.step_length_right = tempSecondObject.step_length_right;

      tempSecondObject.step_length_left =
        secondEvaluation?.data?.step_length_left !== '-'
          ? secondEvaluation?.data?.step_length_left?.toFixed(4)
          : '-';
      tmpSObjectPTwo.step_length_right = tempSecondObject.step_length_right;

      tempSecondObject.stance_right =
        secondEvaluation?.data?.stance_per_right !== '-'
          ? `${(secondEvaluation?.data?.stance_per_right * 100).toFixed(2)}%`
          : '-';
      tmpSObjectPTwo.stance_right = tempSecondObject.stance_right;

      tempSecondObject.stance_left =
        secondEvaluation?.data?.stance_per_left !== '-'
          ? `${(secondEvaluation?.data?.stance_per_left * 100).toFixed(2)}%`
          : '-';
      tmpSObjectPTwo.stance_left = tempSecondObject.stance_left;

      tempSecondObject.swing_right =
        secondEvaluation?.data?.swing_per_right !== '-'
          ? `${(secondEvaluation?.data?.swing_per_right * 100).toFixed(2)}%`
          : '-';
      tmpSObjectPThree.swing_right = tempSecondObject.swing_right;

      tempSecondObject.swing_left =
        secondEvaluation?.data?.swing_per_left !== '-'
          ? `${(secondEvaluation?.data?.swing_per_left * 100).toFixed(2)}%`
          : '-';
      tmpSObjectPThree.swing_left = tempSecondObject.swing_left;

      newData.push(tempSecondObject);
      newDataPOne.push(tmpSObjectPOne);
      newDataPTwo.push(tmpSObjectPTwo);
      newDataPThree.push(tmpSObjectPThree);

      setDataSource(newData);
      setDataSourcePOne(newDataPOne);
      setDataSourcePTwo(newDataPTwo);
      setDataSourcePThree(newDataPThree);

      const xPlotsSecond = [];
      const newXIndexes = [];
      const newXIndexesR = [];

      const sDHipLeft = [];
      const sData = [];
      const sDKneeLeft = [];
      const sDKneeRight = [];

      const tmpIndexes = [];
      const tmpIndexesR = [];

      let cont = 0;
      let contR = 0;
      let lastIndex = 0;
      let lastIndexR = 0;

      const s_phase_transition_left =
        secondEvaluation?.data?.phase_transition_left;
      const secondSizeArrLeft = secondEvaluation.data.hip_angle_left.length;

      for (let i = 0; i < secondSizeArrLeft; i += 1) {
        xPlotsSecond.push(i);

        tmpIndexes.push(i);
        if (
          s_phase_transition_left[cont] === i ||
          i === secondSizeArrLeft - 1
        ) {
          const tmpLocalData = secondEvaluation?.data?.hip_angle_left.slice(
            lastIndex,
            i - 1,
          );
          newXIndexes.push(tmpIndexes.slice(lastIndex, i - 1));
          sDHipLeft.push(tmpLocalData);

          sDKneeLeft.push(
            secondEvaluation?.data?.knee_angle_left.slice(lastIndex, i - 1),
          );

          if (i !== 0) {
            lastIndex = i - 2;
          } else {
            lastIndex = i;
          }

          cont += 1;
        }
      }

      const s_phase_transition_right =
        secondEvaluation?.data?.phase_transition_right;
      const secondSizeArrRight = secondEvaluation.data.hip_angle_right.length;

      for (let i = 0; i < secondSizeArrRight; i += 1) {
        xPlotsSecond.push(i);
        tmpIndexesR.push(i);
        if (
          s_phase_transition_right[contR] === i ||
          i === secondSizeArrRight - 1
        ) {
          const tmpLocalData = secondEvaluation?.data?.hip_angle_right.slice(
            lastIndexR,
            i - 1,
          );
          newXIndexesR.push(tmpIndexesR.slice(lastIndexR, i - 1));
          sData.push(tmpLocalData);

          sDKneeRight.push(
            secondEvaluation?.data?.knee_angle_right.slice(lastIndexR, i - 1),
          );

          if (i !== 0) {
            lastIndexR = i - 2;
          } else {
            lastIndexR = i;
          }

          contR += 1;
        }
      }

      if (secondEvaluation?.data?.hip_angle_left !== '') {
        const updtVal = sDHipLeft.map((dt, index) =>
          graphWIndex(newXIndexes, dt, index),
        );
        setHipAngleLeftSecond(updtVal);
      }

      if (secondEvaluation?.data?.hip_angle_right !== '') {
        const updtVal = sData.map((dt, index) =>
          graphWIndex(newXIndexesR, dt, index),
        );
        setHipAngleRightSecond(updtVal);
      }

      if (secondEvaluation?.data?.knee_angle_left !== '') {
        const updtVal = sDKneeLeft.map((dt, index) =>
          graphWIndex(newXIndexes, dt, index),
        );
        setKneeAngleLeftSecond(updtVal);
      }

      if (secondEvaluation?.data?.knee_angle_right !== '') {
        const updtVal = sDKneeRight.map((dt, index) =>
          graphWIndex(newXIndexesR, dt, index),
        );
        setKneeAngleRightSecond(updtVal);
      }
    }
  }, [firstEvaluation, secondEvaluation]);

  return (
    <Row gutter={[32, 16]} style={{ marginTop: '0.5rem' }}>
      <Col className="gutter-row" span={firstChange ? 12 : 24}>
        <S.Subtitles>Avaliação 1</S.Subtitles>
        <Dropdown
          overlay={
            <Menu activeKey="0" style={S.AntdStyles.menuExams}>
              {computerVision.map(item => (
                <Menu.Item disabled={item.status !== 'DONE'}>
                  <element onClick={() => setFirstEvaluation(item)}>
                    {item.name}
                  </element>
                </Menu.Item>
              ))}
            </Menu>
          }
          placement="bottomCenter"
        >
          <Button style={S.AntdStyles.ButtonExams}>
            {firstEvaluation !== undefined && firstEvaluation.name}
            <S.ImgDropdown src={ArrowDownGrey} alt="mostrar exames" />
          </Button>
        </Dropdown>

        <br />

        {secondEvaluation !== undefined &&
        firstEvaluation !== undefined &&
        computerVision.length !== 0 ? (
          <Col>
            <ReactPlayer
              url={firstEvaluation?.left_side_video_analyzed}
              width={310}
              height={500}
              playing
              loop
              controls
            />
            <br />
            <br />
            <S.Subtitles>Angulação do Joelho lado esquerdo</S.Subtitles>
            <S.ContainerGraphs style={{ marginTop: '1rem' }}>
              <Plot
                data={kneeAngleLeftFirst}
                layout={comparativeLayout}
                config={{
                  displayModeBar: false,
                }}
              />
            </S.ContainerGraphs>

            <S.Subtitles>Angulação do Quadril lado esquerdo</S.Subtitles>
            <S.ContainerGraphs style={{ marginTop: '1rem' }}>
              <Plot
                data={hipAngleLeftFirst}
                layout={comparativeLayout}
                config={{
                  displayModeBar: false,
                  staticPlot: true,
                }}
              />
            </S.ContainerGraphs>

            <ReactPlayer
              url={firstEvaluation?.right_side_video_analyzed}
              width={310}
              height={500}
              playing
              loop
              controls
            />
            <br />
            <br />
            <S.Subtitles>Angulação do Joelho lado direito</S.Subtitles>
            <S.ContainerGraphs style={{ marginTop: '1rem' }}>
              <Plot
                data={kneeAngleRightFirst}
                layout={comparativeLayout}
                config={{
                  displayModeBar: false,
                  staticPlot: true,
                }}
              />
              <S.SubContainerLegends>
                <Space size="small" direction="horizontal">
                  <S.LegendSquare lcolor={labelAMonitoring} />
                  Apoio
                </Space>
                <Space size="small" direction="horizontal">
                  <S.LegendSquare lcolor={labelBMonitoring} />
                  Balanço
                </Space>
              </S.SubContainerLegends>
            </S.ContainerGraphs>
            <S.Subtitles>Angulação do Quadril lado direito</S.Subtitles>
            <S.ContainerGraphs style={{ marginTop: '1rem' }}>
              <Plot
                data={hipAngleRightFirst}
                layout={comparativeLayout}
                config={{
                  displayModeBar: false,
                  staticPlot: true,
                }}
              />
              <S.SubContainerLegends>
                <Space size="small" direction="horizontal">
                  <S.LegendSquare lcolor={labelAMonitoring} />
                  Apoio
                </Space>
                <Space size="small" direction="horizontal">
                  <S.LegendSquare lcolor={labelBMonitoring} />
                  Balanço
                </Space>
              </S.SubContainerLegends>
            </S.ContainerGraphs>
          </Col>
        ) : null}
      </Col>

      <Col className="gutter-row" span={firstChange ? 12 : 24}>
        <S.Subtitles>Avaliação 2</S.Subtitles>
        <Dropdown
          overlay={
            <Menu style={S.AntdStyles.menuExams}>
              {computerVision.map((item, index) => (
                <Menu.Item disabled={item.status !== 'DONE'}>
                  <element onClick={() => setSecondEvaluation(item)}>
                    {item.name}
                  </element>
                </Menu.Item>
              ))}
            </Menu>
          }
          placement="bottomCenter"
        >
          <Button style={S.AntdStyles.ButtonExams}>
            {secondEvaluation !== undefined && secondEvaluation.name}
            <S.ImgDropdown src={ArrowDownGrey} alt="mostrar exames" />
          </Button>
        </Dropdown>

        <br />

        {secondEvaluation !== undefined &&
        firstEvaluation !== undefined &&
        computerVision.length !== 0 ? (
          <Col>
            <ReactPlayer
              url={secondEvaluation?.left_side_video_analyzed}
              width={310}
              height={500}
              playing
              loop
              controls
            />
            <br />
            <br />
            <S.Subtitles>Angulação do Joelho lado esquerdo</S.Subtitles>
            <S.ContainerGraphs style={{ marginTop: '1rem' }}>
              <Plot
                data={kneeAngleLeftSecond}
                layout={comparativeLayout}
                config={{
                  displayModeBar: false,
                }}
              />
            </S.ContainerGraphs>
            <S.Subtitles>Angulação do Quadril lado esquerdo</S.Subtitles>
            <S.ContainerGraphs style={{ marginTop: '1rem' }}>
              <Plot
                data={hipAngleLeftSecond}
                layout={comparativeLayout}
                config={{
                  displayModeBar: false,
                  staticPlot: true,
                }}
              />
            </S.ContainerGraphs>

            <ReactPlayer
              url={secondEvaluation?.right_side_video_analyzed}
              width={310}
              height={500}
              playing
              loop
              controls
            />
            <br />
            <br />
            <S.Subtitles>Angulação do Joelho lado direito</S.Subtitles>
            <S.ContainerGraphs style={{ marginTop: '1rem' }}>
              <Plot
                data={kneeAngleRightSecond}
                layout={comparativeLayout}
                config={{
                  displayModeBar: false,
                  staticPlot: true,
                }}
              />
              <S.SubContainerLegends>
                <Space size="small" direction="horizontal">
                  <S.LegendSquare lcolor={labelAMonitoring} />
                  Apoio
                </Space>
                <Space size="small" direction="horizontal">
                  <S.LegendSquare lcolor={labelBMonitoring} />
                  Balanço
                </Space>
              </S.SubContainerLegends>
            </S.ContainerGraphs>
            <S.Subtitles>Angulação do Quadril lado direito</S.Subtitles>
            <S.ContainerGraphs style={{ marginTop: '1rem' }}>
              <Plot
                data={hipAngleRightSecond}
                layout={comparativeLayout}
                config={{
                  displayModeBar: false,
                  staticPlot: true,
                }}
              />
              <S.SubContainerLegends>
                <Space size="small" direction="horizontal">
                  <S.LegendSquare lcolor={labelAMonitoring} />
                  Apoio
                </Space>
                <Space size="small" direction="horizontal">
                  <S.LegendSquare lcolor={labelBMonitoring} />
                  Balanço
                </Space>
              </S.SubContainerLegends>
            </S.ContainerGraphs>
            {/* <Row style={{ justifyContent: 'space-between' }}>
            <Col>
              <ReactPlayer
                url={firstEvaluation?.left_side_video_analyzed}
                width={420}
                height={260}
                playing
                loop
              />
            </Col>
            <Divider
              type="vertical"
              style={{
                backgroundColor: '#d4d4d4',
                height: '16.2rem',
                marginTop: '2px',
                width: 1,
              }}
            />
            <Col>
              <ReactPlayer
                url={secondEvaluation?.right_side_video_analyzed}
                width={420}
                height={260}
              />
            </Col>
          </Row> */}
          </Col>
        ) : null}
      </Col>

      {secondEvaluation !== undefined &&
      firstEvaluation !== undefined &&
      computerVision.length !== 0 ? (
        <Row style={{ justifyContent: 'center' }}>
          {firstChange && (
            <Table
              dataSource={dataSource}
              columns={columns}
              pagination={{
                defaultPageSize: 500,
              }}
            />
          )}

          {!firstChange && (
            <>
              <Table
                dataSource={dataSourcePOne}
                columns={columnPOne}
                pagination={{
                  defaultPageSize: 500,
                }}
              />

              <Table
                dataSource={dataSourcePTwo}
                columns={columnPTwo}
                pagination={{
                  defaultPageSize: 500,
                }}
              />

              <Table
                dataSource={dataSourcePThree}
                columns={columnPThree}
                pagination={{
                  defaultPageSize: 500,
                }}
              />
              <br />
            </>
          )}
        </Row>
      ) : null}
    </Row>
  );
}
