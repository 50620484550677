import { useState, useEffect } from 'react';
import { Layout, Menu, Drawer } from 'antd';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import PatientController from '../patientController';
import PatientPageHeader from '../pageHeader';
import '../SiderMenu.css';
import * as S from './styles';

import ImgRegisterData from '../../../../../../assets/register-data.svg';

import { ImageRegisterData } from './styles';

const { Sider } = Layout;

function SiderMenu() {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();

  const [navColor1, setNavColor1] = useState('#118E88');

  const setBackGroundColor = key => {
    switch (key) {
      case '1':
        setNavColor1('#00605B');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (location.pathname.includes('edit_info')) {
      setBackGroundColor('1');
    }
  }, []);

  const NavBar = () => (
    <>
      <PatientPageHeader />
      <div className="space-btw" />
      <PatientController />
      <Menu mode="inline" defaultSelectedKeys={['1']} style={S.AntdStyles.menu}>
        <Menu.Item
          key="1"
          icon={<ImageRegisterData src={ImgRegisterData} />}
          style={{
            display: 'flex',
            alignItems: 'center',
            background: navColor1,
            color: 'white',
            width: '90%',
            borderRadius: '3px',
            marginBottom: '4%',
            marginTop: '4%',
          }}
          onClick={() => {
            setBackGroundColor('1');
            history.push(`/${id}/edit_info`);
          }}
          className="teste"
        >
          Dados Cadastrais
        </Menu.Item>
      </Menu>
    </>
  );

  const [toggled, setToggled] = useState(false);
  // showCollapsed tem q ficar false > 990px e true < 990px, 990px é quando o sider colapsa
  const [showCollapsed, setShowCollapsed] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 990;

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResizeWindow);
    return () => {
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, []);

  useEffect(() => {
    if (width > breakpoint) {
      setShowCollapsed(false);
    } else {
      setShowCollapsed(true);
    }
  }, [width]);

  return (
    <>
      <Drawer
        placement="left"
        onClose={() => setToggled(false)}
        closable={false}
        visible={toggled}
        bodyStyle={{ backgroundColor: '#F7F7F7', padding: '15px' }}
        key="left"
        width={260}
      >
        <NavBar />
      </Drawer>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        collapsed={showCollapsed}
        style={S.AntdStyles.sider}
        zeroWidthTriggerStyle={{ backgroundColor: '#118E88' }}
        onCollapse={(collapsed, type) => {
          if (type === 'clickTrigger' && collapsed) {
            setToggled(false);
          } else if (type === 'clickTrigger' && !collapsed) {
            setToggled(true);
          }
        }}
      >
        <NavBar />
      </Sider>
    </>
  );
}

export default SiderMenu;
