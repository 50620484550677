import { Modal, Button } from 'antd';

import { useSelector, useDispatch } from 'react-redux';
import { sendMsgProfModal } from 'store/modules/professional/actions';

import closeIcon from 'assets/close.svg';

import * as S from './styles';

export function MsgToProfModal() {
  const dispatch = useDispatch();
  const isToShowModal = useSelector(state => state.professional.sendMsgModal);

  function handleOk() {
    dispatch(sendMsgProfModal(false));
  }

  return (
    <Modal
      title="Convite enviado"
      visible={isToShowModal}
      onOk={() => handleOk()}
      onCancel={() => handleOk()}
      width={400}
      height={250}
      closeIcon={
        <img src={closeIcon} alt="close" style={S.AntdStyles.iconClose} />
      }
      footer={[
        <Button
          type="primary"
          onClick={() => handleOk()}
          style={{
            width: '100px',
            borderRadius: '5px',
            backgroundColor: '#118E88',
            borderColor: '#118E88',
            color: 'white',
          }}
        >
          Ok
        </Button>,
      ]}
    >
      <S.Container>
        <p>
          <b>
            Um email foi enviado para o profissional convidando-o para o
            Monitore.
          </b>
        </p>
        <p>
          <b>
            Quando ele criar um perfil no Monitore poderá ingressar na sua
            clínica.
          </b>
        </p>
      </S.Container>
    </Modal>
  );
}
