/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/alt-text */
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  Row,
  Col,
  notification,
  Button,
  Dropdown,
  Menu,
  InputNumber,
} from 'antd';
import { uniqueId } from 'lodash';

import { Formik } from 'formik';
import * as Yup from 'yup';
import fileSize from 'filesize';

import 'moment/locale/pt-br';

import closeIcon from 'assets/close.svg';

import { useRef, useState } from 'react';
import { ComputerVisionArray } from 'utils/computerVision';
import { addComputerVisionEvaluation } from 'store/modules/evaluations/actions';

import * as S from './styles';
import './styles.css';
import Upload from './Upload';

export function AddVideoModal({ isModalVisible, handleModalFalse }) {
  const firstChange = useMediaQuery('(min-width: 770px)');
  const dispatch = useDispatch();
  const formikRef = useRef();
  const [pathLeftVideo, setPathLeftVideo] = useState('');
  const [pathRightVideo, setPathRightVideo] = useState('');
  const [leftVideo, setLeftVideo] = useState(null);
  const [rightVideo, setRightVideo] = useState(null);
  const patientId = useSelector(state => state.patient?.patientDetails?.id);
  const formikGeneralData = {
    initialValues: {
      activity: 'Marcha',
      activityId: 'GAIT',
      leg_length: 20.0,
      left_video: '',
      right_video: '',
      patient: '',
    },
    validationSchema: Yup.object({
      activity: Yup.string().required('É obrigatório'),
      leg_length: Yup.number().required('É obrigatório'),
    }),
  };

  const handleUploadLeft = files => {
    const uploadedFiles = files.map(file => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: fileSize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    uploadedFiles.forEach(uploadedFile => {
      const data = new FormData();
      data.append('file', uploadedFile.file);
      setPathLeftVideo(uploadedFile.file.path);
      setLeftVideo(uploadedFile.file);
    });
  };

  const handleUploadRight = files => {
    const uploadedFiles = files.map(file => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: fileSize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    uploadedFiles.forEach(uploadedFile => {
      const data = new FormData();
      data.append('file', uploadedFile.file);
      setPathRightVideo(uploadedFile.file.path);
      setRightVideo(uploadedFile.file);
    });
  };

  return (
    <Formik
      initialValues={formikGeneralData.initialValues}
      validationSchema={formikGeneralData.validationSchema}
      innerRef={formikRef}
      onSubmit={(values, { setErrors }) => {
        values.left_video = leftVideo;
        values.right_video = rightVideo;
        values.patient = patientId;
        if (values.left_video === null) {
          setErrors({ left_video: 'Insira o vídeo do lado esquerdo' });
        }
        if (values.right_video === null) {
          setErrors({ right_video: 'Insira o vídeo do lado direito' });
        }
        if (values.left_video !== null && values.right_video !== null) {
          dispatch(addComputerVisionEvaluation(values));
        }
        setTimeout(() => {
          handleModalFalse();
        }, 1000);

        notification.success({
          message: 'Seus vídeos estão sendo processados, aguarde.',
        });
      }}
    >
      {props => (
        <Modal
          title="Enviar Vídeos para Análise"
          visible={isModalVisible}
          onOk={() => handleModalFalse()}
          onCancel={() => handleModalFalse()}
          style={{
            borderRadius: '5px',
          }}
          centered
          bodyStyle={{ borderRadius: '20px' }}
          width={650}
          closeIcon={
            <img src={closeIcon} alt="close" style={S.AntdStyles.iconClose} />
          }
          footer={[
            <Button
              type="primary"
              onClick={() => props.handleSubmit()}
              style={{
                width: '140px',
                borderRadius: '5px',
                backgroundColor: '#118E88',
                borderColor: '#118E88',
              }}
            >
              Enviar
            </Button>,
          ]}
        >
          <S.ContainerModal>
            <Row gutter={[32, 16]}>
              <Col className="gutter-row" span={firstChange ? 12 : 24}>
                <S.LabelText className="input-label">
                  Atividade
                  <Dropdown
                    overlay={
                      <Menu>
                        {ComputerVisionArray.map(item => (
                          <Menu.Item>
                            <element
                              onClick={() => {
                                props.setFieldValue('activity', item.value);
                                props.setFieldValue('activityId', item.label);
                              }}
                            >
                              {item.value}
                            </element>
                          </Menu.Item>
                        ))}
                      </Menu>
                    }
                    placement="bottomCenter"
                  >
                    <Button style={S.AntdStyles.ButtonTypePayment}>
                      {props.values.activity}
                    </Button>
                  </Dropdown>
                  {props.touched.activity && props.errors.activity ? (
                    <S.ErrorMessage>{props.errors.activity}</S.ErrorMessage>
                  ) : null}
                </S.LabelText>
              </Col>
              <Col className="gutter-row" span={firstChange ? 12 : 24}>
                <S.LabelText className="input-label">
                  Comprimento da perna
                  <InputNumber
                    style={S.AntdStyles.inputNumber}
                    defaultValue="20"
                    min="0"
                    max="1000"
                    step="0.1"
                    stringMode
                    onChange={value => {
                      props.setFieldValue('leg_length', value);
                    }}
                    formatter={value => `${value}cm`}
                    parser={value => value.replace('cm', '')}
                  />
                </S.LabelText>
              </Col>
              <Col className="gutter-row" span={24}>
                <S.LabelText className="input-label">
                  Vídeo | Lado Direito{' '}
                  {pathRightVideo === '' ? '' : `- ${pathRightVideo}`}
                  <S.AssetsView>
                    <Upload onUpload={handleUploadRight} />
                  </S.AssetsView>
                  {props.touched.right_video && props.errors.right_video ? (
                    <S.ErrorMessage>{props.errors.right_video}</S.ErrorMessage>
                  ) : null}
                </S.LabelText>
              </Col>
              <Col className="gutter-row" span={24}>
                <S.LabelText className="input-label">
                  Vídeo | Lado Esquerdo{' '}
                  {pathLeftVideo === '' ? '' : `- ${pathLeftVideo}`}
                  <S.AssetsView>
                    <Upload onUpload={handleUploadLeft} />
                  </S.AssetsView>
                  {props.touched.left_video && props.errors.left_video ? (
                    <S.ErrorMessage>{props.errors.left_video}</S.ErrorMessage>
                  ) : null}
                </S.LabelText>
              </Col>
            </Row>
          </S.ContainerModal>
        </Modal>
      )}
    </Formik>
  );
}
