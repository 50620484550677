import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Button, InputNumber, Col } from 'antd';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateEvaluation } from 'store/modules/evaluations/actions';
import SavedAnswerComponent from 'components/SavedAnswer';

import * as S from './styles';

function TCB({ title, current_eval }) {
  const dispatch = useDispatch();
  const { id } = useParams();

  const qts = current_eval?.content?.questions;

  const Question = [
    'Número de blocos transportados com o membro saudável (1 min)',
    'Número de blocos transportados com o membro afetado (1 min)',
  ];

  const [formValues, setFormValues] = useState(
    current_eval?.content?.questions.length > 0
      ? Question.map((item, index) => ({
          index: index + 1,
          text: index === 0 ? 'Saudável' : 'Afetado',
          value: parseInt(current_eval.content.questions[index].value, 10),
        }))
      : Question.map((item, index) => ({
          index: index + 1,
          text: index === 0 ? 'Saudável' : 'Afetado',
          value: '',
        })),
  );

  function updateValues(isFinal) {
    const tempFormValue = {
      id: current_eval?.id,
      isFinished: isFinal,
      name: title,
      professionalId: current_eval?.professionalId,
      result: null,
      content: {
        questions: formValues,
      },
    };
    dispatch(updateEvaluation(tempFormValue, id));
    if (isFinal) {
      setFormValues(
        Question.map((item, index) => ({
          index: index + 1,
          text: index === 0 ? 'Saudável' : 'Afetado',
          value: '',
        })),
      );
    }
  }

  function updateFormValues(index, value) {
    setFormValues([
      {
        index: 1,
        text: 'Saudável',
        value: index === 0 ? value : formValues[0].value,
      },
      {
        index: 2,
        text: 'Afetado',
        value: index === 1 ? value : formValues[1].value,
      },
    ]);
  }

  useEffect(() => {
    const valuesFormValues = Object.values(formValues);
    if (valuesFormValues.find(item => item.value !== '')) {
      updateValues(false);
    }
  }, [formValues]);

  return (
    <Formik
      initialValues={{
        Answer1: qts[0] !== undefined ? qts[0].value : '',
        Answer2: qts[1] !== undefined ? qts[1].value : '',
      }}
      onSubmit={(values, actions) => {
        // eslint-disable-next-line prefer-const
        updateValues(true);

        let valuesSubmit = {
          name: title,
          content: {
            questions: [],
          },
        };

        Question.forEach((_, index) => {
          valuesSubmit.content.questions.push({
            index: index + 1,
            text: index === 0 ? 'Saudável' : 'Afetado',
            value: values[`Answer${index + 1}`],
          });
        });
        valuesSubmit = null;

        actions.resetForm({
          values: {
            Answer1: '',
            Answer2: '',
          },
        });
      }}
    >
      {props => (
        <>
          <S.ContainerQuestionSixMinWalk>
            <Col>
              {Question.map(
                (item, index) =>
                  item && (
                    <div style={{ marginBottom: '1rem' }}>
                      <S.LabelText>{item}</S.LabelText>
                      <InputNumber
                        min={0}
                        style={S.AntdStyles.inputNumber}
                        defaultValue={formValues[index].value}
                        onChange={e => {
                          props.setFieldValue('Answer1', e);
                          updateFormValues(index, e);
                        }}
                      />
                    </div>
                  ),
              )}
              <SavedAnswerComponent
                style={{ marginLeft: '4px' }}
                resource="hasSpecializedEvaluation"
                propsFormik={props}
                fieldName="Answer1"
              />
            </Col>
          </S.ContainerQuestionSixMinWalk>
          <Button
            type="primary"
            htmlType="submit"
            onClick={props.handleSubmit}
            style={S.AntdStyles.ButtonSave}
          >
            Salvar
          </Button>
        </>
      )}
    </Formik>
  );
}

export default TCB;
