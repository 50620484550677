export const PropsProfessionEnum = {
  NOTDEFINED: 'notdefined',
  PHYSIOTHERAPY: 'physiotherapy',
  OCCUPATIONALTHERAPIST: 'occupationaltherapist',
  PHYSICALEDUCATION: 'physicaleducation',
  OTHERPROFESSION: 'otherprofession',
};

export const PropsProfessionArray = [
  'PHYSIOTHERAPY',
  'OCCUPATIONALTHERAPIST',
  'PHYSICALEDUCATION',
  'OTHERPROFESSION',
];

export const PropsProfessionArrayWithoutOther = [
  'PHYSIOTHERAPY',
  'OCCUPATIONALTHERAPIST',
  'PHYSICALEDUCATION',
];

export const PropsProfessionTranslated = [
  {
    type: PropsProfessionEnum.NOTDEFINED,
    pt: 'não definido',
  },
  {
    type: PropsProfessionEnum.PHYSIOTHERAPY,
    pt: 'Fisioterapeuta',
  },
  {
    type: PropsProfessionEnum.OCCUPATIONALTHERAPIST,
    pt: 'Terapeuta ocupacional',
  },
  {
    type: PropsProfessionEnum.PHYSICALEDUCATION,
    pt: 'Educador Físico',
  },
  {
    type: PropsProfessionEnum.OTHERPROFESSION,
    pt: 'Outro?',
  },
];

export const ProfessionTranslated = profession => {
  const result = PropsProfessionTranslated.find(p => p.type === profession).pt;
  return result;
};
