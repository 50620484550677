import React from 'react';
import { Row, Col, Input, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import './styles.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import 'moment/locale/pt-br';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import { saveProfessionalInfo } from 'store/modules/clinic/actions';
import InputMask from 'react-input-mask';
import { maskBuilderCPFCNPJ } from 'utils/maskValidator';
import * as S from './styles';

function FormReceptionistEdit() {
  const infoDetails = useSelector(state => state.clinic.infoDetails);
  const typeInfoDetails = useSelector(state => state.clinic.typeInfoDetails);

  const dispatch = useDispatch();
  const firstChange = useMediaQuery('(min-width: 770px)');

  const formikGeneralData = {
    initialValues: {
      name: infoDetails?.name,
      email: infoDetails?.email,
      document: infoDetails?.document,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('É obrigatório'),
      email: Yup.string().email('E-mail inválido'),
      document: Yup.string().required('É obrigatório'),
    }),
    onSubmit: values => {
      dispatch(saveProfessionalInfo(values, typeInfoDetails));
    },
  };

  return (
    <Formik
      initialValues={formikGeneralData.initialValues}
      validationSchema={formikGeneralData.validationSchema}
      onSubmit={values => formikGeneralData.onSubmit(values)}
    >
      {props => (
        <div style={{ paddingBottom: '3rem' }}>
          <S.TitlePage>Informações da Conta</S.TitlePage>

          <S.DivSession>
            <S.TitleSession>Dados Cadastrais</S.TitleSession>
          </S.DivSession>

          <Row gutter={[32, 16]}>
            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Nome
                <Input
                  style={S.AntdStyles.marginTop}
                  id="name"
                  name="name"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.name}
                  onBlur={props.handleBlur}
                />
              </S.LabelText>
              {props.touched.name && props.errors.name ? (
                <S.ErrorMessage>{props.errors.name}</S.ErrorMessage>
              ) : null}
            </Col>

            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                E-mail
                <Input
                  style={S.AntdStyles.marginTop}
                  id="email"
                  name="email"
                  type="text"
                  disabled
                  onChange={props.handleChange}
                  value={props.values.email}
                  onBlur={props.handleBlur}
                />
                {props.touched.email && props.errors.email ? (
                  <S.ErrorMessage>{props.errors.email}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>
            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                CPF ou CNPJ
                <InputMask
                  mask={maskBuilderCPFCNPJ(props.values.document)}
                  style={S.AntdStyles.inputMask}
                  defaultValue={
                    props.values.document !== undefined
                      ? props.values.document
                      : ''
                  }
                  onChange={e =>
                    props.setFieldValue(
                      'document',
                      e.target.value.replace(/\D/g, ''),
                    )
                  }
                />
                {props.touched.document && props.errors.document ? (
                  <S.ErrorMessage>{props.errors.document}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>
          </Row>

          <Button
            type="primary"
            htmlType="submit"
            onClick={props.handleSubmit}
            style={S.AntdStyles.ButtonSave}
          >
            Salvar Alterações
          </Button>
          {/* <Divider />
          <S.DeleteText>
            Você pode deletar a conta deste usuário por meio do botão abaixo. No
            entanto, esta opção exclui também os dados e registros relacionados
            a este perfil. Para apenas desativá-lo temporariamente mantendo as
            suas informações, clique em “Arquivar” no menu ao lado.
          </S.DeleteText>
          <Button
            type="secondary"
            htmlType="submit"
            onClick={props.handleSubmit}
            style={{
              marginTop: 20,
              backgroundColor: 'transparent',
              borderColor: '#118E88',
              borderRadius: 5,
              color: '#118E88',
            }}
          >
            Apagar Conta
          </Button> */}
        </div>
      )}
    </Formik>
  );
}

export default FormReceptionistEdit;
