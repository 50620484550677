export const PropsProfileEnum = {
  PATIENT: 'PATIENT',
  THERAPIST: 'THERAPIST',
  CLINIC: 'CLINIC',
  RECEPTIONIST: 'RECEPTIONIST',
  NOTDEFINED: 'NOTDEFINED',
  CLINIC_ADMINISTRATOR: 'CLINIC_ADMINISTRATOR',
};

export const PropsProfileTranslated = [
  {
    type: PropsProfileEnum.PATIENT,
    pt: 'Paciente',
  },
  {
    type: PropsProfileEnum.THERAPIST,
    pt: 'Profissional de saúde',
  },
  {
    type: PropsProfileEnum.CLINIC,
    pt: 'Clínica',
  },
  {
    type: PropsProfileEnum.RECEPTIONIST,
    pt: 'Recepcionista',
  },
  {
    type: PropsProfileEnum.CLINIC_ADMINISTRATOR,
    pt: 'Administrador da clínica',
  },
];

export const PropsProfileArray = [
  'PATIENT',
  'THERAPIST',
  'CLINIC',
  'RECEPTIONIST',
];

export const PropsProfileTranslatedWithClinic = {
  PATIENT: 'Paciente',
  THERAPIST: 'Profissional de Saúde',
  CLINIC: 'Clínica',
  RECEPTIONIST: 'Recepcionista',
  NOTDEFINED: 'Não definido',
  CLINIC_ADMINISTRATOR: 'Administrador',
};

// 'CLINIC'
