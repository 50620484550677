import { Row, Col } from 'antd';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import * as S from './styles';

export function Protocols({ protocols = [] }) {
  const isBiggerThan = useMediaQuery('(min-width: 770px)');

  if (protocols.length === 0) {
    return (
      <S.ContainerNotFound>
        <S.SpanNotFound>Não há protocolos</S.SpanNotFound>
      </S.ContainerNotFound>
    );
  }

  return (
    <S.Container>
      <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
        {protocols.map(item => (
          <Col className="gutter-row" span={isBiggerThan ? 8 : 16}>
            {item}
          </Col>
        ))}
      </Row>
    </S.Container>
  );
}
