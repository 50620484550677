import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  height: 100vh;
  background: transparent;
  padding-left: 2rem;

  text-align: left;
  letter-spacing: 0px;
  opacity: 1;

  strong {
    color: var(--davysGrey);
    font: normal normal bold 14px/16px Nunito Sans;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }

  span {
    font: normal normal normal 14px/16px Nunito Sans;

    &.btn-start-practice {
      color: #fff;
    }

    &.regular-text {
      color: var(--jet);
    }
  }

  ul {
    display: flex;
    width: 100%;
    height: 150px;
    list-style-type: none;
    overflow-x: scroll;
    margin: 0;

    li {
      width: 150px;
      height: 90%;
      background: var(--lavender);

      & + li {
        margin-left: 1rem;
      }
    }
  }
`;

export const ContainerInfoDontHaveEvents = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  height: 100vh;
  background: transparent;
  padding-left: 2rem;

  text-align: left;
  letter-spacing: 0px;
  opacity: 1;

  strong {
    color: var(--davysGrey);
    font: normal normal bold 16px/19px Nunito Sans;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }
`;

export const AntdStyles = {
  ButtonStart: {
    background: 'var(--darkCyan) 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    opacity: 1,
    width: '10rem',
    marginTop: '2rem',
    marginBottom: '2rem',
    fontWeight: 'bold',
    fontColor: 'white',
  },
};
