import { Layout } from 'antd';
import AppHeader from 'components/Header';
import SiderMenu from 'components/Siders/Professional/Sider';

function Patients() {
  return (
    <Layout>
      <AppHeader />
      <SiderMenu />
    </Layout>
  );
}

export default Patients;
