/* eslint-disable no-unused-vars */
import { Input, Button, DatePicker, Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';
import moment from 'moment';
import localePTDate from 'antd/lib/locale/pt_BR';
import * as S from './styles';

import { savePatient } from '../../../../../../store/modules/patient/actions';

import 'moment/locale/pt-br';

function PatientProfile() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);

  const { TextArea } = Input;

  return (
    <>
      <S.TextLogin>Obrigado pelo interesse!</S.TextLogin>

      <S.TextLoginDesc>
        Se o seu profissional lhe enviou o código de acesso, baixe o app
        Neurobots na{' '}
        <a
          href="https://play.google.com/store/apps/details?id=br.com.neurobots.monitore"
          target="_blank"
          rel="noreferrer"
        >
          Play Store
        </a>{' '}
        ou{' '}
        <a
          href="https://apps.apple.com/br/app/monitore-app/id1554152979"
          target="_blank"
          rel="noreferrer"
        >
          Apple Store
        </a>
        . Se estiver em busca de profissionais, preencha o formulário abaixo.
      </S.TextLoginDesc>

      <Formik
        initialValues={{
          name: '',
          email: user?.email ? user.email : '',
          cellphone: '',
          feeling: '',
          born: '',
          hasProfessional: '',
        }}
        validationSchema={Yup.object({
          name: Yup.string().required('É obrigatório'),
          email: Yup.string()
            .email('E-mail inválido')
            .required('É obrigatório'),
          cellphone: Yup.string().required('É obrigatório'),
          feeling: Yup.string().required('É obrigatório'),
          born: Yup.string().required('É obrigatório'),
          hasProfessional: Yup.string().required('É obrigatório'),
        })}
        onSubmit={values => dispatch(savePatient(values))}
      >
        {props => (
          <>
            <S.DescInput>Nome completo</S.DescInput>
            <Input
              placeholder="Nome completo"
              style={S.AntdStyles.inputForm}
              id="name"
              name="name"
              type="text"
              onChange={props.handleChange}
              value={props.values.name}
              onBlur={props.handleBlur}
            />
            {props.touched.name && props.errors.name ? (
              <S.ErrorMessage>{props.errors.name}</S.ErrorMessage>
            ) : null}

            <S.DescInput>E-mail</S.DescInput>
            <Input
              placeholder="exemplo@exemplo.com.br"
              style={S.AntdStyles.inputForm}
              id="email"
              name="email"
              type="email"
              onChange={props.handleChange}
              value={props.values.email}
              onBlur={props.handleBlur}
            />
            {props.touched.email && props.errors.email ? (
              <S.ErrorMessage>{props.errors.email}</S.ErrorMessage>
            ) : null}

            <S.DescInput>Telefone</S.DescInput>
            <InputMask
              mask="(99)99999-9999"
              style={S.AntdStyles.inputMask}
              value={props.values.cellphone}
              onChange={e => props.setFieldValue('cellphone', e.target.value)}
            />
            {props.touched.cellphone && props.errors.cellphone ? (
              <S.ErrorMessage>{props.errors.cellphone}</S.ErrorMessage>
            ) : null}

            <S.DescInput>Data de Nascimento</S.DescInput>
            <DatePicker
              onChange={(_, dateString) =>
                props.setFieldValue(
                  'born',
                  moment(dateString, 'DD-MM-YYYY').format('YYYY-MM-DD') ===
                    'Data Inválida'
                    ? null
                    : moment(dateString, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                )
              }
              style={S.AntdStyles.inputForm}
              defaultValue={
                props.values.born !== ''
                  ? moment(props.values.born, 'YYYY-MM-DD')
                  : null
              }
              locale={localePTDate}
              format="DD-MM-YYYY"
              allowClear={false}
            />
            {props.touched.born && props.errors.born ? (
              <S.ErrorMessage>{props.errors.born}</S.ErrorMessage>
            ) : null}

            <S.DescInput>O que está sentindo?</S.DescInput>
            <TextArea
              rows={4}
              placeholder="O que te fez sentir a necessidade de iniciar um tratamento fisioterapêutico?"
              style={S.AntdStyles.inputTextArea}
              value={props.values.feeling}
              onChange={e => props.setFieldValue('feeling', e.target.value)}
            />

            {props.touched.feeling && props.errors.feeling ? (
              <S.ErrorMessage>{props.errors.feeling}</S.ErrorMessage>
            ) : null}

            <S.DescInput>Possui profissional?</S.DescInput>
            <Radio.Group
              onChange={e =>
                props.setFieldValue('hasProfessional', e.target.value)
              }
              value={props.values.hasProfessional}
              style={{ marginTop: '1rem' }}
            >
              <Radio style={S.AntdStyles.radioStyle} value="Sim">
                Sim
              </Radio>
              <Radio style={S.AntdStyles.radioStyle} value="Não">
                Não
              </Radio>
            </Radio.Group>
            {props.touched.hasProfessional && props.errors.hasProfessional ? (
              <S.ErrorMessage>{props.errors.hasProfessional}</S.ErrorMessage>
            ) : null}

            <Button
              type="primary"
              htmlType="submit"
              onClick={props.handleSubmit}
              style={S.AntdStyles.buttonLogin}
            >
              Enviar
            </Button>
          </>
        )}
      </Formik>
    </>
  );
}

export default PatientProfile;
