import { combineReducers } from 'redux';

import auth from './auth/reducer';
import patient from './patient/reducer';
import anamnesis from './anamnesis/reducer';
import goals from './goals/reducer';
import agenda from './agenda/reducer';
import professional_agenda from './professional_agenda/reducer';
import evaluations from './evaluations/reducer';
import protocol from './protocol/reducer';
import messages from './messages/reducer';
import monitor from './monitor/reducer';
import professional from './professional/reducer';
import clinic from './clinic/reducer';
import clinicagenda from './clinicagenda/reducer';
import video_call from './video_call/reducer';
import professional_content from './professional_content/reducer';
import payment from './payment/reducer';
import CEP from './CEP/reducer';

export default combineReducers({
  auth,
  patient,
  goals,
  anamnesis,
  agenda,
  professional_agenda,
  evaluations,
  protocol,
  messages,
  monitor,
  professional,
  video_call,
  professional_content,
  payment,
  clinic,
  clinicagenda,
  CEP,
});
