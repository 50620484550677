/* eslint-disable import/no-unresolved */
/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import React, { useRef, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import ReCAPTCHA from 'react-google-recaptcha';

import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

import { Row, Col, Input, Radio, Button, AutoComplete, Space } from 'antd';
import InputMask from 'react-input-mask';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { purchasePlan, purchasePackage } from 'store/modules/payment/actions';

import PlanContext from '../../Context/PlanContext';

import * as S from './styles';

export function PaymentForm({ self, reason, contractId }) {
  const firstChange = useMediaQuery('(min-width: 770px)');
  const dispatch = useDispatch();
  const { changeScreen } = useContext(PlanContext);
  const loading = useSelector(state => state.payment.loading);
  const payment = useSelector(
    state => state.payment.subsc_status.subscriptionStatus.payment,
  );
  const captchatRef = useRef(null);

  const filterAutoComplete = [
    {
      value: 'Visa',
    },
    {
      value: 'Mastercard',
    },
    {
      value: 'Elo',
    },
    {
      value: 'Amex',
    },
    {
      value: 'Hipercard',
    },
  ];

  const formikGeneralData = {
    initialValues: {
      name: self.name,
      email: self.email,
      cpf: self.document,
      cellPhone:
        self.phone_number === undefined
          ? self.phone_number1
          : self.phone_number,
      cardNumber: '',
      cardName: '',
      cardFlag: '',
      cardUsageTime: '',
      cardSecuryCode: '',
      contractId,
      existingCard: false,
      captcha: false,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('É obrigatório'),
      email: Yup.string().email('E-mail inválido').required('É obrigatório'),
      cpf: Yup.string().required('É obrigatório'),
      cellphone: Yup.string(),
      cardNumber: Yup.string().when('existingCard', {
        is: false,
        then: Yup.string().required('É obrigatório'),
      }),
      cardName: Yup.string().when('existingCard', {
        is: false,
        then: Yup.string().required('É obrigatório'),
      }),
      cardFlag: Yup.string().when('existingCard', {
        is: false,
        then: Yup.string().required('É obrigatório'),
      }),
      cardUsageTime: Yup.string().when('existingCard', {
        is: false,
        then: Yup.string().required('É obrigatório'),
      }),
      cardSecuryCode: Yup.string().when('existingCard', {
        is: false,
        then: Yup.string().required('É obrigatório'),
      }),
      existingCard: Yup.boolean().required('É obrigatório'),
      captcha: Yup.boolean()
        .isTrue('Aceite o captcha')
        .required('É obrigatório'),
    }),
  };

  function onChangeRecaptcha(propsFormik) {
    if (captchatRef.current.getValue()) {
      propsFormik.setFieldValue('captcha', true);
    } else {
      propsFormik.setFieldValue('captcha', false);
    }
  }

  return (
    <Formik
      initialValues={formikGeneralData.initialValues}
      validationSchema={formikGeneralData.validationSchema}
      onSubmit={values => {
        if (
          reason.includes('Teleconsultas') ||
          reason.includes('Profissionais')
        ) {
          dispatch(purchasePackage(values));
        } else {
          dispatch(purchasePlan(values));
        }
      }}
    >
      {props => (
        <S.ContainerRightSection>
          {firstChange ? '' : <br />}
          <S.TitlePage>Confirme seus dados</S.TitlePage>
          <br />
          <Row gutter={[32, 32]}>
            <Col className="gutter-row" span={firstChange ? 12 : 24}>
              <S.LabelText>
                Nome
                <Input
                  id="name"
                  name="name"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.name}
                  onBlur={props.handleBlur}
                />
              </S.LabelText>
              {props.touched.name && props.errors.name ? (
                <S.ErrorMessage>{props.errors.name}</S.ErrorMessage>
              ) : null}
            </Col>

            <Col className="gutter-row" span={firstChange ? 12 : 24}>
              <S.LabelText>
                CPF
                <InputMask
                  mask="999.999.999-99"
                  defaultValue={props.values.cpf}
                  style={S.AntdStyles.inputMask}
                  onChange={e => props.setFieldValue('cpf', e.target.value)}
                />
              </S.LabelText>
              {props.touched.cpf && props.errors.cpf ? (
                <S.ErrorMessage>{props.errors.cpf}</S.ErrorMessage>
              ) : null}
            </Col>

            <Col className="gutter-row" span={firstChange ? 12 : 24}>
              <S.LabelText>
                Email
                <Input
                  id="email"
                  name="email"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.email}
                  onBlur={props.handleBlur}
                  disabled
                />
              </S.LabelText>
              {props.touched.email && props.errors.email ? (
                <S.ErrorMessage>{props.errors.email}</S.ErrorMessage>
              ) : null}
            </Col>

            {firstChange ? (
              <Col
                className="gutter-row"
                span={firstChange ? 12 : 24}
                style={{ visibility: 'hidden' }}
              >
                <S.LabelText>
                  NotVisible
                  <Input
                    id="name"
                    name="name"
                    type="text"
                    onChange={props.handleChange}
                    value={null}
                    onBlur={props.handleBlur}
                  />
                </S.LabelText>
              </Col>
            ) : (
              ''
            )}

            <Col className="gutter-row" span={firstChange ? 12 : 24}>
              <S.LabelText>
                Celular
                <InputMask
                  mask="(99)999999999"
                  defaultValue={props.values.cellPhone}
                  style={S.AntdStyles.inputMask}
                  onChange={e =>
                    props.setFieldValue('cellPhone', e.target.value)
                  }
                />
              </S.LabelText>
              {props.touched.cellphone && props.errors.cellphone ? (
                <S.ErrorMessage>{props.errors.cellphone}</S.ErrorMessage>
              ) : null}
            </Col>
          </Row>
          {firstChange ? (
            <>
              <br />
              <br />
              <br />
              <br />
            </>
          ) : (
            ''
          )}
          {payment !== null && payment.paymentInfos !== undefined && (
            <>
              <S.LabelText style={{ marginBottom: 8 }}>
                Selecione uma opção:
              </S.LabelText>
              <Radio.Group
                style={{ marginBottom: 10 }}
                onChange={e =>
                  props.setFieldValue('existingCard', e.target.value)
                }
                value={props.values.existingCard}
              >
                <Space direction="vertical">
                  <Radio
                    value
                  >{`Utilizar o cartão já existente (${payment.paymentInfos?.cardNumber})`}</Radio>
                  <Radio value={false}>Utilizar novo cartão</Radio>
                </Space>
              </Radio.Group>
            </>
          )}
          {props.touched.existingCard && props.errors.existingCard ? (
            <S.ErrorMessage>{props.errors.existingCard}</S.ErrorMessage>
          ) : null}

          {props.values.existingCard === false && (
            <S.ContainerCredCardInfo>
              <S.ContainerCredCardInputs>
                <strong>Número do cartão</strong>
                <InputMask
                  mask={['9999 9999 9999 9999']}
                  defaultValue={props.values.cardNumber}
                  style={S.AntdStyles.inputMask}
                  onChange={e =>
                    props.setFieldValue('cardNumber', e.target.value)
                  }
                />
                {props.touched.cardNumber && props.errors.cardNumber ? (
                  <S.ErrorMessage>{props.errors.cardNumber}</S.ErrorMessage>
                ) : null}
                <strong>Nome escrito no cartão</strong>
                <Input
                  id="cardName"
                  name="cardName"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.cardName}
                  onBlur={props.handleBlur}
                />
                {props.touched.cardName && props.errors.cardName ? (
                  <S.ErrorMessage>{props.errors.cardName}</S.ErrorMessage>
                ) : null}
                <strong>Bandeira</strong>
                <AutoComplete
                  dropdownMatchSelectWidth={252}
                  style={{
                    width: 252,
                  }}
                  allowClear
                  options={filterAutoComplete}
                  filterOption={(inputValue, option) =>
                    option.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                  value={props.values.cardFlag}
                  onChange={e => {
                    props.setFieldValue('cardFlag', e);
                  }}
                >
                  <Input
                    id="cardFlag"
                    name="cardFlag"
                    type="text"
                    onChange={props.handleChange}
                    value={props.values.cardFlag}
                    onBlur={props.handleBlur}
                  />
                </AutoComplete>
                {props.touched.cardFlag && props.errors.cardFlag ? (
                  <S.ErrorMessage>{props.errors.cardFlag}</S.ErrorMessage>
                ) : null}
                <S.ContainerSmallInputs>
                  <S.ContainerSectionSmallInput>
                    <strong>Validade</strong>
                    <InputMask
                      mask="99/99"
                      defaultValue={props.values.cardUsageTime}
                      style={S.AntdStyles.inputMask}
                      onChange={e =>
                        props.setFieldValue('cardUsageTime', e.target.value)
                      }
                    />
                    {props.touched.cardUsageTime &&
                    props.errors.cardUsageTime ? (
                      <S.ErrorMessage>
                        {props.errors.cardUsageTime}
                      </S.ErrorMessage>
                    ) : null}
                  </S.ContainerSectionSmallInput>
                  <S.ContainerSectionSmallInput style={{ width: '100%' }}>
                    <strong>Código de Segurança</strong>
                    <Input
                      id="cardSecuryCode"
                      name="cardSecuryCode"
                      type="text"
                      onChange={props.handleChange}
                      value={props.values.cardSecuryCode}
                      onBlur={props.handleBlur}
                    />
                    {props.touched.cardSecuryCode &&
                    props.errors.cardSecuryCode ? (
                      <S.ErrorMessage>
                        {props.errors.cardSecuryCode}
                      </S.ErrorMessage>
                    ) : null}
                  </S.ContainerSectionSmallInput>
                </S.ContainerSmallInputs>
              </S.ContainerCredCardInputs>
              <S.ContainerCard>
                <Cards
                  cvc={props.values.cardSecuryCode}
                  expiry={props.values.cardUsageTime}
                  name={props.values.cardName}
                  number={props.values.cardNumber}
                />
              </S.ContainerCard>
            </S.ContainerCredCardInfo>
          )}

          <ReCAPTCHA
            ref={captchatRef}
            sitekey="6LdULCoeAAAAADE6x3T02Nt6VH9BFBd3j9j_h2_v"
            onChange={() => onChangeRecaptcha(props)}
          />
          {props.touched.captcha && props.errors.captcha ? (
            <S.ErrorMessage>{props.errors.captcha}</S.ErrorMessage>
          ) : null}

          <S.ContainerPaymentFormButtons>
            <Button
              type="primary"
              onClick={() => changeScreen('management')}
              style={{
                marginTop: '0.5rem',
                marginBottom: '0.5rem',
                width: '7rem',
                borderRadius: '5px',
                backgroundColor: '#118E88',
                borderColor: '#118E88',
              }}
            >
              <strong>Voltar</strong>
            </Button>

            <Button
              type="primary"
              onClick={() => props.handleSubmit()}
              style={{
                marginTop: '0.5rem',
                marginBottom: '0.5rem',
                width: '7rem',
                borderRadius: '5px',
                backgroundColor: '#118E88',
                borderColor: '#118E88',
              }}
              loading={loading}
            >
              <strong>Assinar</strong>
            </Button>
          </S.ContainerPaymentFormButtons>

          <strong className="final-coment">
            Autorizar cobrança neste cartão até o cancelamento
          </strong>
          <br />
        </S.ContainerRightSection>
      )}
    </Formik>
  );
}
