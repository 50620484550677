import React, { useState, useEffect, memo } from 'react';
import { Input } from 'antd';
import useDebounce from 'hooks/debounce/useDebounce';
import SavedAnswerComponent from 'components/SavedAnswer';

import * as S from './styles';
import './styles.css';

function TextAndTextArea({
  text,
  answerKey,
  answerFunc,
  setFormValues,
  formValues,
  indexSession,
  currentValue,
  propsFormik,
}) {
  const { TextArea } = Input;
  const [taValue, setTAValue] = useState(currentValue);
  const [isFirstRendering, setIsFirstRendering] = useState(true);
  const debounceChange = useDebounce(updateFormValues, 50);

  useEffect(() => {
    if (!isFirstRendering) {
      debounceChange();
    }
    setIsFirstRendering(false);
  }, [taValue]);

  function updateFormValues() {
    const tempForm = [];
    for (let i = 0; i < formValues.length; i += 1) {
      if (i !== indexSession) {
        tempForm.push(formValues[i]);
      } else {
        tempForm.push({
          index: i + 1,
          text: formValues[i].text,
          value: [formValues[i].value[0], formValues[i].value[1], taValue],
        });
      }
    }
    setFormValues(tempForm);
  }

  return (
    <div style={{ marginTop: '0.5rem', marginLeft: '1rem' }}>
      <ul
        style={{
          marginLeft: '1rem',
        }}
      >
        <li>
          <S.LabelText className="input-label">
            {text}
            <TextArea
              rows={4}
              placeholder="Inserir..."
              style={S.AntdStyles.inputTextArea}
              value={currentValue}
              onChange={e => {
                answerFunc(answerKey, e.target.value);
                setTAValue(e.target.value);
              }}
            />
            <SavedAnswerComponent
              resource="hasSpecializedEvaluation"
              propsFormik={propsFormik}
              fieldName={answerKey}
            />
          </S.LabelText>
        </li>
      </ul>
    </div>
  );
}

export default memo(
  TextAndTextArea,
  (prev, next) => prev.propsFormik.values === next.propsFormik.values,
);
