/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Button } from 'antd';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateEvaluation } from 'store/modules/evaluations/actions';
import ImgMiniMentalDesenho from 'assets/mini-mental-desenho.png';
import DoubleTextAndRadioGroupOneAnswer from '../../components/DoubleTextAndRadioGroupOneAnswer';
import * as S from './styles';

function MiniExameEstadoMental({ title, current_eval }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const qts = current_eval?.content?.questions;

  const forms = [
    { label: '0', value: '0' },
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
  ];

  const forms2 = [
    { label: '0', value: '0' },
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
  ];

  const forms3 = [
    { label: '0', value: '0' },
    { label: '1', value: '1' },
  ];

  const Questions1 = [
    'Em que ano (1 ponto), dia/semana (1 ponto), dia/mês (1 ponto), mês (1 ponto) e hora (1 ponto) aproximada que estamos?',
    'Em que local (andar/setor) específico (1 ponto), instituição (residência, hospital, clínica) específica (1 ponto), bairro ou rua próxima (1 ponto), cidade (1 ponto) e estado (1 ponto) estamos?',
  ];

  const Questions2 = [
    'Repita as palavras: VASO (1 ponto), CARRO (1 ponto) e JANELA (1 ponto)',
  ];

  const Questions3 = [
    'Subtrair: 100-7 (5 tentativas: 93-86-79-72-65) (1 ponto cada)',
  ];

  const Questions4 = ['Repita as palavras ditas anteriormente (1 ponto cada)'];

  const Questions5 = [
    'Nomear relógio e caneta (1 ponto)',
    'Repetir: "Nem aqui, nem ali, nem lá" (1 ponto)',
    'Siga as instruções: "Pegue um papel com a mão direita (1 ponto). dobre ao meio (1 ponto) e jogue no chão (1 ponto)"',
    'Ler e obedecer: "Feche os olhos" (1 ponto)',
    'Escreva uma frase (1 ponto)',
    'Copie o desenho (1 ponto)',
  ];

  const [formValues, setFormValues] = useState(
    current_eval?.content?.questions.length > 0
      ? [
          ...Questions1,
          ...Questions2,
          ...Questions3,
          ...Questions4,
          ...Questions5,
        ].map((item, index) => ({
          index: index + 1,
          text: item,
          value: current_eval.content.questions[index].value,
        }))
      : [
          ...Questions1,
          ...Questions2,
          ...Questions3,
          ...Questions4,
          ...Questions5,
        ].map((item, index) => ({
          index: index + 1,
          text: item,
          value: '',
        })),
  );

  function updateValues(isFinal) {
    const tempFormValue = {
      id: current_eval?.id,
      isFinished: isFinal,
      name: title,
      professionalId: current_eval?.professionalId,
      result: null,
      content: {
        questions: formValues,
      },
    };
    dispatch(updateEvaluation(tempFormValue, id));
    if (isFinal) {
      setFormValues(
        [
          ...Questions1,
          ...Questions2,
          ...Questions3,
          ...Questions4,
          ...Questions5,
        ].map((item, index) => ({
          index: index + 1,
          text: item,
          value: '',
        })),
      );
    }
  }

  useEffect(() => {
    const valuesFormValues = Object.values(formValues);
    if (valuesFormValues.find(item => item.value !== '')) {
      updateValues(false);
    }
  }, [formValues]);

  function returnForms(indexQuestion) {
    if (indexQuestion === 3) return forms2;
    if (indexQuestion === 5) return forms2;
    if (indexQuestion === 6) return forms3;
    if (indexQuestion === 7) return forms3;
    if (indexQuestion === 8) return forms2;
    if (indexQuestion === 9) return forms3;
    if (indexQuestion === 10) return forms3;
    if (indexQuestion === 11) return forms3;

    return forms;
  }

  function changeNameItem(nameCurrent) {
    if (
      nameCurrent ===
      'Em que local (andar/setor) específico (1 ponto), instituição (residência, hospital, clínica) específica (1 ponto), bairro ou rua próxima (1 ponto), cidade (1 ponto) e estado (1 ponto) estamos?'
    ) {
      return 'Em que local específico (andar/setor), instituição (residência, hospital, clínica), bairro ou rua próxima, cidade e estado estamos?';
    }

    if (nameCurrent === 'Copie o desenho (1 ponto)') {
      return 'Desenhar';
    }

    return nameCurrent
      .replaceAll(' (1 ponto)', '')
      .replaceAll(' (1 ponto cada)', '');
  }

  return (
    <Formik
      initialValues={{
        Answer1: qts[0] !== undefined ? qts[0].value : '',
        Answer2: qts[1] !== undefined ? qts[1].value : '',
        Answer3: qts[2] !== undefined ? qts[2].value : '',
        Answer4: qts[3] !== undefined ? qts[3].value : '',
        Answer5: qts[4] !== undefined ? qts[4].value : '',
        Answer6: qts[5] !== undefined ? qts[5].value : '',
        Answer7: qts[6] !== undefined ? qts[6].value : '',
        Answer8: qts[7] !== undefined ? qts[7].value : '',
        Answer9: qts[8] !== undefined ? qts[8].value : '',
        Answer10: qts[9] !== undefined ? qts[9].value : '',
        Answer11: qts[10] !== undefined ? qts[10].value : '',
      }}
      onSubmit={(values, actions) => {
        // eslint-disable-next-line prefer-const
        let valuesSubmit = {
          name: title,
          content: {
            questions: [],
          },
        };

        [
          ...Questions1,
          ...Questions2,
          ...Questions3,
          ...Questions4,
          ...Questions5,
        ].forEach((item, index) => {
          valuesSubmit.content.questions.push({
            index: index + 1,
            text: changeNameItem(item),
            value: values[`Answer${index + 1}`],
          });
        });

        actions.resetForm({
          values: {
            Answer1: '',
            Answer2: '',
            Answer3: '',
            Answer4: '',
            Answer5: '',
            Answer6: '',
            Answer7: '',
            Answer8: '',
            Answer9: '',
            Answer10: '',
            Answer11: '',
          },
        });

        updateValues(true);
      }}
    >
      {props => (
        <>
          <S.LabelText>1. Orientação</S.LabelText>
          {Questions1.map(
            (item, index) =>
              item && (
                <DoubleTextAndRadioGroupOneAnswer
                  text={item}
                  ArrayValue={returnForms(index + 1)}
                  setAnswer={props.setFieldValue}
                  keyAnswer={`Answer${index + 1}`}
                  direction="horizontal"
                  setFormValues={setFormValues}
                  formValues={formValues}
                  index={index}
                  currentValue={props.values[`Answer${index + 1}`]}
                  propsFormik={props}
                />
              ),
          )}

          <S.LabelText>2. Memória Imediata</S.LabelText>
          {Questions2.map(
            (item, index) =>
              item && (
                <DoubleTextAndRadioGroupOneAnswer
                  text={item}
                  ArrayValue={returnForms(index + 1 + 2)}
                  setAnswer={props.setFieldValue}
                  keyAnswer={`Answer${index + 1 + 2}`}
                  direction="horizontal"
                  setFormValues={setFormValues}
                  formValues={formValues}
                  index={index + 2}
                  currentValue={props.values[`Answer${index + 1 + 2}`]}
                  propsFormik={props}
                />
              ),
          )}

          <S.LabelText>3. Atenção e Cálculo</S.LabelText>
          {Questions3.map(
            (item, index) =>
              item && (
                <DoubleTextAndRadioGroupOneAnswer
                  text={item}
                  ArrayValue={returnForms(index + 1 + 2 + 1)}
                  setAnswer={props.setFieldValue}
                  keyAnswer={`Answer${index + 1 + 2 + 1}`}
                  direction="horizontal"
                  setFormValues={setFormValues}
                  formValues={formValues}
                  index={index + 2 + 1}
                  currentValue={props.values[`Answer${index + 1 + 2 + 1}`]}
                  propsFormik={props}
                />
              ),
          )}

          <S.LabelText>4. Evocação</S.LabelText>
          {Questions4.map(
            (item, index) =>
              item && (
                <DoubleTextAndRadioGroupOneAnswer
                  text={item}
                  ArrayValue={returnForms(index + 1 + 2 + 1 + 1)}
                  setAnswer={props.setFieldValue}
                  keyAnswer={`Answer${index + 1 + 2 + 1 + 1}`}
                  direction="horizontal"
                  setFormValues={setFormValues}
                  formValues={formValues}
                  index={index + 2 + 1 + 1}
                  currentValue={props.values[`Answer${index + 1 + 2 + 1 + 1}`]}
                  propsFormik={props}
                />
              ),
          )}

          <S.LabelText>5. Linguagem</S.LabelText>
          {Questions5.map(
            (item, index) =>
              item && (
                <DoubleTextAndRadioGroupOneAnswer
                  text={item}
                  ArrayValue={returnForms(index + 1 + 2 + 1 + 1 + 1)}
                  setAnswer={props.setFieldValue}
                  keyAnswer={`Answer${index + 1 + 2 + 1 + 1 + 1}`}
                  direction="horizontal"
                  setFormValues={setFormValues}
                  formValues={formValues}
                  index={index + 2 + 1 + 1 + 1}
                  currentValue={
                    props.values[`Answer${index + 1 + 2 + 1 + 1 + 1}`]
                  }
                  propsFormik={props}
                />
              ),
          )}
          {
            <img
              src={ImgMiniMentalDesenho}
              alt="desenho"
              style={{ width: '50%' }}
            />
          }

          <Button
            type="primary"
            htmlType="submit"
            onClick={props.handleSubmit}
            style={S.AntdStyles.ButtonSave}
          >
            Salvar
          </Button>
        </>
      )}
    </Formik>
  );
}

export default MiniExameEstadoMental;
