/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import {
  Layout,
  Input,
  notification,
  Avatar,
  Menu,
  Dropdown,
  Button,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import AppHeader from 'components/Header';
import IconSearch from 'assets/icon-search.svg';
import IconBack from 'assets/arrow-left-grey.svg';
import IconSend from 'assets/send-message.svg';
// import fire from 'services/fire';
import {
  getContacts,
  getTimezone,
  selectChat,
  sendFile,
  sendMessage,
  updateStatus,
} from 'store/modules/messages/actions';
import 'firebase/firestore';
import { getHours, getMinutes } from 'date-fns';
import fileSize from 'filesize';
import { uniqueId } from 'lodash';
import { MoreOutlined } from '@ant-design/icons';
import {
  PropsProfileEnum,
  PropsProfileTranslatedWithClinic,
} from 'utils/profile';
import { nameHandler } from 'utils/NameHandler';
import { setInfoDetails } from 'store/modules/clinic/actions';
import { showHeaderVinculated } from 'utils/showHeaderVinculated';
import { replaceSpecialChars } from 'utils/Validator';
import * as S from './styles';
import Messages from './Messages';
import Upload from './Upload';

function Chat() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [text, setText] = useState('');
  const contactsList = useSelector(state => state.messages.contacts);
  const [contacts, setContacts] = useState(contactsList);
  const selectedChatUser = useSelector(state => state.messages.selectedChat);
  const newMessages = useSelector(state => state.messages.newMessages);
  // const timezoneHours = useSelector(state => state.messages.timezoneHours);
  const firstChange = useMediaQuery('(min-width: 1250px)');
  const secondChange = useMediaQuery('(min-width: 950px)');
  const thirdChange = useMediaQuery('(min-width: 500px)'); // menor tamanho para o header de rightcontent
  const fourthChange = useMediaQuery('(min-width: 650px)'); // menor tamanho para o header de rightcontent
  // const fifthChange = useMediaQuery('(min-width: 1050px)'); // menor tamanho para o header de rightcontent
  const [messages, setMessages] = useState([]);
  const [lastUserId, setUserId] = useState('');
  const [lastChatId, setChatId] = useState('');
  const user = useSelector(state => state.auth.user);

  function changeTextFilter(textChanged) {
    setContacts(
      contactsList.filter(value =>
        replaceSpecialChars(value.name)
          .toLowerCase()
          .includes(replaceSpecialChars(textChanged).toLowerCase()),
      ),
    );
  }

  const handleSend = () => {
    if (text === '') {
      notification.warning({
        message: 'Atenção!',
        description: 'É necessário escrever uma mensagem para poder envia-la',
      });
    } else {
      dispatch(
        sendMessage(
          selectedChatUser.selectedChatId,
          selectedChatUser.selectedUserId,
          text,
        ),
      );
      setText('');
    }
  };

  const handleName = () => {
    if (firstChange || (fourthChange && !secondChange)) {
      return `${selectedChatUser?.selectedChatName}`;
    }
    return (
      `${selectedChatUser?.selectedChatName.substring(0, 10)}` +
      `${selectedChatUser?.selectedChatName.length > 15 ? '...' : '.'}`
    );
  };

  const handleInputKeyUp = e => {
    if (e.keyCode === 13) {
      handleSend();
    }
  };

  const menu = (
    <Menu>
      {selectedChatUser.selectedUserProfileName ===
        PropsProfileEnum.PATIENT && (
        <Menu.Item
          onClick={() => {
            history.push({
              pathname: `${selectedChatUser.selectedUserUid}/edit_info`,
              state: { search: PropsProfileEnum.PATIENT },
            });
            dispatch(updateStatus(lastChatId, lastUserId, 'OFFLINE'));
          }}
        >
          Ir à Página do{' '}
          {
            PropsProfileTranslatedWithClinic[
              selectedChatUser.selectedUserProfileName
            ]
          }
        </Menu.Item>
      )}
    </Menu>
  );

  useEffect(() => {
    if (showHeaderVinculated(user)) {
      dispatch(getContacts(true));
    }
    dispatch(getTimezone());
  }, []);

  useEffect(() => {
    setContacts(contactsList);
  }, [contactsList]);

  useEffect(() => {
    async function getMessages() {
      if (
        lastChatId === '' &&
        lastChatId !== null &&
        selectedChatUser.selectedChatId !== '' &&
        selectedChatUser.lastMessage !== null
      ) {
        dispatch(
          updateStatus(
            selectedChatUser.selectedChatId,
            selectedChatUser.selectedUserId,
            'ONLINE',
          ),
        );
        setUserId(selectedChatUser.selectedUserId);
        setChatId(selectedChatUser.selectedChatId);
      }
      if (
        lastChatId !== selectedChatUser.selectedChatId &&
        lastChatId !== '' &&
        lastChatId !== null &&
        selectedChatUser.selectedChatId !== '' &&
        selectedChatUser.selectedChatId !== null
        // em caso da tela cheia em que o usuario apenas troca de chat
      ) {
        dispatch(updateStatus(lastChatId, lastUserId, 'OFFLINE'));
        dispatch(
          updateStatus(
            selectedChatUser.selectedChatId,
            selectedChatUser.selectedUserId,
            'ONLINE',
          ),
        );
        setUserId(selectedChatUser.selectedUserId);
        setChatId(selectedChatUser.selectedChatId);
      }
      if (
        lastChatId !== selectedChatUser.selectedChatId &&
        lastChatId !== '' &&
        lastChatId !== null &&
        selectedChatUser.selectedChatId === ''
        // em caso da tela menor em que o usuario aperta no botao de voltar e remove o chat atual
      ) {
        dispatch(updateStatus(lastChatId, lastUserId, 'OFFLINE'));
        setChatId('');
      }
    }
    getMessages();
  }, [selectedChatUser]);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      dispatch(
        updateStatus(
          selectedChatUser.selectedChatId,
          selectedChatUser.selectedUserId,
          'OFFLINE',
        ),
      );
    };
  }, [selectedChatUser]);

  const handleUpload = files => {
    const uploadedFiles = files.map(file => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: fileSize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    let sizeIsBig = false;
    uploadedFiles.forEach(uploadedFile => {
      if (uploadedFile.file.size > 500000) {
        sizeIsBig = true;
        notification.warning({
          message: 'Atenção!',
          description:
            'Não é possível enviar uma imagem com tamanho maior a 500 Kb',
        });
      }
    });

    if (sizeIsBig === false) {
      uploadedFiles.forEach(uploadedFile => {
        const data = new FormData();
        data.append('file', uploadedFile.file);

        dispatch(
          sendFile(
            selectedChatUser.selectedUserId,
            selectedChatUser.selectedChatId,
            data,
          ),
        );
      });
    }
  };

  const leftContent = (
    <S.LeftContent style={{ width: secondChange ? '27vw' : '80vw' }}>
      <S.TitlePage>Mensagens</S.TitlePage>
      <S.Search>
        <Input
          placeholder="Busque por pacientes ou colegas"
          onChange={e => changeTextFilter(e.target.value)}
          suffix={
            <img
              alt="pesquisa"
              src={IconSearch}
              style={{ cursor: 'pointer' }}
            />
          }
          style={{
            width: '100%',
            marginRight: firstChange ? '3rem' : '1rem',
            marginBottom: secondChange ? '0rem' : '1rem',
            height: '2.5rem',
            borderRadius: 5,
          }}
        />
      </S.Search>
      <S.ScrollerContainer key={contactsList}>
        {contacts.length > 0 ? (
          <>
            {newMessages > 0 ? (
              <>
                <S.Topic>Últimas conversas</S.Topic>
                {contacts.map(contact =>
                  contact.quantityMessageUnread > 0 ? (
                    <S.LastCardTalk
                      key={contact.id}
                      style={{
                        backgroundColor:
                          selectedChatUser?.selectedUserId === contact.id
                            ? 'var(--aliceBlue)'
                            : 'var(--white)',
                        border:
                          selectedChatUser?.selectedUserId === contact.id
                            ? '1px solid var(--japaneseLaurel)'
                            : 'var(--white)',
                      }}
                      onClick={() => {
                        if (
                          selectedChatUser.selectedChatId !== contact.chatId
                        ) {
                          dispatch(
                            selectChat(
                              contact.id,
                              contact.name,
                              contact.photo,
                              contact.chatId,
                              contact.lastMessage,
                              contact.uid,
                            ),
                          );
                          setMessages([]);
                        }
                      }}
                    >
                      {contact?.photo === null ? (
                        <Avatar size={40} style={S.AntdStyles.AvatarUser}>
                          {nameHandler(contact.name)}
                        </Avatar>
                      ) : (
                        <S.UserImage src={contact.photo} />
                      )}
                      <S.ColumnTexts>
                        <S.RowTexts>
                          <S.Topic>
                            {firstChange
                              ? `${contact.name.substring(0, 23)}`
                              : `${contact.name.substring(0, 10)}` +
                                `${contact.name.length > 15 ? '...' : '.'}`}
                          </S.Topic>
                          <S.Hour>
                            {contact.lastMessage
                              ? `| ${getHours(
                                  new Date(contact.lastMessage?.createdAt),
                                )}:${
                                  getMinutes(
                                    new Date(contact.lastMessage?.createdAt),
                                  ) > 9
                                    ? getMinutes(
                                        new Date(
                                          contact.lastMessage?.createdAt,
                                        ),
                                      )
                                    : `0${getMinutes(
                                        new Date(
                                          contact.lastMessage?.createdAt,
                                        ),
                                      )}`
                                }`
                              : ''}
                          </S.Hour>
                        </S.RowTexts>
                        <S.Subtext>
                          {contact.lastMessage
                            ? contact.lastMessage.messageType === 'TEXT'
                              ? contact.lastMessage.message
                              : 'Imagem'
                            : 'Inicie uma conversa'}
                        </S.Subtext>
                        <S.NewMessageNotif>
                          <S.NotifText>
                            {`${contact.quantityMessageUnread} `}
                            {secondChange ? 'novas mensagens' : ''}
                          </S.NotifText>
                        </S.NewMessageNotif>
                      </S.ColumnTexts>
                    </S.LastCardTalk>
                  ) : (
                    <></>
                  ),
                )}
                <S.Divider />
              </>
            ) : (
              <></>
            )}

            {contacts.map(contact =>
              contact.quantityMessageUnread === 0 ? (
                <S.CardTalk
                  key={contact.id}
                  style={{
                    backgroundColor:
                      selectedChatUser?.selectedUserId === contact.id
                        ? 'var(--aliceBlue)'
                        : 'var(--white)',
                    border:
                      selectedChatUser?.selectedUserId === contact.id
                        ? '1px solid var(--japaneseLaurel)'
                        : 'var(--white)',
                  }}
                  onClick={() => {
                    if (selectedChatUser.selectedUserId !== contact.id) {
                      dispatch(
                        selectChat(
                          contact.id,
                          contact.name,
                          contact.photo,
                          contact.chatId,
                          contact.lastMessage,
                          contact.profileName,
                          contact.uid,
                        ),
                      );
                      setMessages([]);
                    }
                  }}
                >
                  {contact?.photo === null ? (
                    <Avatar size={40} style={S.AntdStyles.AvatarUser}>
                      {nameHandler(contact.name)}
                    </Avatar>
                  ) : (
                    <S.UserImage src={contact.photo} />
                  )}

                  <S.ColumnTexts>
                    <S.RowTexts>
                      <S.Topic>
                        {firstChange
                          ? `${contact.name.substring(0, 23)}`
                          : `${contact.name.substring(0, 10)}` +
                            `${contact.name.length > 15 ? '...' : '.'}`}
                      </S.Topic>
                      <S.Hour>
                        {contact.lastMessage
                          ? `| ${getHours(
                              new Date(contact.lastMessage?.createdAt),
                            )}:${
                              getMinutes(
                                new Date(contact.lastMessage?.createdAt),
                              ) > 9
                                ? getMinutes(
                                    new Date(contact.lastMessage?.createdAt),
                                  )
                                : `0${getMinutes(
                                    new Date(contact.lastMessage?.createdAt),
                                  )}`
                            }`
                          : ''}
                      </S.Hour>
                    </S.RowTexts>
                    <S.Subtext>
                      {contact.lastMessage
                        ? contact.lastMessage.messageType === 'TEXT'
                          ? contact.lastMessage.message
                          : 'Imagem'
                        : 'Inicie uma conversa'}
                    </S.Subtext>
                  </S.ColumnTexts>
                </S.CardTalk>
              ) : (
                <></>
              ),
            )}
          </>
        ) : (
          <></>
        )}
      </S.ScrollerContainer>
    </S.LeftContent>
  );

  const rightContent = (
    <S.RightContent
      style={{
        width: secondChange ? '66vw' : '95vw',
        marginLeft: secondChange ? '1.5rem' : 0,
      }}
    >
      {selectedChatUser.selectedChatId === '' ? (
        <>
          <S.ChatHeader>
            <S.TextsContainer>
              <S.UserNameChat>
                Selecione um contato para iniciar a conversa
              </S.UserNameChat>
            </S.TextsContainer>
          </S.ChatHeader>
        </>
      ) : (
        <>
          <S.ChatHeader>
            {secondChange ? (
              selectedChatUser?.selectedChatPhoto === null ? (
                <Avatar size={40} style={S.AntdStyles.AvatarUser}>
                  {nameHandler(selectedChatUser?.selectedChatName)}
                </Avatar>
              ) : (
                <S.UserImage
                  style={{ width: 40, height: 40 }}
                  src={selectedChatUser?.selectedChatPhoto}
                />
              )
            ) : (
              <S.BackView
                onClick={() => dispatch(selectChat('', '', null, '', '', ''))}
              >
                <img alt="enviar" src={IconBack} />
              </S.BackView>
            )}

            <S.TextsContainer>
              <S.UserNameChat>{handleName()}</S.UserNameChat>
              <S.Hour>
                {`|
                  ${
                    PropsProfileTranslatedWithClinic[
                      selectedChatUser.selectedUserProfileName
                    ]
                  }`}
              </S.Hour>
            </S.TextsContainer>
            {thirdChange ? (
              selectedChatUser.selectedUserProfileName ===
                PropsProfileEnum.PATIENT && (
                <S.ButtonPacient
                  onClick={() => {
                    dispatch(setInfoDetails(null, 'PATIENT'));

                    history.push({
                      pathname: `${selectedChatUser.selectedUserUid}/edit_info`,
                      state: { search: PropsProfileEnum.PATIENT },
                    });
                    dispatch(updateStatus(lastChatId, lastUserId, 'OFFLINE'));
                  }}
                >
                  <S.ButtonText>
                    Ir à Página da(o){' '}
                    {
                      PropsProfileTranslatedWithClinic[
                        selectedChatUser.selectedUserProfileName
                      ]
                    }
                  </S.ButtonText>
                </S.ButtonPacient>
              )
            ) : (
              <Dropdown overlay={menu} trigger="click" visible={false}>
                <Button
                  style={S.AntdStyles.DropDownButton}
                  icon={<MoreOutlined />}
                  size="large"
                />
              </Dropdown>
            )}
          </S.ChatHeader>

          <Messages
            messages={messages}
            chatUser={selectedChatUser}
            updateMsg={setMessages}
          />

          <S.ChatInputToolbar>
            <S.InputArea>
              <S.Input
                type="text"
                value={text}
                onChange={e => setText(e.target.value)}
                placeholder="Digite a sua mensagem"
                onKeyUp={handleInputKeyUp}
              />
              <S.VerticalDivider />
              <S.SendView onClick={textChanged => handleSend(textChanged)}>
                <img alt="enviar" src={IconSend} />
              </S.SendView>
            </S.InputArea>
            <S.AssetsView>
              <Upload onUpload={handleUpload} />
            </S.AssetsView>
          </S.ChatInputToolbar>
        </>
      )}
    </S.RightContent>
  );

  return (
    <Layout>
      <AppHeader />
      <S.Container>
        <S.Content>
          {secondChange ? (
            <>
              {leftContent} {rightContent}
            </>
          ) : (
            <>
              {selectedChatUser.selectedChatId === ''
                ? leftContent
                : rightContent}
            </>
          )}
        </S.Content>
      </S.Container>
    </Layout>
  );
}

export default Chat;
