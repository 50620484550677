import React, { useState } from 'react';
import { Modal, Button, Radio, Space } from 'antd';
import { useDispatch } from 'react-redux';

// eslint-disable-next-line no-unused-vars
import { answerInviteClinic } from 'store/modules/clinic/actions';

import * as S from './styles';

export default function JoinClinicModal({ isVisible, handleCancel, item }) {
  const [value, setValue] = useState(true);

  const dispatch = useDispatch();

  function onChange(e) {
    setValue(e.target.value);
  }

  return (
    <Modal
      title="Como deseja ingressar na clínica?"
      visible={isVisible}
      onCancel={() => handleCancel()}
      style={{ top: 20 }}
      width={850}
      footer={[
        <Button
          type="primary"
          onClick={() => {
            dispatch(
              answerInviteClinic(
                false,
                item.involvedId,
                item.extraData.inviteId,
                item.id,
                value,
              ),
            );
            handleCancel();
          }}
          style={{
            width: '180px',
            borderRadius: '5px',
            backgroundColor: 'transparent',
            borderColor: '#118E88',
            color: '#118E88',
          }}
        >
          Recusar
        </Button>,
        <Button
          type="primary"
          onClick={() => {
            dispatch(
              answerInviteClinic(
                true,
                item.involvedId,
                item.extraData.inviteId,
                item.id,
                value,
              ),
            );
            handleCancel();
          }}
          style={{
            width: '180px',
            borderRadius: '5px',
            backgroundColor: '#118E88',
            borderColor: '#118E88',
            color: 'white',
          }}
        >
          Aceitar
        </Button>,
      ]}
    >
      <S.Container>
        <Radio.Group onChange={onChange} value={value}>
          <Space direction="vertical">
            <Radio value>
              Ingressar na clínica com um novo perfil profissional. Um novo
              perfil, sem pacientes, será criado. Será necessário cadastrar os
              pacientes da clínica que serão atendidos por você.{' '}
            </Radio>
            <Radio value={false}>
              Ingressar na clínica com o mesmo perfil profissional. Seu perfil
              de profissional independente se transformará em um perfil de
              profissional da clínica. Nesse caso, os seus pacientes também
              serão pacientes da clínica. Somente você pode visualizar o
              prontuário de seus pacientes.
            </Radio>
          </Space>
        </Radio.Group>
      </S.Container>
    </Modal>
  );
}
