import React, { useEffect, useState, useContext } from 'react';
import { Divider } from 'antd';
import { Plan } from '../Plan';
import { Package } from '../Package';

import { PaymentForm } from './PaymentForm';

import PlanContext from '../../Context/PlanContext';

import * as S from './styles';

export function MakePayment({ reason }) {
  const { self } = useContext(PlanContext);
  const { subscript_status } = useContext(PlanContext);

  const [contract, setContract] = useState(null);
  const [contractId, setContractId] = useState('');

  useEffect(() => {
    if (subscript_status !== null) {
      const PlansData = subscript_status.subscriptionStatus.plans;
      const PackageData = subscript_status.subscriptionStatus.packages;

      for (let x = 0; x < PlansData.length; x += 1) {
        if (reason === PlansData[x].planName) {
          setContractId(PlansData[x].planId);
          setContract(
            <Plan
              planData={PlansData[x]}
              isBestPlan={false}
              isCurrentPlan={false}
              isShowButtonContract={false}
            />,
          );
          break;
        }
      }

      for (let x = 0; x < PackageData.length; x += 1) {
        if (reason === PackageData[x].packageName) {
          setContractId(PackageData[x].packageId);
          setContract(
            <Package
              packageData={PackageData[x]}
              isShowButtonContract={false}
            />,
          );
          break;
        }
      }
    }
  }, [subscript_status]);

  return (
    <S.Container>
      <S.ContainerLeftSection>
        <S.TitlePage className="subtitle">Plano</S.TitlePage>
        <br />
        {contract}
        <S.ContainerCompanyData>
          <strong className="subtitle-company-name">Dados da empresas</strong>
          <S.ContainerDivider>
            <Divider />
          </S.ContainerDivider>
          <S.ContainerInfo>
            <label>
              <b>Razão Social: </b>NEUROBOTS PESQUISA E DESENVOLVIMENTO LTDA.
            </label>
          </S.ContainerInfo>
          <S.ContainerInfo>
            <label>
              <b>CNPJ: </b>24.052.685/0001-01
            </label>
          </S.ContainerInfo>
        </S.ContainerCompanyData>
      </S.ContainerLeftSection>
      {contract !== null && setContractId !== '' && (
        <PaymentForm self={self} reason={reason} contractId={contractId} />
      )}
    </S.Container>
  );
}
