/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Divider } from 'antd';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setIsEmailVerified } from 'store/modules/auth/actions';

import * as S from './styles';

function Login({ setPage }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setIsEmailVerified(true));
  }, []);

  return (
    <S.LoginRight>
      <S.DescInput>Seu cadastro foi finalizado.</S.DescInput>

      <S.DescInput>
        Uma confirmação de email foi enviado para o email cadastrado.
      </S.DescInput>

      <S.DescInput>
        Confirme seu email e faça login para poder acessar o Monitore.
      </S.DescInput>

      <Divider />

      <S.TextLogin>Voltar para tela de login.</S.TextLogin>

      <Button
        style={S.AntdStyles.buttonRegister}
        onClick={() => setPage('login')}
      >
        <strong>Login</strong>
      </Button>
    </S.LoginRight>
  );
}

export default Login;
