/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button } from 'antd';

import 'react-credit-cards/es/styles-compiled.css';

import closeIcon from 'assets/close.svg';
import api from '../../../../../../../services/api';
import * as S from './styles';

export function ModalSendCode({
  isModalVisible = false,
  handleOk,
  handleCancel,
}) {
  const patientDetails = useSelector(state => state.patient.patientDetails);
  const [accessCode, setAccessCode] = useState(null);

  useEffect(() => {
    if (patientDetails !== undefined && patientDetails !== null) {
      api.get(`/getAccessCode/${patientDetails.id}`).then(response => {
        setAccessCode(response.data.accessCode);
      });
    }
  }, [patientDetails]);

  return (
    <Modal
      title="Enviar código de acesso"
      visible={isModalVisible}
      onOk={() => handleOk()}
      onCancel={() => handleCancel()}
      width={450}
      closeIcon={
        <img src={closeIcon} alt="close" style={S.AntdStyles.iconClose} />
      }
      footer={[
        <Button
          type="primary"
          onClick={() => handleOk()}
          style={S.AntdStyles.ButtonSend}
        >
          Enviar
        </Button>,
        <Button
          type="primary"
          onClick={() => handleCancel()}
          style={S.AntdStyles.ButtonClose}
        >
          Fechar
        </Button>,
      ]}
    >
      <S.Container>
        <label>
          Ao clicar em <b>Enviar</b>, {patientDetails?.name} receberá por SMS um
          código que lhe permitirá utilizar o Monitore App.
        </label>
        <br />
        <label>
          {' '}
          Informe ao paciente que ele poderá baixar o app através dos links
          <a href="https://neurobots.com.br/android" target="_blank">
            {' '}
            neurobots.com.br/android
          </a>{' '}
          caso seu dispositivo tenha sistema operacional Android ou
          <a href="htpps://neurobots.com.br/ios" target="_blank">
            {' '}
            neurobots.com.br/ios
          </a>{' '}
          caso seja IOS (Iphone).
        </label>
        <br />
        <label>
          O código de acesso é{' '}
          <b style={{ fontSize: 16 }}>
            {accessCode === null ? 'Carregando...' : accessCode}
          </b>
          .
        </label>
        <label style={{ color: 'red' }}>
          O código acima é exclusivo para este paciente. Não compartilhe ele com
          ninguém além de {patientDetails?.name}.
        </label>
      </S.Container>
    </Modal>
  );
}
