import { useState, useCallback } from 'react';
import { Button, Divider, Radio } from 'antd';
import { useDispatch } from 'react-redux';
import fire from 'services/fire';

import * as S from './styles';

import {
  PropsProfileTranslated,
  PropsProfileEnum,
  PropsProfileArray,
} from '../../../../utils/profile';

import { updateUserState } from '../../../../store/modules/auth/actions';

import ProfessionalProfile from './components/ProfessionalProfile';
import PatientProfile from './components/PatientProfile';
import ClinicProfile from './components/ClinicProfile';
import ReceptionistProfile from './components/ReceptionistProfile';

function Register({ setPage }) {
  const dispatch = useDispatch();

  const profileTranslated = useCallback(profile => {
    const result = PropsProfileTranslated.find(p => p.type === profile).pt;
    return result;
  }, []);

  const [checkProfile, setCheckProfile] = useState(null);

  return (
    <S.LoginRight>
      <S.TextLogin>Qual o seu perfil?</S.TextLogin>

      <Radio.Group
        onChange={e => setCheckProfile(e.target.value)}
        value={checkProfile}
      >
        {PropsProfileArray.map(
          itemProfile =>
            itemProfile && (
              <Radio
                style={S.AntdStyles.radioStyle}
                value={PropsProfileEnum[itemProfile]}
              >
                {profileTranslated(PropsProfileEnum[itemProfile])}
              </Radio>
            ),
        )}
      </Radio.Group>

      <Divider />

      {checkProfile === PropsProfileEnum.THERAPIST && (
        <ProfessionalProfile setPage={setPage} />
      )}

      {checkProfile === PropsProfileEnum.PATIENT && <PatientProfile />}

      {checkProfile === PropsProfileEnum.CLINIC && (
        <ClinicProfile setPage={setPage} />
      )}

      {checkProfile === PropsProfileEnum.RECEPTIONIST && (
        <ReceptionistProfile setPage={setPage} />
      )}

      <Divider />
      <S.TextLogin>Caso queira fazer login</S.TextLogin>
      <Button
        style={S.AntdStyles.buttonRegister}
        onClick={() => {
          fire.auth().signOut();
          setPage('login');
          dispatch(updateUserState(null));
        }}
      >
        <strong>Login</strong>
      </Button>
    </S.LoginRight>
  );
}

export default Register;
