import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import MessageItem from '../MessageItem';

import fire from '../../../../services/fire';

import * as S from './styles';

function Messages({ messages, chatUser, updateMsg }) {
  const body = useRef();
  const timezoneHours = useSelector(state => state.messages.timezoneHours);

  useEffect(() => {
    if (chatUser.selectedUserId !== '')
      if (body.current.scrollHeight > body.current.offsetHeight) {
        setTimeout(() => {
          body.current.scrollTop =
            body.current.scrollHeight - body.current.offsetHeight;
        }, 750);
        // setTimeout utilizado para esperar um momento quando carrega as mensagens,
        // porque as imagens demoram um pouco para serem renderizadas.
      }
  }, [messages]);

  function updateMessages(msgs, chatSelected, update) {
    return fire
      .firestore()
      .collection('chat')
      .doc(chatSelected.selectedChatId)
      .collection('messagesChat')
      .orderBy('createdAt', 'asc')
      .onSnapshot(querySnapshot => {
        const messagesList = [];
        querySnapshot.forEach(doc => {
          doc.data().id = doc.id;
          let temp = {};
          temp = doc.data();
          temp.id = doc.id;
          temp.createdAt.seconds += timezoneHours * 1000 * 60 * 60;
          messagesList.push(temp);
        });

        update([...msgs, ...messagesList]);
      });
  }

  useEffect(() => {
    try {
      if (chatUser.selectedChatId !== '' && timezoneHours !== null) {
        const subscriber = updateMessages(messages, chatUser, updateMsg);

        // Stop listening for updates when no longer required
        return () => subscriber();
      }
    } catch (error) {
      // console.tron.log('erro', error.message);
    }
    return null;
  }, [chatUser]);

  return (
    <S.MessagesList ref={body}>
      {messages.length > 0 &&
        messages.map((item, index) => (
          <MessageItem
            key={item.createdAt?.seconds}
            data={item}
            index={index}
            messages={messages}
          />
        ))}
    </S.MessagesList>
  );
}

export default Messages;
