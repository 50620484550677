import React, { useContext, useEffect, useState } from 'react';
import { Pagination, Empty } from 'antd';
import FormContext from '../../../Context/FormContext';
import { ActivityDay } from './components/ActivityDay';
// import { mock } from './mock';

export function ByPractice() {
  const { monitoringData } = useContext(FormContext);
  const [practicesPerformed, setPracticesPerformed] = useState([[]]);
  const [currentIndex, setCurrentIndex] = useState(1);

  useEffect(() => {
    // Preparar estrutura do tempPractice
    const tmpMonit = monitoringData.homeSessionDetails;

    const bodyTmpPractice = [[]];
    for (let x = 0; x < tmpMonit.length; x += 1) {
      if (x % 5 === 0) {
        bodyTmpPractice.push([]);
      }
    }
    const tempPractice = [...bodyTmpPractice];
    let contToChangeIndex = 0;

    // A cada 5 sessões o indice muda e recebem mais 5 sessões
    for (let x = 0; x < tmpMonit.length; x += 1) {
      if (x !== 0 && x % 5 === 0) {
        contToChangeIndex += 1;
      }
      tempPractice[contToChangeIndex].push(
        <ActivityDay
          date={tmpMonit[x].date}
          activities={tmpMonit[x].evaluations}
          percentage={tmpMonit[x].percentage}
          saffety={tmpMonit[x].safety}
          sessionStatus={tmpMonit[x].appreciation}
          considerations={tmpMonit[x].considerations}
        />,
      );
    }
    setPracticesPerformed([...tempPractice]);
  }, [monitoringData]);

  function onChangeIndex(page) {
    setCurrentIndex(page);
  }

  return (
    <>
      {monitoringData.homeSessionDetails.length > 0 ? (
        <>{practicesPerformed[currentIndex - 1]?.map(practice => practice)}</>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: 200,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <label>Não houveram práticas domicilares finalizadas</label>
          <Empty />
        </div>
      )}

      <br />
      {monitoringData.homeSessionDetails.length > 3 && (
        <Pagination defaultCurrent={1} onChange={onChangeIndex} total={50} />
      )}
      <br />
    </>
  );
}
