import { takeLatest, all, call, put } from 'redux-saga/effects';
import { notification } from 'antd';

import api from '../../../services/api';

import { setPatientGoals } from './actions';

export function* getPatientGoals({ payload }) {
  try {
    const { patientID } = payload;

    const response = yield call(api.get, `/patients/${patientID}/goals`);

    yield put(setPatientGoals(response.data));
  } catch (err) {
    notification.error({
      message: 'Houve um erro ao buscar dados de objetivos, tente novamente!',
    });
  }
}

// eslint-disable-next-line require-yield
export function* savePatientGoals({ payload }) {
  try {
    // eslint-disable-next-line no-unused-vars
    const { goals, patientID } = payload;

    yield call(api.put, `/patients/${patientID}/goals`, goals);
  } catch (err) {
    notification.error({
      message:
        'Houve um erro ao salvar os dados de objetivos, tente novamente!',
    });
  }
}

// eslint-disable-next-line require-yield
export function* finalSavePatientGoals({ payload }) {
  try {
    // eslint-disable-next-line no-unused-vars
    const { goals, patientID } = payload;

    const response = yield call(api.put, `/patients/${patientID}/goals`, goals);
    if (response !== undefined) {
      notification.success({
        message: 'Objetivos salvos com sucesso!',
      });
    }
  } catch (err) {
    notification.error({
      message:
        'Houve um erro ao salvar os dados de objetivos, tente novamente!',
    });
  }
}

export default all([
  takeLatest('@goals/GET_PATIENT_GOALS', getPatientGoals),
  takeLatest('@goals/SAVE_PATIENT_GOALS', savePatientGoals),
  takeLatest('@goals/FINAL_SAVE_PATIENT_GOALS', finalSavePatientGoals),
]);
