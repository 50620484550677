export function getPatientGoals(patientID) {
  return {
    type: '@goals/GET_PATIENT_GOALS',
    payload: { patientID },
  };
}

export function setPatientGoals(goals) {
  return {
    type: '@goals/SET_PATIENT_GOALS',
    payload: { goals },
  };
}

export function savePatientGoals(goals, patientID) {
  return {
    type: '@goals/SAVE_PATIENT_GOALS',
    payload: { goals, patientID },
  };
}

export function savePatientGoalsFinal(goals, patientID) {
  return {
    type: '@goals/FINAL_SAVE_PATIENT_GOALS',
    payload: { goals, patientID },
  };
}
