import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layout, Divider } from 'antd';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';

import { getUser } from 'store/modules/auth/actions';
import { getSelf } from 'store/modules/clinic/actions';

import { PropsProfileEnum } from 'utils/profile';
import AppHeader from '../../../components/Header';
import PhotoAndClinic from './components/PhotoAndClinic';
import DataGeneralTherapist from './components/DataGeneralTherapist';
import DataGeneralClinic from './components/DataGeneralClinicOnly';
import DataGeneralReceptionist from './components/DataGeneralReceptionist';
import DataPassword from './components/DataPassword';

import 'antd/dist/antd.less';
import './SiderMenu.css';
import DataAdmins from './components/DataAdmins';
import DataClinic from './components/DataClinic';
import * as S from './styles';
import './styles.css';

function MyAccount() {
  const firstChange = useMediaQuery('(min-width: 900px)');
  const secondChange = useMediaQuery('(min-width: 750px)');
  const thirdChange = useMediaQuery('(min-width: 480px)');
  const { Sider } = Layout;
  const dispatch = useDispatch();
  const isClinic = useSelector(
    state =>
      state.auth?.user?.active_profile_name !== PropsProfileEnum.THERAPIST,
  );
  const isMyAccountView = useSelector(state => state.auth.isMyAccountView);
  const user = useSelector(state => state.auth.user);

  useEffect(() => {
    dispatch(getUser());
    user.profiles.forEach(item => {
      if (item.id === user.active_profile_id && item.clinicId !== null) {
        dispatch(getSelf());
      }
    });
  }, []);

  return (
    <Layout>
      <AppHeader />
      <S.Container mediaFirst={firstChange} mediaThird={thirdChange}>
        <div
          className="container-app-content"
          style={{ flexDirection: firstChange ? 'row' : 'column' }}
        >
          <Sider
            style={{
              flexDirection: 'column',
              backgroundColor: 'var(--guyabano)',
              height: '80%',
              display: firstChange ? 'flex' : 'none',
            }}
          >
            <PhotoAndClinic />
          </Sider>
          {firstChange === false && (
            <PhotoAndClinic secondChange={secondChange} />
          )}
          <div className="container-patient-content">
            <div>
              {/* não é utilizado o user.active_profile_name aqui porque
                  os componentes já estão feitos observando o
                  user.active_profile_name. Daí se o usuário não for
                  administrador, não será renderizado as informações
                  da clínica.
               */}
              {user.profile === 'RECEPTIONIST' && (
                <>
                  <DataGeneralReceptionist secondChange={secondChange} />
                  <DataClinic secondChange={secondChange} />
                </>
              )}
              {user.profile === 'THERAPIST' && (
                <>
                  <DataGeneralTherapist secondChange={secondChange} />
                  <DataClinic secondChange={secondChange} />
                </>
              )}
              {user.profile === 'CLINIC' && (
                <DataGeneralClinic secondChange={secondChange} />
              )}
            </div>

            <Divider />
            {isClinic && isMyAccountView ? (
              <DataPassword secondChange={secondChange} />
            ) : (
              <DataAdmins secondChange={secondChange} />
            )}
          </div>
        </div>
      </S.Container>
    </Layout>
  );
}

export default MyAccount;
