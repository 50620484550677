export const PropsComorbiditiesLeftArrayTranslated = [
  {
    type: 'comorbidades.dores.cabeca',
    pt: 'Cabeça',
  },
  {
    type: 'comorbidades.dores.costas',
    pt: 'Costas',
  },
  {
    type: 'comorbidades.dores.braco_direito',
    pt: 'Braço Direito',
  },
  {
    type: 'comorbidades.dores.mao_direita',
    pt: 'Mão Direita',
  },
  {
    type: 'comorbidades.dores.perna_direita',
    pt: 'Perna Direita',
  },
  {
    type: 'comorbidades.dores.pe_direito',
    pt: 'Pé Direito',
  },
];

export const PropsComorbiditiesRightArrayTranslated = [
  {
    type: 'comorbidades.dores.tronco',
    pt: 'Tronco',
  },
  {
    type: 'comorbidades.dores.braco_esquerdo',
    pt: 'Braço Esquerdo',
  },
  {
    type: 'comorbidades.dores.mao_esquerda',
    pt: 'Mão Esquerda',
  },
  {
    type: 'comorbidades.dores.perna_esquerda',
    pt: 'Perna Esquerda',
  },
  {
    type: 'comorbidades.dores.pe_esquerdo',
    pt: 'Pé Esquerdo',
  },
  {
    type: 'comorbidades.dores.outro_local.checked',
    pt: 'Outro Local',
  },
];

export const PropsComorbiditiesRightInputArray = [
  {
    from: 'comorbidades.dores.outro_local.checked',
    to: 'comorbidades.dores.outro_local.texto',
    label: 'Qual?',
  },
];

export const PropsMoreComorbiditiesArrayTranslated = [
  {
    type: 'comorbidades.diabetes.checked',
    pt: 'Diabetes',
  },
  {
    type: 'comorbidades.obesidade',
    pt: 'Obesidade',
  },
  {
    type: 'comorbidades.colesterol_alto',
    pt: 'Colesterol Alto',
  },
  {
    type: 'comorbidades.hipertensao',
    pt: 'Hipertensão',
  },
  {
    type: 'comorbidades.dislipidemia',
    pt: 'Dislipidemia',
  },
  {
    type: 'comorbidades.tabagismo.checked',
    pt: 'Tabagismo',
  },
  {
    type: 'comorbidades.doenca_cardiaca.checked',
    pt: 'Doença Cardíaca',
  },
  {
    type: 'comorbidades.doenca_renal.checked',
    pt: 'Doença Renal',
  },
  {
    type: 'comorbidades.doenca_pulmonar',
    pt: 'Doença Pulmonar',
  },
  {
    type: 'comorbidades.doenca_reumatologica',
    pt: 'Doença Reumatológica',
  },
  {
    type: 'comorbidades.outra.checked',
    pt: 'Outra',
  },
];

export const PropsMoreComorbiditiesInputArray = [
  {
    from: 'comorbidades.diabetes.checked',
    to: 'comorbidades.diabetes.texto',
    label: 'Qual?',
  },
  {
    from: 'comorbidades.tabagismo.checked',
    to: 'comorbidades.tabagismo.tempo',
    label: 'Tempo',
  },
  {
    from: 'comorbidades.doenca_cardiaca.checked',
    to: 'comorbidades.doenca_cardiaca.qual',
    label: 'Qual?',
  },
  {
    from: 'comorbidades.doenca_renal.checked',
    to: 'comorbidades.doenca_renal.qual',
    label: 'Qual?',
  },
  {
    from: 'comorbidades.outra.checked',
    to: 'comorbidades.outra.qual',
    label: 'Qual?',
  },
];
