/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useRef, useState } from 'react';
import { Layout, Radio, Button, Tooltip, Modal, Spin } from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import { getPatientAgenda, deleteEvent } from 'store/modules/agenda/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import ArrowLeft from 'assets/arrow-left-grey.svg';
import ArrowRight from 'assets/arrow-right-grey.svg';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import locale from '@fullcalendar/core/locales/pt-br';
import listPlugin from '@fullcalendar/list';
import AppHeader from 'components/Header';
import SiderMenu from 'components/Siders/Professional/Sider';
import trash from 'assets/trash_gray.svg';
import { format, addMonths, parse } from 'date-fns';
import pt from 'date-fns/locale/pt';
import interactionPlugin from '@fullcalendar/interaction';
import {
  TitleAgendaEnum,
  TradutorTitleAgenda,
} from 'utils/titleAgendaConsults';
import { LoadingOutlined } from '@ant-design/icons';
import { FiXCircle, FiClock } from 'react-icons/fi';

import ImgCalendar from 'assets/date.svg';
import ImgNote from 'assets/note.svg';
import ImgPin from 'assets/pin.svg';
import closeIcon from 'assets/close.svg';

import './styles.css';
import { getPatientDetails } from 'store/modules/patient/actions';
import * as S from './styles';

function Agenda() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const refCalendar = useRef(null);
  const [month, setMonth] = useState(new Date());
  const [daySelected, setDaySelected] = useState(new Date());
  const [infoCalendar, setInfoCalendar] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalId, setModalId] = useState('');
  const history = useHistory();

  const loadingAgenda = useSelector(state => state.agenda.loading);
  const dataAgenda = useSelector(state => state.agenda.agenda);

  const firstChange = useMediaQuery('(min-width: 900px)');
  const descCalendarChange = useMediaQuery('(min-width: 1100px)');
  const calendarChange = useMediaQuery('(min-width: 815px)');
  const secondChange = useMediaQuery('(min-width: 500px)');

  useEffect(() => {
    dispatch(getPatientDetails(id));
  }, []);

  useEffect(() => {
    if (calendarChange === false) {
      refCalendar.current.getApi().changeView('listMonth');
    } else {
      refCalendar.current.getApi().changeView('dayGridMonth');
    }
  }, [calendarChange]);

  useEffect(() => {
    const monthCurrent = format(month, 'MM', { locale: pt });
    const yearCurrent = format(month, 'yyyy');
    dispatch(getPatientAgenda(id, monthCurrent, yearCurrent));
  }, [month]);

  useEffect(() => {
    if (dataAgenda !== null) {
      setInfoCalendar(
        dataAgenda.map(
          item =>
            item && {
              title: item.title,
              date: format(new Date(item.timestamp), 'yyyy-MM-dd', {
                locale: pt,
              }),
              details: item.details,
              local: item.local,
              eventId: item.id,
              professionalName: item.professionalName,
              horary: format(new Date(item.timestamp), 'HH:mm', {
                locale: pt,
              }),
              duration: item.duration,
            },
        ),
      );
    }
  }, [dataAgenda]);

  function selectColorForEventCalendar(title) {
    if (title === TitleAgendaEnum.DOMICILIAR) {
      return '#79E5FF';
    }

    if (title === TitleAgendaEnum.PRESENCIAL) {
      return '#FFC465';
    }

    if (title === TitleAgendaEnum.TELECHAMADA) {
      return '#FFA8E4';
    }

    return '#FFC465';
  }

  function renderEventContent(eventInfo) {
    return (
      <S.DivContainerEvent
        onClick={() => setDaySelected(eventInfo.event.start)}
      >
        <S.DivColorEvent
          style={{
            backgroundColor: selectColorForEventCalendar(eventInfo.event.title),
          }}
        />
        <S.EventAgenda descCalendarChange={descCalendarChange}>
          {TradutorTitleAgenda[eventInfo.event.title]}
        </S.EventAgenda>
      </S.DivContainerEvent>
    );
  }

  function handleOk(eventId, flag) {
    dispatch(deleteEvent(id, eventId, flag, month));
    setIsModalVisible(false);
  }

  function handleCancel() {
    setIsModalVisible(false);
  }

  function openModal(idMod) {
    setModalId(idMod);
    setIsModalVisible(true);
  }

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <Layout>
      <AppHeader />
      <SiderMenu>
        <S.DivContainer firstChange={firstChange}>
          <S.DivHeader>
            <S.TitlePage>Agenda</S.TitlePage>
            <Radio.Group style={S.AntdStyles.RadioGroup}>
              <Radio.Button
                style={{
                  width: secondChange ? '3.5rem' : '1.75rem',
                  paddingRight: secondChange ? '0' : '18px',
                  ...S.AntdStyles.ButtonLeft,
                }}
                onClick={() => {
                  setMonth(prevState => addMonths(prevState, -1));
                  refCalendar.current.getApi().prev();
                }}
              >
                <S.ImgRadioButton src={ArrowLeft} alt="#left" />
              </Radio.Button>
              <Radio.Button
                style={{
                  width: secondChange ? '3.5rem' : '1.75rem',
                  ...S.AntdStyles.ButtonRight,
                }}
                onClick={() => {
                  setMonth(prevState => addMonths(prevState, 1));
                  refCalendar.current.getApi().next();
                }}
              >
                <S.ImgRadioButton src={ArrowRight} alt="#right" />
              </Radio.Button>
            </Radio.Group>
          </S.DivHeader>

          <S.DivSubTitle
            style={{
              flexDirection: secondChange ? 'row' : 'column',
              alignItems: secondChange ? 'center' : 'flex-start',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <S.TitleSession
                style={{
                  marginBottom: secondChange ? '0px' : '1rem',
                  marginRight: '10px',
                  height: '1.8rem',
                  paddingTop: '5px',
                }}
              >
                {format(month, 'LLLL yyyy', {
                  locale: pt,
                })}
              </S.TitleSession>
              {(loadingAgenda === true || infoCalendar === null) && (
                <Spin indicator={antIcon} size="small" />
              )}
            </div>

            <Button
              type="primary"
              htmlType="submit"
              onClick={() => history.push(`/${id}/agenda/add_event`)}
              style={S.AntdStyles.ButtonAddEvent}
            >
              Adicionar Evento
            </Button>
          </S.DivSubTitle>

          <S.DivCalendar>
            <FullCalendar
              plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
              initialView="dayGridMonth"
              events={infoCalendar}
              eventContent={renderEventContent}
              eventColor="#f7f7f7"
              locale={locale}
              headerToolbar={false}
              ref={refCalendar}
              dayMaxEvents={2}
              showNonCurrentDates={false}
              contentHeight="80vh"
              fixedWeekCount={false}
              expandRows
              dateClick={info =>
                setDaySelected(
                  parse(info.dateStr, 'yyyy-MM-dd', new Date(), {
                    locale: pt,
                  }),
                )
              }
            />
          </S.DivCalendar>

          <S.LabelDate>
            {format(daySelected, "dd 'de' LLLL ", { locale: pt })}
          </S.LabelDate>
          <S.LabelDate style={{ fontWeight: 'normal', color: '#B1B1B1' }}>
            |
          </S.LabelDate>
          <S.LabelDate>
            {format(daySelected, ' EEEE', { locale: pt })}
          </S.LabelDate>

          {infoCalendar !== null &&
            infoCalendar
              .filter(
                item =>
                  item &&
                  item.date ===
                    format(daySelected, 'yyyy-MM-dd', { locale: pt }),
              )
              .map(
                item =>
                  item && (
                    <S.CardActivityAgenda titleCard={item.title}>
                      <S.ContainerTitleCard>
                        <S.TitleCard>
                          {TradutorTitleAgenda[item.title]}
                        </S.TitleCard>
                        {item.professionalName !== 'CONFIDENTIAL' && (
                          <Tooltip>
                            <img
                              src={trash}
                              onClick={() => {
                                openModal(item.eventId);
                              }}
                              style={{ cursor: 'pointer' }}
                              alt="Excluir evento"
                            />
                          </Tooltip>
                        )}
                      </S.ContainerTitleCard>
                      {item.title !== 'DOMESTIC' ? (
                        <S.DivRowCard>
                          <S.MinImage>
                            <S.ImgCard src={ImgCalendar} alt="calendário" />
                          </S.MinImage>
                          <S.DescCard>{item.horary}</S.DescCard>
                        </S.DivRowCard>
                      ) : (
                        <S.DivRowCard />
                      )}
                      {item.title !== 'DOMESTIC' && (
                        <S.DivRowCard>
                          <S.MinImage>
                            <FiClock color="#d5d5d5" size={18} />
                          </S.MinImage>
                          <S.DescCard>{`${item.duration} minutos`}</S.DescCard>
                        </S.DivRowCard>
                      )}
                      {item.local !== '' &&
                        item.local !== undefined &&
                        item.local !== null && (
                          <S.DivRowCard>
                            <S.MinImage>
                              <S.ImgCard src={ImgPin} alt="pin" />
                            </S.MinImage>
                            <S.DescCard>{item.local}</S.DescCard>
                          </S.DivRowCard>
                        )}
                      {item.details !== '' &&
                        item.details !== undefined &&
                        item.details !== null && (
                          <S.DivRowCard>
                            <S.MinImage>
                              <S.ImgCard src={ImgNote} alt="nota" />
                            </S.MinImage>
                            <S.DescCard>{item.details}</S.DescCard>
                          </S.DivRowCard>
                        )}
                      {item.professionalName === 'CONFIDENTIAL' && (
                        <S.DivRowCard>
                          <S.MinImage>
                            <FiXCircle color="#d5d5d5" size={20} />
                          </S.MinImage>
                          <S.DescCard>
                            Horário bloqueado por outro profissional
                          </S.DescCard>
                        </S.DivRowCard>
                      )}
                      <Modal
                        title="Remoção de Evento(s)"
                        visible={isModalVisible}
                        onCancel={handleCancel}
                        width={470}
                        closeIcon={
                          <img
                            src={closeIcon}
                            alt="close"
                            style={S.AntdStyles.iconClose}
                          />
                        }
                        footer={[
                          <Button
                            key="back"
                            onClick={handleCancel}
                            style={{
                              margin: '5px',
                              borderRadius: '5px',
                              borderColor: '#118E88',
                              color: '#118E88',
                            }}
                          >
                            Cancelar
                          </Button>,
                          <Button
                            key="submit"
                            type="primary"
                            style={{
                              borderRadius: '5px',
                              backgroundColor: '#118E88',
                              borderColor: '#118E88',
                              margin: '5px',
                            }}
                            onClick={() => handleOk(modalId, false)}
                          >
                            Excluir Evento
                          </Button>,
                          <Button
                            key="link"
                            type="primary"
                            style={{
                              borderRadius: '5px',
                              backgroundColor: '#118E88',
                              borderColor: '#118E88',
                              margin: '5px',
                            }}
                            onClick={() => handleOk(item.eventId, true)}
                          >
                            Excluir Todos Associados
                          </Button>,
                        ]}
                      >
                        <span>
                          Se você deseja excluir apenas o evento deste dia
                          selecionado clique em <b>Excluir Evento</b>.
                        </span>
                        <br />
                        <span>
                          Caso deseje excluir todos os eventos (inclusive o
                          selecionado) que foram criados e que estão associados
                          a este, clique em <b>Excluir Todos Associados</b>.
                        </span>
                      </Modal>
                    </S.CardActivityAgenda>
                  ),
              )}

          {infoCalendar !== null &&
            infoCalendar.filter(
              item =>
                item &&
                item.date === format(daySelected, 'yyyy-MM-dd', { locale: pt }),
            ).length === 0 && (
              <S.NoActivityAgenda>
                <S.NoActivityText>
                  Não há atividades nessa data.
                </S.NoActivityText>
              </S.NoActivityAgenda>
            )}
        </S.DivContainer>
      </SiderMenu>
    </Layout>
  );
}

export default Agenda;
