/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';

import Dropzone from 'react-dropzone';
import IconGallery from 'assets/gallery.svg';

import { DropContainer, UploadMessage } from './styles';

export default class Upload extends Component {
  renderDragMessage = (isDragActive, isDragReject) => {
    if (!isDragActive) {
      return (
        <div
          style={{
            width: '35.4rem',
            justifyContent: 'center',
            alignItems: 'center',
            height: '6rem',
            textAlign: 'center',
          }}
        >
          <img alt="send" src={IconGallery} style={{ paddingTop: '2.5rem' }} />
        </div>
      );
    }

    if (isDragReject) {
      return <UploadMessage type="error">Arquivo não suportado</UploadMessage>;
    }

    return <UploadMessage type="success">Solte os arquivos aqui</UploadMessage>;
  };

  render() {
    const { onUpload } = this.props;

    return (
      <Dropzone accept="video/*" onDropAccepted={onUpload}>
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
          <DropContainer
            {...getRootProps()}
            isDragActive={isDragActive}
            isDragReject={isDragReject}
          >
            <input {...getInputProps()} />
            {this.renderDragMessage(isDragActive, isDragReject)}
          </DropContainer>
        )}
      </Dropzone>
    );
  }
}
