export const labelAMonitoring = '#9A91DB';
export const labelBMonitoring = '#fcaf00';

/* Layouts */

export const defaultLayout = {
  font: {
    family: 'Helvetica',
  },
  title: {
    font: {
      size: 12,
    },
  },
  showlegend: false,
  width: 550,
  height: 280,
  margin: { l: 15, t: 15, r: 10, b: 65 },
  xaxis: {
    showticklabels: true,
    ticks: '',
  },
  yaxis: {
    showticklabels: false,
    ticks: '',
    showline: true,
  },
  paper_bgcolor: 'transparent',
  plot_bgcolor: 'transparent',
};

export const stanceSwingLayout = {
  font: {
    family: 'Helvetica',
  },
  title: {
    font: {
      size: 12,
    },
  },
  showlegend: false,
  width: 300,
  height: 250,
  margin: { l: 30, t: 10, r: 15, b: 75 },
  xaxis: {
    showticklabels: true,
    ticks: '',
    range: [-0.5, 10],
    showline: true,
    tickangle: 65,
  },
  yaxis: {
    showticklabels: true,
    ticks: '',
    showline: true,
    range: [-0.5, 100],
  },
  paper_bgcolor: 'transparent',
  plot_bgcolor: 'transparent',
};

export const comparativeLayout = {
  font: {
    family: 'Helvetica',
  },
  title: {
    font: {
      size: 12,
    },
  },
  showlegend: false,
  width: 300,
  height: 250,
  margin: { l: 30, t: 20, r: 10, b: 15 },
  xaxis: {
    showticklabels: true,
    ticks: '',
  },
  yaxis: {
    showticklabels: true,
    ticks: '',
  },
  paper_bgcolor: 'transparent',
  plot_bgcolor: 'transparent',
};

export const evolutionsLayout = {
  font: {
    family: 'Helvetica',
  },
  title: {
    font: {
      size: 12,
    },
  },
  showlegend: false,
  width: 300,
  height: 250,
  margin: { l: 30, t: 10, r: 25, b: 85 },
  xaxis: {
    showticklabels: true,
    ticks: '',
    showline: false,
    tickangle: 65,
  },
  yaxis: {
    showticklabels: true,
    ticks: '',
    showline: true,
    range: [0, 1.5],
  },
  paper_bgcolor: 'transparent',
  plot_bgcolor: 'transparent',
};

export const cadencyLayout = {
  font: {
    family: 'Helvetica',
  },
  title: {
    font: {
      size: 12,
    },
  },
  showlegend: false,
  width: 300,
  height: 250,
  margin: { l: 30, t: 10, r: 25, b: 85 },
  xaxis: {
    showticklabels: true,
    ticks: '',
    showline: false,
    tickangle: 65,
  },
  yaxis: {
    showticklabels: true,
    ticks: '',
    showline: true,
    range: [-0.2, 2],
  },
  paper_bgcolor: 'transparent',
  plot_bgcolor: 'transparent',
};

export const stepLengthLayout = {
  font: {
    family: 'Helvetica',
  },
  title: {
    font: {
      size: 12,
    },
  },
  showlegend: false,
  width: 250,
  height: 250,
  margin: { l: 30, t: 10, r: 27, b: 85 },
  xaxis: {
    showticklabels: true,
    ticks: '',
    showline: false,
    tickangle: 65,
  },
  yaxis: {
    showticklabels: true,
    ticks: '',
    showline: true,
    range: [-20, 100],
  },
  paper_bgcolor: 'transparent',
  plot_bgcolor: 'transparent',
};

/* Functions */

export function graph(x, y) {
  const dashLine = {
    x,
    y,
    mode: 'lines+markers',
    name: '',
    marker: {
      color: labelAMonitoring,
    },
  };
  return dashLine;
}

export function graphWColor(x, y, i) {
  const traceColor = i === 1 ? labelAMonitoring : labelBMonitoring;
  const dashLine = {
    x,
    y,
    mode: 'lines+markers',
    name: '',
    marker: {
      color: traceColor,
    },
  };
  return dashLine;
}

export function graphWIndex(x, y, i) {
  const traceColor = i % 2 === 0 ? labelAMonitoring : labelBMonitoring;
  const dashLine = {
    x: x[i],
    y,
    mode: 'lines',
    name: '',
    marker: {
      color: traceColor,
    },
  };
  return dashLine;
}
