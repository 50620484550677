import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 90%;
  padding-top: 20px;
  flex-direction: column;

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
    border: none;
  }

  ::-webkit-scrollbar {
    width: 4.5px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    height: 120px;
    outline: 1.3px solid var(--battleshipGrey);
    border-radius: 10px;
    background-color: var(--battleshipGrey);
  }
`;
