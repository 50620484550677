/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Button } from 'antd';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateEvaluation } from 'store/modules/evaluations/actions';
import TextAndHourInput from '../../components/TextAndHourInput';
import * as S from './styles';

function TFMJT({ title, current_eval }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const qts = current_eval?.content?.questions;

  const forms = [
    { label: '0', value: '0' },
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
  ];

  const question = [
    '1. Virar cartões:',
    '2. Pegar objetos:',
    '3. Pegar feijões:',
    '4. Empilhar fichas:',
    '5. Mover latas leves:',
    '6. Mover latas pesadas:',
  ];

  /* {
    index: index + 1,
    text: item,
    value: [...current_eval.content.questions[index].value],
  }

  let finalValue = [];
        for (let i = 0; i < 6; i += 1){
          finalValue.push(current_eval?.content?.questions[index].value[i]);
        }
        question.map((item, index) => ({
          index: index + 1,
          text: item,
          value: [...current_eval.content.questions[index].value],
        }))
        */

  function findValues(index, searchPlace) {
    const tempArray = [];
    switch (index) {
      case 0:
        for (let x = 0; x < 6; x += 1) {
          tempArray.push(searchPlace[x].value);
        }
        return tempArray;
      case 1:
        for (let x = 6; x < 12; x += 1) {
          tempArray.push(searchPlace[x].value);
        }
        return tempArray;
      case 2:
        for (let x = 12; x < 18; x += 1) {
          tempArray.push(searchPlace[x].value);
        }
        return tempArray;
      case 3:
        for (let x = 18; x < 24; x += 1) {
          tempArray.push(searchPlace[x].value);
        }
        return tempArray;
      case 4:
        for (let x = 24; x < 30; x += 1) {
          tempArray.push(searchPlace[x].value);
        }
        return tempArray;
      case 5:
        for (let x = 30; x < 36; x += 1) {
          tempArray.push(searchPlace[x].value);
        }
        return tempArray;
      default:
        break;
    }
    return ['', '', '', '', '', ''];
  }

  const [formValues, setFormValues] = useState(
    current_eval?.content?.questions.length > 0
      ? question.map((item, index) => ({
          index: index + 1,
          text: item,
          value: findValues(index, current_eval.content.questions),
        }))
      : question.map((item, index) => ({
          index: index + 1,
          text: item,
          value: ['00:00', '00:00', '00:00', '00:00', '00:00', '00:00'],
        })),
  );

  function updateValues(isFinal) {
    const tempFinalValue = [];
    let tempIndex = 0;
    for (let i = 0; i < formValues.length; i += 1) {
      for (let t = 0; t < formValues[i].value.length; t += 1) {
        tempFinalValue.push({
          index: tempIndex,
          text: '',
          value: formValues[i].value[t],
        });
        tempIndex += 1;
      }
    }
    const tempFormValue = {
      id: current_eval?.id,
      isFinished: isFinal,
      name: title,
      professionalId: current_eval?.professionalId,
      result: null,
      content: {
        questions: tempFinalValue,
      },
    };
    dispatch(updateEvaluation(tempFormValue, id));
    if (isFinal) {
      setFormValues(
        question.map((item, index) => ({
          index: index + 1,
          text: item,
          value: ['00:00', '00:00', '00:00', '00:00', '00:00', '00:00'],
        })),
      );
    }
  }

  useEffect(() => {
    const valuesFormValues = Object.values(formValues);
    if (
      valuesFormValues.find(
        item =>
          JSON.stringify(item.value) !==
          JSON.stringify([
            '00:00',
            '00:00',
            '00:00',
            '00:00',
            '00:00',
            '00:00',
          ]),
      )
    ) {
      updateValues(false);
    }
  }, [formValues]);

  return (
    <Formik
      initialValues={{
        Answer1Healthy1: qts[0] !== undefined ? qts[0].value[0] : '',
        Answer1Healthy2: qts[0] !== undefined ? qts[0].value[1] : '',
        Answer1Healthy3: qts[0] !== undefined ? qts[0].value[2] : '',
        Answer1Affected1: qts[0] !== undefined ? qts[0].value[3] : '',
        Answer1Affected2: qts[0] !== undefined ? qts[0].value[4] : '',
        Answer1Affected3: qts[0] !== undefined ? qts[0].value[5] : '',
        Answer2Healthy1: qts[1] !== undefined ? qts[1].value[0] : '',
        Answer2Healthy2: qts[1] !== undefined ? qts[1].value[1] : '',
        Answer2Healthy3: qts[1] !== undefined ? qts[1].value[2] : '',
        Answer2Affected1: qts[1] !== undefined ? qts[1].value[3] : '',
        Answer2Affected2: qts[1] !== undefined ? qts[1].value[4] : '',
        Answer2Affected3: qts[1] !== undefined ? qts[1].value[5] : '',
        Answer3Healthy1: qts[2] !== undefined ? qts[2].value[0] : '',
        Answer3Healthy2: qts[2] !== undefined ? qts[2].value[1] : '',
        Answer3Healthy3: qts[2] !== undefined ? qts[2].value[2] : '',
        Answer3Affected1: qts[2] !== undefined ? qts[2].value[3] : '',
        Answer3Affected2: qts[2] !== undefined ? qts[2].value[4] : '',
        Answer3Affected3: qts[2] !== undefined ? qts[2].value[5] : '',
        Answer4Healthy1: qts[3] !== undefined ? qts[3].value[0] : '',
        Answer4Healthy2: qts[3] !== undefined ? qts[3].value[1] : '',
        Answer4Healthy3: qts[3] !== undefined ? qts[3].value[2] : '',
        Answer4Affected1: qts[3] !== undefined ? qts[3].value[3] : '',
        Answer4Affected2: qts[3] !== undefined ? qts[3].value[4] : '',
        Answer4Affected3: qts[3] !== undefined ? qts[3].value[5] : '',
        Answer5Healthy1: qts[4] !== undefined ? qts[4].value[0] : '',
        Answer5Healthy2: qts[4] !== undefined ? qts[4].value[1] : '',
        Answer5Healthy3: qts[4] !== undefined ? qts[4].value[2] : '',
        Answer5Affected1: qts[4] !== undefined ? qts[4].value[3] : '',
        Answer5Affected2: qts[4] !== undefined ? qts[4].value[4] : '',
        Answer5Affected3: qts[4] !== undefined ? qts[4].value[5] : '',
        Answer6Healthy1: qts[5] !== undefined ? qts[5].value[0] : '',
        Answer6Healthy2: qts[5] !== undefined ? qts[5].value[1] : '',
        Answer6Healthy3: qts[5] !== undefined ? qts[5].value[2] : '',
        Answer6Affected1: qts[5] !== undefined ? qts[5].value[3] : '',
        Answer6Affected2: qts[5] !== undefined ? qts[5].value[4] : '',
        Answer6Affected3: qts[5] !== undefined ? qts[5].value[5] : '',
      }}
      onSubmit={(values, actions) => {
        // eslint-disable-next-line prefer-const
        let valuesSubmit = {
          name: title,
          content: {
            questions: [],
          },
        };

        question.forEach((item, index) => {
          for (let i = 1; i < 4; i += 1) {
            valuesSubmit.content.questions.push({
              index: index + 1,
              text: `${item
                .replace('1. ', '')
                .replace(':', '')} - Saudável - Medida ${i}`,
              value: [values[`Answer${index + 1}Healthy${i}`]],
            });
          }

          for (let i = 1; i < 4; i += 1) {
            valuesSubmit.content.questions.push({
              index: index + 1,
              text: `${item
                .replace('1. ', '')
                .replace(':', '')} - Afetado - Medida ${i}`,
              value: [values[`Answer${index + 1}Affected${i}`]],
            });
          }
        });

        actions.resetForm({
          values: {
            Answer1Healthy1: '',
            Answer1Healthy2: '',
            Answer1Healthy3: '',
            Answer1Affected1: '',
            Answer1Affected2: '',
            Answer1Affected3: '',
            Answer2Healthy1: '',
            Answer2Healthy2: '',
            Answer2Healthy3: '',
            Answer2Affected1: '',
            Answer2Affected2: '',
            Answer2Affected3: '',
            Answer3Healthy1: '',
            Answer3Healthy2: '',
            Answer3Healthy3: '',
            Answer3Affected1: '',
            Answer3Affected2: '',
            Answer3Affected3: '',
            Answer4Healthy1: '',
            Answer4Healthy2: '',
            Answer4Healthy3: '',
            Answer4Affected1: '',
            Answer4Affected2: '',
            Answer4Affected3: '',
            Answer5Healthy1: '',
            Answer5Healthy2: '',
            Answer5Healthy3: '',
            Answer5Affected1: '',
            Answer5Affected2: '',
            Answer5Affected3: '',
            Answer6Healthy1: '',
            Answer6Healthy2: '',
            Answer6Healthy3: '',
            Answer6Affected1: '',
            Answer6Affected2: '',
            Answer6Affected3: '',
          },
        });

        updateValues(true);
      }}
    >
      {props => (
        <>
          {question.map(
            (titleQuestion, indexQuestion) =>
              titleQuestion && (
                <>
                  <S.LabelText>{titleQuestion}</S.LabelText>
                  <TextAndHourInput
                    text="Tempo de realização com o membro saudável (máx 2 min)"
                    answerKey={`Answer${indexQuestion + 1}Healthy`}
                    answerFunc={props.setFieldValue}
                    setFormValues={setFormValues}
                    formValues={formValues}
                    index={indexQuestion}
                    type="Healthy"
                    currentValue={[
                      formValues[indexQuestion].value[0],
                      formValues[indexQuestion].value[1],
                      formValues[indexQuestion].value[2],
                    ]}
                    propsFormik={props}
                  />
                  <TextAndHourInput
                    text="Tempo de realização com o membro afetado (máx 2 min)"
                    answerKey={`Answer${indexQuestion + 1}Affected`}
                    answerFunc={props.setFieldValue}
                    setFormValues={setFormValues}
                    formValues={formValues}
                    index={indexQuestion}
                    type="Affected"
                    currentValue={[
                      formValues[indexQuestion].value[3],
                      formValues[indexQuestion].value[4],
                      formValues[indexQuestion].value[5],
                    ]}
                    propsFormik={props}
                  />
                </>
              ),
          )}

          <Button
            type="primary"
            htmlType="submit"
            onClick={props.handleSubmit}
            style={S.AntdStyles.ButtonSave}
          >
            Salvar
          </Button>
        </>
      )}
    </Formik>
  );
}

export default TFMJT;
