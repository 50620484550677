import produce from 'immer';

const INITIAL_STATE = {
  address: null,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@CEP/SET_ADDRESS_BY_CEP': {
        draft.address = action.payload.address;
        break;
      }

      default:
    }
  });
}
