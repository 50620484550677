import styled from 'styled-components';

export const AntdStyles = {
  iconClose: { marginRight: 8, width: 15, marginBottom: 4 },
};
export const ContainerVideoSelectedOfProtocol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: 400px;
  overflow-x: hidden;
`;

export const ContainerInfoVideoSelectedOfProtocol = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

export const ElementInfoVideoSelectedOfProtocol = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;
  height: auto;
  padding: 5px;
  align-items: center;
`;

export const BigElementInfoVideoSelectedOfProtocol = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  height: auto;
  padding: 5px;
  align-items: center;
  span {
    width: fit-content;
  }
`;

export const ElementInputVideoSelectedOfProtocol = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 45%;
  height: auto;
  padding: 5px;
  margin-top: 5px;
`;
