export function getSelf() {
  return {
    type: '@professional/GET_SELF',
  };
}

export function getSilentSelf() {
  return {
    type: '@professional/GET_SILENT_SELF',
  };
}

export function setSelf(prof_self) {
  return {
    type: '@professional/SET_SELF',
    payload: { prof_self },
  };
}

export function uploadAvatar(file, isShowMessage = true) {
  return {
    type: '@professional/UPLOAD_AVATAR',
    payload: { file, isShowMessage },
  };
}

export function removeAvatar() {
  return {
    type: '@professional/REMOVE_AVATAR',
  };
}

export function createProfessional(professional, typePro) {
  return {
    type: '@professional/CREATE_PROFESSIONAL',
    payload: { professional, typePro },
  };
}

export function archiveProfessional(professionalsID) {
  return {
    type: '@professional/ARCHIVE_PROFESSIONAL',
    payload: { professionalsID },
  };
}

export function unarchiveProfessional(professionalsID) {
  return {
    type: '@professional/UNARCHIVE_PROFESSIONAL',
    payload: { professionalsID },
  };
}

export function sendMsgProfModal(type) {
  return {
    type: '@professional/SEND_MSG_MODAL',
    payload: { type },
  };
}
