import { Divider } from 'antd';

export const divider = (
  <div style={{ width: '90%' }}>
    <Divider
      style={{
        margin: '0px',
        width: '70%',
        borderBottom: '1px solid #00605B',
        opacity: '0.5',
      }}
    />
  </div>
);
