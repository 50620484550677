import React, { useCallback, memo } from 'react';
import { useSelector } from 'react-redux';
import { SavedAnswerLabel } from './styles';

function SavedAnswer({ resource, propsFormik, fieldName, style }) {
  const resources = useSelector(state => state.auth?.user?.resource);

  const getValueObjectNested = useCallback((objectFormik, path) => {
    const pathObjectSplitted = path.split('.');
    let value = objectFormik;
    for (let i = 0; i < pathObjectSplitted.length; i += 1) {
      value = value[pathObjectSplitted[i]];
    }
    return value;
  }, []);

  return resources[resource] &&
    getValueObjectNested(propsFormik.values, fieldName) !==
      getValueObjectNested(propsFormik.initialValues, fieldName) ? (
    <SavedAnswerLabel style={style}>✓ Resposta Salva</SavedAnswerLabel>
  ) : null;
}

export default memo(SavedAnswer);
