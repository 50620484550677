/* eslint-disable no-lonely-if */
import React, { useState, useEffect } from 'react';
import * as S from './styles';

export function SelectionItem(props) {
  const { indexSelection } = props;
  const { handleEvalSelected } = props;
  const { currentProtocol } = props;
  const [selectionItems, setSelectionItems] = useState([]);

  const emptySelection = (type, number, index, prot) => (
    <li>
      {type === 'filled' && (
        <S.FilledItem onClick={() => handleEvalSelected(index, prot)} />
      )}
      {type === 'empty' && (
        <S.EmptyItem onClick={() => handleEvalSelected(index, prot)} />
      )}
      <>{number}</>
    </li>
  );

  useEffect(() => {
    const tempArray = [];
    let tempIndex = 0;
    if (indexSelection !== null) {
      tempIndex = indexSelection;
    }

    for (let x = 0; x < 11; x += 1) {
      // tempIndex é o valor pontuado
      if (x <= tempIndex) {
        // de 0 até o valor pontuado
        // não passar do limite
        if (x < 10) {
          tempArray.push(emptySelection('filled', `0${x}`, x, currentProtocol));
        } else {
          tempArray.push(emptySelection('filled', `${x}`, x, currentProtocol));
        }
      } else {
        if (x < 10) {
          tempArray.push(emptySelection('empty', `0${x}`, x, currentProtocol));
        } else {
          tempArray.push(emptySelection('empty', `${x}`, x, currentProtocol));
        }
      }
    }

    setSelectionItems(tempArray);
  }, [indexSelection, currentProtocol]);

  return (
    <S.ContainerSelectionItem>
      {selectionItems.map(item => item)}
    </S.ContainerSelectionItem>
  );
}
