export const TitleAgendaEnum = {
  DOMICILIAR: 'DOMESTIC',
  PRESENCIAL: 'PRESENTIAL',
  TELECHAMADA: 'INLIVE',
};

export const TradutorTitleAgenda = {
  [TitleAgendaEnum.DOMICILIAR]: 'Domiciliar',
  [TitleAgendaEnum.PRESENCIAL]: 'Presencial',
  [TitleAgendaEnum.TELECHAMADA]: 'Telechamada',
};

export const TradutorTitleConsults = {
  [TitleAgendaEnum.DOMICILIAR]: 'Sessão Domiciliar',
  [TitleAgendaEnum.PRESENCIAL]: 'Sessão Presencial',
  [TitleAgendaEnum.TELECHAMADA]: 'Sessão Via Telechamada',
};
