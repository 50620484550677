/* eslint-disable no-unreachable */
import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Button } from 'antd';
import { updateEvaluation } from 'store/modules/evaluations/actions';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import TextAndRadioGroup from '../../components/TextAndRadioGroup';
import * as S from './styles';

function LEFS({ title, current_eval }) {
  const Questions = [
    '1. Qualquer uma do seu trabalho normal, tarefas domésticas ou nas atividades escolares',
    '2. Seu passatempo predileto, atividades recreacionais ou esportivas',
    '3. Entrar ou sair do banho',
    '4. Caminhar entre os quartos',
    '5. Calçar seus sapatos ou meias',
    '6. Agachar-se',
    '7. Levantar um objeto, como uma sacola de compra do chão',
    '8. Realizar atividades domésticas pesadas leves',
    '9. Realizar atividades domésticas pesadas',
    '10. Entrar ou sair do carro',
    '11. Andar dois quarteirões',
    '12. Andar aproximadamente 1,5 Km',
    '13. Subir ou descer 10 degraus (aproximadamente um lance de escadas)',
    '14. Ficar em pé durante uma hora',
    '15. Ficar sentado durante uma hora',
    '16. Correr em terreno plano',
    '17. Correr em terreno irregular',
    '18. Mudar de direção enquanto corre rapidamente',
    '19. Pular',
    '20. Rolar na cama',
  ];

  const qts = current_eval?.content?.questions;

  const dispatch = useDispatch();
  const { id } = useParams();
  const [formValues, setFormValues] = useState(
    current_eval?.content?.questions.length > 0
      ? Questions.map((item, index) => ({
          index: index + 1,
          text: item,
          value: current_eval.content.questions[index].value,
        }))
      : Questions.map((item, index) => ({
          index: index + 1,
          text: item,
          value: '',
        })),
  );

  function updateValues(isFinal) {
    const tempFormValue = {
      id: current_eval?.id,
      isFinished: isFinal,
      name: title,
      professionalId: current_eval?.professionalId,
      result: null,
      content: {
        questions: formValues,
      },
    };
    dispatch(updateEvaluation(tempFormValue, id));

    if (isFinal) {
      setFormValues(
        Questions.map((item, index) => ({
          index: index + 1,
          text: item,
          value: '',
        })),
      );
    }
  }

  useEffect(() => {
    // previne o envio do formulário vazio
    const valuesFormValues = Object.values(formValues);
    if (valuesFormValues.find(item => item.value !== '')) {
      updateValues(false);
    }
  }, [formValues]);

  const forms = [
    { label: '0', value: '0' },
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
  ];

  return (
    <Formik
      initialValues={{
        Answer1: qts[0] !== undefined ? qts[0].value : '',
        Answer2: qts[1] !== undefined ? qts[1].value : '',
        Answer3: qts[2] !== undefined ? qts[2].value : '',
        Answer4: qts[3] !== undefined ? qts[3].value : '',
        Answer5: qts[4] !== undefined ? qts[4].value : '',
        Answer6: qts[5] !== undefined ? qts[5].value : '',
        Answer7: qts[6] !== undefined ? qts[6].value : '',
        Answer8: qts[7] !== undefined ? qts[7].value : '',
        Answer9: qts[8] !== undefined ? qts[8].value : '',
        Answer10: qts[9] !== undefined ? qts[9].value : '',
        Answer11: qts[10] !== undefined ? qts[10].value : '',
        Answer12: qts[11] !== undefined ? qts[11].value : '',
        Answer13: qts[12] !== undefined ? qts[12].value : '',
        Answer14: qts[13] !== undefined ? qts[13].value : '',
        Answer15: qts[14] !== undefined ? qts[14].value : '',
        Answer16: qts[15] !== undefined ? qts[15].value : '',
        Answer17: qts[16] !== undefined ? qts[16].value : '',
        Answer18: qts[17] !== undefined ? qts[17].value : '',
        Answer19: qts[18] !== undefined ? qts[18].value : '',
        Answer20: qts[19] !== undefined ? qts[19].value : '',
      }}
      onSubmit={(values, actions) => {
        // eslint-disable-next-line prefer-const
        let valuesSubmit = {
          name: title,
          content: {
            questions: [],
          },
        };

        Questions.forEach((item, index) => {
          valuesSubmit.content.questions.push({
            index: index + 1,
            text: item,
            value: values[`Answer${index + 1}`],
          });
        });

        actions.resetForm({
          values: {
            Answer1: '',
            Answer2: '',
            Answer3: '',
            Answer4: '',
            Answer5: '',
            Answer6: '',
            Answer7: '',
            Answer8: '',
            Answer9: '',
            Answer10: '',
            Answer11: '',
            Answer12: '',
            Answer13: '',
            Answer14: '',
            Answer15: '',
            Answer16: '',
            Answer17: '',
            Answer18: '',
            Answer19: '',
            Answer20: '',
          },
        });

        updateValues(true);
      }}
    >
      {props => (
        <>
          {Questions.map(
            (item, index) =>
              item && (
                <TextAndRadioGroup
                  text={item}
                  ArrayValue={forms}
                  setAnswer={props.setFieldValue}
                  keyAnswer={`Answer${index + 1}`}
                  setFormValues={setFormValues}
                  formValues={formValues}
                  index={index}
                  currentValue={props.values[`Answer${index + 1}`]}
                  propsFormik={props}
                />
              ),
          )}

          <Button
            type="primary"
            htmlType="submit"
            onClick={props.handleSubmit}
            style={S.AntdStyles.ButtonSave}
          >
            Salvar
          </Button>
        </>
      )}
    </Formik>
  );
}

export default LEFS;
