import styled from 'styled-components';

export const ContainerTable = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  tbody {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
`;

export const ImgDropdown = styled.img`
  width: 8px;
  height: 4px;
`;

export const Subtitles = styled.label`
  text-align: left;
  font: normal normal bold 14px/19px Nunito Sans;
  letter-spacing: 0px;
  color: var(--davysGrey);
  opacity: 1;
  margin-top: 1rem;
`;

export const TableOfEvaluations = styled.table`
  display: flex;
  flex-direction: column;
  max-width: 800px;
  min-height: 500px;
  border: 1px solid var(--philippineSilver);
  border-collapse: collapse;
  opacity: 0.8;
  font-size: 1rem;
  overflow-x: scroll;

  thead {
    top: 0;
    left: 0;
    th {
      padding: 1rem 1rem;
      border: 1px solid var(--philippineSilver);
      white-space: nowrap;
      text-align: center;
      width: 170px;
      min-width: 170px;
      z-index: 20;
      background: var(--guyabano);

      &:first-child {
        background: var(--reflection);
        min-width: 200px;
      }
    }
  }

  td {
    border: 1px solid var(--philippineSilver);
    width: 170px;
    min-width: 170px;

    &:first-child {
      padding: 0.2rem;
      min-width: 200px;
    }

    & + td {
      text-align: center;
    }
  }

  &.mal-eval-td {
    flex-direction: column;
  }
`;

export const Table = styled.div`
  display: block;
  max-width: 100%;
  overflow-x: scroll;
`;

export const TitleSession = styled.label`
  opacity: 1;
  color: var(--deepKoamaru);
  text-align: start;
  font: normal normal bold 14px/19px Nunito Sans;
  letter-spacing: 0px;
  background: var(--lavender) 0% 0% no-repeat padding-box;
  border-left: solid var(--deepKoamaru) 2px;
  border-radius: 0 1px 1px 0;
  padding: 3px 8px 3px 5px;
  height: 25px;
`;

export const PDescript = styled.p`
  text-align: left;
  font: normal normal normal 14px/18px Nunito Sans;
  letter-spacing: 0px;
  color: #575757;
  opacity: 1;
  width: 50%;
  margin: 0.75rem 0 0 0.75rem;
`;

export const AntdStyles = {
  marginTop: {
    marginTop: '4px',
  },

  ButtonExams: {
    marginTop: '4px',
    textAlign: 'left',
    fontFamily: 'Nunito Sans',
    display: 'flex',
    minWidth: '200px',
    maxWidth: '450px',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '4px',
  },

  menuExams: {
    maxHeight: 200,
    overflowY: 'scroll',
  },

  RadioButtonSelectVisualization: {
    width: 'auto',
    textAlign: 'center',
    borderColor: '#6864A4',
    borderWidth: '1px',
    backgroundColor: '#f7f7f7',
    fontWeight: 'bold',
    color: '#6864A4',
    borderRadius: '0px 0px 0px 0px',
  },
};
