import styled from 'styled-components';

export const ImgDropdown = styled.img`
  width: 8px;
  height: 4px;
`;

export const Subtitles = styled.label`
  text-align: left;
  font: normal normal bold 14px/19px Nunito Sans;
  letter-spacing: 0px;
  color: var(--davysGrey);
  opacity: 1;
  margin-top: 1rem;
`;

export const ContainerGraphs = styled.div`
  display: flex;
  width: 100%;
  min-height: 320px;
`;

export const SubContainerLegends = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
`;

export const LegendSquare = styled.div`
  width: 12px;
  height: 12px;
  background: ${props => props.lcolor};
`;

export const AntdStyles = {
  marginTop: {
    marginTop: '4px',
  },

  ButtonExams: {
    marginTop: '4px',
    textAlign: 'left',
    fontFamily: 'Nunito Sans',
    display: 'flex',
    minWidth: '200px',
    maxWidth: '400px',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '4px',
  },

  menuExams: {
    maxHeight: 200,
    overflowY: 'scroll',
  },
};
