/* eslint-disable no-unused-vars */
import { useState, useEffect, useContext, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  setAddedActivitiesToProtocol,
  saveProtocol,
} from 'store/modules/protocol/actions';
import { Input, Divider, Button, Modal, AutoComplete, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import closeIcon from 'assets/close.svg';
import CheckBoxWhereProtocol from './CheckBox';
import FormContext from '../Context/FormContext';
import ActivityVideos from './ActitityVideos';
import WaitForDataReceive from './WaitForDataReceive';

import ImgFilterActivities from '../../../../../assets/setting-protocol.svg';

import * as S from './styles';
import './styles.module.css';

let contNumberOfFilters = 0;
let isDataReceived = false;

function ProtocolSetup({
  protocolId = null,
  setScreenInProtocolList,
  edit = false,
}) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { Search } = Input;
  const exeCatalog = useSelector(
    state => state.protocol.patientExerciseCatalog,
  );
  const isBiggerThan = useMediaQuery('(min-width: 1367px)');
  const isBiggerThanOverflow = useMediaQuery('(min-width: 800px)');

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [bodyOpt, setBodyOpt] = useState([]);
  const [goalsOpt, setGoalsOpt] = useState([]);
  const [positionOpt, setPositionOpt] = useState([]);
  const [diffOpt, setDiffOpt] = useState([]);

  const [exerciseCatalog, setShowExerciseCatalog] = useState({
    array1: [],
    array2: [],
    array3: [],
    type: false,
  });

  const [isAllActivities, setIsAllActivities] = useState(true);

  const [whereProtocol, setWhereProtocol] = useState({
    domPract: { enable: true, value: 'home-practice' },
    telePract: { enable: false, value: 'live-sessions' },
  });

  const [filterSearchOptions, setFilterSearchOptions] = useState([]);
  const [stackAddedActivities, setStackAddedActivities] = useState(
    useSelector(state => state.protocol.addedActivitiesToProtocol),
  );
  const protocolsCreated = useSelector(
    state => state.protocol.protocolsCreated,
  );
  const [lastStateCatalog, setLastStateCatalog] = useState(exeCatalog);

  const [protocolTitle, setProtocolTitle] = useState('');

  const [filterS, setFilterStack] = useState({
    body: { values: [], types: [] },
    goals: { values: [], types: [] },
    position: { values: [], types: [] },
    level: { values: [], types: [] },
  });

  const [contActAdded, setContActAdded] = useState(
    useSelector(state => state.protocol.addedActivitiesToProtocol.length),
  );
  const videoAddedWithSuccess = () => {
    message.success({
      content: 'Atividade adicionada com sucesso!',
    });
  };
  const videoRemovedWithSuccess = () => {
    message.success({
      content: 'Atividade removida com sucesso!',
    });
  };
  const coudntAddVideo = () => {
    message.error({
      content: 'Video já se encontra adicionado na lista!',
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handlerExerciseSelected = useCallback(
    (cid, inputs) => {
      // Análise de video já adicionado, senão adiciona video ao protocolo
      if (stackAddedActivities.length > 0) {
        for (let q = 0; q < stackAddedActivities.length; q += 1) {
          if (cid === stackAddedActivities[q].cid) {
            coudntAddVideo(); // vídeo já existe
            break;
          }
          if (q === stackAddedActivities.length - 1) {
            for (let x = 0; x < exeCatalog.length; x += 1) {
              if (cid === exeCatalog[x].cid) {
                const tmpIndex = {
                  categories: exeCatalog[x].categories,
                  cid: exeCatalog[x].cid,
                  name: exeCatalog[x].name,
                  repetitions: parseInt(inputs.repetitions, 10),
                  series: parseInt(inputs.series, 10),
                  video_url: exeCatalog[x].video_url,
                  catalogId: exeCatalog[x].cid,
                };
                setStackAddedActivities([...stackAddedActivities, tmpIndex]);
                videoAddedWithSuccess();
                setContActAdded(contActAdded + 1);
                renderActivityVideos(exeCatalog, false);
                dispatch(
                  setAddedActivitiesToProtocol([
                    ...stackAddedActivities,
                    tmpIndex,
                  ]),
                );
                break;
              }
            }
          }
        }
      } else {
        // Protocolo vazio, adiciona direto
        for (let x = 0; x < exeCatalog.length; x += 1) {
          if (cid === exeCatalog[x].cid) {
            const tmpIndex = {
              categories: exeCatalog[x].categories,
              cid: exeCatalog[x].cid,
              name: exeCatalog[x].name,
              repetitions: parseInt(inputs.repetitions, 10),
              series: parseInt(inputs.series, 10),
              video_url: exeCatalog[x].video_url,
              catalogId: exeCatalog[x].cid,
            };
            setStackAddedActivities([...stackAddedActivities, tmpIndex]);
            videoAddedWithSuccess();
            setContActAdded(contActAdded + 1);
            renderActivityVideos(exeCatalog, false);
            dispatch(
              setAddedActivitiesToProtocol([...stackAddedActivities, tmpIndex]),
            );
            break;
          }
        }
      }
    },
    [stackAddedActivities, contActAdded],
  );

  function removeAddedActivityOfProtocol(cid) {
    const oldStack = [...stackAddedActivities];
    for (let x = 0; x < stackAddedActivities.length; x += 1) {
      if (cid === stackAddedActivities[x].cid) {
        const finalNewStack = oldStack
          .slice(0, x)
          .concat(oldStack.slice(x + 1, oldStack.length));
        setStackAddedActivities(finalNewStack);
        renderActivityVideos(finalNewStack, true);
        videoRemovedWithSuccess();
        setContActAdded(contActAdded - 1);
        break;
      }
    }
  }

  /**
   * @param {*} catalog é o catálogo que será atualizado na tela
   * @param {*} flag para modo adicionar video o remover
   */
  function renderActivityVideos(catalog, flag) {
    let contArray1 = 2;
    let contArray2 = 1;
    let contArray3 = 0;
    const arrayExe1 = [];
    const arrayExe2 = [];
    const arrayExe3 = [];
    if (catalog !== null && catalog.length > 0) {
      for (let x = 0; x < catalog.length; x += 1) {
        if (contArray1 === 2) {
          arrayExe1.push(catalog[x]);
          contArray1 = 0;
        } else {
          contArray1 += 1;
        }
        if (contArray2 === 2) {
          arrayExe2.push(catalog[x]);
          contArray2 = 0;
        } else {
          contArray2 += 1;
        }
        if (contArray3 === 2) {
          arrayExe3.push(catalog[x]);
          contArray3 = 0;
        } else {
          contArray3 += 1;
        }
        if (x === catalog.length - 1) {
          setShowExerciseCatalog({
            array1: arrayExe1,
            array2: arrayExe2,
            array3: arrayExe3,
            type: flag,
          });
        }
      }
    } else {
      setShowExerciseCatalog({
        array1: arrayExe1,
        array2: arrayExe2,
        array3: arrayExe3,
        type: flag,
      });
    }
  }

  function updateState(tempExerciseList) {
    let contArray1 = 2;
    let contArray2 = 1;
    let contArray3 = 0;
    const arrayExe1 = [];
    const arrayExe2 = [];
    const arrayExe3 = [];
    if (tempExerciseList.length > 0) {
      for (let x = 0; x < tempExerciseList.length; x += 1) {
        if (contArray1 === 2) {
          arrayExe1.push(tempExerciseList[x]);
          contArray1 = 0;
        } else {
          contArray1 += 1;
        }
        if (contArray2 === 2) {
          arrayExe2.push(tempExerciseList[x]);
          contArray2 = 0;
        } else {
          contArray2 += 1;
        }
        if (contArray3 === 2) {
          arrayExe3.push(tempExerciseList[x]);
          contArray3 = 0;
        } else {
          contArray3 += 1;
        }
        if (x === tempExerciseList.length - 1) {
          isDataReceived = true;
        }
      }
    } else {
      isDataReceived = true;
    }

    if (isDataReceived) {
      if (
        arrayExe1.length === 0 &&
        arrayExe2.length === 0 &&
        arrayExe3.length === 0
      ) {
        setShowExerciseCatalog(
          <div className="container-patient-activities">
            <span>Nenhum exercício encontrado . . . </span>
          </div>,
        );
      } else {
        setShowExerciseCatalog({
          array1: arrayExe1,
          array2: arrayExe2,
          array3: arrayExe3,
          type: false,
        });
      }
    }
  }

  const onSearch = value => {
    const tempNewCatalog = [];
    if (value !== '') {
      for (let x = 0; x < exeCatalog.length; x += 1) {
        if (exeCatalog[x].name === value) {
          tempNewCatalog.push(exeCatalog[x]);
        }
        if (x === exeCatalog.length - 1) {
          renderActivityVideos(tempNewCatalog, false);
          setLastStateCatalog(tempNewCatalog);
        }
      }
    } else {
      renderActivityVideos(exeCatalog, false);
      setLastStateCatalog(exeCatalog);
    }
    if (!isAllActivities) {
      setIsAllActivities(true);
    }
  };

  function onChangeBodyFilter(checkedValues) {
    const tempArray = [];
    for (let x = 0; x < checkedValues.length; x += 1) {
      tempArray.push('body');
    }
    setFilterStack(prevState => ({
      ...prevState,
      body: {
        values: checkedValues,
        types: tempArray,
      },
    }));
  }
  function onChangePositionFilter(checkedValues) {
    const tempArray = [];
    for (let x = 0; x < checkedValues.length; x += 1) {
      tempArray.push('position');
    }
    setFilterStack(prevState => ({
      ...prevState,
      position: {
        values: checkedValues,
        types: tempArray,
      },
    }));
  }
  function onChangeGoalsFilter(checkedValues) {
    const tempArray = [];
    for (let x = 0; x < checkedValues.length; x += 1) {
      tempArray.push('goals');
    }
    setFilterStack(prevState => ({
      ...prevState,
      goals: {
        values: checkedValues,
        types: tempArray,
      },
    }));
  }
  function onChangeLevelFilter(checkedValues) {
    const tempArray = [];
    for (let x = 0; x < checkedValues.length; x += 1) {
      tempArray.push('difficulty');
    }
    setFilterStack(prevState => ({
      ...prevState,
      level: {
        values: checkedValues,
        types: tempArray,
      },
    }));
  }

  const cleanFilters = () => {
    renderActivityVideos(exeCatalog, false);
    setLastStateCatalog(exeCatalog);
    onChangeBodyFilter([]);
    onChangeGoalsFilter([]);
    onChangePositionFilter([]);
    onChangeLevelFilter([]);
  };

  function startFiltering(typeStack, filterStack) {
    let cont = 0;
    const newExerciseList = exeCatalog;
    isDataReceived = false;
    let stopFlag = false;
    const tempExerciseList = [];
    const activitiesNames = [];

    while (!stopFlag) {
      const currentType = typeStack[cont];
      if (newExerciseList.length === 0) {
        // Lista de exercícios após pesquisas nos itens da stack
        stopFlag = true;
        setShowExerciseCatalog(
          <div className="container-patient-activities">
            <span>Nenhum exercício encontrado . . . </span>
          </div>,
        );
      } else {
        switch (currentType) {
          case 'body':
            if (newExerciseList.length > 1) {
              for (let x = 0; x < newExerciseList.length; x += 1) {
                for (
                  let y = 0;
                  y < newExerciseList[x].categories.body.length;
                  y += 1
                ) {
                  if (
                    newExerciseList[x].categories.body[y] === filterStack[cont]
                  ) {
                    if (!activitiesNames.includes(newExerciseList[x].name, 0)) {
                      tempExerciseList.push(newExerciseList[x]);
                      activitiesNames.push(newExerciseList[x].categories.name);
                    }
                  }
                }
                if (x === newExerciseList.length - 1) {
                  cont += 1;
                  if (cont === filterStack.length) {
                    stopFlag = true;
                    updateState(tempExerciseList);
                    setLastStateCatalog(tempExerciseList);
                  } /* else {
                    newExerciseList = tempExerciseList;
                  } */
                }
              }
            } else {
              for (
                let y = 0;
                y < newExerciseList[0].categories.body.length;
                y += 1
              ) {
                if (
                  newExerciseList[0].categories.body[y] === filterStack[cont]
                ) {
                  tempExerciseList.push(newExerciseList[0]);
                  activitiesNames.push(newExerciseList[0].categories.name);
                }
                if (y === newExerciseList[0].categories.body.length - 1) {
                  stopFlag = true;
                  updateState(tempExerciseList);
                  setLastStateCatalog(tempExerciseList);
                }
              }
            }

            break;
          case 'goals':
            if (newExerciseList.length > 1) {
              for (let x = 0; x < newExerciseList.length; x += 1) {
                for (
                  let y = 0;
                  y < newExerciseList[x].categories.goals.length;
                  y += 1
                ) {
                  if (
                    newExerciseList[x].categories.goals[y] === filterStack[cont]
                  ) {
                    if (!activitiesNames.includes(newExerciseList[x].name, 0)) {
                      tempExerciseList.push(newExerciseList[x]);
                      activitiesNames.push(newExerciseList[x].categories.name);
                    }
                  }
                }
                if (x === newExerciseList.length - 1) {
                  cont += 1;
                  if (cont === filterStack.length) {
                    stopFlag = true;
                    updateState(tempExerciseList);
                    setLastStateCatalog(tempExerciseList);
                  } /* else {
                    newExerciseList = tempExerciseList;
                  } */
                }
              }
            } else {
              for (
                let y = 0;
                y < newExerciseList[0].categories.goals.length;
                y += 1
              ) {
                if (
                  newExerciseList[0].categories.goals[y] === filterStack[cont]
                ) {
                  tempExerciseList.push(newExerciseList[0]);
                }
                if (y === newExerciseList[0].categories.goals.length - 1) {
                  stopFlag = true;
                  updateState(tempExerciseList);
                  setLastStateCatalog(tempExerciseList);
                }
              }
            }
            break;
          case 'position':
            if (newExerciseList.length > 1) {
              for (let x = 0; x < newExerciseList.length; x += 1) {
                if (
                  typeof newExerciseList[x].categories.position === 'string'
                ) {
                  if (
                    newExerciseList[x].categories.position.includes(' e ', 0)
                  ) {
                    const tempArrayPos = newExerciseList[
                      x
                    ].categories.position.split(' e ');
                    if (
                      tempArrayPos[0] === filterStack[cont] ||
                      tempArrayPos[1] === filterStack[cont]
                    ) {
                      tempExerciseList.push(newExerciseList[x]);
                      if (
                        !activitiesNames.includes(newExerciseList[x].name, 0)
                      ) {
                        activitiesNames.push(
                          newExerciseList[x].categories.name,
                        );
                      }
                    }
                  } else if (
                    newExerciseList[x].categories.position === filterStack[cont]
                  ) {
                    tempExerciseList.push(newExerciseList[x]);
                    if (!activitiesNames.includes(newExerciseList[x].name, 0)) {
                      activitiesNames.push(newExerciseList[x].categories.name);
                    }
                  }
                } else if (
                  newExerciseList[x].categories.position[0].includes(' e ', 0)
                ) {
                  const tempArrayPos = newExerciseList[
                    x
                  ].categories.position[0].split(' e ');
                  if (
                    tempArrayPos[0] === filterStack[cont] ||
                    tempArrayPos[1] === filterStack[cont]
                  ) {
                    tempExerciseList.push(newExerciseList[x]);
                    if (!activitiesNames.includes(newExerciseList[x].name, 0)) {
                      activitiesNames.push(newExerciseList[x].categories.name);
                    }
                  }
                } else {
                  /* eslint-disable */
                  if (
                    newExerciseList[x].categories.position[0] ===
                    filterStack[cont]
                  ) {
                    tempExerciseList.push(newExerciseList[x]);
                    if (!activitiesNames.includes(newExerciseList[x].name, 0)) {
                      activitiesNames.push(newExerciseList[x].categories.name);
                    }
                  }
                }
                if (x === newExerciseList.length - 1) {
                  cont += 1;
                  if (cont === filterStack.length) {
                    stopFlag = true;
                    updateState(tempExerciseList);
                    setLastStateCatalog(tempExerciseList);
                  } /* else {
                    newExerciseList = tempExerciseList;
                  } */
                }
              }
            } else {
              if (typeof newExerciseList[0].categories.position === 'string') {
                if (newExerciseList[0].categories.position.includes(' e ', 0)) {
                  const tempArrayPos = newExerciseList[0].categories.position.split(
                    ' e ',
                  );
                  if (
                    tempArrayPos[0] === filterStack[cont] ||
                    tempArrayPos[1] === filterStack[cont]
                  ) {
                    tempExerciseList.push(newExerciseList[0]);
                  }
                }
                if (
                  newExerciseList[0].categories.position === filterStack[cont]
                ) {
                  tempExerciseList.push(newExerciseList[0]);
                  updateState(tempExerciseList);
                  setLastStateCatalog(tempExerciseList);
                }
              } else {
                if (
                  newExerciseList[0].categories.position[0] ===
                  filterStack[cont]
                ) {
                  tempExerciseList.push(newExerciseList[0]);
                  updateState(tempExerciseList);
                  setLastStateCatalog(tempExerciseList);
                }
              }

              stopFlag = true;
            }
            break;
          /**
           * * se difficulty[y] tiver ' e ', transforma ele em array de 2 valores
           *  */
          case 'difficulty':
            if (newExerciseList.length > 1) {
              for (let x = 0; x < newExerciseList.length; x += 1) {
                for (
                  let y = 0;
                  y < newExerciseList[x].categories.difficulty.length;
                  y += 1
                ) {
                  if (
                    newExerciseList[x].categories.difficulty[y].includes(
                      ' e ',
                      0,
                    )
                  ) {
                    // verifica se string possui ' e '
                    const tempArrayDif = newExerciseList[
                      x
                    ].categories.difficulty[y].split(' e '); // transforma string em array com os 2 valores
                    if (
                      filterStack[cont] === tempArrayDif[0] ||
                      filterStack[cont] === tempArrayDif[1]
                    ) {
                      // verifica se valor filtrado é 1 das 2 opções
                      if (
                        !activitiesNames.includes(newExerciseList[x].name, 0)
                      ) {
                        tempExerciseList.push(newExerciseList[x]);
                        activitiesNames.push(
                          newExerciseList[x].categories.name,
                        );
                      }
                    }
                  } else {
                    if (
                      newExerciseList[x].categories.difficulty[y] ===
                      filterStack[cont]
                    ) {
                      if (
                        !activitiesNames.includes(newExerciseList[x].name, 0)
                      ) {
                        tempExerciseList.push(newExerciseList[x]);
                        activitiesNames.push(
                          newExerciseList[x].categories.name,
                        );
                      }
                    }
                  }
                }
                if (x === newExerciseList.length - 1) {
                  cont += 1;
                  if (cont === filterStack.length) {
                    stopFlag = true;
                    updateState(tempExerciseList);
                    setLastStateCatalog(tempExerciseList);
                  } /* else {
                    newExerciseList = tempExerciseList;
                  } */
                }
              }
            } else {
              for (
                let y = 0;
                y < newExerciseList[0].categories.difficulty.length;
                y += 1
              ) {
                if (
                  newExerciseList[0].categories.difficulty[y].includes(' e ', 0)
                ) {
                  // verifica se string possui ' e '
                  const tempArrayDif2 = newExerciseList[0].categories.difficulty[
                    y
                  ].split(' e '); // transforma string em array com os 2 valores
                  if (
                    filterStack[cont] === tempArrayDif2[0] ||
                    filterStack[cont] === tempArrayDif2[1]
                  ) {
                    // verifica se valor filtrado é 1 das 2 opções
                    tempExerciseList.push(newExerciseList[0]);
                  }
                } else {
                  if (
                    newExerciseList[0].categories.difficulty[y] ===
                    filterStack[cont]
                  ) {
                    tempExerciseList.push(newExerciseList[0]);
                  }
                }
              }
              stopFlag = true;
              updateState(tempExerciseList);
              setLastStateCatalog(tempExerciseList);
            }
            break;
          default:
            break;
        }
      }
    }
  }

  function handlerFilterInput() {}

  const filtersConfiguration = data => {
    const catalog = data;
    const bodyArray = [];
    const bodyElementsArray = [];
    const goalsArray = [];
    const goalsElementsArray = [];
    const positionArray = [];
    const positionElementsArray = [];
    const difficultyArray = [];
    const difficultyElementsArray = [];

    for (let x = 0; x < catalog.length; x += 1) {
      // BODY
      if (bodyArray.length > 0) {
        if (catalog[x].categories.body.length > 0) {
          // Ver se existe entre os valores do array
          for (let y = 0; y < catalog[x].categories.body.length; y += 1) {
            if (!bodyArray.includes(catalog[x].categories.body[y], 0)) {
              bodyArray.push(catalog[x].categories.body[y]);
              bodyElementsArray.push(
                <S.ItemSelectFilter>
                  <input
                    type="checkbox"
                    id={`check${contNumberOfFilters}`}
                    onClick={() =>
                      handlerFilterInput('body', catalog[x].categories.body[y])
                    }
                  />
                  <span>{catalog[x].categories.body[y]}</span>
                </S.ItemSelectFilter>,
              );
              contNumberOfFilters += 1;
            }
          }
        }
      } else {
        if (catalog[x].categories.body !== '') {
          // Pegar valor
          bodyArray.push(catalog[x].categories.body[0]);
          bodyElementsArray.push(
            <S.ItemSelectFilter>
              <input
                type="checkbox"
                id={`check${contNumberOfFilters}`}
                onClick={() =>
                  handlerFilterInput('body', catalog[x].categories.body[0])
                }
              />
              <span>{catalog[x].categories.body[0]}</span>
            </S.ItemSelectFilter>,
          );
          contNumberOfFilters += 1;
        }
      }

      // GOALS
      if (goalsArray.length > 0) {
        // se ja tem valor no array
        if (catalog[x].categories.goals.length > 0) {
          // se existem goals
          // Ver se existe entre os valores do array
          for (let y = 0; y < catalog[x].categories.goals.length; y += 1) {
            if (!goalsArray.includes(catalog[x].categories.goals[y], 0)) {
              if (catalog[x].categories.goals[y] === 'Coordenaçãor') {
                goalsArray.push('Coordenação');
              } else {
                goalsArray.push(catalog[x].categories.goals[y]);
              }
              goalsElementsArray.push(
                <S.ItemSelectFilter>
                  <input
                    type="checkbox"
                    id={`check${contNumberOfFilters}`}
                    onClick={() =>
                      handlerFilterInput(
                        'goals',
                        catalog[x].categories.goals[y],
                      )
                    }
                  />
                  <span>{catalog[x].categories.goals[y]}</span>
                </S.ItemSelectFilter>,
              );
              contNumberOfFilters += 1;
            }
          }
        }
      } else {
        // se goalsArray ainda nao tem valor
        if (catalog[x].categories.goals.length > 0) {
          // se existem goals
          // Pegar valor
          for (let y = 0; y < catalog[x].categories.goals.length; y += 1) {
            goalsArray.push(catalog[x].categories.goals[y]);
            goalsElementsArray.push(
              <S.ItemSelectFilter>
                <input
                  type="checkbox"
                  id={`check${contNumberOfFilters}`}
                  onClick={() =>
                    handlerFilterInput('goals', catalog[x].categories.goals[y])
                  }
                />
                <span>{catalog[x].categories.goals[y]}</span>
              </S.ItemSelectFilter>,
            );
            contNumberOfFilters += 1;
          }
        }
      }

      // POSITION
      if (positionArray.length > 0) {
        if (typeof catalog[x].categories.position === 'string') {
          if (catalog[x].categories.position !== '') {
            // Ver se existe entre os valores do array
            if (!positionArray.includes(catalog[x].categories.position, 0)) {
              if (!catalog[x].categories.position.includes(' e ', 0)) {
                positionArray.push(catalog[x].categories.position);
                positionElementsArray.push(
                  <S.ItemSelectFilter>
                    <input
                      type="checkbox"
                      id={`check${contNumberOfFilters}`}
                      onClick={() =>
                        handlerFilterInput(
                          'position',
                          catalog[x].categories.position,
                        )
                      }
                    />
                    <span>{catalog[x].categories.position}</span>
                  </S.ItemSelectFilter>,
                );
                contNumberOfFilters += 1;
              }
            }
          }
        } else {
          if (catalog[x].categories.position[0] !== '') {
            // Ver se existe entre os valores do array
            if (!positionArray.includes(catalog[x].categories.position[0], 0)) {
              if (!catalog[x].categories.position[0].includes(' e ', 0)) {
                positionArray.push(catalog[x].categories.position[0]);
                positionElementsArray.push(
                  <S.ItemSelectFilter>
                    <input
                      type="checkbox"
                      id={`check${contNumberOfFilters}`}
                      onClick={() =>
                        handlerFilterInput(
                          'position',
                          catalog[x].categories.position[0],
                        )
                      }
                    />
                    <span>{catalog[x].categories.position[0]}</span>
                  </S.ItemSelectFilter>,
                );
                contNumberOfFilters += 1;
              }
            }
          }
        }
      } else {
        if (typeof catalog[x].categories.position === 'string') {
          if (catalog[x].categories.position !== '') {
            // Pegar valor
            positionArray.push(catalog[x].categories.position);
            positionElementsArray.push(
              <S.ItemSelectFilter>
                <input
                  type="checkbox"
                  id={`check${contNumberOfFilters}`}
                  onClick={() =>
                    handlerFilterInput(
                      'position',
                      catalog[x].categories.position,
                    )
                  }
                />
                <span>{catalog[x].categories.position}</span>
              </S.ItemSelectFilter>,
            );
            contNumberOfFilters += 1;
          }
        } else {
          if (catalog[x].categories.position[0] !== '') {
            // Pegar valor
            positionArray.push(catalog[x].categories.position[0]);
            positionElementsArray.push(
              <S.ItemSelectFilter>
                <input
                  type="checkbox"
                  id={`check${contNumberOfFilters}`}
                  onClick={() =>
                    handlerFilterInput(
                      'position',
                      catalog[x].categories.position[0],
                    )
                  }
                />
                <span>{catalog[x].categories.position[0]}</span>
              </S.ItemSelectFilter>,
            );
            contNumberOfFilters += 1;
          }
        }
      }

      // Transforma string com ' e ' em array com valores antes e depois do ' e '
      // Testa se os 2 valores do array criado já existem na stack

      // DIFFICULTY
      if (difficultyArray.length > 0) {
        if (catalog[x].categories.difficulty !== '') {
          // Ver se existe entre os valores do array
          const stringTest = ' e ';
          for (let y = 0; y < catalog[x].categories.difficulty.length; y += 1) {
            if (catalog[x].categories.difficulty[y].includes(stringTest, 0)) {
              const arrayTempDif = catalog[x].categories.difficulty[y].split(
                ' e ',
              );
              if (!difficultyArray.includes(arrayTempDif[0])) {
                difficultyArray.push(arrayTempDif[0]);
                difficultyElementsArray.push(
                  <S.ItemSelectFilter>
                    <input
                      type="checkbox"
                      id={`check${contNumberOfFilters}`}
                      onClick={() =>
                        handlerFilterInput('difficulty', arrayTempDif[0])
                      }
                    />
                    <span>{arrayTempDif[0]}</span>
                  </S.ItemSelectFilter>,
                );
                contNumberOfFilters += 1;
              }
              if (!difficultyArray.includes(arrayTempDif[1])) {
                difficultyArray.push(arrayTempDif[1]);
                difficultyElementsArray.push(
                  <S.ItemSelectFilter>
                    <input
                      type="checkbox"
                      id={`check${contNumberOfFilters}`}
                      onClick={() =>
                        handlerFilterInput('difficulty', arrayTempDif[1])
                      }
                    />
                    <span>{arrayTempDif[1]}</span>
                  </S.ItemSelectFilter>,
                );
                contNumberOfFilters += 1;
              }
            } else {
              if (
                !difficultyArray.includes(
                  catalog[x].categories.difficulty[y],
                  0,
                )
              ) {
                difficultyArray.push(catalog[x].categories.difficulty[y]);
                difficultyElementsArray.push(
                  <S.ItemSelectFilter>
                    <input
                      type="checkbox"
                      id={`check${contNumberOfFilters}`}
                      onClick={() =>
                        handlerFilterInput(
                          'difficulty',
                          catalog[x].categories.difficulty[y],
                        )
                      }
                    />
                    <span>{catalog[x].categories.difficulty[y]}</span>
                  </S.ItemSelectFilter>,
                );
                contNumberOfFilters += 1;
              }
            }
          }
        }
      } else {
        if (catalog[x].categories.difficulty[0] !== '') {
          // Pegar valor
          for (let y = 0; y < catalog[x].categories.difficulty.length; y += 1) {
            difficultyArray.push(catalog[x].categories.difficulty[y]);
            difficultyElementsArray.push(
              <S.ItemSelectFilter>
                <input
                  type="checkbox"
                  id={`check${contNumberOfFilters}`}
                  onClick={() =>
                    handlerFilterInput(
                      'difficulty',
                      catalog[x].categories.difficulty[y],
                    )
                  }
                />
                <span>{catalog[x].categories.difficulty[y]}</span>
              </S.ItemSelectFilter>,
            );
            contNumberOfFilters += 1;
          }
        }
      }

      if (x === catalog.length - 1) {
        // setFilters();
        setBodyOpt(bodyArray);
        setPositionOpt(positionArray);
        setGoalsOpt(goalsArray);
        setDiffOpt(difficultyArray);
        /* <WaitForDataReceive
            bodyElementsArray={bodyArray}
            goalsElementsArray={goalsArray}
            positionElementsArray={positionArray}
            difficultyElementsArray={difficultyArray}
            stack={filterS}
            onChangeBodyFilter={onChangeBodyFilter}
            onChangePositionFilter={onChangePositionFilter}
            onChangeLevelFilter={onChangeLevelFilter}
            onChangeGoalsFilter={onChangeGoalsFilter}
          />, */
      }
    }
  };

  useEffect(() => {
    if (exeCatalog !== null) {
      renderActivityVideos(exeCatalog, false);
      filtersConfiguration(exeCatalog, false);
      setLastStateCatalog(exeCatalog);
      if (exeCatalog.length > 0) {
        const tempArrayNames = [];
        const includedNames = [];
        for (let x = 0; x < exeCatalog.length; x += 1) {
          if (!includedNames.includes(exeCatalog[x].name)) {
            tempArrayNames.push({
              value: exeCatalog[x].name,
            });
            includedNames.push(exeCatalog[x].name);
          }
          if (x === exeCatalog.length - 1) {
            setFilterSearchOptions(tempArrayNames);
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    // receber protocolo referente ao id
    // dispatch dos currents exes adicionados
    // atualizar resto das variaveis
    if (protocolId !== null && protocolsCreated.length !== 0) {
      for (let x = 0; x < protocolsCreated.length; x += 1) {
        if (protocolsCreated[x].id === protocolId) {
          setProtocolTitle(protocolsCreated[x].name);
          setStackAddedActivities(protocolsCreated[x].exercises);
          setContActAdded(protocolsCreated[x].exercises.length);
          if (protocolsCreated[x].type === 'DOMESTIC') {
            setWhereProtocol({
              domPract: { enable: true, value: 'home-practice' },
              telePract: { enable: false, value: 'live-sessions' },
            });
          } else {
            setWhereProtocol({
              domPract: { enable: false, value: 'home-practice' },
              telePract: { enable: true, value: 'live-sessions' },
            });
          }
          break;
        }
      }
    }
  }, [protocolsCreated, protocolId]);

  const firstChange = useMediaQuery('(min-width: 1090px)');
  const change = useMediaQuery('(min-width: 700px)');
  const secondChange = useMediaQuery('(min-width: 630px)');
  const tirthChange = useMediaQuery('(min-width: 530px)');
  const fourthChange = useMediaQuery('(min-width: 460px)');

  return (
    <div className="create-new-form-protocol">
      {edit ? (
        <S.TitlePage> Editar Protocolo </S.TitlePage>
      ) : (
        <S.TitlePage> Criar Novo Protocolo </S.TitlePage>
      )}

      <S.ContElementsCreateProtocol>
        <strong>Título</strong>
        <Input
          placeholder="Digite o título da atividade"
          style={{
            width: firstChange
              ? '100%'
              : change
              ? '80%'
              : secondChange
              ? '70%'
              : tirthChange
              ? '65%'
              : fourthChange
              ? '55%'
              : '45%',
          }}
          value={protocolTitle}
          onChange={e => setProtocolTitle(e.target.value)}
        />
      </S.ContElementsCreateProtocol>
      <S.ContElementsCreateProtocol>
        <strong>Disponibilizar Protocolo para</strong>
        <div style={{ width: '100%' }}>
          <CheckBoxWhereProtocol
            info={
              whereProtocol.domPract.enable === true ? 'domPract' : 'telePract'
            }
            setInfo={setWhereProtocol}
            keyName={['domPract', 'telePract']}
            change={fourthChange}
          />
        </div>
      </S.ContElementsCreateProtocol>
      <Divider />
      <S.ContElementsVideoSelection style={{ width: !secondChange && '250px' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: secondChange ? 'row' : 'column',
          }}
        >
          <AutoComplete
            dropdownMatchSelectWidth={252}
            style={{
              width: 252,
            }}
            allowClear
            options={filterSearchOptions}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
          >
            <Search
              placeholder="Buscar"
              onSearch={onSearch}
              style={{ width: 252 }}
            />
          </AutoComplete>
          <div
            className="res"
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '170px',
              marginLeft: 10,
            }}
            onClick={showModal}
            aria-hidden="true"
          >
            <S.ImageFilterActivities src={ImgFilterActivities} />
            <strong style={{ marginTop: '0.2rem' }}>Filtrar Resultados</strong>
          </div>
        </div>

        {isAllActivities ? (
          <S.ButtonShowAddedActitivies
            onClick={() => {
              setIsAllActivities(false);
              renderActivityVideos(stackAddedActivities, true);
            }}
            aria-hidden="true"
          >
            <strong>Atividades Adicionadas</strong>
            <S.ImageAddedActitivies>
              <b>{contActAdded}</b>
            </S.ImageAddedActitivies>
          </S.ButtonShowAddedActitivies>
        ) : (
          <S.ButtonShowAddedActitivies
            onClick={() => {
              setIsAllActivities(true);
              renderActivityVideos(lastStateCatalog, false);
            }}
            aria-hidden="true"
          >
            <strong>Todas Atividades</strong>
          </S.ButtonShowAddedActitivies>
        )}
      </S.ContElementsVideoSelection>
      <S.ContElementsCreateProtocol>
        {isAllActivities ? (
          <strong>Todas as Atividades</strong>
        ) : (
          <strong>Atividades Adicionadas</strong>
        )}
      </S.ContElementsCreateProtocol>
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '400px',
          justifyContent: isBiggerThan ? 'center' : 'flex-start',
          overflowX: isBiggerThanOverflow ? 'hidden' : 'scroll',
        }}
      >
        <ActivityVideos
          array1={exerciseCatalog.array1}
          array2={exerciseCatalog.array2}
          array3={exerciseCatalog.array3}
          handlerExerciseSelected={handlerExerciseSelected}
          removeAddedActivityOfProtocol={removeAddedActivityOfProtocol}
          flag={!isAllActivities}
        />
      </div>
      <Button
        type="primary"
        htmlType="submit"
        style={{ ...S.AntdStyles.ButtonCreatePractice, fontWeight: 'bold' }}
        onClick={() => {
          setScreenInProtocolList();
        }}
        aria-hidden="true"
      >
        Voltar
      </Button>
      <Button
        type="primary"
        htmlType="submit"
        style={{
          ...S.AntdStyles.ButtonCreatePractice,
          marginLeft: '2rem',
          fontWeight: 'bold',
        }}
        onClick={() => {
          dispatch(
            saveProtocol(id, protocolTitle, whereProtocol, protocolId, [
              ...stackAddedActivities,
            ]),
          );
        }}
        aria-hidden="true"
      >
        {edit ? 'Salvar Prática' : 'Criar Prática'}
      </Button>
      <Modal
        title="Filtrar Resultados"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={430}
        closeIcon={
          <img src={closeIcon} alt="close" style={S.AntdStyles.iconClose} />
        }
        footer={[
          <Button
            type="primary"
            onClick={() => {
              const tempArrayTypes = [
                ...filterS.body.types,
                ...filterS.position.types,
                ...filterS.level.types,
                ...filterS.goals.types,
              ];
              const tempArrayValues = [
                ...filterS.body.values,
                ...filterS.position.values,
                ...filterS.level.values,
                ...filterS.goals.values,
              ];
              startFiltering(tempArrayTypes, tempArrayValues);
              if (!isAllActivities) {
                setIsAllActivities(true);
              }
            }} //
            style={{
              width: '140px',
              borderRadius: '5px',
              backgroundColor: '#118E88',
              borderColor: '#118E88',
            }}
          >
            Aplicar
          </Button>,
          <Button
            key="primary"
            type="default"
            onClick={() => {
              cleanFilters();
              if (!isAllActivities) {
                setIsAllActivities(true);
              }
            }}
            style={{
              width: '160px',
              borderRadius: '5px',
              borderColor: '#118E88',
              color: '#118E88',
              justifyContent: 'space-around',
            }}
            icon={<DeleteOutlined />}
          >
            <strong>Limpar Filtros</strong>
          </Button>,
        ]}
      >
        <WaitForDataReceive
          bodyElementsArray={bodyOpt}
          goalsElementsArray={goalsOpt}
          positionElementsArray={positionOpt}
          difficultyElementsArray={diffOpt}
          stack={filterS}
          onChangeBodyFilter={onChangeBodyFilter}
          onChangePositionFilter={onChangePositionFilter}
          onChangeLevelFilter={onChangeLevelFilter}
          onChangeGoalsFilter={onChangeGoalsFilter}
        />
        ,
      </Modal>
    </div>
  );
}

export default ProtocolSetup;
