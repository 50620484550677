import { useState, useEffect } from 'react';
import { Modal, Button, Input, Divider } from 'antd';
import { useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import closeIcon from 'assets/close.svg';

import * as S from './styles';

export function VideoSelectedModal(props) {
  const { isModalVisible } = props;
  const { handlerExerciseSelected } = props;
  const { handleCancel } = props;
  const { handleOk } = props;
  const { closeModal } = props;
  const actSel = useSelector(state => state.protocol.actvtSelected);
  const [inputs, setInputs] = useState({
    series: actSel?.series ?? '',
    repetitions: actSel?.series ?? '',
  });

  useEffect(() => {
    setInputs({
      series: actSel?.series ?? '',
      repetitions: actSel?.repetitions ?? '',
    });
  }, [actSel]);

  const ElementInfo = ({ title, value }) => (
    <S.ElementInfoVideoSelectedOfProtocol>
      <strong>{title}</strong>
      {Array.isArray(value) ? (
        value.map(input => <span>{input}</span>)
      ) : (
        <span>{value === '' ? '-' : value}</span>
      )}
    </S.ElementInfoVideoSelectedOfProtocol>
  );

  const ElementInfoBig = ({ title, value }) => (
    <S.BigElementInfoVideoSelectedOfProtocol>
      <strong>{title}</strong>
      {Array.isArray(value) ? (
        value.map(input => <span>{input === '' ? '-' : input}</span>)
      ) : (
        <span>{value === '' ? '-' : value}</span>
      )}
    </S.BigElementInfoVideoSelectedOfProtocol>
  );

  return (
    <Modal
      title="Atividade Selecionada"
      visible={isModalVisible}
      onOk={() => handleOk()}
      onCancel={() => handleCancel()}
      width={550}
      closeIcon={
        <img src={closeIcon} alt="close" style={S.AntdStyles.iconClose} />
      }
      footer={[
        <Button
          type="primary"
          onClick={() => {
            handlerExerciseSelected(actSel.cid, inputs);
            closeModal(false);
          }}
          style={{
            width: '180px',
            borderRadius: '5px',
            backgroundColor: '#118E88',
            borderColor: '#118E88',
          }}
        >
          Adicionar ao Protocolo
        </Button>,
      ]}
    >
      {actSel?.name ? (
        <S.ContainerVideoSelectedOfProtocol>
          <S.ContainerInfoVideoSelectedOfProtocol>
            <ReactPlayer
              url={actSel.video_url}
              width="330px"
              height="180px"
              title={actSel.name}
            />
          </S.ContainerInfoVideoSelectedOfProtocol>
          <Divider />
          <S.ContainerInfoVideoSelectedOfProtocol>
            <S.ElementInputVideoSelectedOfProtocol>
              <strong>Series: </strong>
              <Input
                size="small"
                value={inputs.series}
                type="number"
                onChange={e => {
                  const tempValue = e.target.value;
                  setInputs(prevState => ({
                    ...prevState,
                    series: tempValue,
                  }));
                }}
              />
            </S.ElementInputVideoSelectedOfProtocol>
            <S.ElementInputVideoSelectedOfProtocol>
              <strong>Repetições: </strong>
              <Input
                size="small"
                type="number"
                value={inputs.repetitions}
                onChange={e => {
                  const tempValue = e.target.value;
                  setInputs(prevState => ({
                    ...prevState,
                    repetitions: tempValue,
                  }));
                }}
              />
            </S.ElementInputVideoSelectedOfProtocol>
          </S.ContainerInfoVideoSelectedOfProtocol>
          <S.ContainerInfoVideoSelectedOfProtocol>
            <ElementInfo
              title="Partes do Corpo"
              value={actSel.categories.body}
            />
            <ElementInfo title="Posição" value={actSel.categories.position} />
            <ElementInfo title="Objetos" value={actSel.categories.materials} />
          </S.ContainerInfoVideoSelectedOfProtocol>
          <S.ContainerInfoVideoSelectedOfProtocol>
            <ElementInfo title="Objetivos" value={actSel.categories.goals} />
            <ElementInfoBig
              title="Nível"
              value={actSel.categories.difficulty}
            />
          </S.ContainerInfoVideoSelectedOfProtocol>
        </S.ContainerVideoSelectedOfProtocol>
      ) : null}
    </Modal>
  );
}
