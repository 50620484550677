import styled from 'styled-components';

export const TitlePage = styled.h5`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal normal 20px/27px Nunito Sans;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

export const AntdStyles = {
  marginTop: {
    marginTop: '4px',
  },

  inputNumber: { width: '100%', marginTop: '4px' },

  ButtonSave: {
    background: 'var(--darkCyan) 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    opacity: 1,
    width: '10rem',
    margin: '1rem 0',
    fontWeight: 'bold',
  },
};

export const LabelText = styled.div`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal bold 14px/19px Nunito Sans;
  letter-spacing: 0px;
  display: flex;
  flex-direction: column;
`;

export const TextRequire = styled.label`
  font: normal normal normal 14px/21px Nunito Sans;
  color: #575757;
`;

export const ErrorMessage = styled.label`
  color: #c12;
  font-size: 12px;
  margin-top: 2px;
`;

export const DivCol = styled.div`
  display: flex;
  flex-direction: column;
`;
