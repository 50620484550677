/* eslint-disable no-unused-vars */
/* eslint-disable no-lonely-if */
import React, { useEffect, useState } from 'react';
import { Radio, Space, Tooltip, Row, Col } from 'antd';
import Plot from 'react-plotly.js';

import { useSelector } from 'react-redux';
import { differenceInDays, format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';

import {
  graph,
  stanceSwingLayout,
  evolutionsLayout,
  stepLengthLayout,
  cadencyLayout,
  labelAMonitoring,
  labelBMonitoring,
  graphWColor,
} from '../../../../../../../utils/graphsMonitoringEvals';

import * as S from './styles';

export function Evolution() {
  const computerVision = useSelector(state => state.evaluations.computerVision);
  const [choiceTime, setChoiceTime] = useState('1 Semana');
  const [velocityGraph, setVelocityGraph] = useState([]);
  const [cadenceGraph, setCadenceGraph] = useState([]);
  const [stepGraph, setStepGraph] = useState([]);
  const [stanSwiGraph, setStanSwiGraph] = useState([]);

  const firstChange = useMediaQuery('(min-width: 770px)');

  function updateData({
    velocity,
    xVelocity,
    cadence,
    xCadence,
    step_lgt_left,
    step_lgt_right,
    xStep,
    stance_p_left,
    stance_p_right,
    swing_p_left,
    swing_p_right,
    xEvals,
  }) {
    const vel = graph(xVelocity, velocity);
    setVelocityGraph([vel]);

    const cad = graph(xCadence, cadence);
    setCadenceGraph([cad]);

    const step1 = graphWColor(xStep, step_lgt_left, 1);
    const step2 = graphWColor(xStep, step_lgt_right, 2);
    setStepGraph([step1, step2]);

    const sts1 = graphWColor(xEvals, stance_p_right, 1);
    const sts2 = graphWColor(xEvals, swing_p_right, 2);
    setStanSwiGraph([sts1, sts2]);
  }

  function oneWeek(data) {
    const today = new Date();
    const vel = [];
    const xVel = [];
    const cad = [];
    const xCad = [];
    const step_left = [];
    const step_right = [];
    const xStep = [];
    const stance_left = [];
    const stance_right = [];
    const swing_left = [];
    const swing_right = [];
    const xEvals = [];
    let finalData = null;

    for (let i = 0; i < data.length; i += 1) {
      if (data[i].status === 'DONE') {
        if (differenceInDays(today, new Date(data[i].createdAt)) <= 6) {
          const nameSplitted = data[i].name.split(' ');
          const useDate = `${nameSplitted[2]} ${nameSplitted[1]}`;
          const dt = data[i].data;

          vel.push(dt.gait_velocity === '-' ? 0 : dt.gait_velocity || []);
          xVel.push(useDate);

          cad.push(dt.gait_cadence === '-' ? 0 : dt.gait_cadence || []);
          xCad.push(useDate);

          step_left.push(
            dt.step_length_left === '-' ? 0 : dt.step_length_left || [],
          );
          step_right.push(
            dt.step_length_right === '-' ? 0 : dt.step_length_right || [],
          );
          xStep.push(useDate);

          stance_left.push(
            dt.stance_per_left === '-' ? 0 : dt.stance_per_left * 100 || [],
          );
          stance_right.push(
            dt.stance_per_right === '-' ? 0 : dt.stance_per_right * 100 || [],
          );

          swing_left.push(
            dt.swing_per_left === '-' ? 0 : dt.swing_per_left * 100 || [],
          );
          swing_right.push(
            dt.swing_per_right === '-' ? 0 : dt.swing_per_right * 100 || [],
          );

          xEvals.push(`Avaliação ${nameSplitted[1]}`);
        }
      }
    }

    finalData = {
      velocity: vel.reverse(),
      xVelocity: xVel.reverse(),
      cadence: cad.reverse(),
      xCadence: xCad.reverse(),
      step_lgt_left: step_left.reverse(),
      step_lgt_right: step_right.reverse(),
      xStep: xStep.reverse(),
      stance_p_left: stance_left.reverse(),
      stance_p_right: stance_right.reverse(),
      swing_p_left: swing_left.reverse(),
      swing_p_right: swing_right.reverse(),
      xEvals: xEvals.reverse(),
    };
    updateData(finalData);
  }

  function oneMonth(data) {
    const today = new Date();
    const vel = [];
    const xVel = [];
    const cad = [];
    const xCad = [];
    const step_left = [];
    const step_right = [];
    const xStep = [];
    const stance_left = [];
    const stance_right = [];
    const swing_left = [];
    const swing_right = [];
    const xEvals = [];
    let finalData = null;

    for (let i = 0; i < data.length; i += 1) {
      if (data[i].status === 'DONE') {
        if (differenceInDays(today, new Date(data[i].createdAt)) <= 29) {
          const nameSplitted = data[i].name.split(' ');
          const useDate = `${nameSplitted[2]} ${nameSplitted[1]}`;
          const dt = data[i].data;

          vel.push(dt.gait_velocity === '-' ? 0 : dt.gait_velocity || []);
          xVel.push(useDate);

          cad.push(dt.gait_cadence === '-' ? 0 : dt.gait_cadence || []);
          xCad.push(useDate);

          step_left.push(
            dt.step_length_left === '-' ? 0 : dt.step_length_left || [],
          );
          step_right.push(
            dt.step_length_right === '-' ? 0 : dt.step_length_right || [],
          );
          xStep.push(useDate);

          stance_left.push(
            dt.stance_per_left === '-' ? 0 : dt.stance_per_left * 100 || [],
          );
          stance_right.push(
            dt.stance_per_right === '-' ? 0 : dt.stance_per_right * 100 || [],
          );

          swing_left.push(
            dt.swing_per_left === '-' ? 0 : dt.swing_per_left * 100 || [],
          );
          swing_right.push(
            dt.swing_per_right === '-' ? 0 : dt.swing_per_right * 100 || [],
          );

          xEvals.push(`Avaliação ${nameSplitted[1]}`);
        }
      }
    }

    finalData = {
      velocity: vel.reverse(),
      xVelocity: xVel.reverse(),
      cadence: cad.reverse(),
      xCadence: xCad.reverse(),
      step_lgt_left: step_left.reverse(),
      step_lgt_right: step_right.reverse(),
      xStep: xStep.reverse(),
      stance_p_left: stance_left.reverse(),
      stance_p_right: stance_right.reverse(),
      swing_p_left: swing_left.reverse(),
      swing_p_right: swing_right.reverse(),
      xEvals: xEvals.reverse(),
    };
    updateData(finalData);
  }

  function threeMonth(data) {
    const today = new Date();
    const vel = [];
    const xVel = [];
    const cad = [];
    const xCad = [];
    const step_left = [];
    const step_right = [];
    const xStep = [];
    const stance_left = [];
    const stance_right = [];
    const swing_left = [];
    const swing_right = [];
    const xEvals = [];
    let finalData = null;

    for (let i = 0; i < data.length; i += 1) {
      if (data[i].status === 'DONE') {
        if (differenceInDays(today, new Date(data[i].createdAt)) <= 89) {
          const nameSplitted = data[i].name.split(' ');
          const useDate = `${nameSplitted[2]} ${nameSplitted[1]}`;
          const dt = data[i].data;

          vel.push(dt.gait_velocity === '-' ? 0 : dt.gait_velocity || []);
          xVel.push(useDate);

          cad.push(dt.gait_cadence === '-' ? 0 : dt.gait_cadence || []);
          xCad.push(useDate);

          step_left.push(
            dt.step_length_left === '-' ? 0 : dt.step_length_left || [],
          );
          step_right.push(
            dt.step_length_right === '-' ? 0 : dt.step_length_right || [],
          );
          xStep.push(useDate);

          stance_left.push(
            dt.stance_per_left === '-' ? 0 : dt.stance_per_left * 100 || [],
          );
          stance_right.push(
            dt.stance_per_right === '-' ? 0 : dt.stance_per_right * 100 || [],
          );

          swing_left.push(
            dt.swing_per_left === '-' ? 0 : dt.swing_per_left * 100 || [],
          );
          swing_right.push(
            dt.swing_per_right === '-' ? 0 : dt.swing_per_right * 100 || [],
          );

          xEvals.push(`Avaliação ${nameSplitted[1]}`);
        }
      }
    }

    finalData = {
      velocity: vel.reverse(),
      xVelocity: xVel.reverse(),
      cadence: cad.reverse(),
      xCadence: xCad.reverse(),
      step_lgt_left: step_left.reverse(),
      step_lgt_right: step_right.reverse(),
      xStep: xStep.reverse(),
      stance_p_left: stance_left.reverse(),
      stance_p_right: stance_right.reverse(),
      swing_p_left: swing_left.reverse(),
      swing_p_right: swing_right.reverse(),
      xEvals: xEvals.reverse(),
    };
    updateData(finalData);
  }

  function sixMonth(data) {
    const today = new Date();
    const vel = [];
    const xVel = [];
    const cad = [];
    const xCad = [];
    const step_left = [];
    const step_right = [];
    const xStep = [];
    const stance_left = [];
    const stance_right = [];
    const swing_left = [];
    const swing_right = [];
    const xEvals = [];
    let finalData = null;

    for (let i = 0; i < data.length; i += 1) {
      if (data[i].status === 'DONE') {
        if (differenceInDays(today, new Date(data[i].createdAt)) <= 179) {
          const nameSplitted = data[i].name.split(' ');
          const useDate = `${nameSplitted[2]} ${nameSplitted[1]}`;
          const dt = data[i].data;

          vel.push(dt.gait_velocity === '-' ? 0 : dt.gait_velocity || []);
          xVel.push(useDate);

          cad.push(dt.gait_cadence === '-' ? 0 : dt.gait_cadence || []);
          xCad.push(useDate);

          step_left.push(
            dt.step_length_left === '-' ? 0 : dt.step_length_left || [],
          );
          step_right.push(
            dt.step_length_right === '-' ? 0 : dt.step_length_right || [],
          );
          xStep.push(useDate);

          stance_left.push(
            dt.stance_per_left === '-' ? 0 : dt.stance_per_left * 100 || [],
          );
          stance_right.push(
            dt.stance_per_right === '-' ? 0 : dt.stance_per_right * 100 || [],
          );

          swing_left.push(
            dt.swing_per_left === '-' ? 0 : dt.swing_per_left * 100 || [],
          );
          swing_right.push(
            dt.swing_per_right === '-' ? 0 : dt.swing_per_right * 100 || [],
          );

          xEvals.push(`Avaliação ${nameSplitted[1]}`);
        }
      }
    }

    finalData = {
      velocity: vel.reverse(),
      xVelocity: xVel.reverse(),
      cadence: cad.reverse(),
      xCadence: xCad.reverse(),
      step_lgt_left: step_left.reverse(),
      step_lgt_right: step_right.reverse(),
      xStep: xStep.reverse(),
      stance_p_left: stance_left.reverse(),
      stance_p_right: stance_right.reverse(),
      swing_p_left: swing_left.reverse(),
      swing_p_right: swing_right.reverse(),
      xEvals: xEvals.reverse(),
    };
    updateData(finalData);
  }

  function oneYear(data) {
    const today = new Date();
    const vel = [];
    const xVel = [];
    const cad = [];
    const xCad = [];
    const step_left = [];
    const step_right = [];
    const xStep = [];
    const stance_left = [];
    const stance_right = [];
    const swing_left = [];
    const swing_right = [];
    const xEvals = [];
    let finalData = null;

    for (let i = 0; i < data.length; i += 1) {
      if (data[i].status === 'DONE') {
        if (differenceInDays(today, new Date(data[i].createdAt)) <= 364) {
          const nameSplitted = data[i].name.split(' ');
          const useDate = `${nameSplitted[2]} ${nameSplitted[1]}`;
          const dt = data[i].data;

          vel.push(dt.gait_velocity === '-' ? 0 : dt.gait_velocity || []);
          xVel.push(useDate);

          cad.push(dt.gait_cadence === '-' ? 0 : dt.gait_cadence || []);
          xCad.push(useDate);

          step_left.push(
            dt.step_length_left === '-' ? 0 : dt.step_length_left || [],
          );
          step_right.push(
            dt.step_length_right === '-' ? 0 : dt.step_length_right || [],
          );
          xStep.push(useDate);

          stance_left.push(
            dt.stance_per_left === '-' ? 0 : dt.stance_per_left * 100 || [],
          );
          stance_right.push(
            dt.stance_per_right === '-' ? 0 : dt.stance_per_right * 100 || [],
          );

          swing_left.push(
            dt.swing_per_left === '-' ? 0 : dt.swing_per_left * 100 || [],
          );
          swing_right.push(
            dt.swing_per_right === '-' ? 0 : dt.swing_per_right * 100 || [],
          );

          xEvals.push(`Avaliação ${nameSplitted[1]}`);
        }
      }
    }

    finalData = {
      velocity: vel.reverse(),
      xVelocity: xVel.reverse(),
      cadence: cad.reverse(),
      xCadence: xCad.reverse(),
      step_lgt_left: step_left.reverse(),
      step_lgt_right: step_right.reverse(),
      xStep: xStep.reverse(),
      stance_p_left: stance_left.reverse(),
      stance_p_right: stance_right.reverse(),
      swing_p_left: swing_left.reverse(),
      swing_p_right: swing_right.reverse(),
      xEvals: xEvals.reverse(),
    };
    updateData(finalData);
  }

  function general(data) {
    const today = new Date();
    const vel = [];
    const xVel = [];
    const cad = [];
    const xCad = [];
    const step_left = [];
    const step_right = [];
    const xStep = [];
    const stance_left = [];
    const stance_right = [];
    const swing_left = [];
    const swing_right = [];
    const xEvals = [];
    let finalData = null;

    for (let i = 0; i < data.length; i += 1) {
      if (data[i].status === 'DONE') {
        const nameSplitted = data[i].name.split(' ');
        const useDate = `${nameSplitted[2]} ${nameSplitted[1]}`;
        const dt = data[i].data;

        vel.push(dt.gait_velocity === '-' ? 0 : dt.gait_velocity || []);
        xVel.push(useDate);

        cad.push(dt.gait_cadence === '-' ? 0 : dt.gait_cadence || []);
        xCad.push(useDate);

        step_left.push(
          dt.step_length_left === '-' ? 0 : dt.step_length_left || [],
        );
        step_right.push(
          dt.step_length_right === '-' ? 0 : dt.step_length_right || [],
        );
        xStep.push(useDate);

        stance_left.push(
          dt.stance_per_left === '-' ? 0 : dt.stance_per_left * 100 || [],
        );
        stance_right.push(
          dt.stance_per_right === '-' ? 0 : dt.stance_per_right * 100 || [],
        );

        swing_left.push(
          dt.swing_per_left === '-' ? 0 : dt.swing_per_left * 100 || [],
        );
        swing_right.push(
          dt.swing_per_right === '-' ? 0 : dt.swing_per_right * 100 || [],
        );

        xEvals.push(`Avaliação ${nameSplitted[1]}`);
      }
    }

    // console.tron.log('xEvals', xEvals);

    finalData = {
      velocity: vel.reverse(),
      xVelocity: xVel.reverse(),
      cadence: cad.reverse(),
      xCadence: xCad.reverse(),
      step_lgt_left: step_left.reverse(),
      step_lgt_right: step_right.reverse(),
      xStep: xStep.reverse(),
      stance_p_left: stance_left.reverse(),
      stance_p_right: stance_right.reverse(),
      swing_p_left: swing_left.reverse(),
      swing_p_right: swing_right.reverse(),
      xEvals: xEvals.reverse(),
    };
    updateData(finalData);
  }

  useEffect(() => {
    switch (choiceTime) {
      case '1 Semana':
        oneWeek(computerVision);
        break;
      case '1 Mês':
        oneMonth(computerVision);
        break;
      case '3 Meses':
        threeMonth(computerVision);
        break;
      case '6 Meses':
        sixMonth(computerVision);
        break;
      case '1 Ano':
        oneYear(computerVision);
        break;
      case 'Geral':
        general(computerVision);
        break;
      default:
        general(computerVision);
        break;
    }
  }, [computerVision, choiceTime]);

  return (
    <>
      <Radio.Group
        value={choiceTime}
        onChange={e => setChoiceTime(e.target.value)}
        style={{ marginTop: '0.5rem', marginBottom: '1rem' }}
      >
        <Radio.Button
          value="1 Semana"
          style={
            choiceTime === '1 Semana'
              ? S.AntdStyles.RadioButtonLeftAble
              : S.AntdStyles.RadioButtonLeftDisable
          }
        >
          1 Semana
        </Radio.Button>
        <Radio.Button
          value="1 Mês"
          style={
            choiceTime === '1 Mês'
              ? S.AntdStyles.RadioButtonMiddleAble
              : S.AntdStyles.RadioButtonMiddleDisable
          }
        >
          1 Mês
        </Radio.Button>
        <Radio.Button
          value="3 Meses"
          style={
            choiceTime === '3 Meses'
              ? S.AntdStyles.RadioButtonMiddleAble
              : S.AntdStyles.RadioButtonMiddleDisable
          }
        >
          3 Meses
        </Radio.Button>
        <Radio.Button
          value="6 Meses"
          style={
            choiceTime === '6 Meses'
              ? S.AntdStyles.RadioButtonMiddleAble
              : S.AntdStyles.RadioButtonMiddleDisable
          }
        >
          6 Meses
        </Radio.Button>
        <Radio.Button
          value="1 Ano"
          style={
            choiceTime === '1 Ano'
              ? S.AntdStyles.RadioButtonMiddleAble
              : S.AntdStyles.RadioButtonMiddleDisable
          }
        >
          1 Ano
        </Radio.Button>
        <Radio.Button
          value="Geral"
          style={
            choiceTime === 'Geral'
              ? S.AntdStyles.RadioButtonRigthAble
              : S.AntdStyles.RadioButtonRigthDisable
          }
        >
          Geral
        </Radio.Button>
      </Radio.Group>
      <Row gutter={[32, 16]} style={{ marginTop: '0.5rem' }}>
        <Col className="gutter-row" span={firstChange ? 12 : 24}>
          <Col>
            <br />
            <S.TitleSession>Velocidade</S.TitleSession>
            <br />

            <Space
              size="small"
              direction="horizontal"
              align="start"
              style={{ marginTop: '0.5rem' }}
            >
              <Plot
                data={velocityGraph}
                layout={evolutionsLayout}
                config={{
                  displayModeBar: false,
                }}
              />
              <Tooltip title="Velocidade da marcha em metros/segundos (m/s). Ex: 3m/s.">
                <InfoCircleOutlined color="#3A3979" size={24} />
              </Tooltip>
            </Space>

            <br />
            <S.TitleSession>Comprimento de Passos</S.TitleSession>
            <br />

            <Space
              size="small"
              direction="horizontal"
              align="start"
              style={{ marginTop: '0.5rem' }}
            >
              <Plot
                data={stepGraph}
                layout={stepLengthLayout}
                config={{
                  displayModeBar: false,
                }}
              />

              <S.SubContainerLegends>
                <Space
                  size="small"
                  direction="horizontal"
                  style={{ width: '110px' }}
                >
                  <S.LegendSquare lcolor={labelAMonitoring} />
                  Lado Esquerdo
                </Space>
                <Space size="small" direction="horizontal">
                  <S.LegendSquare lcolor={labelBMonitoring} />
                  Lado Direito
                </Space>
              </S.SubContainerLegends>

              <Tooltip title="Comprimento do passo médio em centímetros (cm). Ex: 150cm.">
                <InfoCircleOutlined color="#3A3979" size={24} />
              </Tooltip>
            </Space>
          </Col>
        </Col>

        <Col
          className="gutter-row"
          span={firstChange ? 12 : 24}
          style={{ borderLeft: '1px solid lightgray' }}
        >
          <Col>
            <br />
            <S.TitleSession>Cadência</S.TitleSession>
            <br />

            <Space
              size="small"
              direction="horizontal"
              align="start"
              style={{ marginTop: '0.5rem' }}
            >
              <Plot
                data={cadenceGraph}
                layout={cadencyLayout}
                config={{
                  displayModeBar: false,
                }}
              />
              <Tooltip title="Número de passos por segundo. Ex: 2 passos/segundos.">
                <InfoCircleOutlined color="#3A3979" size={24} />
              </Tooltip>
            </Space>

            <br />
            <S.TitleSession>Apoio e Balanço</S.TitleSession>
            <br />

            <Space
              size="small"
              direction="horizontal"
              align="start"
              style={{ marginTop: '0.5rem' }}
            >
              <Plot
                data={stanSwiGraph}
                layout={stanceSwingLayout}
                config={{
                  displayModeBar: false,
                }}
              />

              <S.SubContainerLegends>
                <Space size="small" direction="horizontal">
                  <S.LegendSquare lcolor={labelAMonitoring} />
                  Apoio
                </Space>
                <Space size="small" direction="horizontal">
                  <S.LegendSquare lcolor={labelBMonitoring} />
                  Balanço
                </Space>
              </S.SubContainerLegends>

              <Tooltip title="Fase de apoio é a fase entre o calcanhar encostar no chão e o pé sair do chão. Fase de balanço é a fase entre o pé sair do chão e o calcanhar encostar no chão.">
                <InfoCircleOutlined color="#3A3979" size={24} />
              </Tooltip>
            </Space>
          </Col>
        </Col>
      </Row>
      <br />
    </>
  );
}
