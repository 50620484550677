import styled from 'styled-components';

export const ContainerFilters = styled.div`
  width: 100%;
  height: 350px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const AntdStyles = {
  buttonApply: {
    width: '140px',
    borderRadius: '5px',
    backgroundColor: '#118E88',
    borderColor: '#118E88',
  },

  buttonClear: {
    width: '160px',
    borderRadius: '5px',
    borderColor: '#118E88',
    color: '#118E88',
    justifyContent: 'space-around',
  },

  checkbox: {
    marginLeft: '4px',
  },
};

export const DivColCheckbox = styled.div`
  display: flex;
  flex-direction: column;
`;
