import React, { useState, useEffect } from 'react';
import { Layout, Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getContentVideos } from 'store/modules/professional_content/actions';
import AppHeader from 'components/Header';
import { Content } from './Content';
import { Blog } from './Blog';
import { ContentListEnum } from './utils';
import ContentContext from './Context';

import * as S from './styles';

function ProfessionalContent() {
  const dispatch = useDispatch();

  const [chooseContent, setChooseContent] = useState('BLOG');
  const videos = useSelector(state => state.professional_content.content);

  useEffect(() => {
    dispatch(getContentVideos());
  }, []);

  return (
    <Layout>
      <AppHeader />
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '90%',
            alignItems: 'center',
          }}
        >
          <Radio.Group
            value={chooseContent}
            onChange={e => setChooseContent(e.target.value)}
            style={{ marginTop: '70px' }}
          >
            <Radio.Button
              value={ContentListEnum.BLOG}
              style={
                chooseContent === ContentListEnum.BLOG
                  ? S.AntdStyles.RadioButtonActiveAbleLeft
                  : S.AntdStyles.RadioButtonActiveDisableLeft
              }
            >
              Tutorial
            </Radio.Button>
            <Radio.Button
              value={ContentListEnum.VIDEOS}
              style={
                chooseContent === ContentListEnum.VIDEOS
                  ? S.AntdStyles.RadioButtonActiveAbleRight
                  : S.AntdStyles.RadioButtonActiveDisableRight
              }
            >
              Aulas
            </Radio.Button>
          </Radio.Group>
        </div>
        {videos !== null && (
          <ContentContext.Provider value={{ videos }}>
            {chooseContent === 'BLOG' && <Blog />}
            {chooseContent === 'VIDEOS' && <Content />}
          </ContentContext.Provider>
        )}
        {/* (videos === null || videos === undefined) && (
          <div style={{ marginTop: '100px' }}>Carregando ... </div>
        ) */}
      </div>
    </Layout>
  );
}

export default ProfessionalContent;
