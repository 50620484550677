import styled from 'styled-components';

export const TitleSession = styled.label`
  color: var(--unnamed-color-575757);
  text-align: left;
  font: normal normal bold 14px/19px Nunito Sans;
  letter-spacing: 0px;
  color: #575757;
  opacity: 1;
`;

export const AntdStyles = {
  ButtonSave: {
    background: 'var(--darkCyan) 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    opacity: 1,
    width: '10rem',
    margin: '1rem 0',
    fontWeight: 'bold',
  },
};

export const LabelText = styled.div`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal bold 14px/19px Nunito Sans;
  letter-spacing: 0px;
  display: flex;
  flex-direction: column;
  width: auto;
  margin-top: 2rem;
`;
