/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
  Input,
  Dropdown,
  Menu,
  Button,
  InputNumber,
  Divider,
  Radio,
  AutoComplete,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import InputMask from 'react-input-mask';
import './styles.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import 'moment/locale/pt-br';
import { StatesOfBrazil } from 'utils/States';
import {
  PropsSpecialityEnumUpper,
  PropsSpecialityEnum,
  PropsSpecialityTranslated,
  SpecialityTranslatedFromArray,
} from 'utils/speciality';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import {
  ProfessionTranslated,
  PropsProfessionArray,
  PropsProfessionArrayWithoutOther,
  PropsProfessionEnum,
  PropsProfessionTranslated,
} from 'utils/profession';

import { saveProfessionalInfo } from 'store/modules/clinic/actions';
import { maskBuilderCPFCNPJ } from 'utils/maskValidator';
import ModalSpeciality from './components/ModalSpeciality';
import * as S from './styles';

function FormProfessionalEdit() {
  const infoDetails = useSelector(state => state.clinic.infoDetails);
  const typeInfoDetails = useSelector(state => state.clinic.typeInfoDetails);
  const dispatch = useDispatch();
  const firstChange = useMediaQuery('(min-width: 770px)');

  const professionTranslated = useCallback(profession => {
    const result = PropsProfessionTranslated.find(p => p.type === profession)
      .pt;
    return result;
  }, []);

  const specialityTranslated = useCallback(speciality => {
    const result = PropsSpecialityTranslated.find(p => p.type === speciality)
      .pt;
    return result;
  }, []);
  const [specialityValidation, setspecialityValidation] = useState(false);

  const formikGeneralData = {
    initialValues: {
      name: infoDetails?.name,
      email: infoDetails?.email,
      document: infoDetails?.document,
      cellphone: infoDetails?.phone_number,
      specialities: infoDetails?.specialities,
      otherSpeciality:
        infoDetails?.specialities.find(
          item => item.speciality === PropsSpecialityEnumUpper.OTHER,
        ) !== undefined
          ? infoDetails?.specialities.find(
              item => item.speciality === PropsSpecialityEnumUpper.OTHER,
            ).otherSpecialityName
          : '',
      profession:
        infoDetails?.profession.profession.toLowerCase() ===
        PropsProfessionEnum.OTHERPROFESSION
          ? infoDetails?.profession.otherProfessionName
          : ProfessionTranslated(
              infoDetails?.profession.profession.toLowerCase(),
            ),

      otherProfession:
        infoDetails?.profession?.otherProfession !== null
          ? infoDetails?.profession?.otherProfession
          : '',
      street: infoDetails?.address?.street,
      houseNumber: infoDetails?.address?.number,
      complement: infoDetails?.address?.complement,
      neighborhood: infoDetails?.address?.neighborhood,
      city: infoDetails?.address?.city,
      state: infoDetails?.address?.state,
      postalCode: infoDetails?.address?.postal_code,
    },
    validationSchema: Yup.object({
      profession: Yup.string().required('É obrigatório'),
      specialities: Yup.array()
        .min(1, 'Selecione no mínimo uma especialidade')
        .required('É obrigatório'),
      otherProfession: Yup.string().when('profession', {
        is: 'OTHERPROFESSION',
        then: Yup.string().required('É obrigatório'),
      }),
      document: Yup.string().required('É obrigatório'),
      name: Yup.string().required('É obrigatório'),
      email: Yup.string().email('E-mail inválido'),
      cellphone: Yup.string().required('É obrigatório'),
      street: Yup.string().required('É obrigatório'),
      houseNumber: Yup.number().required('É obrigatório'),
      complement: Yup.string().required('É obrigatório'),
      neighborhood: Yup.string().required('É obrigatório'),
      city: Yup.string().required('É obrigatório'),
      state: Yup.string().required('É obrigatório'),
      postalCode: Yup.string().required('É obrigatório'),
    }),
    onSubmit: values => {
      const userSubmit = values;
      if (
        values.specialities.find(item => item === 'OTHER') &&
        values.otherSpeciality === ''
      ) {
        setspecialityValidation(true);
        return;
      }
      if (
        userSubmit.profession ===
        professionTranslated(PropsProfessionEnum.OTHERPROFESSION)
      ) {
        userSubmit.profession = userSubmit.otherProfession;
      }

      if (
        userSubmit.specialities.find(
          item => item === specialityTranslated(PropsSpecialityEnum.OTHER),
        )
      ) {
        userSubmit.specialities = userSubmit.specialities.filter(
          item => item !== specialityTranslated(PropsSpecialityEnum.OTHER),
        );
        userSubmit.specialities.push(userSubmit.otherSpeciality);
      }

      const listSpecialities = [];
      userSubmit.specialities.map(item => {
        listSpecialities.push(
          specialityTranslated(PropsSpecialityEnum[item.speciality]),
        );
      });
      userSubmit.specialitiesTranslated = listSpecialities;

      dispatch(saveProfessionalInfo(userSubmit, typeInfoDetails));
    },
  };
  const [editSpeciality, setEditSpeciality] = useState(false);

  return (
    <Formik
      initialValues={formikGeneralData.initialValues}
      validationSchema={formikGeneralData.validationSchema}
      onSubmit={values => formikGeneralData.onSubmit(values)}
    >
      {props => (
        <div style={{ paddingBottom: '3rem' }}>
          {editSpeciality && (
            <ModalSpeciality
              visible={editSpeciality}
              setVisible={setEditSpeciality}
              propsFormik={props}
              specialitiesUser={infoDetails.specialities}
              handleOk={specialities => {
                const newListSpec = [];
                specialities.map(item => {
                  const newSpecialities = {};
                  newSpecialities.speciality = item;
                  newListSpec.push(newSpecialities);
                });
                props.setFieldValue('specialities', newListSpec);
              }}
            />
          )}
          <S.TitlePage>Informações da Conta</S.TitlePage>

          <S.DivSession>
            <S.TitleSession>Dados Cadastrais</S.TitleSession>
          </S.DivSession>

          <Row gutter={[32, 16]}>
            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Nome
                <Input
                  style={S.AntdStyles.marginTop}
                  id="name"
                  name="name"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.name}
                  onBlur={props.handleBlur}
                />
              </S.LabelText>
              {props.touched.name && props.errors.name ? (
                <S.ErrorMessage>{props.errors.name}</S.ErrorMessage>
              ) : null}
            </Col>

            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                E-mail
                <Input
                  style={S.AntdStyles.marginTop}
                  id="email"
                  name="email"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.email}
                  onBlur={props.handleBlur}
                  disabled
                />
                {props.touched.email && props.errors.email ? (
                  <S.ErrorMessage>{props.errors.email}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>
            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                CPF ou CNPJ
                <InputMask
                  mask={maskBuilderCPFCNPJ(props.values.document)}
                  style={S.AntdStyles.inputMask}
                  defaultValue={
                    props.values.document !== undefined
                      ? props.values.document
                      : ''
                  }
                  onChange={e =>
                    props.setFieldValue(
                      'document',
                      e.target.value.replace(/\D/g, ''),
                    )
                  }
                />
                {props.touched.document && props.errors.document ? (
                  <S.ErrorMessage>{props.errors.document}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Telefone
                <InputMask
                  mask="(99)99999-9999"
                  defaultValue={props.values.cellphone}
                  style={S.AntdStyles.inputMask}
                  onChange={e =>
                    props.setFieldValue('cellphone', e.target.value)
                  }
                />
                {props.touched.cellphone && props.errors.cellphone ? (
                  <S.ErrorMessage>{props.errors.cellphone}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>
            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Profissão
                <AutoComplete
                  style={S.AntdStyles.AutoComplete}
                  defaultValue={props.values.profession}
                  onChange={text => props.setFieldValue('profession', text)}
                  options={PropsProfessionArrayWithoutOther.map(
                    item =>
                      item && {
                        value: professionTranslated(PropsProfessionEnum[item]),
                      },
                  )}
                />
                {props.touched.profession && props.errors.profession ? (
                  <S.ErrorMessage>{props.errors.profession}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>
            {props.values.profession === 'OTHERPROFESSION' ? (
              <Col className="gutter-row" span={firstChange ? 8 : 24}>
                <S.LabelText className="input-label">
                  Outra Profissão
                  <Input
                    placeholder="Qual?"
                    onChange={event =>
                      props.setFieldValue('otherProfession', event.target.value)
                    }
                    value={props.values.otherProfession}
                    style={{ marginTop: '4px' }}
                  />
                  {props.touched.otherProfession &&
                  props.errors.otherProfession ? (
                    <S.ErrorMessage>
                      {props.errors.otherProfession}
                    </S.ErrorMessage>
                  ) : null}
                </S.LabelText>
              </Col>
            ) : null}

            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <Row>
                <S.LabelText className="input-label">
                  <div>
                    Especialidades (
                    <S.EditSpeciality onClick={() => setEditSpeciality(true)}>
                      Editar
                    </S.EditSpeciality>
                    )
                    {props.touched.specialities && props.errors.specialities ? (
                      <S.ErrorMessage>
                        {props.errors.specialities}
                      </S.ErrorMessage>
                    ) : null}
                  </div>
                </S.LabelText>
                <S.SpecialityText>
                  {props.values.specialities.map(
                    (item, index) =>
                      `${SpecialityTranslatedFromArray(item.speciality)}${
                        index + 1 === props.values.specialities.length
                          ? ''
                          : ', '
                      }`,
                  )}
                </S.SpecialityText>
                {/* <S.EditText onClick={() => setEditSpeciality(true)}>
                  (editar)
                </S.EditText> */}
              </Row>
            </Col>
            {props.values.specialities.find(
              item => item.speciality === 'OTHER',
            ) ? (
              <Col className="gutter-row" span={firstChange ? 8 : 24}>
                <S.LabelText className="input-label">
                  Outra Área
                  <Input
                    placeholder="Qual?"
                    onChange={event =>
                      props.setFieldValue('otherSpeciality', event.target.value)
                    }
                    value={props.values.otherSpeciality}
                    style={{ marginTop: '4px' }}
                  />
                </S.LabelText>
              </Col>
            ) : null}

            {specialityValidation ? (
              <S.ErrorMessage>É obrigatório</S.ErrorMessage>
            ) : null}
          </Row>

          <S.DivSession style={S.AntdStyles.DivSessionAddress}>
            <S.TitleSession>Endereço</S.TitleSession>
          </S.DivSession>

          <Row gutter={[32, 16]} style={S.AntdStyles.marginTop2rem}>
            <Col className="gutter-row" span={firstChange ? 16 : 24}>
              <S.LabelText className="input-label">
                Rua
                <Input
                  style={S.AntdStyles.marginTop}
                  id="street"
                  name="street"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.street}
                  onBlur={props.handleBlur}
                  disabled
                />
                {props.touched.street && props.errors.street ? (
                  <S.ErrorMessage>{props.errors.street}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Número
                <InputNumber
                  min={0}
                  style={S.AntdStyles.inputNumber}
                  defaultValue={props.values.houseNumber}
                  onChange={value => props.setFieldValue('houseNumber', value)}
                  disabled
                />
                {props.touched.houseNumber && props.errors.houseNumber ? (
                  <S.ErrorMessage>{props.errors.houseNumber}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col className="gutter-row" span={firstChange ? 16 : 24}>
              <S.LabelText className="input-label">
                Complemento
                <Input
                  style={S.AntdStyles.marginTop}
                  id="complement"
                  name="complement"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.complement}
                  onBlur={props.handleBlur}
                  disabled
                />
                {props.touched.complement && props.errors.complement ? (
                  <S.ErrorMessage>{props.errors.complement}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Bairro
                <Input
                  style={S.AntdStyles.marginTop}
                  id="neighborhood"
                  name="neighborhood"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.neighborhood}
                  onBlur={props.handleBlur}
                  disabled
                />
                {props.touched.neighborhood && props.errors.neighborhood ? (
                  <S.ErrorMessage>{props.errors.neighborhood}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Cidade
                <Input
                  style={S.AntdStyles.marginTop}
                  id="city"
                  name="city"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.city}
                  onBlur={props.handleBlur}
                  disabled
                />
                {props.touched.city && props.errors.city ? (
                  <S.ErrorMessage>{props.errors.city}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Estado
                <Dropdown
                  disabled
                  overlay={
                    <Menu style={S.AntdStyles.menuStates}>
                      {StatesOfBrazil.map(state => (
                        <Menu.Item>
                          <element
                            onClick={() => props.setFieldValue('state', state)}
                          >
                            {state}
                          </element>
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                  placement="bottomCenter"
                >
                  <Button style={S.AntdStyles.ButtonTypePayment}>
                    {props.values.state}
                  </Button>
                </Dropdown>
                {props.touched.state && props.errors.state ? (
                  <S.ErrorMessage>{props.errors.state}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col className="gutter-row" span={firstChange ? 8 : 24}>
              <S.LabelText className="input-label">
                CEP
                <InputMask
                  disabled
                  mask="99999-999"
                  defaultValue={props.values.postalCode}
                  style={S.AntdStyles.inputMask}
                  onChange={e =>
                    props.setFieldValue('postalCode', e.target.value)
                  }
                />
                {props.touched.postalCode && props.errors.postalCode ? (
                  <S.ErrorMessage>{props.errors.postalCode}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>
          </Row>

          <Button
            type="primary"
            htmlType="submit"
            onClick={props.handleSubmit}
            style={S.AntdStyles.ButtonSave}
          >
            Salvar Alterações
          </Button>
          {/* <Divider />
          <S.DeleteText>
            Você pode deletar a conta deste usuário por meio do botão abaixo. No
            entanto, esta opção exclui também os dados e registros relacionados
            a este perfil. Para apenas desativá-lo temporariamente mantendo as
            suas informações, clique em “Arquivar” no menu ao lado.
          </S.DeleteText>
          <Button
            type="secondary"
            htmlType="submit"
            onClick={props.handleSubmit}
            style={{
              marginTop: 20,
              backgroundColor: 'transparent',
              borderColor: '#118E88',
              borderRadius: 5,
              color: '#118E88',
              marginBottom: 20,
            }}
          >
            Apagar Conta
          </Button> */}
        </div>
      )}
    </Formik>
  );
}

export default FormProfessionalEdit;
