import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import { useSelector } from 'react-redux';

import { Category } from '../Content/components/Categories';
import { ContainerContent } from '../Content/components/ContainerContent';
import { Post } from './components/Post';
import { blogContent } from './utils';

import * as S from './styles';

export function Blog() {
  const videos = useSelector(state => state.professional_content.content);
  const [professionalContent, setProfessionalContent] = useState([]);

  useEffect(() => {
    const finalArrayOfCategs = [];

    if (videos !== null) {
      const arrayCategOne = [];
      const contentVideos = videos;
      const arrayOfCategorieNames = [];

      for (let x = 0; x < videos.length; x += 1) {
        if (
          contentVideos[x].index_categorie === 1 ||
          contentVideos[x].index_categorie === '1'
        ) {
          arrayCategOne.push(
            <Category
              id={contentVideos[x].id}
              title={contentVideos[x].title}
              description={contentVideos[x].description}
              video_url={contentVideos[x].video_url}
              index_categorie={1}
              isPreview={false}
            />,
          );

          if (!arrayOfCategorieNames.includes(contentVideos[x].categorie, 0)) {
            arrayOfCategorieNames.push(contentVideos[x].categorie);
            finalArrayOfCategs.push({
              index: '1',
              name: contentVideos[x].categorie,
            });
          }
        }

        if (x === contentVideos.length - 1) {
          setProfessionalContent(
            <ContainerContent
              firstCategory={arrayCategOne}
              secondCategory={[]}
              thirdCategory={[]}
              fourthCategory={[]}
              fifthCategory={[]}
              sixthCategory={[]}
              arrayOfCategoryNames={finalArrayOfCategs}
              isOverflow={false}
            />,
          );
        }
      }
    }
  }, [videos]);

  return (
    <S.Container>
      {professionalContent}
      <br />
      {blogContent.map(post => (
        <Card hoverable style={{ width: '98%', marginBottom: '8px' }}>
          <Post
            title={post.title}
            description={post.description}
            link={post.link}
          />
        </Card>
      ))}
    </S.Container>
  );
}
