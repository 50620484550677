import styled from 'styled-components';

export const DivContainer = styled.div`
  margin-left: ${props => (props.firstChange ? '1rem' : '2rem')};
  height: 100%;
  width: 'calc(100% - 4rem)';
  display: flex;
  flex-direction: column;
`;

export const TitlePage = styled.h5`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal normal 20px/27px Nunito Sans;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 2rem;
  margin-bottom: 1rem;
  margin-left: 0.7rem;
`;

export const AntdStyles = {
  RadioButtonLeftAble: {
    width: '12rem',
    textAlign: 'center',
    borderColor: '#3A3979',
    borderWidth: '1px',
    backgroundColor: '#F3F2FF',
    fontWeight: 'bold',
    color: '#6864A4',
    borderRadius: '4px 0px 0px 4px',
  },

  RadioButtonLeftDisable: {
    width: '12rem',
    textAlign: 'center',
    borderWidth: '1px',
    backgroundColor: '#f7f7f7',
    fontWeight: 'bold',
    color: '#868686',
    borderRadius: '4px 0px 0px 4px',
  },

  RadioButtonRigthAble: {
    width: '12rem',
    textAlign: 'center',
    borderColor: '#3A3979',
    borderWidth: '1px',
    backgroundColor: '#F3F2FF',
    fontWeight: 'bold',
    color: '#3A3979',
    borderRadius: '0px 4px 4px 0px',
  },

  RadioButtonRigthDisable: {
    width: '12rem',
    textAlign: 'center',
    borderWidth: '1px',
    backgroundColor: '#f7f7f7',
    fontWeight: 'bold',
    color: '#868686',
    borderRadius: '0px 4px 4px 0px',
  },

  RadioButtonMiddleAble: {
    width: '12rem',
    textAlign: 'center',
    borderColor: '#3A3979',
    borderWidth: '1px',
    backgroundColor: '#F3F2FF',
    fontWeight: 'bold',
    color: '#3A3979',
    borderRadius: '0px 0px 0px 0px',
  },

  RadioButtonMiddleDisable: {
    width: '12rem',
    textAlign: 'center',
    borderWidth: '1px',
    backgroundColor: '#f7f7f7',
    fontWeight: 'bold',
    color: '#868686',
    borderRadius: '0px 0px 0px 0px',
  },
};
