/* eslint-disable no-empty */
/* eslint-disable require-yield */
/* eslint-disable array-callback-return */
/* eslint-disable no-console */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import { takeLatest, put, all, call, select, delay } from 'redux-saga/effects';
import postmon from '../../../services/postmon';
import { setAddressByCep } from './actions';

export function* getAddressByCepSaga({ payload }) {
  try {
    const { CEP } = payload;

    const response = yield call(fetch, `${postmon.baseURL}${CEP}`);
    const responseBody = yield response.json();
    yield put(setAddressByCep(responseBody));
  } catch (err) {}
}

export default all([
  takeLatest('@CEP/GET_ADDRESS_BY_CEP', getAddressByCepSaga),
]);
