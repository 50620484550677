import styled from 'styled-components';

export const AntdStyles = {
  btnCallPatient: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    borderRadius: '5px',
    font: 'normal normal bold 14px/19px Nunito Sans',
    background: '#6963a9',
    borderColor: '#6963a9',
    paddingLeft: '.1%',
    paddingRight: '.1%',
    width: '75%',
    marginBottom: '10px',
    marginTop: '0.3rem',
  },

  linkTexts: {
    color: '#6963a9',
    fontWeight: 'bold',
    font: 'normal normal bold 14px/19px Nunito Sans',
    cursor: 'pointer',
    marginTop: '0.3rem',
  },

  AvatarUser: {
    color: 'var(--darkCyan)',
    backgroundColor: '#C9E2E1',
    fontSize: 20,
    marginRight: '0.2rem',
  },
};

export const ImageCamera = styled.img`
  display: flex;
  align-items: center;
  width: 16px;
  height: 9px;
`;

export const PatientController = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 450px;
  background-color: #f7f7f7;
`;
