import React, { memo } from 'react';
import { Radio, Space } from 'antd';
import SavedAnswerComponent from 'components/SavedAnswer';
import * as S from './styles';
import './styles.css';

function TextAndRadioGroup({
  text,
  ArrayValue,
  setAnswer,
  keyAnswer,
  direction = 'horizontal',
  setFormValues,
  formValues,
  index,
  currentValue,
  propsFormik,
}) {
  function updateFormValues(value) {
    const tempForm = [];
    for (let i = 0; i < formValues.length; i += 1) {
      if (i !== index) {
        tempForm.push(formValues[i]);
      } else {
        tempForm.push({
          index: i + 1,
          text: formValues[i].text,
          value,
        });
      }
    }
    setFormValues(tempForm);
  }

  return (
    <div style={{ marginTop: '2rem' }}>
      <S.LabelText className="input-label">
        {text}
        <Radio.Group
          onChange={e => {
            setAnswer(`${keyAnswer}`, e.target.value);
            updateFormValues(e.target.value);
          }}
          style={{
            marginTop: '4px',
            fontWeight: '100',
          }}
          value={currentValue}
        >
          <Space direction={direction}>
            {ArrayValue.map(
              item => item && <Radio value={item.value}>{item.label}</Radio>,
            )}

            <SavedAnswerComponent
              resource="hasSpecializedEvaluation"
              propsFormik={propsFormik}
              fieldName={keyAnswer}
            />
          </Space>
        </Radio.Group>
      </S.LabelText>
    </div>
  );
}

export default memo(
  TextAndRadioGroup,
  (prev, next) => prev.propsFormik.values === next.propsFormik.values,
);
