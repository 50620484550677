import styled from 'styled-components';

export const AntdStyles = {
  sider: {
    display: 'flex',
    flexDirection: 'column',
    background: '#00000000',
    height: '80%',
  },

  menu: {
    display: 'flex',
    alignItems: 'center',
    background: '#118E88',
    color: 'white',
    flexDirection: 'column',
    borderRadius: '0px 0px 5px 5px',
  },
};

export const ImageRegisterData = styled.img`
  display: flex;
  align-items: center;
  width: 20px;
  height: 20px;
`;

export const ImageAnamnesis = ImageRegisterData;
export const ImageExams = ImageRegisterData;
export const ImageObjectives = ImageRegisterData;
export const ImageProtocols = ImageRegisterData;
export const ImageAgenda = ImageRegisterData;
export const ImageFollow = ImageRegisterData;
