import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Button } from 'antd';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateEvaluation } from 'store/modules/evaluations/actions';
import TextAndRadioGroup from '../../components/TextAndRadioGroup';
import * as S from './styles';

function Barthel({ title, current_eval }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const qts = current_eval?.content?.questions;

  const Questions = [
    '1. Alimentação',
    '2. Transferências',
    '3. Toalete',
    '4. Utilização do WC',
    '5. Banho',
    '6. Mobilidade',
    '7. Subir e Descer Escadas',
    '8. Vestir',
    '9. Controlo Intestinal',
    '10. Controlo Urinário',
  ];

  const [formValues, setFormValues] = useState(
    current_eval?.content?.questions.length > 0
      ? Questions.map((item, index) => ({
          index: index + 1,
          text: item,
          value: current_eval.content.questions[index].value,
        }))
      : Questions.map((item, index) => ({
          index: index + 1,
          text: item,
          value: '',
        })),
  );

  function updateValues(isFinal) {
    const tempFormValue = {
      id: current_eval?.id,
      isFinished: isFinal,
      name: title,
      professionalId: current_eval?.professionalId,
      result: null,
      content: {
        questions: formValues,
      },
    };
    dispatch(updateEvaluation(tempFormValue, id));
    if (isFinal) {
      setFormValues(
        Questions.map((item, index) => ({
          index: index + 1,
          text: item,
          value: '',
        })),
      );
    }
  }

  useEffect(() => {
    const valuesFormValues = Object.values(formValues);
    if (valuesFormValues.find(item => item.value !== '')) {
      updateValues(false);
    }
  }, [formValues]);

  const forms = [
    { label: 'Independente (10 pontos)', value: '10' },
    {
      label:
        'Precisa de alguma ajuda (por exemplo para cortar os alimentos) (5 pontos)',
      value: '5',
    },
    { label: 'Dependente (0 pontos)', value: '0' },
  ];

  const forms2 = [
    { label: 'Independente (15 pontos)', value: '15' },
    { label: 'Precisa de alguma ajuda (10 pontos)', value: '10' },
    {
      label:
        'Necessita de ajuda de outra pessoa, mas não consegue sentar-se (5 pontos)',
      value: '5',
    },
    { label: 'Dependente, não tem equilíbrio sentado (0 pontos)', value: '0' },
  ];

  const forms3 = [
    {
      label:
        'Independente a fazer a barta, lavar o rosto, lavar os dentes (5 pontos)',
      value: '5',
    },
    { label: 'Dependente, necessita de alguma ajuda (0 pontos)', value: '0' },
  ];

  const forms4 = [
    { label: 'Independente (10 pontos)', value: '10' },
    {
      label: 'Precisa de alguma ajuda (5 pontos)',
      value: '5',
    },
    { label: 'Dependente (0 pontos)', value: '0' },
  ];

  const forms5 = [
    {
      label:
        'Toma banho só (entra e sai do duche ou banheira sem ajuda) (5 pontos)',
      value: '5',
    },
    { label: 'Dependente, necessita de alguma ajuda (0 pontos)', value: '0' },
  ];

  const forms6 = [
    {
      label:
        'Caminha 50 metros, sem ajuda ou supervisão (pode usar ortóteses) (15 pontos)',
      value: '15',
    },
    {
      label: 'Caminha menos de 50 metros, com pouca ajuda (10 pontos)',
      value: '10',
    },
    {
      label:
        'Independentes, em cadeira de rodas, pelo menos 50 metros, incluindo esquinas (5 pontos)',
      value: '5',
    },
    { label: 'Imóvel (0 pontos)', value: '0' },
  ];

  const forms7 = [
    {
      label: 'Independentes, com ou sem ajudas técnicas (10 pontos)',
      value: '10',
    },
    {
      label: 'Precisa de ajuda (5 pontos)',
      value: '5',
    },
    { label: 'Dependente (0 pontos)', value: '0' },
  ];

  const forms8 = [
    {
      label: 'Independentes (10 pontos)',
      value: '10',
    },
    {
      label: 'Com ajuda (5 pontos)',
      value: '5',
    },
    { label: 'Impossível (0 pontos)', value: '0' },
  ];

  const forms9 = [
    {
      label:
        'Controla perfeitamente, sem acidentes, podendo fazer uso de supositório ou similar (10 pontos)',
      value: '10',
    },
    {
      label: 'Acidente ocasional (5 pontos)',
      value: '5',
    },
    { label: 'Impossível (0 pontos)', value: '0' },
  ];

  const forms10 = [
    {
      label:
        'Controla perfeitamente, mesmo algaliado desde que seja capaz de manejar a algália sozinho (10 pontos)',
      value: '10',
    },
    {
      label: 'Acidente ocasional (mázimo uma vez por semana) (5 pontos)',
      value: '5',
    },
    {
      label:
        'Incontinente, ou algaliado sendo incapaz de manejar a algália sozinho (0 pontos)',
      value: '0',
    },
  ];

  function returnForms(indexQuestion) {
    if (indexQuestion === 2) return forms2;
    if (indexQuestion === 3) return forms3;
    if (indexQuestion === 4) return forms4;
    if (indexQuestion === 5) return forms5;
    if (indexQuestion === 6) return forms6;
    if (indexQuestion === 7) return forms7;
    if (indexQuestion === 8) return forms8;
    if (indexQuestion === 9) return forms9;
    if (indexQuestion === 10) return forms10;

    return forms;
  }

  return (
    <Formik
      initialValues={{
        Answer1: qts[0] !== undefined ? qts[0].value : '',
        Answer2: qts[1] !== undefined ? qts[1].value : '',
        Answer3: qts[2] !== undefined ? qts[2].value : '',
        Answer4: qts[3] !== undefined ? qts[3].value : '',
        Answer5: qts[4] !== undefined ? qts[4].value : '',
        Answer6: qts[5] !== undefined ? qts[5].value : '',
        Answer7: qts[6] !== undefined ? qts[6].value : '',
        Answer8: qts[7] !== undefined ? qts[7].value : '',
        Answer9: qts[8] !== undefined ? qts[8].value : '',
        Answer10: qts[9] !== undefined ? qts[9].value : '',
      }}
      onSubmit={(values, actions) => {
        // eslint-disable-next-line prefer-const
        let valuesSubmit = {
          name: title,
          content: {
            questions: [],
          },
        };

        Questions.forEach((item, index) => {
          valuesSubmit.content.questions.push({
            index: index + 1,
            text: item,
            value: values[`Answer${index + 1}`],
          });
        });

        actions.resetForm({
          values: {
            Answer1: '',
            Answer2: '',
            Answer3: '',
            Answer4: '',
            Answer5: '',
            Answer6: '',
            Answer7: '',
            Answer8: '',
            Answer9: '',
            Answer10: '',
          },
        });

        updateValues(true);
      }}
    >
      {props => (
        <>
          {Questions.map(
            (item, index) =>
              item && (
                <TextAndRadioGroup
                  text={item}
                  ArrayValue={returnForms(index + 1)}
                  setAnswer={props.setFieldValue}
                  keyAnswer={`Answer${index + 1}`}
                  direction="vertical"
                  setFormValues={setFormValues}
                  formValues={formValues}
                  index={index}
                  currentValue={props.values[`Answer${index + 1}`]}
                  propsFormik={props}
                />
              ),
          )}

          <Button
            type="primary"
            htmlType="submit"
            onClick={props.handleSubmit}
            style={S.AntdStyles.ButtonSave}
          >
            Salvar
          </Button>
        </>
      )}
    </Formik>
  );
}

export default Barthel;
