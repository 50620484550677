import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 80%;
  height: 100%;
  padding-top: 7%;

  @media (min-width: 770px) {
    flex-direction: row;
  }

  @media (max-width: 770px) {
    flex-direction: column;
  }
`;

export const ContainerLeftSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;

  strong {
    &.subtitle {
      opacity: 0.6;
    }
  }
`;

export const ContainerPlan = styled.div`
  display: flex;
  width: 100%;
  height: 320px;
`;

export const ContainerCompanyData = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 15px 10px 0px 10px;
  width: 350px;
  height: 200px;

  strong {
    text-align: left;

    &.subtitle-company-name {
      color: var(--darkCyan);
      font: normal normal bold 16px/19px Nunito Sans;
    }

    &.plan-price {
      color: var(--davysGrey);
      margin-left: auto;
    }

    &.big-plan-price {
      color: var(--davysGrey);
      margin-left: 4%;
    }
  }

  @media (min-width: 770px) {
    margin-top: 5rem;
  }

  @media (max-width: 770px) {
    margin-top: 0rem;
  }
`;

export const ContainerInfo = styled.div`
  display: flex;
  width: 320px;
  margin-bottom: 1rem;

  label {
    font: normal normal normal 14px/16px Nunito Sans;
  }
`;

export const ContainerDivider = styled.div`
  width: 80%;
`;

export const ContainerRightSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-left: 5%;

  strong {
    &.final-coment {
      color: var(--darkCyan);
    }
  }
`;

export const LabelText = styled.div`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal bold 14px/19px Nunito Sans;
  letter-spacing: 0px;
  display: flex;
  flex-direction: column;
`;

export const TitlePage = styled.h5`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal bold 20px/25px Nunito Sans;
  letter-spacing: 0px;
  opacity: 0.6;
  margin-bottom: 1rem;
`;

export const ContainerTypeAccount = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const ContainerTypeAccountChoices = styled.div`
  display: flex;
  width: 100%;
`;

export const ContainerCredCardInfo = styled.div`
  display: flex;
  width: 100%;
  height: auto;

  @media (min-width: 770px) {
    flex-direction: row;
  }

  @media (max-width: 770px) {
    flex-direction: column;
  }
`;

export const ContainerCredCardInputs = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  strong {
    font: normal normal bold 16px/19px Nunito Sans;
    letter-spacing: 0px;
    opacity: 1;
    margin-top: 1rem;
  }
`;

export const ContainerSmallInputs = styled.div`
  display: flex;
  width: 100%;

  @media (min-width: 770px) {
    flex-direction: row;
  }

  @media (max-width: 770px) {
    flex-direction: column;
  }
  margin-bottom: 1rem;
`;

export const ContainerSectionSmallInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 47%;
  margin-right: 3%;
`;

export const ContainerCard = styled.div`
  display: flex;
  width: 50%;
  margin-left: 10px;

  @media (min-width: 770px) {
    margin-top: 4px;
  }

  @media (max-width: 770px) {
    margin-top: 7rem;
  }
`;

export const ErrorMessage = styled.label`
  color: #c12;
  font-size: 12px;
  margin-top: 2px;
  font-weight: 100;
`;

export const ContainerPaymentFormButtons = styled.div`
  display: flex;
  width: 15rem;
  justify-content: space-between;
`;

export const AntdStyles = {
  inputMask: {
    textAlign: 'left',
    font: 'normal normal normal 14px/19px Nunito Sans',
    letterSpacing: '0px',
    color: 'var(--jet)',
    display: 'flex',
    height: '32px',
    width: '100%',
    padding: '4px 11px',
    borderWidth: 1,
    border: '1px solid #d9d9d9',
  },
};
