import React, { useState } from 'react';
import { Menu, Dropdown, Button } from 'antd';
import ArrowDownGrey from 'assets/arrow-down-grey.svg';
import { ByPractice } from './components/ByPractice';
import { ByPeriod } from './components/ByPeriod';
import * as S from './styles';

export function PerformedHomePractices() {
  const dropdownOption = ['Por Prática', 'Por Período'];

  const [selectedOption, setSelectedOption] = useState('Por Prática');

  function handleDropdownSelection(item) {
    setSelectedOption(item);
  }

  return (
    <>
      <S.Subtitles>Exibindo</S.Subtitles>
      <Dropdown
        overlay={
          <Menu style={S.AntdStyles.menuExams}>
            {dropdownOption.map(item => (
              <Menu.Item>
                <element onClick={() => handleDropdownSelection(item)}>
                  {item}
                </element>
              </Menu.Item>
            ))}
          </Menu>
        }
        placement="bottomCenter"
      >
        <Button style={S.AntdStyles.ButtonExams}>
          {selectedOption}{' '}
          <S.ImgDropdown src={ArrowDownGrey} alt="mostrar exames" />
        </Button>
      </Dropdown>
      {selectedOption === 'Por Prática' ? <ByPractice /> : <ByPeriod />}
    </>
  );
}
