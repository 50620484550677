import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { useParams } from 'react-router-dom';
import { getPatientGoals } from 'store/modules/goals/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import AppHeader from 'components/Header';
import SiderMenu from 'components/Siders/Professional/Sider';
import { getPatientDetails } from 'store/modules/patient/actions';
import FormGoals from './components/FormGoals';

function Goals({ calledByVideoCall = false }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const patientGoals = useSelector(state => state.goals.goals);
  const loadingGoals = useSelector(state => state.goals.loading);
  const firstChange = useMediaQuery('(min-width: 900px)');

  useEffect(() => {
    dispatch(getPatientGoals(id));
  }, []);

  useEffect(() => {
    dispatch(getPatientDetails(id));
  }, []);

  return (
    <Layout>
      {!calledByVideoCall && <AppHeader />}
      <SiderMenu calledByVideoCall={calledByVideoCall}>
        <div style={{ marginLeft: firstChange ? '2rem' : '4rem' }}>
          {loadingGoals === false && patientGoals !== null && <FormGoals />}
          {(loadingGoals === true || patientGoals === null) && (
            <div>Carregando ... </div>
          )}
        </div>
      </SiderMenu>
    </Layout>
  );
}

export default Goals;
