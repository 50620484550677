import styled from 'styled-components';

export const ContainerTable = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  tbody {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
`;

export const ImgDropdown = styled.img`
  width: 8px;
  height: 4px;
`;

export const Subtitles = styled.label`
  text-align: left;
  font: normal normal bold 14px/19px Nunito Sans;
  letter-spacing: 0px;
  color: var(--davysGrey);
  opacity: 1;
  margin-top: 1rem;
`;

export const TableOfEvaluations = styled.table`
  width: 90%;
  border: 1px solid var(--philippineSilver);
  border-collapse: collapse;
  opacity: 0.8;
  font-size: 1rem;
  overflow-x: scroll;

  th {
    padding: 1rem 1rem;
    border: 1px solid var(--philippineSilver);
    white-space: nowrap;
    text-align: center;
    max-width: 150px;

    &:first-child {
      background: var(--reflection);
      min-width: 200px;
    }
  }

  td {
    border: 1px solid var(--philippineSilver);
    max-width: 150px;
    &:first-child {
      padding: 0.2rem;
    }

    & + td {
      text-align: center;
    }
  }

  &.mal-eval-td {
    flex-direction: column;
  }
`;

export const TitleSession = styled.label`
  opacity: 1;
  color: var(--deepKoamaru);
  text-align: start;
  font: normal normal bold 14px/19px Nunito Sans;
  letter-spacing: 0px;
  background: var(--lavender) 0% 0% no-repeat padding-box;
  border-left: solid var(--deepKoamaru) 2px;
  border-radius: 0 1px 1px 0;
  padding: 3px 8px 3px 5px;
  height: 25px;
`;

export const PDescript = styled.p`
  text-align: left;
  font: normal normal normal 14px/18px Nunito Sans;
  letter-spacing: 0px;
  color: #575757;
  opacity: 1;
  width: 50%;
  margin: 0.75rem 0 0 0.75rem;
`;

export const AntdStyles = {
  marginTop: {
    marginTop: '4px',
  },

  ButtonExams: {
    marginTop: '4px',
    textAlign: 'left',
    fontFamily: 'Nunito Sans',
    display: 'flex',
    minWidth: '200px',
    maxWidth: '400px',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '4px',
  },

  menuExams: {
    maxHeight: 200,
    overflowY: 'scroll',
  },

  RadioButtonSelectVisualization: {
    width: 'auto',
    textAlign: 'center',
    borderColor: '#6864A4',
    borderWidth: '1px',
    backgroundColor: '#f7f7f7',
    fontWeight: 'bold',
    color: '#6864A4',
    borderRadius: '0px 0px 0px 0px',
  },
  RadioButtonLeftAble: {
    width: '7rem',
    textAlign: 'center',
    borderColor: '#3A3979',
    borderWidth: '1px',
    backgroundColor: '#F3F2FF',
    fontWeight: 'bold',
    color: '#6864A4',
    borderRadius: '4px 0px 0px 4px',
  },

  RadioButtonLeftDisable: {
    width: '7rem',
    textAlign: 'center',
    borderWidth: '1px',
    backgroundColor: '#f7f7f7',
    fontWeight: 'bold',
    color: '#868686',
    borderRadius: '4px 0px 0px 4px',
  },

  RadioButtonRigthAble: {
    width: '7rem',
    textAlign: 'center',
    borderColor: '#3A3979',
    borderWidth: '1px',
    backgroundColor: '#F3F2FF',
    fontWeight: 'bold',
    color: '#3A3979',
    borderRadius: '0px 4px 4px 0px',
  },

  RadioButtonRigthDisable: {
    width: '7rem',
    textAlign: 'center',
    borderWidth: '1px',
    backgroundColor: '#f7f7f7',
    fontWeight: 'bold',
    color: '#868686',
    borderRadius: '0px 4px 4px 0px',
  },

  RadioButtonMiddleAble: {
    width: '7rem',
    textAlign: 'center',
    borderColor: '#3A3979',
    borderWidth: '1px',
    backgroundColor: '#F3F2FF',
    fontWeight: 'bold',
    color: '#3A3979',
    borderRadius: '0px 0px 0px 0px',
  },

  RadioButtonMiddleDisable: {
    width: '7rem',
    textAlign: 'center',
    borderWidth: '1px',
    backgroundColor: '#f7f7f7',
    fontWeight: 'bold',
    color: '#868686',
    borderRadius: '0px 0px 0px 0px',
  },
};
