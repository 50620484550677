import { Modal, Button } from 'antd';

import closeIcon from 'assets/close.svg';

import * as S from './styles';
import './styles.css';

export function ModalCreateClinic({ isModalVisible, handleCancel, handleOk }) {
  return (
    <Modal
      title="Plano de Clínica"
      visible={isModalVisible}
      onOk={() => handleOk()}
      onCancel={() => handleCancel()}
      style={{
        borderRadius: '5px',
      }}
      centered
      bodyStyle={{ borderRadius: '20px' }}
      width={450}
      closeIcon={
        <img src={closeIcon} alt="close" style={S.AntdStyles.iconClose} />
      }
      footer={[
        <Button
          type="primary"
          onClick={() => handleOk()}
          style={{
            width: '180px',
            borderRadius: '5px',
            backgroundColor: '#118E88',
            borderColor: '#118E88',
          }}
        >
          Ok
        </Button>,
      ]}
    >
      <S.ContainerModal>
        <S.LabelText className="input-label">
          Para contratar um plano de clínica é necessário que você tenha uma
          clínica. Utilize o menu superior direito para criar/acessar sua
          clínica.
        </S.LabelText>
      </S.ContainerModal>
    </Modal>
  );
}
