/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable consistent-return */

import {
  Layout,
  Input,
  Radio,
  Button,
  Divider,
  Table,
  Space,
  Avatar,
  Tooltip,
  Col,
  Row,
  Select,
} from 'antd';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import api from 'services/api';

import editGreen from 'assets/editGreen.svg';
// import thrashGreen from 'assets/thrashGreen.svg';
import archivedGreen from 'assets/archivedGreen.svg';
import AppHeader from 'components/Header';
import IconSearch from 'assets/icon-search.svg';
import AssignIcon from 'assets/assigntoprofessional.svg';
import archivedGrey from 'assets/archivedGrey.svg';
import {
  getPatientsList,
  archivePatient,
  unarchivePatient,
  deletePatient,
} from 'store/modules/patient/actions';
import { refreshToken, setMyAccountView } from 'store/modules/auth/actions';
import {
  getTherapistsWithInvites,
  getSelf,
  setInfoDetails,
} from 'store/modules/clinic/actions';
import { nameHandler } from 'utils/NameHandler';

import { getTimezone } from 'store/modules/messages/actions';
import desarchivedGray from 'assets/desarchiveGrey.svg';
import desarchivedGreen from 'assets/desarchive.svg';
import { PropsProfileEnum } from 'utils/profile';
import {replaceSpecialChars} from 'utils/Validator';
import { AttributeProfessionalModal } from './components/attributeProfessional';
import { PatientListEnum } from './utils/patientList';
import { DeletePatientModal } from './components/DeletePatientModal';
import { AddPatientModal } from './components/addPatientModal';
import * as S from './styles';
import './styles.css';

function PatientListAdmin() {
  const dispatch = useDispatch();
  const history = useHistory();
  const firstChange = useMediaQuery('(min-width: 992px)');
  const secondChange = useMediaQuery('(min-width: 830px)');
  const thirdChange = useMediaQuery('(min-width: 480px)');
  const user = useSelector(state => state.auth.user);
  const therapistList = useSelector(state => state.clinic.therapists);

  const location = useLocation();
  const searchDefault = location?.state?.search;

  // Quando o token expira
  api.interceptors.response.use(
    response => response,
    error => {
      if (error.message === 'Request failed with status code 401') {
        dispatch(refreshToken());
      }
      Promise.reject(error);
    },
  );

  useEffect(() => {
    if (user !== null) {
      let isVinculatedToClinic = true;
      user.profiles.forEach(item => {
        if (
          item.clinicId === null &&
          item.profile === 'RECEPTIONIST' &&
          user.active_profile_id === item.id
        ) {
          isVinculatedToClinic = false;
        }
      });
      if (isVinculatedToClinic && user.active_profile_name !== 'THERAPIST') {
        dispatch(getPatientsList());
        dispatch(getTimezone());
        dispatch(getSelf());
      }
      if (
        user?.active_profile_name === 'CLINIC' ||
        user?.active_profile_name === 'CLINIC_ADMINISTRATOR'
      ) {
        dispatch(setMyAccountView(false));
      } else {
        dispatch(setMyAccountView(true));
      }
    }
  }, [user]);

  const patientList = useSelector(state => state.patient.patientList);
  const loading = useSelector(state => state.patient.loading);

  const [choosePatient, setChoosePatient] = useState('ACTIVE');

  const [selectedPatient, setSelectedPatient] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [withProfessional, setWithProfessional] = useState('Por Profissional');
  //  const [recordId, setRecordId] = useState('');
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [isModalAttributeVisible, setIsModalAttributeVisible] = useState(false);

  const rowSelection = {
    onChange: (_, selectedRows) => {
      setSelectedPatient(selectedRows);
    },
  };

  const buttonArchive = useCallback(patientID => {
    const patientsID = [patientID];
    dispatch(archivePatient(patientsID));
    setSelectedPatient([]);
  }, []);

  const buttonActive = useCallback(patientID => {
    const patientsID = [patientID];
    dispatch(unarchivePatient(patientsID));
    setSelectedPatient([]);
  }, []);

  const columns = [
    {
      title: 'Pacientes',
      dataIndex: 'name',
      key: 'name',
      width: '50%',
      render: (text, record) => (
        <Space size="middle">
          <Avatar size={24} src={record?.photo} style={S.AntdStyles.AvatarUser}>
            {record.photo === null ? nameHandler(text) : null}
            {
              // vai ser necessario colocar condicional para ver se pode [0] [0], porque talvez o nome da pessoa seja apenas um só
            }
          </Avatar>
          <label
            onClick={() => {
              dispatch(setInfoDetails(null, 'PATIENT'));
              history.push({
                pathname: `${record.id}/edit_info`,
                state: { search: PropsProfileEnum.PATIENT },
              });
            }}
            style={{ cursor: 'pointer' }}
          >
            {text}
          </label>
        </Space>
      ),
    },
    {
      title: 'Profissionais Atribuídos',
      dataIndex: 'assignedProfessionals',
      key: 'assignedProfessionals',
      width: '35%',
      render: (text, _) => (
        <label style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
          {text !== undefined && text !== null && text.length > 0
            ? text.join(', ')
            : '-'}
        </label>
      ),
    },
    {
      title: 'Ações',
      key: 'action',
      render: (_, record) => {
        if (choosePatient === PatientListEnum.ACTIVE) {
          return (
            <Space size="middle">
              <Tooltip title="Arquivar">
                <img
                  src={archivedGreen}
                  onClick={() => {
                    buttonArchive(record.id);
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
              <Tooltip title="Editar">
                <img
                  src={editGreen}
                  onClick={() => {
                    dispatch(setInfoDetails(record, 'PATIENT'));
                    history.push(`${record.id}/edit_info`);
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
              {/* <Tooltip title="Excluir">
                <img
                  src={thrashGreen}
                  onClick={() => {
                    setRecordId(record.id);
                    setIsModalDeleteVisible(true);
                  }}
                  style={{ cursor: 'pointer' }}
                />
                </Tooltip> */}
            </Space>
          );
        }

        if (choosePatient === PatientListEnum.ARCHIVED) {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Tooltip title="Ativar">
                <img
                  src={desarchivedGreen}
                  onClick={() => {
                    buttonActive(record.id);
                  }}
                  style={{ cursor: 'pointer', marginRight: '0.8rem' }}
                />
              </Tooltip>

              {/* <Tooltip title="Excluir">
                <img
                  src={thrashGreen}
                  onClick={() => {
                    setRecordId(record.id);
                    setIsModalDeleteVisible(true);
                  }}
                  style={{ cursor: 'pointer' }}
                />
                </Tooltip> */}
            </div>
          );
        }
      },
    },
  ];

  const [dataTable, setDataTable] = useState([]);
  const [dataTableOrder, setDataTableOrder] = useState([]);

  useEffect(() => {
    const sort = dataTable.sort((a, b) => {
      const textA = replaceSpecialChars(a.name.toUpperCase());
      const textB = replaceSpecialChars(b.name.toUpperCase());
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
    setDataTableOrder(sort);
  },[dataTable]);

  const buttonGroupArchiveOrActive = useCallback((listPatient, choose) => {
    const patientsID = listPatient.map(item => item && item.id);
    if (choose === PatientListEnum.ACTIVE) {
      dispatch(archivePatient(patientsID));
    } else if (choose === PatientListEnum.ARCHIVED) {
      dispatch(unarchivePatient(patientsID));
    }
    setSelectedPatient([]);
  }, []);

  function handleModalFalse() {
    setIsModalVisible(false);
  }

  function handleDeleteOk() {
    dispatch(deletePatient('recordId'));
    setIsModalDeleteVisible(false);
  }

  function handleDeleteCancel() {
    setIsModalDeleteVisible(false);
  }

  function handleAttributeModalFalse() {
    setIsModalAttributeVisible(false);
  }

  useEffect(() => {
    if (patientList !== null) {
      const newListPatient = patientList.filter(
        item => item.situation === choosePatient,
      );

      // Validar se existe paciente com situação
      if (newListPatient.length !== 0) {
        setDataTable(
          newListPatient.map(item => item && { ...item, key: item.id }),
        );
        // se não tiver paciente com situação exibe patientList normalmente
      } else {
        // setDataTable([...patientList]);
        setDataTable([]);
      }
    }
  }, [patientList]);

  // filtro
  function changeTextFilter(
    patientName,
    filterProfessional,
    professionalName,
    situation,
  ) {


    const oldListPatient = patientList.map(
      item => item && { ...item, key: item.id },
    );

    let newListPatient = [];

    if (filterProfessional && professionalName !== '') {
      if (professionalName === 'Todos profissionais') {
        newListPatient = oldListPatient.filter(
          item =>
            replaceSpecialChars(item.name).toLowerCase().includes(replaceSpecialChars(patientName).toLowerCase()) && item.situation === situation,
        );
      } else {
        newListPatient = oldListPatient.filter(
          item =>
            replaceSpecialChars(item.name).toLowerCase().includes(replaceSpecialChars(patientName).toLowerCase()) &&
            item.situation === situation &&
            item.assignedProfessionals.includes(professionalName),
        );
      }
    } else if (!filterProfessional) {
      newListPatient = oldListPatient.filter(
        item =>
          replaceSpecialChars(item.name).toLowerCase().includes(replaceSpecialChars(patientName).toLowerCase()) &&
          item.situation === situation &&
          item.assignedProfessionals.length === 0,
      );
    } else {
      newListPatient = oldListPatient.filter(
        item =>
          replaceSpecialChars(item.name).toLowerCase().includes(replaceSpecialChars(patientName).toLowerCase()) && item.situation === situation,
      );
    }

    setDataTable(newListPatient);
  }

  const [patientName, setPatientName] = useState('');
  const [professionalName, setProfessionalName] = useState(
    'Todos profissionais',
  );
  const [professionalNameList, setProfessionalNameList] = useState([
    { value: 'Todos profissionais' },
  ]);

  useEffect(() => {
    if (patientList !== null) {
      changeTextFilter(
        patientName,
        withProfessional === 'Por Profissional',
        professionalName,
        choosePatient,
      );
    }
  }, [choosePatient]);

  useEffect(() => {
    dispatch(getTherapistsWithInvites());
  }, []);

  const therapistsAndInvites = useSelector(
    state => state.clinic.therapistsAndInvites,
  );

  useEffect(() => {
    if (therapistsAndInvites !== null && therapistsAndInvites?.length > 0) {
      // eslint-disable-next-line prefer-const
      let professionalList = [{ value: 'Todos profissionais' }];

      therapistsAndInvites.forEach(item => {
        professionalList.push({
          value: item.professionalName,
        });
      });

      setProfessionalNameList(professionalList);
    }
  }, [therapistsAndInvites]);

  useEffect(() => {
    if (
      patientList !== null &&
      patientList.length > 0 &&
      searchDefault !== undefined
    ) {
      setProfessionalName(searchDefault);
      changeTextFilter(
        patientName,
        withProfessional === 'Por Profissional',
        searchDefault,
        choosePatient,
      );
    }
  }, [patientList]);

  return (
    <Layout>
      <AppHeader />
      <AddPatientModal
        isModalVisible={isModalVisible}
        handleModalFalse={handleModalFalse}
      />
      <DeletePatientModal
        isModalVisible={isModalDeleteVisible}
        handleOk={handleDeleteOk}
        handleCancel={handleDeleteCancel}
      />
      <AttributeProfessionalModal
        isModalVisible={isModalAttributeVisible}
        handleAttributeModalFalse={handleAttributeModalFalse}
        patientsList={selectedPatient}
        professionalsList={therapistList}
      />
      <S.Container mediaFirst={firstChange} mediaThird={thirdChange}>
        <Col style={{ width: firstChange ? '30%' : '90%' }}>
          <S.TitlePage>Lista de pacientes da clínica</S.TitlePage>

          <S.DivInterationPatient media={secondChange}>
            <div
              style={{
                marginRight: firstChange ? '1rem' : '0.5rem',
                marginLeft: firstChange ? '0.2rem' : '0.5rem',
                display: secondChange ? 'block' : 'flex',
                flexDirection: secondChange ? 'row' : 'column',
                marginBottom: secondChange ? '0rem' : '1rem',
              }}
            >
              <Button
                type="primary"
                style={S.AntdStyles.ButtonAddPatient}
                onClick={() => setIsModalVisible(true)}
              >
                Adicionar Paciente
              </Button>
              <Input
                placeholder="Busque por um paciente"
                value={patientName}
                onChange={e => {
                  setPatientName(e.target.value);
                  changeTextFilter(
                    e.target.value,
                    withProfessional === 'Por Profissional',
                    professionalName,
                    choosePatient,
                  );
                }}
                suffix={<img src={IconSearch} />}
                style={{
                  width: '100%',
                  marginRight: firstChange ? '3rem' : '1rem',
                  marginBottom: secondChange ? '0.5rem' : '1rem',
                }}
              />
              <S.TitleSiders>Filtrar</S.TitleSiders>
              <Radio.Group
                value={withProfessional}
                onChange={e => {
                  setWithProfessional(e.target.value);
                  changeTextFilter(
                    patientName,
                    e.target.value === 'Por Profissional',
                    professionalName,
                    choosePatient,
                  );
                }}
                style={{ marginTop: '0.3rem' }}
              >
                <Col>
                  <Row
                    style={{
                      marginBottom: '0.5rem',
                      alignItems: 'start',
                      justifyContent: 'start',
                    }}
                  >
                    <Radio
                      value="Por Profissional"
                      style={
                        withProfessional === 'Por Profissional'
                          ? S.AntdStyles.RadioButtonActiveAble
                          : S.AntdStyles.RadioButtonActiveDisable
                      }
                    >
                      Por Profissional
                    </Radio>
                  </Row>
                  <Row
                    style={{
                      marginBottom: '0.5rem',
                      alignItems: 'start',
                      justifyContent: 'start',
                    }}
                  >
                    <Radio
                      value="Sem Profissional Atribuido"
                      style={
                        withProfessional === 'Sem Profissional Atribuido'
                          ? S.AntdStyles.RadioButtonActiveAble
                          : S.AntdStyles.RadioButtonActiveDisable
                      }
                    >
                      Sem Profissional Atribuído
                    </Radio>
                  </Row>
                </Col>
              </Radio.Group>
              <Col span={24} style={{ marginBottom: '1rem' }}>
                <Select
                  showSearch
                  style={{
                    width: '100%',
                    maxHeight: 50,
                  }}
                  options={professionalNameList}
                  filterOption={(inputValue, option) =>
                    option.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                  value={professionalName}
                  onChange={name => {
                    setProfessionalName(name);
                    changeTextFilter(
                      patientName,
                      withProfessional === 'Por Profissional',
                      name,
                      choosePatient,
                    );
                  }}
                  disabled={withProfessional !== 'Por Profissional'}
                />
              </Col>

              <S.TitleSiders>Exibir Pacientes</S.TitleSiders>
              <Radio.Group
                value={choosePatient}
                onChange={e => setChoosePatient(e.target.value)}
                style={{ marginTop: '0.3rem' }}
              >
                <Col>
                  <Row
                    style={{
                      marginBottom: '0.5rem',
                      alignItems: 'start',
                      justifyContent: 'start',
                    }}
                  >
                    <Radio
                      value={PatientListEnum.ACTIVE}
                      style={
                        choosePatient === PatientListEnum.ACTIVE
                          ? S.AntdStyles.RadioButtonActiveAble
                          : S.AntdStyles.RadioButtonActiveDisable
                      }
                    >
                      Ativos{' '}
                    </Radio>
                  </Row>
                  <Row
                    style={{
                      marginBottom: '0.5rem',
                      alignItems: 'start',
                      justifyContent: 'start',
                    }}
                  >
                    <Radio
                      value={PatientListEnum.ARCHIVED}
                      style={
                        choosePatient === PatientListEnum.ARCHIVED
                          ? S.AntdStyles.RadioButtonActiveAble
                          : S.AntdStyles.RadioButtonActiveDisable
                      }
                    >
                      Arquivados
                    </Radio>
                  </Row>
                </Col>
              </Radio.Group>
            </div>
          </S.DivInterationPatient>
        </Col>
        <Col
          style={{
            width: firstChange ? '70%' : '90%',
            marginLeft: '1rem',
            marginTop: firstChange ? '6.8rem' : '0rem',
          }}
        >
          <S.DivPatientList>
            <Button
              type="text"
              onClick={() => {
                buttonGroupArchiveOrActive(selectedPatient, choosePatient);
              }}
              style={S.AntdStyles.ButtonArchiveOrActive}
            >
              {choosePatient === PatientListEnum.ACTIVE ? (
                <>
                  <img src={archivedGrey} style={S.AntdStyles.ImgArchived} />
                  <S.LabelArchived>Arquivar</S.LabelArchived>
                </>
              ) : (
                <>
                  <img src={desarchivedGray} style={S.AntdStyles.ImgActived} />
                  <S.LabelArchived>Ativar</S.LabelArchived>
                </>
              )}
            </Button>
            {choosePatient === PatientListEnum.ACTIVE && (
              <Button
                type="text"
                onClick={() => {
                  if (selectedPatient.length > 0) {
                    setIsModalAttributeVisible(true);
                  }
                }}
                style={{
                  ...S.AntdStyles.ButtonArchiveOrActive,
                  cursor:
                    selectedPatient.length > 0 ? 'pointer' : 'not-allowed',
                }}
              >
                <>
                  <img src={AssignIcon} style={S.AntdStyles.ImgDeleted} />
                  <S.LabelArchived
                    style={{
                      cursor:
                        selectedPatient.length > 0 ? 'pointer' : 'not-allowed',
                    }}
                  >
                    Atribuir a Profissional
                  </S.LabelArchived>
                </>
              </Button>
            )}
            <Divider style={{ marginLeft: '1rem', width: 'auto' }} />

            {(loading === true || patientList === null) && (
              <S.DivCenter>
                <S.LabelPatientList>
                  Carregando pacientes ...
                </S.LabelPatientList>
              </S.DivCenter>
            )}

            {loading === false &&
              patientList !== null &&
              patientList.length !== 0 && (
                <Table
                  size="small"
                  rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                  }}
                  columns={columns}
                  dataSource={dataTableOrder}
                  pagination={{
                    defaultPageSize: 500,
                  }}
                />
              )}

            {loading === false &&
              patientList !== null &&
              patientList.length === 0 && (
                <S.DivCenter>
                  <S.LabelPatientList>
                    {`Você não possui pacientes, clique no botão "Adicionar Paciente"
              para adicionar um novo.`}
                  </S.LabelPatientList>
                </S.DivCenter>
              )}
          </S.DivPatientList>
        </Col>
      </S.Container>
    </Layout>
  );
}

export default PatientListAdmin;
