/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

export const Container = styled.div`
  padding: ${props =>
    props.mediaFirst
      ? `6rem 7rem`
      : props.mediaThird
      ? `6rem 3rem`
      : `6rem 0.5rem`};
  flex-direction: ${props => (props.mediaFirst ? 'row' : 'column')};
  display: flex;
`;

export const TitlePage = styled.h2`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal normal 20px/27px Nunito Sans;
  letter-spacing: 0px;
  margin-left: 0.1rem;
  opacity: 1;
`;

export const TitleSiders = styled.h3`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal bold 14px/18px Nunito Sans;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 1rem;
`;

export const TextFilters = styled.h3`
  color: var(--davysGrey);
  font: normal normal normal 14px/18px Nunito Sans;
  letter-spacing: 0px;
  opacity: 1;
`;

export const DivInterationPatient = styled.div`
  margin-top: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: ${props => (props.media ? 'center' : 'flex-start')};
`;

export const AntdStyles = {
  RadioButtonActiveAble: {
    width: '7rem',
    textAlign: 'center',
    borderColor: '#3A3979',
    borderWidth: '1px',
    backgroundColor: '#F3F2FF',
    fontWeight: 'bold',
    color: '#6864A4',
    borderRadius: '4px 0px 0px 4px',
  },

  RadioButtonActiveDisable: {
    width: '7rem',
    textAlign: 'center',
    borderWidth: '1px',
    backgroundColor: '#f7f7f7',
    fontWeight: 'bold',
    color: '#868686',
    borderRadius: '4px 0px 0px 4px',
  },

  RadioButtonArchivedAble: {
    width: '7rem',
    textAlign: 'center',
    borderColor: '#3A3979',
    borderWidth: '1px',
    backgroundColor: '#F3F2FF',
    fontWeight: 'bold',
    color: '#6864A4',
    borderRadius: '0px 4px 4px 0px',
  },

  RadioButtonArchivedDisable: {
    width: '7rem',
    textAlign: 'center',
    borderWidth: '1px',
    fontWeight: 'bold',
    color: '#868686',
    borderRadius: '0px 4px 4px 0px',
  },

  ButtonAddPatient: {
    background: 'var(--darkCyan) 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    borderWidth: 0,
    width: '10rem',
    marginTop: '1rem',
    marginBottom: '2rem',
  },

  InputSearchPatient: {
    width: '20rem',
    marginRight: '3rem',
  },

  ImgArchived: { marginRight: 8, width: 15, marginBottom: 4 },
  ImgDeleted: { marginRight: 8, width: 16, marginBottom: 4 },

  ImgActived: {
    marginRight: 8,
    marginBottom: -3.5,
    width: 20,
    height: 20,
  },

  ButtonArchiveOrActive: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  AvatarUser: {
    color: 'var(--darkCyan)',
    backgroundColor: '#C9E2E1',
    fontSize: 20,
  },
};

export const LabelText = styled.div`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal bold 14px/19px Nunito Sans;
  letter-spacing: 0px;
  display: flex;
  flex-direction: column;
  margin-left: 3px;
`;

export const DivPatientList = styled.div`
  margin-top: 1rem;
  flex: 1;
  width: 100%;
`;

export const LabelPatientList = styled.label`
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: bold;
  color: var(--davysGrey);
`;

export const LabelArchived = styled.label`
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: bold;
  color: var(--davysGrey);
  cursor: pointer;
`;

export const LabelTitleTable = styled.label`
  color: var(--unnamed-color-868686);
  text-align: left;
  font: normal normal normal 14px/19px Nunito Sans;
  letter-spacing: 0px;
  color: #868686;
  opacity: 1;
`;

export const DivCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImageRegisterData = styled.img`
  align-items: flex-start;
  width: 14px;
  height: 14px;
  margin-right: 8px;
  margin-bottom: 4px;
`;

export const ImageBackFrontData = styled.img`
  align-items: center;
  width: 14px;
  height: 14px;
`;

export const ProfessionalAgenda = styled.label`
  color: var(--davysGrey);
  text-align: start;
  font: normal normal bold 14px/19px Nunito Sans;
  padding-left: 20px;
  padding-bottom: 4px;
  text-transform: capitalize;
  cursor: pointer;
`;

export const PatientAgenda = styled.label`
  color: var(--davysGrey);
  text-align: start;
  font: normal normal normal 14px/19px Nunito Sans;
  margin-left: 40px;
  text-transform: capitalize;
  cursor: pointer;
`;

export const DivColorEvent = styled.div`
  width: 7px;
  min-height: 60px;
  height: calc(100% + 3px);
  display: flex;
  margin-bottom: 1px;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  margin-left: -5px;
  cursor: pointer;
`;
export const DivColorEventTop = styled.div`
  width: 100%;
  height: 7px;
  display: flex;
  align-self: flex-start;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  cursor: pointer;
  margin-bottom: 6px;
`;

export const DivContainerEvent = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  margin-left: 4px;
  cursor: pointer;
  align-items: center;
`;
export const DivContainerEventWeek = styled.div`
  display: flex;
  min-height: 3.7rem;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  background-color: white;
`;

export const DivColumnContainerEvent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
`;

export const PresentialSquare = styled.div`
  width: 14px;
  height: 14px;
  margin-top: 0.15rem;
  border-radius: 4px;
  background-color: #ffa8e4;
  margin-right: 0.5rem;
  margin-left: 1.5rem;
`;
export const InliveSquare = styled.div`
  width: 14px;
  height: 14px;
  margin-top: 0.15rem;
  border-radius: 4px;
  background-color: #ffc465;
  margin-right: 0.5rem;
  margin-left: 1.5rem;
`;
export const PendingCircle = styled.div`
  height: 16px;
  width: 16px;
  background-color: #fff;
  border-radius: 50px;
  margin-top: 2px;
  margin-left: 2px;
`;
