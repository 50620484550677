/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect, useContext } from 'react';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import { Divider, Row, Col, Progress, Button, Empty } from 'antd';
import pt from 'date-fns/locale/pt';
import { addDays, format } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import AlertImg from 'assets/gray-alert.svg';
import { getVideocallsUsed } from 'store/modules/video_call/actions';
import { PropsProfileEnum } from 'utils/profile';
import { ModalVideoCallsPerformedInfo } from './components/ModalVideoCallsPerformedInfo';
import { ModalChangeCredCard } from './components/ModalChangeCredCard';
import { Plan } from '../Plan';
import { Package } from '../Package';

import PlanContext from '../../Context/PlanContext';

import * as S from './styles';
import './styles.css';
import { ModalContactUs } from '../ModalContactUs';
import { ModalCreateClinic } from '../ModalCreateClinic';

export function PlanManagement({ scrollRef }) {
  const dispatch = useDispatch();
  const { subscript_status } = useContext(PlanContext);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [othersPlansToChoose, setOthersPlansToChoose] = useState([]);
  const [packages, setPackages] = useState([]);
  const [billDay, setBillDay] = useState(null);
  const [isTestPeriod, setIsTestPeriod] = useState(true);
  const [credCardFlag, setCredCardFlag] = useState('');
  const [credCardNumber, setCredCardNumber] = useState('');
  const isClinic = useSelector(
    state =>
      state.auth?.user?.active_profile_name !== PropsProfileEnum.THERAPIST,
  );
  const profile = useSelector(state =>
    state.auth.user.profiles.find(
      item => item.id === state.auth.user.active_profile_id,
    ),
  );

  const [isModalVideoCallsVisible, setIsModalVideoCallsVisible] = useState(
    false,
  );

  const [isModalCredCardsVisible, setIsModalCredCardsVisible] = useState(false);
  const [isModalContactUsVisible, setIsModalContactUsVisible] = useState(false);
  const [isModalCreateClinic, setIsModalCreateClinic] = useState(false);

  const isBiggerThan = useMediaQuery('(min-width: 770px)');
  const isBiggerThanPackages = useMediaQuery('(min-width: 1250px)');
  const isBiggerThanTitle = useMediaQuery('(min-width: 880px)');
  const isBiggerThanCalls = useMediaQuery('(min-width: 660px)');

  useEffect(() => {
    if (isClinic) {
      dispatch(getVideocallsUsed());
    }
  }, [isClinic]);

  useEffect(() => {
    if (subscript_status !== null) {
      const PlansData = subscript_status.subscriptionStatus.plans;
      const PackagesDate = subscript_status.subscriptionStatus.packages;
      const otherPlans = [];
      const packagesArray = [];

      for (let x = 0; x < PlansData.length; x += 1) {
        if (PlansData[x].isCurrentPlan) {
          setCurrentPlan(
            <Plan
              planData={PlansData[x]}
              isBestPlan={PlansData[x].planId === 'PROFESSIONAL'}
              isCurrentPlan
              isTestPeriod
              isShowButtonContract
              showModalCreateClinic={() => setIsModalCreateClinic(true)}
            />,
          );
        } else {
          otherPlans.push(
            <Plan
              planData={PlansData[x]}
              isBestPlan={PlansData[x].planId === 'PROFESSIONAL'}
              isCurrentPlan={false}
              isTestPeriod
              isShowButtonContract
              showModalCreateClinic={() => setIsModalCreateClinic(true)}
            />,
          );
        }
      }

      if (profile.clinicId !== null) {
        otherPlans.push(
          <Plan
            planFake
            planData={{
              planName: 'Contrate mais profissionais',
              planDescription:
                'Para adquirir mais profissionais para sua clínica? Entre em contato conosco.',
              planDescTitle: 'Precisa de mais profissionais?',
              buttonName: 'Entrar em contato',
              buttonFunc: () => setIsModalContactUsVisible(true),
            }}
            isBestPlan={false}
            isCurrentPlan={false}
            isTestPeriod={false}
            isShowButtonContract={false}
          />,
        );
      }

      for (let x = 0; x < PackagesDate.length; x += 1) {
        packagesArray.push(
          <Package packageData={PackagesDate[x]} isShowButtonContract />,
        );
      }

      setBillDay(subscript_status.subscriptionStatus.dueDate);
      setIsTestPeriod(subscript_status.subscriptionStatus.isFree);
      const info_c1 =
        subscript_status.subscriptionStatus.payment?.paymentInfos?.cardFlag;
      setCredCardFlag(info_c1 !== undefined ? info_c1 : 'A definir');
      const info_c2 =
        subscript_status.subscriptionStatus.payment?.paymentInfos?.cardNumber;
      setCredCardNumber(info_c1 !== undefined ? info_c2 : '');
      setOthersPlansToChoose(otherPlans);
      setPackages(packagesArray);
    }
  }, [subscript_status]);

  function handleVideoCallsOk() {
    setIsModalVideoCallsVisible(false);
  }

  function handleVideoCallsCancel() {
    setIsModalVideoCallsVisible(false);
  }

  function handleCredCardsOk() {
    setIsModalCredCardsVisible(false);
  }

  function handleCredCardsCancel() {
    setIsModalCredCardsVisible(false);
  }

  return (
    <S.Container>
      <S.TitlePage style={{ marginTop: isBiggerThanTitle ? '0%' : '10%' }}>
        Gerenciar Plano
      </S.TitlePage>
      <S.ContainerCurrentPlanSection>
        <S.CurrentPlan>
          <label>Plano Atual</label>
          {currentPlan !== null && currentPlan}
          {currentPlan === null && (
            <S.ContainerNoPlan>
              <Empty description={<strong>Nenhum plano assinado</strong>} />
            </S.ContainerNoPlan>
          )}
        </S.CurrentPlan>
        <S.CurrentPlanInfo>
          <label className="subtitle">Dados do Seu Plano</label>
          {isTestPeriod === true ? (
            <label className="regular-text">{`Seu período de teste expira em ${format(
              addDays(new Date(billDay), 1),
              'dd-MM-yyyy',
              {
                locale: pt,
              },
            )}.`}</label>
          ) : (
            <>
              <label className="regular-text">
                {subscript_status.subscriptionStatus.statusCurrentPlan !==
                'canceled'
                  ? `A sua próxima cobrança será em ${format(
                      addDays(new Date(billDay), 1),
                      'dd-MM-yyyy',
                      {
                        locale: pt,
                      },
                    )}.`
                  : `Plano vigente até ${format(
                      addDays(new Date(billDay), 1),
                      'dd-MM-yyyy',
                      {
                        locale: pt,
                      },
                    )}.`}
              </label>
              <S.PaymentOptions>
                <strong>Forma de Pagamento: </strong>
                <label className="regular-text-credcard">{`${credCardFlag} ${credCardNumber}`}</label>
                <Button
                  key="primary"
                  type="default"
                  style={S.AntdStyles.ButtonEdit}
                  onClick={() => setIsModalCredCardsVisible(true)}
                >
                  <strong>(Editar)</strong>
                </Button>
              </S.PaymentOptions>
            </>
          )}

          <br />
          <S.PlanConfigurations>
            {isClinic && (
              <S.PlanConfigurationSection>
                <S.PlanConfigurations>
                  <Progress
                    type="circle"
                    percent={
                      subscript_status.subscriptionStatus.professionals.hired
                    }
                    format={percent =>
                      `${percent}/${subscript_status.subscriptionStatus.professionals.total}`
                    }
                    width={80}
                    strokeWidth={10}
                    strokeColor="#B23535"
                    trailColor="#D5D5D5"
                  />
                  <S.PlanConfigurationSubSection>
                    <label>Profissionais Cadastrados</label>
                    <Button
                      key="primary"
                      type="default"
                      style={S.AntdStyles.ButtonGetMore}
                      onClick={() => {
                        scrollRef.current.scrollTop =
                          scrollRef.current.scrollHeight -
                          scrollRef.current.offsetHeight;
                      }}
                    >
                      <strong>Contratar Mais</strong>
                    </Button>
                  </S.PlanConfigurationSubSection>
                </S.PlanConfigurations>
                {subscript_status.subscriptionStatus.professionals.total -
                  subscript_status.subscriptionStatus.professionals.hired <=
                  10 && (
                  <S.RegularAlertMessage>
                    <img src={AlertImg} alt="alerta" />
                    <label className="alert-message">
                      Você está quase atingindo o limete de profissionais
                      cadastrados.
                    </label>
                  </S.RegularAlertMessage>
                )}
              </S.PlanConfigurationSection>
            )}
            <S.PlanConfigurationSection
              style={{ width: isBiggerThanCalls ? '50%' : '100%' }}
            >
              <S.PlanConfigurations>
                <Progress
                  type="circle"
                  percent={
                    subscript_status.subscriptionStatus.teleconsultations
                      .consumed
                  }
                  format={percent =>
                    `${percent}/${subscript_status.subscriptionStatus.teleconsultations.maxContracted}`
                  }
                  width={80}
                  strokeWidth={10}
                  strokeColor="#118E88"
                  trailColor="#D5D5D5"
                />
                <S.PlanConfigurationSubSection>
                  <strong>Telechamadas Utilizadas</strong>
                  <label>{`Renova em ${format(
                    addDays(new Date(billDay), 1),
                    'dd-MM-yyyy',
                    {
                      locale: pt,
                    },
                  )}.`}</label>
                  {isClinic && (
                    <Button
                      key="primary"
                      type="default"
                      style={{
                        ...S.AntdStyles.ButtonSeeData,
                        padding: 0,
                        width: '7rem',
                      }}
                      onClick={() => setIsModalVideoCallsVisible(true)}
                    >
                      <strong>Ver Dados de Uso</strong>
                    </Button>
                  )}
                </S.PlanConfigurationSubSection>
              </S.PlanConfigurations>
            </S.PlanConfigurationSection>
          </S.PlanConfigurations>
        </S.CurrentPlanInfo>
      </S.ContainerCurrentPlanSection>
      <br />
      <Divider style={{ marginTop: isBiggerThan ? '3%' : '60%' }} />
      <S.DivSession style={{ width: '60px', marginTop: '3%' }}>
        <S.TitleSession>Planos</S.TitleSession>
      </S.DivSession>
      <Row gutter={[16, 16]}>
        {othersPlansToChoose.map(plan => (
          <Col
            className="gutter-row"
            span={isBiggerThan ? 8 : 16}
            style={{ marginBottom: '1rem' }}
          >
            {plan}
          </Col>
        ))}
      </Row>
      <label style={{ opacity: 0.6 }}>
        *Após iniciar uma chamada em vídeo, ela deve durar no máximo 1h30.
        Dentro desse prazo, caso haja desconexão, os usuários têm até 20 minutos
        para retomar a chamada sem que seja contabilizada uma nova chamada.
      </label>
      <Divider style={{ marginTop: isBiggerThan ? '3%' : '5%' }} />
      <S.DivSession style={{ width: '67px' }}>
        <S.TitleSession>Pacotes</S.TitleSession>
      </S.DivSession>
      <Row gutter={[16, 16]}>
        {packages.map(packageItem => (
          <Col
            className="gutter-row"
            span={isBiggerThanPackages ? 8 : 16}
            style={{ marginBottom: '1rem' }}
          >
            {packageItem}
          </Col>
        ))}
      </Row>

      <ModalVideoCallsPerformedInfo
        isModalVisible={isModalVideoCallsVisible}
        handleOk={handleVideoCallsOk}
        handleCancel={handleVideoCallsCancel}
      />
      <ModalChangeCredCard
        isModalVisible={isModalCredCardsVisible}
        handleOk={handleCredCardsOk}
        handleCancel={handleCredCardsCancel}
      />
      <ModalContactUs
        isModalVisible={isModalContactUsVisible}
        handleOk={() => setIsModalContactUsVisible(false)}
        handleCancel={() => setIsModalContactUsVisible(false)}
      />
      <ModalCreateClinic
        isModalVisible={isModalCreateClinic}
        handleOk={() => setIsModalCreateClinic(false)}
        handleCancel={() => setIsModalCreateClinic(false)}
      />
    </S.Container>
  );
}
