export const PropsDayEnum = {
  DOMINGO: 'domingo',
  SEGUNDA: 'segunda',
  TERCA: 'terça',
  QUARTA: 'quarta',
  QUINTA: 'quinta',
  SEXTA: 'sexta',
  SABADO: 'sábado',
};

export const PropsDayArray = [
  'DOMINGO',
  'SEGUNDA',
  'TERCA',
  'QUARTA',
  'QUINTA',
  'SEXTA',
  'SABADO',
];

export const PropsDayArrayNumber = [0, 1, 2, 3, 4, 5, 6];

export const PropsDayTranslated = [
  {
    type: PropsDayEnum.DOMINGO,
    pt: 'Domingo',
  },
  {
    type: PropsDayEnum.SEGUNDA,
    pt: 'Segunda-feira',
  },
  {
    type: PropsDayEnum.TERCA,
    pt: 'Terça-feira',
  },
  {
    type: PropsDayEnum.QUARTA,
    pt: 'Quarta-feira',
  },
  {
    type: PropsDayEnum.QUINTA,
    pt: 'Quinta-feira',
  },
  {
    type: PropsDayEnum.SEXTA,
    pt: 'Sexta-feira',
  },
  {
    type: PropsDayEnum.SABADO,
    pt: 'Sábado',
  },
];

export const DaysTranslated = speciality => {
  const result = PropsDayTranslated.find(p => p.type === speciality).pt;
  return result;
};
