/* eslint-disable no-unused-vars */
import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  subsc_status: null,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@payment/SET_LOADING': {
        draft.loading = action.payload.value;
        break;
      }

      case '@payment/SET_SUBSCRIPTION_STATUS': {
        draft.subsc_status = action.payload.status;
        break;
      }

      case '@payment/SET_LOADING_PAYMENT': {
        draft.loading = action.payload.value;
        break;
      }

      default:
    }
  });
}
