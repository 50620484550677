/* eslint-disable no-unused-vars */
import produce from 'immer';

const INITIAL_STATE = {
  content: null,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@professional_content/SET_CONTENT_VIDEOS': {
        draft.content = action.payload.videos;
        break;
      }

      default:
    }
  });
}
