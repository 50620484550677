import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 40vw;
  height: 100vh;
  padding-top: 6%;
  background: transparent;
`;

export const ContainerDropDown = styled.div`
  margin-left: 1.8rem;
`;

export const ImgDropdown = styled.img`
  width: 8px;
  height: 4px;
`;

export const AntdStyles = {
  menuExams: { maxHeight: 200, overflowY: 'scroll' },

  ButtonExams: {
    marginTop: '4px',
    textAlign: 'left',
    fontFamily: 'Nunito Sans',
    display: 'flex',
    width: '200px',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '4px',
  },
};
