/* eslint-disable no-unused-vars */
/* eslint-disable no-lonely-if */
import React, { useContext, useEffect, useState } from 'react';
import { Menu, Dropdown, Button, Radio } from 'antd';
import { ComputerVisionArray } from 'utils/computerVision';
import ArrowDownGrey from 'assets/arrow-down-grey.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getComputerVisionEvaluation } from 'store/modules/evaluations/actions';
import FormContext from '../Context/FormContext';

import * as S from './styles';
import { Comparative } from './components/Comparative';
import { Evolution } from './components/Evolution';

export function PerformedComputerVision() {
  const [evalSelected, setEvalSelected] = useState(ComputerVisionArray[0]);
  /*
   {
    value: 'Selecione uma Atividade',
    label: 'SELECT',
  }
   */
  const dispatch = useDispatch();
  const patientId = useSelector(state => state.patient?.patientDetails?.id);
  const [selectedRadioValue, setSelectedRadioValue] = useState('Comparativo');
  const [choiceTime, setChoiceTime] = useState('1 Mês');

  useEffect(() => {
    if (evalSelected.label !== 'SELECT') {
      dispatch(getComputerVisionEvaluation(patientId, evalSelected.label));
    }
  }, [evalSelected]);

  return (
    <>
      <S.Subtitles>Atividade</S.Subtitles>
      <Dropdown
        overlay={
          <Menu activeKey="1" style={S.AntdStyles.menuExams}>
            {ComputerVisionArray.map(item => (
              <Menu.Item>
                <element onClick={() => setEvalSelected(item)}>
                  {item.value}
                </element>
              </Menu.Item>
            ))}
          </Menu>
        }
        placement="bottomCenter"
      >
        <Button style={S.AntdStyles.ButtonExams}>
          {evalSelected.value}{' '}
          <S.ImgDropdown src={ArrowDownGrey} alt="mostrar exames" />
        </Button>
      </Dropdown>
      {evalSelected.label !== 'SELECT' ? (
        <>
          <div style={{ marginTop: '2rem' }}>
            <S.TitleSession>Valores de Referência</S.TitleSession>
          </div>
          <S.PDescript>Para cadência: 1.83 passos / segundos.</S.PDescript>
          <S.PDescript>Para velocidade: 1.33 metros / segundos.</S.PDescript>

          <S.Subtitles>Exibir</S.Subtitles>
          <Radio.Group
            value={selectedRadioValue}
            onChange={e => setSelectedRadioValue(e.target.value)}
            style={{ width: '70%', marginBottom: '1rem' }}
          >
            <Radio key="Comparativo" value="Comparativo">
              Comparativo
            </Radio>
            <Radio key="Evolução" value="Evolução">
              Evolução
            </Radio>
          </Radio.Group>
          {selectedRadioValue === 'Comparativo' ? <Comparative /> : null}
          {selectedRadioValue === 'Evolução' ? <Evolution /> : null}
          <br />
          <br />
          <br />
        </>
      ) : null}
    </>
  );
}
