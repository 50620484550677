/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  Row,
  Col,
  Input,
  DatePicker,
  Button,
  AutoComplete,
  TimePicker,
  Radio,
} from 'antd';

import { getPatientsList } from 'store/modules/patient/actions';

import { Formik } from 'formik';
import * as Yup from 'yup';

import moment from 'moment';
import 'moment/locale/pt-br';
import localePTDate from 'antd/lib/locale/pt_BR';

import closeIcon from 'assets/close.svg';

import { useRef, useState, useEffect } from 'react';

import { format, getTime } from 'date-fns';
import { addEventClinic } from 'store/modules/clinicagenda/actions';
import * as S from './styles';
import './styles.css';

const { TextArea } = Input;

export function AddEventModal({ isModalVisible, handleCancel, handleOk }) {
  const firstChange = useMediaQuery('(min-width: 770px)');
  const dispatch = useDispatch();
  const formikRef = useRef();
  const professionalList = useSelector(state => state.clinic.therapists);
  const patientList = useSelector(state => state.patient.patientList);
  const [changedDate, setChangedDate] = useState(false);

  useEffect(() => {
    dispatch(getPatientsList());
  }, [dispatch]);

  const formikGeneralData = {
    initialValues: {
      professional: null,
      professionalId: '',
      patient: null,
      patientId: '',
      date: new Date(),
      initHour: '',
      endHour: '',
      typeConsult: 'PRESENTIAL',
      details: '',
    },
    validationSchema: Yup.object({
      professional: Yup.string()
        .required('É obrigatório')
        .nullable('É obrigatório'),
      patient: Yup.string().required('É obrigatório').nullable('É obrigatório'),
      date: Yup.string().required('É obrigatório'),
      initHour: Yup.string().required('É obrigatório'),
      endHour: Yup.string().required('É obrigatório'),
      typeConsult: Yup.string().required('É obrigatório'),
      details: Yup.string().required('É obrigatório'),
    }),
  };

  return (
    <Formik
      initialValues={formikGeneralData.initialValues}
      validationSchema={formikGeneralData.validationSchema}
      innerRef={formikRef}
      onSubmit={(values, actions) => {
        let year = '';
        let month = '';
        let day = '';

        if (!changedDate) {
          values.date = format(values.date, 'P');
          year = values.date.split('/')[2];
          day = values.date.split('/')[1];
          month = values.date.split('/')[0];
        } else {
          year = values.date.split('/')[0];
          month = values.date.split('/')[1];
          day = values.date.split('/')[2];
        }

        const hour = values.initHour.split(':')[0];
        const minutes = values.initHour.split(':')[1];
        const hourEnd = values.endHour.split(':')[0];
        const minutesEnd = values.endHour.split(':')[1];
        const duration = (hourEnd - hour) * 60 + (minutesEnd - minutes);
        values.duration = duration;
        // month - 1 pois esta sendo lido o mes de outubro como 11 para o timestamp
        values.date = new Date(year, month - 1, day, hour, minutes).getTime();
        dispatch(addEventClinic(values));
        handleOk();

        actions.resetForm({
          values: {
            professional: null,
            professionalId: '',
            patient: null,
            patientId: '',
            date: new Date(),
            initHour: '',
            endHour: '',
            typeConsult: 'PRESENTIAL',
            details: '',
          },
        });
      }}
    >
      {props => (
        <Modal
          title="Agendar Consulta"
          visible={isModalVisible}
          onOk={() => handleOk()}
          onCancel={() => handleCancel()}
          style={{
            borderRadius: '5px',
          }}
          centered
          bodyStyle={{ borderRadius: '20px' }}
          width={650}
          closeIcon={
            <img src={closeIcon} alt="close" style={S.AntdStyles.iconClose} />
          }
          footer={[
            <Button
              type="primary"
              onClick={() => props.handleSubmit()}
              style={{
                width: '180px',
                borderRadius: '5px',
                backgroundColor: '#118E88',
                borderColor: '#118E88',
              }}
            >
              Agendar
            </Button>,
          ]}
        >
          <S.ContainerModal>
            <Row gutter={[32, 16]}>
              <Col className="gutter-row" span={firstChange ? 12 : 24}>
                <S.LabelText className="input-label">
                  Profissional
                  <AutoComplete
                    style={S.AntdStyles.AutoComplete}
                    value={
                      props.values.professional === null
                        ? 'Não Informado'
                        : props.values.professional
                    }
                    onChange={(text, option) => {
                      props.setFieldValue('professional', option.label);
                      props.setFieldValue('professionalId', text);
                    }}
                    options={professionalList?.map(
                      item =>
                        item && {
                          label: item.professionalName,
                          value: item.id,
                        },
                    )}
                  />
                  {props.touched.professional && props.errors.professional ? (
                    <S.ErrorMessage>{props.errors.professional}</S.ErrorMessage>
                  ) : null}
                </S.LabelText>
              </Col>
              <Col className="gutter-row" span={firstChange ? 12 : 24}>
                <S.LabelText className="input-label">
                  Paciente
                  <AutoComplete
                    style={S.AntdStyles.AutoComplete}
                    value={
                      props.values.patient === null
                        ? 'Não Informado'
                        : props.values.patient
                    }
                    onChange={(text, option) => {
                      props.setFieldValue('patient', option.label);
                      props.setFieldValue('patientId', text);
                    }}
                    options={
                      patientList !== null &&
                      patientList.map(
                        item =>
                          item && {
                            label: item.name,
                            value: item.id,
                          },
                      )
                    }
                  />
                </S.LabelText>
                {props.touched.patient && props.errors.patient ? (
                  <S.ErrorMessage>{props.errors.patient}</S.ErrorMessage>
                ) : null}
              </Col>

              <Col className="gutter-row" span={firstChange ? 12 : 24}>
                <S.LabelText className="input-label">
                  Data
                  <DatePicker
                    style={S.AntdStyles.marginTop}
                    onChange={(_, dateString) => {
                      setChangedDate(true);
                      props.setFieldValue(
                        'date',
                        moment(dateString, 'DD/MM/YYYY').format('YYYY/MM/DD'),
                      );
                    }}
                    allowClear={false}
                    value={moment(props.values.date, 'YYYY/MM/DD')}
                    locale={localePTDate}
                    format="DD/MM/YYYY"
                  />
                  {props.touched.date && props.errors.date ? (
                    <S.ErrorMessage>{props.errors.date}</S.ErrorMessage>
                  ) : null}
                </S.LabelText>
              </Col>
              <Col className="gutter-row" span={firstChange ? 12 : 24}>
                <S.LabelText className="input-label">
                  Horário
                  <Row style={{ marginTop: '0.27rem' }}>
                    <TimePicker
                      style={{ width: '40%' }}
                      onChange={(_, hourString1) => {
                        props.setFieldValue(
                          'initHour',
                          moment(hourString1, 'HH:mm').format('HH:mm'),
                        );
                      }}
                      format="HH:mm"
                    />

                    <S.HoursSession>-</S.HoursSession>
                    <TimePicker
                      style={{ width: '40%' }}
                      onChange={(_, hourString2) => {
                        props.setFieldValue(
                          'endHour',
                          moment(hourString2, 'HH:mm').format('HH:mm'),
                        );
                      }}
                      format="HH:mm"
                    />
                  </Row>
                  <Row>
                    {props.touched.initHour && props.errors.initHour ? (
                      <S.ErrorMessage>{props.errors.initHour}</S.ErrorMessage>
                    ) : null}
                    <div style={{ width: firstChange ? 74 : 178 }} />
                    {props.touched.endHour &&
                    props.errors.endHour &&
                    !props.errors.initHour ? (
                      <S.ErrorMessage>{props.errors.endHour}</S.ErrorMessage>
                    ) : null}
                  </Row>
                </S.LabelText>
              </Col>
              <Col className="gutter-row" span={firstChange ? 24 : 24}>
                <S.LabelText className="input-label">
                  Tipo
                  <Row style={{ marginTop: '0.27rem' }}>
                    <Radio.Group
                      size="large"
                      value={props.values.typeConsult}
                      onChange={e =>
                        props.setFieldValue('typeConsult', e.target.value)
                      }
                    >
                      <Radio size="middle" value="PRESENTIAL">
                        <Row>
                          <div
                            style={{
                              width: 14,
                              height: 14,
                              borderRadius: '4px',
                              backgroundColor: '#FFC465',
                              marginRight: '0.5rem',
                              marginTop: '0rem',
                            }}
                          />
                          <S.TextFilters>Presencial</S.TextFilters>
                        </Row>
                      </Radio>
                      <Radio value="INLIVE">
                        <Row>
                          <div
                            style={{
                              width: 14,
                              height: 14,
                              borderRadius: '4px',
                              backgroundColor: '#FFA8E4',
                              marginRight: '0.5rem',
                              marginTop: '0rem',
                            }}
                          />
                          <S.TextFilters>Sessão Via Telechamada</S.TextFilters>
                        </Row>
                      </Radio>
                    </Radio.Group>
                  </Row>
                  {props.touched.initHour && props.errors.initHour ? (
                    <S.ErrorMessage>{props.errors.initHour}</S.ErrorMessage>
                  ) : null}
                </S.LabelText>
              </Col>

              <Col className="gutter-row" span={firstChange ? 24 : 24}>
                <S.LabelText className="input-label">
                  Detalhes
                  <TextArea
                    style={S.AntdStyles.marginTopDetail}
                    id="details"
                    name="details"
                    type="text"
                    onChange={props.handleChange}
                    value={props.values.details}
                    onBlur={props.handleBlur}
                    rows={4}
                  />
                </S.LabelText>
                {props.touched.details && props.errors.details ? (
                  <S.ErrorMessage>{props.errors.details}</S.ErrorMessage>
                ) : null}
              </Col>
            </Row>
          </S.ContainerModal>
        </Modal>
      )}
    </Formik>
  );
}
