import styled from 'styled-components';

export const ContainerChart = styled.div`
  display: flex;
  width: 500px;
  height: 120px;
  align-items: center;
`;

export const ContainerImgs = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: fit-content;
  height: 100%;
`;

export const ContainerLegendsForGraphic = styled.div`
  display: flex;
  width: 90%;
  height: 45px;
  justify-content: center;

  body {
    display: flex;
    width: 590px;
    height: 100%;
    justify-content: space-around;
    background: var(--ceilingBrightWhite);
    opacity: 0.9;
  }

  label {
    text-align: left;
    font: normal normal bold 12px/19px Nunito Sans;
    letter-spacing: 0px;
    color: var(--davysGrey);
    opacity: 1;
    margin-left: 2px;
  }
`;

export const ContainerRotatedGraphic = styled.div`
  display: flex;
  align-items: center;
  height: 350px;
  width: 100%;

  label {
    text-align: left;
    font: normal normal bold 16px/19px Nunito Sans;
    letter-spacing: 0px;
    color: var(--davysGrey);
    opacity: 1;
    margin-right: 2px;
    margin-top: 4px;
    width: 85px;
  }
`;

export const Subtitles = styled.label`
  text-align: left;
  font: normal normal bold 14px/19px Nunito Sans;
  letter-spacing: 0px;
  color: var(--davysGrey);
  opacity: 1;
  margin-top: 1rem;
`;

export const TitleSession = styled.label`
  opacity: 1;
  color: var(--deepKoamaru);
  text-align: start;
  font: normal normal bold 14px/19px Nunito Sans;
  letter-spacing: 0px;
  background: var(--lavender) 0% 0% no-repeat padding-box;
  border-left: solid var(--deepKoamaru) 2px;
  border-radius: 0 1px 1px 0;
  padding: 3px 8px 3px 5px;
  height: 25px;
  width: fit-content;
`;

export const ImgDropdown = styled.img`
  width: 8px;
  height: 4px;
`;

export const ImgGraphics = styled.img`
  width: 20px;
  height: 20px;
`;

export const ImgLegends = styled.img`
  width: 15px;
  height: 15px;
`;

export const AntdStyles = {
  marginTop: {
    marginTop: '4px',
  },

  ButtonExams: {
    marginTop: '4px',
    textAlign: 'left',
    fontFamily: 'Nunito Sans',
    display: 'flex',
    width: '400px',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '4px',
  },

  menuExams: { maxHeight: 200, overflowY: 'scroll' },

  RadioButtonSelectVisualization: {
    width: 'auto',
    textAlign: 'center',
    borderColor: '#6864A4',
    borderWidth: '1px',
    backgroundColor: '#f7f7f7',
    fontWeight: 'bold',
    color: '#6864A4',
    borderRadius: '0px 0px 0px 0px',
  },
};
