/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input, AutoComplete } from 'antd';
import { Protocols } from './components/Protocols';
import { ProtocolItem } from './components/ProtocolItem';

import * as S from './styles';
import './styles.css';

function ProtocolList({ visOpt }) {
  const patientDetails = useSelector(state => state.patient.patientDetails);
  const protocols = useSelector(state => state.protocol.protocolsCreated);
  const dispatch = useDispatch();

  const { Search } = Input;

  const [searchOptions, setSearchOptions] = useState([]);

  const [allProtocols, setAllProtocols] = useState([]);

  const [domesticProtocols, setDomesticProtocols] = useState([]);

  const [inliveProtocols, setInliveProtocols] = useState([]);

  function updateInfo(prot) {
    const tempProt = [];
    const tempAll = [];
    const tempDom = [];
    const tempInlive = [];
    for (let x = 0; x < prot.length; x += 1) {
      if (prot[x].type === 'DOMESTIC') {
        tempDom.push(
          <ProtocolItem
            id={prot[x].id}
            name={prot[x].name}
            type={prot[x].type}
            exercises={prot[x].exercises}
            active={prot[x].active}
          />,
        );
      }
      if (prot[x].type === 'INLIVE') {
        tempInlive.push(
          <ProtocolItem
            id={prot[x].id}
            name={prot[x].name}
            type={prot[x].type}
            exercises={prot[x].exercises}
            active={prot[x].active}
          />,
        );
      }
      tempAll.push(
        <ProtocolItem
          id={prot[x].id}
          name={prot[x].name}
          type={prot[x].type}
          exercises={prot[x].exercises}
          active={prot[x].active}
        />,
      );
      tempProt.push({
        value: prot[x].name,
      });
    }
    setAllProtocols(tempAll);
    setDomesticProtocols(tempDom);
    setInliveProtocols(tempInlive);
    setSearchOptions(tempProt);
  }

  /*
    BUSCA DE ACORDO COM O TIPO SELECIONADO: ALL, DOMESTIC, IN_LIVE_SESSION

  */
  const onSearch = (value, prot) => {
    const tempProt = [];

    if (value !== '') {
      if (visOpt === 'ALL') {
        for (let x = 0; x < prot.length; x += 1) {
          if (value === prot[x].name && prot[x].active === true) {
            tempProt.push(
              <ProtocolItem
                id={prot[x].id}
                name={prot[x].name}
                type={prot[x].type}
                exercises={prot[x].exercises}
              />,
            );
          }
        }
        setAllProtocols(tempProt);
      }
      if (visOpt === 'DOMESTIC') {
        for (let x = 0; x < prot.length; x += 1) {
          if (
            prot[x].name === value &&
            prot[x].type === 'DOMESTIC' &&
            prot[x].active === true
          ) {
            tempProt.push(
              <ProtocolItem
                id={prot[x].id}
                name={prot[x].name}
                type={prot[x].type}
                exercises={prot[x].exercises}
              />,
            );
          }
        }
        setDomesticProtocols(tempProt);
      }
      if (visOpt === 'INLIVE') {
        for (let x = 0; x < prot.length; x += 1) {
          if (
            prot[x].name === value &&
            prot[x].type === 'INLIVE' &&
            prot[x].active === true
          ) {
            tempProt.push(
              <ProtocolItem
                id={prot[x].id}
                name={prot[x].name}
                type={prot[x].type}
                exercises={prot[x].exercises}
              />,
            );
          }
        }
        setInliveProtocols(tempProt);
      }
    } else {
      updateInfo(prot);
    }
  };

  useEffect(() => {
    if (protocols !== null) {
      updateInfo(protocols);
    }
  }, [protocols]);

  useEffect(() => {}, [visOpt]);

  return (
    <div style={{ width: '100%' }}>
      <div className="container-click-elements-protocol">
        <AutoComplete
          dropdownMatchSelectWidth={252}
          style={{
            width: 252,
          }}
          allowClear
          options={searchOptions}
          filterOption={(inputValue, option) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
        >
          <Search
            placeholder="Buscar"
            onSearch={value => onSearch(value, protocols)}
            style={{ width: 220 }}
          />
        </AutoComplete>
      </div>

      {visOpt === 'ALL' && (
        <S.ContainerProtocolsList>
          <Protocols protocols={allProtocols} />
        </S.ContainerProtocolsList>
      )}

      {visOpt === 'DOMESTIC' && (
        <S.ContainerProtocolsList>
          <Protocols protocols={domesticProtocols} />
        </S.ContainerProtocolsList>
      )}

      {visOpt === 'INLIVE' && (
        <S.ContainerProtocolsList>
          <Protocols protocols={inliveProtocols} />
        </S.ContainerProtocolsList>
      )}
    </div>
  );
}

export default ProtocolList;
