/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-names */
/* eslint-disable prefer-const */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getSilentPatientDetails } from 'store/modules/patient/actions';
import { getSilentSelf } from 'store/modules/professional/actions';
import {
  sendCurrentVideoToPatient,
  getVideoCallRelatedToPatient,
} from 'store/modules/video_call/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from 'antd';
import { HeaderTelemonitoring } from './components/Header';
import VideoCallContext from './Context';

import { Video } from './components/Video';
import { Subscreens } from './components/Subscreens';

let interval = null;
let intervalProfessional = null;

export default function VideoCall() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const patientDetails = useSelector(state => state.patient.patientDetails);
  const professionalDetails = useSelector(state => state.professional.self);

  const dropdownOption = [
    'Prática do Dia',
    'Anamnese',
    'Objetivos',
    'Avaliações',
  ];
  const [selectedOption, setSelectedOption] = useState('Selecione');
  const [isPreviewSession, setIsPreviewSession] = useState(false);
  const [canUseDropDown, setCanUseDropDown] = useState(false);

  const videoCallStatus = useSelector(
    state => state.video_call.video_call_status,
  );

  useEffect(() => {
    if (canUseDropDown === false) {
      if (videoCallStatus === 'ACCEPTED') {
        setCanUseDropDown(true);
      }
    }
  }, [videoCallStatus]);

  function handleDropdownSelection(item) {
    setSelectedOption(item);
  }

  function handleSessionStart(option) {
    if (option === 'preview') {
      setIsPreviewSession(false);
    } else if (option === 'start') {
      setIsPreviewSession(true);
    } else {
      setSelectedOption('Selecione');
    }
  }

  useEffect(() => {
    if (patientDetails !== null) {
      clearInterval(interval);
    }
  }, [patientDetails]);

  useEffect(() => {
    if (professionalDetails !== null) {
      clearInterval(intervalProfessional);
    }
  }, [professionalDetails]);

  function getPatientData() {
    dispatch(getSilentPatientDetails(id));
  }

  function getProfessionalData() {
    dispatch(getSilentSelf());
  }

  function initLiveSession(pat_id, sess_id, video) {
    dispatch(sendCurrentVideoToPatient(pat_id, sess_id, video));
  }

  useEffect(() => {
    dispatch(getVideoCallRelatedToPatient(id));
    interval = setInterval(() => {
      getPatientData();
    }, 1000);
  }, []);

  useEffect(() => {
    intervalProfessional = setInterval(() => {
      getProfessionalData();
    }, 1000);
  }, []);

  return (
    <Layout>
      <HeaderTelemonitoring />
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        {professionalDetails !== null && patientDetails !== null && (
          <VideoCallContext.Provider
            value={{ patientDetails, professionalDetails, initLiveSession }}
          >
            <Video selectedOption={selectedOption} />
            <Subscreens
              dropdownOption={dropdownOption}
              selectedOption={selectedOption}
              isPreviewSession={isPreviewSession}
              handleDropdownSelection={handleDropdownSelection}
              handleSessionStart={handleSessionStart}
              canUseDropDown={canUseDropDown}
            />
          </VideoCallContext.Provider>
        )}
        {(professionalDetails === null || patientDetails === null) && (
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '80%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            Carregando ...{' '}
          </div>
        )}
      </div>
    </Layout>
  );
}
