/* eslint-disable prefer-destructuring */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Progress } from 'antd';

import 'moment/locale/pt-br';

import { useRef, useState } from 'react';
import * as S from './styles';

export function CircleGraph({
  percentValue,
  circleValue,
  title,
  subtitleInit,
  subtitleEnd,
  color,
}) {
  const secondChange = useMediaQuery('(min-width: 830px)');

  return (
    <Col
      span={secondChange ? null : 24}
      style={{ ...S.AntdStyles.marginTop, marginTop: '1rem' }}
    >
      <Row>
        <Progress
          type="circle"
          percent={percentValue * 100}
          format={() => `${circleValue}`}
          width={74}
          strokeWidth={8}
          strokeColor={color}
          trailColor="#D5D5D5"
        />
        <S.ContainerTextsProgress>
          <S.TitleProgress>{title}</S.TitleProgress>
          <S.SubtitleProgress>
            {subtitleInit} {percentValue * 100}% {subtitleEnd}
          </S.SubtitleProgress>
        </S.ContainerTextsProgress>
      </Row>
    </Col>
  );
}
