import styled from 'styled-components';

export const TextLogin = styled.text`
  color: var(--battleshipGrey);
  text-align: left;
  font: normal normal normal 18px/24px 'Nunito Sans';
  letter-spacing: 0px;
  color: #868686;
  opacity: 1;
`;

export const TextLoginDesc = styled.text`
  color: var(--battleshipGrey);
  text-align: left;
  font: normal normal normal 14px/20px 'Nunito Sans';
  letter-spacing: 0px;
  color: #868686;
  opacity: 1;
  margin: 1rem 0;
`;

export const AntdStyles = {
  inputForm: {
    marginTop: '0.3rem',
    textAlign: 'left',
    font: 'normal normal normal 14px/19px Nunito Sans',
    letterSpacing: '0px',
    color: 'var(--jet)',
    display: 'flex',
  },

  inputTextArea: {
    textAlign: 'left',
    font: 'normal normal normal 14px/19px Nunito Sans',
    letterSpacing: '0px',
    color: 'var(--jet)',
    display: 'flex',
    minHeight: '5rem',
    marginTop: '0.3rem',
  },

  buttonLogin: {
    background: 'var(--darkCyan) 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    marginTop: '1.2rem',
    width: '8rem',
  },

  inputMask: {
    textAlign: 'left',
    font: 'normal normal normal 14px/19px Nunito Sans',
    letterSpacing: '0px',
    color: 'var(--jet)',
    display: 'flex',
    height: '29px',
    padding: '4px 11px',
    marginTop: '0.3rem',
    borderWidth: 1,
    border: '1px solid #d9d9d9',
  },
};

export const ErrorMessage = styled.label`
  color: #c12;
  font-size: 12px;
  margin-top: 2px;
`;

export const DescInput = styled.text`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal bold 14px/19px 'Nunito Sans';
  letter-spacing: 0px;
  color: #575757;
  opacity: 1;
  margin-top: 1rem;
`;
