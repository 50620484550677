/* eslint-disable no-unused-vars */
import { takeLatest, all, call, put } from 'redux-saga/effects';
import { notification } from 'antd';

import { format } from 'date-fns';
import pt from 'date-fns/locale/pt';

import api from '../../../services/api';

import { setProfessionalAgenda, getProfessionalAgenda } from './actions';

export function* getProfAgenda({ payload }) {
  const { month } = payload;
  const { year } = payload;
  const timezone = new Date().getTimezoneOffset();

  try {
    const response = yield call(
      api.get,
      `/get_schedule_to_professional/month/${month}/year/${year}/timezone/${timezone}`,
    );

    yield put(setProfessionalAgenda(response.data.events));
  } catch (err) {
    notification.error({
      message: 'Houve um erro ao buscar dados da agenda, tente novamente!',
    });
  }
}

export function* deleteEventProfAgenda({ payload }) {
  try {
    const { patientId, eventId, batch, date } = payload;

    const monthCurrent = format(date, 'MM', { locale: pt });
    const yearCurrent = format(date, 'yyyy');

    yield call(
      api.delete,
      `/patient/${patientId}/event/${eventId}/batch/${batch}`,
    );

    yield put(getProfessionalAgenda(monthCurrent, yearCurrent));

    notification.success({
      message: 'Evento(s) excluído(s) com sucesso!',
    });
  } catch (err) {
    notification.error({
      message:
        'Houve um erro ao tentar deletar este evento da agenda, tente novamente. Se o erro persistir contate o suporte!',
    });
  }
}

export default all([
  takeLatest('@prof_agenda/GET_PROFESSIONAL_AGENDA', getProfAgenda),
  takeLatest(
    '@prof_agenda/DELETE_EVENT_PROFESSIONAL_AGENDA',
    deleteEventProfAgenda,
  ),
]);
