/* eslint-disable no-empty */
import { takeLatest, call, all, put } from 'redux-saga/effects';
import { notification } from 'antd';

import api from '../../../services/api';
// import app from '../../../services/fire';

import {
  setVideoCall,
  setStatus,
  setCurrentVideo,
  setInliveSessionData,
  saveNoteTime,
  setInfoDayEvent,
  setVideocallsUsed,
} from './actions';

export function* setStatusOnVideoCall({ payload }) {
  try {
    const { id } = payload;
    const { type } = payload;

    yield call(api.post, '/set_status_on_videocall', {
      receiverId: id,
      statusOnVideoCall: type,
    });
    yield put(setStatus(type));
  } catch (err) {
    const { id } = payload;

    if (
      err.title !== 'InvalidStatusOnVideoCallException' &&
      err.message !== 'O status BUSY só pode ser alterado para NOTHING'
    ) {
      yield call(api.post, '/set_status_on_videocall', {
        receiverId: id,
        statusOnVideoCall: 'NOTHING',
      });
      yield put(setStatus('NOTHING'));
      yield put(setVideoCall(null));
    }
  }
}

export function* getVideoCallRelatedToPat({ payload }) {
  const { patientId } = payload;
  try {
    const timezone = new Date().getTimezoneOffset();
    const response = yield call(
      api.get,
      `/patient/${patientId}/videocall/timezone/${timezone}`,
    );
    yield put(setInfoDayEvent(response.data));
  } catch (err) {}
}

export function* getVideoCall() {
  try {
    const response = yield call(api.get, `/set_videocall`);
    yield put(setVideoCall(response.data));
  } catch (err) {
    notification.error({
      message:
        'Houve um erro ao buscar o catalogo de exercícios, tente novamente!',
    });
  }
}

export function* setToNothing({ payload }) {
  try {
    const { id } = payload;
    yield call(api.post, '/set_status_on_videocall', {
      receiverId: id,
      statusOnVideoCall: 'NOTHING',
    });
    yield put(setStatus('NOTHING'));
    yield put(setVideoCall(null));
  } catch (err) {
    // REFORMULAR MENSAGEM E TRATAR
    notification.error({
      message:
        'Não foi possível finalizar a chamada, atualize a página e tente novamente!',
    });
  }
}

export function* sendVideoToPatient({ payload }) {
  try {
    const { patientId } = payload;
    const { sessionId } = payload;
    const { currentVideo } = payload;

    yield call(
      api.post,
      `/patients/${patientId}/inlive_session/${sessionId}/show_video`,
      {
        video_url: currentVideo,
      },
    );
    yield put(setCurrentVideo(currentVideo));
  } catch (err) {
    notification.error({
      message:
        'Não foi possível enviar video para o paciente, tente novamente!',
    });
  }
}

export function* getInLiveSession({ payload }) {
  try {
    const { patientId } = payload;
    const { event_info } = payload;
    const response = yield call(
      api.post,
      `/patients/${patientId}/inlive_session`,
      {
        eventId: event_info.eventId,
      },
    );
    yield put(setInliveSessionData(response.data));
  } catch (err) {
    // ATÉ O MOMENTO NÃO VI NECESSIDADE
  }
}

export function* endVideoCall({ payload }) {
  function handleMessages(reason) {
    switch (reason) {
      case 'professional-end-video-call':
      case 'video-call-time-expired':
        notification.info({
          message:
            'A video chamada foi finalizada. Se deseja iniciar uma nova, clique em Chamar Paciente.',
        });
        break;
      case 'patient-ended-video-call':
        notification.info({
          message:
            'O paciente finalizou a video chamada. Se deseja iniciar uma nova, clique em Chamar Paciente.',
        });
        break;
      case 'patient-did-not-back':
        notification.info({
          message:
            'Não foi possível restabelecer a conexão da chamada com o paciente. Se deseja iniciar uma nova, clique em Chamar Paciente.',
        });
        break;
      default:
        notification.info({
          message:
            'A video chamada foi finalizada. Se deseja iniciar uma nova, clique em Chamar Paciente.',
        });
        break;
    }
  }
  try {
    const { channel } = payload;
    const { reason } = payload;
    yield call(api.get, `/end_videocall/${channel}`);
    handleMessages(reason);
  } catch (err) {
    const { reason } = payload;
    handleMessages(reason);
  }
}

export function* saveLiveSessionData({ payload }) {
  // BOTAR EVENTID NO BODY
  // ENDPOINT SÓ É ENVIADO QUANDO CLICAR NO DROPDOWN PRATICA DO DIA
  // CAMPO DE ANOTAÇÃO APENAS PARA SESSÃO AO VIVO
  try {
    const { pat_id } = payload;
    const { sess_id } = payload;
    const { evals } = payload;
    const { note } = payload;
    const { eventId } = payload;
    yield call(api.post, `/patients/${pat_id}/inlive_session`, {
      sessionId: sess_id,
      evaluations: evals,
      notes: note,
      eventId,
    });
    const currentDate = new Date();
    const currentTime = `${currentDate.getHours()}:${currentDate.getMinutes()}`;
    yield put(saveNoteTime(currentTime));
  } catch (err) {
    // TRATAR
  }
}

export function* updateLiveSessionData({ payload }) {
  try {
    const { pat_id } = payload;
    const { sess_id } = payload;
    const { evals } = payload;
    const { note } = payload;
    const { eventId } = payload;
    const { isFinished } = payload;
    const response = yield call(api.put, `/patients/${pat_id}/inlive_session`, {
      sessionId: sess_id,
      evaluations: evals,
      notes: note,
      eventId,
      isFineshed: isFinished,
    });

    if (response.status === 200 && isFinished) {
      notification.success({
        message: 'Os dados foram salvo.',
        description: 'clique em voltar para retornar ao menu anterior.',
      });
    }
    const currentDate = new Date();
    const currentTime = `${currentDate.getHours()}:${currentDate.getMinutes()}`;
    yield put(saveNoteTime(currentTime));
  } catch (err) {
    // TRATAR
  }
}

export function* stopShowVideoToPatient({ payload }) {
  try {
    const { patientId } = payload;
    yield call(
      api.get,
      `/patients/${patientId}/inlive_session/stop_show_video`,
    );
  } catch (err) {
    // TRATAR
  }
}

export function* getVideocallsUsed() {
  try {
    const response = yield call(api.get, `/videocall_used`);

    yield put(setVideocallsUsed(response.data.videocalls));
  } catch (err) {
    // TRATAR
  }
}

// const responseSelf = yield call(api.get, '/self');
export default all([
  takeLatest('@video_call/SET_VIDEO_CALL_STATUS', setStatusOnVideoCall),
  takeLatest('@video_call/GET_VIDEO_CALL', getVideoCall),
  takeLatest('@video_call/CALL_NOTHING', setToNothing),
  takeLatest('@video_call/SEND_VIDEO_TO_PATIENT', sendVideoToPatient),
  takeLatest('@video_call/GET_IN_LIVE_SESSION_DATA', getInLiveSession),
  takeLatest('@video_call/CALL_END_VIDEO_CALL', endVideoCall),
  takeLatest('@video_call/SAVE_SESSION_DATA', saveLiveSessionData),
  takeLatest('@video_call/UPDATE_SESSION_DATA', updateLiveSessionData),
  takeLatest('@video_call/STOP_SHOW_VIDEO', stopShowVideoToPatient),
  takeLatest('@video_call/GET_VIDEO_CALL_RELATED', getVideoCallRelatedToPat),
  takeLatest('@video_call/GET_VIDEOCALLS_USED', getVideocallsUsed),
]);
