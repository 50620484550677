import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import AppHeader from 'components/Header';
import SiderMenu from 'components/Siders/Professional/Sider';
import { getPatientDetails } from 'store/modules/patient/actions';
import { useParams } from 'react-router-dom';
import FormPatientEdit from './components/FormPatientEdit';

function PatientEdit() {
  const patientDetails = useSelector(state => state.patient.patientDetails);
  const loadingPatient = useSelector(state => state.patient.loading);
  const firstChange = useMediaQuery('(min-width: 900px)');
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPatientDetails(id));
  }, []);

  return (
    <Layout>
      <AppHeader />
      <SiderMenu>
        <div style={{ marginLeft: firstChange ? '2rem' : '4rem' }}>
          {loadingPatient === false && patientDetails !== null && (
            <FormPatientEdit />
          )}
          {(loadingPatient === true || patientDetails === null) && (
            <div>Carregando ... </div>
          )}
        </div>
      </SiderMenu>
    </Layout>
  );
}

export default PatientEdit;
