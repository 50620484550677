/* eslint-disable react/no-array-index-key */
import { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import * as S from './styles';

export function ContainerContent({
  firstCategory,
  secondCategory,
  thirdCategory,
  fourthCategory,
  fifthCategory,
  sixthCategory,
  arrayOfCategoryNames,
  isOverflow,
}) {
  const isBiggerThan = useMediaQuery('(min-width: 770px)');
  const [sectionsTitles, setSectionsTitle] = useState(['', '', '', '', '']);

  useEffect(() => {
    const arr = arrayOfCategoryNames;
    const tempArray = [];
    for (let i = 0; i < arr.length; i += 1) {
      const tmpVal = arr.find(value => value.index === `${i + 1}`);
      tempArray.push(tmpVal.name);
      if (i === arr.length - 1) {
        setSectionsTitle(tempArray);
      }
    }
  }, [arrayOfCategoryNames]);

  return (
    <S.Container isOverflow={isOverflow}>
      <S.Title>{sectionsTitles[0]}</S.Title>
      <Row gutter={16}>
        {firstCategory.map((item, index) => (
          <Col key={index} className="gutter-row" span={isBiggerThan ? 6 : 16}>
            {item}
          </Col>
        ))}
      </Row>

      {secondCategory.length > 0 && (
        <>
          <S.Title>{sectionsTitles[1]}</S.Title>
          <Row gutter={16}>
            {secondCategory.map((item, index) => (
              <Col
                key={index}
                className="gutter-row"
                span={isBiggerThan ? 6 : 16}
              >
                {item}
              </Col>
            ))}
          </Row>
        </>
      )}

      {thirdCategory.length > 0 && (
        <>
          <S.Title>{sectionsTitles[2]}</S.Title>
          <Row gutter={16}>
            {thirdCategory.map((item, index) => (
              <Col
                key={index}
                className="gutter-row"
                span={isBiggerThan ? 6 : 16}
              >
                {item}
              </Col>
            ))}
          </Row>
        </>
      )}

      {fourthCategory.length > 0 && (
        <>
          <S.Title>{sectionsTitles[3]}</S.Title>
          <Row gutter={16}>
            {fourthCategory.map((item, index) => (
              <Col
                key={index}
                className="gutter-row"
                span={isBiggerThan ? 6 : 16}
              >
                {item}
              </Col>
            ))}
          </Row>
        </>
      )}

      {fifthCategory.length > 0 && (
        <>
          <S.Title>{sectionsTitles[4]}</S.Title>
          <Row gutter={16}>
            {fifthCategory.map((item, index) => (
              <Col
                key={index}
                className="gutter-row"
                span={isBiggerThan ? 6 : 16}
              >
                {item}
              </Col>
            ))}
          </Row>
        </>
      )}

      {sixthCategory.length > 0 && (
        <>
          {sectionsTitles[5] && (
            <>
              <S.Title>{sectionsTitles[5]}</S.Title>
              <Row gutter={16}>
                {sixthCategory.map((item, index) => (
                  <Col
                    key={index}
                    className="gutter-row"
                    span={isBiggerThan ? 6 : 16}
                  >
                    {item}
                  </Col>
                ))}
              </Row>
            </>
          )}
        </>
      )}
    </S.Container>
  );
}
