/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import CloseIconStatus from 'assets/closeWhite.svg';
import CheckIconStatus from 'assets/check.svg';
import { MoreOutlined } from '@ant-design/icons';
import { Row, Dropdown, Menu } from 'antd';
import { useDispatch } from 'react-redux';
import { deleteEventClinic } from 'store/modules/clinicagenda/actions';
import { differenceInMinutes } from 'date-fns';
import * as S from '../../styles';

export default function EventComponent({
  time,
  dayText,
  selectColorForEventCalendar,
  eventInfo,
  handleEventClick,
}) {
  const dispatch = useDispatch();

  const menu = eventInf => (
    <Menu>
      <Menu.Item
        onClick={() => {
          dispatch(
            deleteEventClinic(
              eventInf.extendedProps.patientId,
              eventInf.extendedProps.eventId,
              false,
              eventInf.end,
            ),
          );
        }}
      >
        <a>Apagar</a>
      </Menu.Item>
    </Menu>
  );

  if (time === 'day' && dayText === '') {
    // mobile
    return (
      <S.DivContainerEvent>
        <S.DivColorEvent
          style={{
            backgroundColor: selectColorForEventCalendar(eventInfo.event.title),
            minHeight: 50,
          }}
        />
        <S.DivColumnContainerEvent>
          <Row
            style={{
              alignSelf: 'start',
              width: '100%',
            }}
          >
            <S.ProfessionalAgenda
              onClick={() => {
                handleEventClick(eventInfo);
              }}
              style={{ paddingLeft: 6, fontSize: 11 }}
            >
              {eventInfo.event?.extendedProps?.professionalName.length > 15
                ? `${eventInfo.event?.extendedProps?.professionalName.substring(
                    0,
                    12,
                  )}...`
                : eventInfo.event?.extendedProps?.professionalName}
            </S.ProfessionalAgenda>
            <div
              style={{
                width: 14,
                height: 14,
                marginRight: 10,
                borderRadius: 50,
                marginLeft: 20,
                backgroundColor:
                  eventInfo.event.extendedProps.status === 'EVENT_REALIZED'
                    ? '#118E88'
                    : '#87889B',
              }}
              onClick={() => {
                handleEventClick(eventInfo);
              }}
            >
              {eventInfo.event?.extendedProps?.status === 'PENDING' ? (
                <S.PendingCircle style={{ width: 10, height: 10 }} />
              ) : (
                <img
                  alt="close"
                  src={
                    eventInfo.event?.extendedProps?.status === 'EVENT_REALIZED'
                      ? CheckIconStatus
                      : CloseIconStatus
                  }
                  style={{
                    height: 8,
                    width: 8,
                    marginBottom: 12,
                    marginLeft: 3,
                    alignSelf: 'center',
                    cursor: 'pointer',
                  }}
                />
              )}
            </div>
            <Dropdown overlay={menu(eventInfo.event)} trigger={['click']}>
              <a>
                <MoreOutlined style={{ fontSize: 12 }} />
              </a>
            </Dropdown>
          </Row>

          <S.PatientAgenda
            style={{ marginLeft: 14, width: '100%', fontSize: 11 }}
            onClick={() => {
              handleEventClick(eventInfo);
            }}
          >
            {eventInfo.event?.extendedProps?.patientName}
          </S.PatientAgenda>
        </S.DivColumnContainerEvent>
      </S.DivContainerEvent>
    );
  }
  if (time === 'day') {
    if (
      differenceInMinutes(
        new Date(eventInfo.event?.end),
        new Date(eventInfo.event?.start),
      ) <= 45
    ) {
      return (
        <S.DivContainerEvent style={{ height: '100%' }}>
          <S.DivColorEvent
            style={{
              backgroundColor: selectColorForEventCalendar(
                eventInfo.event?.title,
              ),
              minHeight: 30,
            }}
          />
          <S.DivColumnContainerEvent>
            <Row
              style={{
                alignSelf: 'start',
              }}
            >
              <S.PatientAgenda
                style={{ marginLeft: 10, marginRight: 10 }}
                onClick={() => {
                  handleEventClick(eventInfo);
                }}
              >
                {eventInfo.event?.extendedProps?.patientName.length > 20
                  ? `${eventInfo.event?.extendedProps?.patientName.slice(
                      0,
                      18,
                    )}...`
                  : eventInfo.event?.extendedProps?.patientName}
              </S.PatientAgenda>
              <div
                onClick={() => {
                  handleEventClick(eventInfo);
                }}
                style={{
                  width: 20,
                  height: 20,
                  marginRight: 10,
                  borderRadius: 50,
                  marginLeft: 15,
                  backgroundColor:
                    eventInfo.event.extendedProps.status === 'EVENT_REALIZED'
                      ? '#118E88'
                      : '#87889B',
                }}
              >
                {eventInfo.event?.extendedProps?.status === 'PENDING' ? (
                  <S.PendingCircle />
                ) : (
                  <img
                    alt="close"
                    src={
                      eventInfo.event?.extendedProps?.status ===
                      'EVENT_REALIZED'
                        ? CheckIconStatus
                        : CloseIconStatus
                    }
                    style={{
                      height: 10,
                      width: 10,
                      marginLeft: 5,
                      alignSelf: 'center',
                      cursor: 'pointer',
                    }}
                  />
                )}
              </div>
              <Dropdown overlay={menu(eventInfo.event)} trigger={['click']}>
                <a>
                  <MoreOutlined style={{ fontSize: 20 }} />
                </a>
              </Dropdown>
            </Row>
          </S.DivColumnContainerEvent>
        </S.DivContainerEvent>
      );
    }
    return (
      <S.DivContainerEvent>
        <S.DivColorEvent
          style={{
            backgroundColor: selectColorForEventCalendar(
              eventInfo.event?.title,
            ),
          }}
        />
        <S.DivColumnContainerEvent>
          <Row
            style={{
              alignSelf: 'start',
            }}
          >
            <S.ProfessionalAgenda
              onClick={() => {
                handleEventClick(eventInfo);
              }}
            >
              {eventInfo.event?.extendedProps?.professionalName}
            </S.ProfessionalAgenda>
            <div
              onClick={() => {
                handleEventClick(eventInfo);
              }}
              style={{
                width: 20,
                height: 20,
                marginRight: 10,
                borderRadius: 50,
                marginLeft: 20,
                backgroundColor:
                  eventInfo.event?.extendedProps?.status === 'EVENT_REALIZED'
                    ? '#118E88'
                    : '#87889B',
              }}
            >
              {eventInfo.event?.extendedProps?.status === 'PENDING' ? (
                <S.PendingCircle />
              ) : (
                <img
                  alt="close"
                  src={
                    eventInfo.event?.extendedProps?.status === 'EVENT_REALIZED'
                      ? CheckIconStatus
                      : CloseIconStatus
                  }
                  style={{
                    height: 10,
                    width: 10,
                    marginBottom: 3,
                    marginLeft: 5,
                    alignSelf: 'center',
                    cursor: 'pointer',
                  }}
                />
              )}
            </div>
            <Dropdown overlay={menu(eventInfo.event)} trigger={['click']}>
              <a>
                <MoreOutlined style={{ fontSize: 20 }} />
              </a>
            </Dropdown>
          </Row>

          <S.PatientAgenda
            style={{ width: '100%' }}
            onClick={() => {
              handleEventClick(eventInfo);
            }}
          >
            {eventInfo.event?.extendedProps?.patientName}
          </S.PatientAgenda>
        </S.DivColumnContainerEvent>
      </S.DivContainerEvent>
    );
  }
  if (time === 'week') {
    return (
      <S.DivContainerEventWeek
        onClick={() => {
          handleEventClick(eventInfo);
        }}
      >
        <S.DivColorEventTop
          style={{
            backgroundColor: selectColorForEventCalendar(
              eventInfo.event?.title,
            ),
          }}
        />
        <S.DivColumnContainerEvent>
          <S.PatientAgenda style={{ marginLeft: -7, marginBottom: 4 }}>
            {eventInfo.event?.extendedProps?.patientName.length > 20
              ? `${eventInfo.event?.extendedProps?.patientName.slice(0, 18)}...`
              : eventInfo.event?.extendedProps?.patientName}
          </S.PatientAgenda>
          <Row>
            <div
              style={{
                width: 20,
                height: 20,
                marginRight: 10,
                borderRadius: 50,
                backgroundColor:
                  eventInfo.event.extendedProps.status === 'EVENT_REALIZED'
                    ? '#118E88'
                    : '#87889B',
              }}
            >
              {eventInfo.event?.extendedProps?.status === 'PENDING' ? (
                <S.PendingCircle />
              ) : (
                <img
                  alt="close"
                  src={
                    eventInfo.event?.extendedProps?.status === 'EVENT_REALIZED'
                      ? CheckIconStatus
                      : CloseIconStatus
                  }
                  style={{
                    height: 10,
                    width: 10,
                    marginLeft: 5,
                    marginBottom: 3,
                    alignSelf: 'center',
                    cursor: 'pointer',
                  }}
                />
              )}
            </div>
            <S.PatientAgenda style={{ marginLeft: 0, color: '#868686' }}>
              {eventInfo.timeText}
            </S.PatientAgenda>
          </Row>
        </S.DivColumnContainerEvent>
      </S.DivContainerEventWeek>
    );
  }
}
