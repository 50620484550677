/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

export const Container = styled.div`
  padding: ${props =>
    props.mediaFirst
      ? `6rem 7rem 2rem 7rem`
      : props.mediaThird
      ? `6rem 3rem 2rem 3rem`
      : `6rem 0.5rem 2rem 0.5rem`};
  flex-direction: ${props => (props.mediaFirst ? 'row' : 'column')};
  display: flex;
`;

export const TitlePage = styled.h2`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal normal 20px/27px Nunito Sans;
  letter-spacing: 0px;
  margin-left: 0.1rem;
  opacity: 1;
`;

export const AntdStyles = {
  RadioButtonActiveAble: {
    textAlign: 'center',
    borderColor: '#6864A4',
    borderWidth: '1px',
    backgroundColor: '#f7f7f7',
    fontWeight: 'normal',
    color: '#6864A4',
  },

  RadioButtonActiveDisable: {
    textAlign: 'center',
    borderWidth: '1px',
    backgroundColor: '#f7f7f7',
    fontWeight: 'normal',
    color: '#868686',
  },

  RadioButtonArchivedAble: {
    textAlign: 'center',
    borderColor: '#6864A4',
    borderWidth: '1px',
    backgroundColor: '#f7f7f7',
    fontWeight: 'bold',
    color: '#6864A4',
    borderRadius: '0px 4px 4px 0px',
  },

  RadioButtonArchivedDisable: {
    textAlign: 'center',
    borderWidth: '1px',
    backgroundColor: '#f7f7f7',
    fontWeight: 'bold',
    color: '#868686',
    borderRadius: '0px 4px 4px 0px',
  },

  ButtonAddPatient: {
    background: 'var(--darkCyan) 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    borderWidth: 0,
    width: '10rem',
    marginTop: '1rem',
    marginBottom: '2rem',
  },

  InputSearchPatient: {
    width: '20rem',
    marginRight: '3rem',
  },

  ImgArchived: { marginRight: 8, width: 15, marginBottom: 4 },
  ImgDeleted: { marginRight: 8, width: 16, marginBottom: 4 },

  ImgActived: {
    marginRight: 8,
    marginBottom: -3.5,
    width: 20,
    height: 20,
  },

  ButtonArchiveOrActive: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  AvatarUser: {
    color: 'var(--darkCyan)',
    backgroundColor: '#C9E2E1',
    fontSize: 20,
  },
  marginTop: {
    marginTop: '0.5rem',
  },
};

export const TitleSiders = styled.h3`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal bold 14px/18px Nunito Sans;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 1rem;
`;

export const TitleProgress = styled.h3`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal bold 12px/18px Nunito Sans;
  letter-spacing: 0px;
  opacity: 1;
`;

export const SubtitleProgress = styled.h3`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal normal 12px/18px Nunito Sans;
  letter-spacing: 0px;
  opacity: 1;
`;

export const HifenText = styled.h5`
  color: var(--philippineSilver);
  text-align: left;
  font: normal normal normal 14px/19px Nunito Sans;
  letter-spacing: 0px;
  opacity: 1;
  margin-right: 8px;
  margin-left: 8px;
  margin-top: 0.5rem;
`;

export const ContainerTextsProgress = styled.div`
  width: 150px;
  height: 74px;
  padding-left: 6px;
`;
