/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { Layout, Menu, Drawer } from 'antd';
import { FaRegEye } from 'react-icons/fa';

import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { divider } from './Divider';
import PatientController from '../patientController';
import PatientPageHeader from '../pageHeader';
import '../SiderMenu.css';
import * as S from './styles';

import ImgRegisterData from '../../../../../assets/register-data.svg';
import ImgAnamnesis from '../../../../../assets/anamnesis.svg';
import ImgExams from '../../../../../assets/exams.svg';
import ImgObjectives from '../../../../../assets/objectives.svg';
import ImgProtocols from '../../../../../assets/protocols.svg';
import ImgAgenda from '../../../../../assets/agenda.svg';
import ImgFollow from '../../../../../assets/follow.svg';

import {
  ImageRegisterData,
  ImageAnamnesis,
  ImageExams,
  ImageObjectives,
  ImageProtocols,
  ImageAgenda,
  ImageFollow,
} from './styles';

const { Sider } = Layout;

function SiderMenu() {
  const patientDetails = useSelector(state => state.patient.patientDetails);
  const history = useHistory();
  const location = useLocation();

  const [navColor1, setNavColor1] = useState('#118E88');
  const [navColor2, setNavColor2] = useState('#118E88');
  const [navColor3, setNavColor3] = useState('#118E88');
  const [navColor4, setNavColor4] = useState('#118E88');
  const [navColor5, setNavColor5] = useState('#118E88');
  const [navColor6, setNavColor6] = useState('#118E88');
  const [navColor7, setNavColor7] = useState('#118E88');
  const [navColor8, setNavColor8] = useState('#118E88');

  const setBackGroundColor = key => {
    switch (key) {
      case '1':
        setNavColor1('#00605B');
        setNavColor2('#118E88');
        setNavColor3('#118E88');
        setNavColor4('#118E88');
        setNavColor5('#118E88');
        setNavColor6('#118E88');
        setNavColor7('#118E88');
        setNavColor8('#118E88');
        break;
      case '2':
        setNavColor1('#118E88');
        setNavColor2('#00605B');
        setNavColor3('#118E88');
        setNavColor4('#118E88');
        setNavColor5('#118E88');
        setNavColor6('#118E88');
        setNavColor7('#118E88');
        setNavColor8('#118E88');
        break;
      case '3':
        setNavColor1('#118E88');
        setNavColor2('#118E88');
        setNavColor3('#00605B');
        setNavColor4('#118E88');
        setNavColor5('#118E88');
        setNavColor6('#118E88');
        setNavColor7('#118E88');
        setNavColor8('#118E88');
        break;
      case '4':
        setNavColor1('#118E88');
        setNavColor2('#118E88');
        setNavColor3('#118E88');
        setNavColor4('#00605B');
        setNavColor5('#118E88');
        setNavColor6('#118E88');
        setNavColor7('#118E88');
        setNavColor8('#118E88');
        break;
      case '5':
        setNavColor1('#118E88');
        setNavColor2('#118E88');
        setNavColor3('#118E88');
        setNavColor4('#118E88');
        setNavColor5('#00605B');
        setNavColor6('#118E88');
        setNavColor7('#118E88');
        setNavColor8('#118E88');
        break;
      case '6':
        setNavColor1('#118E88');
        setNavColor2('#118E88');
        setNavColor3('#118E88');
        setNavColor4('#118E88');
        setNavColor5('#118E88');
        setNavColor6('#00605B');
        setNavColor7('#118E88');
        setNavColor8('#118E88');
        break;
      case '7':
        setNavColor1('#118E88');
        setNavColor2('#118E88');
        setNavColor3('#118E88');
        setNavColor4('#118E88');
        setNavColor5('#118E88');
        setNavColor6('#118E88');
        setNavColor7('#00605B');
        setNavColor8('#118E88');
        break;
      case '8':
        setNavColor1('#118E88');
        setNavColor2('#118E88');
        setNavColor3('#118E88');
        setNavColor4('#118E88');
        setNavColor5('#118E88');
        setNavColor6('#118E88');
        setNavColor7('#118E88');
        setNavColor8('#00605B');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (location.pathname.includes('edit_info')) {
      setBackGroundColor('1');
    }

    if (location.pathname.includes('anamnesis')) {
      setBackGroundColor('2');
    }

    if (location.pathname.includes('evaluations')) {
      setBackGroundColor('3');
    }

    if (location.pathname.includes('computer_vision')) {
      setBackGroundColor('4');
    }

    if (location.pathname.includes('goals')) {
      setBackGroundColor('5');
    }

    if (location.pathname.includes('protocol')) {
      setBackGroundColor('6');
    }

    if (location.pathname.includes('agenda')) {
      setBackGroundColor('7');
    }

    if (location.pathname.includes('monitoring')) {
      setBackGroundColor('8');
    }
  }, []);

  const NavBar = () => (
    <>
      <PatientPageHeader />
      <div className="space-btw" />
      <PatientController />
      <Menu mode="inline" defaultSelectedKeys={['1']} style={S.AntdStyles.menu}>
        <Menu.Item
          key="1"
          icon={<ImageRegisterData src={ImgRegisterData} />}
          style={{
            display: 'flex',
            alignItems: 'center',
            background: navColor1,
            color: 'white',
            width: '90%',
            borderRadius: '3px',
            marginBottom: '0px',
            marginTop: '4%',
          }}
          onClick={() => {
            setBackGroundColor('1');
            history.push(`/${patientDetails.id}/edit_info`);
          }}
          className="teste"
        >
          Dados Cadastrais
        </Menu.Item>
        {divider}
        <Menu.Item
          key="2"
          icon={<ImageAnamnesis src={ImgAnamnesis} />}
          style={{
            display: 'flex',
            alignItems: 'center',
            background: navColor2,
            color: 'white',
            width: '90%',
            borderRadius: '3px',
            marginBottom: '0px',
            marginTop: '0px',
          }}
          onClick={() => {
            setBackGroundColor('2');
            history.push(`/${patientDetails.id}/anamnesis`);
          }}
        >
          Anamnese
        </Menu.Item>
        {divider}
        <Menu.Item
          key="3"
          icon={<ImageExams src={ImgExams} />}
          style={{
            display: 'flex',
            alignItems: 'center',
            background: navColor3,
            color: 'white',
            width: '90%',
            borderRadius: '3px',
            marginBottom: '0px',
            marginTop: '0px',
          }}
          onClick={() => {
            setBackGroundColor('3');
            history.push(`/${patientDetails.id}/evaluations`);
          }}
        >
          Avaliações
        </Menu.Item>
        {divider}
        <Menu.Item
          key="4"
          icon={<FaRegEye color="#55bfb8" size={20} />}
          style={{
            display: 'flex',
            alignItems: 'center',
            background: navColor4,
            color: 'white',
            width: '90%',
            borderRadius: '3px',
            marginBottom: '0px',
            marginTop: '0px',
          }}
          onClick={() => {
            setBackGroundColor('4');
            history.push(`/${patientDetails.id}/computer_vision`);
          }}
        >
          Visão Computacional{' '}
          <label
            style={{
              marginTop: '1px',
              marginLeft: '2px',
              position: 'absolute',
            }}
          >
            <label
              style={{
                display: 'flex',
                fontSize: '10px',
                background: '#6963A9',
                padding: '1px',
                justifyContent: 'center',
                alignItems: 'center',
                height: '16px',
              }}
            >
              BETA
            </label>
          </label>
        </Menu.Item>
        {divider}
        <Menu.Item
          key="5"
          icon={<ImageObjectives src={ImgObjectives} />}
          style={{
            display: 'flex',
            alignItems: 'center',
            background: navColor5,
            color: 'white',
            width: '90%',
            borderRadius: '3px',
            marginBottom: '0px',
            marginTop: '0px',
          }}
          onClick={() => {
            setBackGroundColor('5');
            history.push(`/${patientDetails.id}/goals`);
          }}
        >
          Objetivos
        </Menu.Item>
        {divider}
        <Menu.Item
          key="6"
          icon={<ImageProtocols src={ImgProtocols} />}
          style={{
            display: 'flex',
            alignItems: 'center',
            background: navColor6,
            color: 'white',
            width: '90%',
            borderRadius: '3px',
            marginBottom: '0px',
            marginTop: '0px',
          }}
          onClick={() => {
            setBackGroundColor('6');
            history.push(`/${patientDetails.id}/protocol`);
          }}
        >
          Protocolos
        </Menu.Item>
        {divider}
        <Menu.Item
          key="7"
          icon={<ImageAgenda src={ImgAgenda} />}
          style={{
            display: 'flex',
            alignItems: 'center',
            background: navColor7,
            color: 'white',
            width: '90%',
            borderRadius: '3px',
            marginBottom: '0px',
            marginTop: '0px',
          }}
          onClick={() => {
            setBackGroundColor('7');
            history.push(`/${patientDetails.id}/agenda`);
          }}
        >
          Agenda
        </Menu.Item>
        {divider}
        <Menu.Item
          key="8"
          icon={<ImageFollow src={ImgFollow} />}
          style={{
            display: 'flex',
            alignItems: 'center',
            background: navColor8,
            color: 'white',
            width: '90%',
            borderRadius: '3px',
            marginBottom: '5%',
            marginTop: '0px',
          }}
          onClick={() => {
            setBackGroundColor('8');
            history.push(`/${patientDetails.id}/monitoring`);
          }}
        >
          Acompanhar
        </Menu.Item>
      </Menu>
    </>
  );

  const [toggled, setToggled] = useState(false);
  // showCollapsed tem q ficar false > 990px e true < 990px, 990px é quando o sider colapsa
  const [showCollapsed, setShowCollapsed] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 990;

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResizeWindow);
    return () => {
      window.removeEventListener('resize', handleResizeWindow);
    };
  }, []);

  useEffect(() => {
    if (width > breakpoint) {
      setShowCollapsed(false);
    } else {
      setShowCollapsed(true);
    }
  }, [width]);

  return (
    <>
      <Drawer
        placement="left"
        onClose={() => setToggled(false)}
        closable={false}
        visible={toggled}
        bodyStyle={{ backgroundColor: '#F7F7F7', padding: '15px' }}
        key="left"
        width={260}
      >
        <NavBar />
      </Drawer>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        collapsed={showCollapsed}
        style={S.AntdStyles.sider}
        zeroWidthTriggerStyle={{ backgroundColor: '#118E88' }}
        onCollapse={(collapsed, type) => {
          if (type === 'clickTrigger' && collapsed) {
            setToggled(false);
          } else if (type === 'clickTrigger' && !collapsed) {
            setToggled(true);
          }
        }}
      >
        <NavBar />
      </Sider>
    </>
  );
}

export default SiderMenu;
