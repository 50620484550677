/* eslint-disable no-empty */
import { takeLatest, call, all, put, takeEvery } from 'redux-saga/effects';
import { notification } from 'antd';

import api from '../../../services/api';

import { setSelf, getSelf, sendMsgProfModal } from './actions';
import { getProfessionalsWithInvites } from '../clinic/actions';

export function* getSelfSaga() {
  try {
    const response = yield call(api.get, `/self`);
    yield put(setSelf(response.data.self));
  } catch (err) {
    notification.error({
      message: 'Houve um erro ao buscar seus dados, tente novamente!',
    });
  }
}

export function* getSilentSelf() {
  try {
    const response = yield call(api.get, `/self`);
    yield put(setSelf(response.data.self));
  } catch (err) {}
}

export function* uploadAvatar({ payload }) {
  try {
    const { file, isShowMessage } = payload;
    const data = new FormData();
    data.append('file', file);
    data.append('action', 'UPLOAD');

    yield call(api.post, `/upload_profile_image`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    yield put(getSelf());

    if (isShowMessage) {
      notification.success({
        message: 'Atualize a página!',
        description: 'Sua imagem de perfil foi atualizada.',
      });
    }
  } catch (err) {
    notification.error({
      message: 'Houve um erro ao fazer upload da imagem, tente novamente!',
    });
  }
}

export function* removeAvatar() {
  try {
    const data = new FormData();
    data.append('action', 'REMOVE');

    yield call(api.post, `/upload_profile_image`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    yield put(getSelf());

    notification.success({
      message: 'Atualize a página!',
      description: 'Sua imagem de perfil foi removida.',
    });
  } catch (err) {
    notification.error({
      message: 'Houve um erro ao fazer upload da imagem, tente novamente!',
    });
  }
}

export function* createProfessionalSagas({ payload }) {
  try {
    const { professional, typePro } = payload;
    const finalCPF = professional.document
      .replace('.', '')
      .replace('.', '')
      .replace('-', '');
    const finalPhoneNumber = professional.cellphone
      .replace('(', '')
      .replace(')', '')
      .replace('-', '');
    if (typePro === 'receptionist') {
      const responseRegister = yield call(api.post, `/add_professional`, {
        type: 'RECEPTIONIST',
        professional: {
          name: professional.name,
          document: finalCPF,
          email: professional.email,
        },
      });
      if (responseRegister !== undefined) {
        notification.success({
          message:
            'Recepcionista adicionado(a) com sucesso, aguarde pela resposta do(a) profissional.',
        });
        yield put(sendMsgProfModal(true));
      }
    }
    if (typePro === 'professional') {
      const number = professional.houseNumber.toString();
      const responseRegister = yield call(api.post, `/add_professional`, {
        type: 'THERAPIST',
        professional: {
          name: professional.name,
          document: finalCPF,
          address: {
            street: professional.street,
            number,
            complement: professional.complement,
            neighborhood: professional.neighborhood,
            city: professional.city,
            state: professional.state,
            postal_code: professional.postalCode,
            country: 'Brasil',
          },
          phone_number: finalPhoneNumber,
          profession: professional.profession,
          email: professional.email,
          specialities: professional.speciality,
        },
      });
      if (responseRegister !== undefined) {
        notification.success({
          message:
            'Profissional de Saúde adicionado(a) com sucesso, aguarde pela resposta do(a) profissional.',
        });
        yield put(sendMsgProfModal(true));
      }
    }

    yield put(getProfessionalsWithInvites());
  } catch (err) {
    notification.error({
      message:
        'Houve um erro ao tentar adicionar profissional, cheque todos os campos e tente novamente!',
    });
  }
}

export function* archiveProfessional({ payload }) {
  try {
    const { professionalsID } = payload;

    yield call(api.put, '/archive/professionals', {
      toArchive: professionalsID,
    });

    notification.success({
      message: 'Profissional arquivado!',
    });

    yield put(getProfessionalsWithInvites());
  } catch (err) {
    notification.error({
      message: 'Houve um erro ao arquivar profissional, tente novamente!',
    });
  }
}

export function* unarchiveProfessional({ payload }) {
  try {
    const { professionalsID } = payload;

    yield call(api.put, '/unarchive/professionals', {
      toUnarchive: professionalsID,
    });

    notification.success({
      message: 'Profissional ativado!',
    });

    yield put(getProfessionalsWithInvites());
  } catch (err) {
    notification.error({
      message: 'Houve um erro ao ativar profissional, tente novamente!',
    });
  }
}

export default all([
  takeLatest('@professional/GET_SELF', getSelfSaga),
  takeEvery('@professional/GET_SILENT_SELF', getSilentSelf),
  takeLatest('@professional/ARCHIVE_PROFESSIONAL', archiveProfessional),
  takeLatest('@professional/UNARCHIVE_PROFESSIONAL', unarchiveProfessional),
  takeLatest('@professional/UPLOAD_AVATAR', uploadAvatar),
  takeLatest('@professional/REMOVE_AVATAR', removeAvatar),
  takeLatest('@professional/CREATE_PROFESSIONAL', createProfessionalSagas),
]);
