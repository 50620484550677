import styled from 'styled-components';

export const AntdStyles = {
  marginTop: {
    marginTop: '4px',
  },
  ButtonCreateNewActivity: {
    background: 'var(--darkCyan) 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    border: 'none',
    opacity: 1,
    width: '12rem',
    marginTop: '2rem',
    marginBottom: '2rem',
  },
  Btn1: {
    background: 'transparent',
    border: '1px solid var(--philippineSilver)',
    color: 'var(--davysGrey)',
    opacity: 0.9,
    width: '11rem',
  },
  Btn2: {
    background: 'transparent',
    border: '1px solid var(--darkBlueGray)',
    color: 'var(--darkBlueGray)',
    opacity: 0.9,
    width: '11rem',
  },
};

export const TitlePage = styled.h5`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal normal 20px/27px Nunito Sans;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

export const ContainerProtocolsList = styled.div`
  display: flex;
  width: 100%;
`;
