/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
import {
  takeLatest,
  all,
  call,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';
import { notification } from 'antd';

import api from 'services/api';
import { PropsProfileEnum } from 'utils/profile';
import { format, addHours } from 'date-fns';
import fire from '../../../services/fire';

import {
  setPatientsList,
  setPatientDetails,
  disableCPF,
  getPatientsList,
  getSilentPatientDetails,
} from './actions';
import { getInfoDetailsOnlyId, setInfoDetails } from '../clinic/actions';

import { signOut, setIsEmailVerified } from '../auth/actions';

export function* savePatient({ payload }) {
  try {
    const { patient } = payload;

    const born = format(addHours(new Date(patient.born), 12), 'dd-MM-yyyy');

    yield call(api.post, '/save_potential_patient_data', {
      email: patient.email,
      name: patient.name,
      born,
      phone: patient.cellphone,
      hasProfessional: patient.hasProfessional,
      feeling: patient.feeling,
      source: 'web',
    });

    notification.success({
      message: 'Recebemos seu e-mail, em breve entraremos em contato.',
    });
  } catch (err) {
    notification.error({
      message: 'O envio do e-mail falhou, tente novamente!',
    });
  }
}

export function* getPatientsListSaga() {
  try {
    if (fire.auth().currentUser.emailVerified) {
      const responsePatList = yield call(api.get, '/patient_list');
      yield put(setPatientsList(responsePatList.data.patient_list));
    } else {
      yield put(signOut());
      yield put(setIsEmailVerified(false));
    }
  } catch (err) {
    notification.error({
      message: 'Houve um erro na listagem de pacientes, tente novamente!',
    });
  }
}

export function* archivePatient({ payload }) {
  try {
    const userRedux = state => state.auth.user;
    const userStore = yield select(userRedux);

    const { patientsID } = payload;

    yield call(api.put, '/archive/patients', {
      toArchive: patientsID,
    });

    notification.success({
      message: 'Paciente(s) arquivado(s)!',
    });

    yield put(getPatientsList());
  } catch (err) {
    notification.error({
      message: 'Houve um erro ao arquivar paciente, tente novamente!',
    });
  }
}

export function* unarchivePatient({ payload }) {
  try {
    const userRedux = state => state.auth.user;
    const userStore = yield select(userRedux);
    const { patientsID } = payload;

    yield call(api.put, '/unarchive/patients', {
      toUnarchive: patientsID,
    });

    notification.success({
      message: 'Paciente(s) ativado(s)!',
    });

    yield put(getPatientsList());
  } catch (err) {
    notification.error({
      message: 'Houve um erro ao ativar paciente, tente novamente!',
    });
  }
}

export function* deletePatient({ payload }) {
  try {
    const { patientID } = payload;

    yield call(api.delete, `/patients/${patientID}`);

    notification.success({
      message: 'Paciente excluído!',
    });
    yield put(getPatientsList());
  } catch (err) {
    notification.error({
      message: 'Houve um erro ao excluir paciente, tente novamente!',
    });
  }
}

export function* getPatientDetails({ payload }) {
  try {
    const { patientID } = payload;

    const response = yield call(api.get, `/patients/${patientID}`);

    yield put(setPatientDetails(response.data.patient));
  } catch (err) {
    notification.error({
      message: 'Houve um erro ao buscar dados do paciente, tente novamente!',
    });
  }
}

export function* getSilentPatientDetailsSaga({ payload }) {
  try {
    const { patientID } = payload;

    const response = yield call(api.get, `/patients/${patientID}`);
    yield put(setPatientDetails(response.data.patient));
  } catch (err) {}
}

export function* savePatientDetails({ payload }) {
  const userRedux = state => state.auth.user;
  const userStore = yield select(userRedux);
  try {
    const { patientDetails } = payload;

    yield call(api.put, `/edit_patient/${patientDetails.id}`, {
      address: {
        city: patientDetails.city,
        complement: patientDetails.complement,
        country: 'Brasil',
        neighborhood: patientDetails.neighborhood,
        number: patientDetails.houseNumber,
        postal_code: patientDetails.postalCode,
        state: patientDetails.state,
        street: patientDetails.street,
      },
      born: patientDetails.born,
      name: patientDetails.name,
      email: patientDetails.email,
      typePayment: patientDetails.typePayment,
      healthPlan: patientDetails.healthPlan,
      phoneNumber: patientDetails.cellphone ? patientDetails.cellphone : null,
      CPF: patientDetails.cpf ? patientDetails.cpf : null,
    });

    if (userStore.active_profile_name === PropsProfileEnum.THERAPIST) {
      yield put(getSilentPatientDetails(patientDetails.id));
      yield put(setInfoDetails(patientDetails, 'PATIENT'));
    } else {
      yield put(getInfoDetailsOnlyId(patientDetails.id));
    }

    notification.success({
      message: 'Dados salvos com sucesso!',
    });
  } catch (err) {
    notification.error({
      message:
        'Houve um erro ao tentar salvar dados do paciente, tente novamente!',
    });
  }
}

export function* createPatient({ payload }) {
  try {
    const { patient } = payload;
    const number = patient.houseNumber.toString();
    const finalCPF = patient.cpf
      .replace('.', '')
      .replace('.', '')
      .replace('-', '');
    const finalPhoneNumber = patient.cellphone
      .replace('(', '')
      .replace(')', '')
      .replace('-', '');

    const responseRegister = yield call(api.post, `/patient_register`, {
      name: patient.name,
      email: patient.email,
      born: patient.born,
      CPF: finalCPF,
      phoneNumber: finalPhoneNumber,
      typePayment: patient.typePayment,
      healthPlan: patient.healthPlan,
      address: {
        street: patient.street,
        number,
        complement: patient.complement,
        neighborhood: patient.neighborhood,
        city: patient.city,
        state: patient.state,
        postal_code: patient.postalCode,
        country: 'Brasil',
      },
    });

    if (responseRegister !== undefined && responseRegister.status === 200) {
      notification.success({
        message: 'Paciente cadastrado com sucesso!',
      });
    } else {
      notification.error({
        message: responseRegister.data.toPrint,
      });
    }

    yield put(getPatientsList());
  } catch (err) {
    notification.error({
      message:
        'Houve um erro ao tentar salvar dados do paciente, cheque todos os campos e tente novamente!',
    });
  }
}

export function* addPatientToClinic({ payload }) {
  try {
    const { patient } = payload;

    const number = patient.houseNumber.toString();
    const finalCPF = patient.cpf
      .replace('.', '')
      .replace('.', '')
      .replace('-', '');
    const finalPhoneNumber = patient.cellphone
      .replace('(', '')
      .replace(')', '')
      .replace('-', '');

    const responseRegister = yield call(api.post, `/add_patient`, {
      name: patient.name,
      email: patient.email,
      born: patient.born,
      CPF: finalCPF,
      phoneNumber: finalPhoneNumber,
      typePayment: patient.typePayment,
      healthPlan: patient.healthPlan,
      address: {
        street: patient.street,
        number,
        complement: patient.complement,
        neighborhood: patient.neighborhood,
        city: patient.city,
        state: patient.state,
        postal_code: patient.postalCode,
        country: 'Brasil',
      },
    });

    if (responseRegister !== undefined && responseRegister.status === 200) {
      notification.success({
        message: 'Paciente cadastrado com sucesso!',
      });
    } else {
      notification.success({
        message: responseRegister.data.toPrint,
      });
    }

    yield put(getPatientsList());
  } catch (err) {
    notification.error({
      message:
        'Houve um erro ao tentar salvar dados do paciente, cheque todos os campos e tente novamente!',
    });
  }
}

export function* validateCPF({ payload }) {
  const { CPF } = payload;
  try {
    const finalCPF = CPF.replace('.', '').replace('.', '').replace('-', '');
    const responseSelf = yield call(api.post, '/validate_cpf_patient', {
      CPF: finalCPF,
    });
    if (responseSelf !== undefined) {
      const phone = responseSelf.data.register.phoneNumber;
      yield put(disableCPF(phone !== null ? phone.includes('*') : false));
    } else {
      yield put(disableCPF(false));
    }
  } catch (err) {
    notification.error({
      message: 'Houve um erro em buscar suas informações, tente novamente!',
    });
  }
}

export function* sendCode({ payload }) {
  const { patientId } = payload;
  try {
    yield call(api.post, '/send_access_code_from_professional', {
      patientId,
    });

    notification.success({
      message: 'Código enviado com sucesso!',
    });
  } catch (err) {
    if (err.title !== 'InvalidActionException') {
      notification.error({
        message:
          'Não foi possível enviar o código ao paciente, tente novamente! Se o erro persistir, contate o suporte!',
      });
    }
  }
}

export function* attributePatientToProfessional({ payload }) {
  const { submitList } = payload;
  try {
    const responseAttribute = yield call(api.post, '/assignProfessional', {
      assignProfessionals: submitList,
    });
    if (responseAttribute !== undefined) {
      notification.success({
        message: 'Paciente(s) atribuido com sucesso!',
      });

      yield put(getPatientsList());
    }
  } catch (err) {
    notification.error({
      message:
        'Não foi possível atribuir paciente, tente novamente! Se o erro persistir, contate o suporte!',
    });
  }
}

export default all([
  takeLatest('@patient/SAVE_PATIENT', savePatient),
  takeLatest('@patient/GET_PATIENTS_LIST', getPatientsListSaga),
  takeLatest('@patient/ARCHIVE_PATIENT', archivePatient),
  takeLatest('@patient/UNARCHIVE_PATIENT', unarchivePatient),
  takeLatest('@patient/DELETE_PATIENT', deletePatient),
  takeLatest('@patient/GET_PATIENT_DETAILS', getPatientDetails),
  takeEvery('@patient/GET_SILENT_PATIENT_DETAILS', getSilentPatientDetailsSaga),
  takeLatest('@patient/SAVE_PATIENT_DETAILS', savePatientDetails),
  takeLatest('@patient/CREATE_PATIENT', createPatient),
  takeLatest('@patient/ADD_PATIENT_TO_CLINIC', addPatientToClinic),
  takeLatest('@patient/VALIDATE_CPF_PATIENT', validateCPF),
  takeLatest('@patient/SEND_CODE_TO_PATIENT', sendCode),
  takeLatest(
    '@patient/ATTRIBUTE_PATIENT_TO_PRO',
    attributePatientToProfessional,
  ),
]);
