/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Avatar, notification, Popconfirm, Radio, Upload } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { EditOutlined, CloseCircleOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import Text from 'antd/lib/typography/Text';
import { setMyAccountView } from 'store/modules/auth/actions';
import {
  removeAvatarClinic,
  uploadAvatarClinic,
} from 'store/modules/clinic/actions';
import { nameHandler } from 'utils/NameHandler';
import { uploadAvatar, removeAvatar } from 'store/modules/professional/actions';
import { useHistory } from 'react-router-dom';
import { showHeaderVinculated } from 'utils/showHeaderVinculated';
import * as S from './styles';

function PhotoAndClinic({ secondChange = true }) {
  const user = useSelector(state => state.auth.user);
  const isMyAccountView = useSelector(state => state.auth.isMyAccountView);
  const [hoverPhoto, setHoverPhoto] = useState(false);
  const dispatch = useDispatch();
  const clinic = useSelector(state => state.clinic.self);
  const history = useHistory();

  const [radioValue, setRadioValue] = useState(
    isMyAccountView ? 'Minha Conta' : 'Clínica',
  );

  const onPreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  function beforeUpload(file) {
    const isTypeImg =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/jpg';
    if (!isTypeImg) {
      notification.error({
        message: 'Você só pode fazer upload de foto JPG/PNJ/JPEG',
      });
    }
    const isLt1M = file.size / 1024 / 1024 < 0.5;
    if (!isLt1M) {
      notification.error({
        message: 'Sua foto não pode ser maior que 500 KB.',
      });
    }
    return isTypeImg && isLt1M;
  }

  function confirmRemoveAvatar() {
    if (isMyAccountView) {
      dispatch(removeAvatar());
    } else {
      dispatch(removeAvatarClinic());
    }
  }

  const onUpload = async file => {
    if (isMyAccountView) {
      dispatch(uploadAvatar(file.file, true));
    } else {
      dispatch(uploadAvatarClinic(file.file, true));
    }
  };

  return user?.active_profile_name === 'CLINIC_ADMINISTRATOR' ||
    user?.active_profile_name === 'CLINIC' ? (
    <S.DivSider
      style={{ width: '100%', marginTop: secondChange ? '0rem' : '1rem' }}
    >
      <Text style={S.AntdStyles.titleText}>Configurações da Conta</Text>
      <Radio.Group
        value={isMyAccountView ? 'Minha Conta' : 'Clínica'}
        onChange={e => {
          setRadioValue(e.target.value);
          if (e.target.value === 'Minha Conta') {
            dispatch(setMyAccountView(true));
          } else {
            dispatch(setMyAccountView(false));
          }
        }}
        style={S.AntdStyles.RadioButtonGroup}
      >
        <Radio.Button
          value="Minha Conta"
          style={
            radioValue === 'Minha Conta'
              ? S.AntdStyles.RadioButtonActiveAble
              : S.AntdStyles.RadioButtonActiveDisable
          }
        >
          Minha conta
        </Radio.Button>
        <Radio.Button
          value="Clínica"
          style={
            radioValue === 'Clínica'
              ? S.AntdStyles.RadioButtonArchivedAble
              : S.AntdStyles.RadioButtonArchivedDisable
          }
        >
          Clínica
        </Radio.Button>
      </Radio.Group>
      {!isMyAccountView ? (
        <>
          <S.DivPhotos>
            <ImgCrop rotate>
              <Upload
                showUploadList={false}
                maxCount={1}
                customRequest={e => {
                  onUpload(e);
                }}
                beforeUpload={beforeUpload}
                onPreview={onPreview}
              >
                {hoverPhoto === false ? (
                  clinic?.photo === null ? (
                    <Avatar
                      size={120}
                      style={S.AntdStyles.AvatarUser}
                      onMouseEnter={() => setHoverPhoto(true)}
                    >
                      {user.photo === null && nameHandler(clinic.name)}
                    </Avatar>
                  ) : (
                    <Avatar
                      src={clinic?.photo}
                      key={clinic?.photo}
                      size={120}
                      style={S.AntdStyles.AvatarUser}
                      onMouseEnter={() => setHoverPhoto(true)}
                    />
                  )
                ) : (
                  <Avatar
                    size={120}
                    style={{ ...S.AntdStyles.AvatarUser, fontSize: 30 }}
                    onMouseLeave={() => setHoverPhoto(false)}
                    icon={<EditOutlined />}
                  />
                )}
              </Upload>
            </ImgCrop>
            {clinic?.photo !== null && (
              <Popconfirm
                title="Você tem certeza que deseja remover sua foto?"
                onConfirm={() => confirmRemoveAvatar()}
                onCancel={() => {}}
                okText="Sim"
                cancelText="Não"
              >
                <Avatar
                  size={25}
                  style={S.AntdStyles.removePhoto}
                  icon={
                    <CloseCircleOutlined
                      style={{ color: '#ff6666', fontSize: 23 }}
                    />
                  }
                />
              </Popconfirm>
            )}
          </S.DivPhotos>
          <S.InfoAccount>
            Para alterar informações sobre o seu plano como formas de pagamento
            e quantidade de videochamadas, acesse a página{' '}
            <S.MyPlan onClick={() => history.push('/subscription')}>
              Meu Plano
            </S.MyPlan>{' '}
          </S.InfoAccount>
        </>
      ) : (
        <>
          <S.DivPhotos>
            <ImgCrop rotate>
              <Upload
                showUploadList={false}
                maxCount={1}
                customRequest={e => {
                  onUpload(e);
                }}
                beforeUpload={beforeUpload}
                onPreview={onPreview}
              >
                {hoverPhoto === false ? (
                  user.photo === null ? (
                    <Avatar
                      size={120}
                      style={S.AntdStyles.AvatarUser}
                      onMouseEnter={() => setHoverPhoto(true)}
                    >
                      {user.photo === null && nameHandler(user.name)}
                    </Avatar>
                  ) : (
                    <Avatar
                      src={user?.photo}
                      key={user?.photo}
                      size={120}
                      style={S.AntdStyles.AvatarUser}
                      onMouseEnter={() => setHoverPhoto(true)}
                    />
                  )
                ) : (
                  <Avatar
                    size={120}
                    style={{ ...S.AntdStyles.AvatarUser, fontSize: 30 }}
                    onMouseLeave={() => setHoverPhoto(false)}
                    icon={<EditOutlined />}
                  >
                    {user.photo === null && nameHandler(user.name)}
                  </Avatar>
                )}
              </Upload>
            </ImgCrop>
            {user.photo !== null && (
              <Popconfirm
                title="Você tem certeza que deseja remover sua foto?"
                onConfirm={() => confirmRemoveAvatar()}
                onCancel={() => {}}
                okText="Sim"
                cancelText="Não"
              >
                <Avatar
                  size={25}
                  style={S.AntdStyles.removePhoto}
                  icon={
                    <CloseCircleOutlined
                      style={{ color: '#ff6666', fontSize: 23 }}
                    />
                  }
                />
              </Popconfirm>
            )}
          </S.DivPhotos>
          <S.InfoAccount>
            Esta conta é vinculada à {clinic?.name}.
          </S.InfoAccount>
        </>
      )}
    </S.DivSider>
  ) : (
    <S.DivSider
      style={{ width: '83.5%', marginTop: secondChange ? '0rem' : '3rem' }}
    >
      <S.TitleMyAccount>Minha Conta</S.TitleMyAccount>
      <S.DivPhotos>
        <ImgCrop rotate>
          <Upload
            showUploadList={false}
            maxCount={1}
            customRequest={e => {
              onUpload(e);
            }}
            beforeUpload={beforeUpload}
            onPreview={onPreview}
          >
            {hoverPhoto === false ? (
              <Avatar
                size={120}
                src={user?.photo}
                style={S.AntdStyles.AvatarUser}
                onMouseEnter={() => setHoverPhoto(true)}
              >
                {user.photo === null && nameHandler(user.name)}
              </Avatar>
            ) : (
              <Avatar
                size={120}
                src={user?.photo}
                style={{ ...S.AntdStyles.AvatarUser, fontSize: 30 }}
                onMouseLeave={() => setHoverPhoto(false)}
                icon={<EditOutlined />}
              >
                {user.photo === null && nameHandler(user.name)}
              </Avatar>
            )}
          </Upload>
        </ImgCrop>
        {user.photo !== null && (
          <Popconfirm
            title="Você tem certeza que deseja remover sua foto?"
            onConfirm={() => confirmRemoveAvatar()}
            onCancel={() => {}}
            okText="Sim"
            cancelText="Não"
          >
            <Avatar
              size={25}
              style={S.AntdStyles.removePhoto}
              icon={
                <CloseCircleOutlined
                  style={{ color: '#ff6666', fontSize: 23 }}
                />
              }
            />
          </Popconfirm>
        )}
        {/* <Avatar size={38} style={S.AntdStyles.PhotoClinic}>
          Clinica
        </Avatar> */}
      </S.DivPhotos>
      {showHeaderVinculated(user) ? (
        <>
          <S.InfoAccount>
            Essa conta é vinculada à Clínica {clinic?.name}. Somente o
            administrador pode editar os dados da clínica.
          </S.InfoAccount>
          <S.InfoAccount>
            Para alterar informações sobre o seu plano como formas de pagamento
            e quantidade de videochamadas, acesse a página{' '}
            <S.MyPlan onClick={() => history.push('/subscription')}>
              Meu Plano
            </S.MyPlan>
            .
          </S.InfoAccount>
        </>
      ) : (
        <>
          <S.InfoAccount>
            Essa conta não está vinculada a nenhuma clínica.
          </S.InfoAccount>
        </>
      )}
    </S.DivSider>
  );
}

export default PhotoAndClinic;
