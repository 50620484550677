import styled from 'styled-components';

export const AntdStyles = {
  sider: {
    display: 'flex',
    flexDirection: 'column',
    background: '#00000000',
    width: '239px',
    height: '80%',
    marginTop: '5%',
  },

  menu: {
    display: 'flex',
    alignItems: 'center',
    background: '#118E88',
    color: 'white',
    flexDirection: 'column',
    borderRadius: '0px 0px 5px 5px',
  },
};

export const BreadCrumbIcon = styled.img`
  width: 8px;
  height: 8px;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 2px;
`;
