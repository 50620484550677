/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import { useState } from 'react';
import { TimePicker, Input, Button, Checkbox, Row, Col, Upload } from 'antd';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import ImgCrop from 'antd-img-crop';
import localePTDate from 'antd/lib/locale/pt_BR';
import fire from 'services/fire';

import InputMask from 'react-input-mask';
import { DaysTranslated, PropsDayArray, PropsDayEnum } from 'utils/daysAWeek';
import moment from 'moment';

// import { registerClinic } from 'store/modules/auth/actions';
import {
  registerReceptionist,
  updateUserState,
} from 'store/modules/auth/actions';
import { maskBuilderCPFCNPJ } from '../../../../../../utils/maskValidator';

import * as S from './styles';

function ReceptionistProfile({ setPage }) {
  const user = useSelector(state => state.auth.user);
  const loading = useSelector(state => state.auth.loading);
  const [fileList, setFileList] = useState([]);
  const dispatch = useDispatch();

  const formik = {
    initialValues: {
      name: user !== null ? user.name : '',
      document: '',
      email: user !== null ? user.email : '',
      password: '',
      terms: false,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('É obrigatório'),
      document: Yup.string().required('É obrigatório'),
      email: Yup.string().email('E-mail inválido').required('É obrigatório'),
      password:
        user !== null && user.email !== null
          ? Yup.string()
          : Yup.string().required('É obrigatório'),
      terms: Yup.boolean().equals([true], 'Você precisa aceitar os termos'),
    }),
    onSubmit: values => {
      const userSubmit = values;
      const photo = fileList.length > 0 ? fileList[0].originFileObj : null;

      dispatch(registerReceptionist(userSubmit, photo, setPage));
      // dispatch(updateUserState(null));
      // setPage('login');
    },
  };

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  return (
    <Formik
      initialValues={formik.initialValues}
      validationSchema={formik.validationSchema}
      onSubmit={values => formik.onSubmit(values)}
    >
      {props => (
        <>
          <S.TextLogin>
            Cadastre-se para utilizar a nossa plataforma.
          </S.TextLogin>

          {(user !== null && user.photo === null) ||
            (user === null && (
              <>
                <S.DescInput>Foto</S.DescInput>

                <ImgCrop rotate>
                  <Upload
                    listType="picture-card"
                    fileList={fileList}
                    onChange={onChange}
                    onPreview={onPreview}
                  >
                    {fileList.length < 1 && '+ Upload'}
                  </Upload>
                </ImgCrop>
              </>
            ))}

          <>
            <S.DescInput>Nome</S.DescInput>
            <Input
              placeholder="Nome completo"
              style={S.AntdStyles.inputForm}
              id="name"
              name="name"
              type="text"
              onChange={props.handleChange}
              value={props.values.name}
              onBlur={props.handleBlur}
            />
            {props.touched.name && props.errors.name ? (
              <S.ErrorMessage>{props.errors.name}</S.ErrorMessage>
            ) : null}
          </>

          <S.DescInput>CPF ou CNPJ</S.DescInput>
          <InputMask
            mask={maskBuilderCPFCNPJ(props.values.document)}
            style={S.AntdStyles.inputMask}
            value={props.values.document}
            onChange={e =>
              props.setFieldValue('document', e.target.value.replace(/\D/g, ''))
            }
          />
          {props.touched.document && props.errors.document ? (
            <S.ErrorMessage>{props.errors.document}</S.ErrorMessage>
          ) : null}

          {user === null && (
            <>
              <S.DescInput>E-mail</S.DescInput>
              <Input
                placeholder="exemplo@exemplo.com.br"
                style={S.AntdStyles.inputForm}
                id="email"
                name="email"
                type="email"
                onChange={props.handleChange}
                value={props.values.email}
                onBlur={props.handleBlur}
              />
              {props.touched.email && props.errors.email ? (
                <S.ErrorMessage>{props.errors.email}</S.ErrorMessage>
              ) : null}
            </>
          )}

          {user === null && (
            <>
              <S.DescInput>Senha</S.DescInput>
              <Input.Password
                placeholder="Digite a sua senha"
                style={S.AntdStyles.inputForm}
                id="password"
                name="password"
                type="password"
                onChange={props.handleChange}
                value={props.values.password}
                onBlur={props.handleBlur}
              />
              {props.touched.password && props.errors.password ? (
                <S.ErrorMessage>{props.errors.password}</S.ErrorMessage>
              ) : null}
            </>
          )}

          <S.DivTerms>
            <Checkbox
              onChange={e => props.setFieldValue('terms', e.target.checked)}
              value={props.values.terms}
            >
              {' '}
              Li e aceito os{' '}
              <a
                href="https://neurobots.com.br/monitore/termos"
                target="_blank"
                rel="noreferrer"
              >
                termos de uso{' '}
              </a>
              e a{' '}
              <a
                href="https://neurobots.com.br/monitore/privacidade"
                target="_blank"
                rel="noreferrer"
              >
                política de privacidade.
              </a>
            </Checkbox>
            {props.touched.terms && props.errors.terms ? (
              <S.ErrorMessage>{props.errors.terms}</S.ErrorMessage>
            ) : null}
          </S.DivTerms>

          <Button
            type="primary"
            style={S.AntdStyles.buttonLogin}
            loading={loading}
            onClick={props.handleSubmit}
            htmlType="submit"
          >
            Cadastrar
          </Button>
        </>
      )}
    </Formik>
  );
}

export default ReceptionistProfile;
