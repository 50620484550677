export const PropsSpecialityEnum = {
  NOTDEFINED: 'notdefined',
  NEUROFUNCTIONAL: 'neurofunctional',
  DERMATOFUNCTIONAL: 'dermatofunctional',
  WOMENHEALTH: 'womenhealth',
  VESTIBULARREHABILITATION: 'vestibularrehabilitation',
  PEDIATRICS: 'pediatrics',
  GERIATRICS: 'geriatrics',
  REUMATHOLOGY: 'reumathology',
  MUSCLESKELETALORTHOPEDICS: 'muscleskeletalorthopedics',
  SPORT: 'sport',
  OTHER: 'other',
};

export const PropsSpecialityEnumUpper = {
  NOTDEFINED: 'NOTDEFINED',
  NEUROFUNCTIONAL: 'NEUROFUNCTIONAL',
  DERMATOFUNCTIONAL: 'DERMATOFUNCTIONAL',
  WOMENHEALTH: 'WOMENHEALTH',
  VESTIBULARREHABILITATION: 'VESTIBULARREHABILITATION',
  PEDIATRICS: 'PEDIATRICS',
  GERIATRICS: 'GERIATRICS',
  REUMATHOLOGY: 'REUMATHOLOGY',
  MUSCLESKELETALORTHOPEDICS: 'MUSCLESKELETALORTHOPEDICS',
  SPORT: 'SPORT',
  OTHER: 'OTHER',
};

export const PropsSpecialityEnumTranslated = {
  NOTDEFINED: 'Não definido',
  NEUROFUNCTIONAL: 'Neurofuncional',
  DERMATOFUNCTIONAL: 'Dermatofuncional',
  WOMENHEALTH: 'Saúde da mulher',
  VESTIBULARREHABILITATION: 'Reabilitação vestibular',
  PEDIATRICS: 'Pediatria',
  GERIATRICS: 'Geriatria',
  REUMATHOLOGY: 'Reumatologia',
  MUSCLESKELETALORTHOPEDICS: 'Ortopedia Músculo-esquelética',
  SPORT: 'Esportiva',
  OTHER: 'Outro',
};

export const PropsSpecialityArray = [
  'NEUROFUNCTIONAL',
  'PEDIATRICS',
  'GERIATRICS',
  'REUMATHOLOGY',
  'SPORT',
  'DERMATOFUNCTIONAL',
  'WOMENHEALTH',
  'VESTIBULARREHABILITATION',
  'MUSCLESKELETALORTHOPEDICS',
  'OTHER',
];

export const PropsSpecialityTranslated = [
  {
    type: PropsSpecialityEnum.NEUROFUNCTIONAL,
    pt: 'Neurofuncional',
  },
  {
    type: PropsSpecialityEnum.DERMATOFUNCTIONAL,
    pt: 'Dermatofuncional',
  },
  {
    type: PropsSpecialityEnum.WOMENHEALTH,
    pt: 'Saúde da mulher',
  },
  {
    type: PropsSpecialityEnum.VESTIBULARREHABILITATION,
    pt: 'Reabilitação vestibular',
  },
  {
    type: PropsSpecialityEnum.PEDIATRICS,
    pt: 'Pediatria',
  },
  {
    type: PropsSpecialityEnum.GERIATRICS,
    pt: 'Geriatria',
  },
  {
    type: PropsSpecialityEnum.REUMATHOLOGY,
    pt: 'Reumatologia',
  },
  {
    type: PropsSpecialityEnum.MUSCLESKELETALORTHOPEDICS,
    pt: 'Ortopedia Músculo-esquelética',
  },
  {
    type: PropsSpecialityEnum.SPORT,
    pt: 'Esportiva',
  },
  {
    type: PropsSpecialityEnum.OTHER,
    pt: 'Outro',
  },
];

export const SpecialityTranslated = speciality => {
  if (speciality === undefined) return '';
  const result = PropsSpecialityTranslated.find(p => p.type === speciality).pt;
  return result;
};

export const PropsSpecialityTranslatedArray = [
  {
    type: 'NEUROFUNCTIONAL',
    pt: 'Neurofuncional',
  },
  {
    type: 'DERMATOFUNCTIONAL',
    pt: 'Dermato-funcional',
  },
  {
    type: 'WOMENHEALTH',
    pt: 'Saúde da mulher',
  },
  {
    type: 'VESTIBULARREHABILITATION',
    pt: 'Reabilitação vestibular',
  },
  {
    type: 'PEDIATRICS',
    pt: 'Pediatria',
  },
  {
    type: 'GERIATRICS',
    pt: 'Geriatria',
  },
  {
    type: 'REUMATHOLOGY',
    pt: 'Reumatologia',
  },
  {
    type: 'MUSCLESKELETALORTHOPEDICS',
    pt: 'Ortopedia Músculo-esquelética',
  },
  {
    type: 'SPORT',
    pt: 'Esportiva',
  },
  {
    type: 'OTHER',
    pt: 'Outro',
  },
];

export const SpecialityTranslatedFromArray = speciality => {
  const result = PropsSpecialityTranslatedArray.find(p => p.type === speciality)
    .pt;
  return result;
};
