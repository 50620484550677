import styled from 'styled-components';

export const AntdStyles = {
  ButtonCreateNewActivity: {
    background: 'var(--darkCyan) 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    border: 'none',
    opacity: 1,
    width: '12rem',
    marginTop: '2rem',
    marginBottom: '2rem',
  },
  ButtonCreatePractice: {
    background: 'var(--darkCyan) 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    border: 'none',
    opacity: 1,
    width: '9rem',
    marginTop: '3rem',
    marginBottom: '1rem',
  },
  iconClose: { marginRight: 8, width: 15, marginBottom: 4 },
};

export const TitlePage = styled.h5`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal normal 20px/27px Nunito Sans;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
`;

export const ContElementsCreateProtocol = styled.div`
  display: flex;
  flex-direction: column;
  height: 60px;
  color: var(--davysGrey);
  text-align: left;
  font: normal normal normal 20px/27px Nunito Sans;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 1rem;

  strong {
    color: var(--davysGrey);
    font-size: 16px;
  }
`;

export const ContElementsVideoSelection = styled.div`
  display: flex;
  align-items: center;
  color: var(--davysGrey);
  text-align: left;
  font: normal normal normal 20px/27px Nunito Sans;
  letter-spacing: 0px;
  opacity: 1;
  justify-content: space-between;

  @media (max-width: 1090px) {
    flex-direction: column;
    justify-content: space-between;
  }

  @media (max-width: 590px) {
    width: 500px;
  }

  strong {
    font-size: 14px;
    font-weight: bold;
    color: var(--darkCyan);
    margin-left: 0.5rem;
    cursor: pointer;
    min-width: 140px;
  }
`;

export const ImageFilterActivities = styled.img`
  width: 20px;
  cursor: pointer;
`;

export const ButtonShowAddedActitivies = styled.div`
  display: flex;
  height: 28px;
  width: 200px;
  color: var(--deepKoamaru);
  text-align: left;
  font: normal normal normal 20px/27px Nunito Sans;
  letter-spacing: 0px;
  opacity: 1;
  cursor: pointer;
  border: 1px solid var(--deepKoamaru);
  border-radius: 4px;
  align-items: center;
  justify-content: space-around;

  strong {
    font-size: 14px;
    font-weight: bold;
    color: var(--deepKoamaru);
  }
`;

export const ImageAddedActitivies = styled.div`
  display: flex;
  width: 25px;
  height: 20px;
  border: 1px solid var(--deepKoamaru);
  border-radius: 4px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  background-color: var(--deepKoamaru);

  b {
    font-size: 14px;
    font-weight: bold;
    color: var(--white);
  }
`;

/**
 * * FILTERS
 */

export const ContainerFilters = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 350px;
  overflow-y: scroll;
`;

export const ElementFilterActivities = styled.div`
  display: flex;
  flex-direction: row;
  width: 95%;
  height: 100%;
  margin-top: 1%;
`;

export const ElementTitleFilters = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 57%;
  height: auto;

  li {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  ul {
    margin-left: 2%;
    width: 100%;
  }
`;

export const ElementTitleFiltersSmall = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 43%;
  height: auto;

  span {
    margin-left: 2%;
  }

  li {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  ul {
    margin-left: 2%;
    width: 100%;
  }
`;

export const ItemSelectFilter = styled.ul`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-top: 4px;
  span {
    display: flex;
    width: 100%;
  }
`;
