/* eslint-disable no-unused-vars */
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Tooltip } from 'antd';

export const columns = [
  {
    title: 'Avaliação',
    dataIndex: 'evaluation',
    key: 'evaluation',
    align: 'center',
    width: 100,
  },
  {
    title: (filters, sortOrder) => (
      <Tooltip title="Velocidade da marcha em metros/segundos (m/s). Ex: 3m/s.">
        <div style={{ marginRight: 5 }}>Velocidade</div>
      </Tooltip>
    ),
    dataIndex: 'velocity',
    key: 'velocity',
    align: 'center',
    width: 100,
  },
  {
    title: (filters, sortOrder) => (
      <Tooltip title="Número de passos por segundo. Ex: 2 passos/segundos.">
        <div style={{ marginRight: 5 }}>Cadência</div>
      </Tooltip>
    ),
    dataIndex: 'cadency',
    key: 'cadency',
    align: 'center',
    width: 100,
  },
  {
    title: (filters, sortOrder) => (
      <Tooltip title="Comprimento do passo médio em centímetros (cm). Ex: 150cm.">
        <div>Comprimento do Passo lado direito</div>
      </Tooltip>
    ),
    dataIndex: 'step_length_right',
    key: 'step_length_right',
    align: 'center',
    width: 100,
  },
  {
    title: (filters, sortOrder) => (
      <Tooltip title="Comprimento do passo médio em centímetros (cm). Ex: 150cm.">
        <div>Comprimento do Passo lado esquerdo</div>
      </Tooltip>
    ),
    dataIndex: 'step_length_left',
    key: 'step_length_left',
    align: 'center',
    width: 100,
  },
  {
    title: (filters, sortOrder) => (
      <Tooltip title="Fase de apoio é a fase entre o calcanhar encostar no chão e o pé sair do chão.">
        <div>% Fase de Apoio lado direito</div>
      </Tooltip>
    ),
    dataIndex: 'stance_right',
    key: 'stance_right',
    align: 'center',
    width: 100,
  },
  {
    title: (filters, sortOrder) => (
      <Tooltip title="Fase de apoio é a fase entre o calcanhar encostar no chão e o pé sair do chão.">
        <div>% Fase de Apoio lado esquerdo</div>
      </Tooltip>
    ),
    dataIndex: 'stance_left',
    key: 'stance_left',
    align: 'center',
    width: 100,
  },
  {
    title: (filters, sortOrder) => (
      <Tooltip title="Fase de balanço é a fase entre o pé sair do chão e o calcanhar encostar no chão.">
        <div>% Fase de Balanço lado direito</div>
      </Tooltip>
    ),
    dataIndex: 'swing_right',
    key: 'swing_right',
    align: 'center',
    width: 100,
  },
  {
    title: (filters, sortOrder) => (
      <Tooltip title="Fase de balanço é a fase entre o pé sair do chão e o calcanhar encostar no chão.">
        <div>% Fase de Balanço lado esquerdo</div>
      </Tooltip>
    ),
    dataIndex: 'swing_left',
    key: 'swing_left',
    align: 'center',
    width: 100,
  },
];

export const columnPOne = [
  {
    title: 'Avaliação',
    dataIndex: 'evaluation',
    key: 'evaluation',
    align: 'center',
    width: 100,
  },
  {
    title: (filters, sortOrder) => (
      <Tooltip title="Velocidade da marcha em metros/segundos (m/s). Ex: 3m/s.">
        <div style={{ marginRight: 5 }}>Velocidade</div>
      </Tooltip>
    ),
    dataIndex: 'velocity',
    key: 'velocity',
    align: 'center',
    width: 100,
  },
  {
    title: (filters, sortOrder) => (
      <Tooltip title="Número de passos por segundo. Ex: 2 passos/segundos">
        <div style={{ marginRight: 5 }}>Cadência</div>
      </Tooltip>
    ),
    dataIndex: 'cadency',
    key: 'cadency',
    align: 'center',
    width: 100,
  },
  {
    title: (filters, sortOrder) => (
      <Tooltip title="Comprimento do passo médio em centímetros (cm). Ex: 150cm.">
        <div>Comprimento do Passo lado direito</div>
      </Tooltip>
    ),
    dataIndex: 'step_length_right',
    key: 'step_length_right',
    align: 'center',
    width: 100,
  },
];

export const columnPTwo = [
  {
    title: (filters, sortOrder) => (
      <Tooltip title="Comprimento do passo médio em centímetros (cm). Ex: 150cm.">
        <div>Comprimento do Passo lado esquerdo</div>
      </Tooltip>
    ),
    dataIndex: 'step_length_left',
    key: 'step_length_left',
    align: 'center',
    width: 100,
  },
  {
    title: (filters, sortOrder) => (
      <Tooltip title="Fase de apoio é a fase entre o calcanhar encostar no chão e o pé sair do chão.">
        <div>% Fase de Apoio lado direito</div>
      </Tooltip>
    ),
    dataIndex: 'stance_right',
    key: 'stance_right',
    align: 'center',
    width: 100,
  },
  {
    title: (filters, sortOrder) => (
      <Tooltip title="Fase de apoio é a fase entre o calcanhar encostar no chão e o pé sair do chão.">
        <div>% Fase de Apoio lado esquerdo</div>
      </Tooltip>
    ),
    dataIndex: 'stance_left',
    key: 'stance_left',
    align: 'center',
    width: 100,
  },
];

export const columnPThree = [
  {
    title: (filters, sortOrder) => (
      <Tooltip title="Fase de balanço é a fase entre o pé sair do chão e o calcanhar encostar no chão.">
        <div>% Fase de Balanço lado direito</div>
      </Tooltip>
    ),
    dataIndex: 'swing_right',
    key: 'swing_right',
    align: 'center',
    width: 100,
  },
  {
    title: (filters, sortOrder) => (
      <Tooltip title="Fase de balanço é a fase entre o pé sair do chão e o calcanhar encostar no chão.">
        <div>% Fase de Balanço lado esquerdo</div>
      </Tooltip>
    ),
    dataIndex: 'swing_left',
    key: 'swing_left',
    align: 'center',
    width: 100,
  },
];
