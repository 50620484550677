import React from 'react';
import { Menu, Dropdown, Button } from 'antd';
import ArrowDownGrey from 'assets/arrow-down-grey.svg';
import { Preview } from './components/preview';
import { LiveSession } from './components/live-practice';
import Evaluations from '../../../Evaluations';
import Anamnesis from '../../../Anamnesis';
import Goals from '../../../Goals';

import * as S from './styles';

export function Subscreens({
  dropdownOption,
  selectedOption,
  handleSessionStart,
  isPreviewSession,
  canUseDropDown,
  handleDropdownSelection,
}) {
  return (
    <S.Container>
      {(selectedOption === 'Prática do Dia' ||
        selectedOption === 'Avaliações' ||
        selectedOption === 'Objetivos' ||
        selectedOption === 'Anamnese' ||
        selectedOption === 'Selecione') &&
        !isPreviewSession && (
          <S.ContainerDropDown>
            <Dropdown
              overlay={
                <Menu style={S.AntdStyles.menuExams}>
                  {dropdownOption.map(item => (
                    <Menu.Item>
                      <element onClick={() => handleDropdownSelection(item)}>
                        {item}
                      </element>
                    </Menu.Item>
                  ))}
                </Menu>
              }
              disabled={!canUseDropDown}
              placement="bottomCenter"
            >
              <Button style={S.AntdStyles.ButtonExams}>
                {selectedOption}{' '}
                <S.ImgDropdown src={ArrowDownGrey} alt="mostrar exames" />
              </Button>
            </Dropdown>
          </S.ContainerDropDown>
        )}

      {selectedOption === 'Prática do Dia' && !isPreviewSession && (
        <Preview handleSessionStart={handleSessionStart} />
      )}

      {selectedOption === 'Prática do Dia' && isPreviewSession && (
        <LiveSession handleSessionStart={handleSessionStart} />
      )}

      {selectedOption === 'Avaliações' && (
        <div style={{ height: '100%', overflowY: 'scroll' }}>
          <Evaluations calledByVideoCall />
        </div>
      )}

      {selectedOption === 'Anamnese' && (
        <div style={{ height: '100%', overflowY: 'scroll' }}>
          <Anamnesis calledByVideoCall />
        </div>
      )}

      {selectedOption === 'Objetivos' && (
        <div style={{ height: '100%', overflowY: 'scroll' }}>
          <Goals calledByVideoCall />
        </div>
      )}
    </S.Container>
  );
}
