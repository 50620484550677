/* eslint-disable no-unused-vars */
import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  agenda: null,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@prof_agenda/GET_PROFESSIONAL_AGENDA': {
        draft.loading = true;
        break;
      }

      case '@prof_agenda/SET_PROFESSIONAL_AGENDA': {
        draft.loading = false;
        draft.agenda = action.payload.agenda;
        break;
      }

      default:
    }
  });
}
