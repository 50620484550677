import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const ContainerLeftSectionForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media (min-width: 770px) {
    width: 50%;
  }

  @media (max-width: 770px) {
    width: 70%;
  }
`;

export const ContainerRightSectionCard = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  @media (min-width: 770px) {
    visibility: visible;
  }

  @media (max-width: 770px) {
    visibility: hidden;
  }
`;

export const LabelText = styled.div`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal bold 14px/19px Nunito Sans;
  letter-spacing: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ErrorMessage = styled.label`
  color: #c12;
  font-size: 12px;
  margin-top: 2px;
  font-weight: 100;
`;

export const AntdStyles = {
  marginTop: {
    marginTop: '4px',
  },
  iconClose: { marginRight: 8, width: 15, marginBottom: 4 },

  inputMask: {
    textAlign: 'left',
    font: 'normal normal normal 14px/19px Nunito Sans',
    letterSpacing: '0px',
    color: 'var(--jet)',
    display: 'flex',
    height: '32px',
    width: '100%',
    padding: '4px 11px',
    marginTop: '0.25rem',
    borderWidth: 1,
    border: '1px solid #d9d9d9',
  },

  shortInputMask: {
    textAlign: 'left',
    font: 'normal normal normal 14px/19px Nunito Sans',
    letterSpacing: '0px',
    color: 'var(--jet)',
    display: 'flex',
    height: '32px',
    width: '50%',
    padding: '4px 11px',
    marginTop: '0.25rem',
    borderWidth: 1,
    border: '1px solid #d9d9d9',
  },

  ButtonUpdate: {
    width: '7rem',
    borderRadius: '5px',
    backgroundColor: 'transparent',
    borderColor: '#118E88',
    color: '#118E88',
  },

  ButtonClose: {
    width: '7rem',
    borderRadius: '5px',
    backgroundColor: '#118E88',
    color: '#FFFF',
  },

  ButtonSave: {
    background: 'var(--darkCyan) 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    opacity: 1,
    width: '12rem',
    marginTop: '2rem',
    marginBottom: '2rem',
    fontWeight: 'bold',
  },

  AutoComplete: {
    marginTop: '4px',
    fontFamily: 'Nunito Sans',
    fontSize: '14px',
  },

  ButtonTypePayment: {
    marginTop: '4px',
    textAlign: 'left',
    fontFamily: 'Nunito Sans',
  },

  DivSessionAddress: {
    marginTop: '3rem',
    width: '76px',
  },

  marginTop2rem: {
    marginTop: '2rem',
  },

  inputNumber: { width: '100%', marginTop: '4px' },
  menuStates: { height: 200, overflowY: 'scroll' },
};
