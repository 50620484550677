export const ComputerVisionEnum = {
  Marcha: {
    'Instruções de Background': [
      'Escolha um ambiente organizado sem objetos ou obstáculos (sem sapatos no chão, sem móveis, sem caixas, etc).',
      'Garanta que o percurso seja em uma superficie plana.',
      'Escolha um local com iluminação uniforme.',
      'Escolha um local com minimas distrações no background, sem desenhos, quadros, fotos e etc.',
    ],
    'Instruções de Posicionamento da Câmera': [
      'Posicione a câmera horizontalmente (modo paisagem).',
      'Posicione a câmera em uma altura fixa.',
      'Posicione a câmera a uma distância que permita filmar todo o percurso da marcha, sem que seja preciso movimentar a câmera para acompanhar o paciente.',
      'Filme a atividade com paciente vestindo roupas que deixem o cotovelo e joelho visíveis, preferencialmente camisas sem manga e shorts de compressão e garanta que os pés estejam sempre visíveis.',
    ],
    'Instruções de Filmagem da Marcha': [
      'Inicie e finalize a gravação com paciente em repouso com os dois pés no chão. De forma alternativa, edite o vídeo para que o paciente inicie e finalize no inicio da fase de apoio.',
      'Determine uma posição inicial e final para a marcha.',
      'Grave um vídeo em vista lateral do paciente indo, finalize o vídeo. Agora grave um novo vídeo com o paciente andando no sentido oposto. Tente minimizar ao máximo o atraso entre começar o vídeo e instruir o paciente para iniciar a marcha.',
      'Ao gravar o lado direito, peça para o paciente dar o primeiro passo com a perna direita. Do mesmo modo, ao gravar com lado esquerdo, peça para o paciente dar o primeiro passo com a perna esquerda.',
      'Conclua o vídeo assim que finalizar o movimento da marcha.',
      'Faça o upload do vídeo que filma a marcha em vista lateral direita e esquerda separadamente.',
      'O tempo máximo do vídeo deve ser de 15 segundos para cada lado.',
    ],
  },
};

export const ComputerVisionArray = [
  {
    value: 'Marcha',
    label: 'GAIT',
  },
];

export const ComputerVisionTranslated = {
  GAIT: 'Marcha',
};
