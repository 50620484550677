export function getContacts(isChatPage = false) {
  return {
    type: '@messages/GET_CONTACTS',
    payload: { isChatPage },
  };
}

export function setNewMessages(newMessages) {
  return {
    type: '@messages/SET_NEWMESSAGES',
    payload: { newMessages },
  };
}

export function setContacts(contacts) {
  return {
    type: '@messages/SET_CONTACTS',
    payload: { contacts },
  };
}

export function sendMessage(chatId, userReceiverId, message) {
  return {
    type: '@messages/SEND_MESSAGE',
    payload: { chatId, userReceiverId, message },
  };
}

export function newChat(chatId) {
  return {
    type: '@messages/NEW_CHAT',
    payload: { chatId },
  };
}

export function updateContacts(userId, chatId) {
  return {
    type: '@messages/UPDATE_CONTACTS',
    payload: { userId, chatId },
  };
}

export function selectChat(
  userReceiverId,
  userReceiverName,
  userReceiverPhoto,
  chatId,
  lastMessage,
  profileName,
  uid,
) {
  return {
    type: '@messages/SELECT_CHAT',
    payload: {
      userReceiverId,
      userReceiverName,
      userReceiverPhoto,
      chatId,
      lastMessage,
      profileName,
      uid,
    },
  };
}

export function sendFile(userReceiverId, chatId, file) {
  return {
    type: '@messages/SEND_FILE',
    payload: { userReceiverId, chatId, file },
  };
}

export function deleteMessage(messageId, chatId) {
  return {
    type: '@messages/DELETE_MESSAGE',
    payload: { messageId, chatId },
  };
}

export function updateStatus(chatId, userIdReceive, status) {
  return {
    type: '@messages/UPDATE_STATUS',
    payload: { chatId, userIdReceive, status },
  };
}

export function getTimezone() {
  return {
    type: '@messages/GET_TIMEZONE',
  };
}

export function setTimezone(timezoneHours) {
  return {
    type: '@messages/SET_TIMEZONE',
    payload: { timezoneHours },
  };
}

// NOTIFICATIONS

export function getNotifications(index, notifications) {
  return {
    type: '@messages/GET_NOTIFICATIONS',
    payload: { index, notifications },
  };
}

export function setNotifications(notifications) {
  return {
    type: '@messages/SET_NOTIFICATIONS',
    payload: { notifications },
  };
}

export function setNewNotifications(newNotifications) {
  return {
    type: '@messages/SET_NEWNOTIFICATIONS',
    payload: { newNotifications },
  };
}

export function readAllNotifications() {
  return {
    type: '@messages/READ_ALL_NOTIFICATIONS',
  };
}
export function sendTokenPN(token) {
  return {
    type: '@messages/SEND_TOKEN_PN',
    payload: { token },
  };
}
