export function addEventClinic(event) {
  return {
    type: '@clinicagenda/ADD_EVENT_CLINIC',
    payload: { event },
  };
}

export function deleteEventClinic(patientId, eventId, batch, date) {
  return {
    type: '@clinicagenda/DELETE_EVENT_CLINIC',
    payload: { patientId, eventId, batch, date },
  };
}

export function setScheduleClinic(schedule) {
  return {
    type: '@clinicagenda/SET_SCHEDULE_CLINIC',
    payload: { schedule },
  };
}

export function getScheduleClinic(month, year) {
  return {
    type: '@clinicagenda/GET_SCHEDULE_CLINIC',
    payload: { month, year },
  };
}

export function getScheduleClinicTwoMonth(
  monthInit,
  yearInit,
  monthEnd,
  yearEnd,
) {
  return {
    type: '@clinicagenda/GET_SCHEDULE_CLINIC_TWO_MONTH',
    payload: { monthInit, yearInit, monthEnd, yearEnd },
  };
}

export function eraseScheduleClinic() {
  return {
    type: '@clinicagenda/ERASE_SCHEDULE_CLINIC',
  };
}

export function eventStatusUpdate(
  eventId,
  patientId,
  status,
  month1,
  year1,
  month2,
  year2,
) {
  return {
    type: '@clinicagenda/EVENT_STATUS_UPDATE',
    payload: { eventId, patientId, status, month1, year1, month2, year2 },
  };
}

export function getConsultsData(value, isByProfissional, startDate, endDate) {
  return {
    type: '@clinicagenda/GET_CONSULTS_DATA',
    payload: { value, isByProfissional, startDate, endDate },
  };
}

export function setConsultsData(consultsData) {
  return {
    type: '@clinicagenda/SET_CONSULTS_DATA',
    payload: { consultsData },
  };
}

export function getMonthComparative(year) {
  return {
    type: '@clinicagenda/GET_MONTH_COMPARATIVE',
    payload: { year },
  };
}

export function setMonthComparative(comparativeMonth) {
  return {
    type: '@clinicagenda/SET_MONTH_COMPARATIVE',
    payload: { comparativeMonth },
  };
}
