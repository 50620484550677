import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { useParams } from 'react-router-dom';
import { getPatientDetails } from 'store/modules/patient/actions';
import {
  getExerciseCatalog,
  getProtocolsCreated,
} from 'store/modules/protocol/actions';
import { useDispatch, useSelector } from 'react-redux';
import AppHeader from 'components/Header';
import SiderMenu from 'components/Siders/Professional/Sider';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import ProtocolList from './components/ProtocolList';
import ProtocolSetup from './components/ProtocolSetup';
import FormContext from './components/Context/FormContext';

function PatientProtocol() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const patientDetails = useSelector(state => state.patient.patientDetails);
  const loadingPatient = useSelector(state => state.patient.loading);
  const exeCatalog = useSelector(
    state => state.protocol.patientExerciseCatalog,
  );

  useEffect(() => {
    dispatch(getPatientDetails(id));
  }, []);

  const [screen, setScreen] = useState(<ProtocolList />);

  function setScreenInProtocolList() {
    setScreen(<ProtocolList />);
  }

  function changeComponent(input, protocolId) {
    switch (input) {
      case 'default':
        setScreen(<ProtocolList />);
        break;
      case 'create':
        setScreen(
          <ProtocolSetup
            protocolId={protocolId}
            setScreenInProtocolList={setScreenInProtocolList}
          />,
        );
        break;
      case 'edit':
        setScreen(
          <ProtocolSetup
            protocolId={protocolId}
            setScreenInProtocolList={setScreenInProtocolList}
            edit
          />,
        );
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    dispatch(getPatientDetails(id));
    dispatch(getExerciseCatalog());
    dispatch(getProtocolsCreated(id));
  }, []);

  const firstChange = useMediaQuery('(min-width: 900px)');

  return (
    <Layout>
      <AppHeader />
      <SiderMenu>
        <div
          style={{
            marginLeft: firstChange ? '2rem' : '4rem',
            width: '100%',
            height: '100%',
          }}
        >
          {loadingPatient === false && patientDetails !== null && (
            <FormContext.Provider value={{ changeComponent, exeCatalog }}>
              {screen}
            </FormContext.Provider>
          )}
          {loadingPatient === true && patientDetails === null && (
            <div>Carregando ... </div>
          )}
        </div>
      </SiderMenu>
    </Layout>
  );
}

export default PatientProtocol;
