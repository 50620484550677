import { useDispatch } from 'react-redux';
import { Modal, Row, Col, Input, Button } from 'antd';

import { Formik } from 'formik';
import * as Yup from 'yup';

import closeIcon from 'assets/close.svg';
import { contactUsPayment } from 'store/modules/payment/actions';

import { useRef } from 'react';

import * as S from './styles';
import './styles.css';

const { TextArea } = Input;

export function ModalContactUs({ isModalVisible, handleCancel, handleOk }) {
  const dispatch = useDispatch();
  const formikRef = useRef();

  const formikGeneralData = {
    initialValues: {
      quantProfessionals: '',
      observations: '',
    },
    validationSchema: Yup.object({
      quantProfessionals: Yup.string().required('É obrigatório'),
      observations: Yup.string(),
    }),
  };

  return (
    <Formik
      initialValues={formikGeneralData.initialValues}
      validationSchema={formikGeneralData.validationSchema}
      innerRef={formikRef}
      onSubmit={values => {
        dispatch(
          contactUsPayment(values.quantProfessionals, values.observations),
        );
        handleOk();
      }}
    >
      {props => (
        <Modal
          title="Entre em contato"
          visible={isModalVisible}
          onOk={() => handleOk()}
          onCancel={() => handleCancel()}
          style={{
            borderRadius: '5px',
          }}
          centered
          bodyStyle={{ borderRadius: '20px' }}
          width={850}
          closeIcon={
            <img src={closeIcon} alt="close" style={S.AntdStyles.iconClose} />
          }
          footer={[
            <Button
              type="primary"
              onClick={() => props.resetForm()}
              style={{
                width: '180px',
                borderRadius: '5px',
                backgroundColor: 'transparent',
                borderColor: '#118E88',
                color: '#118E88',
              }}
            >
              Limpar Formulário
            </Button>,
            <Button
              type="primary"
              onClick={() => props.handleSubmit()}
              style={{
                width: '180px',
                borderRadius: '5px',
                backgroundColor: '#118E88',
                borderColor: '#118E88',
              }}
            >
              Enviar
            </Button>,
          ]}
        >
          <S.ContainerModal>
            <Row gutter={[32, 16]}>
              <Col className="gutter-row" span={24}>
                <S.LabelText className="input-label">
                  Quantos profissionais deseja ter?
                  <Input
                    style={S.AntdStyles.marginTop}
                    id="quantProfessionals"
                    name="quantProfessionals"
                    type="number"
                    onChange={props.handleChange}
                    value={props.values.quantProfessionals}
                    onBlur={props.handleBlur}
                  />
                </S.LabelText>
                {props.touched.quantProfessionals &&
                props.errors.quantProfessionals ? (
                  <S.ErrorMessage>
                    {props.errors.quantProfessionals}
                  </S.ErrorMessage>
                ) : null}
              </Col>

              <Col className="gutter-row" span={24}>
                <S.LabelText className="input-label">
                  Observações:
                  <TextArea
                    id="observations"
                    name="observations"
                    type="text"
                    rows={4}
                    style={{ ...S.AntdStyles.marginTop, minHeight: '100px' }}
                    value={props.values.observations}
                    onChange={e =>
                      props.setFieldValue('observations', e.target.value)
                    }
                  />
                </S.LabelText>
                {props.touched.observations && props.errors.observations ? (
                  <S.ErrorMessage>{props.errors.observations}</S.ErrorMessage>
                ) : null}
              </Col>
            </Row>
          </S.ContainerModal>
        </Modal>
      )}
    </Formik>
  );
}
