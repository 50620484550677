import React, { useContext, useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import { Empty } from 'antd';
import {
  newBarData,
  newDash,
  newComparisonFirstBarHorizontal,
  newComparisonSecondBarHorizontal,
  layoutHorizontalBarChart,
  responsiveLayout,
  responsiveLayoutTicks,
  responsiveLayoutFall,
  defaultLayout,
} from '../../../../../../../utils/graphsCfg';
import FinishedTask from '../../../../../../../assets/finished-task.svg';
import NotFinishedTask from '../../../../../../../assets/not-finished-task.svg';
import HalfFinishedTask from '../../../../../../../assets/half-finished-task.svg';

import FormContext from '../../../Context/FormContext';
import * as S from './styles';

export function ByPeriod() {
  const { monitoringData } = useContext(FormContext);

  const [barDataFreqGraph, setBarDataFreqGraph] = useState([]);
  const [layoutFrequency, setLayoutFrequency] = useState(defaultLayout);

  const [barLevalPainBegin, setBarLevelPainBegin] = useState([]);
  const [barLevalPainNow, setBarLevelPainNow] = useState([]);
  const [barDataLPGraph, setBarDataLPGraph] = useState([]);
  const [layoutLevelPain, setLayouLevelPain] = useState(defaultLayout);

  const [barLevelDiffBegin, setBarLevelDiffBegin] = useState([]);
  const [barLevalDiffNow, setBarLevelDiffNow] = useState([]);
  const [barDataDiffGraph, setBarDataDiffGraph] = useState([]);
  const [layoutLevelDiff, setLayouLevelDiff] = useState(defaultLayout);

  const [totalFall, setTotalFall] = useState([]);
  const [barDataFallGraph, setBarDataFallGraph] = useState([]);
  const [layoutLevelFall, setLayouLevelFall] = useState(defaultLayout);

  const [barLevelSecBegin, setBarLevelSecBegin] = useState([]);
  const [barLevelSecNow, setBarLevelSecNow] = useState([]);
  const [barDataSecGraph, setBarDataSecGraph] = useState([]);
  const [layoutLevelSec, setLayouLevelSec] = useState(defaultLayout);

  const [barLevelSatBegin, setBarLevelSatBegin] = useState([]);
  const [barLevelSatNow, setBarLevelSatNow] = useState([]);
  const [barDataSatGraph, setBarDataSatGraph] = useState([]);
  const [layoutLevelSat, setLayouLevelSat] = useState(defaultLayout);

  useEffect(() => {
    const monit = monitoringData.homeSessionStatistics;

    const freqGrid = [];
    freqGrid.push(newDash(0.1));
    freqGrid.push(newDash(4.9));
    freqGrid.push(newDash(10));

    const secondCompGrid = [];
    secondCompGrid.push(newDash(0.1));
    secondCompGrid.push(newDash(2));
    secondCompGrid.push(newDash(4));
    secondCompGrid.push(newDash(6));
    secondCompGrid.push(newDash(8));
    secondCompGrid.push(newDash(10));

    const gridFall = [];
    gridFall.push(newDash(0.01));
    gridFall.push(newDash(1));

    // FREQUENCY
    if (monit.frequencyHistory !== undefined) {
      const freqInfo = returnAllInfo(monit, 'frequencyHistory');
      setBarDataFreqGraph([...freqGrid, ...freqInfo.dataBar]);
      setLayoutFrequency(freqInfo.layout);
    }

    // PAIN LEVEL
    if (monit.painHistory !== undefined) {
      const pngInfo = returnAllInfo(monit, 'painHistory');
      setBarLevelPainBegin([...pngInfo.beginBar]);
      setBarLevelPainNow([...pngInfo.todayBar]);
      setBarDataLPGraph([...secondCompGrid, ...pngInfo.dataBar]);
      setLayouLevelPain(pngInfo.layout);
    }

    // DIFFICULTY LEVEL
    if (monit.difficultyHistory !== undefined) {
      const diffInfo = returnAllInfo(monit, 'difficultyHistory');
      setBarLevelDiffBegin([...diffInfo.beginBar]);
      setBarLevelDiffNow([...diffInfo.todayBar]);
      setBarDataDiffGraph([...secondCompGrid, ...diffInfo.dataBar]);
      setLayouLevelDiff(diffInfo.layout);
    }

    // FELL
    if (monit.fallingHistory !== undefined) {
      const fallInfo = returnAllInfo(monit, 'fallingHistory');
      setBarDataFallGraph([...gridFall, ...fallInfo.dataBar]);
      setTotalFall(monit.fallingTotal);
      setLayouLevelFall(fallInfo.layout);
    }

    // Seffety Level
    if (monit.safetyHistory !== undefined) {
      const safeInfo = returnAllInfo(monit, 'safetyHistory');
      setBarLevelSecBegin([...safeInfo.beginBar]);
      setBarLevelSecNow([...safeInfo.todayBar]);
      setBarDataSecGraph([...secondCompGrid, ...safeInfo.dataBar]);
      setLayouLevelSec(safeInfo.layout);
    }

    // Satisfaction layoutLevelPain
    if (monit.appreciationHistory !== undefined) {
      const satInfo = returnAllInfo(monit, 'appreciationHistory');
      setBarLevelSatBegin([...satInfo.beginBar]);
      setBarLevelSatNow([...satInfo.todayBar]);
      setBarDataSatGraph([...secondCompGrid, ...satInfo.dataBar]);
      setLayouLevelSat(satInfo.layout);
    }
  }, [monitoringData]);

  function returnAllInfo(monit, obj) {
    const lvlPain = monit[obj];
    const lpData = [];
    const tempLPDatas = [];
    const tempLPPositions = [];
    let bBar = 0;
    let lBar = 0;

    let cntLP = 0;
    const tmpArLP = [];
    for (let x = lvlPain.length - 1; x >= 0; x -= 1) {
      switch (obj) {
        case 'frequencyHistory':
          tmpArLP.push(lvlPain[x].Averagefrequency * 10);
          break;
        case 'painHistory':
          tmpArLP.push(lvlPain[x].AveragePain);
          // vai receber várias vezes mas vai ser sempre o mesmo valor
          bBar = monit.firstPain.AveragePain;
          lBar = monit.lastPain.AveragePain;
          break;
        case 'difficultyHistory':
          tmpArLP.push(lvlPain[x].AverageDifficulty);
          // vai receber várias vezes mas vai ser sempre o mesmo valor
          bBar = monit.firstDifficulty.AverageDifficulty;
          lBar = monit.lastDifficulty.AverageDifficulty;
          break;
        case 'fallingHistory':
          tmpArLP.push(lvlPain[x].AverageFalling);
          // vai receber várias vezes mas vai ser sempre o mesmo valor
          bBar = monit.firstFalling.AverageFalling;
          lBar = monit.lastFalling.AverageFalling;
          break;
        case 'safetyHistory':
          tmpArLP.push(lvlPain[x].AverageSafety);
          // vai receber várias vezes mas vai ser sempre o mesmo valor
          bBar = monit.firstSafety.AverageSafety;
          lBar = monit.lastSafety.AverageSafety;
          break;
        case 'appreciationHistory':
          tmpArLP.push(lvlPain[x].AverageAppreciation);
          // vai receber várias vezes mas vai ser sempre o mesmo valor
          bBar = monit.firstAppreciation.AverageAppreciation;
          lBar = monit.lastAppreciation.AverageAppreciation;
          break;
        default:
          break;
      }

      const tmpDt = lvlPain[x].date.split('-');

      tempLPDatas.push(`${tmpDt[2]}/${tmpDt[1]}`);
      tempLPPositions.push((cntLP + 1) * 16);
      if (cntLP === 12) break;
      cntLP += 1;
    }
    for (let x = 0; x < tmpArLP.length; x += 1) {
      lpData.push(newBarData((x + 1) * 16, tmpArLP[x]));
    }

    let layout = null;

    if (obj === 'fallingHistory') {
      layout = responsiveLayoutFall(tempLPPositions, tempLPDatas);
    } else if (obj === 'frequencyHistory') {
      layout = responsiveLayout(tempLPPositions, tempLPDatas);
    } else {
      layout = responsiveLayoutTicks(tempLPPositions, tempLPDatas);
    }

    // Gráficos de barra horizontais
    const firstBarLP = newComparisonFirstBarHorizontal(bBar, 'Início');
    const secondBarLP = newComparisonSecondBarHorizontal(10 - bBar, 'Início');
    const firstBarLPNow = newComparisonFirstBarHorizontal(lBar, 'Atualmente');
    const secondBarLPNow = newComparisonSecondBarHorizontal(
      10 - lBar,
      'Atualmente',
    );

    const finalDataLP = [firstBarLP, secondBarLP];

    const finalDataLPNow = [firstBarLPNow, secondBarLPNow];

    const finalObject = {
      beginBar: [...finalDataLP],
      todayBar: [...finalDataLPNow],
      dataBar: [...lpData],
      layout,
    };

    return finalObject;
  }

  return (
    <>
      <br />
      <S.TitleSession>Frequência</S.TitleSession>
      <br />

      {monitoringData.homeSessionStatistics?.frequencyHistory?.length > 0 ? (
        <>
          <S.ContainerChart>
            <S.ContainerImgs>
              <S.ImgGraphics src={FinishedTask} alt="atividade finalizada" />
              <S.ImgGraphics
                src={HalfFinishedTask}
                alt="atividade finalizada pela metade"
              />
              <S.ImgGraphics
                src={NotFinishedTask}
                alt="atividade nao finalizada"
              />
            </S.ContainerImgs>

            <Plot
              data={barDataFreqGraph}
              layout={layoutFrequency}
              config={{
                displayModeBar: false,
                staticPlot: true,
              }}
            />
          </S.ContainerChart>

          <br />

          <S.ContainerLegendsForGraphic>
            <body>
              <div>
                <S.ImgLegends src={FinishedTask} alt="atividade finalizada" />
                <label>Concluiu todas as atividades</label>
              </div>
              <div>
                <S.ImgLegends
                  src={HalfFinishedTask}
                  alt="atividade finalizada pela metade"
                />
                <label>Concluiu parcialmente</label>
              </div>
              <div>
                <S.ImgLegends
                  src={NotFinishedTask}
                  alt="atividade nao finalizada"
                />
                <label>Não concluiu nenhuma atividade</label>
              </div>
            </body>
          </S.ContainerLegendsForGraphic>

          {barDataLPGraph.length > 0 && (
            <>
              <br />
              <S.TitleSession>Nível de Dor</S.TitleSession>
              <br />

              <S.ContainerRotatedGraphic>
                <label>Início</label>
                <Plot
                  data={barLevalPainBegin}
                  layout={layoutHorizontalBarChart}
                  config={{ displayModeBar: false }}
                />
              </S.ContainerRotatedGraphic>

              <S.ContainerRotatedGraphic>
                <label>Atualmente</label>
                <Plot
                  data={barLevalPainNow}
                  layout={layoutHorizontalBarChart}
                  config={{ displayModeBar: false }}
                />
              </S.ContainerRotatedGraphic>
              <br />

              <Plot
                data={barDataLPGraph}
                layout={layoutLevelPain}
                config={{ displayModeBar: false }}
              />
            </>
          )}

          {barDataDiffGraph.length > 0 && (
            <>
              <br />
              <S.TitleSession>Nível de Dificuldade</S.TitleSession>
              <br />

              <S.ContainerRotatedGraphic>
                <label>Início</label>
                <Plot
                  data={barLevelDiffBegin}
                  layout={layoutHorizontalBarChart}
                  config={{ displayModeBar: false }}
                />
              </S.ContainerRotatedGraphic>

              <S.ContainerRotatedGraphic>
                <label>Atualmente</label>
                <Plot
                  data={barLevalDiffNow}
                  layout={layoutHorizontalBarChart}
                  config={{ displayModeBar: false }}
                />
              </S.ContainerRotatedGraphic>

              <br />

              <Plot
                data={barDataDiffGraph}
                layout={layoutLevelDiff}
                config={{ displayModeBar: false }}
              />
            </>
          )}

          {barDataFallGraph.length > 0 && (
            <>
              <br />
              <S.TitleSession>Quedas</S.TitleSession>
              <br />

              <S.ContainerRotatedGraphic>
                <label>Total</label>
                <label>{totalFall}</label>
              </S.ContainerRotatedGraphic>

              <br />

              <Plot
                data={barDataFallGraph}
                layout={layoutLevelFall}
                config={{ displayModeBar: false }}
              />
            </>
          )}

          {barDataSecGraph.length > 0 && (
            <>
              <br />
              <S.TitleSession>Nível de Segurança</S.TitleSession>
              <br />

              <S.ContainerRotatedGraphic>
                <label>Início</label>
                <Plot
                  data={barLevelSecBegin}
                  layout={layoutHorizontalBarChart}
                  config={{ displayModeBar: false }}
                />
              </S.ContainerRotatedGraphic>

              <S.ContainerRotatedGraphic>
                <label>Atualmente</label>
                <Plot
                  data={barLevelSecNow}
                  layout={layoutHorizontalBarChart}
                  config={{ displayModeBar: false }}
                />
              </S.ContainerRotatedGraphic>

              <br />

              <Plot
                data={barDataSecGraph}
                layout={layoutLevelSec}
                config={{ displayModeBar: false }}
              />
            </>
          )}

          {barDataSatGraph.length > 0 && (
            <>
              <br />
              <S.TitleSession>Nível de Satisfação</S.TitleSession>
              <br />

              <S.ContainerRotatedGraphic>
                <label>Início</label>
                <Plot
                  data={barLevelSatBegin}
                  layout={layoutHorizontalBarChart}
                  config={{ displayModeBar: false }}
                />
              </S.ContainerRotatedGraphic>

              <S.ContainerRotatedGraphic>
                <label>Atualmente</label>
                <Plot
                  data={barLevelSatNow}
                  layout={layoutHorizontalBarChart}
                  config={{ displayModeBar: false }}
                />
              </S.ContainerRotatedGraphic>

              <br />

              <Plot
                data={barDataSatGraph}
                layout={layoutLevelSat}
                config={{ displayModeBar: false }}
              />
            </>
          )}
        </>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: 200,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <label>Não houveram práticas domicilares finalizadas.</label>
            <Empty />
          </div>
        </>
      )}
    </>
  );
}
