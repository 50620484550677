import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { updateEvaluation } from 'store/modules/evaluations/actions';
import { useParams } from 'react-router-dom';
import TextAndRadioGroup from '../../components/TextAndRadioGroup';

import * as S from './styles';

function McGill({ title, current_eval }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const qts = current_eval?.content?.questions;

  const Questions = [
    'Grupo 1',
    'Grupo 2',
    'Grupo 3',
    'Grupo 4',
    'Grupo 5',
    'Grupo 6',
    'Grupo 7',
    'Grupo 8',
    'Grupo 9',
    'Grupo 10',
    'Grupo 11',
    'Grupo 12',
    'Grupo 13',
    'Grupo 14',
    'Grupo 15',
    'Grupo 16',
    'Grupo 17',
    'Grupo 18',
    'Grupo 19',
    'Grupo 20',
  ];

  const [formValues, setFormValues] = useState(
    current_eval?.content?.questions.length > 0
      ? Questions.map((item, index) => ({
          index: index + 1,
          text: item,
          value: current_eval.content.questions[index].value,
        }))
      : Questions.map((item, index) => ({
          index: index + 1,
          text: item,
          value: '',
        })),
  );

  function updateValues(isFinal) {
    const tempFormValue = {
      id: current_eval?.id,
      isFinished: isFinal,
      name: title,
      professionalId: current_eval?.professionalId,
      result: null,
      content: {
        questions: formValues,
      },
    };
    dispatch(updateEvaluation(tempFormValue, id));
    if (isFinal) {
      setFormValues(
        Questions.map((item, index) => ({
          index: index + 1,
          text: item,
          value: '',
        })),
      );
    }
  }

  useEffect(() => {
    const valuesFormValues = Object.values(formValues);
    if (valuesFormValues.find(item => item.value !== '')) {
      updateValues(false);
    }
  }, [formValues]);

  const forms = [
    { label: 'Vibração (1 ponto)   ', value: '1' },
    { label: 'Tremor (2 pontos)', value: '2' },
    { label: 'Pulsante (3 pontos)', value: '3' },
    { label: 'Latejante (4 pontos)', value: '4' },
    { label: 'Como batida (5 pontos)', value: '5' },
    { label: 'Como pancada (6 pontos)', value: '6' },
  ];

  const forms2 = [
    { label: 'Ponta (1 ponto)', value: '1' },
    { label: 'Choque (2 pontos)', value: '2' },
    { label: 'Tiro (3 pontos)', value: '3' },
  ];

  const forms3 = [
    { label: 'Agulhada (1 ponto)', value: '1' },
    { label: 'Perfurante (2 pontos)', value: '2' },
    { label: 'Facada (3 pontos)', value: '3' },
    { label: 'Punhalada (4 pontos)', value: '4' },
    { label: 'Em lança (5 pontos)', value: '5' },
  ];

  const forms4 = [
    { label: 'Fina (1 ponto)', value: '1' },
    { label: 'Cortante (2 pontos)', value: '2' },
    { label: 'Estraçalha (3 pontos)', value: '3' },
  ];

  const forms5 = [
    { label: 'Beliscão (1 ponto)', value: '1' },
    { label: 'Aperto (2 pontos)', value: '2' },
    { label: 'Mordida (3 pontos)', value: '3' },
    { label: 'Cólica (4 pontos)', value: '4' },
    { label: 'Esmagamento (5 pontos)', value: '5' },
  ];

  const forms6 = [
    { label: 'Fisgada (1 ponto)', value: '1' },
    { label: 'Puxão (2 pontos)', value: '2' },
    { label: 'Em torção (3 pontos)', value: '3' },
  ];

  const forms7 = [
    { label: 'Calor (1 ponto)', value: '1' },
    { label: 'Queimação (2 pontos)', value: '2' },
    { label: 'Fervente (3 pontos)', value: '3' },
    { label: 'Em brasa (4 pontos)', value: '4' },
  ];

  const forms8 = [
    { label: 'Formigamento (1 ponto)', value: '1' },
    { label: 'Coceira (2 pontos)', value: '2' },
    { label: 'Ardor (3 pontos)', value: '3' },
    { label: 'Ferroada (4 pontos)', value: '4' },
  ];

  const forms9 = [
    { label: 'Mal localizada (1 ponto)', value: '1' },
    { label: 'Dolorida (2 pontos)', value: '2' },
    { label: 'Machucada (3 pontos)', value: '3' },
    { label: 'Doída (4 pontos)', value: '4' },
    { label: 'Pesada (5 pontos)', value: '5' },
  ];

  const forms10 = [
    { label: 'Sensível (1 ponto)', value: '1' },
    { label: 'Esticada (2 pontos)', value: '2' },
    { label: 'Esfolante (3 pontos)', value: '3' },
    { label: 'Rachando (4 pontos)', value: '4' },
  ];

  const forms11 = [
    { label: 'Cansativa (1 ponto)', value: '1' },
    { label: 'Exaustiva (2 pontos)', value: '2' },
  ];

  const forms12 = [
    { label: 'Enjoada (1 ponto)', value: '1' },
    { label: 'Sufocante (2 pontos)', value: '2' },
  ];

  const forms13 = [
    { label: 'Amedrontadora (1 ponto)', value: '1' },
    { label: 'Apavorante (2 pontos)', value: '2' },
    { label: 'Aterrorizante (3 pontos)', value: '3' },
  ];

  const forms14 = [
    { label: 'Castigante (1 ponto)', value: '1' },
    { label: 'Atormenta (2 pontos)', value: '2' },
    { label: 'Cruel (3 pontos)', value: '3' },
    { label: 'Maldita (4 pontos)', value: '4' },
    { label: 'Mortal (5 pontos)', value: '5' },
  ];

  const forms15 = [
    { label: 'Miserável (1 ponto)', value: '1' },
    { label: 'Enlouquecedora (2 pontos)', value: '2' },
  ];

  const forms16 = [
    { label: 'Chata (1 ponto)', value: '1' },
    { label: 'Que incomoda (2 pontos)', value: '2' },
    { label: 'Desgastante (3 pontos)', value: '3' },
    { label: 'Forte (4 pontos)', value: '4' },
    { label: 'Insuportável (5 pontos)', value: '5' },
  ];

  const forms17 = [
    { label: 'Espalha (1 ponto)', value: '1' },
    { label: 'Irradia (2 pontos)', value: '2' },
    { label: 'Penetra (3 pontos)', value: '3' },
    { label: 'Atravessa (4 pontos)', value: '4' },
  ];

  const forms18 = [
    { label: 'Aperta (1 ponto)', value: '1' },
    { label: 'Adormece (2 pontos)', value: '2' },
    { label: 'Repuxa (3 pontos)', value: '3' },
    { label: 'Espreme (4 pontos)', value: '4' },
    { label: 'Rasga (5 pontos)', value: '5' },
  ];

  const forms19 = [
    { label: 'Fria (1 ponto)', value: '1' },
    { label: 'Gelada (2 pontos)', value: '2' },
    { label: 'Congelante (3 pontos)', value: '3' },
  ];

  const forms20 = [
    { label: 'Aborrecida (1 ponto)', value: '1' },
    { label: 'Dá náusea (2 pontos)', value: '2' },
    { label: 'Agonizante (3 pontos)', value: '3' },
    { label: 'Pavoroza (4 pontos)', value: '4' },
    { label: 'Torturante (5 pontos)', value: '5' },
  ];

  function returnForms(indexQuestion) {
    if (indexQuestion === 2) return forms2;
    if (indexQuestion === 3) return forms3;
    if (indexQuestion === 4) return forms4;
    if (indexQuestion === 5) return forms5;
    if (indexQuestion === 6) return forms6;
    if (indexQuestion === 7) return forms7;
    if (indexQuestion === 8) return forms8;
    if (indexQuestion === 9) return forms9;
    if (indexQuestion === 10) return forms10;
    if (indexQuestion === 11) return forms11;
    if (indexQuestion === 12) return forms12;
    if (indexQuestion === 13) return forms13;
    if (indexQuestion === 14) return forms14;
    if (indexQuestion === 15) return forms15;
    if (indexQuestion === 16) return forms16;
    if (indexQuestion === 17) return forms17;
    if (indexQuestion === 18) return forms18;
    if (indexQuestion === 19) return forms19;
    if (indexQuestion === 20) return forms20;
    return forms;
  }

  return (
    <Formik
      initialValues={{
        Answer1: qts[0] !== undefined ? qts[0].value : '',
        Answer2: qts[1] !== undefined ? qts[1].value : '',
        Answer3: qts[2] !== undefined ? qts[2].value : '',
        Answer4: qts[3] !== undefined ? qts[3].value : '',
        Answer5: qts[4] !== undefined ? qts[4].value : '',
        Answer6: qts[5] !== undefined ? qts[5].value : '',
        Answer7: qts[6] !== undefined ? qts[6].value : '',
        Answer8: qts[7] !== undefined ? qts[7].value : '',
        Answer9: qts[8] !== undefined ? qts[8].value : '',
        Answer10: qts[9] !== undefined ? qts[9].value : '',
        Answer11: qts[10] !== undefined ? qts[10].value : '',
        Answer12: qts[11] !== undefined ? qts[11].value : '',
        Answer13: qts[12] !== undefined ? qts[12].value : '',
        Answer14: qts[13] !== undefined ? qts[13].value : '',
        Answer15: qts[14] !== undefined ? qts[14].value : '',
        Answer16: qts[15] !== undefined ? qts[15].value : '',
        Answer17: qts[16] !== undefined ? qts[16].value : '',
        Answer18: qts[17] !== undefined ? qts[17].value : '',
        Answer19: qts[18] !== undefined ? qts[18].value : '',
        Answer20: qts[19] !== undefined ? qts[19].value : '',
      }}
      onSubmit={(values, actions) => {
        // eslint-disable-next-line prefer-const
        let valuesSubmit = {
          name: title,
          content: {
            questions: [],
          },
        };

        Questions.forEach((item, index) => {
          valuesSubmit.content.questions.push({
            index: index + 1,
            text: item,
            value: values[`Answer${index + 1}`],
          });
        });

        actions.resetForm({
          values: {
            Answer1: '',
            Answer2: '',
            Answer3: '',
            Answer4: '',
            Answer5: '',
            Answer6: '',
            Answer7: '',
            Answer8: '',
            Answer9: '',
            Answer10: '',
            Answer11: '',
            Answer12: '',
            Answer13: '',
            Answer14: '',
            Answer15: '',
            Answer16: '',
            Answer17: '',
            Answer18: '',
            Answer19: '',
            Answer20: '',
          },
        });

        updateValues(true);
      }}
    >
      {props => (
        <>
          {Questions.map(
            (item, index) =>
              item && (
                <TextAndRadioGroup
                  text={item}
                  ArrayValue={returnForms(index + 1)}
                  setAnswer={props.setFieldValue}
                  keyAnswer={`Answer${index + 1}`}
                  direction="vertical"
                  setFormValues={setFormValues}
                  formValues={formValues}
                  index={index}
                  currentValue={props.values[`Answer${index + 1}`]}
                  propsFormik={props}
                />
              ),
          )}

          <Button
            type="primary"
            htmlType="submit"
            onClick={props.handleSubmit}
            style={S.AntdStyles.ButtonSave}
          >
            Salvar
          </Button>
        </>
      )}
    </Formik>
  );
}

export default McGill;
