export const PropsTimesAWeekTranslated = [
  {
    type: 'ONE',
    pt: 'Uma',
  },
  {
    type: 'TWO',
    pt: 'Duas',
  },
  {
    type: 'THREE',
    pt: 'Três',
  },
  {
    type: 'FOUR',
    pt: 'Quatro ou mais',
  },
  {
    type: 'UNDEFINED',
    pt: 'Não Definido',
  },
];

export const PropsTimesAWeekEnum = {
  ONE: 'ONE',
  TWO: 'TWO',
  THREE: 'THREE',
  FOUR: 'FOUR',
};

export const TimesAWeekTranslated = times => {
  const result = PropsTimesAWeekTranslated.find(p => p.type === times).pt;
  return result;
};
