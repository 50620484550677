import styled from 'styled-components';

export const AntdStyles = {
  inputForm: {
    marginTop: '0.3rem',
    textAlign: 'left',
    font: 'normal normal normal 14px/19px Nunito Sans',
    letterSpacing: '0px',
    color: 'var(--jet)',
    display: 'flex',
  },

  buttonLogin: {
    background: 'var(--darkCyan) 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    marginTop: '1.2rem',
    width: '10rem',
  },

  buttonGoogle: {
    background: 'var(--white) 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    marginTop: '1.2rem',
    width: '14rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  buttonRegister: {
    background: 'var(--white) 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    marginTop: '1.2rem',
    width: '11rem',
    color: 'var(--darkBlueGray)',
    border: '1px solid var(--darkBlueGray)',
  },
};

export const LoginRight = styled.form`
  display: flex;
  background-color: var(--white);
  flex-direction: column;
  padding: 2.5rem 4rem 1.5rem 4rem;
  height: 100%;
  justify-content: space-evenly;
  width: 40%;

  @media (max-width: 950px) {
    width: 100%;
  }
`;

export const TextLogin = styled.text`
  color: var(--battleshipGrey);
  text-align: left;
  font: normal normal normal 14px/20px 'Nunito Sans';
  letter-spacing: 0px;
  color: #868686;
  opacity: 1;
`;

export const DescInput = styled.text`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal bold 14px/19px 'Nunito Sans';
  letter-spacing: 0px;
  color: #575757;
  opacity: 1;
  margin-top: 1rem;
`;

export const GoogleImg = styled.img`
  width: 16px;
`;

export const ButtonName = styled.strong`
  margin-left: 0.8rem;
`;

export const ErrorMessage = styled.label`
  color: #c12;
  font-size: 12px;
  margin-top: 2px;
`;

export const ReconverPassword = styled.u`
  display: flex;
  align-self: flex-end;
  cursor: pointer;
  color: var(--battleshipGrey);
  font: normal normal normal 14px/19px Nunito Sans;
`;
