/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Layout,
  Divider,
  Col,
  Row,
  Select,
  Checkbox,
  Button,
  Radio,
  Dropdown,
  Menu,
  Tooltip,
  Spin,
} from 'antd';
import { MoreOutlined, LoadingOutlined } from '@ant-design/icons';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import api from 'services/api';
import interactionPlugin from '@fullcalendar/interaction';

import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import AppHeader from 'components/Header';
import { getTherapists } from 'store/modules/clinic/actions';
import { refreshToken } from 'store/modules/auth/actions';
import listPlugin from '@fullcalendar/list';

import './styles.css';
import {
  getScheduleClinic,
  getScheduleClinicTwoMonth,
} from 'store/modules/clinicagenda/actions';
import FullCalendar from '@fullcalendar/react';
import locale from '@fullcalendar/core/locales/pt-br';
import { addHours, format, getMonth, getYear } from 'date-fns';
import pt from 'date-fns/locale/pt';
import { TitleAgendaEnum } from 'utils/titleAgendaConsults';
import { deleteEvent } from 'store/modules/agenda/actions';
import { getPatientsList } from 'store/modules/patient/actions';
import { DetailsEventModal } from './components/detailsEventModal';

import * as S from './styles';
import ImgFollow from '../../../assets/follow.svg';
import ArrowLeftGrey from '../../../assets/arrow-left-grey.svg';
import ArrowRightGrey from '../../../assets/arrow-right-grey.svg';
import { AddEventModal } from './components/addEventModal';
import EventComponent from './components/event';

export default function Schedule() {
  const dispatch = useDispatch();
  const history = useHistory();
  const firstChange = useMediaQuery('(min-width: 1230px)');
  const secondChange = useMediaQuery('(min-width: 960px)');
  const thirdChange = useMediaQuery('(min-width: 840px)');
  const fourthChange = useMediaQuery('(min-width: 630px)');
  const professionalList = useSelector(state => state.clinic.therapists);
  const refCalendar = useRef(null);
  const location = useLocation();
  const searchDefault = location?.state?.search;
  const [selectedConsultsType, setSelectedConsultsType] = useState([
    'PRESENTIAL',
    'INLIVE',
  ]);
  const [daysQuantity, setDaysQuantity] = useState(7);
  const [selectedDate, setSelectedDate] = useState(
    format(new Date(), 'PPP', {
      locale: pt,
    }),
  );
  const [dayText, setDayText] = useState(
    format(new Date(), 'cccc', {
      locale: pt,
    }),
  );

  // Quando o token expira
  api.interceptors.response.use(
    response => response,
    error => {
      if (error.message === 'Request failed with status code 401') {
        dispatch(refreshToken());
      }
      Promise.reject(error);
    },
  );

  const dataAgenda = useSelector(state => state.clinicagenda.schedule);
  const loadingAgenda = useSelector(state => state.clinicagenda.loading);
  const renderDataAgenda = dataAgenda.map(
    item =>
      item && {
        title: item.title,
        start: new Date(item.timestamp),
        end: new Date(item.timestamp + item.duration * 60000),
        details: item.details,
        local: item.local,
        eventId: item.id,
        patientId: item.patientId,
        professionalName: item.professionalName,
        horary: format(new Date(item.timestamp), 'HH:mm', {
          locale: pt,
        }),
        patientName: item.patientName,
        durationTime: item.duration,
        status: item?.status,
      },
  );

  const [infoCalendar, setInfoCalendar] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [detailsIsModalVisible, setDetailsIsModalVisible] = useState(false);
  const [dayHeaderBool, setDayHeaderBool] = useState(false);
  const [time, setTime] = useState('day');
  const [eventProps, setEventProps] = useState(null);
  const [eventInfoProps, setEventInfoProps] = useState(null);

  const [professionalName, setProfessionalName] = useState(
    searchDefault || 'Exibir Todos',
  );
  const [professionalNameList, setProfessionalNameList] = useState([
    { professionalName: 'Exibir Todos' },
  ]);

  useEffect(() => {
    if (professionalName === 'Exibir Todos' && time === 'week') {
      setTime('day');
    }
  }, [professionalName]);

  const handleScheduleDates = (dateInit, dateEnd) => {
    // meses
    const selectedInitMonth = getMonth(addHours(dateInit, 12)) + 1;
    const selectedEndMonth = getMonth(addHours(dateEnd, 12)) + 1;

    // anos
    const selectedInitYear = getYear(addHours(dateInit, 12));
    const selectedEndYear = getYear(addHours(dateEnd, 12));

    const selectedDateInit = `${selectedInitMonth}/${selectedInitYear}`;
    const selectedDateEnd = `${selectedEndMonth}/${selectedEndYear}`;

    if (selectedDateInit !== selectedDateEnd) {
      dispatch(
        getScheduleClinicTwoMonth(
          selectedInitMonth,
          selectedInitYear,
          selectedEndMonth,
          selectedEndYear,
        ),
      );
    } else {
      dispatch(getScheduleClinic(selectedInitMonth, selectedInitYear));
    }
  };

  function handleNextPrev(move) {
    if (move === 'next') {
      refCalendar.current.getApi().next();
    } else {
      refCalendar.current.getApi().prev();
    }

    setSelectedDate(
      refCalendar.current.getApi().currentDataManager.data.viewTitle,
    );
    const dateInit = refCalendar.current.getApi().currentDataManager.data
      .dateProfile.currentRange.start;
    const dateEnd = refCalendar.current.getApi().currentDataManager.data
      .dateProfile.currentRange.end;

    handleScheduleDates(dateInit, dateEnd);
  }

  const handleEventClick = event => {
    setEventProps(event.event);
    setEventInfoProps(event);
    setDetailsIsModalVisible(true);
  };

  useEffect(() => {
    dispatch(getScheduleClinic(getMonth(new Date()) + 1, getYear(new Date())));
  }, []);

  useEffect(() => {
    dispatch(getTherapists());
  }, [dispatch]);

  useEffect(() => {
    if (
      dataAgenda !== null &&
      dataAgenda.length > 0 &&
      infoCalendar.length !== dataAgenda.length
    ) {
      const newDateAgenda = dataAgenda.map(
        item =>
          item && {
            title: item.title,
            start: new Date(item.timestamp),
            end: new Date(item.timestamp + item.duration * 60000),
            details: item.details || '',
            local: item.local || '',
            eventId: item.id || '',
            patientId: item.patientId || '',
            professionalName: item.professionalName,
            horary: format(new Date(item.timestamp), 'HH:mm', {
              locale: pt,
            }),
            patientName: item.patientName || '',
            durationTime: item.duration,
            status: item?.status || 'PENDING',
          },
      );

      setInfoCalendar(newDateAgenda);
    }
  }, [dataAgenda]);

  useEffect(() => {
    if (time === 'week') {
      refCalendar.current.getApi().changeView('timeGridResponsiveDay');
      setDayText('');
      setDayHeaderBool(true);
    } else {
      setDayHeaderBool(false);
      refCalendar.current.getApi().changeView('timeGridDay');
      setDayText(
        format(
          addHours(
            refCalendar.current.getApi().currentDataManager.state.currentDate,
            2,
          ),
          'cccc',
          {
            locale: pt,
          },
        ),
      );
    }
    setSelectedDate(
      refCalendar.current.getApi().currentDataManager.data.viewTitle,
    );
  }, [time]);

  useEffect(() => {
    if (time === 'day') {
      setDayText(
        format(
          addHours(
            refCalendar.current.getApi().currentDataManager.state.currentDate,
            3,
          ),
          'cccc',
          {
            locale: pt,
          },
        ),
      );
    }
  }, [selectedDate]);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  useEffect(() => {
    if (professionalList !== null && professionalList.length !== 0) {
      let newList = [];
      newList = [{ professionalName: 'Exibir Todos' }].concat(professionalList);

      setProfessionalNameList(newList);

      if (searchDefault) {
        changeTextFilter(searchDefault, selectedConsultsType);
      }
    }
  }, [professionalList]);

  function handleCancel() {
    setIsModalVisible(false);
  }

  function handleOk() {
    setIsModalVisible(false);
  }

  function handleDetailsCancel() {
    setDetailsIsModalVisible(false);
  }

  function handleDetailsOk() {
    setDetailsIsModalVisible(false);
  }

  // filtro
  function changeTextFilter(filterProfessional, situationConsult) {
    let newListSchedule = [];

    if (
      filterProfessional === 'Exibir Todos' &&
      (situationConsult.length === 2 || situationConsult.length === 0)
    ) {
      newListSchedule = renderDataAgenda;
    }

    if (
      filterProfessional === 'Exibir Todos' &&
      situationConsult.length === 1
    ) {
      newListSchedule = renderDataAgenda.filter(item =>
        item.title.includes(situationConsult[0]),
      );
    }

    if (
      filterProfessional !== 'Exibir Todos' &&
      (situationConsult.length === 2 || situationConsult.length === 0)
    ) {
      newListSchedule = renderDataAgenda.filter(item =>
        item.professionalName.includes(filterProfessional),
      );
    }

    if (
      filterProfessional !== 'Exibir Todos' &&
      situationConsult.length === 1
    ) {
      newListSchedule = renderDataAgenda.filter(
        item =>
          item.title.includes(situationConsult[0]) &&
          item.professionalName.includes(filterProfessional),
      );
    }

    setInfoCalendar(newListSchedule);
  }

  useEffect(() => {
    if (firstChange === false) {
      setDaysQuantity(6);
      if (time === 'week') {
        refCalendar.current.getApi().changeView('timeGridResponsiveDay');
      } else {
        refCalendar.current.getApi().changeView('timeGridDay');
      }
    }
    if (secondChange === false) {
      setDaysQuantity(5);
      if (time === 'week') {
        refCalendar.current.getApi().changeView('timeGridResponsiveDay');
      } else {
        refCalendar.current.getApi().changeView('timeGridDay');
      }
    }
    if (thirdChange === false) {
      setDaysQuantity(4);
      if (time === 'week') {
        refCalendar.current.getApi().changeView('timeGridResponsiveDay');
      } else {
        refCalendar.current.getApi().changeView('timeGridDay');
      }
    }
    if (
      firstChange === true &&
      secondChange === true &&
      thirdChange === false
    ) {
      setDaysQuantity(4);
      if (time === 'week') {
        refCalendar.current.getApi().changeView('timeGridResponsiveDay');
      } else {
        refCalendar.current.getApi().changeView('timeGridDay');
      }
    }
    if (fourthChange === false) {
      refCalendar.current.getApi().changeView('listMonth');
      setDayText('');
      setSelectedDate(
        refCalendar.current.getApi().currentDataManager.data.viewTitle,
      );
      setTime('day');
    }
    if (firstChange === true && secondChange === true && thirdChange === true) {
      setDaysQuantity(7);
    }
  }, [
    firstChange,
    secondChange,
    thirdChange,
    fourthChange,
    selectedDate,
    infoCalendar,
  ]);

  const plainOptions = [
    { label: 'Presenciais', value: 'PRESENTIAL' },
    { label: 'Via Telechamada', value: 'INLIVE' },
  ];

  function onChange(checkedValues) {
    setSelectedConsultsType(checkedValues);
    changeTextFilter(professionalName, checkedValues);
  }

  function renderEventContent(eventInfo) {
    return (
      <EventComponent
        time={time}
        dayText={dayText}
        selectColorForEventCalendar={selectColorForEventCalendar}
        eventInfo={eventInfo}
        handleEventClick={handleEventClick}
      />
    );
  }

  function selectColorForEventCalendar(title) {
    if (title === TitleAgendaEnum.DOMICILIAR) {
      return '#79E5FF';
    }

    if (title === TitleAgendaEnum.PRESENCIAL) {
      return '#FFC465';
    }

    if (title === TitleAgendaEnum.TELECHAMADA) {
      return '#FFA8E4';
    }

    return '#FFC465';
  }

  const handleProfessionalSelected = () => {
    if (professionalName === 'Exibir Todos') return true;
    return false;
  };

  return (
    <Layout>
      <AppHeader />
      <AddEventModal
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <DetailsEventModal
        isModalVisible={detailsIsModalVisible}
        handleOk={handleDetailsOk}
        handleCancel={handleDetailsCancel}
        event={eventProps}
        eventInfo={eventInfoProps}
      />
      <S.Container mediaFirst={firstChange} mediaThird={thirdChange}>
        <Col style={{ width: '100%' }}>
          <S.TitlePage>Consultas</S.TitlePage>
          <Row style={{ marginTop: '2rem' }}>
            <Col span={8} style={{ marginTop: '0.5rem' }}>
              <S.LabelText>Filtrar por profissional</S.LabelText>
              <Select
                showSearch
                style={{
                  width: '100%',
                  maxHeight: 50,
                  marginTop: '0.5rem',
                  marginLeft: '3px',
                }}
                options={professionalNameList.map(
                  item =>
                    item && {
                      value: item.professionalName,
                    },
                )}
                placeholder=""
                filterOption={(inputValue, option) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                value={professionalName}
                onChange={name => {
                  setProfessionalName(name);
                  changeTextFilter(name, selectedConsultsType);
                }}
              />
            </Col>
            <Col
              span={6}
              style={{
                marginTop: '0.5rem',
                marginLeft: '2rem',
              }}
            >
              <S.LabelText>Exibir Consultas</S.LabelText>
              <Checkbox.Group
                options={plainOptions}
                defaultValue={['INLIVE', 'PRESENTIAL']}
                onChange={onChange}
                style={{
                  maxHeight: 50,
                  marginTop: '0.75rem',
                  marginLeft: '3px',
                  fontFamily: 'Nunito Sans',
                }}
              />
            </Col>
            <Row>
              <Button
                type="primary"
                icon={<S.ImageRegisterData src={ImgFollow} />}
                style={{
                  width: '180px',
                  borderRadius: '5px',
                  backgroundColor: 'transparent',
                  borderColor: '#118E88',
                  color: '#118E88',
                  marginRight: '1rem',
                  marginTop: '2rem',
                }}
                onClick={() => {
                  history.push(`/data_consults`);
                }}
              >
                Dados da Consulta
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  setIsModalVisible(true);
                }}
                style={{
                  width: '180px',
                  borderRadius: '5px',
                  backgroundColor: '#118E88',
                  borderColor: '#118E88',
                  marginTop: '2rem',
                }}
              >
                Agendar Consulta
              </Button>
            </Row>
          </Row>
          <Divider style={{ marginTop: '2.5rem' }} />
          <Row style={{ width: '100%', justifyContent: 'space-between' }}>
            {fourthChange === true ? (
              <Radio.Group value="Dia" onChange={e => setTime(e.target.value)}>
                <Radio.Button
                  value="day"
                  style={
                    time === 'day'
                      ? S.AntdStyles.RadioButtonActiveAble
                      : S.AntdStyles.RadioButtonActiveDisable
                  }
                >
                  Dia
                </Radio.Button>
                <Tooltip
                  title={
                    handleProfessionalSelected()
                      ? 'Selecione um Profissional'
                      : ''
                  }
                >
                  <Radio.Button
                    value={professionalName === 'Exibir Todos' ? 'day' : 'week'}
                    style={
                      time === 'week'
                        ? S.AntdStyles.RadioButtonArchivedAble
                        : {
                            ...S.AntdStyles.RadioButtonArchivedDisable,
                            backgroundColor: handleProfessionalSelected()
                              ? '#EAEAEA'
                              : '#f7f7f700',
                            cursor: handleProfessionalSelected()
                              ? 'not-allowed'
                              : 'pointer',
                          }
                    }
                  >
                    Semana
                  </Radio.Button>
                </Tooltip>
              </Radio.Group>
            ) : null}

            <Row style={{ marginTop: 10 }}>
              <S.InliveSquare />
              <S.LabelTitleTable>Presencial</S.LabelTitleTable>
              <S.PresentialSquare />
              <S.LabelTitleTable>Sessão Via Telechamada</S.LabelTitleTable>
            </Row>
          </Row>
          <Row
            style={{
              marginTop: '3rem',
              marginBottom: '1rem',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <S.LabelText>{selectedDate}</S.LabelText>
            <S.TextFilters style={{ marginLeft: '6px' }}>
              {' '}
              {dayText !== ''
                ? `| ${dayText[0].toUpperCase() + dayText.substring(1)}`
                : ''}
            </S.TextFilters>
            <Button
              type="primary"
              style={{
                width: '50px',
                borderTopLeftRadius: '5px',
                borderBottomLeftRadius: '5px',
                backgroundColor: 'transparent',
                borderRightColor: '#D5D5D5',
                borderColor: '#868686',
                marginLeft: '1.4rem',
                marginTop: '-0.4rem',
              }}
              icon={<S.ImageBackFrontData src={ArrowLeftGrey} />}
              onClick={() => {
                handleNextPrev('prev');
              }}
            />
            <Button
              type="primary"
              style={{
                width: '50px',
                borderTopRightRadius: '5px',
                borderBottomRightRadius: '5px',
                borderLeftColor: '#D5D5D5',
                backgroundColor: 'transparent',
                borderColor: '#868686',
                marginTop: '-0.4rem',
              }}
              icon={<S.ImageBackFrontData src={ArrowRightGrey} />}
              onClick={() => {
                handleNextPrev('next');
              }}
            />
            {(loadingAgenda === true || infoCalendar === null) && (
              <Spin
                indicator={antIcon}
                size="small"
                style={{ margin: '0 0 0.5rem 1rem' }}
              />
            )}
          </Row>
          <div style={{ height: '30rem', overflowY: 'scroll' }}>
            <FullCalendar
              plugins={[
                timeGridPlugin,
                dayGridPlugin,
                listPlugin,
                interactionPlugin,
              ]}
              headerToolbar={false}
              locale={locale}
              ref={refCalendar}
              defaultValue={new Date()}
              contentHeight="130rem"
              nowIndicator={false}
              allDaySlot={false}
              eventDisplay="block"
              slotDuration="00:30:00"
              fixedWeekCount
              fixedMirrorParent
              stickyHeaderDates
              views={{
                timeGridResponsiveDay: {
                  type: 'timeGrid',
                  duration: { days: daysQuantity },
                },
              }}
              dayHeaderContent={event => (
                <div>
                  <h style={{ textTransform: 'capitalize' }}>{event.text}</h>
                </div>
              )}
              slotLabelContent={event => (
                <div
                  style={{
                    height: '6px',
                    width: '30px',
                    fontSize: '10px',
                  }}
                >
                  <h1 style={{ fontSize: 12, color: '#868686' }}>
                    {event.text}
                  </h1>
                </div>
              )}
              slotLabelInterval="00:30"
              dayHeaders={dayHeaderBool}
              slotEventOverlap={false}
              expandRows
              dayCellContent={() => <div style={{ width: '10px' }} />}
              eventBackgroundColor="#fff"
              eventBorderColor="#fff"
              eventContent={renderEventContent}
              slotLabelFormat={{
                hour: '2-digit',
                minute: '2-digit',
                omitZeroMinute: false,
                meridiem: 'short',
              }}
              events={infoCalendar} // alternatively, use the `events` setting to fetch from a feed
            />
          </div>
        </Col>
      </S.Container>
    </Layout>
  );
}
