import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Button } from 'antd';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateEvaluation } from 'store/modules/evaluations/actions';
import TextAndRadioGroup from '../../components/TextAndRadioGroup';
import * as S from './styles';

function ABC({ title, current_eval }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const qts = current_eval?.content?.questions;

  const forms = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
    { label: '10', value: '10' },
  ];

  const Questions = [
    '1. Andar pela casa',
    '2. Subir ou descer escada',
    '3. Abaixar-se para pegar um objeto no chão',
    '4. Pegar uma latinha na prateleira na altura dos olhos',
    '5. Ficar na ponta dos pés para pegar algum objeto acima da cabeça',
    '6. Subir em uma cadeira para pegar algo',
    '7. Varrer o chão',
    '8. Sair de casa e andar até um carro ou ônibus parado em frente',
    '9. Entrar ou sair de um carro',
    '10. Atravessar um estacionamento de um supermercado ou shopping',
    '11. Subir ou descer uma rampa',
    '12. Andar em um lugar movimentado onde as pessoas passavam rapidamento por você',
    '13. Esbarrarem em você em um lugar movimentado, cheio de gente',
    '14. Pegar ou sair de uma escada rolante segurando no corrimão',
    '15. Pegar ou sair de uma escada rolante carregando pacotes e sacolas que o(a) impedem de segurar o corrimão',
    '16. Andar em uma calçada molhada ou escorregadia',
  ];

  const [formValues, setFormValues] = useState(
    current_eval?.content?.questions.length > 0
      ? Questions.map((item, index) => ({
          index: index + 1,
          text: item,
          value: current_eval.content.questions[index].value,
        }))
      : Questions.map((item, index) => ({
          index: index + 1,
          text: item,
          value: '',
        })),
  );

  function updateValues(isFinal) {
    const tempFormValue = {
      id: current_eval?.id,
      isFinished: isFinal,
      name: title,
      professionalId: current_eval?.professionalId,
      result: null,
      content: {
        questions: formValues,
      },
    };
    dispatch(updateEvaluation(tempFormValue, id));
    if (isFinal) {
      setFormValues(
        Questions.map((item, index) => ({
          index: index + 1,
          text: item,
          value: '',
        })),
      );
    }
  }

  useEffect(() => {
    const valuesFormValues = Object.values(formValues);
    if (valuesFormValues.find(item => item.value !== '')) {
      updateValues(false);
    }
  }, [formValues]);

  return (
    <Formik
      initialValues={{
        Answer1: qts[0] !== undefined ? qts[0].value : '',
        Answer2: qts[1] !== undefined ? qts[1].value : '',
        Answer3: qts[2] !== undefined ? qts[2].value : '',
        Answer4: qts[3] !== undefined ? qts[3].value : '',
        Answer5: qts[4] !== undefined ? qts[4].value : '',
        Answer6: qts[5] !== undefined ? qts[5].value : '',
        Answer7: qts[6] !== undefined ? qts[6].value : '',
        Answer8: qts[7] !== undefined ? qts[7].value : '',
        Answer9: qts[8] !== undefined ? qts[8].value : '',
        Answer10: qts[9] !== undefined ? qts[9].value : '',
        Answer11: qts[10] !== undefined ? qts[10].value : '',
        Answer12: qts[11] !== undefined ? qts[11].value : '',
        Answer13: qts[12] !== undefined ? qts[12].value : '',
        Answer14: qts[13] !== undefined ? qts[13].value : '',
        Answer15: qts[14] !== undefined ? qts[14].value : '',
        Answer16: qts[15] !== undefined ? qts[15].value : '',
      }}
      onSubmit={(values, actions) => {
        // eslint-disable-next-line prefer-const
        let valuesSubmit = {
          name: title,
          content: {
            questions: [],
          },
        };

        Questions.forEach((item, index) => {
          valuesSubmit.content.questions.push({
            index: index + 1,
            text: item,
            value: values[`Answer${index + 1}`],
          });
        });

        actions.resetForm({
          values: {
            Answer1: '',
            Answer2: '',
            Answer3: '',
            Answer4: '',
            Answer5: '',
            Answer6: '',
            Answer7: '',
            Answer8: '',
            Answer9: '',
            Answer10: '',
            Answer11: '',
            Answer12: '',
            Answer13: '',
            Answer14: '',
            Answer15: '',
            Answer16: '',
          },
        });

        updateValues(true);
      }}
    >
      {props => (
        <>
          {Questions.map(
            (item, index) =>
              item && (
                <TextAndRadioGroup
                  text={item}
                  ArrayValue={forms}
                  setAnswer={props.setFieldValue}
                  keyAnswer={`Answer${index + 1}`}
                  direction="horizontal"
                  setFormValues={setFormValues}
                  formValues={formValues}
                  index={index}
                  currentValue={props.values[`Answer${index + 1}`]}
                  propsFormik={props}
                />
              ),
          )}

          <Button
            type="primary"
            htmlType="submit"
            onClick={props.handleSubmit}
            style={S.AntdStyles.ButtonSave}
          >
            Salvar
          </Button>
        </>
      )}
    </Formik>
  );
}

export default ABC;
