import { takeLatest, call, all, put } from 'redux-saga/effects';
import { notification } from 'antd';

import api from '../../../services/api';

import { setExerciseCatalog, setProtocolsCreated } from './actions';

export function* getExerciseCatalog() {
  try {
    const response = yield call(api.get, `/exercise_catalog`);
    yield put(setExerciseCatalog(response.data.catalog));
  } catch (err) {
    notification.error({
      message:
        'Houve um erro ao buscar o catalogo de exercícios, tente novamente!',
    });
  }
}

export function* saveProtocol({ payload }) {
  try {
    const { patientId } = payload;
    const { addedActToProtocol } = payload;
    const { name } = payload;
    const { type } = payload;
    const { protId } = payload;
    const currentType = type.domPract.enable;

    if (name !== '') {
      if (addedActToProtocol.length > 0) {
        yield call(api.post, `/protocol`, {
          protocol: {
            id: protId,
            name,
            type: currentType === true ? 'DOMESTIC' : 'INLIVE',
            exercises: addedActToProtocol,
            patientsId: [patientId],
          },
        });

        const response = yield call(api.get, `/patient/${patientId}/protocols`);
        yield put(setProtocolsCreated(response.data.protocols));
        notification.success({
          message: 'Protocolo salvo com sucesso!',
        });
      } else {
        notification.error({
          message:
            'Adicione exercícios ao Protocolo e clique em Criar Prática!',
        });
      }
    } else {
      notification.error({
        message:
          'Insira o nome do Protocolo a ser salvo e clique em Criar Prática!',
      });
    }
  } catch (err) {
    notification.error({
      message: 'Houve um erro ao tentar salvar o protocolo, tente novamente!',
    });
  }
}

export function* getProtocolsCreated({ payload }) {
  try {
    const { patientId } = payload;
    const response = yield call(api.get, `/patient/${patientId}/protocols`);
    yield put(setProtocolsCreated(response.data.protocols));
  } catch (err) {
    notification.error({
      message:
        'Houve um erro ao buscar os protocolo criados para este paciente, tente novamente recarregando esta página!',
    });
  }
}

export function* unlinkProtocol({ payload }) {
  try {
    const { patientId } = payload;
    const { protocolId } = payload;

    const responseUnlink = yield call(api.put, `/protocol/unlink`, {
      patientId,
      protocolId,
    });

    if (responseUnlink.data.results === 'Protocolo desvinculado do paciente') {
      notification.success({
        message: 'Protocolo desvinculado com sucesso.',
      });

      const response = yield call(api.get, `/patient/${patientId}/protocols`);
      yield put(setProtocolsCreated(response.data.protocols));
    }

    // eslint-disable-next-line no-empty
  } catch (err) {}
}

export default all([
  takeLatest('@protocol/GET_EXERCISE_CATALOG', getExerciseCatalog),
  takeLatest('@protocol/SAVE_PROTOCOL', saveProtocol),
  takeLatest('@protocol/GET_PROTOCOLS_CREATED', getProtocolsCreated),
  takeLatest('@protocol/UNLINK_PROTOCOL', unlinkProtocol),
]);
