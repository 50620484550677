import styled from 'styled-components/';

export const ImageMonitoreLogoHeader = styled.img`
  width: 25%;
  min-width: 300px;
  @media (min-width: 990px) {
    display: flex;
    padding-left: 4rem;
  }

  @media (max-width: 990px) {
    display: none;
    padding-left: 1rem;
  }
`;

export const AntdStyles = {
  header: {
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
    zIndex: 5,
    width: '100%',
    background: '#118E88',
    borderBottom: '4px solid',
    borderColor: '#55bfb8',
  },
};
