/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  notification,
  Input,
  Checkbox,
  AutoComplete,
  Button,
  Row,
  Col,
  TimePicker,
} from 'antd';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { PropsSpecialityEnum, SpecialityTranslated } from 'utils/speciality';
import {
  PropsProfessionEnum,
  PropsProfessionArrayWithoutOther,
  ProfessionTranslated,
} from 'utils/profession';
import { updateUser } from 'store/modules/auth/actions';
import moment from 'moment';
import { maskBuilderCPFCNPJ } from 'utils/maskValidator';
import { PropsProfileEnum } from 'utils/profile';
import ModalSpeciality from '../ModalSpeciality';
import * as S from './styles';

function DataGeneralReceptionist({ secondChange }) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const isClinic = useSelector(
    state =>
      state.auth?.user?.active_profile_name !== PropsProfileEnum.THERAPIST,
  );
  const isAdmin = useSelector(state => state.auth.isAdmin);
  const isMyAccountView = useSelector(state => state.auth.isMyAccountView);
  const clinic = useSelector(state => state.clinic.self);

  const optionsWeek = [
    { label: 'Segundas', value: 1 },
    { label: 'Terças', value: 2 },
    { label: 'Quartas', value: 3 },
    { label: 'Quintas', value: 4 },
    { label: 'Sextas', value: 5 },
    { label: 'Sábados', value: 6 },
    { label: 'Domingos', value: 7 },
  ];

  const formikGeneralDataReceptionist = {
    initialValues: {
      name: user?.name,
      email: user?.email,
      document: user?.document,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('É obrigatório'),
      email: Yup.string().required('É obrigatório'),
      document: Yup.string().required('É obrigatório'),
    }),
    onSubmit: values => {
      const userSubmit = values;

      dispatch(updateUser(userSubmit));
    },
  };

  Yup.addMethod(Yup.string, 'defaultType', function (errorMessage) {
    return this.test(`test-default-type`, errorMessage, function (value) {
      const { path, createError } = this;

      return (
        value !== 'Selecione' || createError({ path, message: errorMessage })
      );
    });
  });

  return isMyAccountView ? (
    <Formik
      initialValues={formikGeneralDataReceptionist.initialValues}
      validationSchema={formikGeneralDataReceptionist.validationSchema}
      onSubmit={values => formikGeneralDataReceptionist.onSubmit(values)}
    >
      {props => (
        <div>
          <S.TitleSession>Dados Gerais</S.TitleSession>

          <Row gutter={[32, 16]} style={S.AntdStyles.marginTopRem}>
            <Col span={secondChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Nome
                <Input
                  style={S.AntdStyles.marginTop}
                  id="name"
                  name="name"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.name}
                  onBlur={props.handleBlur}
                />
                {props.touched.name && props.errors.name ? (
                  <S.ErrorMessage>{props.errors.name}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col span={secondChange ? 8 : 24}>
              <S.LabelText>E-mail</S.LabelText>
              <Input
                placeholder="exemplo@exemplo.com.br"
                style={S.AntdStyles.marginTop}
                id="email"
                name="email"
                type="email"
                onChange={props.handleChange}
                value={props.values.email}
                onBlur={props.handleBlur}
                disabled
              />
              {props.touched.email && props.errors.email ? (
                <S.ErrorMessage>{props.errors.email}</S.ErrorMessage>
              ) : null}
            </Col>

            <Col className="gutter-row" span={secondChange ? 8 : 24}>
              <S.LabelText className="input-label">
                CPF ou CNPJ
                <InputMask
                  mask={maskBuilderCPFCNPJ(props.values.document)}
                  style={S.AntdStyles.inputMask}
                  value={props.values.document}
                  onChange={e =>
                    props.setFieldValue(
                      'document',
                      e.target.value.replace(/\D/g, ''),
                    )
                  }
                />
                {props.touched.document && props.errors.document ? (
                  <S.ErrorMessage>{props.errors.document}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>
          </Row>

          <Button
            type="primary"
            htmlType="submit"
            style={S.AntdStyles.ButtonSave}
            onClick={props.handleSubmit}
          >
            Salvar Alterações
          </Button>
        </div>
      )}
    </Formik>
  ) : null;
}

export default DataGeneralReceptionist;
