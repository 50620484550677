import styled from 'styled-components';

export const TitleSiders = styled.h3`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal bold 14px/18px Nunito Sans;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 1rem;
`;

export const TitleProgress = styled.h3`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal bold 12px/18px Nunito Sans;
  letter-spacing: 0px;
  opacity: 1;
`;

export const SubtitleProgress = styled.h3`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal normal 12px/18px Nunito Sans;
  letter-spacing: 0px;
  opacity: 1;
`;

export const ContainerTextsProgress = styled.div`
  width: 150px;
  height: 74px;
  padding-left: 6px;
`;

export const AntdStyles = {
  marginTop: {
    marginTop: '0.5rem',
  },
};
