/* eslint-disable prettier/prettier */
/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import { useState, useRef, useEffect } from 'react';
import {
  TimePicker,
  Input,
  Button,
  Menu,
  Checkbox,
  Row,
  Col,
  Upload,
  Dropdown,
} from 'antd';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import ImgCrop from 'antd-img-crop';
import localePTDate from 'antd/lib/locale/pt_BR';

import InputMask from 'react-input-mask';
import {
  DaysTranslated,
  PropsDayArray,
  PropsDayArrayNumber,
  PropsDayEnum,
} from 'utils/daysAWeek';
import moment from 'moment';

import { getAddressByCep } from 'store/modules/CEP/actions';
import { registerClinic, updateUserState } from 'store/modules/auth/actions';
import { StatesOfBrazil } from 'utils/States';
import fire from 'services/fire';
import { maskBuilderCPFCNPJ } from '../../../../../../utils/maskValidator';

import * as S from './styles';

function ClinicProfile({ setPage }) {
  const user = useSelector(state => state.auth.user);
  const loading = useSelector(state => state.auth.loading);
  const cep = useSelector(state => state.CEP);
  const [fileList, setFileList] = useState([]);
  const dispatch = useDispatch();
  const formikRef = useRef(null);

  const formik = {
    initialValues: {
      name: user !== null ? user.name : '',
      document: '',
      email: user !== null ? user.email : '',
      stateUF: 'Selecione',
      address: '',
      number: '',
      complement: '',
      neighborhood: '',
      city: '',
      postal_code: '',
      cellphone1: '',
      cellphone2: '',
      password: '',
      hourInit: '',
      hourEnd: '',
      dayInWeek: [],
      terms: false,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('É obrigatório'),
      document: Yup.string().required('É obrigatório'),
      email: Yup.string().email('E-mail inválido').required('É obrigatório'),
      address: Yup.string().required('É obrigatório'),
      cellphone1: Yup.string().required('É obrigatório'),
      cellphone2: Yup.string().required('É obrigatório'),
      stateUF: Yup.string().required('É obrigatório'),
      number: Yup.string().required('É obrigatório'),
      complement: Yup.string().required('É obrigatório'),
      neighborhood: Yup.string().required('É obrigatório'),
      city: Yup.string().required('É obrigatório'),
      postal_code: Yup.string().required('É obrigatório'),
      dayInWeek: Yup.array()
        .min(1, 'Selecione no mínimo um dia')
        .required('É obrigatório'),
      password:
        user !== null && user.email !== null
          ? Yup.string()
          : Yup.string().required('É obrigatório'),
      hourInit: Yup.string().required('É obrigatório'),
      terms: Yup.boolean().equals([true], 'Você precisa aceitar os termos'),
    }),
    onSubmit: values => {
      const userSubmit = values;
      const photo = fileList.length > 0 ? fileList[0].originFileObj : null;

      dispatch(registerClinic(userSubmit, photo, setPage));
      // dispatch(updateUserState(null));
      // setPage('login');
    },
  };

  const onChange = ({ fileList: newFileList }) => {
    // eslint-disable-next-line prefer-const
    let file = newFileList;
    file[0].status = 'done';
    setFileList(file);
  };

  const onPreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  useEffect(() => {
    if (cep.address !== null) {
      formikRef.current.setFieldValue('address', cep.address.logradouro);
      formikRef.current.setFieldValue('neighborhood', cep.address.bairro);
      formikRef.current.setFieldValue('city', cep.address.cidade);
      formikRef.current.setFieldValue('stateUF', cep.address.estado_info.nome);
    }
  }, [cep]);

  return (
    <Formik
      initialValues={formik.initialValues}
      validationSchema={formik.validationSchema}
      onSubmit={values => formik.onSubmit(values)}
      innerRef={formikRef}
    >
      {props => (
        <>
          <S.TextLogin>
            Cadastre-se para utilizar a nossa plataforma.
          </S.TextLogin>

          {(user !== null && user.photo === null) ||
            (user === null && (
              <>
                <S.DescInput>Foto</S.DescInput>

                <ImgCrop rotate>
                  <Upload
                    listType="picture-card"
                    fileList={fileList}
                    onChange={onChange}
                    onPreview={onPreview}
                  >
                    {fileList.length < 1 && '+ Upload'}
                  </Upload>
                </ImgCrop>
              </>
            ))}

          <>
            <S.DescInput>Nome da Clínica</S.DescInput>
            <Input
              placeholder="Nome completo"
              style={S.AntdStyles.inputForm}
              id="name"
              name="name"
              type="text"
              onChange={props.handleChange}
              value={props.values.name}
              onBlur={props.handleBlur}
            />
            {props.touched.name && props.errors.name ? (
              <S.ErrorMessage>{props.errors.name}</S.ErrorMessage>
            ) : null}
          </>

          <S.DescInput>CPF ou CNPJ</S.DescInput>
          <InputMask
            mask={maskBuilderCPFCNPJ(props.values.document)}
            style={S.AntdStyles.inputMask}
            value={props.values.document}
            onChange={e => {
              props.setFieldValue(
                'document',
                e.target.value.replace(/\D/g, ''),
              );
            }}
          />
          {props.touched.document && props.errors.document ? (
            <S.ErrorMessage>{props.errors.document}</S.ErrorMessage>
          ) : null}

          {user === null && (
            <>
              <S.DescInput>E-mail da Clínica</S.DescInput>
              <Input
                placeholder="exemplo@exemplo.com.br"
                style={S.AntdStyles.inputForm}
                id="email"
                name="email"
                type="email"
                onChange={props.handleChange}
                value={props.values.email}
                onBlur={props.handleBlur}
              />
              {props.touched.email && props.errors.email ? (
                <S.ErrorMessage>{props.errors.email}</S.ErrorMessage>
              ) : null}
            </>
          )}

          <S.DescInput>CEP</S.DescInput>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <InputMask
              mask="99999-999"
              style={{ ...S.AntdStyles.inputMask, width: '100%' }}
              value={props.values.postal_code}
              onChange={e => props.setFieldValue('postal_code', e.target.value)}
            />
            <Button
              type="primary"
              style={S.AntdStyles.buttonSearchCEP}
              onClick={() => {
                dispatch(getAddressByCep(props.values.postal_code));
              }}
            >
              Buscar
            </Button>
          </div>
          {props.touched.postal_code && props.errors.postal_code ? (
            <S.ErrorMessage>{props.errors.postal_code}</S.ErrorMessage>
          ) : null}

          <S.DescInput>Rua</S.DescInput>
          <Input
            style={S.AntdStyles.inputForm}
            id="address"
            name="address"
            type="text"
            onChange={props.handleChange}
            value={props.values.address}
            onBlur={props.handleBlur}
          />
          {props.touched.address && props.errors.address ? (
            <S.ErrorMessage>{props.errors.address}</S.ErrorMessage>
          ) : null}

          <S.DescInput>Número</S.DescInput>
          <Input
            style={S.AntdStyles.inputForm}
            id="number"
            name="number"
            type="text"
            onChange={props.handleChange}
            value={props.values.number}
            onBlur={props.handleBlur}
          />
          {props.touched.number && props.errors.number ? (
            <S.ErrorMessage>{props.errors.number}</S.ErrorMessage>
          ) : null}

          <S.DescInput>Complemento</S.DescInput>
          <Input
            style={S.AntdStyles.inputForm}
            id="complement"
            name="complement"
            type="text"
            onChange={props.handleChange}
            value={props.values.complement}
            onBlur={props.handleBlur}
          />
          {props.touched.complement && props.errors.complement ? (
            <S.ErrorMessage>{props.errors.complement}</S.ErrorMessage>
          ) : null}

          <S.LabelText className="input-label">
            <S.DescInput>Estado</S.DescInput>
            <Dropdown
              overlay={
                <Menu style={S.AntdStyles.menuStates}>
                  {StatesOfBrazil.map(item => (
                    <Menu.Item
                      onClick={() => props.setFieldValue('stateUF', item)}
                    >
                      {item}
                    </Menu.Item>
                  ))}
                </Menu>
              }
            >
              <Button style={S.AntdStyles.ButtonTypePayment}>
                {props.values.stateUF}
              </Button>
            </Dropdown>

            {props.touched.stateUF && props.errors.stateUF ? (
              <S.ErrorMessage>{props.errors.stateUF}</S.ErrorMessage>
            ) : null}
          </S.LabelText>

          <S.DescInput>Cidade</S.DescInput>
          <Input
            style={S.AntdStyles.inputForm}
            id="city"
            name="city"
            type="text"
            onChange={props.handleChange}
            value={props.values.city}
            onBlur={props.handleBlur}
          />
          {props.touched.city && props.errors.city ? (
            <S.ErrorMessage>{props.errors.city}</S.ErrorMessage>
          ) : null}

          <S.DescInput>Bairro</S.DescInput>
          <Input
            style={S.AntdStyles.inputForm}
            id="neighborhood"
            name="neighborhood"
            type="text"
            onChange={props.handleChange}
            value={props.values.neighborhood}
            onBlur={props.handleBlur}
          />
          {props.touched.neighborhood && props.errors.neighborhood ? (
            <S.ErrorMessage>{props.errors.neighborhood}</S.ErrorMessage>
          ) : null}

          <S.DescInput>Telefone 1</S.DescInput>
          <InputMask
            mask="(99)99999-9999"
            style={S.AntdStyles.inputMask}
            value={props.values.cellphone1}
            onChange={e => props.setFieldValue('cellphone1', e.target.value)}
          />
          {props.touched.cellphone1 && props.errors.cellphone1 ? (
            <S.ErrorMessage>{props.errors.cellphone1}</S.ErrorMessage>
          ) : null}
          <S.DescInput>Telefone 2</S.DescInput>
          <InputMask
            mask="(99)99999-9999"
            style={S.AntdStyles.inputMask}
            value={props.values.cellphone2}
            onChange={e => props.setFieldValue('cellphone2', e.target.value)}
          />
          {props.touched.cellphone2 && props.errors.cellphone2 ? (
            <S.ErrorMessage>{props.errors.cellphone2}</S.ErrorMessage>
          ) : null}

          <S.DescInput>Horário de Funcionamento</S.DescInput>

          <TimePicker.RangePicker
            style={S.AntdStyles.inputForm}
            locale={localePTDate}
            showTime={{ format: 'HH:mm' }}
            format="HH:mm"
            onChange={(_, hourString) => {
              props.setFieldValue(
                'hourInit',
                moment(hourString[0], 'HH:mm').format('HH:mm'),
              );
              props.setFieldValue(
                'hourEnd',
                moment(hourString[1], 'HH:mm').format('HH:mm'),
              );
            }}
          />
          {props.touched.hourInit && props.errors.hourInit ? (
            <S.ErrorMessage>{props.errors.hourInit}</S.ErrorMessage>
          ) : null}

          <S.DescInput>Dias da Semana</S.DescInput>
          <Checkbox.Group
            style={{ width: '100%' }}
            onChange={checkedValues =>
              props.setFieldValue('dayInWeek', checkedValues)
            }
          >
            <S.DivMarginTop>
              <Row>
                {PropsDayArray.map(
                  (item, index) =>
                    item && (
                      <Col span={12}>
                        <Checkbox value={PropsDayArrayNumber[index]}>
                          {DaysTranslated(PropsDayEnum[item])}
                        </Checkbox>
                      </Col>
                    ),
                )}
              </Row>
            </S.DivMarginTop>
          </Checkbox.Group>
          {props.touched.dayInWeek && props.errors.dayInWeek ? (
            <S.ErrorMessage>{props.errors.dayInWeek}</S.ErrorMessage>
          ) : null}

          {user === null && (
            <>
              <S.DescInput>Senha</S.DescInput>
              <Input.Password
                placeholder="Digite a sua senha"
                style={S.AntdStyles.inputForm}
                id="password"
                name="password"
                type="password"
                onChange={props.handleChange}
                value={props.values.password}
                onBlur={props.handleBlur}
              />
              {props.touched.password && props.errors.password ? (
                <S.ErrorMessage>{props.errors.password}</S.ErrorMessage>
              ) : null}
            </>
          )}

          <S.DivTerms>
            <Checkbox
              onChange={e => props.setFieldValue('terms', e.target.checked)}
              value={props.values.terms}
            >
              {' '}
              Li e aceito os{' '}
              <a
                href="https://neurobots.com.br/monitore/termos"
                target="_blank"
                rel="noreferrer"
              >
                termos de uso{' '}
              </a>
              e a{' '}
              <a
                href="https://neurobots.com.br/monitore/privacidade"
                target="_blank"
                rel="noreferrer"
              >
                política de privacidade.
              </a>
            </Checkbox>
            {props.touched.terms && props.errors.terms ? (
              <S.ErrorMessage>{props.errors.terms}</S.ErrorMessage>
            ) : null}
          </S.DivTerms>

          <Button
            type="primary"
            style={S.AntdStyles.buttonLogin}
            loading={loading}
            onClick={props.handleSubmit}
            htmlType="submit"
          >
            Cadastrar
          </Button>
        </>
      )}
    </Formik>
  );
}

export default ClinicProfile;
