/* eslint-disable no-return-await */
/* eslint-disable no-console */
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/messaging';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyAnyv5PNsH-CsaniKGLldXBIQjJI36iGQA',
  authDomain: 'nbweb-prod.firebaseapp.com',
  databaseURL: 'https://nbweb-prod.firebaseio.com',
  projectId: 'nbweb-prod',
  storageBucket: 'nbweb-prod.appspot.com',
  messagingSenderId: '584371911289',
  appId: '1:584371911289:web:fe21144d7951c8c454f2a8',
  measurementId: 'G-Q13QS0M3ST',
};

const fire = firebase.initializeApp(firebaseConfig);

let messaging = null;

if (firebase.messaging.isSupported()) {
  messaging = fire.messaging();
}

export const permission = firebase.messaging.isSupported();

export const getToken = async () => {
  const token = await messaging.getToken({
    vapidKey:
      'BLwKR2Jumg0OyY6qnmw18McQAuJcE4AT7t8H1qKzUUsJFxB_7btb_hiVnjhxazNCrhqubIEKTq7Pl1jQo95KGew',
  });
  return token;
};

export const onMessageListener = () =>
  new Promise(resolve => {
    messaging.onMessage(payload => {
      resolve(payload);
    });
  });

export default fire;
