import { Row, Col } from 'antd';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import * as S from './styles';

export function Protocols({ protocols = [] }) {
  const isBiggerThan = useMediaQuery('(min-width: 770px)');

  return (
    <S.Container>
      <Row gutter={16}>
        {protocols.map(item => (
          <Col className="gutter-row" span={isBiggerThan ? 8 : 16}>
            {item}
          </Col>
        ))}
      </Row>
    </S.Container>
  );
}
