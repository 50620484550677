import styled from 'styled-components';

export const EditSpeciality = styled.a`
  color: var(--darkCyan);
  text-align: left;
  font: normal normal normal 14px/19px Nunito Sans;
  letter-spacing: 0px;
  text-decoration: none;
`;

export const AntdStyles = {
  marginTop: {
    marginTop: '4px',
    width: '100%',
  },

  buttonSearchCEP: {
    background: 'var(--darkCyan) 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    width: '6rem',
    height: '30px',
    marginLeft: '10px',
    marginTop: '0.3rem',
  },

  inputMask: {
    textAlign: 'left',
    font: 'normal normal normal 14px/19px Nunito Sans',
    letterSpacing: '0px',
    color: 'var(--jet)',
    display: 'flex',
    height: '32px',
    width: '100%',
    padding: '4px 11px',
    marginTop: '0.25rem',
    borderWidth: 1,
    border: '1px solid #d9d9d9',
  },

  ButtonSave: {
    background: 'var(--darkCyan) 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    opacity: 1,
    width: '12rem',
    marginTop: '1rem',
    marginRight: '8.5rem',
  },

  ButtonRegister: {
    background: 'var(--white) 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    marginTop: '1rem',
    width: '11rem',
    color: 'var(--darkCyan)',
    border: '1px solid var(--darkCyan)',
  },

  AutoComplete: {
    marginTop: '4px',
  },

  ButtonTypePayment: {
    marginTop: '4px',
    textAlign: 'left',
    fontFamily: 'Nunito Sans',
  },
};

export const TitleSession = styled.h5`
  color: var(--battleshipGrey);
  text-align: left;
  font: normal normal normal 16px/22px Nunito Sans;
  letter-spacing: 0px;
  margin-top: ${props => props.marginT || '8.5rem'};
  margin-bottom: 1rem;
`;

export const HoursSession = styled.h5`
  color: var(--battleshipGrey);
  text-align: center;
  font: normal normal normal 16px/22px Nunito Sans;
  letter-spacing: 0px;
  align-self: center;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

export const DivBlockTextAndInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: ${props => (props.secondChange ? '30%' : '100%')};
`;

export const DivBlockTextAndInputWithoutMargin = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  width: 30%;
`;

export const LabelText = styled.div`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal bold 14px/19px Nunito Sans;
  letter-spacing: 0px;
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
`;

export const LabelSpeciality = styled.label`
  margin: 0.8rem 0;
  margin-left: ${props => (props.editSpeciality ? '8px' : '0px')};
  color: var(--davysGrey);
  text-align: left;
  font: normal normal normal 14px/19px Nunito Sans;
  width: 100%;
  height: 60px;
  overflow-y: scroll;
`;

export const ErrorMessage = styled.label`
  color: #c12;
  font-size: 12px;
  margin-top: 2px;
  font-weight: 100;
`;
