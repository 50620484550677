import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Button } from 'antd';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateEvaluation } from 'store/modules/evaluations/actions';
import TextAndRadioGroup from '../../components/TextAndRadioGroup';
import * as S from './styles';

function Berg({ title, current_eval }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const qts = current_eval?.content?.questions;

  const Questions = [
    '1. Posição sentada para posição em pé',
    '2. Permanecer em pé sem apoio',
    '3. Permanecer sentado sem apoio nas costas, mas com os pés apoiados no chão um num banquinho',
    '4. Posição em pé para posição sentada',
    '5. Transferência',
    '6. Permanecer em pé sem apoio com os olhos fechados',
    '7. Permanecer em pé sem apoio com os pés juntos',
    '8. Alcançar a frente com o braço estendido permanecendo em pé',
    '9. Pegar um objeto do chão a partir de uma posição em pé',
    '10. Virar-se e olhar para trás por cima dos ombros direito e esquerdo enquanto permanece em pé',
    '11. Girar 360 graus',
    '12. Posicionar os pés alternadamente no degrau ou banquinho enquanto permanece em pé sem',
    '13. Permanecer em pé sem apoio com um pé à frente',
    '14. Permanecer em pé sobre uma perna',
  ];

  const [formValues, setFormValues] = useState(
    current_eval?.content?.questions.length > 0
      ? Questions.map((item, index) => ({
          index: index + 1,
          text: item,
          value: current_eval.content.questions[index].value,
        }))
      : Questions.map((item, index) => ({
          index: index + 1,
          text: item,
          value: '',
        })),
  );

  function updateValues(isFinal) {
    const tempFormValue = {
      id: current_eval?.id,
      isFinished: isFinal,
      name: title,
      professionalId: current_eval?.professionalId,
      result: null,
      content: {
        questions: formValues,
      },
    };
    dispatch(updateEvaluation(tempFormValue, id));
    if (isFinal) {
      setFormValues(
        Questions.map((item, index) => ({
          index: index + 1,
          text: item,
          value: '',
        })),
      );
    }
  }

  useEffect(() => {
    const valuesFormValues = Object.values(formValues);
    if (valuesFormValues.find(item => item.value !== '')) {
      updateValues(false);
    }
  }, [formValues]);

  const forms = [
    {
      label:
        'Capaz de levantar-se sem utilizar as mãoes e estabilizar-se independentemente (4 pontos)',
      value: '4',
    },
    {
      label:
        'Capaz de levantar-se intependentemente utilizando as mãos (3 pontos)',
      value: '3',
    },
    {
      label:
        'Capaz de levanter-se utilizando as mãoes após diversas tentativas (2 pontos)',
      value: '2',
    },
    {
      label:
        'Necessita de ajuda mínima para levantar-se ou estabilizar-se (1 ponto)',
      value: '1',
    },
    {
      label:
        'Necessita de ajuda moderada ou máxima para levantar-se (0 pontos)',
      value: '0',
    },
  ];

  const forms2 = [
    {
      label: 'capaz de permanecer em pé com segurança por 2 minutos (4 pontos)',
      value: '4',
    },
    {
      label:
        'capaz de permanecer em pé por 2 minutos com supervisão (3 pontos)',
      value: '3',
    },
    {
      label: 'capaz de permanecer em pé por 30 segundos sem apoio (2 pontos)',
      value: '2',
    },
    {
      label:
        'necessita de várias tentativas para permanecer em pé por 30 segunos sem apoio (1 ponto)',
      value: '1',
    },
    {
      label: 'incapaz de permanecer em pé por 30 segundos sem apoio (0 pontos)',
      value: '0',
    },
  ];

  const forms3 = [
    {
      label:
        'capaz de permanecer sentado com segurança e com firmeza por 2 minutos (4 pontos)',
      value: '4',
    },
    {
      label:
        'capaz de permanecer sentado por 2 minutos sob supervisão (3 pontos)',
      value: '3',
    },
    {
      label: 'capaz de permanecer sentado por 30 segundos (2 pontos)',
      value: '2',
    },
    {
      label: 'capaz de permanecer sentado por 10 segundos (1 ponto)',
      value: '1',
    },
    {
      label:
        'incapaz de permanecer sentado sem apoio durante 10 segundos (0 pontos)',
      value: '0',
    },
  ];

  const forms4 = [
    {
      label: 'senta-se com segurança com uso mínimo das mãos (4 pontos)',
      value: '4',
    },
    {
      label: 'controla a descida utilizando as mãos (3 pontos)',
      value: '3',
    },
    {
      label:
        'utiliza a parte posterior das pernas contra a cadeira para controlar a descida (2 pontos)',
      value: '2',
    },
    {
      label:
        'senta-se independetemente, mas tem descida sem controle (1 ponto)',
      value: '1',
    },
    {
      label: 'necessita de ajuda para sentar-se (0 pontos)',
      value: '0',
    },
  ];

  const forms5 = [
    {
      label:
        'capaz de transferir-se com segurança com uso mínimo das mãos (4 pontos)',
      value: '4',
    },
    {
      label:
        'capaz de transferir-se com segurança com o uso das mãos (3 pontos)',
      value: '3',
    },
    {
      label:
        'capaz de transferir-se seguindo orientações verbais e/ou supervisão (2 pontos)',
      value: '2',
    },
    {
      label: 'necessidade de uma pessoa para ajudar (1 ponto)',
      value: '1',
    },
    {
      label:
        'necessidade de duas pessoas para ajudar ou supervisionar para realizar a tarefa com segurança (0 pontos)',
      value: '0',
    },
  ];

  const forms6 = [
    {
      label:
        'capaz de permanecer em pé por 10 segudos com segurança (4 pontos)',
      value: '4',
    },
    {
      label:
        'capaz de permanecer em pé por 10 segundos com supervisão (3 pontos)',
      value: '3',
    },
    {
      label: 'capaz de permanecer em pé por 3 segundos (2 pontos)',
      value: '2',
    },
    {
      label:
        'incapaz de permanecer com os olhos fechados durante 3 segundos, mas mantém-se em pé (1 ponto)',
      value: '1',
    },
    {
      label: 'necessidade de ajuda para não cair (0 pontos)',
      value: '0',
    },
  ];

  const forms7 = [
    {
      label:
        'capaz de posicionar os pés juntos independentemente e permanecer por 1 minuto com segurança (4 pontos)',
      value: '4',
    },
    {
      label:
        'capaz de posicionar os pés juntos independentemente e permanecer por 1 minuto com supervisão (3 pontos)',
      value: '3',
    },
    {
      label:
        'capaz de posicionar os pés juntos independentemente e permanecer por 30 segundos (2 pontos)',
      value: '2',
    },
    {
      label:
        'necessita de ajuda para posicionar-se, mas é capaz de permanecer com os pés juntos durante 15 segundos (1 ponto)',
      value: '1',
    },
    {
      label:
        'necessita de ajuda para posicionar-se e é incapaz de permanecer nessa posição por 15 segundos (0 pontos)',
      value: '0',
    },
  ];

  const forms8 = [
    {
      label: 'pode avançar à frente mais que 25 cm com segurança (4 pontos)',
      value: '4',
    },
    {
      label: 'pode avançar à frente mais que 12,5 cm com segurança (3 pontos)',
      value: '3',
    },
    {
      label: 'pode avançar à frente mais que 5 cm com segurança (2 pontos)',
      value: '2',
    },
    {
      label: 'pode avançar à frente, mas necessita de supervisão (1 ponto)',
      value: '1',
    },
    {
      label:
        'perde o equilíbrio na tentativa, ou necessita de apoio externo (0 pontos)',
      value: '0',
    },
  ];

  const forms9 = [
    {
      label: 'capaz de pegar o chinelo com facilidade e segurança (4 pontos)',
      value: '4',
    },
    {
      label: 'capaz de pegar o chinelo, mas necessita de supervisão (3 pontos)',
      value: '3',
    },
    {
      label:
        'incapaz de pegá-lo, mas se estica até ficar a 2-5 cm do chinelo e mantém o equilíbrio independentemente (2 pontos)',
      value: '2',
    },
    {
      label:
        'incapaz de pegá-lo, necessitando de supervisão enquanto está tentando (1 ponto)',
      value: '1',
    },
    {
      label:
        'incapaz de tentar, ou necessita de ajuda para não perder o equilíbrio ou cair (0 pontos)',
      value: '0',
    },
  ];

  const forms10 = [
    {
      label:
        'olha para trás de ambos os lados com uma boa distribuição do peso (4 pontos)',
      value: '4',
    },
    {
      label:
        'olha para trás somente de um lado, o lado contrário demonstra menor distribuição do peso (3 pontos)',
      value: '3',
    },
    {
      label: 'vira somente para os lados, mas mantém o equilíbrio (2 pontos)',
      value: '2',
    },
    {
      label: 'necessita de supervisão para virar (1 ponto)',
      value: '1',
    },
    {
      label:
        'necessita de ajuda para não perder o equilíbrio ou cair (0 pontos)',
      value: '0',
    },
  ];

  const forms11 = [
    {
      label:
        'capaz de girar 360 graus com segurança em 4 segundos ou menos (4 pontos)',
      value: '4',
    },
    {
      label:
        'capaz de girar 360 graus com segurança somente para um lado em 4 segundos ou menos (3 pontos)',
      value: '3',
    },
    {
      label:
        'capaz de girar 360 graus com segurança, mas lentamente (2 pontos)',
      value: '2',
    },
    {
      label: 'necessita de supervisão próxima ou orientações verbais (1 ponto)',
      value: '1',
    },
    {
      label: 'necessita de ajuda enquanto gira (0 pontos)',
      value: '0',
    },
  ];

  const forms12 = [
    {
      label:
        'capaz de permanecer em pé independentemente e com segurança, completando 8 movimentos em 20 (4 pontos)',
      value: '4',
    },
    {
      label:
        'capaz de permanecer em pé independentemente e completar 8 movimentos em mais que 20 segundos (3 pontos)',
      value: '3',
    },
    {
      label: 'capaz de completar 4 movimentos sem ajuda (2 pontos)',
      value: '2',
    },
    {
      label:
        'capaz de completar mais que 2 movimentos com o mínimo de ajuda (1 ponto)',
      value: '1',
    },
    {
      label:
        'incapaz de tentar, ou necessita de ajuda para não cair (0 pontos)',
      value: '0',
    },
  ];

  const forms13 = [
    {
      label:
        'capaz de colocar um pé imediatamente à frente do outro, independentemente, e permanecer por 30 (4 pontos)',
      value: '4',
    },
    {
      label:
        'capaz de colocar um pé um pouco mais à frente do outro e levemente para o lado, independentemente, e permanecer por 30 segundos (3 pontos)',
      value: '3',
    },
    {
      label:
        'capaz de dar um pequeno passo, independentemente, e permanecer por 30 segundos (2 pontos)',
      value: '2',
    },
    {
      label:
        'necessita de ajuda para dar o passo, porém permanece por 15 segundos (1 ponto)',
      value: '1',
    },
    {
      label:
        'perde o equilíbrio ao tentar dar um passo ou ficar de pé (0 pontos)',
      value: '0',
    },
  ];

  const forms14 = [
    {
      label:
        'capaz de levantar uma perna independentemente e permanecer por mais que 10 segundos (4 pontos)',
      value: '4',
    },
    {
      label:
        'capaz de levantar uma perna independentemente e permanecer por 5-10 segundos (3 pontos)',
      value: '3',
    },
    {
      label:
        'capaz de levantar uma perna independentemente e permanecer por mais que 3 segundos (2 pontos)',
      value: '2',
    },
    {
      label:
        'tenta levantar uma perna, mas é incapaz de permanecer por 3 segundos, embora permaneça em pé independentemente (1 ponto)',
      value: '1',
    },
    {
      label:
        'incapaz de tentar, ou necessita de ajuda para não cair (0 pontos)',
      value: '0',
    },
  ];

  function returnForms(indexQuestion) {
    if (indexQuestion === 2) return forms2;
    if (indexQuestion === 3) return forms3;
    if (indexQuestion === 4) return forms4;
    if (indexQuestion === 5) return forms5;
    if (indexQuestion === 6) return forms6;
    if (indexQuestion === 7) return forms7;
    if (indexQuestion === 8) return forms8;
    if (indexQuestion === 9) return forms9;
    if (indexQuestion === 10) return forms10;
    if (indexQuestion === 11) return forms11;
    if (indexQuestion === 12) return forms12;
    if (indexQuestion === 13) return forms13;
    if (indexQuestion === 14) return forms14;

    return forms;
  }

  return (
    <Formik
      initialValues={{
        Answer1: qts[0] !== undefined ? qts[0].value : '',
        Answer2: qts[1] !== undefined ? qts[1].value : '',
        Answer3: qts[2] !== undefined ? qts[2].value : '',
        Answer4: qts[3] !== undefined ? qts[3].value : '',
        Answer5: qts[4] !== undefined ? qts[4].value : '',
        Answer6: qts[5] !== undefined ? qts[5].value : '',
        Answer7: qts[6] !== undefined ? qts[6].value : '',
        Answer8: qts[7] !== undefined ? qts[7].value : '',
        Answer9: qts[8] !== undefined ? qts[8].value : '',
        Answer10: qts[9] !== undefined ? qts[9].value : '',
        Answer11: qts[10] !== undefined ? qts[10].value : '',
        Answer12: qts[11] !== undefined ? qts[11].value : '',
        Answer13: qts[12] !== undefined ? qts[12].value : '',
        Answer14: qts[13] !== undefined ? qts[13].value : '',
      }}
      onSubmit={(values, actions) => {
        // eslint-disable-next-line prefer-const
        let valuesSubmit = {
          name: title,
          content: {
            questions: [],
          },
        };

        Questions.forEach((item, index) => {
          valuesSubmit.content.questions.push({
            index: index + 1,
            text: item,
            value: values[`Answer${index + 1}`],
          });
        });

        actions.resetForm({
          values: {
            Answer1: '',
            Answer2: '',
            Answer3: '',
            Answer4: '',
            Answer5: '',
            Answer6: '',
            Answer7: '',
            Answer8: '',
            Answer9: '',
            Answer10: '',
            Answer11: '',
            Answer12: '',
            Answer13: '',
            Answer14: '',
          },
        });

        updateValues(true);
      }}
    >
      {props => (
        <>
          {Questions.map(
            (item, index) =>
              item && (
                <TextAndRadioGroup
                  text={item}
                  ArrayValue={returnForms(index + 1)}
                  setAnswer={props.setFieldValue}
                  keyAnswer={`Answer${index + 1}`}
                  direction="vertical"
                  setFormValues={setFormValues}
                  formValues={formValues}
                  index={index}
                  currentValue={props.values[`Answer${index + 1}`]}
                  propsFormik={props}
                />
              ),
          )}

          <Button
            type="primary"
            htmlType="submit"
            onClick={props.handleSubmit}
            style={S.AntdStyles.ButtonSave}
          >
            Salvar
          </Button>
        </>
      )}
    </Formik>
  );
}

export default Berg;
