/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Button } from 'antd';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateEvaluation } from 'store/modules/evaluations/actions';
import DoubleTextAndRadioGroupOneAnswer from '../../components/DoubleTextAndRadioGroupOneAnswer';
import * as S from './styles';

function MedidaIndependenciaFuncional({ title, current_eval }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const qts = current_eval?.content?.questions;

  const forms = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
  ];

  const Questions1 = [
    'Andar pela casa',
    'Higiene pessoal',
    'Banho',
    'Vestir metade superior',
    'Vestir metade inferior',
    'Utilização da sanita',
  ];

  const Questions2 = ['Bexiga', 'Intestino'];

  const Questions3 = [
    'Leito, cadeira, cadeira de rodas',
    'Sanita',
    'Banheira, Duche',
  ];

  const Questions4 = ['Marcha/Cadeira de rodas', 'Escadas'];

  const Questions5 = ['Compreensão', 'Expressão'];

  const Questions6 = ['Interação social', 'Resolução dos problemas', 'Memória'];

  const [formValues, setFormValues] = useState(
    current_eval?.content?.questions.length > 0
      ? [
          ...Questions1,
          ...Questions2,
          ...Questions3,
          ...Questions4,
          ...Questions5,
          ...Questions6,
        ].map((item, index) => ({
          index: index + 1,
          text: item,
          value: current_eval.content.questions[index].value,
        }))
      : [
          ...Questions1,
          ...Questions2,
          ...Questions3,
          ...Questions4,
          ...Questions5,
          ...Questions6,
        ].map((item, index) => ({
          index: index + 1,
          text: item,
          value: '',
        })),
  );

  function updateValues(isFinal) {
    const tempFormValue = {
      id: current_eval?.id,
      isFinished: isFinal,
      name: title,
      professionalId: current_eval?.professionalId,
      result: null,
      content: {
        questions: formValues,
      },
    };
    dispatch(updateEvaluation(tempFormValue, id));
    if (isFinal) {
      setFormValues(
        [
          ...Questions1,
          ...Questions2,
          ...Questions3,
          ...Questions4,
          ...Questions5,
          ...Questions6,
        ].map((item, index) => ({
          index: index + 1,
          text: item,
          value: '',
        })),
      );
    }
  }

  useEffect(() => {
    const valuesFormValues = Object.values(formValues);
    if (valuesFormValues.find(item => item.value !== '')) {
      updateValues(false);
    }
  }, [formValues]);

  return (
    <Formik
      initialValues={{
        Answer1: qts[0] !== undefined ? qts[0].value : '',
        Answer2: qts[1] !== undefined ? qts[1].value : '',
        Answer3: qts[2] !== undefined ? qts[2].value : '',
        Answer4: qts[3] !== undefined ? qts[3].value : '',
        Answer5: qts[4] !== undefined ? qts[4].value : '',
        Answer6: qts[5] !== undefined ? qts[5].value : '',
        Answer7: qts[6] !== undefined ? qts[6].value : '',
        Answer8: qts[7] !== undefined ? qts[7].value : '',
        Answer9: qts[8] !== undefined ? qts[8].value : '',
        Answer10: qts[9] !== undefined ? qts[9].value : '',
        Answer11: qts[10] !== undefined ? qts[10].value : '',
        Answer12: qts[11] !== undefined ? qts[11].value : '',
        Answer13: qts[12] !== undefined ? qts[12].value : '',
        Answer14: qts[13] !== undefined ? qts[13].value : '',
        Answer15: qts[14] !== undefined ? qts[14].value : '',
        Answer16: qts[15] !== undefined ? qts[15].value : '',
        Answer17: qts[16] !== undefined ? qts[16].value : '',
        Answer18: qts[17] !== undefined ? qts[17].value : '',
      }}
      onSubmit={(values, actions) => {
        // eslint-disable-next-line prefer-const
        let valuesSubmit = {
          name: title,
          content: {
            questions: [],
          },
        };

        [
          ...Questions1,
          ...Questions2,
          ...Questions3,
          ...Questions4,
          ...Questions5,
          ...Questions6,
        ].forEach((item, index) => {
          valuesSubmit.content.questions.push({
            index: index + 1,
            text: item,
            value: values[`Answer${index + 1}`],
          });
        });

        actions.resetForm({
          values: {
            Answer1: '',
            Answer2: '',
            Answer3: '',
            Answer4: '',
            Answer5: '',
            Answer6: '',
            Answer7: '',
            Answer8: '',
            Answer9: '',
            Answer10: '',
            Answer11: '',
            Answer12: '',
            Answer13: '',
            Answer14: '',
            Answer15: '',
            Answer16: '',
            Answer17: '',
            Answer18: '',
          },
        });

        updateValues(true);
      }}
    >
      {props => (
        <>
          <S.LabelText>1. Auto-Cuidados</S.LabelText>
          {Questions1.map(
            (item, index) =>
              item && (
                <DoubleTextAndRadioGroupOneAnswer
                  text={item}
                  ArrayValue={forms}
                  setAnswer={props.setFieldValue}
                  keyAnswer={`Answer${index + 1}`}
                  direction="horizontal"
                  setFormValues={setFormValues}
                  formValues={formValues}
                  index={index}
                  currentValue={props.values[`Answer${index + 1}`]}
                  propsFormik={props}
                />
              ),
          )}

          <S.LabelText>2. Controlo dos Esfincteres</S.LabelText>
          {Questions2.map(
            (item, index) =>
              item && (
                <DoubleTextAndRadioGroupOneAnswer
                  text={item}
                  ArrayValue={forms}
                  setAnswer={props.setFieldValue}
                  keyAnswer={`Answer${index + 1 + 6}`}
                  direction="horizontal"
                  setFormValues={setFormValues}
                  formValues={formValues}
                  index={index + 6}
                  currentValue={props.values[`Answer${index + 1 + 6}`]}
                  propsFormik={props}
                />
              ),
          )}

          <S.LabelText>3. Mobilidade</S.LabelText>
          {Questions3.map(
            (item, index) =>
              item && (
                <DoubleTextAndRadioGroupOneAnswer
                  text={item}
                  ArrayValue={forms}
                  setAnswer={props.setFieldValue}
                  keyAnswer={`Answer${index + 1 + 6 + 2}`}
                  direction="horizontal"
                  setFormValues={setFormValues}
                  formValues={formValues}
                  index={index + 6 + 2}
                  currentValue={props.values[`Answer${index + 1 + 6 + 2}`]}
                  propsFormik={props}
                />
              ),
          )}

          <S.LabelText>4. Locomoção</S.LabelText>
          {Questions4.map(
            (item, index) =>
              item && (
                <DoubleTextAndRadioGroupOneAnswer
                  text={item}
                  ArrayValue={forms}
                  setAnswer={props.setFieldValue}
                  keyAnswer={`Answer${index + 1 + 6 + 2 + 3}`}
                  direction="horizontal"
                  setFormValues={setFormValues}
                  formValues={formValues}
                  index={index + 6 + 2 + 3}
                  currentValue={props.values[`Answer${index + 1 + 6 + 2 + 3}`]}
                  propsFormik={props}
                />
              ),
          )}

          <S.LabelText>5. Comunicação</S.LabelText>
          {Questions5.map(
            (item, index) =>
              item && (
                <DoubleTextAndRadioGroupOneAnswer
                  text={item}
                  ArrayValue={forms}
                  setAnswer={props.setFieldValue}
                  keyAnswer={`Answer${index + 1 + 6 + 2 + 3 + 2}`}
                  direction="horizontal"
                  setFormValues={setFormValues}
                  formValues={formValues}
                  index={index + 6 + 2 + 3 + 2}
                  currentValue={
                    props.values[`Answer${index + 1 + 6 + 2 + 3 + 2}`]
                  }
                  propsFormik={props}
                />
              ),
          )}

          <S.LabelText>6. Consciência do mundo exterior</S.LabelText>
          {Questions6.map(
            (item, index) =>
              item && (
                <DoubleTextAndRadioGroupOneAnswer
                  text={item}
                  ArrayValue={forms}
                  setAnswer={props.setFieldValue}
                  keyAnswer={`Answer${index + 1 + 6 + 2 + 3 + 2 + 2}`}
                  direction="horizontal"
                  setFormValues={setFormValues}
                  formValues={formValues}
                  index={index + 6 + 2 + 3 + 2 + 2}
                  currentValue={
                    props.values[`Answer${index + 1 + 6 + 2 + 3 + 2 + 2}`]
                  }
                  propsFormik={props}
                />
              ),
          )}

          <Button
            type="primary"
            htmlType="submit"
            onClick={props.handleSubmit}
            style={S.AntdStyles.ButtonSave}
          >
            Salvar
          </Button>
        </>
      )}
    </Formik>
  );
}

export default MedidaIndependenciaFuncional;
