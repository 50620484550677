export function signInRequest(email, password) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { email, password },
  };
}

export function signInRequestGoogle() {
  return {
    type: '@auth/SIGN_IN_REQUEST_GOOGLE',
  };
}

export function setUser(user) {
  return {
    type: '@auth/SET_USER',
    payload: { user },
  };
}

export function updateUserState(user) {
  return {
    type: '@auth/UPDATE_USER_STATE',
    payload: { user },
  };
}

export function signInSuccess(token, user) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, user },
  };
}

export function signInSuccessGoogle(token, user) {
  return {
    type: '@auth/SIGN_IN_SUCCESS_GOOGLE',
    payload: { token, user },
  };
}

export function signInSuccessWithoutVerification(token, user) {
  return {
    type: '@auth/SIGN_IN_SUCCESS_WITHOUT_VERIFICATION',
    payload: { token, user },
  };
}

export function signFailure() {
  return {
    type: '@auth/SIGN_FAILURE',
  };
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  };
}

export function signed() {
  return {
    type: '@auth/SIGNED',
  };
}

export function refreshPageStart() {
  return {
    type: '@auth/REFRESH_PAGE_START',
  };
}

export function refreshPageEnd() {
  return {
    type: '@auth/REFRESH_PAGE_END',
  };
}

export function refreshToken() {
  return {
    type: '@auth/REFRESH_TOKEN',
  };
}

export function setToken() {
  return {
    type: '@auth/REHYDRATE',
  };
}

export function registerProfessional(user, photo, page) {
  return {
    type: '@auth/REGISTER_PROFESSIONAL',
    payload: { user, photo, page },
  };
}

export function registerClinic(user, photo, page) {
  return {
    type: '@auth/REGISTER_CLINIC',
    payload: { user, photo, page },
  };
}
export function registerReceptionist(user, photo, page) {
  return {
    type: '@auth/REGISTER_RECEPTIONIST',
    payload: { user, photo, page },
  };
}

export function updateUser(user) {
  return {
    type: '@auth/UPDATE_USER',
    payload: { user },
  };
}

export function updatePassword(email) {
  return {
    type: '@auth/UPDATE_PASSWORD',
    payload: { email },
  };
}

export function getUser() {
  return {
    type: '@auth/GET_USER',
  };
}

export function setIsEmailVerified(isVerified) {
  return {
    type: '@auth/SET_IS_EMAIL_VERIFIED',
    payload: { isVerified },
  };
}

export function setMyAccountView(isView) {
  return {
    type: '@auth/SET_MY_ACCOUNT_VIEW',
    payload: { isView },
  };
}

export function createNewProfileProfessional(user, photo, history) {
  return {
    type: '@auth/CREATE_NEW_PROFILE_PROFESSIONAL',
    payload: { user, photo, history },
  };
}

export function createNewProfileClinic(user, photo, history) {
  return {
    type: '@auth/CREATE_NEW_PROFILE_CLINIC',
    payload: { user, photo, history },
  };
}

export function changeLoadingStatus(status) {
  return {
    type: '@auth/CANCEL_LOADING',
    payload: { status },
  };
}
