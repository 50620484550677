/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-lonely-if */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/destructuring-assignment */
import {
  Layout,
  Input,
  Radio,
  Button,
  Divider,
  Table,
  Space,
  Avatar,
  Tooltip,
} from 'antd';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import api from 'services/api';

import editGreen from 'assets/editGreen.svg';
// import thrashGreen from 'assets/thrashGreen.svg';
import archivedGreen from 'assets/archivedGreen.svg';
import AppHeader from 'components/Header';
import IconSearch from 'assets/icon-search.svg';
import archivedGrey from 'assets/archivedGrey.svg';
import desarchivedGrey from 'assets/desarchiveGrey.svg';
import desarchiveGreen from 'assets/desarchive.svg';
import { refreshToken } from 'store/modules/auth/actions';

import './styles.css';
import {
  getProfessionalsWithInvites,
  setInfoDetails,
} from 'store/modules/clinic/actions';
import {
  archiveProfessional,
  unarchiveProfessional,
} from 'store/modules/professional/actions';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { FaHourglassHalf } from 'react-icons/fa';

import {
  PropsSpecialityEnumUpper,
  SpecialityTranslatedFromArray,
} from 'utils/speciality';
import { nameHandler } from 'utils/NameHandler';
import { replaceSpecialChars } from 'utils/Validator';
import { MsgToProfModal } from './msgToProfModal/index';
import {
  ProfessionalListEnum,
  StatusTranslated,
  TypeAccountTranslated,
} from './utils/professionalList';

import * as S from './styles';
import { AddProfessionalModal } from './addProfessional/addProfessional';

function ProfessionalList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const firstChange = useMediaQuery('(min-width: 992px)');
  const secondChange = useMediaQuery('(min-width: 830px)');
  const thirdChange = useMediaQuery('(min-width: 480px)');

  const firstTableChange = useMediaQuery('(min-width: 1070px)');
  const secondTableChange = useMediaQuery('(min-width: 905px)');
  const thirdTableChange = useMediaQuery('(min-width: 480px)');

  // Quando o token expira
  api.interceptors.response.use(
    response => response,
    error => {
      if (error.message === 'Request failed with status code 401') {
        dispatch(refreshToken());
      }
      Promise.reject(error);
    },
  );

  useEffect(() => {
    dispatch(getProfessionalsWithInvites());
  }, []);

  const professionalList = useSelector(
    state => state.clinic.professionalsAndInvites,
  );
  const loading = useSelector(state => state.clinic.loadingProfessionals);

  const [chooseProfessional, setChooseProfessional] = useState('ACTIVE');

  const [selectedProfessional, setSelectedProfessional] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [responsiveColumn, setResponsiveColumn] = useState([]);

  const rowSelection = {
    onChange: (_, selectedRows) => {
      setSelectedProfessional(selectedRows);
    },
  };

  const buttonArchive = useCallback(professionalID => {
    const professionalsID = [professionalID];
    dispatch(archiveProfessional(professionalsID));
  }, []);

  const buttonActive = useCallback(professionalID => {
    const professionalsID = [professionalID];
    dispatch(unarchiveProfessional(professionalsID));
  }, []);

  const columnActive = [
    {
      title: 'Ações',
      key: 'action',
      fixed: 'right',
      editable: true,
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="Arquivar">
            <img
              src={archivedGreen}
              onClick={() => {
                buttonArchive(record.id);
              }}
              style={{ cursor: 'pointer' }}
            />
          </Tooltip>
          <Tooltip title="Editar">
            <img
              src={editGreen}
              onClick={() => {
                dispatch(setInfoDetails(record, record.typeAccount));
                history.push(`${record.id}/edit_info`);
              }}
              style={{ cursor: 'pointer' }}
            />
          </Tooltip>
          {/* <Tooltip title="Excluir">
                <img
                  src={thrashGreen}
                  onClick={() => dispatch(deletePatient(record.id))}
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip> */}
        </Space>
      ),
    },
  ];

  const columnArchive = [
    {
      title: 'Ações',
      key: 'action',
      fixed: 'right',
      editable: true,
      render: (_, record) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Tooltip title="Ativar">
            <img
              src={desarchiveGreen}
              onClick={() => {
                buttonActive(record.id);
              }}
              style={{ cursor: 'pointer' }}
            />
          </Tooltip>
          {/*
              <Tooltip title="Excluir">
                <img
                  src={thrashGreen}
                  onClick={() => dispatch(deletePatient(record.id))}
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip> */}
        </div>
      ),
    },
  ];

  // eslint-disable-next-line no-unused-vars
  const columns = [
    {
      title: 'Profissionais',
      dataIndex: 'name',
      key: 'name',
      width: '100',
      fixed: 'left',
      editable: true,
      render: (text, record) => (
        <Space size="middle">
          <Avatar size={24} src={record?.photo} style={S.AntdStyles.AvatarUser}>
            {record.photo === null ? nameHandler(text) : null}
          </Avatar>
          <label
            onClick={() => {
              dispatch(setInfoDetails(record, record.typeAccount));
              history.push(`${record.id}/edit_info`);
            }}
            style={{ cursor: 'pointer' }}
          >
            {text}
          </label>
        </Space>
      ),
    },
    {
      title: 'Status do Convite',
      dataIndex: 'inviteStatus',
      key: 'inviteStatus',
      width: '100',
      editable: true,
      render: text => (
        <Space style={{ alignSelf: 'center', marginLeft: '3rem' }}>
          <Tooltip title={StatusTranslated[text]}>
            {text === 'PENDING' ? (
              <FaHourglassHalf color="#FFC465" size={18} />
            ) : null}
            {text === 'ACCEPTED' ? (
              <CheckCircleFilled
                style={{
                  color: '#118e88',
                }}
              />
            ) : null}
            {text === 'DENIED' ? (
              <CloseCircleFilled
                style={{
                  color: '#B23535',
                }}
              />
            ) : null}
          </Tooltip>
        </Space>
      ),
    },
    {
      title: 'Tipo de Conta',
      dataIndex: 'typeAccount',
      key: 'typeAccount',
      width: '100',
      editable: true,

      render: text => (
        <Space size="middle">
          <label>{TypeAccountTranslated[text]}</label>
        </Space>
      ),
    },
    {
      title: 'Área / Especialidade',
      dataIndex: 'specialities',
      key: 'specialities',
      width: '100',
      editable: true,

      render: text => (
        <Space
          style={{
            alignSelf: 'center',
            textOverflow: 'clip',
            whiteSpace: 'nowrap',
          }}
        >
          {text.length === 0
            ? '-'
            : text.map(
                (item, index) =>
                  `${
                    item.speciality !== PropsSpecialityEnumUpper.OTHER
                      ? SpecialityTranslatedFromArray(item.speciality)
                      : item.otherSpecialityName
                  } ${index + 1 === text.length ? '' : '/'}`,
              )}
        </Space>
      ),
    },
  ];

  // eslint-disable-next-line no-unused-vars
  const columns2 = [
    {
      title: 'Profissionais',
      dataIndex: 'name',
      key: 'name',
      width: '25%',
      render: (text, record) => (
        <Space size="middle">
          <Avatar size={24} src={record?.photo} style={S.AntdStyles.AvatarUser}>
            {record.photo === null ? nameHandler(text) : null}
          </Avatar>
          <label
            onClick={() => {
              dispatch(setInfoDetails(record, record.typeAccount));
              history.push(`${record.id}/edit_info`);
            }}
            style={{ cursor: 'pointer' }}
          >
            {text}
          </label>
        </Space>
      ),
    },
    {
      title: 'Status do Convite',
      dataIndex: 'inviteStatus',
      key: 'inviteStatus',
      width: '20%',
      render: text => (
        <Space style={{ alignSelf: 'center', marginLeft: '3rem' }}>
          <Tooltip title={StatusTranslated[text]}>
            {text === 'PENDING' ? (
              <FaHourglassHalf color="#FFC465" size={18} />
            ) : null}
            {text === 'ACCEPTED' ? (
              <CheckCircleFilled
                style={{
                  color: '#118e88',
                }}
              />
            ) : null}
            {text === 'DENIED' ? (
              <CloseCircleFilled
                style={{
                  color: '#B23535',
                }}
              />
            ) : null}
          </Tooltip>
        </Space>
      ),
    },
    {
      title: 'Tipo de Conta',
      dataIndex: 'typeAccount',
      key: 'typeAccount',
      width: '20%',
      render: text => (
        <Space size="middle">
          <label>{TypeAccountTranslated[text]}</label>
        </Space>
      ),
    },
  ];

  // eslint-disable-next-line no-unused-vars
  const columns3 = [
    {
      title: 'Profissionais',
      dataIndex: 'name',
      key: 'name',
      width: '40%',
      render: (text, record) => (
        <Space size="middle">
          <Avatar size={24} src={record?.photo} style={S.AntdStyles.AvatarUser}>
            {record.photo === null ? nameHandler(text) : null}
          </Avatar>
          <label
            onClick={() => {
              dispatch(setInfoDetails(record, record.typeAccount));
              history.push(`${record.id}/edit_info`);
            }}
            style={{ cursor: 'pointer' }}
          >
            {text}
          </label>
        </Space>
      ),
    },
    {
      title: 'Tipo de Conta',
      dataIndex: 'typeAccount',
      key: 'typeAccount',
      width: '35%',
      render: text => (
        <Space size="middle">
          <label>{TypeAccountTranslated[text]}</label>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    let chooseColumn = null;
    if (firstTableChange) {
      chooseColumn = [...columns];
    } else {
      if (secondTableChange) {
        chooseColumn = [...columns2];
      } else {
        chooseColumn = [...columns3];
      }
    }
    if (chooseProfessional === ProfessionalListEnum.ARCHIVED) {
      setResponsiveColumn(prev => [...chooseColumn, ...columnArchive]);
    } else {
      setResponsiveColumn(prev => [...chooseColumn, ...columnActive]);
    }
  }, [
    firstTableChange,
    secondTableChange,
    thirdTableChange,
    chooseProfessional,
  ]);

  const [dataTable, setDataTable] = useState([]);
  const [dataTableOrder, setDataTableOrder] = useState([]);

  useEffect(() => {
    const sort = dataTable.sort((a, b) => {
      const textA = replaceSpecialChars(a.name.toUpperCase());
      const textB = replaceSpecialChars(b.name.toUpperCase());
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
    setDataTableOrder(sort);
  }, [dataTable]);

  // useEffect(() => {
  //   let translatingList = [];
  //   translatingList = translatingList.concat(professionalList);
  //   translatingList.map(item => {
  //     if (item !== null) {
  //       TypeAccountTranslated.map(account => {
  //         if (account.type === item.typeAccount)
  //           return Object.assign(item, { typeAccountTrans: account.pt });
  //       });
  //     }
  //   });
  //   setProfessionalListTranslated(translatingList);
  // }, [professionalList]);

  useEffect(() => {
    if (professionalList !== null) {
      const newListProfessional = professionalList.filter(
        item => item.situation === chooseProfessional,
      );

      // Validar se existe paciente com situação
      if (newListProfessional.length !== 0) {
        setDataTable(newListProfessional);
        //   newListProfessional.map(
        //     item =>
        //       item && {
        //         ...item,
        //         key: item.id,
        //         typeTranslated: professionalListTranslated.find(account => {
        //           if (account.type === item.typeAccount) return account.pt;
        //         }),
        //       },
        //   ),
        // );
        // se não tiver paciente com situação exibe professionalList normalmente
      } else {
        setDataTable([]);
      }
    }
  }, [professionalList, chooseProfessional]);

  // filtro
  function changeTextFilter(text) {
    setDataTable(
      professionalList.filter(value =>
        replaceSpecialChars(value.name)
          .toLowerCase()
          .includes(replaceSpecialChars(text).toLowerCase()),
      ),
    );
  }

  const buttonGroupArchiveOrActive = useCallback((listProfessional, choose) => {
    const professionalsID = listProfessional.map(item => item && item.id);
    if (choose === ProfessionalListEnum.ACTIVE) {
      dispatch(archiveProfessional(professionalsID));
    } else if (choose === ProfessionalListEnum.ARCHIVED) {
      dispatch(unarchiveProfessional(professionalsID));
    }
  }, []);

  /*
  const buttonDelete = useCallback(listProfessional => {
    const professionalsID = listProfessional.map(item => item && item.id);
    // delete
  }, []); */

  function handleOk() {
    setIsModalVisible(false);
  }

  return (
    <Layout>
      <AppHeader />
      <AddProfessionalModal
        isModalVisible={isModalVisible}
        handleOk={handleOk}
      />
      <S.Container mediaFirst={firstChange} mediaThird={thirdChange}>
        <S.TitlePage>Lista de Profissionais</S.TitlePage>

        <S.DivInterationPatient media={secondChange}>
          <div
            style={{
              marginRight: firstChange ? '1rem' : '0.5rem',
              display: secondChange ? 'block' : 'flex',
              flexDirection: secondChange ? 'row' : 'column',
              marginBottom: secondChange ? '0rem' : '1rem',
            }}
          >
            <Input
              placeholder="Busque por um profissional"
              onChange={e => changeTextFilter(e.target.value)}
              suffix={<img src={IconSearch} />}
              style={{
                width: '20rem',
                marginRight: firstChange ? '3rem' : '1rem',
                marginBottom: secondChange ? '0rem' : '1rem',
              }}
            />

            <Radio.Group
              value={chooseProfessional}
              onChange={e => setChooseProfessional(e.target.value)}
            >
              <Radio.Button
                value={ProfessionalListEnum.ACTIVE}
                style={
                  chooseProfessional === ProfessionalListEnum.ACTIVE
                    ? S.AntdStyles.RadioButtonActiveAble
                    : S.AntdStyles.RadioButtonActiveDisable
                }
              >
                Ativos
              </Radio.Button>
              <Radio.Button
                value={ProfessionalListEnum.ARCHIVED}
                style={
                  chooseProfessional === ProfessionalListEnum.ARCHIVED
                    ? S.AntdStyles.RadioButtonArchivedAble
                    : S.AntdStyles.RadioButtonArchivedDisable
                }
              >
                Arquivados
              </Radio.Button>
            </Radio.Group>
          </div>

          <Button
            type="primary"
            style={S.AntdStyles.ButtonAddPatient}
            onClick={() => setIsModalVisible(true)}
          >
            Adicionar Profissional
          </Button>
        </S.DivInterationPatient>

        <S.DivPatientList>
          <Button
            type="text"
            onClick={() => {
              buttonGroupArchiveOrActive(
                selectedProfessional,
                chooseProfessional,
              );
            }}
            style={S.AntdStyles.ButtonArchiveOrActive}
          >
            {chooseProfessional === ProfessionalListEnum.ACTIVE ? (
              <>
                <img src={archivedGrey} style={S.AntdStyles.ImgArchived} />
                <S.LabelArchived>Arquivar</S.LabelArchived>
              </>
            ) : (
              <>
                <img src={desarchivedGrey} style={S.AntdStyles.ImgArchived} />
                <S.LabelArchived>Ativar</S.LabelArchived>
              </>
            )}
          </Button>
          {/* <Button
            type="text"
            onClick={() => {
              buttonDelete(selectedProfessional);
              dispatch(getPatientsList());
            }}
            style={S.AntdStyles.ButtonArchiveOrActive}
          >
            <>
              <img src={trashGrey} style={S.AntdStyles.ImgDeleted} />
              <S.LabelArchived>Apagar</S.LabelArchived>
            </>
          </Button> */}
          <Divider style={{ width: '100%' }} />

          {(loading === true || professionalList === null) && (
            <S.DivCenter>
              <S.LabelPatientList>
                Carregando profissionais ...
              </S.LabelPatientList>
            </S.DivCenter>
          )}

          {loading === false &&
            professionalList !== null &&
            professionalList.length !== 0 && (
              <Table
                size="small"
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection,
                }}
                columns={responsiveColumn}
                dataSource={dataTableOrder}
                pagination={{
                  defaultPageSize: 500,
                }}
              />
            )}

          {loading === false &&
            professionalList !== null &&
            professionalList.length === 0 && (
              <S.DivCenter>
                <S.LabelPatientList>
                  {`Você não possui profissionais, clique no botão "Adicionar Profissional"
              para adicionar um novo.`}
                </S.LabelPatientList>
              </S.DivCenter>
            )}
        </S.DivPatientList>
      </S.Container>
      <MsgToProfModal />
    </Layout>
  );
}

export default ProfessionalList;
