/* eslint-disable prefer-destructuring */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal,
  Row,
  Col,
  Input,
  DatePicker,
  Dropdown,
  Menu,
  Button,
  InputNumber,
  AutoComplete,
  TimePicker,
  Radio,
} from 'antd';

import { useRef, useState, useCallback, useEffect } from 'react';
import { DownOutlined } from '@ant-design/icons';

import { createPatient, validateCPF } from 'store/modules/patient/actions';
import useDebounce from 'hooks/debounce/useDebounce';

import { Formik } from 'formik';
import * as Yup from 'yup';

import moment from 'moment';
import 'moment/locale/pt-br';
import localePTDate from 'antd/lib/locale/pt_BR';

import InputMask from 'react-input-mask';
import { typePaymentArray } from 'utils/typePayment';
import { healthPlanArray } from 'utils/healthPlan';
import { StatesOfBrazil } from 'utils/States';
import closeIcon from 'assets/close.svg';
import CloseIconStatus from 'assets/closeWhite.svg';
import CheckIconStatus from 'assets/check.svg';

import {
  format,
  formatISO,
  getDate,
  getDay,
  getMonth,
  getTime,
  getYear,
} from 'date-fns';
import { eventStatusList } from 'utils/eventStatus';
import {
  addEventClinic,
  eventStatusUpdate,
  getScheduleClinic,
} from 'store/modules/clinicagenda/actions';
import {
  TradutorTitleConsults,
  TitleAgendaEnum,
} from 'utils/titleAgendaConsults';

import * as S from './styles';
import './styles.css';

export function DetailsEventModal({
  isModalVisible,
  handleCancel,
  handleOk,
  event,
  eventInfo,
}) {
  const firstChange = useMediaQuery('(min-width: 770px)');
  const dispatch = useDispatch();
  const [eventActualStatus, setActualEventStatus] = useState(null);

  useEffect(() => {
    if (isModalVisible) {
      setActualEventStatus(event?.extendedProps?.status);
    }
  }, [isModalVisible]);

  const handleUpdateStatus = label => {
    const selectedInitMonth = getMonth(new Date(event.start)) + 1;
    const selectedEndMonth = getMonth(new Date(event.end)) + 1;

    // anos
    const selectedInitYear = getYear(new Date(event.start));
    const selectedEndYear = getYear(new Date(event.end));

    // data inicial
    const selectedDateInit = `${selectedInitMonth}/${selectedInitYear}`;
    // data final
    const selectedDateEnd = `${selectedInitMonth}/${selectedInitYear}`;

    if (selectedDateInit !== selectedDateEnd) {
      dispatch(
        eventStatusUpdate(
          event?.extendedProps?.eventId,
          event?.extendedProps?.patientId,
          label,
          selectedInitMonth,
          selectedInitYear,
          selectedEndMonth,
          selectedEndYear,
        ),
      );
    } else {
      dispatch(
        eventStatusUpdate(
          event?.extendedProps?.eventId,
          event?.extendedProps?.patientId,
          label,
          selectedInitMonth,
          selectedInitYear,
          '',
          '',
        ),
      );
    }
  };

  const eventStatusTranslated = useCallback(status => {
    const result = eventStatusList.find(e => e.label === status).value;
    return result;
  }, []);

  function selectColorForEventCalendar(title) {
    if (title === TitleAgendaEnum.DOMICILIAR) {
      return '#79E5FF';
    }

    if (title === TitleAgendaEnum.PRESENCIAL) {
      return '#FFC465';
    }

    if (title === TitleAgendaEnum.TELECHAMADA) {
      return '#FFA8E4';
    }

    return '#FFC465';
  }

  return (
    <Modal
      visible={isModalVisible}
      onOk={() => handleOk()}
      onCancel={() => handleCancel()}
      style={{
        borderRadius: '5px',
      }}
      centered
      bodyStyle={{ borderRadius: '20px' }}
      closeIcon={
        <img src={closeIcon} alt="close" style={S.AntdStyles.iconClose} />
      }
      width={400}
      footer={false}
    >
      <S.ContainerModal>
        <Row
          gutter={[32, 16]}
          style={{ marginTop: '-1.2rem', marginLeft: '-2.2rem' }}
        >
          <Col className="gutter-row" span={24}>
            <S.LabelText className="input-label">
              {event?.extendedProps?.professionalName}
            </S.LabelText>
            <S.NormalText style={{ marginTop: '4px' }} className="input-label">
              {event?.extendedProps?.patientName}
            </S.NormalText>
          </Col>

          <Col className="gutter-row" span={24}>
            <Row>
              <div
                style={{
                  width: 14,
                  height: 14,
                  marginTop: '0.15rem',
                  marginRight: '0.5rem',
                  borderRadius: '4px',
                  backgroundColor: selectColorForEventCalendar(event?.title),
                }}
              />
              <S.NormalText>{TradutorTitleConsults[event?.title]}</S.NormalText>
            </Row>
          </Col>
          <Col className="gutter-row" span={24}>
            <S.NormalText className="input-label">
              {event !== null
                ? format(new Date(event?.start), 'dd/MM/yyyy')
                : null}
              {' |'}
              {` ${eventInfo?.timeText}`}
            </S.NormalText>
          </Col>
          <Col className="gutter-row" span={24}>
            <S.NormalText className="input-label">
              {event?.extendedProps?.details}
            </S.NormalText>
          </Col>

          <Col className="gutter-row" span={24}>
            <Dropdown
              overlay={
                <Menu>
                  {eventStatusList.map(
                    item =>
                      item.label !== 'PENDING' && (
                        <Menu.Item>
                          <element
                            onClick={() => {
                              handleUpdateStatus(item?.label);
                              setActualEventStatus(item?.label);
                            }}
                          >
                            {item?.value}
                          </element>
                        </Menu.Item>
                      ),
                  )}
                </Menu>
              }
              placement="bottomCenter"
            >
              <Button style={{ width: '80%', height: '2.5rem' }}>
                <Row style={{ justifyContent: 'space-between' }}>
                  <Row>
                    <div
                      style={{
                        width: 20,
                        height: 20,
                        marginRight: 10,
                        borderRadius: 50,
                        backgroundColor:
                          eventActualStatus === 'EVENT_REALIZED'
                            ? '#118E88'
                            : '#87889B',
                      }}
                    >
                      {eventActualStatus === 'PENDING' ? (
                        <S.PendingCircle />
                      ) : (
                        <img
                          alt="close"
                          src={
                            eventActualStatus === 'EVENT_REALIZED'
                              ? CheckIconStatus
                              : CloseIconStatus
                          }
                          style={{
                            height: 10,
                            width: 10,
                            marginBottom: 3,
                            alignSelf: 'center',
                            cursor: 'pointer',
                          }}
                        />
                      )}
                    </div>
                    <S.TextDropdown>
                      {eventActualStatus !== null &&
                        eventStatusTranslated(eventActualStatus)}
                    </S.TextDropdown>
                  </Row>

                  <DownOutlined style={{ marginTop: '0.15rem' }} />
                </Row>
              </Button>
            </Dropdown>
          </Col>
        </Row>
      </S.ContainerModal>
    </Modal>
  );
}
