/* eslint-disable no-unused-vars */
import produce from 'immer';

const INITIAL_STATE = {
  contacts: [],
  selectedChat: {
    selectedChatId: '',
    selectedUserId: '',
    selectedChatName: '',
    selectedChatPhoto: '',
    selectedUserProfileName: '',
    lastMessage: '',
    selectedUserUid: '',
  },
  newMessages: 0,
  newNotifications: 0,
  notifications: [],
  timezoneHours: null,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@messages/SET_CONTACTS': {
        draft.contacts = action.payload.contacts;
        break;
      }

      case '@messages/SELECT_CHAT': {
        draft.selectedChat.selectedUserId = action.payload.userReceiverId;
        draft.selectedChat.selectedChatId = action.payload.chatId;
        draft.selectedChat.selectedChatName = action.payload.userReceiverName;
        draft.selectedChat.selectedChatPhoto = action.payload.userReceiverPhoto;
        draft.selectedChat.selectedUserProfileName = action.payload.profileName;
        draft.selectedChat.lastMessage = action.payload.lastMessage;
        draft.selectedChat.selectedUserUid = action.payload.uid;
        break;
      }

      case '@messages/NEW_CHAT': {
        draft.selectedChat.selectedChatId = action.payload.chatId;
        break;
      }

      case '@messages/UPDATE_CONTACTS': {
        draft.contacts = draft.contacts.map(contact =>
          contact.id === action.payload.userId
            ? { ...contact, chatId: action.payload.chatId }
            : contact,
        );
        break;
      }

      case '@messages/SET_NEWMESSAGES': {
        draft.newMessages = action.payload.newMessages;
        break;
      }

      case '@messages/SET_NEWNOTIFICATIONS': {
        draft.newNotifications = action.payload.newNotifications;
        break;
      }

      case '@messages/SET_NOTIFICATIONS': {
        draft.notifications = action.payload.notifications;
        break;
      }

      case '@messages/SET_TIMEZONE': {
        draft.timezoneHours = action.payload.timezoneHours;
        break;
      }

      default:
    }
  });
}
