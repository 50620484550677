/* eslint-disable no-empty */
/* eslint-disable no-console */
/* eslint-disable no-const-assign */
/* eslint-disable no-plusplus */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import { takeLatest, call, all, put } from 'redux-saga/effects';
import { notification } from 'antd';

import { differenceInMinutes } from 'date-fns';
import api from '../../../services/api';

import {
  newChat,
  setContacts,
  setNewMessages,
  setNewNotifications,
  setNotifications,
  setTimezone,
  getNotifications,
  updateContacts,
} from './actions';

export function* getContacts({ payload }) {
  const { isChatPage } = payload;

  try {
    const response = yield call(api.get, `/contacts`);
    let newMessages = 0;
    response.data.contacts.map(contact => {
      newMessages = contact.quantityMessageUnread + newMessages;
    });
    yield put(setContacts(response.data.contacts));
    yield put(setNewMessages(newMessages));
  } catch (err) {
    if (isChatPage) {
      notification.error({
        message: 'Houve um erro ao captar seus contatos, tente novamente!',
      });
    }
  }
}

export function* getNotification({ payload }) {
  const { index, notifications } = payload;

  try {
    const response = yield call(api.get, `/notifications/page/${index}`);
    let newNotifications = 0;
    let listNotifications = [];

    response.data.notifications.map(notif => {
      if (notif.markAsRead === false) newNotifications++;
    });

    listNotifications = notifications.concat(response.data.notifications);

    // verificar se possui notificações nessa nova pagina de notificacoes
    if (response.data.notifications.length === 10) {
      yield put(getNotifications(index + 1, listNotifications));
    }

    yield put(setNotifications(listNotifications));

    yield put(setNewNotifications(newNotifications));
  } catch (err) {
    notification.error({
      message: 'Houve um erro ao carregar suas notificações, tente novamente!',
    });
  }
}

export function* sendMessage({ payload }) {
  const { chatId, userReceiverId, message } = payload;

  try {
    let response = null;
    if (typeof chatId === 'object') {
      response = yield call(api.post, `/chat/message/text`, {
        userIdReceive: userReceiverId,
        message,
        chatId: null,
      });
      yield put(newChat(response.data.chatId));
      yield put(updateContacts(userReceiverId, response.data.chatId));
    } else {
      response = yield call(api.post, `/chat/message/text`, {
        chatId,
        userIdReceive: userReceiverId,
        message,
      });
    }
  } catch (err) {
    notification.error({
      message: 'Houve um erro no envio da mensagem, tente novamente!',
    });
  }
}

export function* sendFile({ payload }) {
  const { userReceiverId, chatId, file } = payload;

  try {
    typeof chatId !== 'object'
      ? yield call(
          api.post,
          `/chat/${chatId}/userReceive/${userReceiverId}/message/file`,
          file,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
      : yield call(
          api.post,
          `/chat/userReceive/${userReceiverId}/message/file`,
          file,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );
  } catch (err) {
    notification.error({
      message: err.message,
    });
  }
}

export function* deleteMessage({ payload }) {
  const { messageId, chatId } = payload;

  try {
    yield call(api.delete, `/chat/${chatId}/message/${messageId}`);
  } catch (err) {
    notification.error({
      message: `Houve um erro ao deletar mensagem, tente novamente! ${err.message}`,
    });
  }
}

export function* updateStatus({ payload }) {
  const { chatId, userIdReceive, status } = payload;

  try {
    yield call(api.put, `/chat/updateStatus`, {
      chatId,
      userIdReceive,
      status,
    });
  } catch (err) {}
}

export function* readAllNotifications() {
  try {
    yield call(api.put, `/read_all_notifications`);
  } catch (err) {
    notification.error({
      message: `Houve um erro ao ler todas as notificações.`,
    });
  }
}

export function* getTimezoneSagas() {
  try {
    const responseTime = yield call(api.get, `/timeserver`);

    const timezoneHours = (
      differenceInMinutes(new Date(), new Date(responseTime.data.timeserver)) /
      60
    ).toFixed();
    yield put(setTimezone(timezoneHours));
  } catch (err) {
    notification.error({
      message: `Houve um erro ao se conectar com nosso sistema.`,
    });
  }
}

export function* sendTokenPNSagas({ payload }) {
  const { token } = payload;
  try {
    yield call(api.post, '/id_token_messaging', {
      idTokenMessaging: token,
    });
  } catch (err) {
    console.log(err);
  }
}

export default all([
  takeLatest('@messages/GET_CONTACTS', getContacts),
  takeLatest('@messages/GET_NOTIFICATIONS', getNotification),
  takeLatest('@messages/SEND_MESSAGE', sendMessage),
  takeLatest('@messages/SEND_FILE', sendFile),
  takeLatest('@messages/DELETE_MESSAGE', deleteMessage),
  takeLatest('@messages/UPDATE_STATUS', updateStatus),
  takeLatest('@messages/READ_ALL_NOTIFICATIONS', readAllNotifications),
  takeLatest('@messages/GET_TIMEZONE', getTimezoneSagas),
  takeLatest('@messages/SEND_TOKEN_PN', sendTokenPNSagas),
]);
