import styled from 'styled-components';

export const AntdStyles = {
  inputForm: {
    marginTop: '0.3rem',
    textAlign: 'left',
    font: 'normal normal normal 14px/19px Nunito Sans',
    letterSpacing: '0px',
    color: 'var(--jet)',
    display: 'flex',
  },

  buttonLogin: {
    background: '#fff',
    borderRadius: '4px',
    marginTop: '1.2rem',
    width: '6rem',
    color: '#118E88',
    marginRight: '1rem',
    borderWidth: '1px solid #118E88',
  },

  buttonGoogle: {
    background: 'var(--white) 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    marginTop: '1.2rem',
    width: '14rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  buttonRegister: {
    backgroundColor: '#118E88',
    borderColor: '#118E88',
    borderRadius: '4px',
    marginTop: '1.2rem',
    width: '8rem',
    color: '#fff',
    border: '1px solid var(--darkBlueGray)',
  },

  menuStates: {
    height: 200,
    width: '100%',
    overflowY: 'scroll',
  },
  marginTop: {
    marginTop: '4px',
  },

  inputMask: {
    textAlign: 'left',
    font: 'normal normal normal 14px/19px Nunito Sans',
    letterSpacing: '0px',
    color: 'var(--jet)',
    display: 'flex',
    height: '32px',
    width: '100%',
    padding: '4px 11px',
    marginTop: '0.25rem',
    borderWidth: 1,
    border: '1px solid #d9d9d9',
  },

  ButtonSave: {
    background: 'var(--darkCyan) 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    opacity: 1,
    width: '12rem',
    marginTop: '2rem',
    marginBottom: '2rem',
    fontWeight: 'bold',
  },

  ButtonTypePayment: {
    marginTop: '4px',
    textAlign: 'left',
    fontFamily: 'Nunito Sans',
  },

  AutoComplete: {
    marginTop: '4px',
    fontFamily: 'Nunito Sans',
    fontSize: '14px',
  },

  DivSessionAddress: {
    marginTop: '3rem',
    width: '76px',
  },

  marginTop2rem: {
    marginTop: '2rem',
  },

  inputNumber: { width: '100%', marginTop: '4px' },
  iconClose: { marginRight: 8, width: 15, marginBottom: 4 },
};

export const LoginRight = styled.form`
  display: flex;
  background-color: var(--white);
  flex-direction: column;
  padding: 2.5rem;
  height: 100%;
  width: 40%;
  overflow-y: scroll;

  @media (max-width: 950px) {
    width: 100%;
  }
`;

export const TextLogin = styled.text`
  color: var(--battleshipGrey);
  text-align: left;
  font: normal normal normal 14px/20px 'Nunito Sans';
  letter-spacing: 0px;
  color: #868686;
  opacity: 1;
`;

export const DescInput = styled.text`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal bold 14px/19px 'Nunito Sans';
  letter-spacing: 0px;
  color: #575757;
  opacity: 1;
  margin-top: 1rem;
`;

export const GoogleImg = styled.img`
  width: 16px;
`;

export const ButtonName = styled.strong`
  margin-left: 0.8rem;
`;

export const ErrorMessage = styled.label`
  color: #c12;
  font-size: 12px;
  margin-top: 2px;
`;

export const DivMarginTop = styled.div`
  margin-top: 1rem;
`;

export const DivTerms = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const LabelText = styled.div`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal bold 14px/19px Nunito Sans;
  letter-spacing: 0px;
  display: flex;
  flex-direction: column;
`;

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 450px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 1%;
  margin-top: -10px;
  margin-bottom: 20px;
`;

export const TitlePage = styled.h5`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal bold 19px/22px Nunito Sans;
  letter-spacing: 0px;
  opacity: 1;
  margin-bottom: 1rem;
`;

export const TitleSession = styled.label`
  opacity: 1;
  color: var(--deepKoamaru);
  text-align: start;
  font: normal normal bold 14px/19px Nunito Sans;
  letter-spacing: 0px;
  margin-left: 6px;
`;

export const DivSession = styled.div`
  background: var(--lavender) 0% 0% no-repeat padding-box;
  border-width: 15px;
  width: 140px;
  border-left: solid var(--deepKoamaru) 2px;
  display: flex;
  height: 25px;
  align-items: center;
  justify-content: flex-start;
  border-radius: 0 1px 1px 0;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
`;
