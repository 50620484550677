/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
/* eslint-disable no-var */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable import/order */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import {
  Layout,
  Input,
  Radio,
  Button,
  Divider,
  Table,
  Space,
  Avatar,
  Tooltip,
} from 'antd';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import api from 'services/api';

import editGreen from 'assets/editGreen.svg';
import thrashGreen from 'assets/thrashGreen.svg';
import archivedGreen from 'assets/archivedGreen.svg';
import desarchivedGreen from 'assets/desarchive.svg';
import desarchivedGray from 'assets/desarchiveGrey.svg';
import AppHeader from 'components/Header';
import IconSearch from 'assets/icon-search.svg';
import archivedGrey from 'assets/archivedGrey.svg';
import {
  getPatientsList,
  archivePatient,
  unarchivePatient,
  deletePatient,
  setPatientDetails,
} from 'store/modules/patient/actions';
import { refreshToken } from 'store/modules/auth/actions';
import * as S from './styles';

import { AddPatientModal } from './components/addPatientModal';
import { PatientListEnum } from './utils/patientList';

import './styles.css';
import { getTimezone } from 'store/modules/messages/actions';
import { DeletePatientModal } from './components/DeletePatientModal';
import { nameHandler } from 'utils/NameHandler';
import { replaceSpecialChars } from 'utils/Validator';

function PatientList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const firstChange = useMediaQuery('(min-width: 992px)');
  const secondChange = useMediaQuery('(min-width: 830px)');
  const thirdChange = useMediaQuery('(min-width: 480px)');

  // Quando o token expira
  api.interceptors.response.use(
    response => response,
    error => {
      if (error.message === 'Request failed with status code 401') {
        dispatch(refreshToken());
      }
      Promise.reject(error);
    },
  );

  useEffect(() => {
    dispatch(getPatientsList());
    dispatch(getTimezone());
  }, []);

  const patientList = useSelector(state => state.patient.patientList);
  const loading = useSelector(state => state.patient.loading);

  const [choosePatient, setChoosePatient] = useState('ACTIVE');

  const [selectedPatient, setSelectedPatient] = useState([]);
  const [recordId, setRecordId] = useState('');

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);

  const rowSelection = {
    onChange: (_, selectedRows) => {
      setSelectedPatient(selectedRows);
    },
  };

  const buttonArchive = useCallback(patientID => {
    const patientsID = [patientID];
    dispatch(archivePatient(patientsID));
  }, []);

  const buttonActive = useCallback(patientID => {
    const patientsID = [patientID];
    dispatch(unarchivePatient(patientsID));
  }, []);

  const columns = [
    {
      title: 'Pacientes',
      dataIndex: 'name',
      key: 'name',
      width: '90%',
      render: (text, record) => (
        <Space size="middle">
          <Avatar size={24} src={record.photo} style={S.AntdStyles.AvatarUser}>
            {record.photo === null ? nameHandler(text) : null}
          </Avatar>
          <label
            onClick={() => {
              history.push(`/${record.id}/edit_info`);
              dispatch(setPatientDetails(record));
            }}
            style={{ cursor: 'pointer' }}
          >
            {text}
          </label>
        </Space>
      ),
    },
    {
      title: 'Ações',
      key: 'action',
      render: (_, record) => {
        if (choosePatient === PatientListEnum.ACTIVE) {
          return (
            <Space size="middle">
              <Tooltip title="Arquivar">
                <img
                  src={archivedGreen}
                  onClick={() => {
                    buttonArchive(record.id);
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
              <Tooltip title="Editar">
                <img
                  src={editGreen}
                  onClick={() => {
                    history.push(`/${record.id}/edit_info`);
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
              {/* <Tooltip title="Excluir">
                <img
                  src={thrashGreen}
                  onClick={() => {
                    setRecordId(record.id);
                    setIsModalDeleteVisible(true);
                  }}
                  style={{ cursor: 'pointer' }}
                />
                </Tooltip> */}
            </Space>
          );
        }

        if (choosePatient === PatientListEnum.ARCHIVED) {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Tooltip title="Desarquivar">
                <img
                  src={desarchivedGreen}
                  onClick={() => {
                    buttonActive(record.id);
                  }}
                  style={{ cursor: 'pointer', marginRight: '0.8rem' }}
                />
              </Tooltip>

              <Tooltip title="Excluir">
                <img
                  src={thrashGreen}
                  onClick={() => {
                    setRecordId(record.id);
                    setIsModalDeleteVisible(true);
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </Tooltip>
            </div>
          );
        }
      },
    },
  ];

  const [dataTable, setDataTable] = useState([]);
  const [dataTableOrder, setDataTableOrder] = useState([]);

  useEffect(() => {
    const sort = dataTable.sort((a, b) => {
      const textA = replaceSpecialChars(a.name.toUpperCase());
      const textB = replaceSpecialChars(b.name.toUpperCase());
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
    setDataTableOrder(sort);
  },[dataTable]);

  useEffect(() => {
    if (patientList !== null) {
      const newListPatient = patientList.filter(
        item => item.situation === choosePatient,
      );

      // Validar se existe paciente com situação
      if (newListPatient.length !== 0) {
        setDataTable(
          newListPatient.map(item => item && { ...item, key: item.id }),
        );
        // se não tiver paciente com situação exibe patientList normalmente
      } else {
        // setDataTable([...patientList]);
        setDataTable([]);
      }
    }
  }, [patientList, choosePatient]);

  // filtro
  function changeTextFilter(text) {
    setDataTable(patientList.filter(value => replaceSpecialChars(value.name).toLowerCase().includes(replaceSpecialChars(text).toLowerCase())));
  }

  const buttonGroupArchiveOrActive = useCallback((listPatient, choose) => {
    const patientsID = listPatient.map(item => item && item.id);
    if (choose === PatientListEnum.ACTIVE) {
      dispatch(archivePatient(patientsID));
    } else if (choose === PatientListEnum.ARCHIVED) {
      dispatch(unarchivePatient(patientsID));
    }
  }, []);

  function handleOk() {
    setIsModalVisible(false);
    dispatch(getPatientsList());
  }

  function handleCancel() {
    setIsModalVisible(false);
  }

  function handleDeleteOk() {
    dispatch(deletePatient(recordId));
    setIsModalDeleteVisible(false);
  }

  function handleDeleteCancel() {
    setIsModalDeleteVisible(false);
  }

  return (
    <Layout>
      <AppHeader />
      <AddPatientModal
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
      <DeletePatientModal
        isModalVisible={isModalDeleteVisible}
        handleOk={handleDeleteOk}
        handleCancel={handleDeleteCancel}
      />
      <S.Container mediaFirst={firstChange} mediaThird={thirdChange}>
        <S.TitlePage>Sua lista de Pacientes</S.TitlePage>

        <S.DivInterationPatient media={secondChange}>
          <div
            style={{
              marginRight: firstChange ? '1rem' : '0.5rem',
              display: secondChange ? 'block' : 'flex',
              flexDirection: secondChange ? 'row' : 'column',
              marginBottom: secondChange ? '0rem' : '1rem',
            }}
          >
            <Input
              placeholder="Busque por um paciente"
              onChange={e => changeTextFilter(e.target.value)}
              suffix={<img src={IconSearch} />}
              style={{
                width: '20rem',
                marginRight: firstChange ? '3rem' : '1rem',
                marginBottom: secondChange ? '0rem' : '1rem',
              }}
            />

            <Radio.Group
              value={choosePatient}
              onChange={e => setChoosePatient(e.target.value)}
            >
              <Radio.Button
                value={PatientListEnum.ACTIVE}
                style={
                  choosePatient === PatientListEnum.ACTIVE
                    ? S.AntdStyles.RadioButtonActiveAble
                    : S.AntdStyles.RadioButtonActiveDisable
                }
              >
                Ativos
              </Radio.Button>
              <Radio.Button
                value={PatientListEnum.ARCHIVED}
                style={
                  choosePatient === PatientListEnum.ARCHIVED
                    ? S.AntdStyles.RadioButtonArchivedAble
                    : S.AntdStyles.RadioButtonArchivedDisable
                }
              >
                Arquivados
              </Radio.Button>
            </Radio.Group>
          </div>

          <Button
            type="primary"
            style={S.AntdStyles.ButtonAddPatient}
            onClick={() => setIsModalVisible(true)}
          >
            Adicionar Paciente
          </Button>
        </S.DivInterationPatient>

        <S.DivPatientList>
          <Button
            type="text"
            onClick={() => {
              if (selectedPatient.length > 0) {
                buttonGroupArchiveOrActive(selectedPatient, choosePatient);
                setSelectedPatient([]);
              }
            }}
            style={{
              ...S.AntdStyles.ButtonArchiveOrActive,
              cursor: selectedPatient.length > 0 ? 'pointer' : 'not-allowed',
            }}
          >
            {choosePatient === PatientListEnum.ACTIVE ? (
              <>
                <img src={archivedGrey} style={S.AntdStyles.ImgArchived} />
                <S.LabelArchived
                  style={{
                    cursor:
                      selectedPatient.length > 0 ? 'pointer' : 'not-allowed',
                  }}
                >
                  Arquivar
                </S.LabelArchived>
              </>
            ) : (
              <>
                <img src={desarchivedGray} style={S.AntdStyles.ImgActived} />
                <S.LabelArchived
                  style={{
                    cursor:
                      selectedPatient.length > 0 ? 'pointer' : 'not-allowed',
                  }}
                >
                  Desarquivar
                </S.LabelArchived>
              </>
            )}
          </Button>

          <Divider />

          {(loading === true || patientList === null) && (
            <S.DivCenter>
              <S.LabelPatientList>Carregando pacientes ...</S.LabelPatientList>
            </S.DivCenter>
          )}

          {loading === false &&
            patientList !== null &&
            patientList.length !== 0 && (
              <Table
                size="small"
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection,
                }}
                columns={columns}
                dataSource={dataTableOrder}
                pagination={{
                  defaultPageSize: 500,
                }}
              />
            )}

          {loading === false &&
            patientList !== null &&
            patientList.length === 0 && (
              <S.DivCenter>
                <S.LabelPatientList>
                  {`Você não possui pacientes, clique no botão "Adicionar Paciente"
              para adicionar um novo.`}
                </S.LabelPatientList>
              </S.DivCenter>
            )}
        </S.DivPatientList>
      </S.Container>
    </Layout>
  );
}

export default PatientList;
