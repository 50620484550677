/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { Button, Modal } from 'antd';
import closeIcon from 'assets/close.svg';

import * as S from './styles';

export function Category({
  id,
  title,
  description,
  video_url,
  isPreview,
  index_categorie,
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editedDescription, setEditedDescription] = useState('');

  useEffect(() => {
    let tempDescription = title;
    if (tempDescription.length > 30) {
      tempDescription = `${tempDescription.slice(0, 30)} ...`;
    }
    setEditedDescription(tempDescription);
  }, []);

  function handleOk() {
    setIsModalVisible(false);
  }

  function handleCancel() {
    setIsModalVisible(false);
  }

  return (
    <S.Container title={title}>
      <ReactPlayer url={video_url} width="100%" height="100%" light />
      <S.ContainerEditedDescription>
        <label>{editedDescription}</label>
      </S.ContainerEditedDescription>
      <S.TouchScreen
        className="touch-screen"
        role="button"
        onClick={() => setIsModalVisible(true)}
        onKeyPress={() => setIsModalVisible(true)}
        tabIndex="0"
      />

      <Modal
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={850}
        closeIcon={
          <img src={closeIcon} alt="close" style={S.AntdStyles.iconClose} />
        }
        destroyOnClose
        footer={[
          <Button
            type="primary"
            onClick={() => handleCancel()}
            style={{
              width: '140px',
              borderRadius: '5px',
              backgroundColor: '#118E88',
              borderColor: '#118E88',
            }}
          >
            Fechar
          </Button>,
        ]}
      >
        <S.ContainerModal>
          <ReactPlayer url={video_url} width="100%" height="350px" controls />
          <br />
          <label>{description}</label>
        </S.ContainerModal>
      </Modal>
    </S.Container>
  );
}
