export function addNewEvaluation(evalName, patientId) {
  return {
    type: '@evaluations/ADD_NEW_EVALUATIONS',
    payload: { evalName, patientId },
  };
}

export function setNewEvaluations(evaluation) {
  return {
    type: '@evaluations/SET_NEW_EVALUATION',
    payload: { evaluation },
  };
}

export function updateEvaluation(evaluation, patientId) {
  return {
    type: '@evaluations/UPDATE_EVALUATION',
    payload: { evaluation, patientId },
  };
}

export function addComputerVisionEvaluation(evaluation) {
  return {
    type: '@evaluations/ADD_COMPUTER_VISION',
    payload: {
      evaluation,
    },
  };
}

export function getComputerVisionEvaluation(patient, activity) {
  return {
    type: '@evaluations/GET_COMPUTER_VISION',
    payload: {
      patient,
      activity,
    },
  };
}

export function setComputerVisionEvaluation(evaluations) {
  return {
    type: '@evaluations/SET_COMPUTER_VISION',
    payload: {
      evaluations,
    },
  };
}
