/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import format from 'date-fns/format';
import ptBR from 'date-fns/locale/pt-BR';
import { Divider, Row, Col, Progress } from 'antd';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import { stringFormater } from '../../../../../../../../../utils/StringFormater';
import FinishedCheck from '../../../../../../../../../assets/finished-check.svg';
import NotFinishedCheck from '../../../../../../../../../assets/not-finished-check.svg';
import LovedSession from '../../../../../../../../../assets/loved-session.svg';
import LikedSession from '../../../../../../../../../assets/liked-session.svg';
import IndeciseAboutSession from '../../../../../../../../../assets/dont-know-if-liked.svg';
import DoNotLikedSession from '../../../../../../../../../assets/do-not-liked-session.svg';
import HatedSession from '../../../../../../../../../assets/hated-session.svg';
import * as S from './styles';

export function ActivityDay({
  date,
  activities,
  percentage,
  saffety,
  sessionStatus,
  considerations,
}) {
  const [fActivity, setFActivity] = useState([]);
  const [sActivity, setSActivity] = useState([]);
  const [tActivity, setTActivity] = useState([]);
  const [saffetyStatus, setSaffetyStatus] = useState(null);
  const [sessionDate, setSessionDate] = useState('');
  const isBiggerThan = useMediaQuery('(min-width: 770px)');

  useEffect(() => {
    let contArray1 = 2;
    let contArray2 = 1;
    let contArray3 = 0;
    const tempArray1 = [];
    const tempArray2 = [];
    const tempArray3 = []; // MMMM

    const tempDate = format(new Date(date), 'EEEEEEE d MMMM', {
      locale: ptBR,
    }).split(' ');
    const secondPartDate = tempDate[2].charAt(0).toUpperCase();

    setSessionDate(
      `${tempDate[1]} de ${secondPartDate}${tempDate[2].slice(1)}`,
    );

    for (let x = 0; x < activities.length; x += 1) {
      if (contArray1 === 2) {
        tempArray1.push(activities[x]);
        contArray1 = 0;
      } else {
        contArray1 += 1;
      }
      if (contArray2 === 2) {
        tempArray2.push(activities[x]);
        contArray2 = 0;
      } else {
        contArray2 += 1;
      }
      if (contArray3 === 2) {
        tempArray3.push(activities[x]);
        contArray3 = 0;
      } else {
        contArray3 += 1;
      }
    }
    setFActivity([...tempArray1]);
    setSActivity([...tempArray2]);
    setTActivity([...tempArray3]);
    if (saffety === 1) {
      setSaffetyStatus('inseguro (a)');
    } else if (saffety === 2) {
      setSaffetyStatus('um pouco seguro(a)');
    } else if (saffety === 3) {
      setSaffetyStatus('bastante seguro(a)');
    } else {
      setSaffetyStatus(null);
    }
  }, [activities]);

  return (
    <>
      <br />
      <S.TitleSession>{sessionDate}</S.TitleSession>
      <br />
      <S.ContainerShowActivityVideos>
        <Row
          gutter={[32, 16]}
          style={{
            display: 'flex',
            width: '100%',
          }}
        >
          {fActivity.map(exercises => (
            <Col className="gutter-row" span={isBiggerThan ? 8 : 24}>
              <S.ElementVideoActVideos
                key={exercises.exerciseId}
                title={exercises.name}
              >
                <ReactPlayer
                  url={exercises.video_url}
                  width="180px"
                  height="120px"
                  light
                  title={exercises.name}
                />
                <S.Subtitles title={exercises.name}>
                  {stringFormater(exercises.name)}
                </S.Subtitles>
                <>
                  {exercises.pain !== null && exercises.pain !== -1 ? (
                    <>
                      <S.ContainerStatusActivity>
                        <img src={FinishedCheck} alt="Finalizada" />
                        <label>Atividade concluída</label>
                      </S.ContainerStatusActivity>

                      <S.ContainerStatusActivity>
                        <label>{`Dor: ${exercises.pain}`}</label>
                      </S.ContainerStatusActivity>

                      <S.ContainerStatusActivity>
                        <label>{`Dificuldade: ${exercises.difficulty}`}</label>
                      </S.ContainerStatusActivity>
                    </>
                  ) : (
                    <S.ContainerStatusActivity>
                      <img src={NotFinishedCheck} alt="Não finalizada" />
                      <label>Atividade não concluída</label>
                    </S.ContainerStatusActivity>
                  )}
                </>
              </S.ElementVideoActVideos>
            </Col>
          ))}

          {sActivity.map(exercises => (
            <Col className="gutter-row" span={isBiggerThan ? 8 : 24}>
              <S.ElementVideoActVideos
                key={exercises.exerciseId}
                title={exercises.name}
              >
                <ReactPlayer
                  url={exercises.video_url}
                  width="180px"
                  height="120px"
                  light
                  title={exercises.name}
                />
                <S.Subtitles title={exercises.name}>
                  {stringFormater(exercises.name)}
                </S.Subtitles>
                <>
                  {exercises.pain !== null && exercises.pain !== -1 ? (
                    <>
                      <S.ContainerStatusActivity>
                        <img src={FinishedCheck} alt="Finalizada" />
                        <label>{exercises.status}</label>
                      </S.ContainerStatusActivity>
                      <S.ContainerStatusActivity>
                        <label>{`Dor: ${exercises.pain}`}</label>
                      </S.ContainerStatusActivity>
                      <S.ContainerStatusActivity>
                        <label>{`Dificuldade: ${exercises.difficulty}`}</label>
                      </S.ContainerStatusActivity>
                    </>
                  ) : (
                    <S.ContainerStatusActivity>
                      <img src={NotFinishedCheck} alt="Não finalizada" />
                      <label>Atividade não concluída</label>
                    </S.ContainerStatusActivity>
                  )}
                </>
              </S.ElementVideoActVideos>
            </Col>
          ))}

          {tActivity.map(exercises => (
            <Col className="gutter-row" span={isBiggerThan ? 8 : 24}>
              <S.ElementVideoActVideos
                key={exercises.exerciseId}
                title={exercises.name}
              >
                <ReactPlayer
                  url={exercises.video_url}
                  width="180px"
                  height="120px"
                  light
                  title={exercises.name}
                />
                <S.Subtitles title={exercises.name}>
                  {stringFormater(exercises.name)}
                </S.Subtitles>
                <>
                  {exercises.pain !== null && exercises.pain !== -1 ? (
                    <>
                      <S.ContainerStatusActivity>
                        <img src={FinishedCheck} alt="Finalizada" />
                        <label>{exercises.status}</label>
                      </S.ContainerStatusActivity>
                      <S.ContainerStatusActivity>
                        <label>{`Dor: ${exercises.pain}`}</label>
                      </S.ContainerStatusActivity>
                      <S.ContainerStatusActivity>
                        <label>{`Dificuldade: ${exercises.difficulty}`}</label>
                      </S.ContainerStatusActivity>
                    </>
                  ) : (
                    <S.ContainerStatusActivity>
                      <img src={NotFinishedCheck} alt="Não finalizada" />
                      <label>Atividade não concluída</label>
                    </S.ContainerStatusActivity>
                  )}
                </>
              </S.ElementVideoActVideos>
            </Col>
          ))}
        </Row>
      </S.ContainerShowActivityVideos>
      <S.ContainerActivityInfo>
        <S.ElementActivityInfo>
          <Progress
            type="circle"
            percent={percentage * 100}
            format={percent => {}}
            width={23}
            strokeWidth={15}
            strokeColor="#118E88"
            trailColor="#D5D5D5"
          />
          <label>{`Concluiu ${percentage * 100}% dos exercícios`}</label>
        </S.ElementActivityInfo>
        <S.ElementActivityInfo>
          {saffetyStatus !== null && (
            <label>
              Se sentiu <strong>{saffetyStatus}</strong> ao executar a sessão de
              exercícios
            </label>
          )}
        </S.ElementActivityInfo>
        {sessionStatus === 5 && (
          <S.ElementActivityInfo>
            <img src={LovedSession} alt="gostou bastante da sessão" />
            <label>Gostou bastate da sessão.</label>
          </S.ElementActivityInfo>
        )}
        {sessionStatus === 4 && (
          <S.ElementActivityInfo>
            <img src={LikedSession} alt="gostou da sessao" />
            <label>Gostou da sessão.</label>
          </S.ElementActivityInfo>
        )}
        {sessionStatus === 3 && (
          <S.ElementActivityInfo>
            <img src={IndeciseAboutSession} alt="indeciso sobre a sessão." />
            <label>Indeciso sobre a sessão.</label>
          </S.ElementActivityInfo>
        )}
        {sessionStatus === 2 && (
          <S.ElementActivityInfo>
            <img src={DoNotLikedSession} alt="não gostou da sessao" />
            <label>Não gostou da sessão.</label>
          </S.ElementActivityInfo>
        )}
        {sessionStatus === 1 && (
          <S.ElementActivityInfo>
            <img src={HatedSession} alt="não gostou nem um pouco da sessao" />
            <label>Não gostou nem um pouco da sessão.</label>
          </S.ElementActivityInfo>
        )}
      </S.ContainerActivityInfo>
      {!isBiggerThan && (
        <>
          <br />
          <br />
        </>
      )}
      <S.Subtitles>Considerações do Paciente: </S.Subtitles>
      <S.NormalLabel>{considerations}</S.NormalLabel>
      <Divider />
    </>
  );
}
