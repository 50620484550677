export function setLoading(value) {
  return {
    type: '@payment/SET_LOADING',
    payload: { value },
  };
}

export function getSubscriptionStatus() {
  return {
    type: '@payment/GET_SUBSCRIPTION_STATUS',
  };
}

export function setSubscriptionStatus(status) {
  return {
    type: '@payment/SET_SUBSCRIPTION_STATUS',
    payload: { status },
  };
}

export function updateCard(card) {
  return {
    type: '@payment/UPDATE_CARD',
    payload: { card },
  };
}

export function purchasePlan(info) {
  return {
    type: '@payment/PURCHASE_PLAN',
    payload: { info },
  };
}

export function purchasePackage(info) {
  return {
    type: '@payment/PURCHASE_PACKAGE',
    payload: { info },
  };
}

export function cancelPlan() {
  return {
    type: '@payment/CANCEL_PLAN',
  };
}

export function contactUsPayment(quantProfessionals, observations) {
  return {
    type: '@payment/CONTACT_US_PAYMENT',
    payload: { quantProfessionals, observations },
  };
}
