import styled from 'styled-components';
import ImgBackgroundLogin from '../../assets/background-login.svg';

export const AntdStyles = {
  inputForm: {
    marginTop: '0.3rem',
    textAlign: 'left',
    font: 'normal normal normal 14px/19px Nunito Sans',
    letterSpacing: '0px',
    color: 'var(--jet)',
    display: 'flex',
  },

  buttonLogin: {
    background: 'var(--darkCyan) 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    marginTop: '1.2rem',
    width: '10rem',
  },

  buttonGoogle: {
    background: 'var(--white) 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    marginTop: '1.2rem',
    width: '14rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  buttonRegister: {
    background: 'var(--white) 0% 0% no-repeat padding-box',
    borderRadius: '4px',
    marginTop: '1.2rem',
    width: '11rem',
    color: 'var(--darkBlueGray)',
    border: '1px solid var(--darkBlueGray)',
  },
};

export const Background = styled.div`
  background-color: var(--darkCyan);
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-image: url(${ImgBackgroundLogin});
  background-size: cover;
`;

export const LoginLeft = styled.div`
  background-color: var(--guyabano);
  flex-direction: column;
  padding: 2.5rem;
  height: 100%;
  width: 60%;
  display: flex;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;

  @media (max-width: 950px) {
    width: 100%;
    display: none;
  }
`;

export const Title = styled.label`
  color: var(--ceil);
  font: normal normal bold 14px/19px 'Nunito Sans';
  text-align: left;
  letter-spacing: 0px;
  text-transform: uppercase;
`;

export const Description = styled.label`
  text-align: left;
  font: normal normal normal 14px/21px 'Nunito Sans';
  letter-spacing: 0px;
  color: var(--deepKoamaru);
  margin-top: 1rem;
`;

export const ImageMonitoreExercises = styled.img`
  background: transparent 0% 0% no-repeat padding-box;
  padding: 1rem;
  width: 85%;
  align-self: center;
  justify-self: center;
`;

export const LoginRight = styled.form`
  display: flex;
  background-color: var(--white);
  flex-direction: column;
  padding: 2.5rem;
  height: 100%;
  justify-content: space-evenly;
  width: 40%;

  @media (max-width: 950px) {
    width: 100%;
  }
`;

export const TextLogin = styled.text`
  color: var(--battleshipGrey);
  text-align: left;
  font: normal normal normal 14px/20px 'Nunito Sans';
  letter-spacing: 0px;
  color: #868686;
  opacity: 1;
`;

export const DescInput = styled.text`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal bold 14px/19px 'Nunito Sans';
  letter-spacing: 0px;
  color: #575757;
  opacity: 1;
  margin-top: 1rem;
`;

export const LogoImg = styled.img`
  position: absolute;
  bottom: 15px;
  right: 15px;
  width: 6.5rem;
`;

export const GoogleImg = styled.img`
  width: 16px;
`;

export const ButtonName = styled.strong`
  margin-left: 0.8rem;
`;

export const Container = styled.div`
  display: flex;
  width: 80vw;
  height: 80vh;

  @media (max-width: 950px) {
    flex-direction: column;
  }
`;

export const ErrorMessage = styled.label`
  color: #c12;
  font-size: 12px;
  margin-top: 2px;
`;
