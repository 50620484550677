export function patientBeingRaised(patient) {
  return {
    type: '@paient/PATIENT_BEING_SAVED',
    payload: { patient },
  };
}

export function savePatient(patient) {
  return {
    type: '@patient/SAVE_PATIENT',
    payload: { patient },
  };
}

export function getPatientsList() {
  return {
    type: '@patient/GET_PATIENTS_LIST',
  };
}

export function setPatientsList(patientList) {
  return {
    type: '@patient/SET_PATIENTS_LIST',
    payload: { patientList },
  };
}

export function setPatientLoading(status) {
  return {
    type: '@patient/SET_PATIENTS_LOADING',
    payload: { status },
  };
}

export function archivePatient(patientsID) {
  return {
    type: '@patient/ARCHIVE_PATIENT',
    payload: { patientsID },
  };
}

export function unarchivePatient(patientsID) {
  return {
    type: '@patient/UNARCHIVE_PATIENT',
    payload: { patientsID },
  };
}

export function deletePatient(patientID) {
  return {
    type: '@patient/DELETE_PATIENT',
    payload: { patientID },
  };
}

export function getPatientDetails(patientID) {
  return {
    type: '@patient/GET_PATIENT_DETAILS',
    payload: { patientID },
  };
}

export function getSilentPatientDetails(patientID) {
  return {
    type: '@patient/GET_SILENT_PATIENT_DETAILS',
    payload: { patientID },
  };
}

export function setPatientDetails(patientDetails) {
  return {
    type: '@patient/SET_PATIENT_DETAILS',
    payload: { patientDetails },
  };
}

export function savePatientDetails(patientDetails) {
  return {
    type: '@patient/SAVE_PATIENT_DETAILS',
    payload: { patientDetails },
  };
}

export function createPatient(patient) {
  return {
    type: '@patient/CREATE_PATIENT',
    payload: { patient },
  };
}

export function validateCPF(CPF) {
  return {
    type: '@patient/VALIDATE_CPF_PATIENT',
    payload: { CPF },
  };
}

export function disableCPF(isDisabled) {
  return {
    type: '@patient/DISABLE_CPF_PATIENT',
    payload: { isDisabled },
  };
}

export function sendCodeToPatient(patientId) {
  return {
    type: '@patient/SEND_CODE_TO_PATIENT',
    payload: { patientId },
  };
}

export function addPatientToClinic(patient) {
  return {
    type: '@patient/ADD_PATIENT_TO_CLINIC',
    payload: { patient },
  };
}

export function attributePatientToProfessional(submitList) {
  return {
    type: '@patient/ATTRIBUTE_PATIENT_TO_PRO',
    payload: { submitList },
  };
}
