/* eslint-disable no-lone-blocks */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import AppHeader from 'components/Header';
import SiderMenu from 'components/Siders/Professional/Sider';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import { Button, Layout, Radio, Tooltip } from 'antd';
import { getMonitoringData } from 'store/modules/monitor/actions';
import ReactPlayer from 'react-player';

import { ComputerVisionArray, ComputerVisionEnum } from 'utils/computerVision';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPatientDetails,
  setPatientLoading,
} from 'store/modules/patient/actions';

import * as S from './styles';
import { AddVideoModal } from './components/addVideoModal';

function PatientComputerVision() {
  const firstChange = useMediaQuery('(min-width: 900px)');

  const { id } = useParams();
  const dispatch = useDispatch();
  const monitoringData = useSelector(state => state.monitor.monitoringData);
  const loadingPatient = useSelector(state => state.patient.loading);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    dispatch(setPatientLoading(true));
    dispatch(getPatientDetails(id));
  }, []);

  function handleModalFalse() {
    setIsModalVisible(false);
  }

  return (
    <Layout>
      <AddVideoModal
        isModalVisible={isModalVisible}
        handleModalFalse={handleModalFalse}
      />
      <AppHeader />
      <SiderMenu>
        <div
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          {loadingPatient === true && (
            <S.DivContainer firstChange={firstChange}>
              <br />
              <>Carregando ... </>
            </S.DivContainer>
          )}

          {loadingPatient === false && (
            <S.DivContainer firstChange={firstChange}>
              <S.TitlePage>Visão Computacional (BETA) </S.TitlePage>

              <S.PDescript>
                A Visão Computacional é uma tecnologia inovadora e empregada no
                Monitore para análise da marcha. Essa solução foi desenvolvida e
                testada em nosso laboratório e no momento estamos
                disponibilizando uma versão BETA para um grupo seleto de
                usuários. Estimamos qualquer feedback com o intuito de
                contribuir com melhorias na ferramenta, portanto, sinta-se a
                vontade para dar sua opinião a respeito.
              </S.PDescript>

              {Object.keys(ComputerVisionEnum.Marcha).map(title => (
                <>
                  <div style={{ marginTop: '2rem' }}>
                    <S.TitleSession>{title}</S.TitleSession>
                  </div>
                  {ComputerVisionEnum.Marcha[title].map(
                    (instruction, index) => (
                      <S.PDescript>
                        {index + 1}. {instruction}
                      </S.PDescript>
                    ),
                  )}
                </>
              ))}
              <div style={{ marginTop: '2rem', marginBottom: '1rem' }}>
                <S.TitleSession>Videos de exemplo</S.TitleSession>
              </div>
              <S.ContainerVideos firstChange={firstChange}>
                <S.VideoElement>
                  <ReactPlayer
                    url="https://firebasestorage.googleapis.com/v0/b/nbweb-prod.appspot.com/o/barros-left-landscape.MOV?alt=media&token=d6c01a99-0c85-4ce5-aa3d-f276b715e72d"
                    width={310}
                    height={230}
                    muted
                    volume={0}
                    playing
                    loop
                  />
                  <label style={{ marginLeft: '15px' }}>
                    Lado Esquerdo: Começar com a perna
                  </label>
                  <label style={{ marginLeft: '15px' }}>esquerda.</label>
                </S.VideoElement>

                <S.VideoElement style={{ marginLeft: '10px' }}>
                  <ReactPlayer
                    url="https://firebasestorage.googleapis.com/v0/b/nbweb-prod.appspot.com/o/barros-right-landscape.MOV?alt=media&token=8e73e5ef-dfd9-43f9-99b6-c69b11c120dd"
                    width={310}
                    height={230}
                    muted
                    volume={0}
                    playing
                    loop
                  />
                  <label style={{ marginLeft: '15px' }}>
                    Lado Direito: Começar com a perna
                  </label>
                  <label style={{ marginLeft: '15px' }}>direita.</label>
                </S.VideoElement>
              </S.ContainerVideos>

              <Button
                type="primary"
                style={{
                  ...S.AntdStyles.ButtonAddPatient,
                  // cursor: 'not-allowed',
                }}
                onClick={() => setIsModalVisible(true)}
              >
                Enviar Vídeos para Análise
              </Button>
              <br />
              <br />
              <br />
            </S.DivContainer>
          )}
        </div>
      </SiderMenu>
    </Layout>
  );
}

export default PatientComputerVision;
