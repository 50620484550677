import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { unlinkProtocol } from 'store/modules/protocol/actions';
import { Menu, Dropdown, Button, Popconfirm } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import ReactPlayer from 'react-player';
import FormContext from '../../../Context/FormContext';
import * as S from './styles';

export function ProtocolItem({ id, name, type, exercises, active }) {
  const patientId = useParams().id;
  const [exeShown, setExeShown] = useState([]);
  const { changeComponent } = useContext(FormContext);
  const dispatch = useDispatch();
  const [popBackScreen, setPopBackScreen] = useState(false);
  const patientDetails = useSelector(state => state.patient.patientDetails);

  function handleClickMenu(e) {
    if (e === '1') {
      changeComponent('edit', id);
    }
    if (e === '2') {
      dispatch(unlinkProtocol(patientId, id));
    }
  }

  const menu = (
    <Menu>
      <Menu.Item onClick={() => handleClickMenu('1')}>Editar</Menu.Item>
      <Menu.Item onClick={() => setPopBackScreen(true)}>
        Desvincular do paciente
      </Menu.Item>
    </Menu>
  );

  function handleOk() {
    handleClickMenu('2');
  }

  function handleCancel() {
    setPopBackScreen(false);
  }

  useEffect(() => {
    if (exercises.length > 2) {
      setExeShown(exercises.slice(0, 3));
    } else {
      setExeShown(exercises);
    }
  }, []);

  return (
    <S.Container
      style={{ border: active === true ? '0.1px solid #7772B0' : '' }}
    >
      <Popconfirm
        title={`Deseja realmente desvincular este protocolo de ${patientDetails.name}?`}
        visible={popBackScreen}
        onConfirm={handleOk}
        onCancel={handleCancel}
      />
      <S.ContainerVideos>
        {exeShown.map(exe => (
          <S.Video>
            <ReactPlayer
              style={{ zIndex: '4' }}
              url={exe.video_url}
              width="100%"
              height="100%"
              light
            />
            <S.UntouchableScreen />
          </S.Video>
        ))}
      </S.ContainerVideos>
      <S.ContainerSubInfo>
        <strong>{name}</strong>
        <Dropdown overlay={menu}>
          <Button
            style={S.AntdStyles.DropDownButton}
            icon={<MoreOutlined />}
            size="large"
          />
        </Dropdown>
      </S.ContainerSubInfo>
      <span className="practice">
        {type === 'DOMESTIC' ? 'Prática Domiciliar' : 'Telechamada'}
      </span>
    </S.Container>
  );
}
