import { Checkbox } from 'antd';
import * as S from '../styles';
import '../styles.module.css';

function WaitForDataReceive({
  bodyElementsArray,
  goalsElementsArray,
  positionElementsArray,
  difficultyElementsArray,
  stack,
  onChangeBodyFilter,
  onChangePositionFilter,
  onChangeGoalsFilter,
  onChangeLevelFilter,
}) {
  return (
    <S.ContainerFilters>
      <S.ElementFilterActivities>
        <S.ElementTitleFilters>
          <li className="element-number-title">
            <span> - Objetivos</span>
          </li>
          <Checkbox.Group
            options={goalsElementsArray}
            onChange={onChangeGoalsFilter}
            value={stack.goals.values}
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
            }}
          />
          {/* <ul>{goalsElementsArray.map(item => item)}</ul> */}
        </S.ElementTitleFilters>
        <S.ElementTitleFiltersSmall>
          <li className="element-number-title">
            <span> - Posição</span>
          </li>
          {/* <ul>{positionElementsArray.map(item => item)}</ul> */}
          <Checkbox.Group
            options={positionElementsArray}
            onChange={onChangePositionFilter}
            value={stack.position.values}
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
            }}
          />
        </S.ElementTitleFiltersSmall>
      </S.ElementFilterActivities>
      <S.ElementFilterActivities>
        <S.ElementTitleFilters>
          <li className="element-number-title">
            <span> - Partes do Corpo</span>
          </li>
          {/* <ul>{bodyElementsArray.map(item => item)}</ul> */}
          <Checkbox.Group
            options={bodyElementsArray}
            onChange={onChangeBodyFilter}
            value={stack.body.values}
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
            }}
          />
        </S.ElementTitleFilters>
        <S.ElementTitleFiltersSmall>
          <li className="element-number-title">
            <span> - Nível</span>
          </li>
          {/* <ul>{difficultyElementsArray.map(item => item)}</ul> */}
          <Checkbox.Group
            options={difficultyElementsArray}
            onChange={onChangeLevelFilter}
            value={stack.level.values}
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
            }}
          />
        </S.ElementTitleFiltersSmall>
      </S.ElementFilterActivities>
    </S.ContainerFilters>
  );
}

export default WaitForDataReceive;
