/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

export const MessageLine = styled.div`
  flex-direction: column;
  margin: 0.5rem;
`;

export const MessageItem = styled.div`
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 0 1px 1px #ccc;
  display: flex;
  flex-direction: column;
  padding: 3px;
`;

export const MessageText = styled.div`
  font-size: 14px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  font: normal normal normal 14px/20px Nunito Sans;
`;

export const DateText = styled.div`
  font: normal normal normal 11px/20px Nunito Sans;
  color: var(--battleshipGrey);
  margin-right: 5px;
  text-align: 15px;
  height: 15px;
`;

export const MessageImage = styled.img`
  max-width: 300px;
  max-height: 300px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
`;

export const DayContainer = styled.div`
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: center;
`;

export const DayText = styled.div`
  color: var(--battleshipGrey);
  text-align: center;
  font: normal normal normal 14px/20px Nunito Sans;
`;
