import { Layout, Breadcrumb } from 'antd';

import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import { useSelector } from 'react-redux';

import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ArrowRight from 'assets/arrow-right-grey.svg';
import SiderMenu from './SiderMenu';
// import VideoCallContext from '../../pages/Anamnesis/Context';

import 'antd/dist/antd.less';
import './SiderMenu.css';
import * as S from './styles';

const { Content } = Layout;

function AppContent({ children, calledByVideoCall = false }) {
  const isBiggerThan = useMediaQuery('(min-width: 990px)');
  const location = useLocation();

  const infoDetails = useSelector(state => state.clinic.infoDetails);

  // const { calledByVideoCall } = useContext(VideoCallContext);

  const Page = useCallback(locationUrl => {
    if (locationUrl.includes('edit_info')) return 'Dados Cadastrais';
    return 'Dados Cadastrais';
  }, []);

  const Page2 = useCallback(locationUrl => {
    if (locationUrl.includes('add_event')) return 'Adicionar Evento';
    return 'Adicionar Evento';
  }, []);

  const [breadcrumbLevel2, setBreadcrumbLevel2] = useState(false);

  useEffect(() => {
    if (location.pathname.includes('add_event')) {
      setBreadcrumbLevel2(true);
    }
  }, []);

  return (
    <>
      <Content
        style={{
          display: 'flex',
          height: '100%',
          width: '100%',
          paddingTop: isBiggerThan ? '7%' : '17%',
          flexDirection: 'row',
          justifyContent: 'center',
          bottom: '0',
        }}
      >
        <div className="container-app-content-sider">
          {!calledByVideoCall && <SiderMenu />}
          <div className="container-patient-content">
            {!calledByVideoCall && (
              <Breadcrumb
                separator=""
                style={{
                  marginLeft: isBiggerThan ? '2rem' : '4rem',
                  width: 'calc(100% - 32px)',
                  borderBottom: '1px solid #D5D5D5',
                }}
              >
                <Breadcrumb.Item>
                  Pacientes
                  <S.BreadCrumbIcon src={ArrowRight} alt="#right" />
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  {infoDetails !== null ? infoDetails.name : 'Carregando...'}
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  style={{
                    color:
                      breadcrumbLevel2 === false
                        ? 'var(--deepKoamaru)'
                        : 'var(--guayabano)',
                    fontWeight: breadcrumbLevel2 === false ? 'bold' : '100',
                  }}
                >
                  <S.BreadCrumbIcon src={ArrowRight} alt="#right" />
                  {Page(location.pathname)}
                </Breadcrumb.Item>

                {breadcrumbLevel2 && (
                  <Breadcrumb.Item
                    style={{
                      color:
                        breadcrumbLevel2 === false
                          ? 'var(--guyabano)'
                          : 'var(--deepKoamaru)',
                      fontWeight: breadcrumbLevel2 === false ? '100' : 'bold',
                    }}
                  >
                    {Page2(location.pathname)}
                  </Breadcrumb.Item>
                )}
              </Breadcrumb>
            )}
            {children}
          </div>
        </div>
      </Content>
    </>
  );
}

export default AppContent;
