import styled from 'styled-components';

export const ContainerTimer = styled.div`
  display: flex;
  height: 20px;
  width: 80px;
  align-items: center;
  z-index: 20;

  label {
    font: normal normal normal 14px/16px Nunito Sans;
    &.gray-text-color {
      color: var(--philippineSilver);
    }

    &.red-text-color {
      color: red;
    }
  }
`;
