/* eslint-disable no-unused-vars */
import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  goals: null,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@goals/GET_PATIENT_GOALS': {
        draft.loading = true;
        break;
      }

      case '@goals/SET_PATIENT_GOALS': {
        draft.loading = false;
        draft.goals = action.payload.goals;
        break;
      }

      default:
    }
  });
}
