/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  notification,
  Input,
  Checkbox,
  AutoComplete,
  Button,
  Row,
  Col,
  TimePicker,
  Dropdown,
  Menu,
  InputNumber,
} from 'antd';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { getAddressByCep } from 'store/modules/CEP/actions';
import {
  PropsSpecialityEnum,
  PropsSpecialityTranslated,
  SpecialityTranslated,
} from 'utils/speciality';
import {
  PropsProfessionEnum,
  PropsProfessionArray,
  ProfessionTranslated,
  PropsProfessionTranslated,
  PropsProfessionArrayWithoutOther,
} from 'utils/profession';
import { updateUser } from 'store/modules/auth/actions';
import moment from 'moment';
import { StatesOfBrazil } from 'utils/States';
import { maskBuilderCPFCNPJ } from 'utils/maskValidator';
import ModalSpeciality from '../ModalSpeciality';
import * as S from './styles';

function DataGeneralTherapist({ secondChange }) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);
  const isMyAccountView = useSelector(state => state.auth.isMyAccountView);

  Yup.addMethod(Yup.string, 'defaultType', function (errorMessage) {
    return this.test(`test-default-type`, errorMessage, function (value) {
      const { path, createError } = this;

      return (
        value !== 'Selecione' || createError({ path, message: errorMessage })
      );
    });
  });

  const professionTranslated = useCallback(profession => {
    const result = PropsProfessionTranslated.find(p => p.type === profession)
      .pt;
    return result;
  }, []);

  const specialityTranslated = useCallback(speciality => {
    const result = PropsSpecialityTranslated.find(p => p.type === speciality)
      .pt;
    return result;
  }, []);

  const formikGeneralDataProfessional = {
    initialValues: {
      name: user?.name,
      email: user?.email,
      cellphone: user?.phone_number,
      speciality: user?.specialities,
      otherSpeciality: user.specialities.find(
        item => item.speciality.toLowerCase() === PropsSpecialityEnum.OTHER,
      )?.otherSpecialityName,
      profession:
        user.profession.profession.toLowerCase() ===
        PropsProfessionEnum.OTHERPROFESSION
          ? user.profession.otherProfessionName
          : ProfessionTranslated(user.profession.profession.toLowerCase()),
      street: user?.address?.street,
      houseNumber: user?.address?.number,
      complement: user?.address?.complement,
      neighborhood: user?.address?.neighborhood,
      city: user?.address?.city,
      state: user?.address?.state,
      postalCode: user?.address?.postal_code,
      document: user?.document,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('É obrigatório'),
      email: Yup.string().email('E-mail inválido').required('É obrigatório'),
      cellphone: Yup.string().required('É obrigatório'),
      street: Yup.string().required('É obrigatório'),
      houseNumber: Yup.number().required('É obrigatório'),
      complement: Yup.string().required('É obrigatório'),
      neighborhood: Yup.string().required('É obrigatório'),
      city: Yup.string().required('É obrigatório'),
      state: Yup.string().required('É obrigatório'),
      postalCode: Yup.string().required('É obrigatório'),
      document: Yup.string().required('É obrigatório'),
    }),
    onSubmit: values => {
      const userSubmit = values;
      if (
        userSubmit.speciality.find(
          item => item.speciality.toLowerCase() === PropsSpecialityEnum.OTHER,
        ) &&
        userSubmit.otherSpeciality === ''
      ) {
        notification.warning({
          message:
            'Você selecionou a opção outra especialidade e não especificou qual seria a especialidade, informe e tente novamente',
        });
        return;
      }
      if (userSubmit.speciality.length === 0) {
        notification.warning({
          message:
            'Você não selecionou nenhuma opção de especialidade, informe e tente novamente',
        });
        return;
      }
      const specialitiesArray = [];
      userSubmit.speciality.forEach(item => {
        if (item.speciality.toLowerCase() === PropsSpecialityEnum.OTHER) {
          specialitiesArray.push(userSubmit?.otherSpeciality);
        } else {
          specialitiesArray.push(
            specialityTranslated(item.speciality.toLowerCase()).toLowerCase(),
          );
        }
      });
      userSubmit.specialities = specialitiesArray;
      dispatch(updateUser(userSubmit));
    },
  };

  const [editSpeciality, setEditSpeciality] = useState(false);

  const cep = useSelector(state => state.CEP);
  const formikRef = useRef(null);

  useEffect(() => {
    if (isMyAccountView && cep.address !== null) {
      formikRef.current.setFieldValue('street', cep.address.logradouro);
      formikRef.current.setFieldValue('neighborhood', cep.address.bairro);
      formikRef.current.setFieldValue('city', cep.address.cidade);
      formikRef.current.setFieldValue('state', cep.address.estado_info.nome);
    }
  }, [cep]);

  return isMyAccountView ? (
    <Formik
      initialValues={formikGeneralDataProfessional.initialValues}
      validationSchema={formikGeneralDataProfessional.validationSchema}
      onSubmit={values => formikGeneralDataProfessional.onSubmit(values)}
      innerRef={formikRef}
    >
      {props => (
        <div>
          {editSpeciality && (
            <ModalSpeciality
              visible={editSpeciality}
              setVisible={setEditSpeciality}
              propsFormik={props}
              specialitiesUser={user.specialities}
            />
          )}
          <S.TitleSession>Dados Gerais</S.TitleSession>

          <Row gutter={[32, 16]} style={S.AntdStyles.marginTop2rem}>
            <Col span={secondChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Nome
                <Input
                  style={S.AntdStyles.marginTop}
                  id="name"
                  name="name"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.name}
                  onBlur={props.handleBlur}
                />
                {props.touched.name && props.errors.name ? (
                  <S.ErrorMessage>{props.errors.name}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>
            <Col span={secondChange ? 8 : 24}>
              <S.LabelText>E-mail</S.LabelText>
              <Input
                placeholder="exemplo@exemplo.com.br"
                style={S.AntdStyles.marginTop}
                id="email"
                name="email"
                type="email"
                onChange={props.handleChange}
                value={props.values.email}
                onBlur={props.handleBlur}
                disabled
              />
              {props.touched.email && props.errors.email ? (
                <S.ErrorMessage>{props.errors.email}</S.ErrorMessage>
              ) : null}
            </Col>

            <Col span={secondChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Telefone
                <InputMask
                  mask="(99)99999-9999"
                  style={S.AntdStyles.inputMask}
                  value={props.values.cellphone}
                  onChange={e =>
                    props.setFieldValue('cellphone', e.target.value)
                  }
                />
              </S.LabelText>
              {props.touched.cellphone && props.errors.cellphone ? (
                <S.ErrorMessage>{props.errors.cellphone}</S.ErrorMessage>
              ) : null}
            </Col>

            <Col className="gutter-row" span={secondChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Profissão
                <AutoComplete
                  style={S.AntdStyles.AutoComplete}
                  defaultValue={props.values.profession}
                  onChange={text => props.setFieldValue('profession', text)}
                  options={PropsProfessionArrayWithoutOther.map(
                    item =>
                      item && {
                        value: professionTranslated(PropsProfessionEnum[item]),
                      },
                  )}
                />
                {props.touched.profession && props.errors.profession ? (
                  <S.ErrorMessage>{props.errors.profession}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>
            <Col className="gutter-row" span={secondChange ? 8 : 24}>
              <S.LabelText className="input-label">
                CPF ou CNPJ
                <InputMask
                  mask={maskBuilderCPFCNPJ(props.values.document)}
                  style={S.AntdStyles.inputMask}
                  value={props.values.document}
                  onChange={e =>
                    props.setFieldValue(
                      'document',
                      e.target.value.replace(/\D/g, ''),
                    )
                  }
                />
                {props.touched.document && props.errors.document ? (
                  <S.ErrorMessage>{props.errors.document}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>
            <Col span={secondChange ? 16 : 24}>
              <S.LabelText className="input-label">
                <div>
                  Especialidades (
                  <S.EditSpeciality
                    onClick={() => setEditSpeciality(prevState => !prevState)}
                  >
                    Editar
                  </S.EditSpeciality>
                  )
                </div>
              </S.LabelText>
              <S.LabelSpeciality editSpeciality={editSpeciality} key={user}>
                {props.values.speciality.map(item =>
                  item.speciality === 'OTHER'
                    ? `${props.values.otherSpeciality}; `
                    : `${SpecialityTranslated(
                        PropsSpecialityEnum[item.speciality],
                      )}; `,
                )}
              </S.LabelSpeciality>
            </Col>
          </Row>
          <S.TitleSession style={{ marginTop: '4rem' }}>
            Endereço
          </S.TitleSession>

          <Row gutter={[32, 16]} style={S.AntdStyles.marginTop2rem}>
            <Col className="gutter-row" span={secondChange ? 8 : 24}>
              <S.LabelText className="input-label">
                CEP
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <InputMask
                    mask="99999-999"
                    defaultValue={props.values.postalCode}
                    style={S.AntdStyles.inputMask}
                    onChange={e =>
                      props.setFieldValue('postalCode', e.target.value)
                    }
                  />
                  <Button
                    type="primary"
                    style={S.AntdStyles.buttonSearchCEP}
                    onClick={() => {
                      dispatch(getAddressByCep(props.values.postalCode));
                    }}
                  >
                    Buscar
                  </Button>
                </div>
                {props.touched.postalCode && props.errors.postalCode ? (
                  <S.ErrorMessage>{props.errors.postalCode}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col className="gutter-row" span={secondChange ? 16 : 24}>
              <S.LabelText className="input-label">
                Rua
                <Input
                  style={S.AntdStyles.marginTop}
                  id="street"
                  name="street"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.street}
                  onBlur={props.handleBlur}
                />
                {props.touched.street && props.errors.street ? (
                  <S.ErrorMessage>{props.errors.street}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col className="gutter-row" span={secondChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Número
                <InputNumber
                  min={0}
                  style={S.AntdStyles.inputNumber}
                  value={props.values.houseNumber}
                  onChange={value => props.setFieldValue('houseNumber', value)}
                />
                {props.touched.houseNumber && props.errors.houseNumber ? (
                  <S.ErrorMessage>{props.errors.houseNumber}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col className="gutter-row" span={secondChange ? 16 : 24}>
              <S.LabelText className="input-label">
                Complemento
                <Input
                  style={S.AntdStyles.marginTop}
                  id="complement"
                  name="complement"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.complement}
                  onBlur={props.handleBlur}
                />
                {props.touched.complement && props.errors.complement ? (
                  <S.ErrorMessage>{props.errors.complement}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col className="gutter-row" span={secondChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Bairro
                <Input
                  style={S.AntdStyles.marginTop}
                  id="neighborhood"
                  name="neighborhood"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.neighborhood}
                  onBlur={props.handleBlur}
                />
                {props.touched.neighborhood && props.errors.neighborhood ? (
                  <S.ErrorMessage>{props.errors.neighborhood}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col className="gutter-row" span={secondChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Cidade
                <Input
                  style={S.AntdStyles.marginTop}
                  id="city"
                  name="city"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.city}
                  onBlur={props.handleBlur}
                />
                {props.touched.city && props.errors.city ? (
                  <S.ErrorMessage>{props.errors.city}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col className="gutter-row" span={secondChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Estado
                <Dropdown
                  overlay={
                    <Menu>
                      {StatesOfBrazil.map(item => (
                        <Menu.Item>
                          <element
                            onClick={() => props.setFieldValue('state', item)}
                          >
                            {item}
                          </element>
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                  placement="bottomCenter"
                >
                  <Button style={S.AntdStyles.ButtonTypePayment}>
                    {props.values.state}
                  </Button>
                </Dropdown>
                {props.touched.state && props.errors.state ? (
                  <S.ErrorMessage>{props.errors.state}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>
          </Row>

          <Button
            type="primary"
            htmlType="submit"
            style={S.AntdStyles.ButtonSave}
            onClick={props.handleSubmit}
          >
            Salvar Alterações
          </Button>
        </div>
      )}
    </Formik>
  ) : null;
}

export default DataGeneralTherapist;
