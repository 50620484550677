/* eslint-disable no-console */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Input, Button, Divider, notification } from 'antd';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
// import fire from 'services/fire';

import * as Yup from 'yup';
import ImgGoogle from '../../../../assets/google.svg';
import { PropsProfileEnum } from '../../../../utils/profile';

import * as S from './styles';

import {
  signInRequest,
  signInRequestGoogle,
} from '../../../../store/modules/auth/actions';

function Login({ setPage }) {
  const dispatch = useDispatch();

  const user = useSelector(state => state.auth.user);
  const loading = useSelector(state => state.auth.loading);

  useEffect(() => {
    if (user !== null) {
      if (user.profile === PropsProfileEnum.PATIENT) {
        notification.warning({
          message: 'Atenção!',
          description:
            'Identificamos que você é um paciente. Para usar o Monitore utilize o nosso aplicativo.',
        });
        return;
      }

      if (user.profile === PropsProfileEnum.NOTDEFINED) {
        setPage('register');
        notification.info({
          message: 'Atenção!',
          description:
            'É sua primeira vez. Para continuar finalize o cadastro.',
        });
        return;
      }

      if (user.checkFirstAccess === true) {
        setPage('register');
        notification.info({
          message: 'Atenção!',
          description:
            'É sua primeira vez. Para continuar finalize o cadastro.',
        });
      }
    }
  }, [user]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('E-mail inválido').required('É obrigatório'),
      password: Yup.string().required('É obrigatório'),
    }),
    onSubmit: values => {
      dispatch(signInRequest(values.email, values.password));
    },
  });

  return (
    <S.LoginRight onSubmit={formik.handleSubmit}>
      <S.TextLogin>
        Faça login ou crie uma conta para utilizar a nossa plataforma.
      </S.TextLogin>

      <S.DescInput>E-mail</S.DescInput>
      <Input
        placeholder="exemplo@exemplo.com.br"
        style={S.AntdStyles.inputForm}
        id="email"
        name="email"
        type="email"
        onChange={formik.handleChange}
        value={formik.values.email}
        onBlur={formik.handleBlur}
      />
      {formik.touched.email && formik.errors.email ? (
        <S.ErrorMessage>{formik.errors.email}</S.ErrorMessage>
      ) : null}

      <S.DescInput>Senha</S.DescInput>
      <Input.Password
        placeholder="Digite a sua senha"
        style={S.AntdStyles.inputForm}
        id="password"
        name="password"
        type="password"
        onChange={formik.handleChange}
        value={formik.values.password}
        onBlur={formik.handleBlur}
      />
      <S.ReconverPassword onClick={() => setPage('recoverPassword')}>
        Esqueci minha senha
      </S.ReconverPassword>

      {formik.touched.password && formik.errors.password ? (
        <S.ErrorMessage>{formik.errors.password}</S.ErrorMessage>
      ) : null}

      <Button
        type="primary"
        htmlType="submit"
        style={S.AntdStyles.buttonLogin}
        loading={loading}
      >
        Entrar
      </Button>

      <S.TextLogin style={{ paddingTop: '2rem' }}>Você também pode</S.TextLogin>

      <Button
        style={S.AntdStyles.buttonGoogle}
        onClick={() => dispatch(signInRequestGoogle())}
      >
        <S.GoogleImg src={ImgGoogle} alt="Google" />
        <S.ButtonName>Fazer login com o Google</S.ButtonName>
      </Button>

      <Divider />

      <S.TextLogin>Caso ainda não possua uma conta</S.TextLogin>

      <Button
        style={S.AntdStyles.buttonRegister}
        onClick={() => setPage('register')}
      >
        <strong>Cadastre-se agora</strong>
      </Button>
    </S.LoginRight>
  );
}

export default Login;
