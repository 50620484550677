import { useState, useEffect } from 'react';
import AppHeader from 'components/Header';
import SiderMenu from 'components/Siders/Professional/Sider';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import { Layout, Radio } from 'antd';
import { getMonitoringData } from 'store/modules/monitor/actions';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientDetails } from 'store/modules/patient/actions';
import { PerformedEvaluations } from './components/PerformedEvaluations';
import { PerformedHomePractices } from './components/PerformedHomePractices';
import { PerformedOnlinePractices } from './components/PerformedOnlinePractices';
import FormContext from './components/Context/FormContext';

import * as S from './styles';
import { PerformedComputerVision } from './components/PerformedComputerVision';

function PatientMonitoring() {
  const firstChange = useMediaQuery('(min-width: 900px)');

  const { id } = useParams();
  const dispatch = useDispatch();
  const monitoringData = useSelector(state => state.monitor.monitoringData);
  const loadingPatient = useSelector(state => state.patient.loading);

  const [choiceTypeMonitor, setChoiceTypeMonitor] = useState('Avaliações');

  useEffect(() => {
    dispatch(getMonitoringData(id));
  }, []);

  useEffect(() => {
    dispatch(getPatientDetails(id));
  }, []);

  return (
    <Layout>
      <AppHeader />
      <SiderMenu>
        <div
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          {loadingPatient === false && monitoringData !== null && (
            <FormContext.Provider value={{ monitoringData }}>
              <S.DivContainer firstChange={firstChange}>
                <S.TitlePage>Acompanhar</S.TitlePage>
                <Radio.Group
                  value={choiceTypeMonitor}
                  onChange={e => setChoiceTypeMonitor(e.target.value)}
                  style={{
                    display: 'flex',
                    flexDirection: firstChange ? 'row' : 'column',
                    marginTop: '0.5rem',
                    marginBottom: firstChange ? '1rem' : 0,
                    minHeight: firstChange ? '30px' : '140px',
                    justifyContent: firstChange
                      ? 'flex-start'
                      : 'space-between',
                  }}
                >
                  <Radio.Button
                    value="Avaliações"
                    style={
                      choiceTypeMonitor === 'Avaliações'
                        ? S.AntdStyles.RadioButtonLeftAble
                        : S.AntdStyles.RadioButtonLeftDisable
                    }
                  >
                    Avaliações
                  </Radio.Button>
                  <Radio.Button
                    value="Práticas Domicilares"
                    style={
                      choiceTypeMonitor === 'Práticas Domicilares'
                        ? S.AntdStyles.RadioButtonMiddleAble
                        : S.AntdStyles.RadioButtonMiddleDisable
                    }
                  >
                    Práticas Domiciliares
                  </Radio.Button>
                  <Radio.Button
                    value="Telechamada"
                    style={
                      choiceTypeMonitor === 'Telechamada'
                        ? S.AntdStyles.RadioButtonMiddleAble
                        : S.AntdStyles.RadioButtonMiddleDisable
                    }
                  >
                    Telechamada
                  </Radio.Button>
                  <Radio.Button
                    value="Visão Computacional"
                    style={
                      choiceTypeMonitor === 'Visão Computacional'
                        ? S.AntdStyles.RadioButtonRigthAble
                        : S.AntdStyles.RadioButtonRigthDisable
                    }
                  >
                    Visão Computacional
                  </Radio.Button>
                </Radio.Group>

                {choiceTypeMonitor === 'Avaliações' && <PerformedEvaluations />}

                {choiceTypeMonitor === 'Práticas Domicilares' && (
                  <PerformedHomePractices />
                )}

                {choiceTypeMonitor === 'Telechamada' && (
                  <PerformedOnlinePractices />
                )}

                {choiceTypeMonitor === 'Visão Computacional' && (
                  <PerformedComputerVision />
                )}
              </S.DivContainer>
            </FormContext.Provider>
          )}
          {monitoringData === null && <div>Carregando ... </div>}
          <br />
          <br />
          <br />
        </div>
      </SiderMenu>
    </Layout>
  );
}

export default PatientMonitoring;
