/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  notification,
  Input,
  Menu,
  AutoComplete,
  Button,
  Row,
  Col,
  Dropdown,
} from 'antd';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import {
  PropsSpecialityEnum,
  SpecialityTranslated,
  SpecialityTranslatedFromArray,
} from 'utils/speciality';
import {
  PropsProfessionEnum,
  PropsProfessionArray,
  ProfessionTranslated,
  PropsProfessionTranslated,
  PropsProfessionArrayWithoutOther,
} from 'utils/profession';
import { updateUser } from 'store/modules/auth/actions';
import moment from 'moment';
import { StatesOfBrazil } from 'utils/States';
import { getAddressByCep } from 'store/modules/CEP/actions';
import ModalSpeciality from '../ModalSpeciality';
import * as S from './styles';
import { maskBuilderCPFCNPJ } from '../../../../../utils/maskValidator';

function DataGeneral({ secondChange }) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth.user);

  const professionTranslated = useCallback(profession => {
    const result = PropsProfessionTranslated.find(p => p.type === profession)
      .pt;
    return result;
  }, []);

  const formikGeneralData = {
    initialValues: {
      name: user.name,
      cellphone: user.phone_number,
      speciality: user?.specialities,
      otherSpeciality: user.specialities.find(
        item => item.speciality.toLowerCase() === PropsSpecialityEnum.OTHER,
      )?.otherSpecialityName,
      profession:
        user.profession.profession.toLowerCase() ===
        PropsProfessionEnum.OTHERPROFESSION
          ? user.profession.otherProfessionName
          : ProfessionTranslated(user.profession.profession.toLowerCase()),
      street: user.address.street,
      houseNumber: user.address.number,
      complement: user.address.complement,
      neighborhood: user.address.neighborhood,
      city: user.address.city,
      postalCode: user.address.postal_code,
      state: user.address.state === null ? 'Selecione' : user.address.state,
      document: user.document,
      email: user.email,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('É obrigatório'),
      cellphone: Yup.string().required('É obrigatório'),
      profession: Yup.string().required('É obrigatório'),
      speciality: Yup.array()
        .min(1, 'Selecione no mínimo uma especialidade')
        .required('É obrigatório'),
      otherProfession: Yup.string().when('profession', {
        is: PropsProfessionEnum.OTHERPROFESSION,
        then: Yup.string().required('É obrigatório'),
      }),
      street: Yup.string().required('É obrigatório'),
      houseNumber: Yup.string().required('É obrigatório'),
      complement: Yup.string().required('É obrigatório'),
      neighborhood: Yup.string().required('É obrigatório'),
      city: Yup.string().required('É obrigatório'),
      state: Yup.string().required('É obrigatório'),
      postalCode: Yup.string().required('É obrigatório'),
      document: Yup.string().required('É obrigatório'),
    }),
    onSubmit: values => {
      const userSubmit = values;
      if (
        userSubmit.speciality.find(
          item => item.speciality.toLowerCase() === PropsSpecialityEnum.OTHER,
        ) &&
        userSubmit.otherSpeciality === ''
      ) {
        notification.warning({
          message:
            'Você selecionou a opção outra especialidade e não especificou qual seria a especialidade, informe e tente novamente',
        });
        return;
      }
      if (userSubmit.speciality.length === 0) {
        notification.warning({
          message:
            'Você não selecionou nenhuma opção de especialidade, informe e tente novamente',
        });
        return;
      }
      const specialitiesArray = [];
      userSubmit.speciality.forEach(item => {
        if (item.speciality.toLowerCase() === PropsSpecialityEnum.OTHER) {
          specialitiesArray.push(userSubmit?.otherSpeciality);
        } else {
          specialitiesArray.push(
            SpecialityTranslated(item.speciality.toLowerCase()).toLowerCase(),
          );
        }
      });
      userSubmit.specialities = specialitiesArray;

      dispatch(updateUser(userSubmit));
    },
  };

  const [editSpeciality, setEditSpeciality] = useState(false);
  const cep = useSelector(state => state.CEP);
  const formikRef = useRef(null);

  useEffect(() => {
    if (cep.address !== null) {
      formikRef.current.setFieldValue('street', cep.address.logradouro);
      formikRef.current.setFieldValue('neighborhood', cep.address.bairro);
      formikRef.current.setFieldValue('city', cep.address.cidade);
      formikRef.current.setFieldValue('state', cep.address.estado_info.nome);
    }
  }, [cep]);

  return (
    <Formik
      initialValues={formikGeneralData.initialValues}
      validationSchema={formikGeneralData.validationSchema}
      onSubmit={values => formikGeneralData.onSubmit(values)}
      innerRef={formikRef}
    >
      {props => (
        <div style={{ paddingBottom: '3rem' }}>
          {editSpeciality && (
            <ModalSpeciality
              visible={editSpeciality}
              setVisible={setEditSpeciality}
              propsFormik={props}
              specialitiesUser={user.specialities}
            />
          )}
          <S.TitleSession>Dados Gerais</S.TitleSession>

          <Row gutter={[32, 16]} style={S.AntdStyles.marginTop2rem}>
            <Col span={secondChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Nome
                <Input
                  style={S.AntdStyles.marginTop}
                  id="name"
                  name="name"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.name}
                  onBlur={props.handleBlur}
                />
                {props.touched.name && props.errors.name ? (
                  <S.ErrorMessage>{props.errors.name}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col span={secondChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Telefone
                <InputMask
                  mask="(99)99999-9999"
                  style={S.AntdStyles.inputMask}
                  value={props.values.cellphone}
                  onChange={e =>
                    props.setFieldValue('cellphone', e.target.value)
                  }
                />
              </S.LabelText>
              {props.touched.cellphone && props.errors.cellphone ? (
                <S.ErrorMessage>{props.errors.cellphone}</S.ErrorMessage>
              ) : null}
            </Col>

            <Col className="gutter-row" span={secondChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Profissão
                <AutoComplete
                  style={S.AntdStyles.AutoComplete}
                  defaultValue={props.values.profession}
                  onChange={text => props.setFieldValue('profession', text)}
                  options={PropsProfessionArrayWithoutOther.map(
                    item =>
                      item && {
                        value: professionTranslated(PropsProfessionEnum[item]),
                      },
                  )}
                />
                {props.touched.profession && props.errors.profession ? (
                  <S.ErrorMessage>{props.errors.profession}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>
            <Col span={secondChange ? 16 : 24}>
              <S.LabelText className="input-label">
                <div>
                  Especialidades (
                  <S.EditSpeciality
                    onClick={() => setEditSpeciality(prevState => !prevState)}
                  >
                    Editar
                  </S.EditSpeciality>
                  )
                </div>
              </S.LabelText>
              <S.LabelSpeciality editSpeciality={editSpeciality} key={user}>
                {props.values.speciality.map(item =>
                  item.speciality === 'OTHER'
                    ? `${props.values.otherSpeciality}; `
                    : `${SpecialityTranslated(
                        PropsSpecialityEnum[item.speciality],
                      )}; `,
                )}
              </S.LabelSpeciality>
            </Col>

            <Col span={secondChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Documento
                <InputMask
                  mask="999.999.999-99"
                  style={S.AntdStyles.inputMask}
                  value={props.values.document}
                  onChange={e =>
                    props.setFieldValue('document', e.target.value)
                  }
                />
                {props.touched.document && props.errors.document ? (
                  <S.ErrorMessage>{props.errors.document}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col span={secondChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Email
                <Input
                  style={S.AntdStyles.marginTop}
                  id="email"
                  name="email"
                  type="text"
                  value={props.values.email}
                  onBlur={props.handleBlur}
                  disabled
                />
              </S.LabelText>
            </Col>

            <Col span={secondChange ? 8 : 24}>
              <S.LabelText className="input-label">
                CEP
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <InputMask
                    mask="99999-999"
                    defaultValue={props.values.postalCode}
                    style={S.AntdStyles.inputMask}
                    onChange={e =>
                      props.setFieldValue('postalCode', e.target.value)
                    }
                  />
                  <Button
                    type="primary"
                    style={S.AntdStyles.buttonSearchCEP}
                    onClick={() => {
                      dispatch(getAddressByCep(props.values.postalCode));
                    }}
                  >
                    Buscar
                  </Button>
                </div>
                {props.touched.postalCode && props.errors.postalCode ? (
                  <S.ErrorMessage>{props.errors.postalCode}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col span={secondChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Rua
                <Input
                  style={S.AntdStyles.inputForm}
                  id="street"
                  name="street"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.street}
                  onBlur={props.handleBlur}
                />
                {props.touched.street && props.errors.street ? (
                  <S.ErrorMessage>{props.errors.street}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col span={secondChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Número
                <Input
                  style={S.AntdStyles.inputForm}
                  id="houseNumber"
                  name="houseNumber"
                  type="text"
                  onChange={props.handleChange}
                  defaultValue={props.values.houseNumber}
                  onBlur={props.handleBlur}
                />
                {props.touched.houseNumber && props.errors.houseNumber ? (
                  <S.ErrorMessage>{props.errors.houseNumber}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col span={secondChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Complemento
                <Input
                  style={S.AntdStyles.inputForm}
                  id="complement"
                  name="complement"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.complement}
                  onBlur={props.handleBlur}
                />
                {props.touched.complement && props.errors.complement ? (
                  <S.ErrorMessage>{props.errors.complement}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col span={secondChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Estado
                <Dropdown
                  overlay={
                    <Menu style={S.AntdStyles.menuStates}>
                      {StatesOfBrazil.map(item => (
                        <Menu.Item
                          onClick={() => props.setFieldValue('state', item)}
                        >
                          {item}
                        </Menu.Item>
                      ))}
                    </Menu>
                  }
                >
                  <Button style={S.AntdStyles.ButtonTypePayment}>
                    {props.values.state}
                  </Button>
                </Dropdown>
                {props.touched.state && props.errors.state ? (
                  <S.ErrorMessage>{props.errors.state}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col span={secondChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Cidade
                <Input
                  style={S.AntdStyles.inputForm}
                  id="city"
                  name="city"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.city}
                  onBlur={props.handleBlur}
                />
                {props.touched.city && props.errors.city ? (
                  <S.ErrorMessage>{props.errors.city}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>

            <Col span={secondChange ? 8 : 24}>
              <S.LabelText className="input-label">
                Bairro
                <Input
                  style={S.AntdStyles.inputForm}
                  id="neighborhood"
                  name="neighborhood"
                  type="text"
                  onChange={props.handleChange}
                  value={props.values.neighborhood}
                  onBlur={props.handleBlur}
                />
                {props.touched.neighborhood && props.errors.neighborhood ? (
                  <S.ErrorMessage>{props.errors.neighborhood}</S.ErrorMessage>
                ) : null}
              </S.LabelText>
            </Col>
          </Row>

          <Button
            type="primary"
            htmlType="submit"
            style={S.AntdStyles.ButtonSave}
            onClick={props.handleSubmit}
          >
            Salvar Alterações
          </Button>
        </div>
      )}
    </Formik>
  );
}

export default DataGeneral;
