import { Input, Button, Divider } from 'antd';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import * as S from './styles';

import { updatePassword } from '../../../../store/modules/auth/actions';

function RecoverPassword({ setPage }) {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('E-mail inválido').required('É obrigatório'),
    }),
    onSubmit: values => {
      dispatch(updatePassword(values.email));
    },
  });

  return (
    <S.LoginRight onSubmit={formik.handleSubmit}>
      <S.TextLogin>
        Recupere sua senha digitando seu email e conferindo sua caixa de
        entrada.
      </S.TextLogin>

      <S.DescInput>E-mail</S.DescInput>
      <Input
        placeholder="exemplo@exemplo.com.br"
        style={S.AntdStyles.inputForm}
        id="email"
        name="email"
        type="email"
        onChange={formik.handleChange}
        value={formik.values.email}
        onBlur={formik.handleBlur}
      />
      {formik.touched.email && formik.errors.email ? (
        <S.ErrorMessage>{formik.errors.email}</S.ErrorMessage>
      ) : null}

      <Button type="primary" htmlType="submit" style={S.AntdStyles.buttonLogin}>
        Me envie um e-mail
      </Button>

      <Divider />

      <S.TextLogin>Caso queira fazer login</S.TextLogin>
      <Button
        style={S.AntdStyles.buttonRegister}
        onClick={() => {
          setPage('login');
        }}
      >
        <strong>Login</strong>
      </Button>
    </S.LoginRight>
  );
}

export default RecoverPassword;
