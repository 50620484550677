/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable no-empty */
import { takeLatest, call, all, put, select } from 'redux-saga/effects';
import { notification } from 'antd';
import { addHours, getMonth, getYear } from 'date-fns';
import api from '../../../services/api';
import {
  eraseScheduleClinic,
  setConsultsData,
  setMonthComparative,
  setScheduleClinic,
  getScheduleClinic as getScheduleClinicAction,
  getScheduleClinicTwoMonth as getScheduleClinicTwoMonthAction,
} from './actions';

export function* addNewEventClinic({ payload }) {
  try {
    const {
      professionalId,
      patientId,
      date,
      duration,
      typeConsult,
      details,
    } = payload.event;

    const addEvent = yield call(api.post, `/clinic_add_new_event`, {
      schedule: {
        professionalId,
        patientId,
        duration,
        timestamp: date,
        title: typeConsult,
        details,
        timezone: new Date().getTimezoneOffset(),
      },
    });
    if (addEvent !== undefined) {
      notification.success({
        message: 'Agendamento criado com sucesso!',
      });
    }
    yield put(
      getScheduleClinicAction(
        new Date(date).getMonth() + 1,
        new Date(date).getFullYear(),
      ),
    );
  } catch (err) {
    notification.error({
      message:
        'Houve um erro ao tentar adicionar um agendamento, tente novamente!',
    });
  }
}

export function* getScheduleClinic({ payload }) {
  const { month, year } = payload;

  try {
    const schedule = yield call(
      api.get,
      `/get_schedule_to_clinic/month/${month}/year/${year}/timezone/${new Date().getTimezoneOffset()}`,
    );
    yield put(setScheduleClinic(schedule.data.events));
  } catch (err) {
    notification.error({
      message:
        'Houve um erro ao tentar recuperar sua agenda, se este erro persistir entre em contato.',
    });
  }
}

export function* getScheduleClinicTwoMonth({ payload }) {
  const { monthInit, yearInit, monthEnd, yearEnd } = payload;

  try {
    const schedule1 = yield call(
      api.get,
      `/get_schedule_to_clinic/month/${monthInit}/year/${yearInit}/timezone/${new Date().getTimezoneOffset()}`,
    );

    const schedule2 = yield call(
      api.get,
      `/get_schedule_to_clinic/month/${monthEnd}/year/${yearEnd}/timezone/${new Date().getTimezoneOffset()}`,
    );

    const schedule = [...schedule1.data.events, ...schedule2.data.events];

    yield put(setScheduleClinic(schedule));
  } catch (err) {
    notification.error({
      message:
        'Houve um erro ao tentar recuperar sua agenda, se este erro persistir entre em contato.',
    });
  }
}

export function* eventStatusUpdate({ payload }) {
  try {
    const {
      eventId,
      patientId,
      status,
      month1,
      year1,
      month2,
      year2,
    } = payload;

    const statusUpdate = yield call(api.put, `/event_status`, {
      patientId,
      eventId,
      status,
    });
    if (statusUpdate !== undefined) {
      notification.success({
        message: 'Agendamento atualizado com sucesso!',
      });
    }

    if (month2 !== '') {
      yield put(getScheduleClinicTwoMonthAction(month1, year1, month2, year2));
    } else {
      yield put(getScheduleClinicAction(month1, year1));
    }
  } catch (err) {
    notification.error({
      message:
        'Houve um erro ao tentar atualizar um agendamento, tente novamente!',
    });
  }
}

export function* getConsultsDataSaga({ payload }) {
  const { value, isByProfissional, startDate, endDate } = payload;

  try {
    if (isByProfissional) {
      const response = yield call(
        api.get,
        `/data_professional_consultation?initialDate=${startDate}&endDate=${endDate}&professionalId=${value}`,
      );
      yield put(setConsultsData(response.data.consultationInfo));
    } else {
      const response = yield call(
        api.get,
        `/data_specialities_consultation?initialDate=${startDate}&endDate=${endDate}&speciality=${value}`,
      );
      yield put(setConsultsData(response.data.consultationInfo));
    }
    // yield put(setConsultsData(response.data.professionals));
  } catch (err) {}
}

export function* getMonthComparativeSaga({ payload }) {
  const { year } = payload;

  try {
    const response = yield call(
      api.get,
      `/data_year_consultation/year/${year}`,
    );
    yield put(setMonthComparative(response.data.consultationInfo));
  } catch (err) {}
}

export function* deleteEventClinic({ payload }) {
  try {
    const { patientId, eventId, batch, date } = payload;

    yield call(
      api.delete,
      `/patient/${patientId}/event/${eventId}/batch/${batch}`,
    );
    yield put(
      getScheduleClinicAction(
        getMonth(addHours(new Date(date), 12)) + 1,
        getYear(addHours(new Date(date), 12)),
      ),
    );
  } catch (err) {
    notification.error({
      message:
        'Houve um erro ao tentar deletar este evento da agenda, tente novamente. Se o erro persistir contate o suporte!',
    });
  }
}

export default all([
  takeLatest('@clinicagenda/ADD_EVENT_CLINIC', addNewEventClinic),
  takeLatest('@clinicagenda/DELETE_EVENT_CLINIC', deleteEventClinic),
  takeLatest('@clinicagenda/GET_SCHEDULE_CLINIC', getScheduleClinic),
  takeLatest(
    '@clinicagenda/GET_SCHEDULE_CLINIC_TWO_MONTH',
    getScheduleClinicTwoMonth,
  ),
  takeLatest('@clinicagenda/EVENT_STATUS_UPDATE', eventStatusUpdate),
  takeLatest('@clinicagenda/GET_MONTH_COMPARATIVE', getMonthComparativeSaga),
  takeLatest('@clinicagenda/GET_CONSULTS_DATA', getConsultsDataSaga),
]);
