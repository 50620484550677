/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

export const TitlePage = styled.h5`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal normal 20px/27px Nunito Sans;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

export const TitleSession = styled.label`
  opacity: 1;
  color: var(--deepKoamaru);
  text-align: start;
  font: normal normal bold 14px/19px Nunito Sans;
  letter-spacing: 0px;
  background: var(--lavender) 0% 0% no-repeat padding-box;
  border-left: solid var(--deepKoamaru) 2px;
  border-radius: 0 1px 1px 0;
  padding: 3px 8px 3px 5px;
  height: 25px;
`;

export const AntdStyles = {
  marginTop: {
    marginTop: '4px',
  },

  RadioButtonActiveAble: {
    width: 'auto',
    textAlign: 'center',
    borderColor: '#6864A4',
    borderWidth: '1px',
    backgroundColor: '#f7f7f7',
    fontWeight: 'bold',
    color: '#6864A4',
    borderRadius: '4px 0px 0px 4px',
  },

  RadioButtonActiveDisable: {
    width: 'auto',
    textAlign: 'center',
    borderWidth: '1px',
    backgroundColor: '#f7f7f7',
    fontWeight: 'bold',
    color: '#868686',
    borderRadius: '4px 0px 0px 4px',
  },

  RadioButtonArchivedAble: {
    width: 'auto',
    textAlign: 'center',
    borderColor: '#6864A4',
    borderWidth: '1px',
    backgroundColor: '#f7f7f7',
    fontWeight: 'bold',
    color: '#6864A4',
    borderRadius: '0px 4px 4px 0px',
  },

  RadioButtonArchivedDisable: {
    width: 'auto',
    textAlign: 'center',
    borderWidth: '1px',
    backgroundColor: '#f7f7f7',
    fontWeight: 'bold',
    color: '#868686',
    borderRadius: '0px 4px 4px 0px',
  },

  RadioButtonMiddleArchivedAble: {
    width: 'auto',
    textAlign: 'center',
    borderColor: '#6864A4',
    borderWidth: '1px',
    backgroundColor: '#f7f7f7',
    fontWeight: 'bold',
    color: '#6864A4',
    borderRadius: '0px 0px 0px 0px',
  },

  RadioButtonMiddleArchivedDisable: {
    width: 'auto',
    textAlign: 'center',
    borderWidth: '1px',
    backgroundColor: '#f7f7f7',
    fontWeight: 'bold',
    color: '#868686',
    borderRadius: '0px 0px 0px 0px',
  },

  ButtonExams: {
    marginTop: '4px',
    textAlign: 'left',
    fontFamily: 'Nunito Sans',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '4px',
  },

  menuExams: { maxHeight: 200, overflowY: 'scroll' },
};

export const DivContainer = styled.div`
  margin-left: ${props => (props.firstChange ? '2rem' : '4rem')};
`;

export const DivCol = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LabelTitle = styled.label`
  text-align: left;
  font: normal normal bold 14px/19px Nunito Sans;
  letter-spacing: 0px;
  color: #575757;
  opacity: 1;
`;

export const LabelText = styled.div`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal bold 14px/19px Nunito Sans;
  letter-spacing: 0px;
  display: flex;
  flex-direction: column;
  width: 28rem;
`;

export const ImgDropdown = styled.img`
  width: 8px;
  height: 4px;
`;

export const PDescript = styled.p`
  text-align: left;
  font: normal normal normal 12px/18px Nunito Sans;
  letter-spacing: 0px;
  color: #575757;
  opacity: 1;
  width: 70%;
  margin: 0.75rem 0 0 0.75rem;
`;

export const ImageFilterActivities = styled.img`
  width: 20px;
  cursor: pointer;
  margin-right: 4px;
`;

export const DivFilter = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: var(--darkCyan);
  margin-left: 0.5rem;
  cursor: pointer;
  min-width: 140px;
  margin-bottom: 4px;
`;
