import styled from 'styled-components';

export const DescInput = styled.text`
  color: var(--davysGrey);
  text-align: left;
  font: normal normal bold 14px/19px 'Nunito Sans';
  letter-spacing: 0px;
  color: #575757;
  opacity: 1;
  margin-top: 1rem;
`;

export const ErrorMessage = styled.label`
  color: #c12;
  font-size: 12px;
  margin-top: 2px;
`;

export const DivMarginTop = styled.div`
  margin-top: 1rem;
`;

export const AntdStyles = {
  iconClose: { marginRight: 8, width: 15, marginBottom: 4 },
};
