/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable import/order */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import { Layout } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import api from 'services/api';
import alertGray from 'assets/gray-alert.svg';

import AppHeader from 'components/Header';

import { refreshToken } from 'store/modules/auth/actions';
import * as S from './styles';

function NotVinculatedToClinic() {
  const dispatch = useDispatch();
  const history = useHistory();
  const firstChange = useMediaQuery('(min-width: 992px)');
  const secondChange = useMediaQuery('(min-width: 830px)');
  const thirdChange = useMediaQuery('(min-width: 480px)');
  const user = useSelector(state => state.auth.user);

  // Quando o token expira
  api.interceptors.response.use(
    response => response,
    error => {
      if (error.message === 'Request failed with status code 401') {
        dispatch(refreshToken());
      }
      Promise.reject(error);
    },
  );

  return (
    <Layout>
      <AppHeader />
      <S.Container mediaFirst={firstChange} mediaThird={thirdChange}>
        <img
          src={alertGray}
          onClick={() => {}}
          style={{ cursor: 'pointer', height: '10rem', marginBottom: '2rem' }}
        />
        <S.AlertText>
          Você não está associado a nenhuma clínica. Para utilizar os serviços
          de clínica do Monitore, se vincule com uma clínica através do convite.
        </S.AlertText>
      </S.Container>
    </Layout>
  );
}

export default NotVinculatedToClinic;
