import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { unlinkProtocol } from 'store/modules/protocol/actions';
import { Menu, Dropdown, Button, Popconfirm } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import ReactPlayer from 'react-player';

import { addPracticeToEvent } from 'store/modules/agenda/actions';

import FormContext from '../Context/FormContext';

import * as S from './styles';

export function ProtocolItem({ id, name, type, exercises, active }) {
  const patientId = useParams().id;
  const [exeShown, setExeShown] = useState([]);
  const dispatch = useDispatch();
  const [popBackScreen, setPopBackScreen] = useState(false);
  const patientDetails = useSelector(state => state.patient.patientDetails);
  const [stackAddedActivities, setStackAddedActivities] = useState(
    useSelector(state => state.agenda.practice),
  );
  const practiceId = useSelector(state => state.agenda.practiceId);
  // const [isToInsert, setToInsert] = useState(false);

  const { changeComponent } = useContext(FormContext);

  function handleClickMenu(e) {
    if (e === '1') {
      changeComponent('edit', id);
    }
    if (e === '2') {
      dispatch(unlinkProtocol(patientId, id));
    }
  }

  const menu = (
    <Menu>
      <Menu.Item onClick={() => handleClickMenu('1')}>Editar</Menu.Item>
      <Menu.Item onClick={() => setPopBackScreen(true)}>
        Desvincular do paciente
      </Menu.Item>
    </Menu>
  );

  function handleOk() {
    handleClickMenu('2');
  }

  function handleCancel() {
    setPopBackScreen(false);
  }

  useEffect(() => {
    if (exercises.length > 2) {
      setExeShown(exercises.slice(0, 3));
    } else {
      setExeShown(exercises);
    }
  }, []);

  function removeAddedActivityOfProtocol(cid) {
    const oldStack = [...stackAddedActivities];
    for (let x = 0; x < stackAddedActivities.length; x += 1) {
      if (cid === stackAddedActivities[x].id) {
        const finalNewStack = oldStack
          .slice(0, x)
          .concat(oldStack.slice(x + 1, oldStack.length));
        setStackAddedActivities(finalNewStack);
        dispatch(addPracticeToEvent(finalNewStack));
        break;
      }
    }
  }

  function handlerExerciseSelected(cid, stackAdded) {
    // Análise de video já adicionado, senão adiciona video ao protocolo
    if (stackAdded.length > 0) {
      for (let q = 0; q < stackAdded.length; q += 1) {
        if (cid === stackAdded[q].id) {
          removeAddedActivityOfProtocol(cid);
          break;
        }
        if (q === stackAdded.length - 1) {
          setStackAddedActivities([
            ...stackAdded,
            { id, name, type, exercises, active },
          ]);
          dispatch(
            addPracticeToEvent(
              [...stackAdded, { id, name, type, exercises, active }],
              id,
            ),
          );
        }
      }
    } else {
      // Protocolo vazio, adiciona direto
      setStackAddedActivities([
        ...stackAdded,
        { id, name, type, exercises, active },
      ]);

      dispatch(
        addPracticeToEvent(
          [...stackAdded, { id, name, type, exercises, active }],
          id,
        ),
      );
    }
  }

  return (
    <S.Container
      style={{ border: practiceId === id ? '2px solid #7772B0' : '' }}
      onClick={() => {
        handlerExerciseSelected(id, stackAddedActivities);
      }}
    >
      <Popconfirm
        title={`Deseja realmente desvincular este protocolo de ${patientDetails.name}?`}
        visible={popBackScreen}
        onConfirm={handleOk}
        onCancel={handleCancel}
      />
      <S.ContainerVideos>
        {exeShown.map(exe => (
          <S.Video>
            <ReactPlayer url={exe.video_url} width="100%" height="100%" light />
            <S.UntouchableScreen />
          </S.Video>
        ))}
      </S.ContainerVideos>
      <S.ContainerSubInfo>
        <strong>{name}</strong>
        <Dropdown overlay={menu}>
          <Button
            style={S.AntdStyles.DropDownButton}
            icon={<MoreOutlined />}
            size="large"
          />
        </Dropdown>
      </S.ContainerSubInfo>
      <span className="practice">
        {type === 'DOMESTIC' ? 'Prática Domiciliar' : 'Telechamada'}
      </span>
    </S.Container>
  );
}
