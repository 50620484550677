/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Category } from './components/Categories';
import { ContainerContent } from './components/ContainerContent';
import * as S from './styles';

export function Content() {
  const videos = useSelector(state => state.professional_content.content);

  // eslint-disable-next-line no-unused-vars
  const [professionalContent, setProfessionalContent] = useState([]);

  useEffect(() => {
    const finalArrayOfCategs = [];

    if (videos !== null) {
      const arrayCategOne = [];
      const arrayCategTwo_One = [];
      const arrayCategThree_One = [];
      const arrayCategFour_One = [];
      const arrayCategFive_One = [];
      const arrayCategSix_One = [];
      const arrayOfCategorieNames = [];
      const contentVideos = videos;

      for (let x = 0; x < videos.length; x += 1) {
        /* Por no array ComponentCategorie para Categoria 1 */
        if (
          contentVideos[x].index_categorie === 1 ||
          contentVideos[x].index_categorie === '1'
        ) {
          arrayCategOne.push(
            <Category
              id={contentVideos[x].id}
              title={contentVideos[x].title}
              description={contentVideos[x].description}
              video_url={contentVideos[x].video_url}
              index_categorie={1}
              isPreview={false}
            />,
          );
          if (!arrayOfCategorieNames.includes(contentVideos[x].categorie, 0)) {
            arrayOfCategorieNames.push(contentVideos[x].categorie);
            finalArrayOfCategs.push({
              index: '1',
              name: contentVideos[x].categorie,
            });
          }
        }
        /* Por no array ComponentCategorie para categoria de videos 2 */
        if (contentVideos[x].index_categorie === 2) {
          // Divisão de primeira linha e segunda linha (se houver)
          arrayCategTwo_One.push(
            <Category
              id={contentVideos[x].id}
              title={contentVideos[x].title}
              description={contentVideos[x].description}
              video_url={contentVideos[x].video_url}
              index_categorie={2}
              isPreview={contentVideos[x].isPreview}
            />,
          );

          if (!arrayOfCategorieNames.includes(contentVideos[x].categorie, 0)) {
            arrayOfCategorieNames.push(contentVideos[x].categorie);
            finalArrayOfCategs.push({
              index: '2',
              name: contentVideos[x].categorie,
            });
          }
        }
        /* Por no array ComponentCategorie para categoria de videos 3 */
        if (contentVideos[x].index_categorie === 3) {
          // Divisão de primeira linha e segunda linha (se houver)
          arrayCategThree_One.push(
            <Category
              id={contentVideos[x].id}
              title={contentVideos[x].title}
              description={contentVideos[x].description}
              video_url={contentVideos[x].video_url}
              index_categorie={3}
              isPreview={contentVideos[x].isPreview}
            />,
          );

          if (!arrayOfCategorieNames.includes(contentVideos[x].categorie, 0)) {
            arrayOfCategorieNames.push(contentVideos[x].categorie);
            finalArrayOfCategs.push({
              index: '3',
              name: contentVideos[x].categorie,
            });
          }
        }

        /* Por no array ComponentCategorie para categoria de videos 4 */
        if (contentVideos[x].index_categorie === 4) {
          // 4
          // Divisão de primeira linha e segunda linha (se houver)
          arrayCategFour_One.push(
            <Category
              id={contentVideos[x].id}
              title={contentVideos[x].title}
              description={contentVideos[x].description}
              video_url={contentVideos[x].video_url}
              index_categorie={4}
              isPreview={contentVideos[x].isPreview}
            />,
          );

          if (!arrayOfCategorieNames.includes(contentVideos[x].categorie, 0)) {
            arrayOfCategorieNames.push(contentVideos[x].categorie);
            finalArrayOfCategs.push({
              index: '4',
              name: contentVideos[x].categorie,
            });
          }
        }

        /* Por no array ComponentCategorie para categoria de videos 5 */
        if (contentVideos[x].index_categorie === 5) {
          // 5
          // Divisão de primeira linha e segunda linha (se houver)
          arrayCategFive_One.push(
            <Category
              id={contentVideos[x].id}
              title={contentVideos[x].title}
              description={contentVideos[x].description}
              video_url={contentVideos[x].video_url}
              index_categorie={5}
              isPreview={contentVideos[x].isPreview}
            />,
          );
          if (!arrayOfCategorieNames.includes(contentVideos[x].categorie, 0)) {
            arrayOfCategorieNames.push(contentVideos[x].categorie);
            finalArrayOfCategs.push({
              index: '5',
              name: contentVideos[x].categorie,
            });
          }
        }
        /* Por no array ComponentCategorie para categoria de videos 5 */
        if (contentVideos[x].index_categorie === 6) {
          // 5
          // Divisão de primeira linha e segunda linha (se houver)
          arrayCategSix_One.push(
            <Category
              id={contentVideos[x].id}
              title={contentVideos[x].title}
              description={contentVideos[x].description}
              video_url={contentVideos[x].video_url}
              index_categorie={5}
              isPreview={contentVideos[x].isPreview}
            />,
          );
          if (!arrayOfCategorieNames.includes(contentVideos[x].categorie, 0)) {
            arrayOfCategorieNames.push(contentVideos[x].categorie);
            finalArrayOfCategs.push({
              index: '6',
              name: contentVideos[x].categorie,
            });
          }
        }
        if (x === contentVideos.length - 1) {
          setProfessionalContent(
            <ContainerContent
              firstCategory={arrayCategOne}
              secondCategory={arrayCategTwo_One}
              thirdCategory={arrayCategThree_One}
              fourthCategory={arrayCategFour_One}
              fifthCategory={arrayCategFive_One}
              sixthCategory={arrayCategSix_One}
              arrayOfCategoryNames={finalArrayOfCategs}
              isOverflow
            />,
          );
        }
      }
    }
  }, [videos]);

  return <S.Container>{professionalContent}</S.Container>;

  /*
    return (
    <S.Container>
      <iframe
        title="iframe-test"
        src="https://tutorialmonitore.wordpress.com/2022/05/04/como-adicionar-pacientes/"
        width="800px"
        height="1500px"
      />
    </S.Container>
  );
  */
}
