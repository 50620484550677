import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { Button, Row, Col } from 'antd';
import { setActivitySelected } from 'store/modules/protocol/actions';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'hooks/medias/useMediaQuery';
import { VideoSelectedModal } from './VideoSelectedModal';
import * as S from './styles';

const ActivityVideos = props => {
  const { array1 } = props;
  const { array2 } = props;
  const { array3 } = props;
  const { flag } = props;
  const { handlerExerciseSelected } = props;
  const isBiggerThan = useMediaQuery('(min-width: 770px)');

  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function stringFormater(value) {
    const tempStr = value.length;
    return tempStr > 40 ? `${value.slice(0, 40)}...` : value;
  }
  return (
    <S.ContainerShowActivityVideos>
      <Row
        gutter={[32, 16]}
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-around',
        }}
      >
        {array1.map(exercises => (
          <Col className="gutter-row" span={isBiggerThan ? 8 : 24}>
            <S.ElementVideoActVideos key={exercises.cid} title={exercises.name}>
              <ReactPlayer
                url={exercises.video_url}
                width="180px"
                height="120px"
                light
                title={exercises.name}
              />
              <S.ElementInfoActVideos>
                {stringFormater(exercises.name)}
              </S.ElementInfoActVideos>
              {flag ? (
                <Button
                  type="primary"
                  htmlType="submit"
                  style={S.AntdStyles.ButtonAddToProtocol}
                  onClick={() => {
                    props.removeAddedActivityOfProtocol(exercises.cid);
                  }}
                  aria-hidden="true"
                >
                  Remover
                </Button>
              ) : (
                <Button
                  type="primary"
                  htmlType="submit"
                  style={S.AntdStyles.ButtonAddToProtocol}
                  onClick={() => {
                    dispatch(setActivitySelected(exercises));
                    setIsModalVisible(true);
                    // props.handlerExerciseSelected(exercises.cid);
                  }}
                  aria-hidden="true"
                >
                  Adicionar ao protocolo
                </Button>
              )}
            </S.ElementVideoActVideos>
          </Col>
        ))}
        {array2.map(exercises => (
          <Col className="gutter-row" span={isBiggerThan ? 8 : 24}>
            <S.ElementVideoActVideos key={exercises.cid} title={exercises.name}>
              <ReactPlayer
                url={exercises.video_url}
                width="180px"
                height="120px"
                light
                title={exercises.name}
              />
              <S.ElementInfoActVideos>
                {stringFormater(exercises.name)}
              </S.ElementInfoActVideos>
              {flag ? (
                <Button
                  type="primary"
                  htmlType="submit"
                  style={S.AntdStyles.ButtonAddToProtocol}
                  onClick={() => {
                    props.removeAddedActivityOfProtocol(exercises.cid);
                  }}
                  aria-hidden="true"
                >
                  Remover
                </Button>
              ) : (
                <Button
                  type="primary"
                  htmlType="submit"
                  style={S.AntdStyles.ButtonAddToProtocol}
                  onClick={() => {
                    dispatch(setActivitySelected(exercises));
                    setIsModalVisible(true);
                    // props.handlerExerciseSelected(exercises.cid);
                  }}
                  aria-hidden="true"
                >
                  Adicionar ao protocolo
                </Button>
              )}
            </S.ElementVideoActVideos>
          </Col>
        ))}
        {array3.map(exercises => (
          <Col className="gutter-row" span={isBiggerThan ? 8 : 24}>
            <S.ElementVideoActVideos key={exercises.cid} title={exercises.name}>
              <ReactPlayer
                url={exercises.video_url}
                width="180px"
                height="120px"
                light
                title={exercises.name}
              />
              <S.ElementInfoActVideos>
                {stringFormater(exercises.name)}
              </S.ElementInfoActVideos>
              {flag ? (
                <Button
                  type="primary"
                  htmlType="submit"
                  style={S.AntdStyles.ButtonAddToProtocol}
                  onClick={() => {
                    props.removeAddedActivityOfProtocol(exercises.cid);
                  }}
                  aria-hidden="true"
                >
                  Remover
                </Button>
              ) : (
                <Button
                  type="primary"
                  htmlType="submit"
                  style={S.AntdStyles.ButtonAddToProtocol}
                  onClick={() => {
                    dispatch(setActivitySelected(exercises));
                    setIsModalVisible(true);
                    // props.handlerExerciseSelected(exercises.cid);
                  }}
                  aria-hidden="true"
                >
                  Adicionar ao protocolo
                </Button>
              )}
            </S.ElementVideoActVideos>
          </Col>
        ))}
      </Row>
      <VideoSelectedModal
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        closeModal={setIsModalVisible}
        handlerExerciseSelected={handlerExerciseSelected}
      />
    </S.ContainerShowActivityVideos>
  );
};

export default ActivityVideos;
